.block-eval-param-to-list {
	margin-top: 25px;
	height: 30px;
	cursor: pointer;
}
.block-eval-param-to-list .block-eval-positive-param,
.block-eval-param-to-list .block-eval-zero-param  {
	float: left;
	width: 24px;
	height: 24px;
	border-radius: 2px;
}
.block-eval-param-to-list .block-eval-positive-param {
	background-color: #0ECFAA;
}
.block-eval-param-to-list .block-eval-zero-param {
	background: #FFFFFF;
	border: 1px solid #FFFFFF;
	box-sizing: border-box;
	box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.block-eval-param-to-list .block-eval-positive-param .param-count {
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.505952px;
	color: #FFFFFF;
	text-align: center;
}

.block-eval-param-to-list .wrapper {
	width: 145px;
	float: left;
	margin-left: 12px;
	white-space: nowrap;
}
.block-eval-param-to-list .eval-param-name {
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: -0.505952px;
	color: #000;
}
.block-eval-param-to-list  .position-title {
	margin-top: 2px;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: -0.708333px;
	color: #000;
	overflow: hidden;
	text-overflow: ellipsis;
}
.block-eval-param-to-list .glue{
	height: 6px;
}