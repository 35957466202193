.block-header {
    width: 100%;
    overflow: hidden;
    /*position: relative;*/
    padding: 0;
    position: fixed;
    z-index: 999;
}
.block-header.for-project {
    background-size: cover!important;
    position: relative;
    box-shadow: inset 0px 0px 0px 10000px rgba(0,0,0,0.3);
    z-index: auto;
}
.block-header-project-top{
    top: -54px;
    padding: 54px 0 0 0;
}
.project-header-menu .block-new-top-menu-rocket-dao{
    position: relative!important;
}

.block-header.b-h-fund {
    padding: 0 0 0 0;
}
.block-header.b-h-fund.blue {
    background-color: #0028DC;
}

.block-header-top {
    overflow: hidden;
    height: 60px;
    padding: 15px 30px;
}

.block-header-top-left {
    float: left;
}

.block-header-top-left img {
    height: 35px;
}

.block-header-top-right {
    float: right;
}

.block-header-top-right-language {
    display: inline-block;
    vertical-align: top;
}

.block-header-top-right-language img {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    margin: 0 8px 0 0;
}

.block-header-top-right-language p {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 17px;
    line-height: 30px;
    margin: 0;
    font-weight: 300;
    display: inline-block;
    vertical-align: top;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50px;
    overflow: hidden;
}

.block-header-top-right-person {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 20px;
    position: relative;
}

.block-header-top-right-person img {
    width: 30px;
    height: 30px;
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
}

.block-header-top-right-person p {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 17px;
    line-height: 30px;
    margin: 0;
    font-weight: 300;
    display: inline-block;
    vertical-align: top;
    margin: 0 8px 0 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 150px;
}

.block-header-top-right-title {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 20px;
}
.block-header-top-right-title.bht-justify {
    line-height: 40px;
    width: 25px;
}
.block-header-top-right-title.bht-justify span{
    font-size: 25px;
    color: #ffffff;
    cursor: pointer;
}

.block-header-top-right-title p {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 17px;
    line-height: 30px;
    margin: 0;
    font-weight: 300;
    display: inline-block;
    vertical-align: top;
    text-overflow: ellipsis;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
}

.block-header-body-left-project img {
    display: inline-block;
    vertical-align: bottom;
}

.block-header-body-left-description {
}

.block-header-body-left-video {
}

.block-header-body-left-video img {
    width: 100%;
}

.block-header-body-right-state {
    border-bottom: 1px solid #D4D6D9;
    height: 36px;
}

.block-header-body-right-state .block-left-state {
    display: inline-block;
    float: left;
}

.block-header-body-right-state .block-left-state .value-global-state {
    font-family: "Stratos LC";
    font-size: 34px;
    line-height: 34px;
    font-weight: 400;
    color: #FFFFFF;
    display: inline-block;
    line-height: 30px;
    vertical-align: top;
    margin: 0 0 3px 0;
}

.block-header-body-right-state .block-left-state .block-info-state {
    background-color: #D4D6D9;
    width: 15px;
    height: 15px;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 5px;
    border-radius: 50%;
    cursor: pointer;
    font-family: "Stratos LC";
    font-size: 10px;
    line-height: 15px;
    font-weight: 300;
    color: #1B2434;
    text-align: center;
    position: relative;
    top: -8px;
}

.block-header-body-right-state .block-right-state {
    display: inline-block;
    float: right;
}

.block-right-state .block-local-state {
    display: inline-block;
    margin: 0 0 0 10px;
}

.block-right-state .block-local-state .value-state {
    font-family: "Stratos LC";
    font-size: 18px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    margin: 0;
    font-weight: 400;
    color: #FFFFFF;
}

.block-right-state .block-local-state .title-state {
    color: #D4D6D9;
    font-family: "Stratos LC";
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    margin: 0;
    font-weight: 300;
    color: #D4D6D9;
}

.block-header-body-right-raised {
    margin: 20px 0;
}

.block-header-body-right-progress {
    overflow: hidden;
    margin: 20px 0;
}

.block-header-body-right-ends-in.margin-fix {
    margin: 0 -15px;
}


.block-header-body-right-ends-in .count-raised-multi-fund span.count{color: #FFFFFF; font-weight: 300}
.block-header-body-right-ends-in .count-raised-multi-fund span.title{color: #FFFFFF;}
.block-header-body-right-ends-in .count-contrebuted-multi-fund span.count{color: #FFFFFF;}
.block-header-body-right-ends-in .count-contrebuted-multi-fund span.title{color: #FFFFFF;}
.block-header-body-right-ends-in .block-soft-cap-multi-fund p{color: #FFFFFF;}
.block-header-body-right-ends-in .block-hard-cap-multi-fund p{color: #FFFFFF;}


.block-header-body-right-ends-in .block-person-ends-in .person-by-ends-in {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 11px;
    line-height: 13px;
    font-weight: 300;
    margin: 0;
    height: 38px;
    display: inline-block;
    vertical-align: top;
}

.block-header-body-right-ends-in .block-person-ends-in img {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: top;
    margin: 0 10px 0 3px;
}

.block-header-body-right-ends-in .block-person-ends-in .block-name-person-ends-in {
    display: inline-block;
    vertical-align: top;
    margin: 0;
}



.block-header-body-right-ends-in .block-person-ends-in .block-name-person-ends-in .name-person-ends-in {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 15px;
    font-weight: 400;
    line-height: 19px;
    margin: 0;
}
.block-header-body-right-ends-in .block-person-ends-in .block-name-person-ends-in .name-person-ends-in a{
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
}
.block-header-body-right-ends-in .block-person-ends-in .block-name-person-ends-in .title-person-ends-in {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 16px;
    font-weight: 300;
    margin: 0;
}
.projects-nominees-person-style .block-person-ends-in{
    margin: 0 0 10px 0;
}
.projects-nominees-person-style .block-person-ends-in .block-name-person-ends-in .name-person-ends-in{    color: #000000!important;}
.projects-nominees-person-style .block-person-ends-in .block-name-person-ends-in .title-person-ends-in{    color: #000000!important;}

.block-header-body-right-buttons {
}

.block-header-body-right-buttons .shell-big-button-buy {
    margin: 0 0 10px 0;
}

.block-header-body-right-buttons .shell-dual-buttons-share-follow {
}

.block-header-body-right-buttons .shell-dual-buttons-share-follow .shell-dual-buttons-share {
    width: 48.5%;
    display: inline-block;
    float: left;
}

.block-header-body-right-buttons .shell-dual-buttons-share-follow .shell-dual-buttons-follow {
    width: 48.5%;
    display: inline-block;
    float: right;
}

.container-menu {
    width: 1140px;
    margin: 0 auto 0 auto;
}

@media screen and (min-width: 1200px) {
    .block-header-body {
        width: 1140px;
        margin: 0 auto;
    }

    .block-header-body-left {
        width: 715px;
        float: left;
    }

    .block-header-body-right {
        width: 360px;
        float: right;
        margin: 66px 0 0 0;
    }

    .block-header-body-left-project {
        min-height: 75px;
        padding-bottom: 15px;
    }

    .block-header-body-left-project .shell-button {
        display: inline-block;
        vertical-align: bottom;
        margin: 0 0 0 15px;
    }

    .block-header-body-left-project .title-header-body-left-project {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 48px;
        line-height: 62px;
        font-weight: 500;
        display: inline-block;
        vertical-align: top;
        text-overflow: ellipsis;
        max-width: 520px;
        overflow: hidden;
        letter-spacing: -1px;
        white-space: nowrap;
        margin: 0 0 0 15px;
        vertical-align: bottom;
        vertical-align: bottom;
        bottom: -12px;
        position: relative;
    }

    .block-header-body-left-description p {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 17px;
        line-height: 21px;
        margin: 0 0 10px 0;
        font-weight: 300;
    }

    .block-header-body-left-tag {
        margin: 0 0 20px 0;
    }

    .block-header-body-right-raised .title-raised {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin: 0;
    }

    .block-header-body-right-raised .value-raised {
        color: #00F082;
        font-family: "Stratos LC";
        font-size: 34px;
        line-height: 40px;
        font-weight: 400;
        margin: 0;
    }

    .block-header-body-right-raised .price-raised {
        color: #D4D6D9;
        font-family: "Stratos LC";
        font-size: 13px;
        line-height: 16px;
        font-weight: 300;
        margin: 0;
    }

    .block-header-body-right-ends-in .title-ends-in {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 14px;
        line-height: 18px;
        font-weight: 300;
        margin: 0;
        text-align: left;
    }

    .block-header-body-right-ends-in .dey-ends-in {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        margin: 0;
        text-align: left;
    }

    .block-header-body-right-ends-in .block-person-ends-in {
        margin: 20px 0 0 0;
    }

    .block-header-body-right-social {
        margin: 20px 0;
    }
}

@media screen and (max-width: 1199px) {
    .block-header-body {
        width: 715px;
        margin: 0 auto;
    }

    .block-header-body-left {
        width: 100%;
        float: none;
    }

    .block-header-body-right {
        width: 100%;
        float: none;
        margin: 20px 0 0 0;
    }

    .block-header-body-left-project {
        height: auto;
        text-align: center;
    }

    .block-header-body-left-project .shell-button {
        display: inline-block;
        vertical-align: bottom;
        margin: 0 0 0 15px;
    }

    .block-header-body-left-project .title-header-body-left-project {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 48px;
        line-height: 62px;
        font-weight: 500;
        display: inline-block;
        vertical-align: top;
        text-overflow: ellipsis;
        max-width: 520px;
        overflow: hidden;
        letter-spacing: -1px;
        white-space: nowrap;
        margin: 0 0 0 15px;
        vertical-align: bottom;
        vertical-align: bottom;
        bottom: -12px;
        position: relative;
    }

    .block-header-body-left-description p {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 17px;
        line-height: 21px;
        margin: 0 0 10px 0;
        font-weight: 300;
        text-align: center;
    }

    .block-header-body-left-tag {
        display: table;
        margin: 0 auto 20px auto;
    }

    .block-header-body-right-raised .title-raised {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin: 0;
        text-align: center;
    }

    .block-header-body-right-raised .value-raised {
        color: #00F082;
        font-family: "Stratos LC";
        font-size: 34px;
        line-height: 40px;
        font-weight: 400;
        margin: 0;
        text-align: center;
    }

    .block-header-body-right-raised .price-raised {
        color: #D4D6D9;
        font-family: "Stratos LC";
        font-size: 13px;
        line-height: 16px;
        font-weight: 300;
        margin: 0;
        text-align: center;
    }

    .block-header-body-right-ends-in .title-ends-in {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 14px;
        line-height: 18px;
        font-weight: 300;
        margin: 0;
        text-align: center;
    }

    .block-header-body-right-ends-in .dey-ends-in {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        margin: 0;
        text-align: left;
    }

    /*.block-header-body-right-ends-in .block-person-ends-in {*/
        /*margin: 20px auto 0 auto;*/
        /*display: table;*/
    /*}*/

    .block-header-body-right-social {
        margin: 20px 0;
        text-align: center;
    }
}

@media screen and (max-width: 750px) {
    .block-header-body {
        width: 100%;
        margin: 0;
        padding: 0 20px;
    }

    .block-header-body-left {
        width: 100%;
        float: none;
    }

    .block-header-body-right {
        width: 100%;
        float: none;
        margin: 20px 0 0 0;
    }

    .block-header-body-left-project {
        height: auto;
        overflow: hidden;
        text-align: center;
    }

    .block-header-body-left-project .shell-button {
        display: table;
        vertical-align: bottom;
        margin: 20px auto;
    }

    .block-header-body-left-project .title-header-body-left-project {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 48px;
        line-height: 56px;
        font-weight: 500;
        display: inline-block;
        vertical-align: top;
        text-overflow: ellipsis;
        max-width: 520px;
        overflow: hidden;
        letter-spacing: -1px;
        white-space: nowrap;
        margin: 0 0 0 15px;
        bottom: 0;
        position: relative;
    }

    .block-header-body-left-description p {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 17px;
        line-height: 21px;
        margin: 0 0 10px 0;
        font-weight: 300;
        text-align: center;
    }

    .block-header-body-left-tag {
        display: table;
        margin: 0 auto 20px auto;
    }

    .block-header-body-right-raised .title-raised {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        margin: 0;
        text-align: center;
    }

    .block-header-body-right-raised .value-raised {
        color: #00F082;
        font-family: "Stratos LC";
        font-size: 34px;
        line-height: 40px;
        font-weight: 400;
        margin: 0;
        text-align: center;
    }

    .block-header-body-right-raised .price-raised {
        color: #D4D6D9;
        font-family: "Stratos LC";
        font-size: 13px;
        line-height: 16px;
        font-weight: 300;
        margin: 0;
        text-align: center;
    }

    .block-header-body-right-ends-in .title-ends-in {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 14px;
        line-height: 18px;
        font-weight: 300;
        margin: 0;
        text-align: center;
    }

    .block-header-body-right-ends-in .dey-ends-in {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        margin: 0;
        text-align: center;
    }

    /*.block-header-body-right-ends-in .block-person-ends-in {*/
        /*margin: 20px auto 0 auto;*/
        /*display: table;*/
    /*}*/

    .block-header-body-right-social {
        margin: 20px 0;
        text-align: center;
    }
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
}
.container-shadow {
    box-shadow: inset 0px 0px 0px 10000px rgba(0,0,0,0.5);
}
.pb-50 {
    padding-bottom: 120px;
}


.hamburger-icon {
    width: 30px;
    height: 20px;
    position: relative;
    display: block;
    margin: 4px auto 20px auto;
    transition: .3s;
    top: 0;
}
.hamburger-icon .line {
    display: block;
    background: #ecf0f1;
    width: 30px;
    height: 3px;
    position: absolute;
    left: 0;
    border-radius: 1.5px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
}
.hamburger-icon .line.line-1 {
    top: 0;
}
.hamburger-icon .line.line-2 {
    top: 50%;
}
.hamburger-icon .line.line-3 {
    top: 100%;
}
/*.hamburger-icon:hover .line-1, .hamburger-icon:focus .line-1 {*/
    /*transform: translateY(-1.5px);*/
    /*-webkit-transform: translateY(-1.5px);*/
    /*-moz-transform: translateY(-1.5px);*/
/*}*/
/*.hamburger-icon:hover .line-3, .hamburger-icon:focus .line-3 {*/
    /*transform: translateY(1.5px);*/
    /*-webkit-transform: translateY(1.5px);*/
    /*-moz-transform: translateY(1.5px);*/
/*}*/
.hamburger-icon.active{
    position: fixed;
    top: 10px;
}
.hamburger-icon.active .line-1 {
    transform: translateY(9px) translateX(0) rotate(45deg);
    -webkit-transform: translateY(9px) translateX(0) rotate(45deg);
    -moz-transform: translateY(9px) translateX(0) rotate(45deg);
}
.hamburger-icon.active .line-2 {
    opacity: 0;
}
.hamburger-icon.active .line-3 {
    transform: translateY(-11px) translateX(0) rotate(-45deg);
    -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
    -moz-transform: translateY(-11px) translateX(0) rotate(-45deg);
}

.wallet-percent-down{
    color: #FA4600;
}
.wallet-percent-up{
    color: #00c46b;
}
.wallet-percent-down i{

}

.title-start-in-project{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 15px;	letter-spacing: -0.16px;	line-height: 21px;
    margin: 60px 0 80px -15px; font-weight: 300;
}
.title-start-in-project span{
    font-weight: 400;
    margin: 0 0 0 20px;
}