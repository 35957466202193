.block-fund-info {
    width: 100%;
    height: 40px;
    display: none;
}
.block-fund-info .avatar {
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: 50% 50%!important;
}
.block-fund-info .name-title {
    padding-left: 10px;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: -0.505952px;
}
.preliminary-evaluation-block {
    padding-top: 14px;
}
.preliminary-evaluation-block .left-sidebar {
    margin: 22px 0 0 24px;
    /*max-width: 200px;*/
    min-width: 150px;
    /*min-height: 100vh;*/
}
.save-and-return-block {
    padding-bottom: 12px;
    border-bottom: 1px solid #D8D8D8;
    /*max-width: 200px;*/
}
.save-and-return-block .save-arrow{
    max-width: 40px;
    height: 14px;
    background-image: url("./img/save-arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 6px;
}
.save-and-return-block .save-title {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.505952px;
    color: #595757
}
.evaluation-points-block .point-text {
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.708333px;
    color: #2F2F2F;
}
.evaluation-points-block .point-block {
    /*margin-right: 3%;*/
}
.evaluation-points-block .point-block:nth-child(4n) {
    margin-right: 0;
}
.preliminary-evaluation-block .evaluation-main-content {
    padding: 22px 24px;
    min-height: 100vh;
}
.evaluation-main-content .evaluation-title-h1 {
    font-size: 26px;
    line-height: 33px;
    letter-spacing: -0.505952px;
}
.evaluation-title-descr {
    font-size: 16px;
    line-height: 20px;
}
.evaluation-main-content .example-text-block {
    width: 220px;
    height: 222px;
    border-radius: 3px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px rgba(0,0,0,0.14);
    overflow-y: hidden;
}
.example-text-block .example-text-title {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: -0.505952px;
    color: rgba(8,8,8,0.8);
}
.example-text-block .example-text-title.text-block {
    margin: 14px 0 0 15px;
}
.example-text-block .example-text {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.505952px;
    color: rgba(8,8,8,0.6);
}
.example-text-block .example-wrapper {
    margin: 25px 0 0 15px;
    height: 150px;
}
.profile-name-block {
    position: fixed;
    top: 36px;
    right: 50px;
    display: none;
}
.profile-name-block .name-text,
.profile-name-block .save-text {
    font-family: Roboto;
    font-style: normal;
    font-size: 12px;
    line-height: 15px;
}
.profile-name-block .name-text {
    color: #404040;
}
.profile-name-block .save-text {
    color: #979797;
}
.evaluation-main-content  .block-button-3-0 p.block-button-3-0-title {
    font-weight: 500;
}
/*evaluation view*/
.block-value-reviews {
    border-top: 1px solid #ECECEC;
}
.block-preliminary-evaluation-view {
    background-color: #FDFDFD;
    margin-left: 56px;
}
.block-preliminary-evaluation-view .block-parameter-review {
    background: #FFFFFF;
    box-shadow: -7px 12px 24px rgba(0,0,0,0.1);
    border-radius: 6px;
    padding: 22px 25px 40px 25px;
    max-width: 700px;
}
.block-preliminary-evaluation-view .block-main-parameter-review {
    /*padding-left: calc(25% - 320px);*/
    padding-bottom: 80px;
    max-width: 700px;
    background-color: #FDFDFD;
}
.block-main-parameter-review .parameter-name-title {
    font-size: 32px;
    line-height: 32px;
}
.block-main-parameter-review .preliminary-evaluation-title {
    font-size: 16px;
    line-height: 20px;
}
.block-parameter-review .rewiew-text {
    font-size: 26px;
    line-height: 35px;
}
.block-parameter-review .avatar {
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: 50%!important;
}
.block-parameter-review .expert-text {
    font-size: 12px;
    line-height: 15px;
    color: #0ECFAA;
}
.block-parameter-review .text-40-symbols {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0,0,0,0.5);
}
.block-parameter-review .positive-sides-block {
    padding-left: 13px;
    border-left: 2px solid #0ECFAA;
}
.block-parameter-review .negative-sides-block {
    padding-left: 13px;
    border-left: 2px solid #FF4133;
}
.sides-text {
    font-size: 14px;
    line-height: 18px;
    white-space: pre-wrap;
}
.recommendations-text {
    font-size: 16px;
    line-height: 22px;
    white-space: pre-wrap;
}
.block-info-icon {
    margin-top: 5px;
    margin-left: -5px;
    width: 14px;
    height: 14px;
    background: #c4c4c4;
    border-radius: 50%;
    transition: .3s;
}
.block-info-icon:hover {
     background: #A4A4A4;
 }
.block-info-icon .info-icon {
    font-size: 11px;
    line-height: 14px;
    color: #FFFFFF;
    text-align: center;
}
.total-score {
    margin-left: 5px;
}
.total-score .total-score-text {
    font-size: 32px;
    line-height: 38px;
}
.block-param-total-score .param-total-score-title {
    font-size: 12px;
    line-height: 16px;
}
.block-param-total-score-title {
    margin-top: 3px;
}
.block-preliminary-evaluation-view .mobile-button-back {
    height: 30px;
    margin: 20px 0 0 20px;
    display: none;
}
.sides-text a {
    color: black;
}
.text-mobile-button-back {
    font-size: 16px;
    line-height: 30px;
}
.value-name-container {
    display: flex;
}
.block-left-cart-expert startup .expert-review-score{
    cursor: pointer;
}
.block-evaluation-view-comments .block-comments-gradient {
    display: none;
}



.margin-left-10 {
    margin-left: 10px;
}
.margin-top-12 {
    margin-top: 12px;
}
.margin-left-20 {
    margin-left: 20px;
}
.margin-top-22 {
    margin-top: 22px;
}
.padding-top-30 {
    padding-top: 30px;
}
.color-2f3849 {
    color: #2F3849;
}
.color-rgba-08 {
    color: rgba(0,0,0,0.8)!important;
}
.color-rgba-03 {
    color: rgba(0,0,0,0.3)!important;
}
.cursor-pointer {
    cursor: pointer;
}
.font-weight-300 {
    font-weight: 300;
}


@media screen and (max-width:1040px) and (min-width: 992px) {
   .block-main-parameter-review {
       flex: none;
   }
}
@media screen and (max-width:688px) {
    .view-eval-left-bar .avatar {
        margin: 0 auto;
    }
    .view-eval-left-bar .expert-review-score {
        margin: 0 auto;
    }
    .view-eval-left-bar .evaluation-title-descr {
        margin: 0 auto;
    }
    .view-eval-left-bar .point-text {
        margin: 0 auto;
    }
    .preliminary-evaluation-title,
    .parameter-name-title {
        margin-left: 25px;
    }
}
@media screen and (max-width:630px) {
    .save-back-buttons-block .block-button-right,
    .save-back-buttons-block .block-button-left {
        float: none;
    }
    .save-back-buttons-block .block-button-right .block-button-3-0,
    .save-back-buttons-block .block-button-left .block-button-3-0 {
        width: 100%;
    }
    .save-back-buttons-block .block-button-left .block-button-3-0 {
        margin-top: 10px;
    }
}
@media screen and (max-width:515px) {
    .block-preliminary-evaluation-view .profile-menu-frame {
        display: none;
    }
    .block-preliminary-evaluation-view {
        margin-left: 0;
    }
    .block-preliminary-evaluation-view .mobile-button-back {
        display: block;
    }
}
@media screen and (min-width:576px) {
    .preliminary-evaluation-block .left-sidebar {
        max-width: 200px;
    }
    .preliminary-evaluation-block .evaluation-main-content {
        padding: 22px 0 0 24px;
    }
}

@media screen and (min-width:660px) {
    .block-fund-info {
        display: block;
    }
}
@media screen and (min-width:820px) {
    .preliminary-evaluation-block .evaluation-main-content {
        padding: 22px 0 0 calc(25% - 200px);
    }
}
@media screen and (min-width:992px) {
    .example-text-block {
        margin-left: 20px;
    }
}
@media screen and (min-width:1100px) {
    .preliminary-evaluation-block .left-sidebar {
        margin: 22px 0 0 48px;
    }
}
@media screen and (min-width:1300px) {
    .preliminary-evaluation-block .evaluation-main-content {
        padding: 22px 0 0 162px;
    }
}


