$color_black: rgba(0,0,0,0.8);

.popup-wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(255,255,255,0.7);
  z-index: 1234;
}

.details-popup-container {
  position: relative;
  top: 50%;
  left: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  min-height: 100px;
  max-width: 540px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 20px 0 0 0;
  .description-title {
    font-size: 14px;
    line-height: 18px;
    color: $color_black;
  }
  .details-title {
    font-size: 16px;
    line-height: 20px;
    color: $color_black;
  }
  .top-block {
    padding: 23px 58px 0 38px;
    .description-title {
      font-weight: 300;
    }
    .details-title {
      font-weight: 300;
      span {
        font-weight:500;
        cursor: pointer;
      }
      .position-text {
        font-size: 14px;
        font-weight: 300;
      }
    }
    .description-block {
      padding: 23px 0 16px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    }
    .location-block {
      margin-top: 15px;
    }
    .site-block {
      margin-top: 13px;
      span {
        font-weight:500;
      }
    }
    .clutch-stars-wrapper {
      display: inline-block;
      height: 15px;
      width: 85px;
      margin-left: 10px;
      padding-top: 2px;
    }
    .clutch-stars {
      background: url("../img/five_stars.svg");
      background-repeat: no-repeat;
      background-position: 50%;
      width: 100%;
      height: 15px;
    }
  }
  .top-block.slon-paddings {
    padding: 23px 40px 0 38px;
  }
  .bottom-block {
    background: linear-gradient(180deg, #F5F5F5 0.24%, rgba(255, 255, 255, 0.0001) 55.46%);
    padding: 35px 40px 32px 38px;
    margin-top: 30px;
    .block-button-3-0.button-3-0-green {
      box-shadow: 0px 8px 19px rgba(47, 56, 73, 0.151664);
      height: 40px;
      p.block-button-3-0-title {
        font-size: 16px;
        line-height: 40px;
        color: #FFFFFF;
        font-weight: 500;
      }
    }
    .slon-bottom-container {
      .descr-block {
        margin-top: 34px;
      }
      .format-block {
        margin-top: 12px;
        .format-title {
          font-size: 10px;
          line-height: 13px;
          color: rgba(0, 0, 0, 0.6);
          font-weight: 500;
        }
        .format-descr {
          font-size: 12px;
          line-height: 15px;
          color: rgba(0, 0, 0, 0.8)
        }
      }
      .format-block.last-format {
        padding-bottom: 8px;
      }
      .prices-wrapper {
        padding: 20px 0 20px 0;
        .price-col {
          .descr-text {
            font-size: 12px;
            line-height: 15px;
            color: rgba(0, 0, 0, 0.8);
          }
          .descr-text-wrapper {
            margin-top: 6px;
          }
          .example-button {
            background: transparent;
            border: 1px solid #2F3849;
            border-radius: 4px;
            padding: 0 10px;
            display: inline-block;
            margin-top: 16px;
            cursor: pointer;
          }
          .example-button-text {
            font-size: 12px;
            line-height: 23px;
            color: #2F3849;
          }
        }
      }
    }
  }


  @media all and (max-width: 576px) {
    min-height: 100vh;
    border-radius: unset;
    border: none;
      .top-block,
      .top-block.slon-paddings {
        padding: 23px 18px 0 17px;
      }
      .bottom-block {
        padding: 35px 18px 52px 17px;
        .block-button-3-0.button-3-0-green {
          width: 100%;
        }
        .slon-bottom-container {
          .prices-wrapper {
            padding: 20px 0 0 0;
            .price-col {
              position: relative;
              margin-bottom: 28px;
              .example-button {
                margin-top: 0;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
          .format-block.last-format {
            margin-top: 4px;
          }
        }
      }
    }
}

