.tool-tip:before,
.tool-tip:after {
    display: block;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    /*margin-bottom: 15px;*/
    transition: opacity .7s, margin-bottom .7s;
    left: 50%;
    z-index: 1000000000;
}

.tool-tip {
    position: relative;
    cursor: pointer;
}

.tool-tip:before {
    top: calc(100% + 20px);
    left: 50%;
    padding: 6px;
    width: 160px;
    margin-left: -80px;
    color: white;
    background-color: #FFFFFF;
    border: 1px solid #BBBBBB;
    content: attr(data-title);
    box-shadow: border-box;
    text-align: left;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 11px;	line-height: 13px; font-weight: 300;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
}

.tool-tip:after {
    content: "";
    width: 15px;
    height: 15px;
    top: 25px;
    left: 0;
    background: #FFFFFF;
    border-left: 1px solid #BBBBBB;
    border-top: 1px solid #BBBBBB;
    transform: rotate(45deg);
}

.tool-tip:hover:before,
.tool-tip:hover:after {
    opacity: 1;
    visibility: visible;
}


span.new-white-tool-tip{
    margin: 0 0 0 10px;
    display: inline-block;
    width: 15px;
    height: 15px;
    color: #FFFFFF;
    background: #0000D3;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 15px;
    font-weight: 300;
    text-align: center;
    border-radius: 50%;
}

span.new-white-tool-tip:before,
span.new-white-tool-tip:after {
    display: block;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    margin-bottom: 15px;
    transition: opacity .4s, margin-bottom .4s;
    left: 20%;
    z-index: 1000;
}

span.new-white-tool-tip {
    position: relative;
    cursor: pointer;
}

span.new-white-tool-tip:before {
    top: calc(100% + 20px);
    left: calc(50% - 5px);
    padding: 6px;
    width: 190px;
    margin-left: -60px;
    border: 1px solid #979797;
    border-radius: 3px;
    color: white;
    background-color: rgba(255, 255, 255, .85);
    content: attr(data-title);
    box-shadow: border-box;
    text-align: center;
    color: #000;
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 17px;
    font-weight: 300;
    text-align: justify;
}

span.new-white-tool-tip:after {
    content: "";
    /* border-bottom: 10px solid rgba(255, 255, 255, .85); */
    /* border-left: 5px solid #000; */
    /* border-right: 5px solid transparent; */
    top: calc(100% + 15px);
    width: 10px;
    height: 10px;
    background: #fff;
    border-left: 1px solid #979797;
    border-top: 1px solid #979797;
    transform: rotate(45deg);
}

span.new-white-tool-tip:hover:before,
span.new-white-tool-tip:hover:after {
    opacity: 1;
    visibility: visible;
}



span.new-black-tool-tip{

}

span.new-black-tool-tip:before,
span.new-black-tool-tip:after {
    display: block;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    margin-bottom: 15px;
    transition: opacity .4s, margin-bottom .4s;
    left: 20%;
    z-index: 1000;
}

span.new-black-tool-tip {
    position: relative;
    cursor: pointer;
}

span.new-black-tool-tip:before {
    bottom: calc(100% + 15px);
    right: -30px;
    left: auto;
    padding: 6px;
    width: 190px;
    margin: 0;
    border: 1px solid #979797;
    border-radius: 3px;
    color: white;
    background-color: #383838;
    content: attr(data-title);
    box-shadow: border-box;
    text-align: center;
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 11px;
    line-height: 13px;
    font-weight: 300;
    text-align: justify;
}

span.new-black-tool-tip:after {
    content: "";
    /* border-bottom: 10px solid rgba(255, 255, 255, .85); */
    /* border-left: 5px solid #000; */
    /* border-right: 5px solid transparent; */
    top: -35px;
    left: 50%;
    width: 10px;
    height: 10px;
    background-color: #383838;
    /*border-left: 1px solid #0f0f0f;*/
    /*border-top: 1px solid #0f0f0f;*/
    transform: rotate(45deg);
}

span.new-black-tool-tip:hover:before,
span.new-black-tool-tip:hover:after {
    opacity: 1;
    visibility: visible;
}