.container-100-reg {
    width: 100%;
    /*overflow: hidden;*/
    margin: 0 auto;
    display: block;
    /*min-height: 100vh;*/
    min-height: 100%;
}
.padding-reg {
    padding: 0 0 120px 0;
}
.terms-links-text {
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    width: 100%;
    max-width: 280px;
    margin: 10px auto 0 auto;
}
.terms-links-text a {
    color: #2CA6EB;
}
.block-item-check-box-reg-startup {
    color: rgba(0,0,0,0.8);
}
/*.container-global-reg{*/
    /*min-height: 100vh;*/
/*}*/
@media (min-width: 1200px) {
    .container-global-reg {
        width: 1140px !important;
        margin: 0 auto;
        display: table;
    }

    .container-full-reg {
        width: 940px !important;;
        margin: 0 auto;
        display: table;
    }

    .container-step-1-reg {
        width: 860px !important;;
        margin: 0 0 0 auto;
        display: table;
    }

    .container-step-2-reg {
        width: 780px !important;;
        margin: 0 0 0 auto;
        display: table;
    }

    .container-step-2-reg.container-step-21 {
        width: 600px !important;
        margin: 0 auto !important;
    }

    .container-step-11-reg {
        width: 720px !important;;
        margin: 0 auto;
        display: table;
    }

    .container-width-600 {
        width: 600px !important;
        margin: 0 auto !important;
    }

}

@media (max-width: 1199px) {
    .container-global-reg {
        width: 855px !important;;
        margin: 0 auto;
        display: table;
    }

    .container-full-reg {
        width: 705px !important;;
        margin: 0 auto;
        display: table;
    }

    .container-step-1-reg {
        width: 645px !important;;
        margin: 0 0 0 auto;
        display: table;
    }

    .container-step-2-reg {
        width: 585px !important;;
        margin: 0 0 0 auto;
        display: table;
    }
}

@media (max-width: 900px) {
    .container-global-reg {
        width: 570px !important;;
        margin: 0 auto;
        display: table;
    }

    .container-full-reg {
        width: 100% !important;;
        margin: 0 auto;
        display: table;
    }

    .container-step-1-reg {
        width: 100% !important;;
        margin: 0 auto;
        display: table;
    }

    .container-step-2-reg {
        width: 100% !important;;
        margin: 0 auto;
        display: table;
    }
    .padding-reg {
        padding: 0 0 250px 0;
    }
}

@media (max-width: 620px) {
    .container-global-reg {
        width: 100% !important;
        margin: 0 auto;
        display: table;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .container-full-reg {
        width: 100% !important;
        margin: 0 auto;
        display: table;
    }

    .container-step-1-reg {
        width: 100% !important;
        margin: 0 0 0 auto;
        display: table;
    }

    .container-step-2-reg {
        width: 100% !important;
        margin: 0 0 0 auto;
        display: table;
    }

}

.temp-wrap {
    text-align: center;
}

.reg-full-wrap {
    min-height: 100vh;
    background-color: #fff;
}

.reg-wrap {
    /*text-align: center;*/
    background-color: #fdfdfd;
    min-height: 100vh;
    overflow: hidden;
}

div[role=button]:focus {
    outline: none;
}

.reg-wrap .row {
    margin-left: 0;
    margin-right: 0;
}

.block-btns-soc {
    width: 321px;
    margin: 0 auto;
}

.block-btns-soc .block-button-reg-social,
.block-btns-soc .block-button-social-reg-custom {
    margin-bottom: 24px;
}

.block-btns-soc.block-email {
    background-color: #fff;
    padding: 25px;
    width: 369px;
    text-align: left;

}
.block-btns-soc.block-email.login-form {
    border-radius: 7px;
    box-shadow: 0px 6px 16px rgba(0,0,0,0.07);
}

.block-btns-soc.block-email.back {
    background-color: initial;
    text-align: right;
    padding: 5px 0 0 0;
}

.block-btns-soc.block-email.back span {
    text-decoration: underline;
    cursor: pointer;
    color: #737373;
    font-size: 14px;
}

.block-btns-soc.block-email .block-new-input {
    margin-bottom: 20px;
}

.block-btns-soc.block-email .block-button-3-0 {
    width: 100%;
    margin-top: 20px;
}

.block-login-sign-up {
    margin-top: 20px;
}
.sign-up-text {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0,0,0,0.7);
    font-weight: 300;
}
.sign-up-text a {
    color: #2CA6EB;
    font-weight: 500;
    cursor: pointer;
    margin-left: 8px;
    text-decoration: none;
}

.block-logo-top {
    height: 100px;
    text-align: left;
}

.block-logo-top img {
    padding-top: 40px;
}

.block-btns-soc .btn-google > button,
.block-btns-soc .btn-twitter > button {
    display: inline-block;
    background: none !important;
    color: inherit !important;
    width: 100% !important;
    padding: 0 !important;
    border: 0 !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    font-family: inherit !important;
    outline: none !important;
}

.block-img-footer img {
    width: 100%;
    position: absolute;
    /*bottom: -70px;*/
    left: 0;
    z-index: 0;
}

.block-created {
    min-height: 200px;
    font-size: 18px;
    line-height: 22px;
}

.bold {
    font-weight: 500;
}

.block-created .click-here {
    text-decoration: underline;
    cursor: pointer;
    color: #4966ff;
}

.reg-wrap .progress-bar {
    background-color: #ECECEC;
}

.reg-wrap .progress-bar .active-line {
    height: 8px;
    background-color: #0ECFAA;
}

.reg-wrap .reg-header {
    height: 94px;
    background-color: #fdfdfd;
}

.reg-wrap .reg-header img {

}

.reg-wrap .reg-header .first-last {
    color: #080808;
    font-size: 14px;
}

.reg-wrap .reg-header .grey-bottom {
    font-size: 12px;
    color: #080808;
    opacity: 0.5;
    padding-top: 3px;
}

.reg-wrap .step-1 {
    text-align: left;
}

.reg-wrap .step-1 .text-h1 {
    margin-top: 0;
    color: rgba(8, 8, 8, 0.8);
}

.reg-wrap .step-1 .text-h5 {
    color: rgba(0, 0, 0, 0.75);
}

.reg-wrap .step-1 .text-h6 {
    color: #4a4a4a;
}

.reg-wrap .step-1 .pg-left {
    padding-left: 45px;
}

.reg-wrap .step-1 .wrap-white {
    background-color: #fdfdfd;
    padding-bottom: 40px;
    padding-top: 20px;
}

.step-1 .container-bottom .row {
    padding: 70px 45px 105px 45px;
}

.step-1 .container-bottom .text-h4 {
    margin: 0;
    color: rgba(8, 8, 8, 0.8);
}

.step-1 .container-bottom .numb {
    font-size: 40px;
    color: rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
}

.step-1 .container-bottom .numb.active {
    color: #0ECFAA;
}

.step-1 .row.row-flex,
.step-2 .row.row-flex,
.step-17 .row.row-flex {
    justify-content: space-between;
}

.step-1 .row.row-flex .col-4,
.step-1 .row.row-flex .col-3,
.step-2 .row.row-flex .col-4 {
    width: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: none;
    max-width: none;
    transition: .3s;
}

.step-1.is-startup .row.row-flex .col-3 {
    max-width: 20%;
}

.step-1 .container-bottom .btm-text {
    color: rgba(8, 8, 8, 0.8);
    margin-top: 5px;
    line-height: 18px;
    font-size: 14px;
}

.btm-text b {
    font-weight: 500;
}

.step-1 .container-bottom .text-h4.no-active {
    color: rgba(0, 0, 0, 0.25);
}

.step-1 .container-bottom .btm-text.no-active {
    color: rgba(8, 8, 8, 0.3);
}

.reg-wrap .btn-next {
    padding: 50px 0;
    text-align: right;
}

.reg-wrap .btn-next .btn-left {
    float: left;
}

.step-1 .container-bottom .div-flex span {
    color: #979797;
    position: relative;
    top: 60px;
    font-weight: bold;
    opacity: 0.3;
    border-top: 3px dashed #979797;
    display: inline-block;
    width: 50px;
}

.step-2 {
    padding-top: 60px;
}

.step-2 .block-center .text-desc {
    color: #525252;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 60px;
}

.step-2 .row.row-flex .col-4 {
    width: 200px;
    padding: 45px 10px 10px 10px;
    text-align: center;
    background-color: #fff;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    cursor: pointer;
}

.step-2 .row.row-flex .col-4:hover {
    box-shadow: -7px 12px 24px 0 rgba(0, 0, 0, 0.06);
}

.step-2 .row.row-flex .col-4 img {
    width: 55px;
    height: 55px;
    transition: .3s;
    margin: 0 auto;
}

.step-2 .row.row-flex .col-4 .text-h4 {
    margin-top: 20px;
    margin-bottom: 14px;
    color: rgba(8, 8, 8, 0.8);
}

.step-2 .row.row-flex .col-4 .text-desc-2 {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 500;
    margin: 0;
}

.step-2 .row.row-flex .col-4 .text-btm {
    font-size: 12px;
    color: #596377;
    margin-top: 30px;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.step-2 .text-h1 {
    margin-top: 0;
    color: rgba(8, 8, 8, 0.8);
}

.temp-wrap h2 {
    margin-top: 0;
}

.temp-wrap .btn {
    display: inline-block;
    color: #ffffff;
    background-color: #000000;
    border-width: 1px;
    border-radius: 1rem;
    padding: 7px 50px;
    transition: color 0.2s, background-color 0.2s, border-color 0.2s;
    margin: 10px 0;
    position: relative;
}

.temp-wrap .btn:hover {
    cursor: pointer;
    background-color: #515151;
}

.temp-wrap .btn:disabled {
    background-color: #606060;
    cursor: default;
}

.temp-wrap .btn:focus {
    outline: none;
}

.temp-wrap .btn.btn-white {
    color: #000000;
    background-color: #ffffff;
}

.temp-wrap .btn.btn-white:hover {

}

.temp-wrap .btn .spinner {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    right: 10px;
    opacity: 0;
}

.temp-wrap .btn .spinner.loading {
    opacity: 1;
}

.temp-wrap .btn .spinner img {
    width: 100%;
}

.temp-wrap .border {
    border: 1px solid #919191;
    cursor: pointer;
}

.temp-wrap .border.active {
    border: 2px solid #000000;
}

.temp-wrap .span-tag {
    display: inline-block;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid #686868;
    border-radius: 5px;
    margin-right: 10px;
    cursor: pointer;
}

.temp-wrap .span-tag:hover {
    background-color: #000000;
    color: #ffffff;
}

.temp-wrap .span-tag.no-hover:hover {
    background-color: #5e5e5e;
}

.temp-wrap .span-tag.active {
    background-color: #5e5e5e;
    color: #ffffff;
}

.temp-wrap .SocialMediaShareCount {
    display: inline-block;
}

.left-text {
    text-align: left !important;
}

.block-9-top {
    padding: 25px 0 70px 0;
}

.block-9-top .text-h2 {
    padding-top: 15px;
}

.block-9-top .container {
    max-width: 700px;
    text-align: left;
}

.block-9-top p {
    margin: 0;
}

.block-9-bottom {

}

.block-gradient-gray-reg {
    position: relative;
    width: 100%;
    z-index: 0;
}

.block-gradient-gray-reg:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0) 100%);
    height: 180px;
    width: 1000%;
    z-index: -1;
}

.block-9-bottom .w-700 {
    max-width: 700px;
    margin: 0 auto;
    padding: 60px 0 50px 0;
}

.block-9-bottom .input-wrap {
    margin-bottom: 20px;
}

.block-graph-left img.img-graph-green {
    width: 278px;
}

.block-graph-left img.img-graph-black {
    height: 894px;
}

.block-graph-left img.img-graph-blue {
    height: 823px;
}

.block-graph-left img.active {
    opacity: 1;
}

.shell-select-reg {
    width: 200px !important;
}

.img-right-step {
    position: absolute;
    right: -100px;
}

.color-text-reg-black {
    color: #080808 !important;
    opacity: .8 !important;
}

.color-text-reg-gray {
    color: #000000 !important;
    opacity: .75 !important;
}

.shell-tabs-reg {
    text-align: left !important;
    width: 550px;
}

.shell-tabs-reg .block-button-tab.big {
    margin: 0 12px 12px 0;
}

.shell-tabs-reg .block-button-tab.small {
    margin: 0 6px 6px 0;
}

.shell-input-reg {
    width: 241px;
}

.shell-input-reg .block-new-input {
    height: 59px;
}

.shell-input-reg .block-new-input input {
    height: 41px;
    line-height: normal;
}
.profile-edit-popup-inner-block .block-cart-load-img-reg-expert{
    box-shadow: none!important;
    border-radius: 0px;
}
.block-cart-load-img-reg-expert {
    width: 100%;
    padding: 16px 24px 16px 40px;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    background-color: #FFFFFF;
    border-radius: 6px;
    display: table;
    margin: 0 0 24px 0;
}

.block-left-cart-load-img-reg-expert {
    width: calc(100% - 230px);
    float: left;
}
.block-left-cart-load-img-reg-expert.accelerator-cart-load-img {
    width: calc(100% - 250px);
}

.block-right-cart-load-img-reg-expert {
    float: right;
    width: 230px;
}

.shell-load-img-cart {

}

.block-load-img {
    /*background-color: #ECECEC;*/
    border-radius: 6px;
    height: 111px;
    width: 111px;
    position: relative;
    margin: 0 auto;
    cursor: pointer;
    display: table;
}

.block-load-img.big {
    height: 111px;
    width: 230px;
}

.block-load-img .load-img {
    height: 111px;
    width: 111px;
    border-radius: 6px;
}

.block-load-img.big .load-img {
    height: 111px;
    width: 230px;
    border-radius: 6px;
}

.block-load-img .bgc-img-3 {
    height: 111px;
    width: 230px;
    background-size: cover !important;
}

.logo-load-img {
    height: 42px;
    width: 42px;
    margin: 20px auto 0 auto;
    display: block;
}

.block-button-load-img {
    height: 25px;
    display: inline-block;
    margin: 0 auto;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.07);
    position: absolute;
    top: 80px;
    width: 95px;
    left: calc((100% - 95px) / 2);
}

.block-button-load-img p.title-button-load-img {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 11px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    margin: 0;
    padding: 0 18px;
}

.title-load-img {
    color: #979797;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin: 7px 0 0 0;
}

.block-list-cart-choose {
    width: 100%;
    display: table;
    margin: 90px 0 0 0;
}

.block-cart-choose {
    width: 260px;
    height: 310px;
    padding: 10px 24px 32px 24px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    transition: .3s;
    cursor: pointer;
    position: relative;

    display: inline-block!important;
    vertical-align: top;
    margin: 0 20px 20px 0!important;
    /*float: none!important;*/
}

.block-cart-choose:hover {
    box-shadow: -7px 12px 24px 0 rgba(0, 0, 0, 0.1);
}

.block-cart-choose.center {
    margin: 0 auto;
    display: inline-block
}

.block-cart-choose.left {
    float: left
}

.block-cart-choose.right {
    float: right
}

.block-cart-choose .block-top-cart-choose {
    height: 24px;
}

.block-cart-choose .block-top-cart-choose .block-check-box-blue {
    margin: 0 0 0 auto;
}

.block-cart-choose .block-body-cart-choose {
    width: 203px;

}

.block-cart-choose .block-body-cart-choose img {
    width: 64px;
    height: 64px;
    display: block;
    margin: 0 auto 20px auto;
}

.block-cart-choose .block-body-cart-choose p.text-h4 {
    margin: 0 0 12px 0;
    color: rgba(8, 8, 8, 0.8);
}

.block-cart-choose .block-body-cart-choose p.text-sub-title {
    margin: 0 0 16px 0;
    color: rgba(0, 0, 0, 0.75);
}

.block-cart-choose .block-body-cart-choose p.text-caption-2 {
    color: #596377;
}

.block-cart-choose .block-button-3-0 {
    position: absolute;
    bottom: 32px;
    left: 50px;
}

.block-details {
    display: inline-block;
    height: 48px;
    padding: 0 30px;
}

.block-details p.text-button {
    line-height: 48px;
    color: rgba(0, 0, 0, 0.26);
    margin: 0;
}

.title-top-page-reg-expert {
    color: rgba(0, 0, 0, 0.8);
}

.block-graph-left {
    width: 300px;
    height: 850px;
    position: absolute;
    left: 0;
    top: 20px;
    overflow: hidden;
}

.block-graph-left img {
    position: absolute;
    width: 250px;
    height: 850px;
    top: 0;
    left: 0;
    transition: .5s;
    opacity: 0;
}

.block-graph-left img.img-graph-gray {
    opacity: 1;
}

.block-graph-left img.img-graph-green {
    width: 278px;
}

.block-graph-left img.img-graph-black {
    height: 894px;
}

.block-graph-left img.img-graph-blue {
    height: 823px;
}

.block-graph-left img.active {
    opacity: 1;
}

.shell-select-reg {
    width: 191px;
}
.shell-select-reg.select-z-to-top .block-select{
    z-index: 10;
}

.img-right-step {
    position: absolute;
    right: -100px;
}

.shell-tabs-reg {
    text-align: left !important;
    width: 550px;
}

.shell-tabs-reg .block-button-tab.big {
    margin: 0 12px 12px 0;
}

.shell-tabs-reg .block-button-tab.small {
    margin: 0 6px 6px 0;
}

.shell-input-reg {
    width: 241px;
}

.shell-input-reg .block-new-input {
    height: 59px;
}

.shell-input-reg .block-new-input input {
    height: 41px;
    line-height: normal;
}

/*.shell-tabs-reg{*/
/*text-align: left!important;*/
/*width: 550px;*/
/*}*/
/*.shell-tabs-reg .block-button-tab.big{*/
/*margin: 0 12px 12px 0;*/
/*}*/
/*.shell-tabs-reg .block-button-tab.small{*/
/*margin: 0 6px 6px 0;*/
/*}*/
/*.shell-input-reg{*/
/*width: 241px;*/
/*}*/
/*.shell-input-reg .block-new-input{*/
/*height: 59px;*/
/*}*/
/*.shell-input-reg .block-new-input input{*/
/*height: 41px;*/
/*line-height: 41px;*/
/*}*/

.shell-input-height-40 {
}

.shell-input-height-40 .block-new-input {
    height: 59px;
}

.shell-input-height-40 .block-new-input input {
    height: 41px;
    line-height: normal;
}

.block-prof-ide {
    width: 100%;
    display: table;
}

.block-left-prof-ide {
    width: 463px;
    float: left;
}

.block-right-prof-ide {
    width: 240px;
    float: right;
    padding: 90px 0 0 0;
}

.color-text-reg-white-gray {
    opacity: 0.5 !important;
    color: #000000 !important;
}

.block-person-reg-expert {
    width: 100%;
    display: inline-block;
}

.block-left-person-reg-expert {
    height: 56px;
    width: 73px;
    float: left;
}

.block-left-person-reg-expert img {
    height: 56px !important;
    width: 56px !important;
    border-radius: 4px;
    display: block;
}

.block-right-person-reg-expert {
    float: right;
    width: calc(100% - 73px);
}

.block-right-person-reg-expert p.text-h6 {
    white-space: nowrap; /* Отменяем перенос текста */
    overflow: hidden; /* Обрезаем содержимое */
    text-overflow: ellipsis; /* Многоточие */
    width: 100%;
}

.block-right-person-reg-expert p.text-button {
    height: 40px;
    overflow: hidden;
    line-height: 16px;
}

.block-list-tabs {
    width: 100%;
    text-align: left !important;
}

.block-list-tabs .block-button-tab {
    margin: 0 12px 12px 0;
}

.block-list-tabs .block-button-tab p {
    padding: 0 17px !important;
}

.block-list-tabs .block-button-tab:nth-child(1) p {
    padding: 0 20px !important;
}

.block-list-tabs .block-button-tab:nth-child(2) p {
    padding: 0 20px !important;
}

.block-list-tabs .block-button-tab:nth-child(3) p {
    padding: 0 22px !important;
}

.block-list-tabs .block-button-tab:nth-child(4) p {
    padding: 0 20px !important;
}
.member-links {
    margin-left: 120px;
    max-width: 350px;
}
@media (max-width: 620px) {
    .member-links {
        margin-left: 0;
    }
}

.block-gradient {
    position: absolute;
    height: 180px;
    width: 100%;
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0) 100%);
    left: 0;
    top: 520px;
}
.title-block-button-reg-expert {
    color: rgba(0, 0, 0, 0.5);
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 17px;
    text-align: right !important;
    padding: 7px 0;
    margin: 0;
    vertical-align: top;
}

.block-list-category {
    text-align: left !important;
}

.block-list-category .block-button-categorie {
    display: inline-block;
    width: auto;
    padding: 0 65px 0 0;
    margin: 0 12px 12px 0;
}

.block-list-button-right {
    width: 100%;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 0 100px 0 rgba(0, 0, 0, 0.05), -1px 5px 14px 0 rgba(0, 0, 0, 0.1);
    padding: 16px 16px 16px 32px;
    display: table;
}

.block-left-list-button-right {
    float: left;
}

.block-left-list-button-right p.title {
    opacity: 0.75;
    color: #000000;
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    margin: 4px 0;
    text-align: left !important;
}

.block-right-list-button-right {
    float: right;
}

.block-right-list-button-right .block-button-3-0 {
    margin: 0 0 0 16px;
}


.block-item-cart-activity-reg-expert {
    cursor: pointer;
    width: 100%;
    border: 1px solid rgba(151, 151, 151, 0.2);
    border-radius: 6px;
    background-color: #FFFFFF;
    /*box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.07);*/
    height: 130px;
    padding: 12px 14px 14px 14px;
    margin: 0 0 16px 0;
    transition: .3s;
}

.block-button-circle-title {
    width: 130px;
    position: absolute;
    top: 8px;
    left: 0;
    height: 30px;
    display: table;
    cursor: pointer;
    z-index: 10;
}

.block-button-circle {
    height: 30px;
    width: 30px;
    border-radius: 30px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    float: left;
}

.block-button-circle i {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 18px;
}

.block-button-circle-title p.title {
    color: rgba(0, 0, 0, 0.3);
    font-family: "Stratos LC";
    font-size: 12px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    margin: 0 0 0 13px;
    float: left;
}

.block-list-tabs-counter-reg-expert {
    width: 100%;
    display: table;
    text-align: left !important;
    margin: 0 0 24px 0;
}

.block-list-tabs-counter-reg-expert .block-button-tab-counter {
    margin: 0 6px 6px 0;
}

.block-form-add-edit-modal-reg-expert {
    width: 100%;
    display: table;
    padding: 24px;
    box-shadow: -1px 5px 14px 0 rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    background-color: #FFFFFF;
    margin-bottom: 30px;
}

.block-input-item-form-add-edit-modal-reg-expert {
    width: 100%;
    display: table;
    margin: 0;
}

.block-input-item-form-add-edit-modal-reg-expert .block-new-input {
    height: 59px;
}

.block-input-item-form-add-edit-modal-reg-expert .block-new-input input {
    height: 41px;
    line-height: normal;
}

.block-input-item-form-add-edit-modal-reg-expert p.title {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    text-align: left !important;
}

.block-text-area-form-add-edit-modal-reg-expert {
    width: 100%;
    display: table;
    margin: 0 0 16px 0;
}

.block-text-area-form-add-edit-modal-reg-expert p.title {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    text-align: left !important;
}

.block-text-area-form-add-edit-modal-reg-expert textarea {
    border-radius: 4px;
    background-color: #FFFFFF;
    overflow: hidden;
    resize: none;
    height: 82px;
    width: 100%;
    color: #000000;
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    outline: none;
    transition: .3s;
    border: 1px solid rgba(151, 151, 151, 0.5);
    font-weight: 300;
}



.block-text-area-form-add-edit-modal-reg-expert textarea:focus {
    border: 1px solid #979797;
}

.block-text-area-form-add-edit-modal-reg-expert textarea {
}

.block-button-form-add-edit-modal-reg-expert {
    text-align: left !important;
}



.block-button-form-add-edit-modal-reg-expert .button-delete {
    float: right;
    position: relative;
    top: 20px;
    color: #E43A70;
    cursor: pointer;
    margin: 0;
    font-size: 14px;
    text-decoration: underline;
}

.block-list-active-activity-reg-expert {
    width: 100%;
    display: block;
}

.block-button-list-active-activity-reg-expert {
    text-align: left !important;
    margin: 0 0 12px 0;
}

.block-list-item-active-activity-reg-expert {
    width: 100%;
    display: block;
}

.block-item-active-activity-reg-expert {
    width: 100%;
    height: 54px;
    display: block;
    padding: 10px 12px;

    border: 1px solid rgba(151, 151, 151, 0.2);
    border-radius: 6px;
    background-color: #FFFFFF;

    margin: 0 0 12px 0;
}

.block-left-item-active-activity-reg-expert {
    width: calc(100% - 43px);
    float: left;
    height: 31px;
}

.block-left-item-active-activity-reg-expert p.title {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    margin: 0;

    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
}

.block-left-item-active-activity-reg-expert p.description {
    color: #596377;
    font-family: "Stratos LC";
    font-size: 11px;
    line-height: 13px;
    text-align: left;
    margin: 0;
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
}

.block-right-item-active-activity-reg-expert {
    width: 43px;
    float: right;
    height: 31px;
}

.block-right-item-active-activity-reg-expert p.button-edit {
    color: #525252;
    font-family: "Stratos LC";
    font-size: 11px;
    font-weight: 500;
    line-height: 31px;
    text-align: center;
    width: 43px;
    margin: 0;
    cursor: pointer;
}

.block-list-check-box-reg-expert {
    width: 100%;
    display: table;
}

.block-top-list-check-box-reg-expert {
    width: 100%;
    display: table;
    margin: 0 0 30px 0;
}

.block-body-list-check-box-reg-expert {
    width: 100%;
    display: table;
}

.block-left-top-list-check-box-reg-expert {
    width: 50%;
    float: left;
}

.block-right-top-list-check-box-reg-expert {
    width: 50%;
    float: right;
}

.block-item-check-box-double-title {
    width: 100%;
    display: block;
    cursor: pointer;
}

.block-left-item-check-box-double-title {
    width: 35px;
    float: left;
}

.block-right-item-check-box-double-title {
    width: calc(100% - 35px);
    float: right;
}

.block-right-item-check-box-double-title p.title {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 0 3px 0;
    text-align: left;
}

.block-right-item-check-box-double-title p.description {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin: 0;
    text-align: left;
}

.block-img-right {
    position: absolute;
    right: -25px;
    top: 150px;
}

.block-img-right img {
}

.block-list-button-check-box-reg-expert {
    width: 100%;
    display: table;
}

.block-item-button-check-box-reg-expert {
    width: 100%;
    height: 71px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: -1px 5px 14px 0 rgba(0, 0, 0, 0.1);
    padding: 24px;
    position: relative;
    margin: 0 0 32px 0;
    cursor: pointer;
}

.block-left-item-button-check-box-reg-expert {
    width: 42px;
    height: 24px;
    float: left;
}

.block-right-item-button-check-box-reg-expert {
    width: calc(100% - 42px);
    float: left;
}

.block-right-item-button-check-box-reg-expert p.title {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
    text-align: left !important;
}

.block-item-button-check-box-reg-expert a {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
}

.block-item-button-check-box-reg-expert a i {
    color: #D0D0D0;
}

.cont-metamask .cm-text-title {
    text-align: left;
    margin-bottom: 15px;
    font-size: 28px;
    font-weight: 600;
}

.cont-metamask .description-page-reg-expert {
    margin-bottom: 25px;
}

.cont-metamask .shell-input-reg {
    width: 425px;
}

.block-button-circle-title.fox-cont {
    width: 100px;
    padding-left: 30px;
}

.step-17 .row.row-flex {
    margin: 30px -15px 60px -15px;
}

.step-17 .row.row-flex .col-4 {
    text-align: left;
}

.step-17 .row.row-flex .col-4 .text-h4 {
    margin: 0 0 8px 0;
    font-size: 20px;
}

.step-17 .row.row-flex .col-4 .text-desc-2 {
    margin: 0;
    font-size: 14px;
    color: rgba(8, 8, 8, 0.8);
}

.step-17 .shell-margin-bottom-20 {
    margin-bottom: 50px;
}

.block-grad-17 {
    top: 430px;
}

.img-16-step-reg-expert {
    position: absolute;
    right: 0;
    top: 250px;
}

.img-18-step-reg-expert {
    position: absolute;
    right: 0;
    top: 180px;
}

.step-3 .title-top-page-reg-expert {
    color: #000 !important;
    max-width: 644px;
}

.step-3-img img {
    width: 100%;
}

.container-step3-btm {
    top: -100px;
    padding: 0px 0 60px 0;
}

.container-step-6 .bottom-content {
    padding-top: 120px;
    width: 100%;
}

.container-step-6 .bottom-content .bc-1 {
    width: 100px;
    text-align: left;
    float: left;
}

.container-step-6 .bottom-content .bc-1 img {
    width: 60px;
}

.container-step-6 .bottom-content .bc-2 {
    width: calc(100% - 100px);
    text-align: left;
    float: left;
}

.container-step-6 .bottom-content .bc-2 .bc-2-1 {
    width: calc(100% - 100px);
    float: left;
}

.container-step-6 .bottom-content .bc-2 .bc-2-2 {
    width: 60px;
    float: right;
}
.container-step-6 .bottom-content .bc21-title{
    /*display: flex;*/
    width: 100%;
}
.container-step-6 .bottom-content .bc21-title .bc-2-1 {
    font-size: 20px;
    padding-bottom: 10px;
}

.container-step-6 .bottom-content .bc21-title .bc-2-2 {
    font-size: 18px;
    font-weight: 500;
    text-align: right;
}
.container-step-6 .bottom-content .bc21-description{
    display: table;
    width: 100%;
}
.container-step-6 .bottom-content .bc21-description .bc-2-1 {
    font-size: 16px;
    color: rgba(8, 8, 8, 0.8);
    padding-bottom: 10px;
}

.container-step-6 .bottom-content .bc21-description .bc-2-2 {
    font-size: 18px;
    color: #0ECFAA;
    font-weight: 500;
    text-align: right;
}

.container-step-6 .bottom-content .clearfix {
    padding-bottom: 40px;
}

.block-button-user-img-blue {
    width: auto;
    display: inline-block;
    height: 48px;
    border-radius: 6px;
    background-color: #2CA6EB;
    box-shadow: 0 8px 19px 0 rgba(32, 165, 238, 0.15);
    position: relative;
    padding: 0 37px 0 64px;
    position: relative;
    transition: .3s;
    cursor: pointer;
}

.block-button-user-img-blue:hover {
    opacity: 0.8;
}

.block-button-user-img-blue img {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 14px;
    left: 26px;
}

.block-button-user-img-blue p.title-button {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 16px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;
    margin: 0;
    width: 100%;
}

.shell-button-reg-expert {
    width: 100%;
    text-align: left !important;
}

.step-21-btm {
    padding-top: 70px;
}

.step-21-btm .s21-left {
    width: 40%;
    float: left;
}

.step-21-btm .s21-right {
    width: 60%;
    float: left;
    padding-left: 50px;
}

.step-21-btm .s21-left.inv-succ {
    width: 60%;
}

.step-21-btm .s21-right.inv-succ {
    width: 40%;
    padding-left: 0;
}

.step-21-btm .s21-right img {
    width: 100%;
}

.step-21-btm .title-top-page-reg-expert {
    margin-bottom: 0;
    margin-top: 0;
}

.step-21-btm .title-top-page-reg-expert.mb {
    margin-bottom: 10px;
}

.step-21-btm .text-button-3 {
    margin-top: 5px;
}

.step-21-btm .text-button-3 span {
    color: #0ECFAA;
}

.step-21-btm .block-input-item-form-add-edit-modal-reg-expert {
    padding-top: 25px;
}

.step-21-btm .block-input-item-form-add-edit-modal-reg-expert .title {
    font-weight: 500;
}

.step-21-btm .block-new-input {
    width: 260px;
    float: left;
}

.step-21-btm .block-button-3-0 {
    height: 41px;
    float: right;
}

.step-21-btm .block-button-3-0 p {
    line-height: 41px !important;
}

.item-email-sent {
    color: #525252;
    font-size: 14px;
    height: 23px;
    line-height: 23px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 0 11px;
    margin: 5px 5px 5px 0;
    display: inline;
    float: left;
}

.img-19-step-reg-expert {
    position: absolute;
    right: 0;
    top: 160px;
}

.block-button-big-icon {
    height: 60px;
    border-radius: 6px;
    background-color: #2CA6EB;
    box-shadow: 0 8px 19px 0 rgba(32, 165, 238, 0.15);
    display: inline-block;
    padding: 0 50px;
    cursor: pointer;
    transition: .3s;
}

.block-button-big-icon:hover {
    opacity: 0.8;
}

.block-button-big-icon p.title {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    text-align: center;
}

.block-button-big-icon p.title img {
    display: inline-block;
    height: 18px;
    width: 17px;
    margin: 0 5px 0 0;
    position: relative;
    top: 2px;
}

.shell-button-bottom-step-22 {
    text-align: center;
    padding: 200px 0 60px 0;
}

.block-img-step-22 {
    position: absolute;
    right: 150px;
    top: 220px;
}

.block-cart-kyc-reg-expert {
    width: 100%;
    display: table;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    padding: 24px 40px 32px 40px;
    margin: 0 0 24px 0;
}

.title-cart-kyc-reg-expert {
    display: block;
    width: 100%;
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 28px;
    font-weight: 600;
    line-height: 35px;
    margin: 0 0 24px 0;
    text-align: left !important;
}

.block-left-cart-kyc-reg-expert {
    width: calc(100% - 320px);
    float: left;
}

.block-right-cart-kyc-reg-expert {
    width: 220px;
    float: right;
}

.block-line-left-cart-kyc-reg-expert {
    width: 100%;
    display: table;
    height: 85px;
}

.block-left-left-cart-kyc-reg-expert {
    width: 210px;
    float: left;
}

.block-right-left-cart-kyc-reg-expert {
    width: calc(100% - 222px);
    float: right;
}

.shell-input-kyc {
    width: 100%;
    display: table;
}

.shell-input-kyc p.title-input-kyc {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 4px 0;
    text-align: left !important;
}

.shell-input-kyc .block-new-input {
    height: 59px;
}

.shell-input-kyc .block-new-input input {
    height: 41px;
    line-height: normal;
}

.block-date-input {
    width: 100%;
    display: table;

}

.block-date-input p.title-input-kyc {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 4px 0;
    width: 100%;
    display: block;
    text-align: left !important;
}

.block-date-input .block-new-input {
    height: 59px;
}

.block-date-input .block-new-input input {
    height: 41px;
    line-height: normal;
}

.block-date-input .block-year-date-input {
    width: calc((100% / 3) - (16px / 3));
    float: left;
    margin: 0 8px 0 0;
}

.block-date-input .block-month-date-input {
    width: calc((100% / 3) - (16px / 3));
    float: left;
    margin: 0 8px 0 0;
}

.block-date-input .block-day-date-input {
    width: calc((100% / 3) - (16px / 3));
    float: left;
    margin: 0;
}

.shell-select-kyc {
    width: 100%;
    display: table;
}

.shell-select-kyc p.title-input-kyc {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 4px 0;
    width: 100%;
    display: block;
    text-align: left !important;
}

.shell-select-kyc .block-select {
    height: 55px;
}

.shell-select-kyc .block-top-select {
    height: 41px;
}

.shell-select-kyc .block-top-select p {
    height: 41px;
    line-height: 41px;
}

.shell-select-kyc .block-top-select input {
    height: 41px;
    line-height: 41px;
}

.shell-select-kyc .block-top-select i {
    top: 12px;
}

.block-load-img-kyc {
    width: 100%;
    display: table;
}

.block-load-img-kyc p.title-input-kyc {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 4px 0;
    width: 100%;
    display: block;
    text-align: left !important;
}

.block-load-img-kyc p.description-input-kyc {
    color: rgba(0, 0, 0, 0.5);
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 15px;
    margin: 0 0 12px 0;
    width: 100%;
    display: block;
    text-align: left !important;
}

.shell-text-area-kyc {
    width: 100%;
    display: table;
}

.shell-text-area-kyc p.title-input-kyc {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 4px 0;
    width: 100%;
    display: block;
    text-align: left !important;
}

.shell-text-area-kyc textarea {
    border-radius: 4px;
    background-color: #FFFFFF;
    overflow: hidden;
    resize: none;
    height: 72px;
    width: 100%;
    color: #000000;
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px;
    outline: none;
    transition: .3s;
    border: 1px solid rgba(151, 151, 151, 0.5);
}

.shell-text-area-kyc textarea:focus {
    border: 1px solid #979797;
}

.block-list-img-load-kyc {
    width: 100%;
    display: table;
    margin: 0 0 12px 0;
    text-align: left;
}

.block-img-load-kyc {
    height: 39px;
    width: 39px;
    border-radius: 4px;
    background-color: #EAEAEA;
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    display: inline-block;
    margin: 0 6px 6px 0;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}

.block-img-load-kyc:after {
    content: '';
    height: 39px;
    width: 39px;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    transition: .3s;
    top: 0;
    left: 0;
    z-index: 1;
}

.block-img-load-kyc:hover:after {
    background-color: rgba(0, 0, 0, 0.6);
}

.block-img-load-kyc i {
    color: #FFFFFF;
    font-size: 22px;
    height: 39px;
    width: 39px;
    line-height: 39px;
    text-align: center;
    position: relative;
    z-index: 2;
    opacity: 0;
    transition: .3s;
}

.block-img-load-kyc:hover i {
    opacity: 1;
}

.block-for-social-btn {
    text-align: left;
}

.block-for-social-btn button[type="button"] {
    background-color: inherit;
    border: none;
    padding: 0;
}

.block-for-social-btn button[type="button"]:focus {
    outline: none;
}

.step-15 #telegram-button {
    background: #54a9eb;
    padding-top: 0;
    margin-top: 10px;
    border-radius: 6px;
}

/*.step-15 #telegram-button iframe {*/
/*width: 100%;*/
/*}*/
.block-button-big-reg-expert {
    width: 100%;
    display: inline-block;
    height: 60px;
    border-radius: 6px;
    background-color: #2CA6EB;
    box-shadow: 0 8px 19px 0 rgba(32, 165, 238, 0.15);
    transition: .3s;
    cursor: pointer;
    z-index: 9999;
    position: relative;
}

.block-button-big-reg-expert p.title {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 18px;
    font-weight: 500;
    line-height: 60px;
    text-align: center;
    margin: 0;
}

.block-button-big-reg-expert:hover {
    opacity: 0.8;
}

.block-add-activity-reg-exp {
    margin-bottom: 15px;
    text-align: left;
}

.div-flex {

}

.block-list-cart-start-reg {
    width: 100%;
    display: table;
    text-align: center;
}

.block-cont-investor {
}

.block-cont-investor .block-item-cart-start-reg {
    width: 240px;
}

.block-cont-investor .div-flex {
    margin: 0 30px;
}

.block-top-graph-step-2 {
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    position: sticky;
    top: 0;
}

.block-progress-graph-step-2 {
    width: 100%;
    height: 40px;
}

.block-step-graph-step-2 {
    height: 40px;
    float: left;
    background: #CDD2DD;
    transition: .3s;
    position: relative;
}

.block-step-graph-step-2:before {
    content: '';
    position: absolute;
    height: 40px;
    width: 0%;
    transition: .6s;
    top: 0;
    left: 0;
    z-index: 0;
}

.block-step-graph-step-2.img-graph-blue.active:before {
    background: #2CA6EB;
    width: 100%;
}

.block-step-graph-step-2.img-graph-green.active:before {
    background: #0ECFAA;
    width: 100%;
}

.block-step-graph-step-2.img-graph-black.active:before {
    background: #2F3849;
    width: 100%;
}

.block-step-graph-step-2 p.title-progress {
    font-family: "Stratos LC";
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    font-weight: 500;
    margin: 0;
    text-align: center;
    color: #FFFFFF;
    position: relative;
    z-index: 1;
}

.block-list-cart-state-reg-expert {
    width: 100%;
    display: table;
    text-align: center;
}

.block-item-cart-state-reg-expert {
    width: 200px;
    display: inline-block;
    text-align: center;
    padding: 45px 10px 10px 10px;
    background-color: #fff;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    cursor: pointer;
    box-sizing: border-box;
}

.block-item-cart-state-reg-expert:hover {
    box-shadow: -7px 12px 24px 0 rgba(0, 0, 0, 0.06);
}

.block-item-cart-state-reg-expert img {
    width: 55px;
    height: 55px;
    transition: .3s;
    margin: 0 auto;
}

.block-item-cart-state-reg-expert .text-h4 {
    margin-top: 20px;
    margin-bottom: 14px;
    color: rgba(8, 8, 8, 0.8);
}

.block-item-cart-state-reg-expert .text-desc-2 {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.75);
    font-weight: 500;
    margin: 0;
}

.block-item-cart-state-reg-expert .text-btm {
    font-size: 12px;
    color: #596377;
    margin-top: 30px;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.startup-cart-start-reg {
    padding: 71px 0 0 0;
    z-index: 1;
    position: relative;
}

.block-list-items-input {
    max-width: 413px;
    margin: 0;
}

.block-item-input-reg-startup {
    margin: 0 0 22px 0;
}

.block-item-input-reg-startup .block-new-input {
    height: 59px;
}

.block-item-input-reg-startup .block-new-input input {
    height: 41px;
    line-height: normal;
}

.block-item-input-reg-startup .block-select {
    width: 190px;
}

.block-list-check-box-reg-startup {
    width: 100%;
    display: table;
    margin: 20px 0 0 0;
}

.block-list-check-box-reg-startup.bl-fix-height {
    min-height: 480px;
}

.block-item-check-box-reg-startup {
    margin: 0 0 8px 0;
    cursor: pointer;
    display: table;
}

.block-left-check-box-reg-startup {
    width: 30px;
    height: 20px;
    padding: 1px 0;
    float: left;
}

.block-right-check-box-reg-startup {
    width: calc(100% - 30px);
    float: left;
}

.block-list-cart-reg-startup {
    width: 100%;
}

.block-item-cart-reg-startup {
    height: 310px;
    width: 260px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    overflow: hidden;
    transition: .3s;
    position: relative;
    cursor: pointer;
    border: 2px solid #FFFFFF;
}

.block-item-cart-reg-startup.active {
    border: 2px solid #2CA6EB;
    box-shadow: -7px 12px 24px 0 rgba(0, 0, 0, 0.1);
}

.block-item-cart-reg-startup .block-checkbox-front-cart {
    display: none;
}

.block-item-cart-reg-startup.active .block-checkbox-front-cart {
    display: block;
    position: absolute;
    width: 23px;
    height: 23px;
    right: 15px;
    top: 15px;
}

.block-item-cart-reg-startup:hover {
    box-shadow: -7px 12px 24px 0 rgba(0, 0, 0, 0.1);
}

.block-item-cart-reg-startup p.title-front-cart {
    margin: 75px 0 0 0;
    width: 100%;
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 38px;
    font-weight: 600;
    text-align: center;
    transition: .3s;
    position: relative;
}

.block-item-cart-reg-startup:hover p.title-front-cart {
    margin: 35px 0 0 0;
}

.block-item-cart-reg-startup p.description-front-cart {
    width: 100%;
    margin: 5px 0 0 0;
    color: rgba(0, 0, 0, 0.75);
    font-family: "Stratos LC";
    font-size: 14px;
    text-align: center;
    transition: .3s;
    position: relative;
    left: 0;
}

.block-item-cart-reg-startup:hover p.description-front-cart {
    left: -101%;
}

.block-item-cart-reg-startup p.description-back-cart {
    color: #596377;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    font-weight: 300;
    margin: 0;
    transition: .3s;
    position: relative;
    right: -101%;
    padding: 0 24px;
}

.block-item-cart-reg-startup:hover p.description-back-cart {
    right: 0;
}

.block-item-cart-reg-startup .block-button-cart {
    height: 40px;
    width: 160px;
    border-radius: 6px;
    border: 2px solid #CDD2DD;
    box-sizing: border-box;
    box-shadow: 0 8px 19px 0 rgba(32, 165, 238, 0.15);
    position: absolute;
    bottom: 32px;
    left: calc((100% - 160px) / 2);
    transition: .3s;
}

.block-item-cart-reg-startup:hover .block-button-cart {
    background-color: #2CA6EB;
    border: 2px solid #2CA6EB;
}

.block-item-cart-reg-startup:hover .block-button-cart p.title-button-cart {
    color: #FFFFFF;
}

.block-item-cart-reg-startup.active .block-button-cart {
    background-color: #ECECEC;
    border: 2px solid #ECECEC;
}

.block-item-cart-reg-startup.active .block-button-cart p.title-button-cart {
    color: #525252;
}

.block-item-cart-reg-startup .block-button-cart p.title-button-cart {
    color: #8A92A3;
    font-family: "Stratos LC";
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    width: 100%;
    margin: 0;
    transition: .3s;
}

.block-list-cart-round-reg-startup {
    width: 100%;
}

.block-item-cart-round-reg-startup {
    height: 300px;
    width: 220px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    position: relative;
    overflow: hidden;
    transition: .3s;
    border: 2px solid transparent;
    cursor: pointer;
}
.block-item-cart-round-reg-accelerator {
    height: 350px;
    padding: 19px 22px 28px 22px;
    text-align: left;
}
.block-item-cart-round-reg-accelerator .accelerator-main-title {
    font-size: 25px;
    line-height: 31px;
    letter-spacing: -0.505952px;
    margin-bottom: 27px;
}
.block-item-cart-round-reg-accelerator .description-accelerator-cart {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.505952px;
}
.block-item-choice-role {
    height: 310px;
    text-align: center;
}
.block-item-choice-role .choice-role-main-title {
    font-size: 24px;
    line-height: 25px;
    margin: 68px 0 13px 0;
}
.block-item-choice-role .choice-role-descr {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
}


.block-item-cart-round-reg-startup.bic-bm {
    width: 292px;
    height: 172px;
    margin-bottom: 30px;
}

.block-item-cart-round-reg-startup.bic-bm .block-checkbox-front-cart {
    display: none;
    position: absolute;
    right: 15px;
    top: 15px;
}

.block-item-cart-round-reg-startup.bic-bm.active .block-checkbox-front-cart {
    display: block;
}

.block-item-cart-round-reg-startup p.title-front-cart {
    margin: 75px 0 0 0;
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 28px;
    font-weight: 600;
    text-align: center;
    transition: .3s;
}

.block-item-cart-round-reg-startup.bic-bm p.title-front-cart {
    position: absolute;
    left: calc((100% - 48px) / 2);
    margin: 0;
    top: 35px;
}

.block-item-cart-round-reg-startup p.description-front-cart {
    color: rgba(0, 0, 0, 0.75);
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin: 0;
    transition: .3s;
    position: relative;
    left: 0;
}

.block-item-cart-round-reg-startup.bic-bm p.description-front-cart {
    position: absolute;
    left: calc((100% - 147px) / 2);
    margin: 0;
    top: 72px;
}

.block-item-cart-round-reg-startup p.description-back-cart {
    color: #596377;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin: 0;
    transition: .3s;
    font-weight: 300;
    padding: 0 22px;
    position: relative;
    right: -101%;
    top: 25px;
}

.block-item-cart-round-reg-startup .block-button-cart {
    height: 40px;
    width: 156px;
    border: 2px solid #CDD2DD;
    border-radius: 6px;
    /*box-shadow: 0 8px 19px 0 rgba(32, 165, 238, 0.15);*/
    box-sizing: border-box;
    transition: .3s;
    position: absolute;
    left: calc((100% - 156px) / 2);
    bottom: 32px;
}

.block-item-cart-round-reg-startup.bic-bm .block-button-cart {
    bottom: 25px;
}

.block-item-cart-round-reg-startup .block-button-cart p.title-button-cart {
    color: #8A92A3;
    font-family: "Stratos LC";
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    margin: 0;
    transition: .3s;
}

.block-item-cart-round-reg-startup:hover {
}

.block-item-cart-round-reg-startup:hover p.title-front-cart {
    margin: 32px 0 0 0;
}

.block-item-cart-round-reg-startup:hover p.description-front-cart {
    left: -101%;
}

.block-item-cart-round-reg-startup.bic-bm:not(.active):hover p.title-front-cart {
    left: -101%;
}

.block-item-cart-round-reg-startup.bic-bm.active:hover p.description-back-cart {
    right: -101%;
}

.block-item-cart-round-reg-startup.bic-bm.active:hover p.description-front-cart {
    left: calc((100% - 147px) / 2);
}

.block-item-cart-round-reg-startup:hover p.description-back-cart {
    right: 0;
}

.block-item-cart-round-reg-startup.bic-bm.active:hover p.title-front-cart {
    margin: 0;
}

.block-item-cart-round-reg-startup.bic-bm:not(.active):hover p.description-back-cart {
    top: 25px;
    position: relative;
    right: 0;
}

.block-item-cart-round-reg-startup:hover .block-button-cart {
    border: 2px solid #2CA6EB;
    background-color: #2CA6EB;
    box-shadow: 0 8px 19px 0 rgba(32, 165, 238, 0.15);
}

.block-item-cart-round-reg-startup:hover .block-button-cart p.title-button-cart {
    color: #FFFFFF;
}

.block-item-cart-round-reg-startup.active {
    box-shadow: -7px 12px 24px 0 rgba(0, 0, 0, 0.1);
    border: 2px solid #2CA6EB;
}

.block-item-cart-round-reg-startup.active .block-button-cart {
    background-color: #ECECEC;
    border: 2px solid #ECECEC;
}

.block-item-cart-round-reg-startup.active .block-button-cart p.title-button-cart {
    color: #525252;
}

.block-input-range {
    width: 100%;
    max-width: 632px;
    position: relative;
    z-index: 100;
}

.block-input-range p.title-input-range {
    width: 100%;
    line-height: 41px;
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 28px;
    font-weight: 600;
    margin: 0;
}

.block-input-range p.title-input-range span.value input {
    border: 1px solid #979797;
    border-radius: 4px;
    background-color: #FFFFFF;
    display: inline-block;
    line-height: 41px;
    width: 150px;
    padding: 0 20px;
    outline: none;
    margin: 0 0 0 20px;
    color: #000000;
    font-family: "Stratos LC";
    font-size: 14px;
    text-align: center;
    font-weight: 400;
}

.block-input-range p.title-left-right-input-range {
    width: 100%;
    opacity: 0.75;
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    display: table;
}

.block-input-range p.title-left-right-input-range span.left {
    float: left;
}

.block-input-range p.title-left-right-input-range span.right {
    float: right;
}

p.title-value-cap {
    width: 100%;
    line-height: 41px;
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 22px;
    font-weight: 600;
    margin: 20px 0 0 0;
}

p.title-value-cap span.value {
    font-weight: 400;
}

.slider-green {
}

.slider-green .rangeslider-horizontal {
    height: 2px;
    background: #979797;
}

.slider-green .rangeslider__fill {
    position: relative;
    background: #0ECFAA;
    height: 8px;
    top: -3px;
}

.slider-green .rangeslider__handle {
    outline: none;
    width: 20px;
    height: 20px;
    border: 0;
    box-shadow: none;
    background-color: #000000;
}

.slider-green .rangeslider__handle:after {
    display: none;
}

.slider-black {
}

.slider-black .rangeslider-horizontal {
    height: 2px;
    background: #979797;
}

.slider-black .rangeslider__fill {
    position: relative;
    background: #383838;
    height: 8px;
    top: -3px;
}

.slider-black .rangeslider__handle {
    outline: none;
    width: 20px;
    height: 20px;
    border: 0;
    box-shadow: none;
    background-color: #000000;
}

.slider-black .rangeslider__handle:after {
    display: none;
}

.block-user-profile-reg-startup {
    width: 100%;
    display: table;
}

.block-left-user-profile-reg-startup {
    width: 45%;
    float: left;
}

.block-right-user-profile-reg-startup {
    width: 45%;
    float: right;
}

.block-user-cart-user-profile-reg-startup {
    width: 100%;
    display: table;
}

.block-left-user-cart-user-profile-reg-startup {
    width: 120px;
    float: left;
}

.block-right-user-cart-user-profile-reg-startup {
    width: calc(100% - 120px);
    float: right;
}

.block-img-user-profile {
    width: 105px;
    height: 105px;
    border-radius: 6px;
    /*background: #0a66b7;*/
    overflow: hidden;
}

.title-user-profile-reg-startup {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin: 0 0 10px 0;
}

.check-box-user-profile-reg-startup {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
    margin: 0;
    cursor: pointer;
}

.check-box-user-profile-reg-startup .block-check-box-blue {
    display: inline-block;
    margin: 0 10px 0 0;
    position: relative;
    top: 5px;
}

.block-select-user-profile-reg-startup {
    margin: 10px 0 0 0;
}

.title-input-user {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    margin: 0 0 20px 0;
}

.block-small-input {
    width: 79px;
}

.bold-input input {
    font-weight: 400;
}

.block-small-input .block-new-input {
    height: 59px;
}

.block-small-input .block-new-input input {
    height: 41px;
    line-height: normal;
}

.block-section-gradient {
    width: 100%;
    display: table;
}

.block-list-input-email {
    width: 100%;
    display: table;
    position: relative;
    z-index: 100;
}

.block-left-list-input-email {
    width: 60%;
    float: left;
}

.block-right-list-input-email {
    width: 40%;
    float: left;
}

.block-input-reg {
    max-width: 300px;
}

.block-input-reg .block-new-input {
    height: 59px;
}

.block-input-reg .block-new-input input {
    height: 41px;
    line-height: normal;
}

.block-input-reg p.title-input-reg {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 4px 0;
}

.block-select-reg {
    width: 300px;
}

.block-select-reg .block-top-select input {
    line-height: 40px;
    height: 40px;
}

.block-select-reg .block-select {
    height: 40px;
}

.block-select-reg .block-top-select {
    height: 41px;
}

.block-select-reg .block-top-select p.title-select {
    height: 40px;
    line-height: 40px;
}

.block-select-reg .block-top-select img {
    top: 15px;
}

.block-select-reg p.title-input-reg {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0 0 4px 0;
}

.block-button-list-input-email {
    margin: 30px 0 0 0;
}

.block-button-list-input-email .block-button-3-0 {
    float: left;
}

.block-button-list-input-email p.check-box-user-profile-reg-startup {
    float: left;
    margin: 6px 0 0 22px;
}

.block-gradient-25 {
    padding: 32px 0 0 0;
    box-sizing: border-box;
    margin: 40px 0 0 0;
}

.block-gradient-25 .block-button-3-0 {
    float: left;
    margin: 0 0 25px 0;
}

.block-add-experience-cart {
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: -1px 5px 14px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 25px;
    box-sizing: border-box;
}

.block-line-add-experience-cart {
    width: 100%;
    display: table;
}

.block-left-add-experience-cart {
    width: calc(100% - 280px);
    float: left;
}

.block-left-add-experience-cart .block-button-3-0 {
    float: left;
    height: 40px;
}

.block-left-add-experience-cart .block-button-3-0 p {
    line-height: 40px !important;
}

.block-right-add-experience-cart {
    width: 230px;
    float: right;
}

.block-right-add-experience-cart .block-button-3-0 {
    float: right;
    height: 40px;
}

.block-right-add-experience-cart .block-button-3-0 p {
    line-height: 40px !important;
}

.block-line-add-experience-cart .shell-select-kyc {
    margin: 20px 0 0 0;
}

.block-input-min {
}

.block-input-min .block-new-input {
    height: 59px;
}

.block-input-min .block-new-input i {
    width: 20px;
    height: 20px;
    font-size: 21px;
    top: 10px;
    left: 14px;
}

.block-input-min.color-icon-black-blue .block-new-input i {
    color: #3B5998;
}

.block-input-min.color-icon-blue .block-new-input i {
    color: #0E76A8;
}

.block-input-min.color-icon-white-blue .block-new-input i {
    color: #55ACEE;
}

.block-input-min .block-new-input input {
    height: 41px;
    line-height: normal;
}

.button-min {
}

.button-min .block-button-3-0 {
    height: 40px;
}

.button-min .block-button-3-0 p {
    height: 40px;
    line-height: 40px !important;
}

@media (min-width: 1200px) {
    .block-item-cart-start-reg {
        display: inline-block;
        vertical-align: top;
        width: 200px;
    }

    .div-flex {
        display: inline-block;
        vertical-align: top;
        margin: 0 60px;
    }

    .startup-cart-start-reg .block-item-cart-start-reg {
        display: inline-block;
        vertical-align: top;
        width: 160px;
    }

    .startup-cart-start-reg .div-flex {
        display: inline-block;
        vertical-align: top;
        margin: 0 20px;
    }

    .startup-cart-start-reg .div-flex span {
        width: 30px !important;
    }

    .block-graph-left {
        display: block;
    }

    .block-top-graph-step-2 {
        display: none;
    }

    .step-2 .block-center {
        width: 660px;
        margin: 25px auto;
        padding-bottom: 50px;
    }

    .block-item-cart-state-reg-expert:nth-child(2n) {
        margin: 0 calc(100% - (200px * 3) - 30px);
    }

    .profile-reg-10-step-2 {

    }

    .profile-reg-10-step-1 {
        padding: 0 0 0 40px;
    }

    .block-item-check-box-reg-startup {
        width: 50%;
        float: left;
    }

    .block-list-cart-reg-startup {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .block-item-cart-reg-startup {

    }

    .block-list-cart-round-reg-startup {
        display: block;
        text-align: center;
    }

    .block-item-cart-round-reg-startup {
        display: inline-block;
        margin: 0 15px 15px 0;
        vertical-align: top;
    }

    .step-20-btm-text {
        font-size: 16px;
        line-height: 21px;
        color: rgba(8, 8, 8, 0.8);
    }
}

@media (max-width: 1199px) {
    .block-item-cart-start-reg {
        display: inline-block;
        vertical-align: top;
        width: 170px;
    }

    .div-flex {
        display: inline-block;
        vertical-align: top;
        margin: 0 15px;
    }

    .startup-cart-start-reg {
        text-align: center;
    }

    .startup-cart-start-reg .block-item-cart-start-reg {
        display: inline-block;
        vertical-align: top;
        width: 200px;
        margin: 0 0 30px 0;
    }

    .startup-cart-start-reg .div-flex {
        display: inline-block;
        vertical-align: top;
        margin: 0 40px;
    }

    .startup-cart-start-reg .div-flex:nth-child(4) {
        display: none;
    }

    .startup-cart-start-reg .div-flex span {
        width: 40px !important;
    }

    .block-graph-left {
        display: none;
    }

    .block-top-graph-step-2 {
        display: block;
    }

    .step-2 .block-center {
        width: 100%;
        margin: 25px auto;
        padding-bottom: 50px;
    }

    .block-item-cart-state-reg-expert:nth-child(2n) {
        margin: 0 calc(100% - (200px * 3) - 55px);
    }

    .block-list-cart-choose {

    }

    .block-cart-choose {
        display: inline-block;
        margin: 0 20px 30px !important;
        float: none !important;
    }

    .img-right-step {
        display: none;
    }

    .shell-tabs-reg {
        width: 100%;
    }

    .profile-reg-10-step-2 {

    }

    .profile-reg-10-step-1 {
        padding: 0 0 0 20px;
    }

    .block-reg-startup-step-2 .img-16-step-reg-expert {
        display: none;
    }

    .block-item-check-box-reg-startup {
        width: 50%;
        float: left;
    }

    .block-list-cart-reg-startup {
        display: block;
        justify-content: space-between;
        flex-wrap: nowrap;
        text-align: center;
    }

    .block-item-cart-reg-startup {
        display: inline-block;
        margin: 0 30px 40px 30px;
    }

    .block-item-cart-reg-startup:nth-child(1) {
        float: left;
    }

    .block-item-cart-reg-startup:nth-child(2) {
        float: right;
    }

    .block-item-cart-reg-startup:nth-child(3) {
    }

    .block-list-cart-round-reg-startup {
        display: block;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
    }

    .block-item-cart-round-reg-startup {
        display: inline-block;
        margin: 0 50px 30px 50px;
    }
}

@media (max-width: 900px) {

    .block-no-net-work-fund {
        width: 100%;
    }

    .block-left-no-net-work-fund {
        width: 100%!important;
        float: none!important;
        height: auto!important;
        display: table!important;
        border: 0!important;
    }
    .block-right-no-net-work-fund {
        width: 100%!important;
        float: none!important;
        padding: 30px 0 0 0!important;
    }


    .block-item-cart-start-reg {
        display: block;
        vertical-align: top;
        width: 200px;
        margin: 0 auto 30px auto;
    }

    .div-flex {
        display: inline-block;
        vertical-align: top;
        margin: 0 auto 30px auto;
    }

    .div-flex span {
        border-top: 0 !important;
        border-left: 3px dashed #979797;
        height: 50px;
        width: 3px !important;
        top: 5px !important;
    }

    .startup-cart-start-reg {
        text-align: center;
    }

    .startup-cart-start-reg .block-item-cart-start-reg {
        display: inline-block;
        vertical-align: top;
        width: 200px;
        margin: 0 0 30px 0;
    }

    .startup-cart-start-reg .div-flex {
        display: inline-block;
        vertical-align: top;
        margin: 0 40px;
    }

    .startup-cart-start-reg .div-flex:nth-child(4) {
        display: none;
    }

    .startup-cart-start-reg .div-flex span {
        width: 40px !important;
        height: 3px !important;
        top: 60px !important;
        font-weight: bold;
        opacity: 0.3 !important;
        border-top: 3px dashed #979797 !important;
        display: inline-block !important;
    }

    .block-item-cart-state-reg-expert {
        margin: 0 auto 30px auto;
        display: block;
    }

    .block-item-cart-state-reg-expert:nth-child(2n) {
        margin: 0 auto 30px auto;
    }

    .block-cart-choose {
        display: block;
        margin: 0 auto 30px auto !important;
        float: none !important;
    }

    .profile-reg-10-step-2 {

    }

    .profile-reg-10-step-1 {
        padding: 0;
    }

    .block-item-check-box-reg-startup {
        width: 50%;
        float: left;
    }

    .block-list-cart-reg-startup {
        display: block;
        justify-content: space-between;
        flex-wrap: nowrap;
        text-align: center;
    }

    .block-item-cart-reg-startup {
        display: inline-block;
        margin: 0 0px 40px 0px;
    }

    .block-item-cart-reg-startup:nth-child(1) {
        float: left;
    }

    .block-item-cart-reg-startup:nth-child(2) {
        float: right;
    }

    .block-item-cart-reg-startup:nth-child(3) {
    }

    .block-list-cart-round-reg-startup {
        display: block;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
    }

    .block-item-cart-round-reg-startup {
        display: inline-block;
        margin: 0 30px 30px 30px;
    }
    .block-right-list-input-email .block-list-mail-more{
        margin-top: 20px;
    }
}
.block-input-net-work-fund{
    width: 100%;
}
.block-input-net-work-fund .shell-input-reg {
    width: 460px;
}

.block-cart-list-activity-reg-expert {
    width: 590px;
    display: table;
}

.block-left-cart-list-activity-reg-expert {
    width: calc(50% - 8px);
    float: left;
}

.block-right-cart-list-activity-reg-expert {
    width: calc(50% - 8px);
    float: right;
}
@media (max-width: 620px) {
    .block-previosu-add-startup {
        width: 100%!important;
        display: table;
    }

    .block-left-previosu-add-startup {
        width: 100%!important;
        float: none!important;
    }

    .block-right-previosu-add-startup {
        width: 100%!important;
        float: none!important;
        padding: 28px 0 0 0;
    }

    .block-item-title-input {
        width: 100%;
        margin: 0 0 30px 0;
        display: table;
    }

    p.title-item-title-input {
        color: rgba(8, 8, 8, 0.8);
        font-family: "Stratos LC";
        font-size: 22px;
        font-weight: 500;
        line-height: 41px;
        margin: 0;
        float: none!important;
        width: 100%!important;
    }

    .block-input-item-title-input {
        width: 105px;
        float: none!important;
    }

    .no-float-620 {
        display: block;
        float: none!important;
        width: 100%!important;
    }
    .block-input-net-work-fund{
        width: 100%;
    }
    .block-input-net-work-fund .shell-input-reg {
        width: 100%;
    }

    .block-cart-list-activity-reg-expert {
        width: 100%;
        display: table;
    }

    .block-left-cart-list-activity-reg-expert {
        width: 100%;
        float: none;
    }

    .block-right-cart-list-activity-reg-expert {
        width: 100%;
        float: none;
    }
    .startup-cart-start-reg {
        text-align: center;
    }

    .startup-cart-start-reg .block-item-cart-start-reg {
        display: block;
        vertical-align: top;
        width: 200px;
        margin: 0 auto;
    }

    .startup-cart-start-reg .div-flex {
        display: block;
        width: 100%;
        vertical-align: top;
        margin: 30px auto;
    }

    .startup-cart-start-reg .div-flex:nth-child(4) {
        display: block;
    }

    .startup-cart-start-reg .div-flex span {
        border-top: 0 !important;
        border-left: 3px dashed #979797 !important;
        height: 50px !important;
        width: 3px !important;
        top: 5px !important;
    }

    .profile-reg-10-step-2 {

    }

    .profile-reg-10-step-1 {
        padding: 0;
    }

    .block-left-cart-load-img-reg-expert,
    .block-left-cart-load-img-reg-expert.accelerator-cart-load-img{
        width: 100%;
        float: none;
    }

    .block-right-cart-load-img-reg-expert {
        width: 100%;
        float: none;
        margin: 15px 0 0 0;
    }

    .block-item-check-box-reg-startup {
        width: 100%;
        float: none;
    }

    .block-list-cart-reg-startup {
        display: block;
        justify-content: space-between;
        flex-wrap: nowrap;
        text-align: center;
    }

    .block-item-cart-reg-startup {
        display: inline-block;
        margin: 0 10px 40px 10px;
    }

    .block-item-cart-reg-startup:nth-child(1) {
        float: none;
    }

    .block-item-cart-reg-startup:nth-child(2) {
        float: none;
    }

    .block-item-cart-reg-startup:nth-child(3) {
    }

    .block-list-cart-round-reg-startup {
        display: block;
        justify-content: space-between;
        flex-wrap: wrap;
        text-align: center;
    }

    .block-item-cart-round-reg-startup {
        display: inline-block;
        margin: 0 10px 30px 10px;
    }
    .block-input-range p.title-input-range {
        width: 100%;
        line-height: 41px;
        color: rgba(8, 8, 8, 0.8);
        font-family: "Stratos LC";
        font-size: 18px;
        font-weight: 600;
        margin: 0;
    }
}

.block-right-cart-load-img-reg-expert.test-fix {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.block-list-check-box-reg-startup.bl-min-height {
    min-height: 252px;
}

.block-input-range .subtext {
    color: rgba(8, 8, 8, 0.8);
    opacity: 0.75;
    font-size: 14px;
    position: relative;
    left: 17px;
    font-weight: normal;
}

.container-input-range {
    padding-top: 50px;
}

.container-input-range .input-range__track {
    background-color: #979797;
    height: 2px;
}

.container-input-range .input-range__track.input-range__track--active {
    background-color: #2CA6EB;
    height: 8px;
    top: -3px;
}

.container-input-range.disable .input-range__track.input-range__track--active,
.container-input-range.disable .input-range__slider {
    display: none;
}

.container-input-range .input-range__slider {
    background-color: #000000;
    border-color: #000;
    top: -4px;
}

.container-input-range .input-range__label--value {
    display: none;
}

.block-input-range .container-inline-checkbox {
    margin-top: 40px;
}

.container-inline-checkbox {
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    display: inline-block;
}

.container-inline-checkbox .block-check-box-blue {
    float: left;
    margin-right: 10px;
}

.turnover-block {
    padding-left: 45px;
    position: relative;
}

.bta-10 p {
    font-size: 22px;
    font-weight: 600;
}

.bta-10 textarea {
    height: 120px;
}

.bta-10-p {
    font-size: 22px;
    font-weight: 600;
    position: relative;
}

.block-item-cart-round-reg-startup.bit-10 {
    height: 210px;
}

.block-item-cart-round-reg-startup.bit-10 .title-front-cart {
    font-size: 20px;
    font-weight: normal;
    position: relative;
    margin-top: 40px;
    left: 0;
}

.block-item-cart-round-reg-startup.bit-10:hover .title-front-cart {
    left: -101%;
}

.block-item-cart-round-reg-startup.bit-10 p.description-back-cart {
    top: -20px;
    position: relative;
}

/*.block-item-cart-round-reg-startup.bit-10.active:hover p.description-back-cart {*/
/*right: -101%;*/
/*}*/

.s11-top-text {
    max-width: 550px;
}

.s11-top-text .description-page-reg-expert {
    font-size: 20px;
    font-weight: normal;
}

.s11-btm-block {
    max-width: 360px;

}

.s11-btm-block .title-top-page-reg-expert.text-h3 {
    font-size: 22px;
}

.s11-irs {
    right: 50px;
    top: 270px;
}

.step-12 {
    text-align: left;
}

.step-16-row .col-left {
    width: 50%;
    float: left;
    padding-right: 50px;
}

.step-16-row .col-right {
    width: 50%;
    float: right;
    padding-left: 50px;
}

.step-16-row .s16-subtitle {
    padding-bottom: 10px;
}

.step-16-row .s16-subdescr {
    padding-bottom: 30px;
    min-height: 90px;
    line-height: 20px;
}

.step-16-row textarea {
    height: 267px;
}

.step-19-tp {
    display: flex;
    max-width: 635px;
}

.step-19-tp .title-value-cap {
    text-align: right;
    position: relative;
    top: -18px;
}

.s19-t {
    display: inline-block;
    min-width: 207px;
}

.block-input-range p.title-input-range span.value input.s19-i {
    width: 105px;
}

.before-btn {
    position: absolute;
    left: -110px;
    top: 7px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    line-height: 17px;
}

.before-btn.after {
    left: initial;
    right: -270px;
    color: #000000;
    display: inline-block;
    text-align: left;
}

.container-step-11-reg .text-h1 {
    margin-bottom: 20px;
}

.container-step-11-reg .text-h7 {
    line-height: 22px;
}

.text-bold-600 {
    font-weight: 600;
}

.wrap-white.container-step-11-reg.step-14 {
    padding-bottom: 20px;
}

.drop-container {
    width: 100%;
    border: 2px solid rgba(151, 151, 151, 0.2);
    border-radius: 8px;
    height: 450px;
    position: relative;
    padding: 10px;
    overflow: auto;
}

.dropzone-component__active .drop-container {
    border: 2px solid #2CA6EB;
    /*opacity: 0.5;*/
}

.dropzone-component__active .drop-container .opacity-wrapper {
    opacity: 0.3;
}

.drop-container .absolute-hint {
    color: rgba(151, 151, 151, 0.45);
    line-height: 20px;
    text-align: right;
    position: absolute;
    right: 25px;
    bottom: 20px;
}

.drop-container .absolute-hint-2 {
    width: 317px;
    color: #ffffff;
    background-color: #2CA6EB;
    line-height: 20px;
    text-align: center;
    position: absolute;
    border-radius: 0px 0px 8px 8px;
    top: 0px;
    left: 254px;
    height: 68px;
    display: -ms-flexbox;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    z-index: 2;
    display: none;
}

.dropzone-component__active .drop-container .absolute-hint-2 {
    display: flex;
}

.drop-container .one-item {
    width: 140px;
    height: 140px;
    border-radius: 6px;
    float: left;
    margin: 10px;
    /*background-color: #afffcc;*/
    position: relative;
    cursor: pointer;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
}

.drop-container .one-item.oi-media:hover {

}

.one-item.oi-empty {

}

.oi-empty .oie-title {
    font-weight: 600;
    line-height: 24px;
    font-size: 14px;
}

.oi-empty .oie-description {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    line-height: 15px;
}

.oi-empty .oie-button {
    width: 100%;
    text-align: center;
    background-color: #ECECEC;
    color: #525252;
    font-weight: 600;
    height: 41px;
    border-radius: 6px;
    margin-top: 6px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.oi-empty .oie-input {
    width: 100%;
    height: 41px;
    position: absolute;
    bottom: 25px;
}

.oi-empty .oie-input input {
    height: 41px;
}

.oiy-video {
    height: 80px;
}

.oiy-title {
    height: 42px;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
}

.oiy-title .y-title {
    font-size: 12px;
    color: #2CA6EB;
    line-height: 15px;
    overflow: hidden;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    white-space: nowrap;
    width: 140px;
    position: relative;
}

.oiy-title .y-title:after {
    content: ''; /* Выводим элемент */
    position: absolute; /* Абсолютное позиционирование */
    right: 0;
    top: 0; /* Положение элемента */
    width: 40px; /* Ширина градиента*/
    height: 100%; /* Высота родителя */
    /* Градиент */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0.2), #ffffff 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.2), #ffffff 100%);
}

.oiy-title .y-type {
    font-weight: 600;
    color: #000000;
    font-size: 12px;
}

.one-item .oi-fileinfo {
    display: flex;
    justify-content: space-between;
    padding-top: 5px;
}

.oi-fileinfo .oif-1 {
    font-size: 12px;

}

.oi-fileinfo .oif-2 {
    font-size: 12px;
    padding-right: 10px;
}

.one-item .oiy-image {
    text-align: center;
}

.one-item .oiy-image img {
    height: 80px;
    max-width: 100%;
    border-radius: 6px;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.07);
}

.one-item .hover-back {
    position: absolute;
    background: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 6px;
    /*transition: .5s;*/
    display: none;
}

.one-item.oi-media:hover .hover-back,
.one-item.oi-youtube:hover .hover-back {
    display: block;
}

.one-item.oi-media:active:hover .hover-back {
    /*display: none;*/
    opacity: 0;
}

.one-item .hover-back .hb-text {
    color: #ffffff;
    text-align: center;
    padding-top: 25px;
}

.one-item .hover-back .hb-close {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: white;
}

.oiy-video.oiy-circle {
    width: 140px;
    height: 80px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.tab-info-big {
    min-height: 143px;
    /*width: 280px;*/
    border: 2px solid rgba(151, 151, 151, 0.2);
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    padding: 14px;
    /*margin-right: 15px;*/
    /*margin-left: 15px;*/
    float: left;
    margin-bottom: 15px;
    cursor: pointer;
    transition: .3s;
}

.tab-info-big.active,
.tab-info-big:hover {
    border: 2px solid #0ECFAA;
}
.step-1.is-startup .block-list-tabs .tab-info-big {
    min-height: 163px;
}
@media screen and (max-width:900px)  {
    .tab-info-big:hover {
        border: 2px solid rgba(151, 151, 151, 0.2);
    }
    .tab-info-big.active:hover {
        border: 2px solid #0ECFAA;
    }
}

.row.block-list-tabs {
    margin-left: -8px;
    margin-right: -8px;
    width: auto;
    min-height: 480px;
}

.tib-title {
    color: rgba(8, 8, 8, 0.8);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 3px;
}

.tib-description {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    line-height: 18px;
}

.tib-6 {
    padding-right: 8px;
    padding-left: 8px;
}

.container-step-16 .text-middle {
    margin: 15px 0;
    font-size: 22px;
}

.container-step-16 .input-with-btn-right {

}

.input-with-btn-right {
    display: flex;
    width: 100%;
}

.input-with-btn-right .input-wrap {
    width: calc(100% - 105px);
}

.input-with-btn-right .block-button-3-0 {
    width: 90px;
    margin-left: 15px;
}

.step-20-text {
    margin: 0;
    font-size: 1.1em;
}

.wrapper-new-calendar {
    display: inline-block;
    float: left;
    margin-bottom: 15px;
    margin-right: 10px;
}

.shell-text-area-kyc.bottom-calendars-block {
    margin-top: 15px;
    /*display: flex;*/
    /*align-items: center;*/
}

.shell-text-area-kyc.bottom-calendars-block .container-inline-checkbox {
    /*margin: -8px 0 0 50px;*/
}

.data-slider-turnover {
    display: inline-block;
    /*padding-left: 20px;*/
}

.data-slider-turnover input{
    border: 1px solid #979797;
    border-radius: 4px;
    background-color: #FFFFFF;
    display: inline-block;
    line-height: 41px;
    width: 150px;
    padding: 0 20px;
    outline: none;
    margin: 0 0 0 20px;
    color: #000000;
    font-family: "Stratos LC";
    font-size: 14px;
    text-align: center;
    font-weight: 400;
}

.data-slider-turnover .subtext {
    left: auto;
    padding-left: 20px;
    padding-right: 5px;
}

.input-wrap.blue-icon .active {
    color: #49639d;
}

.wrap-for-input-105 .block-new-input {
    display: inline;
}

.wrap-for-input-105 input {
    width: 105px !important;
    height: 43px;
}

.list-funds-found {

}

.list-funds-found img {
    width: 50px;
}

.list-funds-found .item-fund-found {
    border: 1px solid black;
    margin-bottom: 10px;
}

.input-range__label {
    display: none;
}

.block-item-cart-reg-invest {
    height: 310px;
    width: 260px;
    margin: 10px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    display: inline-block;
    vertical-align: top;
    padding: 32px;
    box-sizing: border-box;
    position: relative;
    z-index: 10;
    cursor: pointer;
    border: 2px solid #FFFFFF;
}

.block-item-cart-reg-invest.active {
    border: 2px solid #2CA6EB;
}

.block-item-cart-reg-invest.active .block-checkbox-front-cart {
    display: block;
}

.block-item-cart-reg-invest .block-checkbox-front-cart {
    width: 23px;
    height: 23px;
    position: absolute;
    top: 15px;
    right: 15px;
    display: none;
}

.block-item-cart-reg-invest .block-icon-cart-reg-invest {
    width: 100%;
    height: 32px;
    margin: 10px 0 0 0;
    transition: .3s;
    opacity: 1;
}

.block-item-cart-reg-invest .block-icon-cart-reg-invest img {
    display: block;
    margin: 0 auto;
    height: 32px;
}

.block-item-cart-reg-invest .block-title-cart-reg-invest {
    width: 100%;
    margin: 20px 0 0 0;
    transition: .3s;
    opacity: 1;
}

.block-item-cart-reg-invest .block-title-cart-reg-invest p.title-cart-reg-invest {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    margin: 0;
}

.block-item-cart-reg-invest .block-description-cart-reg-invest {
    width: 100%;
    margin: 10px 0 0 0;
    transition: .3s;
    opacity: 1;
}

.block-item-cart-reg-invest .block-description-cart-reg-invest p.description-cart-reg-invest {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin: 0;
}

.block-item-cart-reg-invest .block-big-description-cart-reg-invest {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 210px;
    top: 0;
    left: 0;
    padding: 30px 32px 0 32px;
    transition: .3s;
}

.block-item-cart-reg-invest .block-big-description-cart-reg-invest p.big-description-cart-reg-invest {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin: 10px 0 0 0;
}

.block-item-cart-reg-invest .shell-button-cart {
    position: absolute;
    left: 0;
    bottom: 32px;
    height: 40px;
    width: 100%;
}

.block-item-cart-reg-invest.active .block-button-cart {
    background: #ECECEC;
    border: 2px solid #ECECEC;
}

.block-item-cart-reg-invest.active .block-button-cart p {
    color: #525252;
}

.block-item-cart-reg-invest .block-button-cart {
    height: 40px;
    width: 160px;
    border: 2px solid #CDD2DD;
    border-radius: 6px;
    box-shadow: 0 8px 19px 0 rgba(32, 165, 238, 0);
    transition: .3s;
    margin: 0 auto;
    cursor: pointer;
}

.block-item-cart-reg-invest:not(.active):hover .block-button-cart {
    box-shadow: 0 8px 19px 0 rgba(32, 165, 238, 0.15);
    background: #2CA6EB;
    border: 2px solid #2CA6EB;

}

.block-item-cart-reg-invest:not(.active):hover .block-button-cart p {
    color: #FFFFFF !important;
}

.block-item-cart-reg-invest .block-button-cart p.title-button-cart {
    color: #8A92A3;
    font-family: "Stratos LC";
    font-size: 16px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    margin: 0;
}

.block-item-cart-reg-invest:not(.active):hover .block-icon-cart-reg-invest {
    opacity: 0;
}

.block-item-cart-reg-invest:not(.active):hover .block-title-cart-reg-invest {
    opacity: 0;
}

.block-item-cart-reg-invest:not(.active):hover .block-description-cart-reg-invest {
    opacity: 0;
}

.block-item-cart-reg-invest:not(.active):hover .block-big-description-cart-reg-invest {
    opacity: 1;
}

.block-list-title-input {
    width: 100%;
    /*max-width: 360px;*/
    margin: 130px 0;
    z-index: 100;
    position: relative;
}

.block-item-title-input {
    width: 100%;
    margin: 0 0 30px 0;
    display: table;
}
.profile-edit-popup-inner-block .block-item-title-input {
    margin: 0 0 15px 0;
}

p.title-item-title-input {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 22px;
    font-weight: 500;
    line-height: 41px;
    margin: 0;
    float: left;
    width: calc(100% - 105px);
}

.block-input-item-title-input {
    width: 105px;
    float: right;
}

.accelerator-select-user-profile .select-add-new-btn.text-add-new {
    line-height: 32px;
}

.block-input-item-title-input .block-new-input {
    height: 59px;
}

.block-input-item-title-input .block-new-input input {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    height: 40px;
    font-weight: 400;
}

.block-net-work-fund {
    width: 100%;
}

.block-top-net-work-fund {
}

.block-body-net-work-fund {
    margin: 25px 0 0 0;
    padding: 40px 0 0 0;
}

.block-input-net-work-fund {
    position: relative;
    z-index: 10;
    display: table;
}



.block-list-net-work-fund {
    position: relative;
    z-index: 10;
}

.block-item-net-work-fund {
    height: 60px;
    width: 220px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    display: inline-block;
    margin: 0 15px 20px 0;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.block-left-item-net-work-fund {
    width: calc(100% - 60px);
    height: 60px;
    float: left;
    padding: 8px 8px 8px 16px;
}

.block-left-item-net-work-fund p.title-item-net-work-fund {
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 25px;
    margin: 0;
    text-align: left;
}

.block-left-item-net-work-fund p.description-item-net-work-fund {
    color: rgba(0, 0, 0, 0.5);
    font-family: "Stratos LC";
    font-size: 11px;
    line-height: 16px;
    margin: 0;
    text-align: left;
}

.block-right-item-net-work-fund {
    width: 60px;
    height: 60px;
    float: right;
}

.block-right-item-net-work-fund .block-img-item-net-work-fund {
    width: 60px;
    height: 60px;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
}

.block-active-net-work-fund {
    position: absolute;
    width: 100%;
    height: 60px;
    opacity: 0;
    background-color: #666666;
    transition: .3s;
}

.block-active-net-work-fund p.title-active-net-work-fund {
    color: rgba(255, 255, 255, 0.8);
    font-family: "Stratos LC";
    font-size: 22px;
    font-weight: 500;
    line-height: 60px;
    text-align: center;
    margin: 0;
}

.block-item-net-work-fund:hover .block-active-net-work-fund {
    opacity: 1;
}

.block-item-net-work-fund:hover .block-active-net-work-fund-del {
    opacity: 1;
}

.block-item-net-work-fund.active .block-active-net-work-fund {
    opacity: 1;
}

.block-active-net-work-fund-del {
    position: absolute;
    width: 100%;
    height: 60px;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transition: .3s;
}

.block-del-button-active-net-work-fund {
    width: 60px;
    height: 60px;
    float: right;
    position: relative;
}

.block-del-button-active-net-work-fund:after {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    border-right: 1px;
    background: #FFFFFF;
    transform: rotate(45deg);
    top: 30px;
    left: 22px;
}

.block-del-button-active-net-work-fund:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    border-right: 1px;
    background: #FFFFFF;
    transform: rotate(-45deg);
    top: 30px;
    right: 22px;
}

.block-no-net-work-fund {
    border: 1px solid rgba(151, 151, 151, 0.2);
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
    display: table;
    padding: 28px 31px 10px 31px;
    width: 100%;
}

.block-left-no-net-work-fund {
    width: 445px;
    float: left;
    border-right: 2px solid rgba(0, 0, 0, 0.3);
    padding: 0 30px 0 0;
    box-sizing: border-box;
}
.block-right-no-net-work-fund {
    width: calc(100% - 450px);
    padding: 0 0 0 30px;
    float: right;
    box-sizing: border-box;
}
.block-title-no-net-work-fund {
    margin: 0;

}

.block-title-no-net-work-fund p.title-no-net-work-fund {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    text-align: left;
    font-weight: 400;
}

.block-list-button-no-net-work-fund {
}

.block-list-button-no-net-work-fund .block-button-3-0 {
    float: left;
    margin: 15px 13px 0 0;
    height: 40px;
}

.block-list-button-no-net-work-fund .block-button-3-0 p {
    line-height: 40px !important;
}



.block-add-invite-no-net-work-fund {
}

.block-add-invite-no-net-work-fund .shell-input-reg {
    width: calc(100% - 110px);
    float: left;
}

.block-add-invite-no-net-work-fund .block-button-3-0 {
    float: right;
    height: 40px;
}

.block-add-invite-no-net-work-fund .block-button-3-0 p {
    line-height: 40px !important;
}

.block-button-small .block-button-3-0 {
    height: 40px;
}

.block-button-small .block-button-3-0 p {
    line-height: 40px !important;
}

.block-previosu-add-startup {
    width: 100%;
    display: table;
}

.block-left-previosu-add-startup {
    width: 50%;
    float: left;
}

.block-right-previosu-add-startup {
    width: 40%;
    float: right;
    padding: 28px 0 0 0;
}

.block-size-previosu-add-startup {
    width: 100%;
    display: table;
    margin: 15px 0 0 0;
}

.block-left-size-previosu-add-startup {
    width: 160px;
    float: left;
    margin: 0 80px 0 0;
}

.block-right-size-previosu-add-startup {
    width: 160px;
    float: left;
}

.block-yes-no-exit-size-previosu-add-startup {
    width: 100%;
    display: table;
    margin: 38px 0 0 0;
}

.block-yes-exit-size-previosu-add-startup {
    width: 50%;
    float: left;
    height: 23px;
    text-align: left;
}

.block-no-exit-size-previosu-add-startup {
    width: 50%;
    float: right;
    height: 23px;
    text-align: right;
}

.block-yes-no-exit-size-previosu-add-startup .block-check-box-blue {
    display: inline-block;
    margin: 0 10px 0 0;
    vertical-align: top;
    cursor: pointer;
}

.block-yes-no-exit-size-previosu-add-startup .block-check-box-blue:after {
    opacity: 0
}

.block-yes-no-exit-size-previosu-add-startup .block-check-box-blue:before {
    opacity: 0
}

.block-yes-no-exit-size-previosu-add-startup .block-check-box-blue.active:after {
    opacity: 0
}

.block-yes-no-exit-size-previosu-add-startup .block-check-box-blue.active:before {
    opacity: 0
}

.block-yes-no-exit-size-previosu-add-startup p.title-previosu-add-startup {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 23px;
    margin: 0 10px 0 0;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.block-left-previosu-add-startup .block-button-3-0 {
    height: 40px;
    margin: 30px 0 0 0;
}

.block-left-previosu-add-startup .block-button-3-0 p {
    height: 40px;
    line-height: 40px !important;
}

.block-button-link {
}

.block-button-link .block-button-3-0.button-3-0-border {
    height: 33px;
    float: left
}

.block-button-link .block-button-3-0.button-3-0-border p {
    line-height: 30px !important;
    height: 30px
}

.block-button-link a.link {
    width: calc(100% - 126px);
    float: right;
    line-height: 29px;
    color: rgba(8, 8, 8, 0.8);
    font-family: "Stratos LC";
    font-size: 16px;
}

span.check-ok {
    display: inline-block;
    box-sizing: border-box;
    height: 33px;
    width: 33px;
    border: 1px solid #2CA6EB;
    position: relative;
    border-radius: 50%;
    margin: 0 15px 0 0;
    float: left;
    top: 3px;
}

span.check-ok:after {
    position: absolute;
    content: '';
    background: #2CA6EB;
    width: 8px;
    height: 1px;
    transform: rotate(45deg);
    top: 17px;
    left: 7px;
}

span.check-ok:before {
    position: absolute;
    content: '';
    background: #2CA6EB;
    width: 14px;
    height: 1px;
    transform: rotate(-45deg);
    top: 17px;
    left: 12px;
}

.block-list-button-input {
    width: 100%;
    display: table;
}

.shell-input-reg {
    float: left;
    margin: 0 15px 0 0;
}

.shell-input-button-add {
    float: left;
}

.shell-input-button-add .block-button-3-0 {
    height: 41px;
}

.shell-input-button-add .block-button-3-0 p {
    height: 41px;
    line-height: 41px !important;
}

.block-item-cart-round-reg-startup .block-checkbox-front-cart {
    position: absolute;
    right: 15px;
    top: 15px;
    display: none;
}

.block-item-cart-round-reg-startup.active .block-checkbox-front-cart {
    display: block;
}

.block-fix-button-reg-bottom {
    position: fixed;
    width: 100%;
    height: 80px;
    left: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    padding: 16px 0;
    z-index: 223;
}
.block-button-left{
    float: left;
}
.block-button-right{
    float: right;
}

.fix-button-double{

}
.fix-button-double-details-double{

}
.fix-button-double-details-one{

}
.fix-button-one-details-one{

}

p.description-cart-reg-accelerator {
    font-size: 20px!important;
    line-height: 25px!important;
}
.accelerator-descr-text {
    line-height: 24px!important;
    opacity: 0.75;
}
.font-weight-400 {
    font-weight: 400!important;
}
.block-item-cart-reg-invest .block-icon-accelerator img {
    height: 38px;
}
.block-item-cart-reg-invest .block-icon-government img {
    height: 43px;
}
.block-item-cart-reg-invest .block-icon-accelerator,
.block-item-cart-reg-invest .block-icon-government {
    height: 40px;
}
.block-item-cart-reg-invest .block-title-cart-accelerator {
    margin: 26px 0 0 0;
}
.block-button-link-accelerator .block-button-3-0.button-3-0-border{
    background-color: #2f3849;
    border: 2px solid #2f3849;
    min-width: 111px;
}
.block-button-link-accelerator .block-button-3-0.button-3-0-border p.block-button-3-0-title {
    font-size: 14px;
    color: #FFFFFF;
}
.block-form-proof-owner .proof-descr-text {
    font-size: 14px;
    line-height: 20px;
}
.previous-investments-textarea .textarea {
    height: 103px!important;
}



@media (max-width: 1440px){
    .block-cart-choose.right {
        float: none;
    }
}

@media (max-width: 1200px){
    .fix-button-double-details-double{
        padding: 0 20px;
    }
    .fix-button-double-details-double .container-full-reg{
        width: 100%!important;
    }
    .fix-button-double-details-one{
        padding: 20px;
    }
    .fix-button-double-details-one .container-full-reg{
        width: 100%!important;
    }
    .block-input-item-form-add-edit-modal-reg-expert.input-item-form-add-adaptive .block-button-3-0.button-3-0-blue {
        float: left;
        margin-bottom: 20px;
    }
    .block-input-item-form-add-edit-modal-reg-expert.input-item-form-add-adaptive .block-new-input {
        margin-right: 10px;
        width: calc(100% - 103px);
    }
    .block-input-item-form-add-edit-modal-reg-expert.input-item-form-add-adaptive .block-button-3-0.button-3-0-blue {
        float: left;
        margin-bottom: 20px;
    }
}

@media (max-width: 900px) {
    .container-inline-checkbox .block-check-box-blue {
        margin-bottom: 20px;
    }

    .fix-button-double{
        padding: 0 20px;
    }
    .fix-button-double-details-double{
        padding: 0 20px;
        height: 250px;
    }
    .fix-button-one-details-one{
        padding: 0 20px;
        height: 135px;
    }
    .fix-button-double-details-double .container-full-reg{
        text-align: center!important;
    }
    .fix-button-double-details-double .block-button-left{
        float: none;
        padding: 0!important;
        position: absolute;
        bottom: 20px;
        width: calc(100% - 40px);
    }
    .fix-button-double-details-double .block-details{
        float: none;
        display: block;
        width: 100%;
        padding: 0 0 20px 0!important;
        box-sizing: content-box;
    }
    .fix-button-double-details-double .block-details .title-block-button-reg-expert{
        text-align: center!important;
    }
    .fix-button-double-details-double .block-details:nth-child(2){
        position: absolute;
        bottom: 68px;
        width: calc(100% - 40px);
    }
    .fix-button-double-details-double .block-details:nth-child(3){
        position: absolute;
        top: 68px;
        width: calc(100% - 40px);
    }
    .fix-button-double-details-double .block-button-right{
        float: none;
        padding: 0!important;
        position: absolute;
        top: 0;
        width: calc(100% - 40px);

    }
    .fix-button-double-details-double .block-button-3-0{
        display: block!important;
    }




    .fix-button-double-details-one{
        height: 205px;
    }
    .fix-button-double-details-one .block-button-left{
        float: none;
        padding: 0!important;
        position: absolute;
        bottom: 20px;
        width: calc(100% - 40px);
    }
    .fix-button-double-details-one .block-details{
        float: none;
        display: block;
        padding: 0 0 20px 0!important;
        box-sizing: content-box;
        position: absolute;
        bottom: 68px;
        width: calc(100% - 40px);
    }
    .fix-button-double-details-one .block-button-right{
        float: none;
        padding: 0!important;
        position: absolute;
        top: 0;
        width: calc(100% - 40px);

    }
    .fix-button-double-details-one .block-button-3-0{
        display: block!important;
        padding: 0!important;
    }




    .fix-button-one-details-one{

    }
    .fix-button-one-details-one .block-button-left{
        float: none;
        padding: 0!important;
        position: absolute;
        bottom: 20px;
        width: calc(100% - 40px);
    }
    .fix-button-one-details-one .block-details{
        float: none;
        display: block;
        box-sizing: content-box;
        position: absolute;
        bottom: 20px;
        width: calc(100% - 40px);
    }
    .fix-button-one-details-one .block-button-right{
        float: none;
        padding: 0!important;
        position: absolute;
        top: 0;
        width: calc(100% - 40px);

    }

    .fix-button-one-details-one .block-button-3-0{
        display: block!important;
        padding: 0!important;
    }


    .fix-button-one{
        padding: 16px 20px!important;
    }
    .block-fix-button-reg-bottom.fix-button-reg-adaptive {
        padding: 16px 20px;
    }
    .fix-button-double-details-double.achieve-details .block-button-right {
        top: 55px;
    }
    .block-metamask-adaptive {
        padding: 55px 0 250px!important;
    }
}

@media (max-width: 550px) {
    .shell-button-reg-expert.wrap-button{
        display: flex;
        flex-direction: column;
    }

    .shell-button-reg-expert.wrap-button a:last-child{
        margin-left: 0 !important;
        margin-top: 20px;
    }
}

@media (max-width: 500px) {
    .fix-button-double{
        padding: 0 20px;
        height: 135px;
    }
    .fix-button-double .block-button-left{
        float: none;
        width: calc(100% - 40px);
        position: absolute;
        bottom: 20px;
    }
    .fix-button-double .block-button-right{
        float: none;
        width: calc(100% - 40px);
        position: absolute;
        top: 0;
    }
    .fix-button-double .block-button-right .block-button-3-0.button-3-0-blue {
        padding: 0!important;
    }
    .fix-button-double .block-button-3-0{
        display: block!important;
    }
    .block-fix-button-reg-bottom.fix-button-reg-adaptive {
        height: 150px;
    }
    .block-fix-button-reg-bottom.fix-button-reg-adaptive .block-button-right .block-button-3-0,
    .block-fix-button-reg-bottom.fix-button-reg-adaptive .block-button-left .block-button-3-0 {
        width: 100%;
        margin-bottom: 15px;
    }
    .block-fix-button-reg-bottom.fix-button-reg-adaptive .block-button-right,
    .block-fix-button-reg-bottom.fix-button-reg-adaptive .block-button-left {
        float: none;
    }
    .new-adaptive-fix {
        height: 200px!important;
    }
    .new-adaptive-fix .block-details {
        height: 63px;
        display: block;
        padding: 0!important;
    }
    .new-adaptive-fix .block-details .block-button-3-0 {
        width: 100%;
    }



    .fix-button-one .block-button-left{
        float: none;
        width: 100%;
    }
    .fix-button-one .block-button-right{
        float: none;
        width: 100%;
    }
    .fix-button-one .block-button-3-0{
        display: block!important;
        padding: 0!important;
    }
    .drop-container .absolute-hint-mobile {
        display: none;
    }

}
@media (max-width: 400px) {
    .block-btns-soc {
        width: 100%!important;
    }
}
@media (max-width: 300px) {
    .block-btns-soc {
        width: 300px!important;
    }
}

.padding-top-60 {
    padding-top: 60px;
}

