.block-button-reg-social{
    width: 100%;
    display: table;
    height: 48px;
    transition: .3s;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
}
p.title-button-reg-social{
    width: 100%;
    line-height: 48px;
    font-family: "Stratos LC";	font-size: 16px;	text-align: center;
    font-weight: 400;
    margin: 0;
    transition: .3s;
}


.block-button-social-reg-custom{
    width: 100%;
    display: table;
    height: 48px;
    transition: .3s;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 2px 50px 6px rgba(0, 0, 0, 0.07);
    transition: .3s;
}
.block-button-social-reg-custom img{
    width: 22px;
    height: 22px;
    position: absolute;
    display: block;
    top: 13px;
    left: 13px;
}
.title-button-social-reg-custom{
    color: #C5B399;	font-family: "Stratos LC";	font-size: 16px;	line-height: 48px;	text-align: center;
    width: 100%; font-weight: 400; margin: 0;
}
.block-button-social-reg-custom:hover{
    opacity: 0.8;
}