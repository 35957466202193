.page-view-controls{
    width: 100%;
    display: table;
}
.block-left-view-controls{
    width: 360px;
    float: left;
    background: rgb(34, 34, 34);;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
}
.block-right-view-controls{
    width: calc(100% - 360px);
    float: right;
    background: #FFFFFF;
    min-height: 100vh;
}

.block-button-page-view-control{
    width: 100%;
    height: 40px;
    background: rgb(17, 17, 17);
    border-bottom: 1px solid rgb(70, 48, 235);
    transition: 0.3s;
    cursor: pointer;
}
.block-button-page-view-control:hover{
    background: rgb(70, 48, 235);
}
.block-button-page-view-control.active{
    background: rgba(70, 48, 235, 0.6);
}


.block-button-page-view-control p.title{
    font-family: "Stratos LC";
    font-size: 16px;
    font-weight: 300;
    line-height: 40px;
    margin: 0;
    width: 100%;
    text-align: center;
    color: #FFFFFF;
}