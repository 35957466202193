 
.block-way-name,
.closed-block-way-name,
.active-block-way-name {
	width: 100%;
	height: 15px;
	margin: 5px 0;
}
.number-way-block {
	width: 15px;
	height: 15px;
	background-color: #343434;
	border-radius: 50%;
	float: left;
	margin-right: 10px;
}
.number-way-block > div {
	font-family: Stratos LC;
	font-size: 10px;
	line-height: 15px;
	/* identical to box height, or 130% */
	text-align: center;
	color: #F6F6F6;
}
.block-way-name .way-title,
.closed-block-way-name .way-title,
.active-block-way-name .way-title {
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: #2F3849;
	cursor: pointer;
}
.closed-block-way-name .way-title {
	color: rgba(0, 0, 0, 0.5);
}
.block-way-descr,
.block-way-closed-descr {
	margin-left: 7px;
	padding-left: 17px;
	width: 100%;
	border-left: 1px solid rgba(0, 0, 0, 0.8);
}
.block-way-last-descr {
	margin-left: 7px;
	padding-left: 18px;
	width: 100%;	
}
.block-way-closed-descr {
	border-left: 1px solid #D0D0D0;
}
.block-way-descr > div,
.block-way-closed-descr > div,
.block-way-last-descr > div {
	font-family: Stratos LC;
	font-size: 11px;
	line-height: 13px;
	color: #979797;
}
.closed-block-way-name .number-way-block {
	background-color: #D0D0D0;
}

.closed-block-way-name .number-way-block > div {
	width: 15px;
	height: 15px;
	background-image: url(./img/ic_lock.svg);
	background-repeat: no-repeat;
	background-position: 49% 49%;
}
.active-block-way-name .fa-check {
	color: #FFFFFF;
	font-size: 11px;
	display: inline-block;
	vertical-align: top;
	width: 15px;
	height: 15px;
	line-height: 15px;
	font-weight: 500;
	text-align: center;
}