.rocket-header {
    position: fixed;
    width: 100%;
    top: 0;
    height: 74px;
    /*line-height: 74px;*/
    background-color: #ffffff;
    z-index: 999;
    font-family: 'Stratos LC';
}

.rocket-header .logo {
    font-size: 28px;
}

.rocket-header .block-right {
    text-align: right;
}

.rocket-header .to-profile {
    display: inline-block;
    cursor: pointer;
}

.rocket-header .name {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 14px;
    padding-right: 10px;
    display: inline-block;
    max-width: 60%;
}

.rocket-header img {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    position: relative;
    top: 10px;
    background: #000;
    z-index: 100000;
}

.rocket-header .block-img-rock {
    height: 32px;
    width: 32px;
    border-radius: 16px;
    position: relative;
    display: inline-block;
    top: 10px;
    background: #000;
}

.block-img-rock img {
    display: block;
    width: 17px;
    height: 17px;
    margin: 8px 9px;
    border: 0;
    border-radius: 0;
    top: 0;
}

.logo img {
    height: auto;
    width: auto;
    background: none;
    border-radius: 0;
    vertical-align: top;
    margin: 16px 0;
}

.rocket-header .balance {
    padding-left: 20px;
    padding-right: 10px;
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 74px;
}

.rocket-header .block-icons {
    padding-top: 15px;
}

.rocket-header .col-icon {
    line-height: 20px;
    width: 20%;
    float: left;
    text-align: center;
    cursor: pointer;
}

.opacity-3 {
    opacity: .3;
}

.open-menu {
    opacity: 1 !important;
}

.rocket-header .col-icon:hover div {
    color: #0000D3;
}

.rocket-header .col-icon div {
    color: #000000;
    font-size: 10px;
    font-family: "Stratos LC";
    font-weight: 400;
    line-height: 10px;
}

.rocket-header .col-icon .icon {
    display: inline-block;
    width: 25px;
    height: 25px;
}

.rocket-header .col-icon .icon.icon-network {
    background: url('../../images/icon/net-work.svg') center center no-repeat;
    -webkit-background-size: 25px 25px;
    background-size: 25px 25px;
}

.rocket-header .col-icon .icon.icon-project {
    background: url('../../images/icon/project-board.svg') center center no-repeat;
    -webkit-background-size: 25px 25px;
    background-size: 25px 25px;
}

.rocket-header .col-icon .icon.icon-messages {
    background: url('../../images/icon/message.svg') center center no-repeat;
    -webkit-background-size: 25px 25px;
    background-size: 25px 25px;
}

.rocket-header .col-icon .icon.icon-notification {
    background: url('../../images/icon/notification.svg') center center no-repeat;
    -webkit-background-size: 25px 25px;
    background-size: 25px 25px;
}

.rocket-header .col-icon .icon.icon-contract {
    background: url('../../images/icon/smart-contract.svg') center center no-repeat;
    -webkit-background-size: 25px 25px;
    background-size: 25px 25px;
}

.Select-option.is-focused {
    background: rgba(0, 40, 220, 0.4);
}

.shell-inline-block {
    display: inline-block;
    vertical-align: top;
}

.block-person-new {
    display: inline-block;
    vertical-align: top;
    width: 240px;
    margin: 12px 0px;
}

.block-bottom-person-new {
    background: #FFFFFF;
    padding: 0px 10px 0 0;
    width: 105px;
    overflow: hidden;
    height: 0px;
    float: right;
    transition: .5s;
}

.block-top-person-new:hover + .block-bottom-person-new {
    height: 110px;
    padding: 13px 15px 0 0;
}

.block-bottom-person-new:hover {
    height: 110px;;
    padding: 13px 15px 0 0;
}

.block-bottom-person-new a {
    font-size: 16px !important;
    letter-spacing: 0;
    font-weight: 300;

}

.header-links {
    position: fixed;
    width: 250px;
    right: 0;
    top: 60px;
    background-color: #0028DC;
    border-radius: 0 0 2px 2px;
    padding-left: 24px;
    display: none;
}

.header-links .one-line {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: #ffffff;
    font-weight: 200;
}

.header-links a {
    color: #ffffff;
    text-decoration: none;
}

.header-links a:hover {
    /*text-decoration: underline;*/
}

.header-links .one-line a {
    height: 50px;
    display: block;
}

.header-links .one-line a:hover {
    color: #00F082;
}

.header-links .one-line .click-link {
    display: block;
}

.header-links .one-line .click-link:hover {
    color: #00F082;
    cursor: pointer;
}

.block-header-top .block-header-top-right-person {
    height: 60px;
}

.block-header-top-right-person:hover .header-links {
    display: block;
}

.header-edit-mode .input-reg-dao input,
.header-edit-mode textarea {
    color: #ffffff !important;
    border-color: #D8D8D8;
}

.header-edit-mode .input-reg-dao.for-youtube input {
    color: #000000 !important;
}

.block-header-body-left-description {
    padding-top: 20px;
}

.header-edit-mode .block-desc-project {
    margin-top: 30px;
}

.header-edit-mode .Select-control {
    border-color: #D8D8D8;
}

.header-edit-mode textarea::placeholder {
    color: #c4c4c4 !important;
}

.block-drop-list-dao.bdld-2 {
    background: inherit;

}

.block-drop-list-dao.bdld-2 .privacy-type {
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
}

.block-drop-list-dao.bdld-2 .bttd-2 {
    padding-left: 0;
}

.block-drop-list-dao.bdld-2 .block-title-top-drop-list-dao {
    border-bottom: none;
}

.block-drop-list-dao.bdld-2 p {
    font-size: 14px;
}

.input-reg-dao.ird-fix {
    width: 45%;
    float: left;
}

.input-reg-dao.ird-fix2 {
    width: 45%;
    float: right;
}

.span-ird-fix {
    display: inline-block;
    text-align: center;
    color: #ffffff;
    padding-top: 12px;
    padding-left: 4px;
    font-size: 12px;
}

.block-list-countries .Select-value-label {
    color: #ffffff !important;
}

.block-list-countries .is-focused .Select-value-label {
    color: #000000 !important;
}

.block-list-countries .Select-control {
    background: inherit;
    border: none;
    border-bottom: 1px solid #D8D8D8;
}

.block-list-countries .Select-input > input {
    color: #000000 !important;
}

.block-header-top.block-for-unregister {
    background-color: #0028DC;
}

.block-header-top.block-for-unregister .btn-login {
    color: #00F082;
    font-size: 16px;
    cursor: pointer;
}

.block-header-top.block-for-unregister .btn-register {
    background-color: #00F082;
    font-size: 14px;
    color: #000000;
    border: 0;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    width: 100px;
    outline: none;
}

.main-left-menu {
    width: 325px;
    background-color: rgb(0, 40, 220);
    height: calc(100vh - 60px);
    position: fixed;
    right: 0;
    z-index: 9999;
    font-family: "Stratos LC";
}

.main-left-menu .block-top {
    border-bottom: 1px solid #435CD1;
    padding-bottom: 15px;
}

.main-left-menu .block-top div {
    color: #ffffff;
    font-size: 19px;
    padding-left: 35px;
    line-height: 50px;
}

.main-left-menu .block-top div a {
    color: #ffffff;
    text-decoration: none;
}

.main-left-menu .block-top div:hover {
    text-decoration: underline;
    cursor: pointer;
}

.main-left-menu .block-top div a:hover {
    text-decoration: underline;
}

.main-left-menu .block-bottom {
    padding-top: 15px;
}

.main-left-menu .block-bottom div {
    color: #ffffff;
    font-size: 21px;
    font-weight: 500;
    padding-left: 35px;
    line-height: 50px;
}

.main-left-menu .block-bottom div:hover {
    text-decoration: underline;
    cursor: pointer;
}

.shell-button {
    position: relative;
}

.wp-success {
    position: absolute;
    color: #ffffff;
    font-size: 12px;
}

.reg_error_field.error-wp {
    /*position: absolute;*/
    top: 3px;
}

.block-list-menu-right-new-top-menu-rocket-dao-mobile {
    position: absolute;
    width: 100%;
    right: 0;
    top: 0;
    z-index: 2;
    background-color: #FFFFFF;
    height: 100vh;
    padding: 0;
    overflow-y: auto;
}

.block-border-line {
    width: 100%;
    height: 1px;
    overflow: hidden;
    background: #9B9B9B;
}

.shell-item-padding-left-right-20 {
    padding: 0 20px;
    overflow: hidden;
}

.block-img-title-logout-person-mobile-menu {
}
.block-img-title-logout-person-mobile-menu a{
    width: 100%;
    display: block;
    height: 40px;
}
.block-imt-person-mobile-menu {
    width: 40px;
    height: 40px;
    float: left;
}

.block-imt-person-mobile-menu img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
}

.block-title-person-mobile-menu {
    height: 40px;
    width: calc(100% - 100px);
    float: left;
}

.block-title-person-mobile-menu p {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 40px;
    text-align: left;
    font-weight: 300;
    margin: 0;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.block-logout-person-mobile-menu {
    width: 60px;
    height: 40px;
    float: right;
}

.block-logout-person-mobile-menu a {
    cursor: pointer;
    color: #A2A6AF;
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 40px;
    text-align: right;
}

.block-item-mobile-menu-right-new-top-menu-rocket-dao-mobile {
    overflow: hidden;
    margin: 20px 0;
}

.block-item-mobile-menu-right-new-top-menu-rocket-dao-mobile a.small-link-mobile-item-menu-right {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 18px;
    line-height: 23px;
    font-weight: 300;
    text-decoration: none;
    transition: .3s
}

.block-item-mobile-menu-right-new-top-menu-rocket-dao-mobile a.big-link-mobile-item-menu-right {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    text-decoration: none;
    transition: .3s
}

.block-item-menu-mobile-person-new-top-menu-rocket-dao {
    overflow: hidden;
    margin: 20px 0;
    cursor: pointer;
    width: 100%;
}

.block-item-menu-mobile-person-new-top-menu-rocket-dao a {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 18px;
    line-height: 23px;
    font-weight: 300;
    text-decoration: none;
    position: relative;
    transition: .3s;
}

.block-item-menu-mobile-person-new-top-menu-rocket-dao a span.state-item-menu-person-new-top-menu-rocket-dao {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #FA4600;
    position: absolute;
    right: -10px;
    top: 4px;
}

.header-title-about {
    color: rgba(0,0,0,0.8);
    transition: .3s;
    font-weight: 500;
    line-height: 14px;
    text-decoration: none;
    margin-left: 20px;
    display: inline;
    cursor: pointer
}
.header-title-about:hover {
    color: rgba(0,0,0,0.5);
    cursor: pointer;
}
.open-close-header-block {
    border-radius: 6px;
    position: absolute;
    display: none;
    background-color: #FFFFFF;
    padding: 10px 0 0 10px;
    width: 104%;
    box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
}
.open-close-header-block:hover {
    display: block;
}
.block-list-link-blog-header {
    position: relative;
}
.header-subtitle-about {
    color: rgba(0,0,0,0.8);
    font-size: 15px;
    transition: .3s;
    font-weight: 500;
    line-height: 18px!important;
    text-decoration: none;
    padding: 10px 0;
}
.header-subtitle-about a {
    margin-left: 0!important;
}
.header-subtitle-about:hover {
    color: rgba(0,0,0,0.5);
    cursor: pointer;
}


.block-item-mobile-menu-right-new-top-menu-rocket-dao-mobile a.active {
    color: #0028DC;
}

.block-item-mobile-menu-right-new-top-menu-rocket-dao-mobile a:hover {
    color: #00F082;
}

.block-item-mobile-menu-right-new-top-menu-rocket-dao-mobile a.active:hover {
    color: #0028DC;
}

.block-item-menu-mobile-person-new-top-menu-rocket-dao a:hover {
    color: #00F082;
}


.button-new-login{
    height: 35px;
    display: block;
    border: 1px solid #2CA6EB;	border-radius: 4px;	background-color: #FFFFFF;	box-shadow: 0 8px 19px 0 rgba(32,165,238,0);
    transition: .3s;
    color: #2CA6EB;	font-family: "Stratos LC";	font-size: 14px;	font-weight: 500;	line-height: 33px;	text-align: center;
    padding: 0 16px!important;
}

.button-new-login:hover{
    box-shadow: 0 8px 19px 0 rgba(32,165,238,0.15);
}

.resent-search-block {
    width: 100%;
    max-height: 58px;
    overflow-y: hidden;
    margin: 8px 0;
    cursor: pointer;
    position: relative;
}
.resent-search-block .time-icon-block {
    max-width: 16px;
    background-image: url("./Search/img/time_icon.svg");
    background-size: contain;
    height: 10px;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    margin-top: -5px;
}

.resent-search-block .resent-search-text {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.505952px;
    color: #404040;
}
.resent-search-block .main-block {
    border-left: 2px solid #2f3849;
    padding-left: 8px;
    margin-left: 17px;
}
.resent-search-block .main-expert-block {border-left: 2px solid #0ECFAA;}
.resent-search-block .main-investor-block {border-left: 2px solid #2AA2E6;}
.search-mobile-cancel-button {
    font-size: 14px;
    line-height: 54px;
    color: rgba(8,8,8,0.6);
    display: none;
    margin-left: 8px;
}
.block-search-header-input .block-new-input input::-ms-clear {
    display: none;
    width : 0;
    height: 0;
}


@media (min-width: 1150px) {
    .block-logo-new-top-menu-rocket-dao {
        display: block;
    }

    .block-list-new-top-menu-rocket-dao {
        display: block;
    }

    .block-right-container-new-top-menu-rocket-dao {
        display: block;
    }

    .shell-person-name-photo-new-top-menu-rocket-dao {
        display: block;
    }

    .block-list-menu-right-new-top-menu-rocket-dao-mobile {
        display: none;
    }
    .container-balance-dao.person-menu{
        display: none;
    }
    .shell-button-open-close-menu-right-new-top-menu-rocket-dao{
        display: none;
    }
}

@media (max-width: 1150px) {
    .container-balance-dao.person-menu{
        display: block;
    }
    .block-logo-new-top-menu-rocket-dao {
        display: block;
    }

    .block-list-new-top-menu-rocket-dao {
        display: block;
        padding: 0 0 0 250px;
    }
    .block-list-new-top-menu-rocket-dao{
        width: calc(100% - 150px)!important;
        padding: 0 0 0 320px;
        margin: 0 auto 0 0!important;
        z-index: 50!important;
    }
    .block-right-container-new-top-menu-rocket-dao {
        display: block;
        /*width: auto !important;*/
    }
    .block-right-container-new-top-menu-rocket-dao{
        width: 64px!important;
    }
    .block-right-container-new-top-menu-rocket-dao .shell-list-menu-person-new-top-menu-rocket-dao{
        left: -220px;
    }
    .shell-person-name-photo-new-top-menu-rocket-dao {
        display: block;
    }
    .shell-person-name-photo-new-top-menu-rocket-dao .container-balance-dao.header-menu{
         display: none;
    }
    .shell-person-name-photo-new-top-menu-rocket-dao .container-balance-dao.person-menu{
        display: block;
    }

    .block-list-menu-right-new-top-menu-rocket-dao {
        display: none;
    }

    .shell-menu-right-new-top-menu-rocket-dao {
        width: 100% !important;
    }

    .block-list-menu-right-new-top-menu-rocket-dao-mobile {
        display: block;
    }
    .right-no-reg-user{
        width: 100%;
    }
    .block-item-new-top-menu-rocket-dao{
        padding: 0 10px!important;
    }
    .block-item-new-top-menu-rocket-dao:first-child{
        padding: 0 10px 0 0!important;
    }
    .block-item-new-top-menu-rocket-dao:last-child{
        padding: 0 0 0 10px!important;
    }
    .block-grid-new-top-menu-rocket-dao{
        margin: 0!important;
    }
    .shell-button-open-close-menu-right-new-top-menu-rocket-dao{
        display: none;
    }
}
@media (max-width: 1000px) {
    .block-logo-new-top-menu-rocket-dao{
        /*width: auto!important;*/
    }
}
@media (max-width: 850px) {
    .block-list-new-top-menu-rocket-dao{
        /*display: none;*/
    }
    .shell-button-open-close-menu-right-new-top-menu-rocket-dao{
        display: block;
    }
    .shell-person-name-photo-new-top-menu-rocket-dao{
        /*display: none;*/
    }

    .block-logo-new-top-menu-rocket-dao{
        /*width: auto!important;*/
    }

    /*.block-list-link-blog-header {
        display: none!important;
    }*/
    .block-list-link-blog-header > a {
        display: none;
    }
    .block-list-link-blog-header .header-title-about {
        margin-right: 25px;
    }
    .block-list-link-blog-header .open-close-header-block {
        width: 140%;
    }


}
@media (max-width: 770px) {
    .block-logo-new-top-menu-rocket-dao {
        width: 410px!important;
    }
}
@media (max-width: 680px) {
    .block-person-photo-new-top-menu-rocket-dao {
        display: none;
    }
    .block-new-top-menu-rocket-dao {
        padding: 0 20px 0 0!important;
    }
}
@media (max-width: 650px) {
    .search-header-adaptive .temp-links-header .block-list-link-blog-header {
        display: none;
    }
}
@media (max-width: 580px) {
    .search-header-adaptive .temp-links-header {
        display: none;
    }

}
@media (max-width: 500px) {
    .search-header-adaptive .block-logo-new-top-menu-rocket-dao{
        width: calc(100% - 65px)!important;
    }
    .search-header-adaptive .block-search-header-input {
        width: calc(100% - 60px);
        max-width: 340px;
    }
    .industry-mobile-padding {
        padding: 25px 30px 23px 30px;
    }

}
@media (max-width: 1350px) {
    .block-list-link-header{
        display: none!important;
    }
}


span.icon-unread-messages{
    display: inline-block;
    min-width: 16px;
    padding: 2px;
    font-size: 11px;
    color: initial;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #00F082;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    position: relative;
    top: -2px;
}
span.icon-ex-point{
    display: inline-block;
    min-width: 16px;
    padding: 2px;
    font-size: 11px;
    color: #ffffff;
    font-weight: 600;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #FA4600;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin-left: 5px;
    position: relative;
    top: -2px;
}
span.icon-ex-point.fix-for-logo {
    position: absolute;
    top: 16px;
    left: 8px;
}

.notification-indicator {
    display: inline-block;
    background-color: #00F082;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 0px;
    left: 24px;
}

.container-balance-dao {
    /*float: left;*/
    display: inline-block;
    /*background-color: #ffffff;*/
    /*width: 150px;*/
    text-align: center;
    border-radius: 15px;
    /*margin-top: 13px;*/
    height: 30px;
    cursor: pointer;
    z-index: 10;
    /*position: relative;*/
    font-weight: 300;
    font-size: 14px;
    padding: 0 20px 0 0;
}
.container-balance-dao.person-menu{
    text-align: left;
}
.container-balance-dao.person-menu span{
    color: #CFCFCF;
}
.container-balance-dao img {
    position: relative;
    top: 5px;
    padding-right: 5px;
}

.container-balance-dao .eth-item {
    padding-right: 15px;
}
.container-balance-dao .rock-item {

}

.block-item-menu-mobile-link{}
.block-item-menu-mobile-link a.item-link{
    cursor: pointer;
    display: block;
    width: 100%;
    color: #000000;	font-family: "Stratos LC";	font-size: 18px;	font-weight: 400;	letter-spacing: -0.19px;	line-height: 39px;
    text-decoration: none;
    border-bottom: 2px solid #979797;
    position: relative;
}
.block-item-menu-mobile-link a.item-link:after{
    content: '';
    position: absolute;
    border: 6px solid transparent;
    border-top: 10px solid #1BC8A7;
    border-bottom: 0;
    display: block;
    width: 0;
    height: 0;
    right: 15px;
    top: 17px;
    transition: .3s;
}
.block-item-menu-mobile-link.active a.item-link:after{
    border-top: 0;
    border-bottom: 10px solid #1BC8A7;
    top: 17px;
}
.block-item-list-sub-link{
    padding: 0 0 0 15px;
}
.block-grid-sub-item{
    overflow: hidden;
    transition: .3s;
    height: 0;
}

.block-grid-sub-item a{
    display: block;
    width: 100%;
    color: #000000;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.17px;	line-height: 39px; font-weight: 300;
    text-decoration: none;
}


.block-item-menu-mobile-link.active .block-grid-sub-item.row-2{
    height: calc(39px * 2);
}
.block-item-menu-mobile-link.active .block-grid-sub-item.row-3{
    height: calc(39px * 3);
}
.block-item-menu-mobile-link.active .block-grid-sub-item.row-4{
    height: calc(39px * 4);
}
.block-item-menu-mobile-link.active .block-grid-sub-item.row-6{
    height: calc(39px * 6);
}

.temp-links-header {
    height: 54px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
}
.temp-links-header a {
    color: rgba(0,0,0,0.8);
    transition: .3s;
    font-weight: 500;
    line-height: 14px;
    text-decoration: none;
    margin-left: 20px;

}
.temp-links-header a.active,
.temp-links-header a:hover {
    color: rgba(0,0,0,0.5);
    cursor: pointer;
}

.search-line-wrapper {
    display: none;

    width: 100%;
    height: auto;
    background-color: #FFFFFF;
    border-top: 1px solid rgba(0,0,0,0.15);
    border-bottom: 1px solid rgba(0,0,0,0.15);
}
.search-line {
    height: inherit;
    width: 100%;
    margin: 0 auto;
    padding-top: 2px;
}
.search-line .block-button-tab-counter {
    margin: 5px 8px 5px 0;
}
.search-popup {
    /*position: absolute;*/
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    box-shadow: -1px 5px 14px rgba(0,0,0,0.1);
    border-radius: 8px;
    /*left: 50%;*/
    /*top: 50%;*/
    /*transform: translate(-50%, -50%);*/

    margin: 100px auto 0 auto;
}
.search-popup#accel-inv-size {
    transform: translateX(-130px);
}
.search-popup#accel-type {
    transform: translateX(-240px);
}
.search-popup#business-model {
    transform: translateX(-60px);
}
.search-popup#eval-param {
    transform: translateX(-285px);
}
.search-popup#inv-round {
    transform: translateX(-205px);
}
.search-popup#industry {
    transform: translateX(-132px);
}
.search-popup#inv-size {
    transform: translateX(-212px);
}
.search-popup#inv-type {
    transform: translateX(60px);
}
.search-popup#location {
    transform: translateX(-340px);
}


.search-popup-industry {
    width: 720px;
    padding: 30px 30px 23px 30px;
}
.search-popup-bmodel {
    width: 322px;
    padding: 13px 25px 20px 25px;
}
.new-search-popup-bmodel {
    padding: 0 0 20px 0;
}
.search-popup-eval-param {
    width: 320px;
    padding: 27px 12px 17px 30px;
}
.search-popup-eval-param .block-item-check-box-reg-startup {
    float: none;
    width: 100%;
}

.buttons-line {
    justify-content: flex-end;
}
.font-button {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.505952px;
    color: #000000;
    width: 40px;
    cursor: pointer;
}
.buttons-line .font-button.col {
    max-width: 40px;
    margin-right: 25px;
}
.block-item-check-box-reg-startup .search-popup-right {
    font-size: 16px;
    letter-spacing: -0.505952px;
    color: #404040;
    font-weight: 300;
}
.business-model-list-search {
    margin: 12px 0;
}
.accel-type-list-search {
    margin: 8px 0;
}
.business-model-list-search .bmodel-list-name {
    font-size: 18px;
    line-height: 18px;
    color: rgba(8,8,8,0.8);
    font-weight: 500;
}
.business-model-list-search .bmodel-list-descr {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0,0,0,0.5);
}
.business-model-list-search .imodel-list-descr {
    font-size: 12px;
    line-height: 15px;
    color: rgba(0,0,0,0.5);
    letter-spacing: -0.505952px;
    margin-top: 12px;
}
.business-model-list-search .investor-list-descr {
    margin-top: 6px;
}
.business-model-list-search .accel-list-descr {
    margin-top: 0px;
}


.business-model-list-search .block-swipe-blue {
    width: 34px;
    height: 18px;
    background-color: #c4c4c4;
    border: none;
}
.business-model-list-search .block-swipe-blue.active {
    background-color: #2f3849;
    border: none;
}
.business-model-list-search .block-swipe-blue:after {
    width: 14px;
    height: 14px;
    background-color: #FFFFFF;
}
.business-model-list-search .block-swipe-blue.active:after {
    left: 18px;
    background-color: #FFFFFF;
}
.search-popup-location {
    width: 285px;
    padding: 20px 25px 25px 25px;
}
.search-location-title {
    font-size: 14px;
    line-height: 24px;
    color: rgba(8,8,8,0.8);
    font-weight: 500;
    margin-bottom: 5px;
}
.search-popup-location .block-button-tab.active {
    margin: 0 5px 5px 0;
}
.search-popup-location .block-button-tab.active p.title {
    /*font-weight: 400;*/
}
.search-popup-location .block-top-select input {
    opacity: 1;
}
.search-popup-location .block-select,
.search-popup-location .block-top-select{
    height: 41px;
}
.search-popup-location .block-top-select input {
    height: 39px;
}
.search-popup-location .block-top-select img {
    display: none;
}
.search-popup-location .block-body-select {
    top: 36px;
    padding: 0;
}
.search-popup-location .block-body-select .block-list-item-select .block-item-select {
    margin-top: -1px;
}
.search-popup-location .block-button-tab .zh-icon-remove {
    right: -6px;
    top: -1px;
}
.search-popup-location .block-button-tab.active:hover {
    opacity: 1;
}
.search-popup-location .block-button-tab.active p.title:hover {
    color: rgba(8,8,8,0.7);
}
.search-popup-location .checked-countries-block .block-button-tab {
    overflow: hidden;
}
.search-popup .mobile-popup-header,
.search-popup .mobile-popup-footer {
    height: 70px;
    background-color: #FFFFFF;
    width: 100%;
    /*position: fixed;*/
    z-index: 200;
    left: 0;
    display: none;
}
.search-popup .mobile-popup-footer {
    position: absolute;
}
.search-popup .mobile-popup-header {
    border-bottom: 1px solid rgba(0,0,0,0.15);
    top: 0;
}

.search-popup .mobile-popup-footer {
    bottom: 0;
    padding: 12px 20px 20px 20px;
}
.search-popup .mobile-popup-footer .block-button-3-0.button-3-0-black {
    height: 38px;
    width: 100%;
}
.search-popup .mobile-popup-footer .block-button-3-0.button-3-0-black p.block-button-3-0-title {
    font-size: 14px;
    line-height: 38px;
    font-weight: 500;
}
.search-popup .mobile-popup-header .popup-header-reset-button {
    float: right;
    margin-right: 25px;
    font-size: 14px;
    line-height: 70px;
    color: #0ECFAA;
    cursor: pointer;
}
.mobile-popup-header .close-button {
    float: left;
    margin-left: 25px;
    width: 30px;
    height: 30px;
    background-image: url("./Search/img/close_icon.svg");
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-top: 20px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}
.checked-countries-list-wrapper-mobile {
    display: none;
}
.block-search-header-input .input-enter-icon {
    position: absolute;
    right: 36px;
    top: 18px;
    width: 16px;
    height: 17px;
    cursor: pointer;
    background: url("../../images/enter_arrow.svg");
    background-repeat: no-repeat;
    background-size: contain;
}


@media all and (max-width: 1000px) {
    .search-line {
        padding-top: 2px;
    }
    .search-popup {
        transform: translateX(0)!important;
    }
    .search-popup#accel-inv-size {
        margin: 100px auto 0 210px;
    }
    .search-popup#accel-type {
        margin: 100px auto 0 100px;
    }
    .search-popup#business-model {
        margin: 100px auto 0 280px;
    }
    .search-popup#eval-param {
        margin: 100px auto 0 55px;
    }
    .search-popup#inv-round {
        margin: 100px auto 0 130px;
    }
    .search-popup#industry {
        margin: 100px auto 0 10px;
    }
    .search-popup#inv-size {
        margin: 100px auto 0 130px;
    }
    .search-popup#inv-type {
        margin: 100px auto 0 405px;
    }
    .search-popup#location {
        margin: 100px auto 0 20px;
    }
}
@media all and (max-height: 670px) {
    .search-popup#industry {
        margin-top: 50px!important;
    }
    .search-popup#inv-round {
        margin-top: 50px!important;
    }
}
@media all and (max-width: 756px) {
    .block-logo-new-top-menu-rocket-dao a {
        background-image: url("./Search/img/adaptive_logo.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 34px;
        height: 30px;
        display: inline-block;
        margin-top: 15px;
        margin-left: 10px;
    }
    .block-logo-new-top-menu-rocket-dao a img {
        display:none;
    }
}

@media all and (max-width: 720px) {
    .search-line {
        padding-top: 2px;
    }
    .search-popup-industry {
        width: 100vw;
        min-height: 100vh;
        top: unset;
        left: unset;
        transform: unset;
    }
}
@media all and (max-width: 650px) {
    .search-line {
        padding-top: 2px;
    }
    .search-popup {
        margin: 100px auto 0 auto!important;
    }
}
@media all and (max-width: 500px) {
    .search-popup {
        border: none;
        align-items: flex-start;
        align-content: flex-start;
        padding: 0!important;
        position: relative!important;
        margin: 0!important;
        min-height: 100%;
    }
    .search-popup#industry,
    .search-popup#inv-round {
        margin-top: 0!important;
    }

    .search-popup .mobile-popup-footer,
    .search-popup .mobile-popup-header {
        display: block;
    }
    .search-popup-industry {
        padding: 100px 30px 93px 30px;
    }
    .search-popup .font-button {
        display: none;
    }
    .search-popup-bmodel,
    .search-popup-eval-param,
    .search-popup-location {
        width: 100vw;
        min-height: 100%;
        top: unset;
        left: unset;
        transform: unset;
        height: 100%;
    }
    .search-popup-bmodel {
        padding: 83px 25px 90px 25px;
    }
    .search-popup-eval-param {
        padding: 97px 12px 87px 30px;
    }
    .search-popup-location {
        padding: 90px 25px 95px 25px;
    }
    .checked-countries-list-wrapper-mobile {
        display: block;
    }
    .checked-countries-list-wrapper {
        display: none;
    }
    .search-popup-location .block-button-tab .zh-icon-remove {
        display: inline-block;
        opacity: 0.8;
    }
    .search-popup-location .block-button-tab.remove:hover {
        border: 1px solid #0ECFAA;
    }
    /*.scroll-this {*/
    /*    min-height: 100vh;*/
    /*    height: 100vh;*/
    /*    width: 100vw;*/
    /*}*/
}

.error-not-found-text{
    font-size: 14px;
    padding: 0 14px 0 14px;
}

.block-new-top-menu-rocket-dao.container-full-reg .block-logo-new-top-menu-rocket-dao img{
    margin-left: 0px !important;
}

.container-full-reg .block-person-photo-new-top-menu-rocket-dao,
.container-full-reg .block-person-photo-new-top-menu-rocket-dao p{
    margin-right: 0px !important;
    cursor: auto !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: right !important;
}

.continue-later{
    color: #0ECFAA;
    cursor: pointer;
    padding: 0 !important;
    float: none !important;
}

.continue-later:hover{
    text-decoration: underline;
}

@media (max-width:900px) {
    .block-new-top-menu-rocket-dao.container-full-reg .block-logo-new-top-menu-rocket-dao img{
        margin-left: 20px !important;
    }
}