.box{
  width: 100%;
  padding: 32px;
  background: #FFFFFF;
  box-shadow: -4px 6px 14px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
}

@media screen and (max-width: 576px) {
  .box{
    padding: 0 16px;
    box-shadow: none;
  }
}