.parameter-details-block {
  .back-block {
    margin-top: -4px;
    .back-title {
      font-size: 20px;
      line-height: 26px;
      color: rgba(0, 0, 0, 0.8);
      padding-left: 13px !important;
      cursor: pointer;
    }

    .back-icon {
      max-width: 31px;
      height: 31px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.103487);
      border-radius: 15.5px;
      text-align: center;
      cursor: pointer;

      i {
        line-height: 31px;
      }
    }
  }
  .parameter-details-content {
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    padding: 39px 30px 32px 30px;
    .expert-param-block-wrapper {
      padding: 0;
      box-shadow: unset;
      min-height: unset;
      cursor: unset;
      .fa-chevron-down {
        visibility: hidden;
      }
      @media (max-width: 400px) {
        .title-wrapper {
          flex: 0 0 100%;
          max-width: 100%;
          @media (max-width: 340px) {
            .title {
              font-size: 17px;
            }
          }
        }
      }
    }
    .param-details-wrapper {
      padding: 34px 0 6px 0;
      .block-list-state-cart-achievements {
        display: flex;
        width: unset;
        .block-left-state-cart-achievements,
        .block-center-state-cart-achievements,
        .block-right-state-cart-achievements {
          margin: 0 0 20px 0;
          float: unset;
          max-width: 220px;
          @media (max-width: 880px) and (min-width: 756px) {
            flex: 0 0 100%;
            max-width: unset;
          }
          @media (max-width: 576px) {
            max-width: unset;
          }
        }
      }
    }
  }
  .achieve-wrapper {
    padding-top: 40px;
    position: relative;
    .achieve-gradient {
      background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0.0001) 54.77%);
      position: absolute;
      top: 0;
      left: -30px;
      width: calc(100% + 60px);
      height: 150px;
    }
    .achieve-descr {
      font-size: 16px;
      line-height: 22px;
      color: rgba(0,0,0,0.75);
      margin-bottom: 20px;
      position: relative;
    }
  }
}