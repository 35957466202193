.verification {
    width: 17px;
    height: 17px;
    &.fly{
      position: absolute;
      top: 16px;
      right: 16px;
    }
    &.blue-icon {
        background-image: url(../../../images/icon/VerificationBlue.svg);
    }
    &.green-icon {
        background-image: url(../../../images/icon/VerificationGreen.svg);
    }
    &.black-icon {
        background-image: url(../../../images/icon/VerificationBlack.svg);
    }
    &.sticky{
        display: inline-block;
        margin-left: 8px;
        @media (max-width: 1021px) {
            position: absolute;
            right: 5px;
            top: 12px;
            margin: 0;
        }
    }
}