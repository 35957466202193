.invest-club-container {
  .with-green-dot {
    position: relative;
    &:after{
      content: "";
      position: absolute;
      background: #0ECFAA;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      width: 8px;
      height: 8px;
      top:-4px;
      right: -4px;
      border-radius: 100%;
    }
    
  }
}

.invest-club-block-avatar {
  width: 100px;
  height: 100px;
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
}

.investClubInput {
  display: flex;
  margin: 45px 0;


}
.investClubInput > .block-new-input {
  display: block;
}

.investClubInput > .block-new-input > input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.investClubInput > .block-button-3-0 {
  flex-shrink: 4;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.invest-club-paper {
  text-align: left;
  align-items: center;
  margin-bottom: 15px;
  &.invest-club-paper-header {
    color: #979797;
    margin-top: 40px;
    padding: 0 20px;
  }
}

.modal-invest-club {
  padding: 44px;
  width: 540px;
  text-align: center;
  background: #ffffff;
  border: 1px solid #d0d0d0;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.invest-club-copy-link {
  font-size: 13px;
  color: #979797;
  padding-top: 5px;
  cursor: pointer;
  transition: opacity 200ms ease;
  &:hover{
    opacity: 0.5;
  }
}

.like-a-link{
  cursor: pointer;
  transition: opacity 200ms ease;
  &:hover{
    opacity: 0.5;
  }
}

.invest-club-modal-description{
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;  

  color: rgba(8, 8, 8, 0.8);

  text-align: left;

}

.invest-club-avatar-block{
  text-align: left;
  h4 {
    margin:0; 
    font-size: 16px;
  }
  p {
    font-size:13px;
    margin: 5px 0 8px 0;
  }
  img {
    float: left;
    border-radius: 4px;
    width: 100px;
    height: 100px;
    margin-right: 24px;

  }
}

.ReactModalPortal .ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.close {
  cursor: pointer;
}

.margin-10 {
  margin: 10px 0;
}
.margin-15 {
  margin: 15px 0;
}
.margin-20 {
  margin: 20px 0;
}

.red-background.red-background.red-background {
  background-color: #eb2c2c;
}
