@import '../CONST';


.photo-block-container {
  .photo-block-title {
    @extend %input-label-22;
    @extend %font-weight-500;
    line-height: 30px;
  }
  .photo-block-title-descr {
    @extend %block-descr-14;
    @extend %mt-7
  }
  .block-load-img > div {
    width: 111px!important;
    height: 111px!important;
    display: block!important;;
  }
  .block-load-img {
    display: block;
  }
  canvas {
    display: block;
    margin: 0 auto;
  }
  .add-new-btn {
    margin: -44px auto 26px auto;
    .block-button-load-img {
      display: block;
      left: 0;
    }
  }
  .zoom-inp input {
    margin: 2px auto!important;
  }


  @media all and (max-width: 576px) {
    .block-load-img,
    .block-load-img > div,
    .block-load-img .load-img,
    canvas {
      height: 236px!important;
      width: 236px!important;
    }
    .block-button-load-img {
      width: 206px;
      left: 15px;
      bottom: 19px;
      top: unset;
    }
    .zoom-inp input {
      width: 206px!important;
    }
  }
}

