@import '../CONST';

.lang-block-container {
  .lang-block-title {
    @extend %input-label;
    @extend %font-weight-500;
    margin-bottom: 5px;
  }
  .lang-left-col {
    padding-top: 25px;
  }
  .checked-countries-wrapper {
    width: calc(100% + 5px);
  }
  .checked-countries-block .col-auto {
    padding: 0 5px 5px 0;
  }
  .block-select,
  .block-top-select,
  .block-top-select input,
  .block-top-select p.title-select {
    height: 41px;
  }
  .block-top-select p.title-select {
    line-height: 41px;
  }
  .block-body-select {
    top: 34px;
  }
  .block-top-select img {
    top: 15px
  }
}
