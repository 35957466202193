.search-startup-page .block-cart-new-startup-marketplace{
  width: 100% !important;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 14px 0 rgba(0,0,0,0.03);
  overflow: hidden;
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin: 0 0 20px 0 !important;
}


.next-row-b > div{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}