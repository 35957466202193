@import '../CONST';

.skill-tags-container {
  .title {
    @extend %block-descr-16;
    @extend %font-weight-500;
    line-height: 24px;
    margin-bottom: 10px;
    margin-top: 30px;
  }
  .my-skills-block {
    padding-top: 24px;
  }
  .input-wrapper {
    height: 41px;
    max-width: 261px;
    .block-new-input,
    .block-new-input input {
      height: 41px;
    }
  }

  .skills-button-wrapper {
    padding-left: 5px;
    .block-button-3-0.button-3-0-blue {
      height: 41px;
    }
    .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
      line-height: 41px;
      font-weight: 400;
    }
  }
  .search-skills-wrapper {
    padding: 12px 0 6px 0;
  }

}

@media all and (max-width: 576px) {
  .skill-tags-container {
    .input-wrapper {
      max-width: unset;
    }
    .skills-button-wrapper {
      padding-left: 10px;
      padding-top: 10px;
      .block-button-3-0.button-3-0-blue {
        width: 100%;
      }
    }
    .my-skills-block {
      padding-bottom: 3px;
    }
  }
}

