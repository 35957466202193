.block-list-add-parameters{
    border-top: 1px solid #2F3849;
    border-bottom: 1px solid #2F3849;
    padding: 0;
    position: absolute;
    width: 100%;
    height: 0;
    overflow: hidden;
    background: #2F3849;
    transition: .3s;
}
.block-list-add-parameters.open{
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
    padding: 15px 0 0 0;
    position: absolute;
    width: 100%;
    background: #2F3849;
}
.block-item-add-parameters{

}


.block-item-add-parameters p.title-item-add-parameters{
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.505952px;
    font-family: "Stratos LC";
    color: #FFFFFF;
    margin: 0 0 15px 0;
    cursor: pointer;
    text-align: left;
}
.shell-scroll-component{
    width: 100%;
    overflow-x: hidden;
    padding: 0 40px 0 0;
}
.profile-edit-popup-inner-block .shell-scroll-component {
    padding: 0 40px 0 35px;
    overflow: hidden;
}

.block-body-role-list-user-menu-mobile  p.title-top-role-list-user-menu-mobile {
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.505952px;
    margin: 0;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 300;
}
.block-body-role-list-user-menu-mobile .block-settings-menu-mobile {
    margin: 15px 0;
}
.block-body-role-list-user-menu-mobile.about-button:after {
    height: 42px;
}

.block-blog-mobile-menu p.title-top-role-list-user-menu-mobile {
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.505952px;
    margin: 0;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 300;
}
.block-blog-mobile-menu {
    margin: 16px 20px 0 20px;
}
.user-settings-popup {
    background-color: #FFFFFF;
    border-radius: 3px;
    width: 150px;
    min-height: 100px;
    box-shadow: 0px 2px 6px rgba(0,0,0,0.15);
    position: fixed;
    bottom: 50px;
    left: 50px;
    display: none;
}
.user-settings-popup .settings-popup-line {
    width: 100%;
    height: 30px;
    transition: .3s;
    padding: 0 13px;
    cursor: pointer;
}
.user-settings-popup .settings-popup-line:hover {
    background-color: rgba(0,0,0,0.1);
}
.user-settings-popup .settings-popup-title {
    font-size: 14px;
    line-height: 30px;
    color: #2f3849;
}
.user-settings-popup .settings-popup-name {
    font-size: 12px;
    line-height: 30px;
    color: rgba(0,0,0,0.5);
}
.user-settings-popup .settings-popup-line-name {
    width: 100%;
    height: 30px;
    padding: 0 5px 0 13px;
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/*.menu-block-avatar:hover + .user-settings-popup {
    opacity: 1;
}
.user-settings-popup:hover {
    opacity: 1;
}*/