
.block-info-create-token {
    margin: 0 0 30px 0;
}

.block-create-new-token {
    border-radius: 6px;
    background-color: rgba(255, 255, 255, .64);
    width: 100%;
    padding: 25px;
    color: #0000D3;
}

.block-info-create-new-token {
    margin: 0 0 30px 0;
}

.block-input-create-new-token {
    border: 1px solid #979797;

}

.block-header-body {
    /*margin-top: 100px;*/
}

.block-header-body-right {
    margin: 0;
}

.block-header-body-right img {
    width: 100%;
    height: 310px;
}

.block-content-start-page {
    width: 1140px;
    margin: 0 auto;
}

.block-left-content-start-page {
    width: 715px;
    float: left;
}

.block-right-content-start-page {
    width: 330px;
    float: right;
    margin: 66px 0 0 0;
}

.block-last-person-created-token {
    margin: 30px 0 0 0;
}

.block-person-cart-last-created-token {
    display: inline-block;
    width: 25%;
    vertical-align: top;
}

.block-person-cart-last-created-token .block-img {
    display: inline-block;
    width: 95px;
}

.block-person-cart-last-created-token .block-img img {
    width: 65px;
    height: 65px;
    display: flex;
    margin: auto;
    border-radius: 50%;
}

.block-person-cart-last-created-token .block-info {
    width: calc(100% - 95px);
    display: inline-block;
    vertical-align: top;
}

.block-person-cart-last-created-token .block-info .title-info-last {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    margin: 0;
}

.block-person-cart-last-created-token .block-info .time-info-last {
    color: #A2A6AF;
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 19px;
    font-weight: 300;
    margin: 0;
}

.block-popular-token {
    margin: 30px 0 0 0;

}

.title-popular-token {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 42px;
    line-height: 53px;
    font-weight: 400;
    margin: 0;
}

.description-popular-token {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 18px;
    line-height: 23px;
    font-weight: 300;
    margin: 0 0 30px 0;
}

.descrition-popular-token a {
    color: #0000D3 !important;
    font-family: "Stratos LC";
    font-size: 18px;
    line-height: 23px;
    font-weight: 300;
    margin: 0;
    transition: .3s;
}

.description-popular-token a:hover {
    color: #2d2eff;
}

.block-list-popular-token {
}

.block-cart-popular-token {
    width: 24%;
    margin: 0 5px 5px 0;
    display: inline-block;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    padding: 20px;
}

.block-top-cart-popular-token {
    overflow: hidden;
    margin: 0 0 20px 0;
}

.block-top-cart-popular-token img {
    width: 60px;
    height: 60px;
    display: inline-block;
    float: left;
}

.block-top-cart-popular-token p {
    float: right;
    width: calc(100% - 60px);
    color: #000000;
    font-family: "Stratos LC";
    font-size: 25px;
    font-weight: 500;
    line-height: 32px;
    margin: 26px 0 0 0;
    padding: 0 0 0 15px;
}

.block-bottom-cart-popular-token {
}

.block-bottom-cart-popular-token .description-popular-token {
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
    margin: 20px 0;
}

.block-bottom-cart-popular-token .city-popular-token {
    color: #9B9B9B;
    font-family: "Stratos LC";
    font-size: 17px;
    line-height: 21px;
    font-weight: 300;
    margin: 0 0 20px 0;
}

.block-list-project-start-page {
}

.block-cart-project-start-page {
    width: 24%;
    margin: 0 5px 5px 0;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
    display: inline-block;
    vertical-align: top;
}

.block-top-cart-project-start-page {
    height: 220px;
    padding: 20px 20px 0 20px;
    position: relative;
}

.block-top-cart-project-start-page .block-title-top {
    vertical-align: top;
}

.block-top-cart-project-start-page .block-title-top .name-round-project {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    float: left;
}

.block-top-cart-project-start-page .block-title-top .title-funded-project {
    color: #00F082;
    font-family: "Stratos LC";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    float: right;
}

.block-top-cart-project-start-page img {
    width: 60px;
    height: 60px;
    position: absolute;
    bottom: -20px;
    left: 20px;
}

.block-bottom-cart-project-start-page {
    padding: 0 20px 20px 20px;
}

.block-bottom-cart-project-start-page .title-project-start-page {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 25px;
    font-weight: 500;
    line-height: 32px;
    margin: 30px 0 0 0;
}

.block-bottom-cart-project-start-page .description-project-start-page {
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 15px;
    line-height: 20px;
    font-weight: 300;
    margin: 20px 0;
}

.block-bottom-cart-project-start-page .city-project-start-page {
    color: #9B9B9B;
    font-family: "Stratos LC";
    font-size: 17px;
    line-height: 21px;
    font-weight: 300;
    margin: 0 0 20px;
}

.shell-mass-media {
    margin: 30px 0 0 0;
}

.block-wallet {
    margin: 30px 0;
    width: 100%;
    overflow: hidden;
    text-align: center;
    height: 698px;
    background-size: cover;
}

.shell-wallet {
    display: inline-block;
    margin: 150px 0 0 0;

}

.block-wallet .block-img {
    display: inline-block;
    width: 316px;
}

.block-wallet .block-img img {
    margin: 0;
}

.block-wallet .block-info-wallet {
    display: inline-block;
    width: 520px;
    vertical-align: top;
    margin: 0 0 0 50px;
}

.block-wallet .block-info-wallet img {
    height: 106px;
    width: 87px;
    margin: 0;
    display: block;
}

.block-wallet .block-info-wallet p {
    text-align: left;
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 38px;
    line-height: 48px;
    margin: 40px 0;
    font-weight: 400;
}

.block-wallet .block-button-store {
}

.block-wallet .block-button-store img {
    height: 49px;
    width: 148px;
    display: inline-block;
    margin: 0 20px 20px 0;
}

.block-footer {
    width: 100%;
    background-color: #23262C;
    padding: 40px 0 50px 0;
    overflow: hidden;
    margin-top: 120px;
    position: relative;
}

.shell-footer {
    max-width: 1140px;
    margin: 0 auto;
    overflow: hidden;
    padding: 0 40px;
}

.block-left-footer {
    float: left;
    width: 49%;
}
.block-right-footer {
    /*float: right;*/
    /*width: 49%;*/
    text-align: right;
    padding: 70px 0 0 0;
}
.block-left-footer a {
    color: #00F082;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 200;
    line-height: 16px;
    margin: 0px 0 0 0;
    display: block;
    padding-top: 5px;
}

.block-left-footer img {
    display: block;
    height: 20px;
}


.block-right-footer img{
    display: block;
    height: 22px;
    margin: 0 0 27px -16px;
    display: inline-block;
}
.block-right-footer a {
    color: #1BC8A7;
    font-family: Helvetica;
    font-size: 12px;
    font-weight: 200;
    line-height: 16px;
    margin: 0px 0 0 0;
    display: block;
    padding-top: 5px;
}
.block-right-footer .social-button{
    margin: 0 0 15px 27px
}
.block-right-footer .social-button:first-child{
    margin: 0;
}
.block-right-footer p {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 13px;
    font-weight: 300;
    line-height: 16px;
    margin: 10px 0 0 0;
    /*text-align: right;*/
}


.block-link-grid-footer{
    width: 100%;
    margin: 50px 0 0 0;
}
.block-link-grid-footer .block-col-link-grid{
    width: 25%;
    float: left;
    padding: 0 20px 0 0;
}
.block-link-grid-footer .block-col-link-grid a{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 13px;	letter-spacing: -0.05px;	line-height: 15px;
    margin: 0 0 3px 0; font-weight: 300;
    text-decoration: none;
    transition: .3s;
}
.block-link-grid-footer .block-col-link-grid a:hover{
       text-decoration: none;
    color: #FFFFFF;
}
.block-link-grid-footer .block-col-link-grid a.top-link{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.06px;	line-height: 18px;
    margin: 0 0 5px 0; font-weight: 300; cursor: default;
}
/*.block-link-grid-footer .block-col-link-grid a.top-link:hover{*/
    /*text-decoration: underline;*/
/*}*/


.block-input-button-footer{
    width: 100%;
    border: 1px solid #FFFFFF;
    height: 34px;
    border-radius: 16px;
    overflow: hidden;
    margin: 0 0 30px 0;
}
.block-input-footer-input{
    float: left;
    width: calc(100% - 222px);
    height: 32px;
    border: 0;
    background: transparent;
    padding: 0 10px;
    outline: none;
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.06px;	line-height: 32px;
    font-weight: 300;
}
.block-input-footer-input::placeholder{
    color: #CFCFCF!important;
}
.block-button-footer-input{
    width: 222px;
    height: 32px;
    background-color: #1BC8A7;
    border-radius: 16px 0 0 16px;
    float: right;
}
.block-button-footer-input.error {
    background-color: #c85d39;
}

.block-button-footer-input:not(.error):hover {
    cursor: pointer;
    background-color: #1bbf9e;
}
.block-button-footer-input p.title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: 0.13px;	line-height: 32px;	text-align: center;
    margin: 0; font-weight: 300;
}

.input-button-footer-new input,
.input-button-footer-new .button-footer-new {
    float: unset!important;
    width: 100%!important;
}
.input-button-footer-new .button-footer-new {
    margin: 0!important;
    max-width: 100px;
}
.input-button-footer-new .col-8 {
    padding-right: 12px;
    max-width: 200px;
}
.input-button-footer-new {
    width: unset!important;
    height: unset!important;
    display: flex!important;
}

@media screen and (min-width: 1200px) {
    .title-header-body-left {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 66px;
        letter-spacing: -1px;
        line-height: 83px;
        margin: 0;
        font-weight: 300;
    }

    .block-info-create-token .all-tokens {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 18px;
        line-height: 23px;
        font-weight: 300;
        margin: 0 20px 0 0;
        display: inline-block;
        vertical-align: top;
    }

    .block-info-create-token .all-tokens a {
        color: #00F082;
        transition: .3s;
        padding: 0 8px 0 0;

    }

    .block-info-create-token .all-tokens a:hover {
        color: #0000D3;
    }

    .block-info-create-token .create-last {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 18px;
        line-height: 23px;
        display: inline-block;
        margin: 0;
        font-weight: 300;
        vertical-align: top;
    }

    .block-input-create-new-token input {
        display: inline-block;
        width: calc(100% - 100px);
        height: 32px;
        border: 0;
        vertical-align: top;
        background: #FFFFFF;
    }

    .block-input-create-new-token .button-2-0-green {

        display: inline-block;
        width: 100px !important;
        vertical-align: top;
        min-width: 100px;
    }

    .block-left-info-create-new-token {
        display: inline-block;
        width: calc(100% - 112px);
        vertical-align: top;
        padding: 0 50px 0 0;
    }

    .block-left-info-create-new-token p {
        color: #000000;
        font-family: "Stratos LC";
        font-size: 20px;
        line-height: 25px;
        font-weight: 300;
        margin: 0;
    }

    .block-right-info-create-new-token {
        display: inline-block;
        width: 112px;
        float: right;
        vertical-align: top;
    }

    .title-big-dot-list:after {
        content: '';
        width: 15px;
        height: 15px;
        background: #00F082;
        border-radius: 50%;
        top: 12px;
        left: 0;
        position: absolute;
    }

    .title-big-dot-list {
        position: relative;
        padding: 0 0 0 19px;
        margin: 0;
        color: #000000;
        font-family: "Stratos LC";
        font-size: 30px;
        font-weight: 500;
        line-height: 39px;
    }
}

@media screen and (max-width: 1199px) {
    .title-header-body-left {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 66px;
        letter-spacing: -1px;
        line-height: 83px;
        margin: 0;
        font-weight: 300;
    }

    .block-info-create-token .all-tokens {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 18px;
        line-height: 23px;
        font-weight: 300;
        margin: 0 20px 0 0;
        display: inline-block;
        vertical-align: top;
    }

    .block-info-create-token .all-tokens a {
        color: #00F082;
        transition: .3s;
        padding: 0 8px 0 0;

    }

    .block-info-create-token .all-tokens a:hover {
        color: #0000D3;
    }

    .block-info-create-token .create-last {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 18px;
        line-height: 23px;
        display: inline-block;
        margin: 0;
        font-weight: 300;
        vertical-align: top;
    }

    .block-header-body-right img {
        width: 100%;
        height: 310px;
        margin: 30px 0 0 0;
    }

    .block-input-create-new-token input {

        display: inline-block;
        width: calc(100% - 100px);
        height: 32px;
        border: 0;
        vertical-align: top;
        background: #FFFFFF;
    }

    .block-input-create-new-token .button-2-0-green {
        display: inline-block;
        width: 100px !important;
        vertical-align: top;
        min-width: 100px;
    }

    .block-left-info-create-new-token {
        display: inline-block;
        width: calc(100% - 112px);
        vertical-align: top;
        padding: 0 50px 0 0;
    }

    .block-left-info-create-new-token p {
        color: #000000;
        font-family: "Stratos LC";
        font-size: 20px;
        line-height: 25px;
        font-weight: 300;
        margin: 0;
    }

    .block-right-info-create-new-token {
        display: inline-block;
        width: 112px;
        float: right;
        vertical-align: top;
    }

    .title-big-dot-list:after {
        content: '';
        width: 15px;
        height: 15px;
        background: #00F082;
        border-radius: 50%;
        top: 12px;
        left: 0;
        position: absolute;
    }

    .title-big-dot-list {
        position: relative;
        padding: 0 0 0 19px;
        margin: 0;
        color: #000000;
        font-family: "Stratos LC";
        font-size: 30px;
        font-weight: 500;
        line-height: 39px;
    }

}

@media screen and (max-width: 750px) {

    .shell-footer {
        max-width: 1140px;
        margin: 0 auto;
        padding: 0 20px;
        overflow: hidden;
    }

    .block-left-footer {
        float: none;
        width: 100%;
    }
    .block-right-footer {
        /*float: none;*/
        /*width: 100%;*/
        /*text-align: left;*/
        padding: 70px 0 0 0;
    }

    .title-header-body-left {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 40px;
        letter-spacing: -1px;
        line-height: 50px;
        margin: 0;
        font-weight: 300;
    }

    .block-info-create-token .all-tokens {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 18px;
        line-height: 23px;
        font-weight: 300;
        margin: 0 20px 0 0;
        display: inline-block;
        vertical-align: top;
    }

    .block-info-create-token .all-tokens a {
        color: #00F082;
        transition: .3s;
        padding: 0 8px 0 0;

    }

    .block-info-create-token .all-tokens a:hover {
        color: #0000D3;
    }

    .block-info-create-token .create-last {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 18px;
        line-height: 23px;
        display: inline-block;
        margin: 0;
        font-weight: 300;
        vertical-align: top;
    }

    .block-input-create-new-token input {

        display: block;
        width: 100%;
        height: 32px;
        border: 0;
        vertical-align: top;
        background: #FFFFFF;
    }

    .block-input-create-new-token .button-2-0-green {

        display: block;
        width: 100% !important;
        vertical-align: top;
        min-width: 100px;
    }

    .block-left-info-create-new-token {
        display: inline-block;
        width: calc(100% - 112px);
        vertical-align: top;
        padding: 0 20px 0 0;
    }

    .block-left-info-create-new-token p {
        color: #000000;
        font-family: "Stratos LC";
        font-size: 16px;
        line-height: 20px;
        font-weight: 300;
        margin: 0;
    }

    .block-right-info-create-new-token {
        display: inline-block;
        width: 112px;
        float: right;
        vertical-align: top;
    }

    .title-big-dot-list:after {
        content: '';
        width: 15px;
        height: 15px;
        background: #00F082;
        border-radius: 50%;
        top: 12px;
        left: 0;
        position: absolute;
    }

    .title-big-dot-list {
        position: relative;
        padding: 0 0 0 19px;
        margin: 0;
        color: #000000;
        font-family: "Stratos LC";
        font-size: 30px;
        font-weight: 500;
        line-height: 39px;
    }
}

@media (max-width: 576px) {
    .input-button-footer-new .button-footer-new,
    .input-button-footer-new .col-8 {
        max-width: unset;
    }
    .block-right-footer {
        text-align: left;
    }
    .block-right-footer img{
        margin: 0 0 27px 0;
    }
}
@media screen and (max-width: 550px) {
    .title-header-body-left {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 30px;
        letter-spacing: -1px;
        line-height: 40px;
        margin: 0;
        font-weight: 300;
    }

    .block-info-create-token .all-tokens {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 12px;
        line-height: 23px;
        font-weight: 300;
        margin: 0 20px 0 0;
        display: inline-block;
        vertical-align: top;
    }

    .block-info-create-token .all-tokens a {
        color: #00F082;
        transition: .3s;
        padding: 0 8px 0 0;

    }

    .block-info-create-token .all-tokens a:hover {
        color: #0000D3;
    }

    .block-info-create-token .create-last {
        color: #FFFFFF;
        font-family: "Stratos LC";
        font-size: 12px;
        line-height: 23px;
        display: inline-block;
        margin: 0;
        font-weight: 300;
        vertical-align: top;
    }

    .block-left-info-create-new-token {
        display: inline-block;
        width: calc(100% - 75px);
        vertical-align: top;
        padding: 0 10px 0 0;
    }

    .block-left-info-create-new-token p {
        color: #000000;
        font-family: "Stratos LC";
        font-size: 14px;
        line-height: 16px;
        font-weight: 300;
        margin: 0;
    }

    .block-right-info-create-new-token {
        display: inline-block;
        width: 75px;
        float: right;
        vertical-align: top;
    }

    .title-big-dot-list:after {
        content: '';
        width: 9px;
        height: 9px;
        background: #00F082;
        border-radius: 50%;
        top: 8px;
        left: 0;
        position: absolute;
    }

    .title-big-dot-list {
        position: relative;
        padding: 0 0 0 12px;
        margin: 0;
        color: #000000;
        font-family: "Stratos LC";
        font-size: 20px;
        font-weight: 500;
        line-height: 26px;
    }

}




.block-input-button-footer-new{
    width: 100%;
    display: table;
    margin: 0 0 25px 0;
}
p.title-input-button-footer-new{
    color: #FDFDFD;	font-family: "Stratos LC";	font-size: 14px;	font-weight: 400;	line-height: 24px;
    margin: 0;
    text-align: left;
}
.input-button-footer-new{
    width: 100%;
    display: table;
    height: 41px;
    padding: 8px 0 0 0;
}
.input-button-footer-new input{
    height: 41px;	width: 188px;	border-radius: 4px;	background-color: #FFFFFF;
    color: rgba(0,0,0,0.8);
    font-family: "Stratos LC";	font-size: 14px;	line-height: 41px;
    margin: 0;
    padding: 0 12px;
    border: 0;
    outline: none;
    float: left;
}
.input-button-footer-new .button-footer-new{
    height: 41px;	width: 100px;	border-radius: 6px;	background-color: #0ECFAA;
    float: left;
    margin: 0 0 0 12px;
    cursor: pointer;
}
.input-button-footer-new .button-footer-new p.title-button-footer-new{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 41px;	text-align: center;
    margin: 0;
}
