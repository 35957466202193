.startupButton {
  background: #FFFFFF;
  border: 1px solid #D0D0D0;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  min-height: 36px;
  overflow: hidden;
  cursor: pointer;

  .blockLeft {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 9.5px;
    font-size: 16px;
    line-height: 18px;
    color: #2F3849;
    font-weight: 600;
    transform: translateY(-65%);

  }

  .blockShare {

    width: 50%;
    display: flex;
    justify-content: space-between;

  }

  .blockRight {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-right: 9px;
    margin-top: 12px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 15px;
    text-align: right;
    color: rgba(0, 0, 0, 0.5);


  }

  .blockImg {
    display: inline-block;
    background-size: contain;
    height: 100%;
    width: 18px;
    margin-left: 10.5px;
    cursor: pointer;
    background-position: 10% 55%;
    background-repeat: no-repeat;

  }

  &.like {
    .blockLikeImg {
      background-image: url('../../../../images/icon/Hearth.svg');
    }

    .blockLikeImg.active {
      background-image: url('../../../../images/icon/HearthActive.svg');
      background-position: 10% 55%;


    }
  }

  &.wait {
    margin-bottom: 6px;

    .blockWaitImg {
      background-image: url('../../../../images/icon/Wait.svg');
    }

    .blockWaitImg.active {
      background-image: url('../../../../images/icon/WaitActive.svg');
    }

    .blockLeft{
      font-size: 14px;
      line-height: 18px;
      white-space: nowrap;
      font-weight: normal;
    }
    .blockRight{
      white-space: nowrap;
      font-size: 12px;
      line-height: 15px;
      font-weight: normal;
    }


    &.disable_behavior{
      .wrap{
        transition: transform 0.12s linear;
        display: flex;
        flex: 0 0 200%;
        .two,
        .one{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex: 0 0 50%;
        }
      }
    }

    &:not(.disable_behavior){
      &:hover{
        .wrap{
          transform: translate3d(-50%,0, 0);
        }
      }

      .wrap{
        transition: transform 0.12s linear;
        display: flex;
        flex: 0 0 200%;

        .two,
        .one{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex: 0 0 50%;
        }
      }
    }

  }

  &.share {

    .blockShareImg {

      background-image: url('../../../../images/icon/Share.svg');

    }

    .blockShareImg.active {
      background-image: url('../../../../images/icon/ShareActive.svg');


    }

    .blockSocialIcon {
      display: flex;
      justify-content: space-around;

      width: 50%;



      .blockImgIcon {
        display: inline-block;
        background-size: contain;
        height: 100%;
        width: 24px;
        margin-left: 10.5px;
        background-position: 10% 65%;
        background-repeat: no-repeat;

      }

      .iconInst {
        background-image: url('../../../../images/icon/Insta.svg');
      }

      .iconInst:hover {
        background-image: url('../../../../images/icon/InstaActive.svg');
      }

      .iconFacebook {
        background-image: url('../../../../images/icon/Facebook.svg');
      }

      .iconFacebook:hover {
        background-image: url('../../../../images/icon/FacebookActive.svg');
      }

      .iconTwitter {
        background-image: url('../../../../images/icon/Twitter.svg');
      }

      .iconTwitter:hover {
        background-image: url('../../../../images/icon/TwitterActive.svg');
      }

    }

    .blockForAnimation {
      display: flex;
      width: 200%;
      flex: 0 0 200%;
      cursor: pointer;
      transition: transform ease 1s;

    }

    .blockForAnimation:hover {
      transform: translateX(-50%);


    }
  }
}


