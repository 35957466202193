.item-user-investor {
    cursor: pointer;
}
.item-user-investor:hover {
    background-color: blue;
    color: white;
}
.item-user-investor.active {
    background-color: green;
    color: white;
}
.block-item-cart-activity-reg-expert.active {
    border-color: #0ECFAA;
}
