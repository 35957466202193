.block-header-mini-reg {
    width: 100%;
    min-height: 54px;
    padding: 19px 0;
}

.block-header-mini-reg a {
    display: block;
    text-decoration: none;
}

.block-header-mini-reg a img {
    cursor: pointer;
}

.shell-container-mini-reg {
    width: 100%;
    min-height: 100vh;
    background: #FDFDFD;
    overflow-x: auto;
}

.block-container-mini-reg {
    max-width: 370px;
    margin: 0 auto;
    min-height: 100vh;
    position: relative;
    padding: 0 25px 20px 25px;
}

.block-auth-mini-reg {
}

.block-reg-start-up-mini-reg {
    padding-bottom: 60px;
    position: relative;
    min-height: calc(100vh - 160px);
}

.block-auth-mini-reg p.title-auth-mini-reg {
    font-size: 30px;
    line-height: 37px;
    color: rgba(8, 8, 8, 0.8);
    font-weight: 500;
    margin: 20px 0 0 0;
}

.block-auth-mini-reg .block-list-button-auth-mini-reg {
    margin: 40px 0 0 0;
}

.block-button-auth-mini-reg{}
.block-button-auth-mini-reg .block-button-reg-social{
    margin: 0 0 15px 0;
}
.block-auth-email-mini-reg{
    margin: 102px 0 0 0;
}





.block-reg-email-mini-reg {
}

.block-reg-email-mini-reg p.title-reg-email-mini-reg {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    color: rgba(8, 8, 8, 0.8);
    margin: 20px 0;
}

.block-input-reg-email-mini-reg {
}

.block-input-reg-email-mini-reg p.title-input-reg-email-mini-reg {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.75;
    font-weight: 500;
    margin: 0 0 10px 0;
}

.block-input-reg-email-mini-reg .block-new-input {
    height: 59px;
}

.block-input-reg-email-mini-reg .block-new-input input {
    height: 41px !important;
    line-height: normal !important;
}

.block-select-state-mini-reg {
}

.block-select-state-mini-reg p.title-select-state-mini-reg {
    font-size: 30px;
    line-height: 37px;
    color: rgba(8, 8, 8, 0.8);
    font-weight: 500;
    margin: 20px 0 0 0;
}

.block-select-state-mini-reg p.description-select-state-mini-reg {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    color: rgba(8, 8, 8, 0.8);
    margin: 35px 0 0 0;
}

.block-button-description {
}

.block-button-description p.description-button {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    min-height: 36px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    margin: 5px 0 15px 0;
}

.block-reg-state-mine-reg {
}

.block-reg-state-mine-reg p.block-title-reg-state-mine-reg {
    font-size: 30px;
    line-height: 37px;
    color: rgba(8, 8, 8, 0.8);
    font-weight: 500;
    margin: 0;
}

.block-reg-state-mine-reg p.block-description-reg-state-mine-reg {
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    color: rgba(8, 8, 8, 0.8);
    margin: 15px 0 0 0;
}

.block-reg-start-up-mini-reg {
}

.block-reg-start-up-mini-reg .block-button-mini-reg {
    margin: 0 0 40px 0;
}

.block-list-tabs-mini-reg {
    margin: 40px 0 0 0;
}

.block-list-tabs-mini-reg .block-button-tab {
    margin: 0 12px 12px 0;
}

.block-check-box-mini-reg {
    width: 100%;
    display: table;
    margin: 8px 0 0 0;
}

.block-check-box-mini-reg .block-left-check-box-mini-reg {
    width: 23px;
    height: 23px;
    float: left;
}

.block-check-box-mini-reg .block-right-check-box-mini-reg {
    width: calc(100% - 23px);
    padding: 0 0 0 11px;
    float: left;
}

.block-check-box-mini-reg .block-right-check-box-mini-reg p.title-check-box-mini-reg {
    font-size: 16px;
    line-height: 23px;
    font-weight: 400;
    color: rgba(8, 8, 8, 0.8);
    margin: 0;
}

.bg-end-reg-mini-reg {
    width: calc(100% + 50px);
    margin: 0 -25px;
    height: 300px;
    background-size: contain !important;
    background-position: center!important;
    background-repeat: no-repeat!important;
}

.block-reg-start-up-mini-reg .block-button-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.block-button-bottom {
    width: calc(100% - 50px);
    position: absolute;
    bottom: 60px;
}

.block-button-bottom-20 {
    width: 100%;
    position: absolute;
    bottom: 20px;
}

.block-button-bottom-state {
    /*width: calc(100% - 50px);*/
    /*position: absolute;*/
    /*bottom: -30px;*/
    margin-top: 30px;
}

.round-b{
    width: 31px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.103487);
    border-radius: 15.5px;
    margin-top: 10px;
}