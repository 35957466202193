.block-profile-to-list {
	margin-top: 16px;
	height: 32px;
	cursor: pointer;
}
.block-profile-to-list .profile-avatar {
	float: left;
	width: 32px;
	height: 32px;
	border-radius: 2px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
.block-profile-to-list .wrapper {
	width: 145px;
	height: 32px;
	float: left;
	margin-left: 8px;
	white-space: nowrap;
	
	
}
.block-profile-to-list .profile-name {
	overflow: hidden;
	text-overflow: ellipsis;
	font-family: "Stratos LC";
	font-size: 16px;
	letter-spacing: -0.708333px;
	color: #FFFFFF;

}
.block-profile-to-list .edit-title {
	font-family: "Stratos LC";
	font-size: 12px;
	line-height: 15px;
	color: #fff;
}


.block-profile-to-list .edit-title:hover{
	color: #979797;
}


.block-profile-to-list .glue{
	height: 6px;
}