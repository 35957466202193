.block-select{
    width: 100%;
    height: 48px;
    border-radius: 6px;
    position: relative;
}
.block-top-select{
    width: 100%;
    height: 34px;
    overflow: hidden;
    position: relative;
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 1px solid rgba(151,151,151,0.45);
    box-sizing: border-box;
    z-index: 9;
}
.block-top-select.active input{
    opacity: 1;
}
.block-top-select input{
    width: 100%;
    height: 32px;
    border: 0;
    outline: 0;
    padding: 8px 40px 8px 16px;
    line-height: 16px;
    color: #080808;	font-family: "Stratos LC";
    font-size: 12px;
    margin: 0;
    font-weight: 400;
    border-radius: 6px;
    z-index: 2;
    position: relative;
    opacity: 0;
}
.block-select-with-add input{
    padding: 8px 90px 8px 16px;
}
.block-top-select.error {
    border: 1px solid #E43A70;
    opacity: 1 !important;
}
.block-top-select p.title-select{
    color: #080808;	font-family: "Stratos LC";
    font-size: 12px;
    margin: 0;
    font-weight: 400;
    line-height: 32px;
    padding: 0 40px 0 16px;
    position: absolute;
    width: 100%;
    height: 32px;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 1;
    text-align: left!important;
}
.block-top-select p.title-select.placeholder{

}
.block-top-select img{
    position: absolute;
    width: 10px;
    height: 10px;
    color: rgba(0,0,0,0.8);
    top:11px;
    right: 15px;
    cursor: pointer;
    z-index: 2;
}

.block-body-select{
    border-left: 1px solid rgba(151,151,151,0.45);
    border-right: 1px solid rgba(151,151,151,0.45);
    border-bottom: 1px solid rgba(151,151,151,0.45);
    border-radius: 0 0 6px 6px;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.11);
    /*background-color: #FFFFFF;*/
    width: calc(100% - 2px);
    height: 200px;
    overflow: hidden;
    position: absolute;
    top: 30px;
    z-index: 21;
    padding: 4px 0 0 0;
    box-sizing: content-box;
    transition: .3s;
}
.block-body-select{opacity: 0}
.block-body-select.active{opacity: 1}
span.gradient-select{
    width: 100%;
    position: absolute;
    display: block;
    height: 15px;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    bottom: 0;
}
.block-body-select .block-list-item-select{}
.block-body-select .block-list-item-select .block-item-select{
    width: 100%;
    height: 34px;
    overflow: hidden;
    cursor: pointer;
    transition: .3s;
    background-color: #ffffff;
}
.block-body-select .block-list-item-select .block-item-select:hover{
    background-color: #ECECEC;
}

.block-body-select .block-list-item-select .block-item-select p.title-item-select{
    color: #080808;	font-family: "Stratos LC";	font-size: 12px;	line-height: 34px;
    margin: 0; font-weight: 400; padding: 0 40px 0 16px;text-align: left!important;
}
.title-error-select{
    color: #E43A70;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    margin: 4px 0 0 0;
    padding: 0 0 0 16px;
    position: absolute;
    text-align: left!important;
    bottom: -3px;
}
.select-add-new-btn {
    position: absolute;
    right: 30px;
    top: 0;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
}
.select-add-new-btn.text-add-new {
    font-size: 12px;
    line-height: 40px;
    color: #2CA6EB;
    opacity: 0;
    font-weight: 500;
}
.block-top-select.active .select-add-new-btn {
    opacity: 1;
    z-index: 1000;
}
.block-top-select.active .select-add-new-btn.text-add-new {
    opacity: 0.7;
}
.block-top-select .loader-button img{
    filter: invert(27%) sepia(51%) saturate(2878%) hue-rotate(100deg) brightness(0%) contrast(0%);
    width: 25px;
    height: 25px;
    top: 6px;
}