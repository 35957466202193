.block-list-section-forum{
    width: 100%;
    overflow: hidden;
    padding: 0 0 15px 0;
}

.block-top-list-section-forum{
    width: 100%;
    height: 50px;
    overflow: hidden;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: #CCCCCC;
}
.block-top-list-section-forum .block-title{
    float: left;
    width: calc(100% - 200px);
    height: 50px;
    position: relative;
}
.block-top-list-section-forum .block-title p{
margin: 0;
    color: #000000;	font-family: "Stratos LC";	font-size: 18px;	letter-spacing: -0.39px;	line-height: 50px; font-weight: 400;
}
.block-top-list-section-forum .block-button-section-forum{
    width: 200px;
    float: right;
    height: 32px;
    margin: 9px 0;
}
.block-top-list-section-forum .block-button-section-forum .block-button-2-0 p{
    font-weight: 400!important;
    font-size: 15px!important;
}
.block-top-list-section-forum .block-button-section-forum .block-button-2-0{
    float: right;
    background: #FFFFFF;
    /*border: 2px solid #0028DC;*/
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.03);
}


.block-list-thread-section-forum{
    width: 100%;
    overflow: hidden;
    padding: 0 0 0 0;
    box-sizing: border-box;
    background: #FFFFFF;
}
.block-item-thread-section-forum{
    border-bottom: 1px solid #CFCFCF;
    height: 60px;
    width: 100%;
    padding: 0 20px 0 0;
    box-sizing: border-box;
    cursor: pointer;
}
.block-item-thread-section-forum:last-child{
    border: 0;
}
.block-column-state-thread-section-forum{
    width: 60px;
    height: 60px;
    float: left;
}
.block-state{
    width: 60px;
    height: 60px;
}
.block-state span.state{
    display: block;
    width: 10px;
    height: 10px;
    margin: 25px;
    border-radius: 50%;
}
.block-state span.state.state-blue{
    background: #0028DC;
}


.block-column-title-thread-section-forum{
    float: left;
    height: 60px;
    width: calc(100% - 330px);
    padding: 9px 0;
    position: relative;
}
.block-column-title-thread-section-forum p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 20px;	font-weight: 600;	letter-spacing: -0.25px;	line-height: 26px;
    margin: 0; font-weight: 400;    text-overflow: ellipsis; width: 100%;
    white-space: nowrap;
    overflow: hidden;
}
.block-column-title-thread-section-forum p.description{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 13px;
    margin: 0; font-weight: 300;    text-overflow: ellipsis; width: 100%;
    white-space: nowrap;
    overflow: hidden;
}
.block-column-title-thread-section-forum:hover .link-delete-one {
    display: inline-block;
}
.block-column-title-grid-forum-thread {
    position: relative;
}
.block-column-title-grid-forum-thread:hover .link-delete-one {
    display: inline-block;
}

.block-column-user-thread-section-forum{
    float: left;
    height: 60px;
    width: 170px;
    padding: 11px 0;
}
.block-column-user-thread-section-forum a.user-link{
    color: #0028DC;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 13px;
    text-decoration: none; font-weight: 300;
}
.block-column-user-thread-section-forum p.up-date{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 13px; margin: 5px 0 0 0; font-weight: 300;
}

.block-column-info-thread-section-forum{
    width: 100px;
    height: 60px;
    float: right;
    padding: 15px 0;
    box-sizing: border-box;
}
.block-column-info-thread-section-forum p{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 15px;	letter-spacing: -0.19px;	line-height: 16px; margin: 0;
    text-align: right;
}
.block-column-info-thread-section-forum p:last-child{
    font-weight: 300;
}



.block-top-title-button-forum{
    overflow: hidden;
    height: 40px;
    margin: 0 0 20px 0;
}
.block-top-title-button-forum p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 30px;	letter-spacing: -0.64px;	line-height: 38px;
    font-weight: 400; margin: 0;
    float: left;
}
.block-top-title-button-forum.button-right .block-button-2-0{
    border-radius: 3px!important;
    float: right!important;


}
.block-top-title-button-forum .block-button-2-0{
    float: left;
    margin: 4px 0 0 20px ;
    border-radius: 3px;
    background: #FFFFFF!important;
    box-shadow: 0 2px 9px 0 rgba(0,0,0,0.03);
}
.block-top-title-button-forum .block-button-2-0 p{
    font-weight: 400!important;
    font-size: 15px!important;
}
.block-bread{
    overflow: hidden;
    height: 15px;
    margin: 0 0 30px 0
}
.block-bread a{
    float: left;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;
    font-weight: 300; margin: 0 8px 0 0;
}
.block-bread span{
    float: left;
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;
    font-weight: 300; margin: 0 8px 0 0;
}
.block-bread p{
    float: left;
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;
    font-weight: 300; margin: 0 8px 0 0;
}

.text-right{
    text-align: right;
}
.column-height{
    height: 50px!important;
}





.shell-post-message{
    width: 100%;
    /*overflow: hidden;*/
}
.block-post-message{
    width: 100%;
    /*overflow: hidden;*/
}

.block-top-post-message{
    width: 100%;
    overflow: hidden;
    height: 42px;
    background-color: #CCCCCC;
    padding: 0 20px;
    box-sizing: border-box;
}
.block-title{
    width: calc(100% - 200px);
    float: left;
}
.block-title p{
    color: #000000;	font-family: "Stratos LC";	font-size: 18px;	letter-spacing: -0.39px;	line-height: 42px;
    margin: 0; font-weight: 400;
}
.block-input-search{
    width: 200px;
    float: right;

}

.block-input-search .block-input-DAO-new-icon{height: 42px;margin: 0!important;}
.block-input-search .block-input-DAO-new-icon input{}
.block-body-post-message{
    width: 100%;
    /*overflow: hidden;*/
}
.block-list-post-message{
    width: 100%;
    /*overflow: hidden;*/
}

.block-item-post-message{
    border-bottom: 1px solid #CFCFCF;
    padding: 20px 140px 20px 20px;
    box-sizing: border-box;
    display: table;
    background: #ffffff;
    width: 100%;
}
.background-white{
    background: #FFFFFF;
}
.block-left-post-message{
    width: 170px;
    float: left;
}
.block-left-post-message .block-title{
    width: 100%;
    margin: 0 0 20px 0;
    float: none!important;
}
.block-left-post-message .block-title p{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
    margin: 0; font-weight: 400;
}
.block-left-post-message .block-img{
    width: 100%;
    height: 90px;
    margin: 0 0 20px 0;
}
.block-left-post-message .block-img img{
    width: 90px;
    height: 90px;
    margin: 0;
    border-radius: 50%;
    display: block;
    background: #0000d3;
}
.block-left-post-message .block-info{
    width: 100%;
    overflow: hidden;
}
.block-left-post-message .block-info .block-item-info{
    margin: 0;
}
.block-left-post-message .block-info .block-item-info i{
    float: left;
    color: #A2A6AF;
    margin: 0 5px 0 0;
    font-size: 12px;
    line-height: 15px;
}
.block-left-post-message .block-info .block-item-info p{
    color: #0028DC;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 15px;
    float: left; margin: 0;
}


.block-right-post-message{
    width: calc(100% - 170px);
    float: right;
}
.block-right-post-message p.date{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 15px;
    font-weight: 300; margin: 0 0 10px 0;
}
.block-right-post-message div.description{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
    font-weight: 400; margin: 0 0 20px 0;
    /*min-height: 100px;*/
}

.block-right-post-message div.description {
    /*min-height: 100px;*/
}

.block-right-post-message p.description span.reply{
    background-color: #F1F2F2;
    display: block;
    padding: 15px;
}
.block-right-post-message{}
.block-right-post-message .title-preview{
    color: #00F082;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
    margin: 0 0 10px 0; font-weight: 400;
}
.block-right-post-message .block-link-post-message{
    text-align: right;
}
.block-right-post-message .block-link-post-message a{
    margin: 0 0 0 20px;
    display: inline-block;
    font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
}
.block-right-post-message .block-link-post-message a.blue{
    color: #0028DC;
    text-decoration: underline;
    cursor: pointer;
}
.block-right-post-message .block-link-post-message a.blue.grey {
    color: #b0b0b0;
    cursor: inherit;
}

.block-right-post-message .block-link-post-message a.red{	color: #FA4600;}

.block-right-post-message .attached-image{
    max-width: 80%;
}
.block-right-post-message .attached-image img {
    max-width: 100%;
}

.block-pagin{
padding: 0 120px 0 0;
    border-bottom: 1px solid #CFCFCF;
    margin-bottom: 30px;
}
.block-pagin p{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 25px;	text-align: right;
    font-weight: 300;
    margin: 0;
    padding: 2px 0;
}
.block-pagin p span{
    display: inline-block;
    margin: 0 2px;
    padding: 0 5px;
    cursor: pointer;
}

.block-pagin p span:hover,
.block-pagin p span.active{
    background-color: #D8D8D8;
}

.block-add-new-message-post{
    overflow: hidden;
    margin: 0 0 0 0;
    padding: 0 120px 0 0 ;
}
.block-left-add-new-message-post{
    width: 170px;
    float: left;
}

.block-left-add-new-message-post .block-title{
    width: 100%;
    margin: 0 0 20px 0;
    float: none!important;
}
.block-left-add-new-message-post .block-title p{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
    margin: 0; font-weight: 400;
}
.block-left-add-new-message-post .block-img{
    width: 100%;
    height: 90px;
    margin: 0 0 20px 0;
}
.block-left-add-new-message-post .block-img img{
    width: 90px;
    height: 90px;
    margin: 0;
    border-radius: 50%;
    display: block;
    background: #0000d3;
}

.block-right-add-new-message-post{
    width: calc(100% - 170px);
    float: right;
}
.block-right-add-new-message-post textarea{
    width: 100%;
    height: 200px;
    resize: none;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
    margin: 0 0 0 0;
}
.block-right-add-new-message-post a{
    color: #0028DC;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
    font-weight: 300;
    margin: 0; text-decoration: underline;
    cursor: pointer;
}
.block-right-add-new-message-post p.img-load{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;
    margin: 0 ; font-weight: 300;
}
.block-right-add-new-message-post p.img-title{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;
    margin: 0 0 0 20px; font-weight: 300; display: inline-block;
}
.block-right-add-new-message-post .block-button-list{
    margin: 20px 0 0 0;
}
.block-right-add-new-message-post .block-button-list .block-button-2-0{
    margin: 0 10px 0 0;
}

div.error.textarea-error,
p.error.textarea-error {
    width: 100%; height: 16px;
    color: #FA4600;	font-family: "Stratos LC";	font-size: 13px;	line-height: 16px; font-weight: 300; margin: 0 0 10px 0;

}


.block-add-new-forum{
    /*width: 950px;*/
    overflow: hidden;
    /*padding: 20px;*/
    background: #FFFFFF;
}
.block-left-add-new-forum{
    width: 120px;
    overflow: hidden;
    float: left;
    padding: 0 20px 0 0;
    box-sizing: border-box;
}
.block-name-user{

}
.block-name-user p{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
    margin: 0 0 10px 0; font-weight: 400;
}
.block-img-user{
    height: 87px;
    width: 100%;
    margin: 0 0 20px 0;
}

.block-img-user img{
    height: 87px;	width: 87px;
    border-radius: 50%;
    background: #0000d3;
    display: block;
}


.block-right-add-new-forum{
    width: calc(100% - 120px);
    overflow: hidden;
    float: left;
}
.block-title-input{
    width: 100%;
    overflow: hidden;
    margin: 0 0 20px 0;
}
.block-title-input p{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.21px;	line-height: 20px;
    margin: 0 0 10px 0;
}
.block-title-input p.capitalize {
    text-transform: capitalize;
}

.block-title-input input{
    height: 30px;
    line-height: 30px;
    width: 100%;
}

.block-description-title{
    margin: 0 0 10px 0;
}
.block-add-new-forum .error {
    width: 100%;
    height: 16px;
    color: #FA4600;
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 16px;
    font-weight: 300;
    margin: 0 0 0px 0 ;

}
.block-description-title p{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.21px;	line-height: 20px;
    margin: 0 0 10px 0;
}
.block-description-title textarea{
    height: 70px;
    width: 100%;
    resize: none;
}

.block-link{
    margin: 0 0 20px 0;
}
.block-link a{
    color: #0028DC;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
    margin: 0;
    text-decoration: underline; cursor: pointer;
}
.block-link p{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.17px;	line-height: 16px;
    margin: 0;font-weight: 300;
}

.block-button-add-forum{}


.shell-person-info{
    position: relative;
}
.block-person-info{

}
.block-cart-info-person{
    width: 290px;
    overflow: hidden;
    padding: 20px 30px;
    background: #FFFFFF;
    position: absolute;
    top: -20px;
    left: -335px;
    z-index: 100;
    transition: .3s;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
}
.block-cart-info-person.active{
    left: -20px;
}

.block-img-title-status{
    height: 90px;
}
.block-left-img{
    width: 90px;
    height: 90px;
    float: left;
}
.block-left-img img{
    width: 90px;
    height: 90px;
    display: block;
    /*background: #002BDE;*/
    border-radius: 50%;
}
.block-right-title-status{
    width: calc(100% - 90px);
    float: left;
    padding: 0 0 0 20px;
    box-sizing: border-box;
}
.block-right-title-status p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 19px;	letter-spacing: -0.16px;	line-height: 24px;
    margin: 0; font-weight: 400;
}
.block-right-title-status p.role {
    color: #4A4A4A;
    font-size: 14px;
}
.block-right-title-status span.status{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.17px;	line-height: 15px;	text-align: center;
    margin: 0; font-weight: 300; display: inline-block; padding: 0 10px; border-radius: 15px;
}
.block-right-title-status span.status.online{
    background-color: #00F082;
}
.block-right-title-status span.status.offline{
    background-color: #4A4A4A;
    color: #FFFFFF;
}

.block-list-icon-link{
    margin: 20px 0 0 0;
    overflow: hidden;
}
.block-list-icon-link a{
    float: left;
    margin: 0 15px 0 0;
}
.block-list-icon-link a i{
    float: left;
    color: #A2A6AF;
    margin: 0 5px 0 0;
    font-size: 12px;
    line-height: 15px;
}
.block-list-icon-link a span{
    color: #0028DC;
    font-family: "Stratos LC";
    font-size: 12px;
    letter-spacing: -0.15px;
    line-height: 15px;
    float: left;
    margin: 0;
}

.block-title-list{
    margin: 20px 0 0 0;
    overflow: hidden;
}
.block-title-list p.title-small{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 13px;	letter-spacing: -0.19px;	line-height: 16px;
    font-weight: 300; margin: 0 0 6px 0;
}
.block-title-list p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.2px;	line-height: 18px;
    font-weight: 300; margin: 0 0 6px 0;
}

.block-line{
    width: 100%;
    height: 10px;
    border-bottom: 1px solid #9B9B9B;
    margin: 0 0 10px 0;
}

.block-list-link a{
    color: #0028DC;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.2px;	line-height: 18px;
    margin: 0; font-weight: 300;
    text-decoration: underline;
    cursor: pointer;
}
.block-list-link a.left{
    float: left;
}
.block-list-link a.right{
    float: right;
}

.block-button-add-forum{

}

.preview-block {
    margin-bottom: 10px;
    /*width: calc(100% - 290px);*/
    /*margin-left: 170px;*/
    background-color: #eaeaea;
}

.block-item-post-message.bipm-main {
    background-color: #efeeee;
}

.reply-block {
    margin-bottom: 10px;
    background-color: #eaeaea;
    /* padding-right: 120px; */
    width: calc(100% - 290px);
    margin-left: 170px;
}





.rdw-option-wrapper {
    border: 1px solid #F1F1F1;
    padding: 5px;
    min-width: 25px;
    height: 20px;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
.rdw-option-wrapper:hover {
    box-shadow: 1px 1px 0px #BFBDBD;
}
.rdw-option-wrapper:active {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-option-active {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-option-disabled {
    opacity: 0.3;
    cursor: default;
}
.rdw-dropdown-wrapper {
    height: 30px;
    background: white;
    cursor: pointer;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    margin: 0 3px;
    text-transform: capitalize;
    background: white;
}
.rdw-dropdown-wrapper:focus {
    outline: none;
}
.rdw-dropdown-wrapper:hover {
    box-shadow: 1px 1px 0px #BFBDBD;
    background-color: #FFFFFF;
}
.rdw-dropdown-wrapper:active {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-dropdown-carettoopen {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-top: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.rdw-dropdown-carettoclose {
    height: 0px;
    width: 0px;
    position: absolute;
    top: 35%;
    right: 10%;
    border-bottom: 6px solid black;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}
.rdw-dropdown-selectedtext {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
    padding: 0 5px;
}
.rdw-dropdown-optionwrapper {
    z-index: 100;
    position: relative;
    border: 1px solid #F1F1F1;
    width: 98%;
    background: white;
    border-radius: 2px;
    margin: 0;
    padding: 0;
    max-height: 250px;
    overflow-y: scroll;
}
.rdw-dropdown-optionwrapper:hover {
    box-shadow: 1px 1px 0px #BFBDBD;
    background-color: #FFFFFF;
}
.rdw-dropdownoption-default {
    min-height: 25px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}
.rdw-dropdownoption-highlighted {
    background: #F1F1F1;
}
.rdw-dropdownoption-active {
    background: #f5f5f5;
}
.rdw-dropdownoption-disabled {
    opacity: 0.3;
    cursor: default;
}
.rdw-inline-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-inline-dropdown {
    width: 50px;
}
.rdw-inline-dropdownoption {
    height: 40px;
    display: flex;
    justify-content: center;
}
.rdw-block-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-block-dropdown {
    width: 110px;
}
.rdw-fontsize-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-fontsize-dropdown {
    min-width: 40px;
}
.rdw-fontsize-option {
    display: flex;
    justify-content: center;
}
.rdw-fontfamily-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-fontfamily-dropdown {
    width: 115px;
}
.rdw-fontfamily-placeholder {
    white-space: nowrap;
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.rdw-fontfamily-optionwrapper {
    width: 140px;
}
.rdw-list-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-list-dropdown {
    width: 50px;
    z-index: 90;
}
.rdw-list-dropdownOption {
    height: 40px;
    display: flex;
    justify-content: center;
}
.rdw-text-align-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-text-align-dropdown {
    width: 50px;
    z-index: 90;
}
.rdw-text-align-dropdownOption {
    height: 40px;
    display: flex;
    justify-content: center;
}
.rdw-right-aligned-block {
    text-align: right;
}
.rdw-left-aligned-block {
    text-align: left !important;
}
.rdw-center-aligned-block {
    text-align: center !important;
}
.rdw-justify-aligned-block {
    text-align: justify !important;
}
.rdw-right-aligned-block > div {
    display: inline-block;
}
.rdw-left-aligned-block > div {
    display: inline-block;
}
.rdw-center-aligned-block > div {
    display: inline-block;
}
.rdw-justify-aligned-block > div {
    display: inline-block;
}
.rdw-colorpicker-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-colorpicker-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 175px;
    height: 175px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-colorpicker-modal-header {
    display: flex;
    padding-bottom: 5px;
}
.rdw-colorpicker-modal-style-label {
    font-size: 15px;
    width: 50%;
    text-align: center;
    cursor: pointer;
    padding: 0 10px 5px;
}
.rdw-colorpicker-modal-style-label-active {
    border-bottom: 2px solid #0a66b7;
}
.rdw-colorpicker-modal-options {
    margin: 5px auto;
    display: flex;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    overflow: scroll;
}
.rdw-colorpicker-cube {
    width: 22px;
    height: 22px;
    border: 1px solid #F1F1F1;
}
.rdw-colorpicker-option {
    margin: 3px;
    padding: 0;
    min-height: 20px;
    border: none;
    width: 22px;
    height: 22px;
    min-width: 22px;
    box-shadow: 1px 2px 1px #BFBDBD inset;
}
.rdw-colorpicker-option:hover {
    box-shadow: 1px 2px 1px #BFBDBD;
}
.rdw-colorpicker-option:active {
    box-shadow: -1px -2px 1px #BFBDBD;
}
.rdw-colorpicker-option-active {
    box-shadow: 0px 0px 2px 2px #BFBDBD;
}
.rdw-link-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-link-dropdown {
    width: 50px;
}
.rdw-link-dropdownOption {
    height: 40px;
    display: flex;
    justify-content: center;
}
.rdw-link-dropdownPlaceholder {
    margin-left: 8px;
}
.rdw-link-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 205px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-link-modal-label {
    font-size: 15px;
}
.rdw-link-modal-input {
    margin-top: 5px;
    border-radius: 2px;
    border: 1px solid #F1F1F1;
    height: 25px;
    margin-bottom: 15px;
    padding: 0 5px;
}
.rdw-link-modal-input:focus {
    outline: none;
}
.rdw-link-modal-buttonsection {
    margin: 0 auto;
}
.rdw-link-modal-target-option {
    margin-bottom: 20px;
}
.rdw-link-modal-target-option > span {
    margin-left: 5px;
}
.rdw-link-modal-btn {
    margin-left: 10px;
    width: 75px;
    height: 30px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
.rdw-link-modal-btn:hover {
    box-shadow: 1px 1px 0px #BFBDBD;
}
.rdw-link-modal-btn:active {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-link-modal-btn:focus {
    outline: none !important;
}
.rdw-link-modal-btn:disabled {
    background: #ece9e9;
}
.rdw-link-dropdownoption {
    height: 40px;
    display: flex;
    justify-content: center;
}
.rdw-history-dropdown {
    width: 50px;
}
.rdw-embedded-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-embedded-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 180px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    justify-content: space-between;
    box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-embedded-modal-header {
    font-size: 15px;
    display: flex;
}
.rdw-embedded-modal-header-option {
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.rdw-embedded-modal-header-label {
    width: 95px;
    border: 1px solid #f1f1f1;
    margin-top: 5px;
    background: #6EB8D4;
    border-bottom: 2px solid #0a66b7;
}
.rdw-embedded-modal-link-section {
    display: flex;
    flex-direction: column;
}
.rdw-embedded-modal-link-input {
    width: 88%;
    height: 35px;
    margin: 10px 0;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 15px;
    padding: 0 5px;
}
.rdw-embedded-modal-link-input-wrapper {
    display: flex;
    align-items: center;
}
.rdw-embedded-modal-link-input:focus {
    outline: none;
}
.rdw-embedded-modal-btn-section {
    display: flex;
    justify-content: center;
}
.rdw-embedded-modal-btn {
    margin: 0 3px;
    width: 75px;
    height: 30px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
.rdw-embedded-modal-btn:hover {
    box-shadow: 1px 1px 0px #BFBDBD;
}
.rdw-embedded-modal-btn:active {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-embedded-modal-btn:focus {
    outline: none !important;
}
.rdw-embedded-modal-btn:disabled {
    background: #ece9e9;
}
.rdw-embedded-modal-size {
    align-items: center;
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
}
.rdw-embedded-modal-size-input {
    width: 80%;
    height: 20px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 12px;
}
.rdw-embedded-modal-size-input:focus {
    outline: none;
}
.rdw-emoji-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-emoji-modal {
    overflow: auto;
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-wrap: wrap;
    width: 235px;
    height: 180px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-emoji-icon {
    margin: 2.5px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rdw-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.rdw-spinner > div {
    width: 12px;
    height: 12px;
    background-color: #333;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.rdw-spinner .rdw-bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.rdw-spinner .rdw-bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
}
@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}
.rdw-image-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-image-modal {
    position: absolute;
    top: 35px;
    left: 5px;
    display: flex;
    flex-direction: column;
    width: 235px;
    border: 1px solid #F1F1F1;
    padding: 15px;
    border-radius: 2px;
    z-index: 100;
    background: white;
    box-shadow: 3px 3px 5px #BFBDBD;
}
.rdw-image-modal-header {
    font-size: 15px;
    margin: 10px 0;
    display: flex;
}
.rdw-image-modal-header-option {
    width: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.rdw-image-modal-header-label {
    width: 80px;
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    margin-top: 5px;
}
.rdw-image-modal-header-label-highlighted {
    background: #6EB8D4;
    border-bottom: 2px solid #0a66b7;
}
.rdw-image-modal-upload-option {
    width: 100%;
    color: gray;
    cursor: pointer;
    display: flex;
    border: none;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    outline: 2px dashed gray;
    outline-offset: -10px;
    margin: 10px 0;
    padding: 9px 0;
}
.rdw-image-modal-upload-option-highlighted {
    outline: 2px dashed #0a66b7;
}
.rdw-image-modal-upload-option-label {
    cursor: pointer;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
}
.rdw-image-modal-upload-option-label span{
    padding: 0 20px;
}
.rdw-image-modal-upload-option-image-preview {
    max-width: 100%;
    max-height: 200px;
}
.rdw-image-modal-upload-option-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.rdw-image-modal-url-section {
    display: flex;
    align-items: center;
}
.rdw-image-modal-url-input {
    width: 90%;
    height: 35px;
    margin: 15px 0 12px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 15px;
    padding: 0 5px;
}
.rdw-image-modal-btn-section {
    margin: 10px auto 0;
}
.rdw-image-modal-url-input:focus {
    outline: none;
}
.rdw-image-modal-btn {
    margin: 0 5px;
    width: 75px;
    height: 30px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}
.rdw-image-modal-btn:hover {
    box-shadow: 1px 1px 0px #BFBDBD;
}
.rdw-image-modal-btn:active {
    box-shadow: 1px 1px 0px #BFBDBD inset;
}
.rdw-image-modal-btn:focus {
    outline: none !important;
}
.rdw-image-modal-btn:disabled {
    background: #ece9e9;
}
.rdw-image-modal-spinner {
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
}
.rdw-image-modal-alt-input {
    width: 70%;
    height: 20px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 12px;
    margin-left: 5px;
}
.rdw-image-modal-alt-input:focus {
    outline: none;
}
.rdw-image-modal-alt-lbl {
    font-size: 12px;
}
.rdw-image-modal-size {
    align-items: center;
    display: flex;
    margin: 8px 0;
    justify-content: space-between;
}
.rdw-image-modal-size-input {
    width: 40%;
    height: 20px;
    border: 1px solid #F1F1F1;
    border-radius: 2px;
    font-size: 12px;
}
.rdw-image-modal-size-input:focus {
    outline: none;
}
.rdw-image-mandatory-sign {
    color: red;
    margin-left: 3px;
    margin-right: 3px;
}
.rdw-remove-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    position: relative;
}
.rdw-history-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}
.rdw-history-dropdownoption {
    height: 40px;
    display: flex;
    justify-content: center;
}
.rdw-history-dropdown {
    width: 50px;
}
.rdw-link-decorator-wrapper {
    position: relative;
}
.rdw-link-decorator-icon {
    position: absolute;
    left: 40%;
    top: 0;
    cursor: pointer;
    background-color: white;
}
.rdw-mention-link {
    text-decoration: none;
    color: #1236ff;
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 2px;
}
.rdw-suggestion-wrapper {
    position: relative;
}
.rdw-suggestion-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    border: 1px solid #F1F1F1;
    min-width: 100px;
    max-height: 150px;
    overflow: auto;
    background: white;
    z-index: 100;
}
.rdw-suggestion-option {
    padding: 7px 5px;
    border-bottom: 1px solid #f1f1f1;
}
.rdw-suggestion-option-active {
    background-color: #F1F1F1;
}
.rdw-hashtag-link {
    text-decoration: none;
    color: #1236ff;
    background-color: #f0fbff;
    padding: 1px 2px;
    border-radius: 2px;
}
.rdw-image-alignment-options-popup {
    position: absolute;;
    background: white;
    display: flex;
    padding: 5px 2px;
    border-radius: 2px;
    border: 1px solid #F1F1F1;
    width: 105px;
    cursor: pointer;
    z-index: 100;
}
.rdw-alignment-option-left {
    justify-content: flex-start;
}
.rdw-image-alignment-option {
    height: 15px;
    width: 15px;
    min-width: 15px;
}
.rdw-image-alignment {
    position: relative;
}
.rdw-image-imagewrapper {
    position: relative;
}
.rdw-image-center {
    display: flex;
    justify-content: center;
}
.rdw-image-left {
    display: flex;
}
.rdw-image-right {
    display: flex;
    justify-content: flex-end;
}
.rdw-image-alignment-options-popup-right {
    right: 0;
}
.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    /*height: 200px !important;*/
    /*border: 1px solid #F1F1F1 !important;*/
    padding: 5px !important;
    /*border-radius: 2px !important;*/
    background-color: #ffffff;
}
.rdw-editor-toolbar {
    padding: 6px 5px 0;
    border-radius: 2px;
    border: 1px solid #F1F1F1;
    display: flex;
    justify-content: flex-start;
    background: white;
    flex-wrap: wrap;
    font-size: 15px;
    /*margin-bottom: 5px;*/
    user-select: none;
}
.public-DraftStyleDefault-block {
    margin: 1em 0;
}
.rdw-editor-wrapper:focus {
    outline: none;
}
.rdw-editor-wrapper {
    box-sizing: content-box;
}
.rdw-editor-main blockquote {
    border-left: 5px solid #f1f1f1;
    padding-left: 5px;
}
.rdw-editor-main pre {
    background: #f1f1f1;
    border-radius: 3px;
    padding: 1px 10px;
}

.block-right-post-message div.description p {
    margin: 0;
}
.message-content-wrapper {
    min-height: 100px;
}
.continue-editing {
    color: #0028DC;
    font-size: 13px;
    display: inline-block;
    margin-left: 10px;
    padding-top: 14px;
}
.continue-editing:hover {
    text-decoration: underline;
    cursor: pointer;
}
.text-preview-green {
    color: #00F082;
    margin: 0 0 10px 0;
}

.block-item-post-message strong,
.block-item-post-message b {
    font-weight: 500!important;
}


.block-cart-info-person {
    /*border: 1px solid #909090;*/
    /*display: none;*/
}
.shell-person-info:hover .block-cart-info-person {
    /*display: block;*/
    left: -20px;
}

.reply-post {
    background-color: #F1F2F2;
    border: 1px solid #d4d4d4;
    padding: 5px;
    margin-bottom: 10px;
}
.reply-post .reply-post {
    margin: 10px;
}
.reply-post p.reply-name {
    margin-top: 0;
    font-size: 14px;
    font-weight: 500;
}

.remove-image {
    color: #dc0725;
    font-family: "Stratos LC";
    font-size: 12px;
    letter-spacing: -0.17px;
    line-height: 20px;
    font-weight: 300;
    margin: 0;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    padding-left: 10px;
}
.image-id {
    margin-top: 0;
}





.shell-banner-forum-topic{
    width: 100%;
    height: 73px;
    background-color: #0028DC;
    position: fixed;
    bottom: -73px;
    transition: .3s;
    left: 0;
    z-index: 50;
}
.shell-banner-forum-topic.active{
    bottom: 0;
}
.block-banner-forum-topic{
    overflow: hidden;
    height: 73px;
    width: 100%;
}
.block-left-banner-forum-topic{
    float: left;
    height: 73px;
    width: calc(100% - 300px);
}
.block-left-banner-forum-topic p.title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 17px;	letter-spacing: -0.16px;	line-height: 21px;
    margin: 15px 0; font-weight: 300;
}
.block-right-banner-forum-topic{
    float: right;
    height: 73px;
    width: 300px;
}
.block-right-banner-forum-topic .block-button-2-0{
    float: right;
    margin: 16px 0;
}
.block-right-banner-forum-topic .block-button-2-0 p{
    font-weight: 400!important;
}

.shell-footer-banner{
    padding: 0 0 70px 0;
    background-color: #000000;
}
.link-delete-one {
    color: #FA4600;
    font-family: "Stratos LC";
    font-size: 12px;
    letter-spacing: -0.26px;
    line-height: 18px;
    font-weight: 400;
    margin: 0;
    text-align: right;
    /*display: block;*/
    text-decoration: underline;
    cursor: pointer;
    padding-left: 10px;
    display: none;
}
.block-title:hover .link-delete-one {
    display: inline-block;
}

.links-absolute-position {
    position: absolute;
    right: 10px;
    top: 15px;
}
.bottom-block-edit {
    text-align: left;
    padding-bottom: 20px;
}
.input-edit {
    height: 28px;
    border: 0;
    background: none;
    color: #4A4A4A;
    border-bottom: 1px solid #979797;
    outline: none;
    width: 100%;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 14px;
}





