html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: "Stratos LC", Arial, sans-serif!important;
}

/*.container {*/
    /*padding-right: 15px;*/
    /*padding-left: 15px;*/
    /*margin-right: auto;*/
    /*margin-left: auto;*/
/*}*/

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.top-menu {
    height: 54px;
    width: 100%;
    padding: 0 30px;
    background-color: #0028dc;
}

.logo {
    display: inline-block;
    width: 138px;
    height: 54px;
    box-sizing: border-box;
    padding-top: 8px;
    vertical-align: top;
    color: #f2f2f2;
}

.logo.fund {
    width: 105px;
    height: 33px;
    padding-top: 10px;
}

.project-content {
    width: 100%;
}

.top-project-content {
    position: relative;
    margin-bottom: 50px;
}

.top-project-content .rating {
    width: 100%;
}

.top-project-content .rating-info {
    height: 25px;
    width: 230px;
    padding-top: 30px;
}

.top-project-content .rating .rate {
    position: relative;
    width: 23%;
    float: left;
    margin-left: 9%;
    vertical-align: bottom;
    font-size: 14px;
}

.top-project-content .rating .rate a {
    text-decoration: none;
}
.top-project-content .rating .rate a:hover {
    text-decoration: underline;
}
.top-project-content .rating .rate.up {
    color: #00f082;
}

.top-project-content .rating .rate.down {
    color: #fa4600;
}

.top-project-content .rating .rate.comments {
    color: #0028dc;
}

.top-project-content .rating .rate.up:before {
    content: "";
    position: absolute;
    left: -18px;
    top: 2px;
    height: 18px;
    width: 10px;
    background: url(../../img/fund/arrow-up.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.top-project-content .rating .rate.down:before {
    content: "";
    position: absolute;
    left: -18px;
    top: 2px;
    height: 18px;
    width: 10px;
    background: url(../../img/fund/arrow-down.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.top-project-content .rating .rate.comments:before {
    content: "";
    position: absolute;
    left: -27px;
    top: 2px;
    height: 18px;
    width: 19px;
    background: url(../../img/fund/comment-icon.png);
    background-size: cover;
    background-repeat: no-repeat;
}

.top-project-content .rating .voting {
    overflow-y: hidden;
    width: 130px;
    padding-top: 10px;
}

.top-project-content .rating .voting p {
    font-size: 13px;
    color: #9b9b9b;
    font-weight: 300;
    margin-bottom: 5px;
    margin-top: 0;
}

.top-project-content .rating .voting .vote-btn {
    width: 61px;
    height: 30px;
    float: left;
    text-align: center;
    font-size: 20px;
    border: 0;
    cursor: pointer;
}

.top-project-content .rating .voting .vote-btn.no {
    margin-left: 5px;
    background-color: #fa4600;
    transition: all 0.2s ease-in;
}

.top-project-content .rating .voting .vote-btn.no:hover {
    background-color: #cc3900;
}

.top-project-content .rating .voting .vote-btn.yes {
    background-color: #00f082;
    transition: all 0.2s ease-in;
}

.top-project-content .rating .voting .vote-btn.yes:hover {
    background-color: #00c46b;
}

.bottom-project-content {
    padding-top: 20px;
    padding-bottom: 30px;
    border-top: 1px solid #979797;
}

.bottom-project-content table tr td {
    width: 50%;
}

.bottom-project-content table tr td:nth-child(1) {
    font-size: 13px;
    line-height: 26px;
    color: #9b9b9b;
}

.bottom-project-content table tr td:nth-child(2) {
    font-size: 15px;
    line-height: 26px;
    color: #4a4a4a;
    text-align: left;
    vertical-align: top;
    text-transform: uppercase;
}

.bottom-project-content table tr td p {
    margin-bottom: 0;
    margin: 0;
}

.bottom-pool-content table tr td:nth-child(1) {
    width: 30%;
    font-size: 13px;
    line-height: 26px;
    color: #9b9b9b;
}

.bottom-pool-content table tr td:nth-child(2) {
    width: 70%;
    font-size: 16px;
    line-height: 26px;
    color: #4a4a4a;
    text-align: left;
    vertical-align: top;
    text-transform: uppercase;
}

.bottom-pool-content table tr td p {
    line-height: 23px;
    margin-bottom: 0;
    margin: 0;
}

.project-content .project-logo {
    width: 144px;
    height: 144px;
    background-size: cover !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.project-content .project-logo img {
    width: 100%;
}

.project-content .project-name {
    font-size: 25px;
    font-weight: 400;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 30px;
}

.project-content .project-desc {
    width: 80%;
    color: #4a4a4a;
    font-size: 15px;
    font-weight: 300;
    line-height: 19px;
    margin: 0;
    margin-top: 10px;
}

.comments-content {
    width: 100%;
    padding: 50px 0;
    background-color: #f5f6f6;
}

/* 2 */

.pool-content {
    padding-top: 94px;
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(../../img/fund/wood.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.pool-content .project-logo {
    width: 80px;
    height: 80px;
    background-size: cover !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.pool-content .project-logo img {
    width: 100%;
}

.pool-content .pr-def {
    font-size: 20px;
    font-weight: 500;
    color: #fff;
}

.pool-content .project-name {
    font-size: 43px;
    font-weight: 500;
    color: #fff;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.pool-content .project-desc {
    color: #fff;
    margin: 10px 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 21px;
}

.pool-content .tags {
    position: relative;
    padding-left: 15px;
    margin-top: 15px;
}

.pool-content .tags:before {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 10px;
    height: 10px;
    background: url(../../img/fund/tag-icon.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.pool-content .tags .tag {
    color: #00f082;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;
    cursor: pointer;
}

.pool-content .tags .tag:hover {
    text-decoration: none;
}

.pool-content .pool-btns {
    margin: 15px 0;
}

.pool-content .pool-btns button {
    margin-right: 10px;
}

.pool-content .pool-btns button:last-child {
    margin-right: 0;
}

.bottom-pool-content {
    padding-top: 30px;
    padding-bottom: 30px;
    background-color: #F1F2F2;
}

.bottom-pool-content .info-pool-item {
    margin-bottom: 15px;
}

.bottom-pool-content .info-pool-item .info-title {
    font-size: 13px;
    line-height: 26px;
    color: #9b9b9b;
    margin: 0;
    font-weight: 300;
}

.bottom-pool-content .info-pool-item .info-def {
    font-size: 16px;
    line-height: 26px;
    color: #4a4a4a;
    text-align: left;
    vertical-align: top;
    margin: 0;
}

.bottom-pool-content .info-pool-item-big {
    margin-bottom: 15px;
}

.bottom-pool-content .info-pool-item-big .info-title {
    font-size: 16px;
    line-height: 26px;
    color: #9b9b9b;
    margin: 0;
}

.pool-content .change-bottom-btns {
    margin-top: 20px;
}

.pool-content .change-bottom-btns button {
    font-family: "Stratos LC";
    height: 70px;
    background: transparent;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin-right: 20px;
    border: 0;
    cursor: pointer;
    outline: none;
    background-image: linear-gradient(#00f082, #00f082), linear-gradient(transparent, transparent);
    background-size: 0 5px, auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all 0.2s ease-out;
}

.pool-content .change-bottom-btns button:hover {
    background-size: 100% 5px, auto;
}

.pool-content .change-bottom-btns button.active {
    font-weight: 500;
    background-size: 100% 5px, auto;
    cursor: default;
}

.pool-content .change-bottom-btns button:last-child {
    margin-right: 0;
}

.bottom-pool-content .project-logo {
    width: 80px;
    height: 80px;
    background-size: cover !important;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.bottom-pool-content .project-logo img{
    width: 80px;
    height: 80px;
}
.bottom-pool-content .pr-def {
    font-size: 20px;
    font-weight: 500;
    color: #000;
}

.bottom-pool-content .project-name {
    margin-top: 17px;
    font-size: 36px;
    font-weight: 500;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bottom-pool-content .project-desc {
    color: #000;
    margin-top: 15px;
    font-size: 15px;
    font-weight: 300;
    line-height: 21px;
}

#video-container {
    width: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

#video-container .cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
    background-color: rgba(0, 0, 0, 0.3);
}

.video {
    width: 100%;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.7);
}

.playpause {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    box-sizing: border-box;
    display: block;
    width: 40px;
    height: 40px;
    padding-top: 10px;
    padding-left: 3px;
    line-height: 20px;
    border: 0;
    border-radius: 50%;
    color: #000;
    text-align: center;
    text-decoration: none;
    background-color: rgba(0, 240, 130, 1);
    font-size: 20px;
    font-weight: bold;
    transition: all 0.3s ease;
    cursor: pointer;
}

.playpause:hover {
    background-color: rgba(0, 240, 130, 0.8);
}

.col-project {
    float: left;
}

.col-project.cp1 {
    width: 28%;
    margin: 0 1.5% 0 0;
}

.col-project.cp2 {
    width: 28%;
    margin: 0 1%;
}

.col-project.cp3 {
    width: 35%;
    margin: 0 0 0 1.5%;
}

.person {
    width: 100%;
    height: 40px;
    clear: both;
    margin-bottom: 10px;
}

.person .icon {
    position: relative;
    float: left;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    /* background: url(/img/fund/harold.jpg); */
    background-size: contain;
}
.person .icon img {
    width: 100%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.person .icon:before {
    content: "By";
    position: absolute;
    top: -5px;
    left: -13px;
    color: #9b9b9b;
    font-size: 11px;
}

.person .info {
    float: left;
    margin-left: 10px;
}

.person .info .name p {
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    margin: 0;
}

.person .info .city {
    color: #4a4a4a;
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
    margin-top: 5px;
}
.person-comments{
    margin: 15px 0 0 0;
}
.person-comments span {
    color: #9b9b9b;
    font-size: 13px;
    font-weight: 300;
}

.person-comments p {
    margin-top: 0px;
    color: #4a4a4a;
    font-size: 15px;
    line-height: 19px;
    font-weight: 300;
}

.person.person-progress {
    margin-top: 20px;
    margin-left: 12px;
}

.person.person-progress .icon:before {
    color: #ddd;
}

.person.person-progress .info .name p {
    color: #fff;
}

.person.person-progress .info .city p {
    color: #fff;
}

.top-pool-content .creation-date {
    width: 100%;
    text-align: left;
    margin-top: 20px;
    color: #d4d6d9;
    font-size: 11px;
}

.top-pool-content .creation-date span {
    float: left;
    font-weight: 300;
}

.top-pool-content .creation-date p {
    float: left;
    margin: 0;
    margin-left: 5px;
    font-weight: 300;
}

.top-pool-content .stats {
    /*margin-top: 40px;*/
}

.top-pool-content .stats .stat {
    float: left;
}

.top-pool-content .stats .stat.s1 {
    width: 26%;
}

.top-pool-content .stats .stat.s2 {
    width: 26%;
}

.top-pool-content .stats .stat.s3 {
    width: 48%;
}

.top-pool-content .stats .stat span {
    font-size: 13px;
    color: #fff;
    font-weight: 300;
}

.top-pool-content .stats .stat p {
    font-size: 20px;
    color: #fff;
    margin: 10px 0 0 0;
    font-weight: 400;
}
.title-line-top-right{
    font-weight: 300;
}




.block-icon-info-up-down-comments-nominee{}
.block-icon-info-nominee{
    width: 33%;
    float: left;
}
.up-nominee p{
    color: #00F082;
}
.down-nominee p{
    color: #FA4600;
}
.comments-nominee p{
    color: #0028DC;
}
.block-icon-info-nominee p{
    font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 18px; font-weight: 400; text-align: left; text-decoration: none!important;
}
.block-icon-info-nominee p i{
    color: #9B9B9B;margin: 0 8px 0 0;
}

@media (max-width: 991px) {
    .person {
        margin-top: 50px;
        margin-left: 12px;
    }
    .second-table {
        margin-top: 30px;
    }
    .shell-float-right {
        margin-top: 30px;
    }
    .pool-content .pool-btns button {
        margin-right: 1%;
        width: 32%;
    }
}

@media (max-width: 575px) {
    .project-logo {
        margin-left: auto;
        margin-right: auto;
    }
    .project-name {
        margin-top: 25px;
        text-align: center;
    }
    .project-desc {
        margin-top: 15px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .top-project-content .rating-info {
        margin-left: auto;
        margin-right: auto;
        width: 210px;
    }
    .top-project-content .rating .rate {
        width: 31%;
    }
    .top-project-content .rating .rate.comments {
        width: 10%;
    }
    .top-project-content .rating .voting {
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
    .pool-content .pr-def {
        display: table;
        margin: auto;
        margin-top: 20px;
    }
    .col-project {
        float: left;
        margin-left: 15px;
        margin-right: 15px;
    }
    .col-project.cp1 {
        width: 100%;
        margin: 0;
    }
    .col-project.cp2 {
        width: 100%;
        margin: 0;
        margin-top: 30px;
    }
    .col-project.cp3 {
        width: 100%;
        margin: 0;
        margin-top: 30px;
    }
    .copyright {
        margin-top: 0;
    }
}

@media (max-width: 476px) {
    .pool-content .change-bottom-btns button {
        font-size: 3.7vw;
        margin-right: 7px;
    }
}

@media (max-width: 411px) {
    .pool-content .pool-btns button {
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
    }
}

.info-pool-item .info-pool-item-big .info-pool-item-style {
    margin-bottom: 55px;
}

.change-bottom-btns a button {
    margin-right: 20px  !important;
}

.page-project-nominee .share-through {
    color: #9B9B9B;
    font-size: 13px;
}
.page-project-nominee .col-sm-12 {
    padding-left: 0;
}
.list-social-links {
    padding-top: 10px;
}
.list-social-links .social-button-gray a i {
    font-size: 22px;
    padding-right: 5px;
}
.tr-rable .you_said {
    color: #4A4A4A;
    font-size: 13px;
}
.block-for-category {
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    height: 60px;
}

.block-header-grid-start-fund.for-cursor p {
    cursor: default;
}







