.block-swipe-blue {
    height: 31px;
    width: 53px;
    border: 2px solid #D0D0D0;
    border-radius: 15px;
    background-color: #FFFFFF;
    transition: .2s;
    position: relative;
    display: inline-block;
    cursor: pointer;

    &:after{
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        height: 23px;	width: 23px;	background-color: #D0D0D0;
        border-radius: 50%;
        transition: .2s;
    }

    &.active {
        border: 2px solid #2CA6EB;
    }

    &.active:after {
        background-color: #2CA6EB;
        box-shadow: 0 8px 19px 0 rgba(32,165,238,0.15);
        left: 23px;
    }

    &.disable{
        opacity: 0.3;
        cursor: default;
    }
}

.block-for-swipe{
    width: 100%;
    display: table;

    .swipe-control.block-swipe-blue{
        float: left;
    }

    .swipe-text {
        width: calc(100% - 53px);
        float: right;
        text-align: left;
        padding-left: 15px;
        position: relative;
        font-size: 18px;
        margin: 5px 0;
    }
}