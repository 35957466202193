@import '../CONST';

.white-bg-wrapper {
  @extend %white-bg-wrapper;
  .inner-popup {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 0 51px 0;
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: calc(100% - 32px);
    max-width: 540px;
    max-height: calc(100vh - 40px);
    overflow: auto;

    .close {
      top: 34px;
      right: 40px;
      left: unset;
      opacity: 1;
      z-index: 2355;
    }
    .close::before, .close::after {
      background: rgba(0, 0, 0, 0.8);
    }
    .content-wrapper {
      width: 100%;
      max-width: 351px;
      padding: 0 20px;
      margin: 0 auto;
      text-align: center;

      .title {
        font-size: 28px;
        line-height: 31px;
        color: #2F3849;
        @extend %font-weight-500;
      }
      .descr {
        @extend %block-descr-16;
        color: rgba(8, 8, 8, 0.8);
        margin-top: 9px;
      }
    }
     .login-popup-buttons-wrapper {
       margin-top: 24px;
     }
  }
}

.login-content.white-bg-wrapper {
  background: transparent;
  position: relative;
  .inner-popup {
    box-shadow: none;
    border: none;
    position: unset;
    margin: 0 auto;
    transform: none;
  }
}

@media all and (max-width: 420px) {
  .white-bg-wrapper {
    .inner-popup {
      .close {
        top: 20px;
        right: 20px;
      }
    }
  }
}
@media all and (max-width: 370px) {
  .white-bg-wrapper {
    .inner-popup {
      .close {
        top: 15px;
        right: 12px;
      }
    }
  }
}