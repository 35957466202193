.shell-header-sticky-role {

  $color_expert: #0ECFAA;
  $color_fund: #2CA6EB;
  $color_startup: #2F3849;
  $color_investor: #2CA6EB;
  $color_network: #2CA6EB;
  $color_investor: #2CA6EB;
  $color_accelerator: #2CA6EB;


  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
  //padding-top: 10px;
  //padding-bottom: 10px;
  padding: 10px 20px;

  .block-avatar {
    width: 60px;
    height: 60px;
    border-radius: 4px;
  }

  .block-avatar.url {
    background-position: center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }

  .block-avatar.create {


    p.symbol-avatar {
      font-size: 32px;
      color: #FFFFFF;
      margin: 0;
      width: 100%;
      line-height: 60px;
      text-align: center;
      cursor: default;
    }
  }

  .block-avatar.create.expert {
    background-color: $color_expert;
  }

  .block-avatar.create.fund {
    background-color: $color_fund;
  }

  .block-avatar.create.startup {
    background-color: $color_startup;
  }

  .block-avatar.create.investor {
    background-color: $color_investor;
  }

  .block-avatar.create.network {
    background-color: $color_network;
  }
  .block-avatar.create.accelerator {
    background-color: $color_accelerator;
  }

  .block-name-type-description {

    .block-top-name-type-description {
      margin: 3px 0 7px 0;
      padding: 0 15px 0 0;

      p.name-role {
        position: relative;
        line-height: 26px;
        font-size: 22px;
        margin: 0;
        padding: 0 28px 0 0;
        font-weight: 500;
        display: inline-grid;


        span.title-name-role {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }

        span.check-role {
          width: 19px;
          height: 19px;
          position: absolute;
          top: 2px;
          right: 0;
          border-radius: 50%;

          i {
            width: 19px;
            height: 20px;
            line-height: 20px;
            color: #FFFFFF;
            text-align: center;
            font-size: 11px;
            vertical-align: top;
          }
        }


        span.check-role.expert {
          background-color: $color_expert;
        }

        span.check-role.fund {
          background-color: $color_fund;
        }

        span.check-role.startup {
          background-color: $color_startup;
        }

        span.check-role.investor {
          background-color: $color_investor;
        }

        span.check-role.network {
          background-color: $color_network;
        }

        span.check-role.accelerator {
          background-color: $color_accelerator;
        }
      }
    }

    .block-bottom-name-type-description {
      width: 100%;
      display: table;

      .block-type-role {
        float: left;
        margin: 0 8px 0 0;

        p.type-role {
          padding: 0 10px;
          border-radius: 10px;
          line-height: 21px;
          color: #FFFFFF;
          font-weight: 400;
          display: inline-block;
          margin: 0;
          font-size: 12px;
          text-transform: capitalize;
        }

        p.type-role.expert {
          background-color: $color_expert;
        }

        p.type-role.fund {
          background-color: $color_fund;
        }

        p.type-role.startup {
          background-color: $color_startup;
        }

        p.type-role.investor {
          background-color: $color_investor;
        }

        p.type-role.network {
          background-color: $color_network;
        }
        p.type-role.accelerator {
          background-color: $color_accelerator;
        }
      }


      .block-description-role {
        float: left;
        width: calc(100% - 120px);

        p.description-role {
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

    }

  }

  p.title-role-info {
    font-size: 14px;
    line-height: 18px;
    margin: 6px 0 8px 0;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.5);
  }

  p.description-role-info {
    font-size: 16px;
    line-height: 20px;
    color: rgba(8, 8, 8, 0.8);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding: 0 10px 0 0;
  }


  .block-buttons-startup {
    width: 100%;
    text-align: right;
    padding: 0 40px 0 0;

    .button-startup {
      width: 36px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid #D0D0D0;
      display: inline-block;
      vertical-align: top;
      margin: 12px 0 0 10px;
      cursor: pointer;

      i {
        width: 35px;
        height: 36px;
        line-height: 35px;
        text-align: center;
        font-size: 16px;
      }
    }

    .button-startup.like {
      color: #E43A70;
    }

    .button-startup.white-list {
      color: #0ECFAA;
    }

    .button-startup-social {
      width: 36px;
      height: 36px;
      border-radius: 6px;
      border: 1px solid #D0D0D0;
      display: inline-block;
      vertical-align: top;
      margin: 12px 0 0 10px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: .3s;

      .block-front-button-startup-social {
        width: 35px;
        height: 35px;
        transition: .3s;
        left: 0;
        position: relative;

        i {
          width: 36px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 16px;
        }
      }

      .block-back-button-startup-social {
        width: calc(24px * 3);
        position: absolute;
        right: calc(24px * 3);
        top: 0;
        transition: .3s;

        .button-social {
          width: 24px;
          height: 36px;
          float: left;
          cursor: pointer;

          i {
            width: 24px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            font-size: 18px;
            transition: .3s;
          }
        }

        .button-social:hover {


          i {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }

    }

    .button-startup-social.share {
      .block-front-button-startup-social {
        i {
          color: #2CA6EB;
        }
      }
    }

    .button-startup-social:hover {
      width: calc(24px * 3 + 16px);

      .block-front-button-startup-social {
        left: 100%;
      }

      .block-back-button-startup-social {

        right: 7px;
      }
    }


  }


  .block-button-3-0 {
    height: 40px;
    margin: 10px 0;
    width: 100%;

    p.block-button-3-0-title {
      font-size: 16px !important;
      height: 40px !important;
      line-height: 40px !important;
    }
  }


}