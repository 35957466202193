.achieve-container {
  .block-item-achievements-cart-share {
    width: 100%;
    p.title-achievements-cart-share {
      line-height: 15px;
    }
  }
  .achieve-descr-wrapper {
    margin-top: 4px;
  }

  .achieve-tab-block {
    height: 38px;
    margin-bottom: 8px;
  }
  .achieve-line {
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.2);
    border-radius: 6px;
    height: 52px;
    max-width: 560px;
    padding: 9px 8px 10px 12px;
    position: relative;
    cursor: pointer;
    .text-wrapper {
      padding-right: 65px;
      .title {
        font-size: 14px;
        line-height: 18px;
        color: rgba(8, 8, 8, 0.8);
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .description {
        font-size: 11px;
        line-height: 13px;
        color: rgba(8, 8, 8, 0.8);
        margin-top: 2px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .edit-button {
      position: absolute;
      right: 8px;
      top: 50%;
      height: 36px;
      transform: translateY(-50%);
      background: transparent;
      border-radius: 6px;
      transition: 0.2s;
      padding: 0 16px;

      font-size: 14px;
      line-height: 36px;
      text-align: center;
      color: #525252;
      font-weight: 500;
    }

    &:hover {
      .edit-button {
        color: #FFFFFF;
        background: #4B586E;
      }
    }

    @media (max-width: 1050px) and (min-width: 890px) {
      max-width: 400px;
    }
    @media (max-width: 890px) and (min-width: 840px) {
      max-width: 350px;
    }
    @media (max-width: 840px) and (min-width: 756px) {
      max-width: 270px;
    }
  }

  .add-article-button {
    height: 40px;
    margin-top: 16px;
    p.block-button-3-0-title{
      line-height: 40px;
    }
  }
  .edit-achieve-wrapper {
    margin: 16px 0 8px 0;
    .block-form-add-edit-modal-reg-expert {
      margin-bottom: 0;
      padding: 0 0 4px 0;
      box-shadow: none;
      .block-text-area-form-add-edit-modal-reg-expert {
        margin: 6px 0 0 0;
      }
    }
  }
  .delete-button {
    background: #ECECEC;
    p.block-button-3-0-title {
      color: #525252;
    }
  }
  @media (max-width: 576px) {
    .block-button-3-0.button-3-0-blue {
      width: 100%;
      &.delete-button {
        margin-top: 10px;
      }
    }
  }
}
