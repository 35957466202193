@media (max-width: 600px){

  .answer-text{
    color: rgba(0, 0, 0, 0.5) !important;
    transition: all 0.5s;
  }

  .slick-current + .slick-active .answer-text{
    color: rgba(0, 0, 0, 1) !important;
  }

  .block-viral-answer-point{
    display: flex;
    min-height: 100px !important;
    align-items: center;

    .block-answer-text{
      height: auto !important;
    }
  }

  .cell{
    &.white{
      background: #fff;
      height: 95px;
      width: 0!important;
    }
  }

  #scrollport{
    position: relative;
    background: #fff;
    overflow: hidden;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    z-index: 0;
  }

  .wrapper{
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    -webkit-border-radius: 6px;
  }


  .drum{
    .slick-track{
      margin-top: -25px;
    }


    .slick-slide {
    }
    /* the parent */
    .slick-list {
      height: 300px !important;
    }

    .slick-slide {
      height: auto; // ← that must not be ignored
    }
    .slick-vertical .slick-slide {
      border: none!important;
    }
  }

  .default-answer-point .block-viral-answer-point{
    margin: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .block-answer-text::before{
    display: none !important;
  }

  .block-viral-answer-point:before{
    position: absolute;
    content: '';
    top: 0px;
    left: 0;
    width: 100%;
    border-top: 1px solid rgba(0,0,0,0.15);
  }

  #overlay{
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    //background: linear-gradient(180deg, #2F3849 0%, rgba(255, 255, 255, 0) 22.55%, rgba(255, 255, 255, 0) 55.35%, #2F3849 96.44%);
    background-image: url("data:image/svg+xml,%3Csvg width='343' height='257' viewBox='0 0 343 257' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.3125' y='0.0761719' width='342' height='256' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='171.312' y1='0.0761719' x2='171.312' y2='256.076' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%232F3849'/%3E%3Cstop offset='0.177083' stop-color='white' stop-opacity='0'/%3E%3Cstop offset='0.665718' stop-color='white' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%232F3849'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E ");
    background-size: cover;
    background-repeat: no-repeat;

    z-index: 120310293;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
}





















































































































