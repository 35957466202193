body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.main-bg {
  width: 100vw;
  min-height: 100vh;
  background-image: url(../../images/main-bg.jpg);
  -webkit-background-size: cover;
  background-size: cover;
  padding-top: 30px;
}
.login-form {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
.login-form--wrap {
  padding: 35px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #F5F6F6; }

.login-form__variant {
  margin-bottom: 25px;
  color: #A2A6AF;
  font-family: "Stratos LC";
  font-size: 30px;
  font-weight: 500;    letter-spacing: -1.5px;

  line-height: 38px;
  text-align: left;
}

.login-form__link_login {
  color: #A2A6AF;
  text-decoration: underline; }
.login-form__link_join {
  color: #000000; }
.login-form__field {
  border: 1px solid transparent;
  background-color: #ffffff;
  margin-bottom: 15px;
  padding: 10px 13px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 14px;
  font-family: 'Stratos LC';
  font-weight: 400;
  outline: none;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear; }

.auth_active{
  color: #000000;

}

.login-form__field::-webkit-input-placeholder {
  color: #A2A6AF;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear; }
.login-form__field::-moz-placeholder {
  color: #A2A6AF;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear; }
.login-form__field:-moz-placeholder {
  color: #A2A6AF;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear; }
.login-form__field:-ms-input-placeholder {
  color: #A2A6AF;
  -webkit-transition: all .3s linear;
  -o-transition: all .3s linear;
  transition: all .3s linear; }
.login-form__field:focus {
  border-color: #00F082; }
.login-form__field:focus::-webkit-input-placeholder {
  color: #A2A6AF; }
.login-form__field:focus::-moz-placeholder {
  color: #454545; }
.login-form__field:focus:-moz-placeholder {
  color: #454545; }
.login-form__field:focus:-ms-input-placeholder {
  color: #454545; }
.login-form__btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border: none;
  background-color: transparent;
  color: #000000;
  font-family: "Stratos LC";
  font-size: 16px;
  font-weight: 300;    letter-spacing: -1px;

  line-height: 17px;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
  /*text-transform: uppercase;*/
}
.login-form__btn:last-child {

}
.login-form__btn_join {
  background-color: #1BC8A7;
  padding: 13px;
  border-radius: 50px;
  color: #FFFFFF;
}
.login-form__btn_join:disabled {
  opacity: 0.4;
  cursor: inherit;
}

.login-form__btn_fb {
  background-color: #4267B2;
  color: #ffffff;
  padding: 14px 18px;
  text-align: left;
  font-size: 19px;
  line-height: 24px; }
.login-form__btn_fb .fa-facebook-official{
  font-size: 22px;
}
.login-form__btn_fb.btn-google {
  background-color: #d74937;
  /*margin-top: 10px*/
}

.login-form__btn_linkedin {
  background-color: #008FC2;
  color: #ffffff;
  padding: 14px 18px;
  text-align: left;
  font-size: 19px;
  line-height: 24px; }

.login-form__btn-icon {
  display: inline-block;
  width: 19px;
  height: 19px;
  background-color: #ffffff;
}


.login-form__btn-text {
  text-align: center;
  width: -webkit-calc(100% - 20px);
  width: calc(100% - 20px);
  display: inline-block; }
.login-form__devider {
  text-align: center;
  margin-bottom: 24px; }
.login-form__devider span {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #A2A6AF;
  font-size: 14px;
  line-height: 18px; }
.login-form__devider span:before, .login-form__devider span:after {
  content: '';
  display: inline-block;
  height: 1px;
  width: 45%;
  background-color: #A2A6AF; }
.login-form__policy {
  color: #5A6170;
  font-size: 11px;
  line-height: 15px;
  text-align: center; }
.login-form__policy a {
  color: #5A6170;
  text-decoration: underline; }

.login-form__captcha input {
  margin-right: 10px; }
.login-form__captcha label {
  font-size: 10px; }

.interest-wrap {
  padding: 0; }
.interest-wrap h1 {
  color: #ffffff;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-weight: 100;
  font-size: 29px;
  line-height: 40px;
  margin: 0 auto; }

.interest-form {
  background-color: #F5F6F6;
  margin-top: 39px;
  padding: 35px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
.interest-form__inner {
  padding: 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #ffffff;
  width: 100%;
  height: 396px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }
.interest-form__title {
  color: #000000;
  font-size: 30px;
  font-weight: 500;    letter-spacing: -1.5px;

  line-height: 38px;
  margin-bottom: 5px; }
.interest-form__btn {
  display: block;
  width: 70%;
  max-width: 345px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 17px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #00F082;
  text-transform: uppercase;
  text-align: center;
  border: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;    letter-spacing: -1.5px;

  line-height: 17px;
  color: #000000; }

.interest-item {
  width: 30%;
  margin-bottom: 30px;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid transparent;
  padding: 1px;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease; }
.interest-item__image {
  height: 106px;
  background-color: blue;
  -webkit-background-size: contain;
  background-size: contain;
  position: relative;
  color: #5A6170;
  margin-bottom: 9px; }
.interest-item__image:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 226, 169, 0.7);
  position: absolute;
  top: 0;
  left: 0; }
.interest-item__title {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px; }
.interest-item__desc {
  font-size: 12px;
  line-height: 15px; }
.interest-item_selected {
  border-color: #00F082; }
.interest-item p {
  margin: 0; }

@media screen and (max-width: 575px) {
  .interest-item {
    width: 47%; } }

@media screen and (max-width: 400px) {
  .interest-item {
    width: 100%; } }

.login-form__link_login a{
  color: #A2A6AF;
  text-decoration: underline;
}
.forgot_pass {
  text-align: right;
  margin-top: -28px;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  color: #7d7d7d;
}
.forgot_pass a{
  text-align: right;
  color: #24C875;
  font-family: "Stratos LC";
  text-decoration: underline;
}

.reg_error_field {
  color: rgb(174, 88, 86);
  position: relative;
  top: -10px;
  text-align: left;
  font-size: 12px;
}
.reg_error_field.fix{
  top: 0;
}

.reg_error_field.err_center {
  text-align: center;
}
.reg_error_photo {
  color: rgb(174, 88, 86);
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  padding-bottom: 10px;
}

.card-help {
  background-color: #ffffff;
  font-family: "Stratos LC", serif;
  padding: 25px 0;
}
.card-help .ch-title{
  color: #000000;

  font-size: 30px;
  text-align: center;
}
.card-help .list-questions{
  padding: 20px 0;
}
.card-help .block-btn{
  padding: 0 35px;
}
.card-help .single-question{
  height: 44px;
  line-height: 44px;
  border-bottom: 1px solid #D4D6D9;
  cursor: pointer;
  font-weight: 300;
}
.card-help .single-question:hover{
  background-color: #E7FCF5;
  font-weight: 400;
}
.card-help .single-question.active{
  font-weight: 400;
}

.card-help .single-question:first-child{
  border-top: 1px solid #D4D6D9;
  height: 45px;
}

.card-help .single-question .ch-checkbox{
  float: left;
  padding-left: 35px;
}
.card-help .single-question .ch-text{
  float: left;
  padding-left: 15px;
}
.card-help .single-question .ch-checkbox .icon_checkbox{
  display: inline-block;
  width: 19px;
  height: 14px;
  background: url('../../images/checkmark-grey.png') center center no-repeat;
  background-size: 19px 14px;
}
/*.card-help .single-question:hover .icon_checkbox,*/
.card-help .single-question.active .icon_checkbox{
  background: url('../../images/checkmark-green.png') center center no-repeat;
  background-size: 19px 14px;
}

/*.card-help .single-question:hover .ch-text{*/
  /*font-weight: bold;*/
/*}*/
.interest-page {
  max-width: 559px;
}
.header-title {
  color: #FFFFFF;
  font-family: "Stratos LC", serif;
  font-size: 29px;
  line-height: 40px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0,0,0,0.5);
  margin-bottom: 20px;
}
.card-interests {
  background-color: #F5F6F6;
  font-family: "Stratos LC", serif;
  padding: 35px;

  position: relative;
}
.card-interests .ci-title{
  color: #000000;
  font-weight: 400;
  font-size: 30px;
  margin-bottom: 5px;
  text-align: left;
}
.card-interests .ci-list{
  background-color: #ffffff;
  overflow-y: scroll;
  height: 396px;
  margin-bottom: 20px;
  padding: 15px;
}
.card-interests .ci-list .one-card img{
  width: 100%;
}
.card-interests .ci-list .one-card .text-1{
  color: #5A6170;
  font-size: 16px;
}
.card-interests .ci-list .one-card .text-2{
  color: #5A6170;
  font-size: 12px;
}

.card-interests .ci-list .one-card{
  margin-bottom: 30px;
  padding: 5px;
}
.card-interests .ci-list .one-card .border-card{
  border: 1px solid #ffffff;
  padding: 5px;
  cursor: pointer;
}
.card-interests .ci-list .one-card .border-card:hover{
  background-color: #ccfcd4;
}

.card-interests .ci-list .one-card .border-card.active{
  opacity: 0.6;
  background: #00F082;
}
.photo-page {
  text-align: center;
}
.photo-page .card-interests{
  width: 363px;
}
.photo-page .upload-photo {
  text-align: center;
  margin-top: 35px;
  /*margin-bottom: 70px;*/
}
.photo-page .photo-name {
  min-height: 50px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  padding-bottom: 10px;
}
.justify-content-column {
  flex-direction: column;
}
.under-input {
  font-size: 12px;
  color: #848484;
  margin-bottom: 15px;
}
.login-form__field.less_margin {
  margin-bottom: 10px;
}
.person-avatar-block.pab-3 {
  margin-top: 10px;
  height: auto;
}

.photo-page .block-btn {
  margin-top: 10px;
}
.photo-page  .person-avatar-block {
  height: auto;
  min-height: 50px;
}
.block-drop-zone.fix2 {
  margin: 0 auto;
  width: 250px;
  height: 250px;
  padding-top: 20%;
}
.block-drop-zone.fix3 {
  margin: 0 auto;
  width: 200px;
  height: 200px;
  padding-top: 16%;
}

.block-drop-zone.fix2 .title-drop-zone{
  font-size: 26px;
}
.block-drop-zone.fix2 .description-drop-zone{
  font-size: 20px;
}
.button-upload.auth-upload {
  background-color: #4a4a4a;
  box-shadow: 0 2px 9px 0 rgba(0,0,0,0.03);
  color: #ffffff;
  font-size: 15px;
  width: 200px;
  border-radius: 50px;
}



.new-button-green-modal{
  border-radius: 6px;
  background: #0ECFAA;
  font-weight: 500;
  letter-spacing: 0;
  opacity: 1;
  transition: .3s;
}
.new-button-green-modal:hover{
  opacity: 0.8;
}