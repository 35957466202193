.block-progress-bar-multi-fund{
    width: 100%;
    /*overflow: hidden;*/
    padding: 0 0 20px 0;
}
.block-top-progress-bar-multi-fund{
    overflow: hidden;
    margin: 0 0 10px 0;
}

.count-raised-multi-fund{
    width: 60%;
    float: left;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 29px;	letter-spacing: -0.53px;	line-height: 26px;	text-align: left;
    margin: 0;
    height: 26px;
    overflow: hidden;
}
.count-raised-multi-fund span.count{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 29px;	letter-spacing: -0.53px;	line-height: 26px;	text-align: left;
    float: left;
}
.count-raised-multi-fund span.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.1px;	line-height: 13px;
    float: left; margin: 0 0 4px 8px;
    display: block;
}
.count-contrebuted-multi-fund{
    width: 40%;
    float: left;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 29px;	letter-spacing: -0.53px;	line-height: 26px;	text-align: left;
    margin: 0;
    height: 26px;
    overflow: hidden;
}
.count-contrebuted-multi-fund span.count{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 29px;	letter-spacing: -0.53px;	line-height: 26px;	text-align: left;
    float: left;
}
.count-contrebuted-multi-fund span.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.1px;	line-height: 13px;
    float: left; margin: 0 0 4px 3px;
    display: block;
}
.fix-color .block-hard-cap-multi-fund p {
    color: #ffffff;
}

.block-body-progress-bar-multi-fund{
    width: 100%;
    background: #FFFFFF;
    height: 5px;
    position: relative;
}
.progress-multi-fund{
    width: 100%;
    height: 5px;
}


.block-soft-cap-multi-fund{
    position: relative;
    border-right: 1px solid #9B9B9B;
    height: 10px;
    top: -5px;
    float: left;
}
.block-soft-cap-multi-fund p{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: 0.11px;	line-height: 13px;	text-align: right;
    margin: 10px 3px 0 0;font-weight: 300;
    white-space: nowrap;
    /*float: right;*/
}
.block-hard-cap-multi-fund{
    float: right;
    display: inline-block;
    margin: 5px 0 0 0;
}
.block-hard-cap-multi-fund p{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: 0.11px;	line-height: 13px;	text-align: right;
    margin: 0;font-weight: 300;
}
