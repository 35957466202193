@import '../CONST';

.name-block-container {
  .user-block-col {
    padding-bottom: 16px;
  }
  .name-block-title {
    @extend %input-label;
    @extend %font-weight-500;
    margin-bottom: 4px;
  }
  .block-new-input,
  .block-new-input input {
    height: 41px;
    line-height: normal;
  }
}
