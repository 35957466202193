 
.block-members-popup {
	position: relative;
	width: 100%;
	max-width: 540px;
	height: auto;
	max-height: 90vh;
	background: #FFFFFF;
	border: 1px solid #D0D0D0;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
	border-radius: 8px;
	overflow-y: auto;
	margin: 5vh auto;
}
.block-members-popup .close {
    top: 10px;
    left: auto;
	right: 35px;
    vertical-align: middle;
	transform: scale(1.4);
	opacity: 1;
}
.block-send-popup {
	padding: 35px 5px 40px 50px;
	overflow: hidden;
}
.block-delete-popup {
	padding: 40px 40px 40px 45px;
}
.block-delete-new-popup {
	padding: 35px 60px 40px 50px; 
}
.block-invite-popup {
	padding: 25px 5px 30px 35px;
}
.block-send-popup-bg {
	background-image: url("img/send_bg.svg");
	background-size: 100% 100%;
	background-repeat: no-repeat;
	width: 100%;
	max-width: 390px;
	height: 310px;

	margin: 0 auto;
}

.block-members-popup .send-button-line {
	text-align: center;
}
.send-button-line .block-button-3-0.button-3-0-blue {
	/*z-index: 100;*/
}
.block-members-popup .send-button-line .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
	display: block;
}



.block-members-popup .members-text-title {
	font-size: 38px;
	line-height: 1.1;
	color: #080808;
	opacity: 0.8;
}
.block-members-popup .members-text-little-subtitle {
	font-size: 18px;
	line-height: 24px;
	color: rgba(8, 8, 8, 0.8);
}
.block-members-popup .members-text-subtitle {
	font-size: 22px;
	line-height: 28px;
	color: rgba(8, 8, 8, 0.8);
}
.block-members-popup .text-members-invite {
	font-size: 28px;
	line-height: 34px;
	color: rgba(8, 8, 8, 0.8);
}
.list-mail-inv-send {
	text-align: center;
}
.list-mail-inv-send .mail-block-border {
	display: inline-block;
	height: 18px;
	/*background: rgba(0, 0, 0, 0.03);*/
	/*border-radius: 4px;*/
	/*padding: 0 9px;*/
}
.list-mail-inv-send .text-mail {
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #525252;
	background: rgba(0, 0, 0, 0.03);
	padding: 0 9px;
	border-radius: 4px;
}



.text-align-center {
	text-align: center;
}
.margin-top-15 {
	margin-top: 15px;
}
.margin-top-25 {
	margin-top: 25px;
}
.margin-top-30 {
	margin-top: 30px;
}
.margin-top-40 {
	margin-top: 40px;
}
.margin-top-45 {
	margin-top: 45px;
}
.margin-top-65 {
	margin-top: 65px;
}
.margin-bottom-20 {
	margin-bottom: 20px;
}
.font-weight-500 {
	font-weight: 500;
}


.block-delete-popup .block-member-profile-card {
	width: 190px;
	height: 300px;
	/*background-image: url('./tempImg/profile_card.png');*/
	background-repeat: no-repeat;
	
}







.block-members-popup.block-add-more-team .block-left-list-input-email .block-input-reg {
	max-width: 100%;
}
.block-members-popup .block-add-more-team .block-select-reg p.title-input-reg,
.block-members-popup .block-add-more-team .block-input-reg p.title-input-reg {
	font-size: 16px;
	color: #000000;
	opacity: 0.75;
}
.block-members-popup .block-add-more-team .block-right-list-input-email .right-list-title {
	font-size: 24px;
	line-height: 27px;
	font-weight: 500;
	color: rgba(8, 8, 8, 0.8);
}


.block-members-popup .block-invite-fund-members .block-button-list-input-email {
	margin: 28px 0 0 0;
}
.block-members-popup .block-invite-fund-members .block-button-list-input-email .block-button-3-0.button-3-0-blue {
	height: 40px;
}
.block-members-popup .block-invite-fund-members .block-button-list-input-email .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
	line-height: 40px;
}
.block-members-popup .block-invite-fund-members .block-button-3-0.button-3-0-border {
	border: 1px solid #596377;
	border-radius: 4px;
}
.block-members-popup .block-invite-fund-members .block-button-3-0.button-3-0-border p.block-button-3-0-title {
	font-size: 14px;
	font-weight: 500;
	color: #2F3849;
}
.block-members-popup .block-invite-fund-members .block-list-first-item-select .block-item-mail-more {
	height: 38px;
}
.block-members-popup .block-invite-fund-members .block-item-mail-more p.title-item-mail-more {
	line-height: 34px;
}
.block-members-popup .block-invite-fund-members .block-item-mail-more .block-button-delete-mail-more {
	margin-top: -5px;
}
.block-members-popup .block-member-card .block-body-team-list .content-box {
	padding: 0 32px 0 0;
}
.block-members-popup .block-member-card .block-body-team-list {
	float: none;
	height: auto;
	width: 220px;
	box-shadow: none;
}
.block-members-popup .block-member-card .block-body-team-list:hover {
	box-shadow: none;
}
.block-members-popup .list-avatar {
	width: 100%;
	height: 188px;
}


.dashboard-button {
	padding: 0 8px 0 11px;
	cursor: pointer;
}
.dashboard-button .db-title {
	float: left;
	line-height: 33px;
	font-size: 14px;
	color: #2f3849;
}
.dashboard-button .db-description {
	float: right;
	line-height: 33px;
	font-size: 12px;
	color: rgba(0,0,0,0.5);
}

.block-delete-popup .block-member-card {
	margin: 0 auto;
	width: 220px;
}

.block-member-card .list-avatar {
	width: 188px;
	height: 188px;
}
.block-member-card .button-delete-member {
	font-size: 14px;
	line-height: 18px;
	float: right;
	text-decoration-line: underline;
	color:#2F3849;
}
.block-member-card .button-delete-member {

}
.block-member-card .list-social {
	float: left;
	margin-top: 0;
	max-width: 70%;
}
.block-member-card.block-body-team-list {
	width: 220px;
	height: 345px;
}
.block-member-card .admin-block {
	margin: 8px;
	display: inline-block;
	height: 24px;
	border-radius: 2px;
	background-color: rgba(0, 0, 0, 0.5);
}
.block-member-card .text-admin-block {
	padding: 0 10px;
	font-size: 14px;
	line-height: 24px;
	color:#FFFFFF;
}
.block-member-card .list-position {
	margin-bottom: 0;
}
.block-member-card .list-mail {
	color: rgba(8, 8, 8, 0.8);
	margin: 12px 0 2px 0;
}
.block-member-card .list-descr {
	color: rgba(0, 0, 0, 0.6);
	margin: 5px 0 2px 0;
}
.block-member-card .color-mail {
	color: rgba(8, 8, 8, 0.8);
}
.block-member-card .color-text-button {
	color: rgba(0,0,0,0.8);
}

@media (max-width: 500px) {
	.block-members-popup .close {
		right: 25px;
	}
	.block-members-popup .text-members-invite {
		max-width: 90%;
		font-size: 25px;
		line-height: 30px;
	}
	.block-members-popup .block-select-reg,
	.block-members-popup .block-input-reg {
		width: 100%;
		max-width: unset;
	}
}
@media (max-width: 400px) {
	.block-invite-popup {
		padding-left: 20px;
	}
}
