@import '../CONST';

.location-block-container {
  .location-col {
    padding-bottom: 16px;
  }
  .location-right-col {
    padding-top: 28px;
  }
  .location-title {
    @extend %input-label-22;
    @extend %font-weight-500;
  }
  .location-descr {
    @extend %block-descr-16;
    margin-top: 9px;
  }

  .block-body-select {
    top: 28px;
  }

  @media all and (max-width: 576px) {
    .location-right-col {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}