.container-unsub-news {
    max-width: 500px;
    width: 100%;
    margin: 50px auto 0 auto;
    padding: 0 20px;
}
.block-unsub-news {
    position: relative;
}

.buttons-line-wrapper .right-button-wrapper {
    margin-left: 20px;
}
.buttons-line-wrapper .right-button-wrapper .block-button-3-0.button-3-0-green {
    background-color: #FF4133;
}

.buttons-line-wrapper .block-button-3-0.button-3-0-green {
    width: 220px;
}
.buttons-line-wrapper .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    font-weight: 500;
}
.buttons-line-wrapper {
    justify-content: space-between;
}

.block-unsub-news .main-title-insub {
    font-size: 50px;
    line-height: 45px;
    color: #2f3849;
    margin: 0 auto;
    z-index: 10;
}
.block-unsub-news .text-insub-descr {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.154797px;
    color: rgba(0,0,0,0.8);
    z-index: 10;
}
.block-unsub-news .person-icon {
    position: absolute;
    width: 116px;
    height: 212px;
    background: url("./img/person.svg");
    background-repeat: no-repeat;
    right: -146px;
    bottom: -100px;
}
.block-unsub-news .paper-planes-icon {
    position: absolute;
    width: 96px;
    height: 119px;
    background: url("./img/paper_planes.svg");
    background-repeat: no-repeat;
    left: -104px;
    top: -52px;
}
.block-unsub-bg {
    background: url("./img/unsub_bg.jpg");
    background-repeat: no-repeat;
    background-position: 60%;
    height: 263px;
    margin-top: -210px;
}


@media all and (min-width: 1250px) {
    .block-unsub-bg {
        background-size: 105% 263px;
    }
}
@media all and (max-width: 705px) {
    .block-unsub-news .paper-planes-icon {
        left: -60px;
        top: -30px;
    }
    .block-unsub-news .person-icon {
        right: -92px;
        bottom: -82px;
    }
}
@media all and (max-width: 576px) {
    .buttons-line-wrapper .block-button-3-0.button-3-0-green {
        width: 100%;
    }
    .buttons-line-wrapper .right-button-wrapper {
        margin: 15px 0 0 0;
    }
    .block-unsub-news .paper-planes-icon {
        left: -25px;
        top: 17px;
    }
    .block-unsub-news .person-icon {
        right: 35px;
        bottom: -212px;
    }
}

