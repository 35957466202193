@import '../../CONST';

.menu-icon {
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    line-height: 32px;
    width: 34px;
    height: 34px;
    min-width: 34px;
    border: 1px solid white;
    border-radius: 100%;
    box-shadow: inset 0 4px 5px rgba(0, 0, 0, 0.25);
    text-align: center;
    font-size: 22px;
    color: white;

    transition: all 0.2s linear;
  }

  .title {
    @extend %menu-title;
    display: none;
    margin-left: 20px;
  }

    &.active{
      &.accelerator{
        .icon{
          background: #2CDFEB;
          border: 1px solid #2CDFEB;
        }
      }
      &.expert{
        .icon{
          background: #0ECFAA;
          border: 1px solid #0ECFAA;
        }
      }
      &.investor {
        .icon {
          background: #2CA6EB;
          border: 1px solid #2CA6EB;
        }
      }
      &.startup {
        .icon {
          background: #4C586F;
          border: 1px solid #4C586F;
        }
      }

      &.current {
        .icon {
          background: transparent;
        }
      }
    }

  &.plus {
    .icon {
      background-image: url(../../../../images/icon/Plus.svg);
      border: 1px solid #2B3344;
      background-repeat: no-repeat;
      background-position: 50%;
    }
  }

  }

@media all and (max-width: 756px) {
  .menu-icon {
    .title {
      display: block;
    }
  }
}