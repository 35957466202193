.block-button-tab {
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.8);
    opacity: 0.3;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: .3s;
    position: relative;
}

.block-button-tab p.title {
    color: #080808;
    font-family: "Stratos LC";
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    margin: 0;
    transition: .3s;
}

.block-button-tab.big p.title {
    padding: 0 22px;
}

.block-button-tab.small p.title {

    padding: 0 16px;
}

.block-button-tab.big {
    height: 38px;
}

.block-button-tab.big p.title {
    line-height: 35px;
}

.block-button-tab.small {
    height: 31px;
}

.block-button-tab.small p.title {
    line-height: 29px;
}

.block-button-tab.active {
    border: 1px solid #0ECFAA;
    opacity: 1;
}

.block-button-tab:hover {
    border: 1px solid #979797;
    opacity: 1;
}

.block-button-tab:hover p.title {
    opacity: 0.5;
    color: #080808;
}

.block-button-tab.active:hover {
    border: 1px solid #0ECFAA;
    opacity: 0.8;
}

.block-button-tab.remove:hover {
    border-color: #e84242;
}

.block-button-tab.active p.title {
    color: rgba(8, 8, 8, 0.8);
    opacity: 1;
    font-weight: 500;
}

.block-button-tab .zh-icon-remove {
    display: none;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -14px;
    top: -9px;
    color: #e84242;
}

.block-button-tab:hover .zh-icon-remove {
    display: inline-block;
}

.block-button-tab-counter {
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.8);
    opacity: 0.3;
    border-radius: 4px;
    background-color: #FFFFFF;
    cursor: pointer;
    transition: .3s;
    position: relative;
    z-index: 5;
}

.block-button-tab-counter p.title {
    color: #080808;
    font-family: "Stratos LC";
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    margin: 0;
    transition: .3s;
    float: left;
}

.search-line .block-button-tab-counter p.title{
    text-transform: capitalize;
}

.block-button-tab-counter.big.counter p.title {
    padding: 0 8px 0 22px;
}

.block-button-tab-counter.small.counter p.title {
    padding: 0 8px 0 16px;
}

.block-button-tab-counter.big p.title {
    padding: 0 22px;
}

.block-button-tab-counter.small p.title {
    padding: 0 16px;
}

.block-button-tab-counter.big {
    height: 38px;
}

.block-button-tab-counter.big p.title {
    line-height: 35px;
}

.block-button-tab-counter.small {
    height: 31px;
}

.block-button-tab-counter.small p.title {
    line-height: 29px;
}

.block-button-tab-counter.active {
    border: 1px solid #0ECFAA;
    opacity: 1;
}

.block-button-tab-counter:hover {
    border: 1px solid #979797;
    opacity: 1;
}

.block-button-tab-counter:hover p.title {
    opacity: 0.5;
    color: #080808;
}

.block-button-tab-counter.active:hover {
    border: 1px solid #0ECFAA;
    opacity: 0.8;
}

.block-button-tab-counter.active p.title {
    color: rgba(8, 8, 8, 0.8);
    opacity: 1;
    font-weight: 500;
}

.block-button-tab-counter span.counter {
    display: inline-block;
    float: right;
    height: 16px;
    line-height: 15px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    border-radius: 50px;
    padding: 0 5px;
    background-color: #0ECFAA;
    color: #FFFFFF;
}

.block-button-tab-counter.big span.counter {
    margin: 10px 12px 10px 0;
}

.block-button-tab-counter.small span.counter {
    margin: 6px 12px 6px 0;
}

.block-button-tab-counter span.counter {
    background-color: rgba(8, 8, 8, 0.3);
}

.block-button-tab-counter.active span.counter {
    background-color: #0ECFAA;
}
.block-button-tab-counter.tab-remove {
    width: 100%!important;
    opacity: 1;
    border: 1px solid rgba(151,151,151,0.2);
}
.block-button-tab-counter.tab-remove:hover {
    opacity: 1;
}
.block-button-tab-counter span.counter.no-bgc {
    background-color: inherit;
    color: #8a8989;
    font-size: 15px;
}
.block-button-tab-counter.tab-remove:hover span.counter.no-bgc {
    color: #e84242;
}
.block-button-tab-counter.tab-remove:hover {
    border-color: #e84242;
}
.block-button-tab-counter:hover p.title {
    opacity: 1;
}
.block-button-tab-counter.tab-remove.tab-down:hover span.counter.no-bgc {
    color: #666565;
}
.block-button-tab-counter.tab-remove.tab-down:hover {
    border: 1px solid rgba(151,151,151,0.4);
}
.block-button-tab-counter.tab-remove.big.under {
    height: 10px;
    position: relative;
    top: -13px;
    z-index: 1;
    width: 98%!important;
}
.block-button-tab-counter.tab-remove.big.under-2 {
    height: 10px;
    position: relative;
    top: -26px;
    z-index: 0;
    width: 96%!important;
}







