.pay-banner-wrapper {
  .right-side .pay-banner-title,
  .left-side .pay-banner-title {
    color: rgba(0, 0, 0, 0.8);
  }
  .right-side .main-title-block,
  .left-side .main-title-block {
    position: relative;
    padding-left: 20px;
  }
  .right-side .main-title-block:after,
  .left-side .main-title-block:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background: #0ECFAA;
    width: 6px;
    height: calc(100% - 6px);
    margin-top: 6px;
  }

  .left-side {
    ul {
      padding-left: 15px;
      margin: 33px 0 0 0;
    }
    .text-list {
      list-style: none;
      position: relative;
      font-size: 14px;
      line-height: 18px;
      color: rgba(8, 8, 8, 0.8);
    }
    .text-list:before {
      content: "";
      position: absolute;
      background: rgba(8, 8, 8, 0.8);;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      left: -14px;
      top: 7px;
    }
    .list-descr-block {
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      padding-top: 6px;
      margin-top: 20px;
    }
    .list-descr {
      font-size: 14px;
      line-height: 150%;
      color: rgba(8, 8, 8, 0.8);
    }
    .buttons-line {
      margin-top: 31px;
      .block-button-3-0 {
        height: 40px;
      }
      .block-button-3-0 {
        height: 40px;
        p.block-button-3-0-title {
          line-height: 40px;
        }
      }
      .request-button-wrapper {
        max-width: 60%;
      }
      .details-button-wrapper {
        max-width: 40%;
        .details-button {
          width: 40%;
          border: 1px solid #2F3849;
          box-shadow: 0px 8px 19px rgba(47, 56, 73, 0.151664);
          border-radius: 6px;
          p.block-button-3-0-title {
            line-height: 38px;
            color: #2F3849;
          }
        }
      }
      @media (min-width: 861px) and (max-width: 950px), (min-width: 620px) and (max-width: 680px) {
        .request-button-wrapper,
        .details-button-wrapper {
          p.block-button-3-0-title {
            font-size: 14px;
          }
        }
      }
      @media (min-width: 576px) and (max-width: 620px) {
        .request-button-wrapper,
        .details-button-wrapper {
          p.block-button-3-0-title {
            font-size: 12px;
          }
        }
      }
      @media (max-width: 462px) {
          .details-button-wrapper {
            margin-top: 14px;
          }
      }

    }
    @media (max-width: 575px) {
      .main-title-block {
        display: none;
      }
      ul {
        margin-top: 41px;
      }
      .buttons-line {
        .request-button-wrapper,
        .details-button-wrapper {
          max-width: unset;
        }
      }
    }
  }
  .right-side {
    .main-title-block {
      display: none;
    }
    .order-button {
      display: block!important;
      margin-top: 10px;
    }
    .text-299 {
      font-size: 42px;
      line-height: 49px;
      color: rgba(0, 0, 0, 0.8);
      span {
        font-size: 14px;
        line-height: 18px;
      }
    }
    @media (min-width: 576px) and (max-width: 600px) {
      .text-299 {
        font-size: 40px;
        span {
          font-size: 13px;
        }
      }
    }
    .agree-text {
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      margin-top: 6px;
      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    img {
      width: 100%;
      max-width: 246px;
      margin: 17px auto 0 auto;
      display: block;
    }
    .see-sample-text {
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      text-align: center;
      color: #0ECFAA;
      margin-top: 28px;
      cursor: pointer;
    }

    @media (max-width: 575px) {
      .main-title-block {
        display: block;
        margin-bottom: 27px;
      }
      img {
        margin-top: 26px;
      }
    }
  }

  &.landing-banner {
    .right-side .pay-banner-title,
    .left-side .pay-banner-title {
      color: #FFFFFF;
    }
    .left-side {
      .text-list {
        color: #FFFFFF;
      }
      .list-descr-block {
        border-top: 1px solid rgba(255, 255, 255, 0.3);
      }
      .text-list:before {
        background: #FFFFFF;
      }
      .list-descr {
        color: #FFFFFF;
      }
      .buttons-line {
        .details-button-wrapper {
          .details-button {
            display: none;
          }
        }
      }
    }
    .right-side {
      .text-299 {
        color: #FFFFFF;
      }
      .agree-text {
        color: #FFFFFF;
      }
    }
  }
}
