@import "top-header";

.sticky-header {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 300;
  width: 100%;
  height: 64px;
  padding: 12px 20px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  .logo-wrapper {
    .logo-wrppaer-row {
      min-height: 40px;
      .edit-profile-title {
        font-size: 20px;
        line-height: 20px;
        color: #2f3849;
        font-weight: 500;
      }
    }
  }
  &.registration-view {
    padding: 0 10px;
    box-shadow: none;
    .temp-links-header {
      height: 54px;
    }
    .logo-wrapper {
      margin-left: 8px;
    }
  }
  &.hide {
    display: none;
  }
  .block-button-hamburger-new {
    background-image: url("../../images/menu_humburger.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 32px;
    height: 32px;
    padding: 0;
    margin: -3px 0 0 20px;
    margin-top: -3px!important;
  }
  .block-logo-new-top-menu-rocket-dao {
    height: auto;
    width: auto!important;
    padding: 0 6px 0 10px;
    a {
      margin:0;
    }
  }
  .container-full-reg {
    display: flex;
  }
  @media (max-width: 756px) {
    .block-logo-new-top-menu-rocket-dao {
      height: 30px;
    }
  }
  .logo-wrapper img {
    width: 100px;
    height: 16px;
    margin: 0;
  }
  .block-search-header-input {
    margin: 0;
    width: 245px;
    transition: 0.2s linear;
    position: relative;
    left: 0;
    .input-close-icon {
      top:13px;
      right: 24px;
      z-index: 10;
      @media (max-width: 465px) {
        right: 40px;
      }
    }
  }
  .block-search-header-input.opened {
    width: 360px;
  }
  .temp-links-header {
    height: 40px;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    margin-right: 8px;
    a {
      margin: 0 0 0 2px;
    }
    .block-list-link-blog-header {
      .header-title-about {
        margin: 0;
      }
    }
    .open-close-header-block {
      width: 180px;
      left: -30px;
    }
    .open-close-header-block.position-left {
      left: -75px;
    }
    .right-no-reg-user {
      margin-right: -16px;
    }
    //.right-no-reg-user {
    //  margin: 5px 0 0 0;
    //}
  }
  .shell-person-name-photo-new-top-menu-rocket-dao {
    height: 35px;
    margin: 0;
  }
  .about-wrapper {
    padding-right: 10px;
    .about-container {
      margin-left: 0;
    }
    .header-title-about {
      margin: 0;
    }
    .header-subtitle-about a {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .search-mobile-cancel-button {
    line-height: 40px;
    margin-left: 0;
  }
  @media (max-width: 1192px) {
    .header-reg-links.hide.investor.expert {
      display: none;
    }
  }
  @media (max-width: 1085px) {
    .header-reg-links.hide.investor.no-expert {
      display: none;
    }
  }
  @media (max-width: 1065px) {
    .header-reg-links.hide.no-investor.expert {
      display: none;
    }
  }
  @media (max-width: 1080px) {
    .header-reg-links.investor.expert {
      display: none;
    }
  }
  @media (max-width: 960px) {
    .header-reg-links.hide.no-investor.no-expert {
      display: none;
    }
  }
  @media (max-width: 970px) {
    .header-reg-links.investor.no-expert {
      display: none;
    }
  }
  @media (max-width: 945px) {
    .header-reg-links.no-investor.expert {
      display: none;
    }
  }
  @media (max-width: 850px) {
    .header-reg-links.no-investor.no-expert {
      display: none;
    }
  }
  @media (max-width: 625px) {
    .about-wrapper.hide .blog-link {
      display: none;
    }
  }
  @media (max-width: 598px) {
    .about-wrapper.hide {
      display: none;
    }
  }
  @media (max-width: 555px) {
    .temp-links-header.hide {
      display: none;
    }
  }
  @media (max-width: 580px) {
    .temp-links-header {
      display: flex;
    }
  }
  @media (max-width: 520px) {
    .about-wrapper .blog-link {
      display: none;
    }
  }
  @media (max-width: 500px) {
    .temp-links-header {
      display: none;
    }
  }
  @media (max-width: 465px) {
    .search-wrapper {
      width: 100%;
      .logo-wrapper {
        width: calc(100% - 60px);
      }
      .block-search-header-input {
        width: calc(100% - 60px);
        transition: none;
        padding-right: 30px;
        .search-header-open-list {
          top:64px;
        }
      }
    }
    &.registration-view {
      .search-wrapper {
        width: unset;
        .logo-wrapper {
          width: auto;
        }
      }
    }
    .search-line-wrapper {
      position: absolute;
      width: 100%!important;
    }
  }
}
.sticky-header.off-sticky {
  position: relative;
}

@media (max-width: 1150px) {
  .sticky-header .temp-links-header .right-no-reg-user {
    margin-right: -9px;
  }
}