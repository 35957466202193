.video-pitch-popup-block {
	background-color: rgba(0,0,0,0.3);
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	max-height: 100vh;
	z-index: 9999999;
}
.video-pitch-popup-inner-block {
	width: 1000px;
	background-color: #FFFFFF;	
	box-shadow: -5px 0 14px 0 rgba(0,0,0,0.1);
	margin: 0 auto;
	display: block;
	max-height: 100vh;
	height: 100vh;
	z-index: 55551;
	padding-bottom: 40px;
}
.video-pitch-popup-header-block {
	width: 100%;
	height: 80px;
	background-color: #FFFFFF;
	display: block;
	box-shadow: -5px 0 14px 0 rgba(0,0,0,0.1);
}
.video-pitch-popup-header-block > div{
	float: left;
}
.video-pitch-popup-header-block .title {
	margin: 30px 35px;
	color: rgba(8,8,8,0.8);	
	font-family: "Stratos LC";	
	font-size: 16px;
	height: 20px;	
	line-height: 20px;
}

.video-pitch-popup-header-block .open-in-new-tab {
	height: 20px;
	color: #2CA6EB;	
	font-family: "Stratos LC";	
	font-size: 16px;	
	font-weight: 500;	
	line-height: 20px;
	margin: 30px 0 30px 0px;
	cursor: pointer;
	transition: all 250ms ease-in-out;
}
.video-pitch-popup-header-block .open-in-new-tab:hover {
	color: #05050a;
}


.video-pitch-popup-header-block .open-in-new-tab .share-icon {
	float: right;
	margin-left: 9px;
}
.video-pitch-popup-header-block .page-number {
	height: 40px;
	width: 180px;
	margin: 20px 30px 20px 75px;
}
.video-pitch-popup-header-block .page-number .title {
	height: 24px;	
	width: 100px;
	display: inline-block;	
	color: rgba(0,0,0,0.5);	
	font-family: "Stratos LC";	
	font-size: 20px;	
	line-height: 24px;	
	text-align: center;
	margin: 10px 0 6px 0;
}
.video-pitch-popup-header-block .page-number .prev-arrow-button,
.video-pitch-popup-header-block .page-number .next-arrow-button {
	height: 40px;
	width: 40px;	
	text-align: center;
	padding-top: 10px;
	color: rgba(0,0,0,0.5);	
	cursor: pointer;
	border-radius: 50%;
	background-color: #FFFFFF;	
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}
.video-pitch-popup-header-block .page-number .prev-arrow-button {
	float: left;
}
.video-pitch-popup-header-block .page-number .prev-arrow-button .arrow {
	transform: rotate(90deg);
}
.video-pitch-popup-header-block .page-number .next-arrow-button {
	float: right;
}
.video-pitch-popup-header-block .page-number .next-arrow-button .arrow {
	transform: rotate(-90deg);
}
.video-pitch-popup-header-block .close {
	top:2px;
	left: 0;
	transform: scale(1.4);
	opacity: 1;
}
.close::before, .close::after {

	position: absolute;
	left: 5px;
	content: ' ';
	height: 15px;
	width: 2px;
	background-color: #333;

}
.video-pitch-popup-header-block .close-title {
	position: relative;
	margin: 30px 30px 30px 10px;
	height: 20px;	
	width: 85px;
	color: rgba(8,8,8,0.8);	
	font-family: "Stratos LC";	
	font-size: 16px;	
	font-weight: 500;	
	line-height: 20px;
	float: right;
	cursor: pointer;
}

.close-title p{
	margin: 0;
	text-align: center;
}


.close {
	top: 2px;
	left: 0;
	-webkit-transform: scale(1.4);
	transform: scale(1.4);
	opacity: 1;
}
.close {
	background-color: transparent;
	position: absolute;
	right: 5px;
	top: 4px;
	width: 15px;
	height: 15px;
	opacity: 0.3;
	transition: all 500ms ease-in-out;
}

.close::before,
.close::after {
	border-radius: 4px;
	transition: all 200ms ease-in-out;
}
.close::after {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.close::before {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.close-title:hover .close:after{
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.close-title:hover .close:before{
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.close-title:hover .close{
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg);
}




.video-pitch-popup-player-block {
	width: 100%;
	height: 520px;
	background-repeat: no-repeat;
	background-size: cover;
	display: inline-block;
	border-radius: 8px;
	margin: 30px 0 0 0;
	background-position: center!important;
	background-size: cover!important;
	background-repeat: no-repeat!important;
}
.video-pitch-popup-player-block .button-play {
	margin: calc(25% - 40px) calc(50% - 40px);
	width: 80px;
	height: 80px;
	background-color: black;
	padding: 20px 29px;
	background-color: rgba(8,8,8,0.8);
	border-radius: 6px;
	cursor: pointer;
}
.video-pitch-popup-player-block .button-play .play {
	width: 0;
  	background: transparent;
  	box-sizing: border-box;
  	height: 20px;
	border-color: transparent transparent transparent #FFFFFF;
  	border-style: solid;
  	border-width: 20px 0 20px 30px;
}

.video-pitch-popup-buttons-block {
	width: inherit;
	height: 80px;
	background-color: #FFFFFF;
	padding: 20px 0;
}
.video-pitch-popup-buttons-block .button {
	float: left;
	margin-right: 15px;
}	
.video-pitch-popup-buttons-block .button .button-block-startup {
	margin: 0;
}
.video-pitch-popup-buttons-block .button .title {
	line-height: 18px!important;
}
.video-pitch-popup-comments-block {
    width: inherit;
    padding: 40px 70px 0 70px;
}
p.title-comments{
	text-align: left;
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 28px;	font-weight: 500;	line-height: 35px;
    margin: 0 0 32px 0; z-index: 10; position: relative;
}
p.title-comments span.count-comments{
    font-weight: 400; margin: 0 0 0 10px;
}

.video-pitch-popup-comments-block p.title-item-comments{
	text-align: left;
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 20px;
    margin: 0 0 4px 0; z-index: 10; position: relative;
}
.video-pitch-popup-comments-block p.title-item-comments span.time{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 12px;	line-height: 20px; margin: 0 0 0 8px ;
}


.block-comments-gradient-gray {
    position: relative;
    width: inherit;
    z-index: 0;
}

.block-comments-gradient-gray:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0) 100%);
    height: 180px;
    width: 100%;
    z-index: 0;
}

.comments-wrap{
	background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0) 100%);
	padding: 0 60px 0 60px;
}

.popup-wrap{
	padding: 30px;
}

.video-pitch-popup-buttons-block .block-button-icon-title-description{
	width: auto;
	float: left;
}

.video-pitch-popup-buttons-block .block-button-icon-title-description:not(:last-child) {
	margin-right: 15px;
}
.container-popup{
	max-width: 1000px;
	margin: 0 auto;
	overflow: hidden;
	display: block;
}
.video-pitch-popup-header-block{
	position: relative;
}
.video-pitch-popup-header-block .open-in-new-tab.mob{
	display: none;
}
@media (max-width: 1000px) {
	.video-pitch-popup-header-block .title{
		display: none;
	}
	.video-pitch-popup-header-block .close-title{
		position: absolute!important;
		top: 0!important;
		left: 0!important;
		width: 20px!important;
		float: none!important;
		margin: 30px 20px!important;
	}
	.close-title p{
		display: none;
	}
	.video-pitch-popup-header-block .page-number{
		margin: 20px 0!important;
		position: absolute;
		top: 0;
		left: calc(50% - 117px);
	}
	.video-pitch-popup-header-block .open-in-new-tab.pc{
		display: none;
	}
	.video-pitch-popup-header-block .open-in-new-tab.mob{
		display: block;
		left: auto;
		right: 20px;
	}
}