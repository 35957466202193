.expert-param-block-wrapper {
  width: 100%;
  min-height: 100px;
  padding: 30px 32px;
  background: #FFFFFF;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.07);
  border-radius: 6px;
  cursor: pointer;
  .param-number-col {
    max-width: 56px;
    min-width: 56px;
    .param-number-wrapper {
      width: 100%;
      height: 40px;
      background: #ECECEC;
      border-radius: 4px;
      .param-number {
        display: block;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
        color: rgba(8, 8, 8, 0.8);
        font-weight: 500;
      }
    }
  }
  .left-side {
    .title-col {
      padding-right: 0;
      .title {
        font-size: 22px;
        line-height: 28px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }
      .subtitle {
        display: none;
        font-size: 12px;
        line-height: 15px;
        color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .progress-col {
    padding: 0 20px 0 0;
    .progress-potential {
      height: 10px;
      background: #ECECEC;
      border-radius: 5px;
      .progress-active {
        height: 10px;
        background: #0ECFAA;
        border-radius: 5px;
      }
    }
  }
  .fa-chevron-down {
    opacity: 0.4;
    transform: rotate(-90deg);
  }

  &.rated-param {
    .param-number-col {
      .param-number-wrapper {
        background: #0ECFAA;
        .param-number {
          color: #FFFFFF;
        }
      }
    }
    .left-side {
      .title-col {
        .title {
          margin-top: -4px;
        }
        .subtitle {
          display: block;
        }
      }
    }
  }

  @media (max-width: 1035px) and (min-width: 992px) {
    padding: 30px 18px;
  }
  @media screen and (max-width: 450px) {
    padding: 30px 20px 22px 20px;
    .left-side {
      .param-number-col {
       margin-bottom: 8px;
      }

      .title-col {
        margin-bottom: 8px;

        .title {
          font-size: 20px;
        }
      }
    }
  }
}