.block-header-marketplace{
    width: 970px;
    max-width: 970px;
    margin: 0 auto;
    padding: 45px 0 120px 0;
    /*min-height: 100vh;*/
}
.block-top-header-marketplace{
    width: 100%;
    display: table;
    height: 70px;
}
.block-top-header-marketplace .block-logo{
    height: 70px;
    width: 130px;
}
.block-top-header-marketplace .block-logo a{}
.block-top-header-marketplace .block-logo a img{
    height: 16px;	width: 100px;
    margin: 27px 0;
}
.block-body-header-marketplace{
    width: 100%;
    position: relative;
    display: table;
}
.block-body-header-marketplace img{
    position: absolute;
    top: 0;
    right: 0;
}
.block-dots-header-marketplace{
    width: 6px;
    float: left;
}
.block-item-dot-header-marketplace{
    height: 55px;	width: 6px;
    margin:  0 0 12px 0;position: relative;
    border-radius: 50%;
    overflow: hidden;
    padding: 0 10px;
    cursor: pointer;
    box-sizing: content-box;
}
.block-item-dot-header-marketplace:before{
    content: '';
    position: absolute;
    width: 6px;
    height: 55px;
    border-radius: 6px;
    left: 10px;
    top: 0;
    background-color: #D0D0D0;
}
.block-item-dot-header-marketplace:after{
    content: '';
    position: absolute;
    width: 6px;
    height: 55px;
    border-radius: 6px;
    left: 10px;
    top: -55px;
}

.block-item-dot-header-marketplace.start:after{
    top: 0;
    transition: 10s;
    transition-timing-function:linear;
}
.block-item-dot-header-marketplace.active:after{
    top: 0;
    transition: 0s;
    transition-timing-function:ease;
}
.block-item-dot-header-marketplace.remove:after{
    top: 55px;
    transition: .5s;
    transition-timing-function:ease;
}
.block-item-dot-header-marketplace.delete:after{
    top: -55px;
    transition: 0s;
}
.block-item-dot-header-marketplace.dot-1:after{
    background-color: #2F3849;
}
.block-item-dot-header-marketplace.dot-2:after{
    background-color: #2CA6EB;
}
.block-item-dot-header-marketplace.dot-3:after{
    background-color: #0ECFAA;
}

.block-info-header-marketplace{
    /*width: calc(100% - 46px);*/
    /*float: right;*/
    width: 100%;
    position: relative;
}
.block-info-header-marketplace p.title-header-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 38px;	font-weight: 600;	letter-spacing: -0.3px;	line-height: 48px;
    margin: 0; transition: .3s;
    max-width: 550px;
    /*height: 144px;*/
    overflow: hidden;
    position: relative;
    z-index: 5;
}
.block-info-header-marketplace p.description-header-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 22px;
    margin: 20px 0 36px 0;transition: .3s;
    max-width: 420px;
}

.block-button-color-header-marketplace{
    width: 150px!important;
    border-radius: 6px;
    height: 51px;
    padding: 0 23px;
    cursor: pointer;
    margin: 0 auto 16px 0;
    transition: .3s;
}
.block-button-color-header-marketplace.black{
    background-color: #2F3849;
}
.block-button-color-header-marketplace.green{
    background-color: #0ECFAA;
}
.block-button-color-header-marketplace.blue{
    background-color: #2CA6EB;
}
.block-button-color-header-marketplace.white{
    background-color: #FFFFFF;
    border: 1px solid rgba(151,151,151,0.2);
}
.block-button-color-header-marketplace p.title-button-color-header-marketplace{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 51px;	text-align: center;
    margin: 0;transition: .3s;
}
.block-button-color-header-marketplace.white p.title-button-color-header-marketplace{
    color: rgba(8,8,8,0.8);line-height: 49px; text-align: left;
}
.block-button-color-header-marketplace p.title-button-color-header-marketplace span.right-title-button{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 16px;	line-height: 49px;	float: right;
    transition: .3s;
}



@media (max-width: 1000px) {
    .block-info-header-marketplace{
        padding: 0 25px 0 25px;
    }
    .block-body-header-marketplace img{
        position: relative!important;
        margin: 60px 0 0 0;
        width: 100%;
    }
}






.block-form-email-marketplace{
    margin: 120px 0 0 0;
}
.block-form-email-marketplace p.title-form-email-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 22px;	font-weight: 500;	line-height: 30px;	text-align: center;
    max-width: 560px;
    margin: 0 auto;
}
.block-form-email-marketplace p.description-form-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: 0 auto; font-weight: 400; text-align: center;
}
.block-form-email-marketplace p.description-form-marketplace b{
    font-weight: 500!important;
}

.block-input-button-form-email-marketplace{
    max-width: 560px;
    margin: 0 auto;
    padding: 0 25px;
    display: table;
    width: 560px;
}
.block-input-button-form-email-marketplace .block-new-input{
    float: left;
    width: 230px;
}
.block-input-button-form-email-marketplace .block-new-input input{
    height: 40px;
    line-height: normal;

}
.block-input-button-form-email-marketplace .block-button-3-0{
    height: 40px;
    float: right;
}
.block-input-button-form-email-marketplace .block-button-3-0 p{
    height: 40px;
    line-height: 40px!important;
}



.block-popup-form-marketplace{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #2F2F2F;
    z-index: 1000;
}

.block-form-marketplace{
    width: 630px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    position: relative;
    margin: 50px auto auto auto;
    padding: 35px 60px;
}
.block-form-marketplace p.title-form-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 28px;	font-weight: 500;	line-height: 35px;
    margin: 0 0 21px 0;
}
.block-form-marketplace p.description-form-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: 0 0 40px 0; font-weight: 400;
}
.block-form-marketplace p.description-form-marketplace b{
    font-weight: 500!important;
}
.block-close-popup-form-marketplace{
    position: absolute;
    width: 35px;
    height: 35px;
    top: 24px;
    right: 24px;
    cursor: pointer;
}
.block-close-popup-form-marketplace i{
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 26px;
}
.block-form-marketplace .block-input-button-form-email-marketplace{
    padding: 0;
    width: 100%;
    max-width: 100%;
}


.block-coming-soon-home-page{
  padding: 60px 0 0 0;
}
.block-banner-marketplace{
    width: 100%;
    margin: 0 auto;
    border-radius: 8px;	background-color: #FFFFFF;	box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    padding: 32px 60px 50px 60px;
    display: table;
}
.block-left-banner-marketplace{
    width: calc(100% - 450px);
    float: left;
}
.block-right-banner-marketplace{
    width: 400px;
    float: right;
}

@media (max-width: 900px) {
    .block-banner-marketplace{
        width: calc(100% - 40px);
        margin: 80px 20px!important;
        border-radius: 8px;	background-color: #FFFFFF;	box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
        padding: 30px 20px;
        display: table;
    }
    .block-left-banner-marketplace{
        width: 100%;
        float: none;
    }
    .block-right-banner-marketplace{
        width: 100%;
        float: none;
        margin: 30px 0 0 0;
    }
    .block-info-header-marketplace p.title-header-marketplace{
        height: auto!important;
    }
}

.block-left-banner-marketplace p.title-banner-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 38px;	font-weight: 500;	line-height: 46px;
    margin: 0 0 12px 0;
}
.block-left-banner-marketplace p.description-banner-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px; font-weight: 400;
    margin: 0 0 26px 0;
    max-width: 340px;
}
.block-left-banner-marketplace .block-button-3-0{
    height: 40px;
}
.block-left-banner-marketplace .block-button-3-0 p{
    height: 40px;
    line-height: 40px!important;
}
.block-right-banner-marketplace img{
    width: 100%;
    margin: 15px 0 0 0;
    opacity: 1!important;
}
.block-list-log-marketplace{
    /*width: 100%;*/
    /*display: table;*/
    height: 71px;
    overflow: hidden;
    max-width: 970px;
    margin: 0 auto;
}
.block-item-log-marketplace{
    width: 251px!important;
    /*float: left;*/
    height: 55px;
    padding: 8px 24px;
    border-right: 1px solid rgba(0,0,0,0.15);
    outline: none;
}
.block-item-log-marketplace p.title-item-log-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 14px;	line-height: 20px;
    font-weight: 400;
    margin: 0;
    width: 100%;

    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
}
.block-item-log-marketplace p.description-item-log-marketplace{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;
    font-weight: 400;
    margin: 0;
    width: 100%;
    white-space: nowrap; /* Запрещаем перенос строк */
    overflow: hidden; /* Обрезаем все, что не помещается в область */
    text-overflow: ellipsis; /* Добавляем многоточие */
}

.block-gradient-new{
    position: relative;
}
.block-gradient-new:before{
    content: '';
    width: 3000px;
    height: 300px;
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255,255,255,0) 100%);
    position: absolute;
    left: -50%;
}

a.no-undeground {
    text-decoration: none;
    color: inherit;
}
.new-mp-rocket {
    color: rgba(8,8,8,0.8);
    font-family: "Stratos LC";
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
}
.date-item-log-marketplace {
    margin: 5px 0;
    color: #000000;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 15px;
    font-weight: 400;
    display: none;
}


.list-avatar .avatar{
    font-size: 100px;
}



.block-button-hamburger-new{
    width: 40px;
    height: 48px;
    float: left;
    cursor: pointer;
    margin: 6px 0 0 8px;
}
.button-hamburger-new{
    width: 18px;
    height: 14px;
    margin: 15px 15px;
    position: relative;
}
.button-hamburger-new span.line-hamburger-new{
    width: 18px;
    height: 2px;
    background: #2F3849;
    position: absolute;
    left: 0;
}
.button-hamburger-new span.line-hamburger-new.line-new-1{
    top: 0;
}
.button-hamburger-new span.line-hamburger-new.line-new-2{
    top: calc(50% - 1px);
}
.button-hamburger-new span.line-hamburger-new.line-new-3{
    bottom: 0;
}
.button-hamburger-new span.circle-state{
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #0ECFAA;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -4px;
    right: -4px;
}
.accelerator-name-title {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0,0,0,0.5);
}
.accelerator-type-block {
    display: inline-block;
    background-color: rgba(0,0,0,0.25);
    border-radius: 4px;
    height: 26px;
    padding: 0 12px;
    font-size: 14px;
    line-height: 26px;
    color: #FFFFFF;
    text-align: center;
}


@media screen and (max-width: 650px) {
    .block-info-header-marketplace .title-header-adaptive {
        font-size: 28px!important;
        line-height: 36px!important;
        height: auto!important;
    }
}

@media all and (max-width: 400px) {
    .block-info-header-marketplace .block-button-color-header-marketplace.black{
        width: 156px!important;
    }
    .block-info-header-marketplace .block-button-white-border {
        width: 156px!important;
    }
}



.block-button-white-border{
    height: 51px;
    border: 1px solid #2F3849;
    /*box-shadow: 0px 8px 19px rgba(47, 56, 73, 0.151664);*/
    border-radius: 6px;
    display: inline-block;
    cursor: pointer;
    /*margin: 1px 0 0 0;*/
    vertical-align: top;
    opacity: 1;
    transition: .3s;
}
.block-button-white-border:hover{
    opacity: 0.8;
}
.block-button-white-border p.title-button-white-border{
    display: inline-block;
    padding: 0 30px;
    line-height: 51px;
    margin: 0;
    font-weight: 500;
}
.block-button-color-header-marketplace.black{
    opacity: 1;
    transition: .3s;
}
.block-button-color-header-marketplace.black:hover{
    opacity: 0.8;
}