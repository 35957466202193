.block-comments{
    width: 100%;
    /*background: linear-gradient(180deg, #F5F5F5 0%, rgba(255,255,255,0) 100%);*/
    padding: 40px 0 0 0;
}
p.title-comments{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 28px;	font-weight: 500;	line-height: 35px;
    margin: 0 0 32px 0; z-index: 10; position: relative;
}
p.title-comments span.count-comments{
    font-weight: 400; margin: 0 0 0 10px;
}

.block-item-comments{
    width: 100%;
    margin: 0 0 25px;
}

.like-count{
    position: absolute;
    line-height: 29px;
    top: 0;
    bottom: 0;
    display: inline-block;
}

.block-item-comments p.title-item-comments{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 20px;
    margin: 0 0 4px 0; z-index: 10; position: relative;
}

.block-item-comments p.title-item-comments span.time{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 12px;	line-height: 20px; margin: 0 0 0 8px ;
}

.time-block{
    display: inline-block;
}
.time-block div{
    display: inline-block;
}

.react-tooltip-lite {
    background: #2F3849;;
    display: inline-block;
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    border-radius: 5px;
    z-index: 100000 !important;
}

.react-tooltip-lite-arrow {
    border-color: #2F3849;
    z-index: 100000 !important;
}

p.description-item-comments{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    font-weight: 400;
    margin: 0;
    /*width: 100%;*/
    padding: 0 120px 0 0;
    position: relative; z-index: 10; position: relative;
}
p.description-item-comments span.like-comments{
    width: 60px;
    position: absolute;
    right: -60px!important;
    top: 0;
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 14px;	line-height: 24px;
    text-align: right;  cursor: pointer;
}
p.description-item-comments span.like-comments span.block-icon-like{
    height: 19px;	width: 19px;
    display: inline-block;
    position: relative;
    margin: 0 5px 0 0;
    top: 5px;
}
p.description-item-comments span.like-comments span.block-icon-like i{
    color: #000000;
    height: 19px;	width: 19px;
    position: absolute;
    top: 0;
    left: 0;
    transition: .3s;
    font-size: 18px;
}
p.description-item-comments span.like-comments span.block-icon-like i.fas{
    opacity: 0;
    color: #2CA6EB;
}
p.description-item-comments span.like-comments span.block-icon-like i.far{
    opacity: 1;
}
p.description-item-comments span.like-comments:hover span.block-icon-like i.fas{
    opacity: 1;
    color: #2CA6EB;
}
p.description-item-comments span.like-comments:hover span.block-icon-like i.far{
    opacity: 0;
}
p.description-item-comments span.like-comments:hover {
    color: #2CA6EB;
}

span.delete-comments{
    width: 15px;
    height: 15px;
    position: absolute;
    top: 3px;
    right: 85px;
    cursor: pointer;
}
span.delete-comments:after{
    content: '';
    position: absolute;
    width: 20px;
    height: 1px;
    background: rgba(0, 0, 0, 0.8);
    transform: rotate(-45deg);
    left: -2px;
    top: 7px;
}
span.delete-comments:before{
    content: '';
    position: absolute;
    width: 20px;
    height: 1px;
    background: rgba(0, 0, 0, 0.8);
    transform: rotate(45deg);
    left: -2px;
    top: 7px;
}

.block-input-button-sent-comments{
    width: 100%;
    display: table;
}
.block-input-button-sent-comments .block-new-input{
    float: left;
    width: calc(100% - 160px)
}
.block-input-button-sent-comments .block-button-3-0{
    float: right;
}
.block-comments-gradient {
    position: absolute;
    width: 200vw;
    /*background: linear-gradient(180deg, #F5F5F5 0%, rgba(255,255,255,0.001) 55%);*/
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255,255,255,1) 55%);
    height: 390px;
    margin-top: -40px;
    margin-left: -50%;
}