.block-fund-sidebar {
	height: 100%;
	width: 100%;
	background-color:#F6F6F6;
	padding: 20px 25px;
	margin-left: 0!important;
	/*margin-left: 15px;*/
	/*position: relative;*/
	/*z-index: 10;*/
}
.block-fund-content {
	margin-left: 265px;
	float: left;
	min-height: 100vh;
	height: auto;
	width: 100%;
	background-color:#FAFAFA;
}
.block-fund-content .block-edit-profile {
	background-color:#FAFAFA;
	padding-left: 85px;
}
.block-fund-content .block-edit-profile .edit-profile-table {
	margin-top: 40px;
}
.block-fund-sidebar .block-fund-info {
	width: 100%;
	height: 40px;
}
.block-fund-sidebar .block-fund-info .avatar {
	float: left;
	width: 40px;
	height: 40px;
	border-radius: 2px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
.block-fund-sidebar .block-fund-info .name-title {
	padding-left: 10px;
	font-family: Stratos LC;
	font-size: 16px;
	line-height: 40px;
	letter-spacing: -0.505952px;
	color: #2F3849;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.block-fund-sidebar .block-fund-buttons-line {
	width: 100%;
	height: 23px;
	margin: 16px 0 20px 0;
}
.button-edit-profile .black-button-profile {
	margin-left: 8px;
	background-color: #FFFFFF;
	border: 1px solid #2F3849;
}
.block-fund-sidebar .block-fund-buttons-line {
	width: 100%;
	height: 23px;
	margin: 16px 0 20px 0;
}
.button-edit-profile .black-button-profile {
	margin-left: 8px;
	background-color: #FFFFFF;
	border: 1px solid #2F3849;
}
.button-edit-profile .black-button-profile .black-button-title {
	color: #2F3849;
}
.button-edit-profile .black-button-profile .black-button-arrow {
	background-image: url(./img/ic_arrow_black.svg);
}
.block-fund-sidebar .block-fund-way {
	width: 100%;
	border-top: 1px solid #D8D8D8;
	border-bottom: 1px solid #D8D8D8;
	padding: 20px 0 20px 0;
}

.block-main-menu {
	margin-top: 20px;
	width: 100%;
}
.block-main-menu .main-menu-title-block {
	margin-top: 10px;
	width: 100%;
	display: table;
}
.block-main-menu .main-menu-title {
	font-size: 17px;
	line-height: 1;
	font-weight: 500;
	letter-spacing: -0.708333px;
	color: #2F2F2F;
	cursor: pointer;
}
.block-main-menu .main-menu-title-block .team-number {
	float: left;
	display: block;
	margin-left: 10px;
	width: auto;
	height: 18px;
	border: 1px solid rgba(8, 8, 8, 0.8);
	border-radius: 11px;
}
.block-main-menu .main-menu-title-block .team-number > div {
	font-size: 12px;
	line-height: 16px;
	letter-spacing: -0.708333px;
	color: rgba(0, 0, 0, 0.8);
	text-align: center;
	padding: 0 10px;
}
.block-newsfeed-temp-text {
	max-width: 610px;
}
.block-fund-newsfeed {
	padding: 30px 0 80px 0;
}
.block-fund-content .main-title {
	font-size: 28px;
	line-height: 28px;
	font-weight: 500;
	color: #2F3849;
}
.block-fund-content .title {
	max-width: 610px;
	width: 50%;
	font-size: 18px;
	line-height: 22px;
	color: #2F3849;
	font-weight: 300;
}
.block-fund-content .normal-weight {
	font-weight: 400;
}
.block-newsfeed-temp-content {
	height: 1450px;
	margin-top: 15px;
	background-image: url(../temp_img/temp_newsfeed.jpg);
	background-repeat: no-repeat;
}
.block-waitlist-temp-text {
	padding: 30px 0 80px 0;
}
.block-fund-waitlist {
	padding: 30px 0 80px 0;
}
.block-waitlist-temp-content {
	height: 365px;
	margin-top: 50px;
	background-image: url(../temp_img/temp_waitlist.jpg);
	background-repeat: no-repeat;
}

.block-fund-members {
	padding: 30px 0 80px 0;
}
.block-fund-members .block-fund-members-title {
	font-size: 28px;
	line-height: 22px;
	color: #2F3849;
	font-weight: 600;
}
.block-fund-members .block-fund-members-title .members-title {
	/*float: left;*/
}
.block-fund-members-title .button-add-members {
	/*float: left;*/
	/*margin-left: 20px;*/
	height: 28px;
	background: #2F3849;
	border-radius: 3px;
	cursor: pointer;
}
.block-fund-members-title .button-add-members .button-add-members-title {
	font-size: 14px;
	line-height: 28px;
	text-align: center;
	color: #FFFFFF;
	padding: 0 10px;
}
.block-fund-members .toggle-buttons-line {
	margin: 22px 80px 24px 0;
	height: 40px;
}
.block-fund-members .toggle-buttons-line > * {
	margin-right: 6px;
	float: left;
}

.block-fund-members .block-fund-members-list {
	margin-top: 8px;
	height: auto;
	margin-right: -10px;
}
.block-fund-members .block-fund-members-list .members-card {
	float: left;
	margin-right: 20px;
	width: 210px;
	height: 345px;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
.block-fund-members .block-fund-members-list .card-request {
	background-image: url(./tempImg/team_request_card.png);
}
.block-fund-members .block-fund-members-list .card-member {
	background-image: url(./tempImg/team_card.png);
}


.profile-dashboard-main-title {
	font-size: 28px;
	line-height: 28px;
	color: #2F3849;
}
.profile-dashboard-16-title {
	font-size: 16px;
	line-height: 22px;
	color: #2f3849;
}
.profile-dashboard-18-title {
	font-size: 16px;
	line-height: 22px;
	color: #2f3849;
}

.row-0::before, .row-0::after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0;
}

.block-invite-fund-members .block-left-list-input-email .block-input-reg {
	float: left;
	max-width: none;
	width: calc(100% - 110px);
	margin-right: 15px;
}
