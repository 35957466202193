.accelerator-right-button-block .block-button-3-0.button-3-0-green {
    background-color: #2f3849;
}
.user-section-title {
    font-size: 38px;
    line-height: 40px;
    color: rgba(8,8,8,0.8);
    font-weight: 500;
}
.user-section-subtitle {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0,0,0,0.5);
}

.margin-top-60 {
    margin-top: 60px;
}
.user-seaction-wrapper .user-member-item {
    height: 56px;
    padding: 8px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px rgba(0,0,0,0.03);
    border-radius: 6px;
    margin-top: 10px;
    /*margin: 10px 18px 0 0;*/
    cursor: pointer;
}
.user-member-item .avatar {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    float: left;
}
.user-member-item .avatar .photo-cart-expert {
    height: 40px;
}
.user-member-item .name,
.user-member-item .descr {
    padding-left: 12px;
    font-size: 16px;
    line-height: 20px;
    margin-top: -2px;
    color: rgba(8,8,8,0.8);
    padding-top: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.user-member-item .avatar .photo-cart-expert img {
    border-radius: 4px;
}
.user-container .block-cart-expert {
    position: relative;
    z-index: 10;
}
.user-container .block-box-shadow-cart-startup {
    z-index: 0;
}
.user-container .block-info-cart-expert .block-button-3-0.button-3-0-blue {
    top: 0;
    right: 0;
}
.user-container .user-member-item-wrapper {
    padding: 0 18px 0 0;
}

@media screen and (max-width: 1020px) {
    .user-container .block-container-expert {
        width: calc(100% - 32px);
        padding: 16px;
    }
    .user-container .block-body-cart-expert-mobile {
        margin: 30px 0 0 0;
    }
}
@media screen and (max-width: 762px) {
    .user-container .block-item-work-experience {
        position: relative;
        padding-bottom: 10px;
    }
    .user-container .block-work-experience .block-item-work-experience .block-left-work-experience {
        float: unset;
        min-height: 38px;
        width: calc(100% - 50px);
    }
    .user-container .block-item-work-experience .block-center-work-experience {
        min-height: 28px;
        float: unset;
        width: calc(100% - 50px)!important;
    }
    .user-container .block-item-work-experience .block-right-work-experience {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        float: unset;
    }
    .user-container .block-description-item-work-experience {
        padding: 10px 0 6px 0;
    }
}


.margin-top-10 {
    margin-top: 10px;
}