.ecosystem-section {
  margin: 60px auto 137px;
  max-width: 990px;
  padding: 0 25px;
  box-sizing: border-box;
  min-width: 320px;
}
.ecosystem-section-title {
  text-align: center;
  margin-bottom: 0.4em;
}

.ecosystem-section-text {
  text-align: center;
  font-size: 23px;
  line-height: 28px;
  margin: 0 auto 1.5em;
  max-width: 600px;
}
.ecosystem-block {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin: 0 -10px;
  &:hover .ecosystem-elem {
    &::before, &::after {
      animation-play-state: paused;
    }
  }
}
.ecosystem-elem {
  width: calc(25% - 20px);
  margin: 0 10px 20px;
  box-sizing: border-box;
  position: relative;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  box-shadow: -4px 6px 14px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  overflow: hidden;
  background: #7317FF;
  &::before {
    content: '';
    width: 2064%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='2064' height='283' viewBox='0 0 2064 283' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_i)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M133.017 41.45c30.04 13.273 61.4 27.13 103.319 27.13 47.755 0 74.941-16.568 102.589-33.417C367.527 17.733 396.623 0 449.5 0c45.585 0 75.034 18.116 108.114 38.464 42.38 26.07 90.719 55.805 186.581 55.805 99.467 0 140.709 32.766 177.647 62.114 29.96 23.803 57.089 45.358 110.158 45.358 53.07 0 80.2-21.555 110.16-45.358 36.94-29.348 78.18-62.114 177.64-62.114 95.87 0 144.21-29.735 186.59-55.805C1539.47 18.116 1568.91 0 1614.5 0c52.88 0 81.97 17.732 110.58 35.163 27.64 16.85 54.83 33.417 102.58 33.417 41.92 0 73.28-13.857 103.32-27.13 39.59-17.491 76.88-33.97 133.02-16.383V283H0V25.067C56.138 7.481 93.43 23.96 133.017 41.45z' fill='%23009470'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_i' x='0' y='-5' width='2064' height='288' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha'/%3E%3CfeOffset dy='-5'/%3E%3CfeGaussianBlur stdDeviation='7'/%3E%3CfeComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1'/%3E%3CfeColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0'/%3E%3CfeBlend in2='shape' result='effect1_innerShadow'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 40% auto;
    animation: ecosystem-wave-back 48s infinite cubic-bezier(0.2, 0.10, 0.2, 0.90);
  }
  &::after {
    content: '';
    width: 2000%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: -1px;
    background-image: url("data:image/svg+xml,%3Csvg width='2000' height='195' viewBox='0 0 2000 195' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M63.1417 40.2978C42.297 46.6564 22.3211 52.75 0 52.75V194V194.5V195H2000L2000 194.5L2000 194L2000 52.75C1947.55 52.7498 1903.78 36.6681 1864.58 22.2637C1832.78 10.5801 1803.99 0 1776 0C1741.46 0 1719.27 15.0101 1693.5 32.4428C1657.81 56.5857 1615.25 85.375 1523.5 85.375C1496.56 85.375 1466.31 75.4678 1432.75 64.4757C1399.02 53.4268 1361.94 41.2816 1321.5 37C1249.2 29.3444 1211.01 44.4311 1169.26 60.9238C1126.06 77.989 1079.05 96.5597 986.5 93C956.424 91.8432 925.231 81.2382 893.961 70.6068C855.618 57.5711 817.16 44.4958 780.5 48.75C756.13 51.578 739.374 58.7062 722.377 65.9365C706.566 72.6623 690.548 79.4765 668 83C615.427 91.2156 578.636 67.9864 541.309 44.4183C505.853 22.0313 469.912 -0.661382 419.5 3C389.472 5.18087 367.871 17.803 346.302 30.4058C324.541 43.121 302.813 55.8166 272.5 57.75C244.848 59.5136 223.882 50.9237 202.72 42.2528C182.687 34.0449 162.477 25.7644 136.25 26.125C108.345 26.5087 85.2544 33.5524 63.1417 40.2978Z' fill='white'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-position: 0 100%;
    background-size: 50% auto;
    animation: ecosystem-wave 48s infinite cubic-bezier(0.71, 0.54, 0.29, 0.5);
  }

  &:nth-child(4n - 2) {
    background: #02A56A;
    &::before {
      background-position: -9% 100%;
    }
    &::after {
      background-position: -10.5% 100%;
    }
    .ecosystem-elem-img {
      width: 64.444444444%;
    }
    .ecosystem-elem-btn {
      background: #02A56A;
    }
    .ecosystem-elem-link {
      color: #02A56A;
      border-bottom: 1px solid #02A56A;
    }
  }
  &:nth-child(4n - 1) {
    background: #1BC8A7;
    &::before {
      background-position: -18% 100%;
    }
    &::after {
      background-position: -21% 100%;
    }
    .ecosystem-elem-img {
      width: 90%;
    }
    .ecosystem-elem-btn {
      background: #1BC8A7;
    }
    .ecosystem-elem-link {
      color: #1BC8A7;
      border-bottom: 1px solid #1BC8A7;
    }
  }
  &:nth-child(4n) {
    background: #BD10E0;
    &::before {
      background-position: -27% 100%;
    }
    &::after {
      background-position: -31.5% 100%;
    }
    .ecosystem-elem-btn {
      background: #BD10E0;
    }
    .ecosystem-elem-link {
      color: #BD10E0;
      border-bottom: 1px solid #BD10E0;
    }
  }
  &:hover {
    box-shadow: -7px 8px 34px rgba(0, 0, 0, 0.15);
    transform: scale(1.04);
    .ecosystem-elem-text {
      opacity: 0;
    }
    .ecosystem-elem-btn-block {
      opacity: 1;
    }
  }
}
.ecosystem-elem-inner {
  position: relative;
  padding-top: 140.9090909%;
  height: 0;
  z-index: 1;
}
@keyframes ecosystem-wave {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-22%);
  }
  100% {
    transform: translateX(-50%);
  }
}
@keyframes ecosystem-wave-back {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-19%);
  }
  100% {
    transform: translateX(0%);
  }
}

.ecosystem-elem-img-block {
  position: absolute;
  padding: 20px;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  min-height: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ecosystem-elem-img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  width: 70%;
}
.ecosystem-elem-img-text {
  font-size: 23px;
  line-height: 0.869565em;
  color: #fff;
}

.ecosystem-elem-text {
  position: absolute;
  color: rgba(0,0,0,.8);
  padding: 1.2285714em;
  margin: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 18px;
  line-height: 1.285714em;
  transition: opacity 0.2s ease-in-out;
  opacity: 1;
}


.ecosystem-elem-btn-block {
  transition: opacity 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 93px;
  opacity: 0;
}
.ecosystem-elem-btn {
  display: inline-block;
  background: #7317FF;
  border: 1px solid #FFFFFF;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  min-width: 156px;
  font-size: 16px;
  line-height: 20px;
  text-decoration: none;
  color: #fff;
  margin-bottom: 10px;
  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 0.8;
  }
}
.ecosystem-elem-link {
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  color: #7317FF;
  border-bottom: 1px solid #7317FF;
  margin-bottom: 20px;
  transition: border-bottom-color 0.2s ease-in-out;
  &:hover {
    border-bottom-color: transparent !important;
  }
}




.industries-section {
  margin: 60px auto 130px;
  max-width: 940px;
  padding: 0 25px;
  box-sizing: border-box;
  min-width: 320px;
  color: #7D29FD;
}

.industries-section-block {
  display: flex;
  align-items: flex-start;
  padding-left: 80px;
}

.industries-section-info {
  width: 42%;
  margin-right: 4%;
  .industries-section-title {
    margin: 0 0 21px;
    text-transform: uppercase;
    font-size: 39px;
    line-height: 46px;
    color: #7D29FD;
    background-image: url("data:image/svg+xml,%3Csvg width='113' height='8' viewBox='0 0 113 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M112.354 4.354a.5.5 0 000-.708L109.172.464a.501.501 0 00-.708.708L111.293 4l-2.829 2.828a.5.5 0 00.708.708l3.182-3.182zM0 4.5h112v-1H0v1z' fill='%237317FF'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 24px top 15px;
    span {
      background: #fdfdfd;
      padding: 0 10px 0 0;
    }
  }
}

.industries-section-text {
  font-size: 23px;
  line-height: 29px;
  font-weight: 600;
  margin: 0;
}

.industries-section-tags {
  width: 54%;
  margin: 0 -3px 0 -2px;
  display: flex;
  flex-wrap: wrap;
}
.industries-section-tag {
  font-size: 26px;
  padding: 0.1em 0.5em;
  background: #7D29FD;
  border-radius: 10px;
  color: #fff;
  margin: 0 2px 5px 3px;
  white-space: nowrap;
  font-weight: 600;
  line-height: 1.25;
}

.turquoise{
  background: #0ECFAA;
}


.success-stories-slider-block {
  margin-bottom: 56px;
  .success-stories-slider-title {
    font-size: 38px;
    line-height: 46px;
    color: rgba(8, 8, 8, 0.8);
    margin: 0 0 38px;
    text-align: center;
  }
  .slick-dots {
    bottom: -36px;
    li {
      margin: 0 4px;
      button:before {
        font-size: 9px;
      }
    }
  }
}
.success-stories-slider-elem {
  margin: 30px 30px 30px 0;
}
.success-stories-slider-img {
  border-radius: 8px;
  box-shadow: -1px 0px 22px rgba(0, 0, 0, 0.1);
  max-width: 80vw;
  width: 940px;
}



.marketplace{
  transition: all linear 1s;
  h2{
    color: rgba(8,8,8,0.8);
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    margin: 0;
  }

  h3{
    color: rgba(0,0,0,0.5);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 20px 0;
  }

  p.more-items{
    color: rgba(8,8,8,0.8);	font-size: 16px;	font-weight: 500;	line-height: 20px;
    margin: 0;
    cursor: pointer;
    i{
      text-align: center;
      margin: 0 0 0 9px;
      font-size: 16px;
      top: 1px;
      position: relative;
    }
  }
  .viral-banner-container {
    overflow: hidden;
    width: 100%;
    height: 208px;
    cursor: pointer;
    .img-block {
      background: url("../../images/marketplace/ViralBanner.png");
      background-repeat: no-repeat;
      background-size: 967px 240px;
      background-position: -18px -10px;
      width: 100%;
      height: 222px;
    }
    @media (max-width: 800px) {
      &{
        height: 208px;
        width: calc(100% - 32px);
        padding: 0;
        margin-left: 16px;
        margin-right: 16px;
        border-radius: 10px;
        //.img-block {
        //  background-size: 967px 236px;
        //}
      }
    }
    @media (max-width: 567px) {
      &{
        max-width: 390px;
        padding-left: 10px;
        margin-left: auto;
        margin-right: auto;
        .img-block {
          background-size: 967px 240px;
        }
      }
    }
    @media (max-width: 440px) {
      &{
        width: calc(100% - 32px);
        padding: 0;
        margin-left: 16px;
        margin-right: 16px;
        //.img-block {
        //  background-size: 967px 236px;
        //}
      }
    }
    @media (max-width: 428px) {
      &{
        .img-block {
          background-size: 967px 236px;
        }
      }
    }
  }

  .marketplace-new-b-img {
    display: block;
    height: auto;
    max-width: 100%;
  }
  @media (min-width: 768px) {
    .marketplace-new-b-img-sh {
      box-shadow: -4px 6px 14px rgba(0, 0, 0, 0.03);
    }
    .marketplace-new-b-img-shd {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
    .marketplace-new-b-img-br8 {
      border-radius: 8px;
    }
    .marketplace-new-b-img-br10 {
      border-radius: 10px;
    }
  }

  .startup-pack-banner-container {
    min-height: 341px;
    width: 100%;
    .img-wrapper {
      background: url("../../images/marketplace/StartupPack.png");
      background-repeat: no-repeat;
      background-position: -18px -10px;
      background-size: cover;
      width: 100%;
      min-height: 340px;
      border-radius: 8px;
      padding: 41px 45px 43px 41px;
      @media (max-width: 992px) {
        background-position: -20px -30px;
        background-size: 970px 590px;
        padding-bottom: 0;
      }
      @media (max-width: 660px) {
        background-position: -150px -30px;
        background-size: 750px 590px;
        padding: 30px 20px 0 20px;
        br {
          display: none;
        }
        .title {
          font-size: 32px;
          line-height: 40px;
        }
      }
      @media (max-width: 576px) {
        background-size: 940px 1200px;
      }
      .title {
        font-weight: 600;
        font-size: 36px;
        line-height: 44px;
        color: #FFFFFF;
        span {
          background: #FF7C33;
          padding: 0 8px;
        }
      }
      .details-wrapper {
        margin-top: 32px;
        .col-12, .col-auto {
          margin-bottom: 20px;
        }
        .details-block {
          background: #FFFFFF;
          box-shadow: -4px 6px 14px rgba(0, 0, 0, 0.6);
          border-radius: 8px;
          padding: 0 18px;
          height: 100%;
          min-height: 136px;
          text-align: center;
          @media (max-width: 576px) {
            max-width: 200px;
            margin: 0 auto;
          }
          .descr {
            font-size: 19px;
            line-height: 23px;
            color: #2F3849;
            font-weight: 500;
            padding: 8px 0 13px 0;
            border-top: 1px solid rgba(47, 56, 73, 0.7);
          }
          .sum-title {
            font-size: 33px;
            line-height: 35px;
            color: #2F3849;
            padding: 26px 0 10px 0;
          }
          &.last-block {
            background: #FF7C33;
            .descr {
              position: relative;
              font-weight: 600;
              font-size: 22px;
              line-height: 28px;
              color: #FFFFFF;
              border-top: unset;
              padding: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      .last-col {
        max-width: 187px;
        @media (max-width: 992px) {
          max-width: unset;
        }
      }
    }
  }
}


@media (max-width: 1024px) {
  .ecosystem-elem-img-text {
    font-size: 2.24609375vw;
  }

  .ecosystem-elem-text {
    font-size: 1.6671875vw;
  }
}

@media (max-width: 899px) {
  .industries-section-block {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .ecosystem-section {
    margin: 30px auto;
  }
  .ecosystem-elem {
    width: calc(50% - 20px);
  }
  .ecosystem-section-text {
    font-size: 20px;
    line-height: 25px;
  }
  .ecosystem-elem-img-text {
    font-size: 4.5vw;
  }
  .ecosystem-elem-text {
    font-size: 2.734375vw;
  }


  .industries-section-info .industries-section-title {
    margin: 0 0 20px;
    font-size: 32px;
    line-height: 38px;
  }
  .industries-section-text {
    font-size: 20px;
    line-height: 24px;
  }
  .industries-section-tag {
    font-size: 22px;
  }

  .industries-section {
    margin-bottom: 70px;
  }
  .success-stories-slider-block .success-stories-slider-title {
    font-size: 28px;
    line-height: 34px;
    margin: 0;
  }
  .success-stories-slider-block .slick-dots {
    bottom: -2px;
  }
  .success-stories-slider-block {
    margin-bottom: 10px;
  }
  .success-stories-slider-elem {
    margin-right: 20px;
  }
}

@media screen and (max-width: 767px) and (max-aspect-ratio: 1/1) {
  .ecosystem-elem {
    width: calc(100% - 20px);
  }
  .ecosystem-elem-img-text {
    font-size: 9vw;
  }
  .ecosystem-elem-text {
    font-size: 5.46875vw;
  }
}



@media (max-width: 699px) {

  .industries-section-block {
    display: block;
  }
  .industries-section-info {
    width: auto;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .industries-section-info .industries-section-title {
    margin: 0 0 12px;
    background: none;
  }
  .industries-section-tags {
    width: auto;
  }
}

@media (max-width: 399px) {
  .ecosystem-elem {
    width: calc(100% - 20px);
  }
  .industries-section-tag {
    font-size: 20px;
  }
  .ecosystem-elem-img-text {
    font-size: 9vw;
  }
  .ecosystem-elem-text {
    font-size: 6.26875vw;
  }
}


@media (max-width: 359px) {
  .industries-section-tag {
    font-size: 18px;
  }
}