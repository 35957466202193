@font-face {
    font-family: "Stratos LC";
    src: url('./stratosweb-semibold.eot');
    src: url('./stratosweb-semibold.eot?#iefix') format('embedded-opentype'),
    url('./stratosweb-semibold.woff') format('woff');
    font-weight: 500;
}
@font-face {
    font-family: "Stratos LC";
    src: url('./stratosweb-semibold.eot');
    src: url('./stratosweb-semibold.eot?#iefix') format('embedded-opentype'),
    url('./stratosweb-semibold.woff') format('woff');
    font-weight: 600;
}
@font-face {
    font-family: "Stratos LC";
    src: url('./stratosweb-regular.eot');
    src: url('./stratosweb-regular.eot?#iefix') format('embedded-opentype'),
    url('./stratosweb-regular.woff') format('woff');
    font-weight: 400;
}
@font-face {
    font-family: "Stratos LC";
    src: url('./stratosweb-semilight.eot');
    src: url('./stratosweb-semilight.eot?#iefix') format('embedded-opentype'),
    url('./stratosweb-semilight.woff') format('woff');
    font-weight: 300;
}