.block-cart-anim-info {
  background: #FFFFFF;
  border-radius: 6px;
  transition: .3s;
  cursor: pointer;
  position: relative;

  height: 100%;



  *{
    user-select: none;
  }

  .check-box-cart-anim-info {
    width: 23px;
    height: 23px;
    border: 1.5px solid #2CA6EB;
    box-sizing: border-box;
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 50%;

    i {
      width: 21px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      font-size: 14px;
      color: #FFFFFF;
    }
  }

  .check-box-cart-anim-info.unchecked {
    background-color: #FFFFFF;

    i {

    }
  }

  .check-box-cart-anim-info.checked {
    background-color: #2CA6EB;

    i {

    }
  }


  p.big-title-cart-anim-info {
    font-family: "Stratos LC";
    font-size: 28px;
    line-height: 25px;
    text-align: center;
    font-weight: 500;
    color: rgba(8, 8, 8, 0.8);
    transition: .4s;
    width: 100%;
    position: absolute;
  }

  p.title-cart-anim-info {
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: rgba(0, 0, 0, 0.747056);
    transition: .4s;
    width: 100%;

    position: absolute;
  }

  p.description-cart-anim-info {
    font-family: "Stratos LC";
    font-size: 11px;
    line-height: 13px;
    margin: 0;
    text-align: center;
    color: #596377;
    transition: .4s;
    height: 100%;
    display: flex;

    span{
      display: flex;
      margin: auto;
    }
  }

  .button-cart-anim-info {
    border-radius: 6px;
    height: 40px;
    width: 156px;
    position: absolute;
    bottom: 32px;
    left: calc(50% - (156px / 2));

    p.title-button-cart-anim-info {
      font-family: "Stratos LC";
      font-size: 16px;
      line-height: 36px;
      margin: 0;
      text-align: center;
    }
  }
}

.block-cart-anim-info.unchecked {
  border: 2px solid #FFFFFF;
  box-shadow: -4px 6px 14px rgba(0, 0, 0, 0.03);

  .button-cart-anim-info {
    background: #2CA6EB;
    border: 2px solid #2CA6EB;

    p.title-button-cart-anim-info {
      color: #FFFFFF;
    }
  }
}

.block-cart-anim-info.checked {
  border: 2px solid #2CA6EB;

  .button-cart-anim-info {
    border: 2px solid #CDD2DD;

    p.title-button-cart-anim-info {
      color: #8A92A3;
    }
  }
}

.block-cart-anim-info.big {
  padding: 20px 20px 90px 20px;
  p.big-title-cart-anim-info {
    margin: 12px 0 8px 0;
    left: 0;
    top: 77px;
  }

  p.title-cart-anim-info {
    margin: 0 0 12px 0;
    left: 0;
    top: 121px;
  }

  p.description-cart-anim-info {
    padding: 73px 0 0 0;
    opacity: 0;
  }
}

.block-cart-anim-info.small {
  padding: 20px 20px 87px 20px;
  p.big-title-cart-anim-info {
    margin: 0;
    position: absolute;
    width: 100%;
    top: 38px;
    left: 0;
    opacity: 1;
    //top: 56px;
  }

  p.title-cart-anim-info {
    margin: 0;
    position: absolute;
    width: 100%;
    top: 69px;
    left: 0;
    opacity: 1;
    //top: 56px;
  }

  p.description-cart-anim-info {
    opacity: 0;
    padding: 30px 0 0 0;
  }
}

.block-cart-anim-info.big:hover {
  box-shadow: -7px 12px 24px rgba(0, 0, 0, 0.1);
  border: 2px solid #FFFFFF;

  p.big-title-cart-anim-info {
    top: 19px;
  }

  p.title-cart-anim-info {
    top: 63px;
  }

  p.description-cart-anim-info {
    opacity: 1;
  }
}
.block-cart-anim-info.small:hover {
  box-shadow: -7px 12px 24px rgba(0, 0, 0, 0.1);
  border: 2px solid #FFFFFF;

  p.big-title-cart-anim-info {
    //top: 0;
    opacity: 0;
  }

  p.title-cart-anim-info {
    //top: 0;
    opacity: 0;
  }

  p.description-cart-anim-info {
    opacity: 1;
  }
}