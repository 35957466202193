.datepicker-custom-input {
    width: 220px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #979797;
    border-radius: 4px;
    background-color: #FFFFFF;
    opacity: 0.5;
    font-size: 14px;
    padding: 0 15px;
}
.datepicker-custom-input.active {
    opacity: 1;
}
.datepicker-custom-input.error {
    border: 1px solid #E43A70;
    opacity: 1;
}