.black-button-profile {
	position: relative;
	float: left;
	min-width: 75px;
	width: auto;
	height: 23px;
	background-color: #2F3849;
	border-radius: 2px;
	cursor: pointer;
}
.black-button-profile .black-button-title {
	font-family: Stratos LC;
	font-size: 12px;
	line-height: 23px;
	color: #FFFFFF;
	padding: 0 20px 0 7px;
}
.black-button-profile .black-button-arrow {
	position: absolute;
	right: 5px;
	top: 5px;
	width: 8px;
	height: 8px;
	background-image: url(./img/ic_arrow_white.svg);
	background-repeat: no-repeat;
	background-position: 50% 50%;
}