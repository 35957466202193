$color_green: #0ECFAA;
$color_blue: #2CA6EB;
$color_black: #2F3849;
$color_text_black: rgba(0, 0, 0, 0.8);
$color_text_gray: rgba(0, 0, 0, 0.4);


.block-order-landing-top {
  background-color: #FFFFFF;
  border-radius: 6px;
  box-shadow: -4px 6px 14px rgba(0, 0, 0, 0.03);
  margin: 85px 0 0 0;

  .block-top-order-landing-top {
    padding: 40px 40px 40px 43px;

    .block-left-top-order-landing-top {


      p.title-top-order-landing-top {
        font-size: 22px;
        line-height: 28px;
        margin: 0;
        font-weight: 500;
        color: $color_text_black;
        //border-left: 6px solid $color_green;
        padding: 0 40px 0 26px;
        position: relative;
      }
      p.title-top-order-landing-top:after{
        content: '';
        position: absolute;
        width: 7px;
        height: calc(100% - 8px);
        background: $color_green;
        left: 0;
        top: 4px;

      }
    }

    .block-right-top-order-landing-top {


      p.price-title-top-order-landing-top {
        margin: 0 0 7px 0;


        span.price {
          font-size: 62px;
          line-height: 55px;
          padding: 0 0 0 4px;
          font-weight: 400;
          color: $color_text_black;
          vertical-align: top;
        }

        span.info {
          display: inline-block;
          padding: 0 0 0 2px;
          margin: -1px 0 0px 0px;
          font-size: 18px;
          line-height: 30px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          vertical-align: top;
        }
      }
      @media screen and (max-width: 991px) {
        p.price-title-top-order-landing-top{
          margin: 20px 0 7px 0;
        }
      }
      .block-button-3-0 {
        height: 51px;

        p.block-button-3-0-title {
          height: 51px!important;
          line-height: 51px!important;

        }
      }
    }
  }

  .block-body-order-landing-top {
    padding: 37px 40px 14px 40px;
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0.0001) 54.77%);

    .block-title-info-body-order-landing-top {


      p.title-info-body-order-landing-top {
        font-size: 24px;
        line-height: 30px;
        margin: 0;
        font-weight: 500;
        color: $color_text_black;

        span.text-green {
          color: $color_green;
        }
      }
    }

    .block-list-item-info-body-order-landing-top {
      padding: 25px 0 0 0;

      .block-item-info-body-order-landing-top {
        margin: 14px 0 13px 0;
        padding: 0 0 0 2px ;

        p.title-check-item-info {
          font-size: 18px;
          line-height: 23px;
          color: $color_text_black;
          margin: 0 0 9px 0;
          font-weight: 500;



          span.title-info {
            margin: 0 6px 0 0;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            max-width: calc(100% - 26px);
          }

          span.check-info {
            width: 18px;
            height: 18px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: 2px;
            background-color: $color_green;
            border-radius: 50%;

            i {
              display: inline-block;
              vertical-align: top;
              color: #FFFFFF;
              width: 18px;
              height: 18px;
              text-align: center;
              line-height: 19px;
              font-size: 10px;
            }
          }
        }

        p.description-item-info {
          font-size: 22px;
          line-height: 28px;
          color: $color_text_black;
          margin: 0;
          font-weight: 400;

          span{
            font-size: 18px;
          }
        }
      }
      @media screen and (max-width: 991px) {
        .block-item-info-body-order-landing-top{
          padding: 0!important;
        }
      }
    }
  }

  .block-bottom-order-landing-top {
    padding: 37px 40px 12px 40px;
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0.0001) 54.77%);

    .block-title-info-bottom-order-landing-top {


      p.title-info-body-order-landing-top {
        font-size: 24px;
        line-height: 30px;
        margin: 0;
        font-weight: 500;
        color: $color_text_black;

        span.text-green {
          color: $color_green;
        }
      }
    }

    .block-list-item-info-bottom-order-landing-top {
      padding: 15px 0 0 0;

      .block-item-info-bottom-order-landing-top {
        margin: 24px 0 33px 0;
        padding: 0 0 0 2px ;

        p.title-check-item-info {
          font-size: 18px;
          line-height: 23px;
          color: $color_text_black;
          margin: 0 0 21px 0;
          font-weight: 500;

          span.title-info {
            padding: 0 8px 0 0;
            display: inline-block;
            vertical-align: top;
          }

          span.check-info {
            width: 18px;
            height: 18px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: 2px;
            background-color: $color_green;
            border-radius: 50%;

            i {
              display: inline-block;
              vertical-align: top;
              color: #FFFFFF;
              width: 18px;
              height: 18px;
              text-align: center;
              line-height: 19px;
              font-size: 10px;
            }
          }
        }

        img.img-item-info {
          margin: 0 0 13px 0;
        }

        p.price-item-info {
          font-size: 22px;
          line-height: 28px;
          color: $color_text_black;
          margin: 0 0 5px 0;
          font-weight: 400;

          span{
            font-size: 18px;
          }
        }

        p.description-item-info {
          font-size: 14px;
          line-height: 18px;
          color: $color_text_black;
          margin: 0 0 16px 0;
          font-weight: 400;
          padding: 0 20px 0 0;
        }

        .button-details-item-info {
          display: inline-block;
          cursor: pointer;
          padding: 0 23px;
          height: 28px;
          border: 1px solid #2F3849;
          border-radius: 4px;

          p.title-details-item-info {
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            color: $color_text_black;
            margin: 0;

          }
        }
      }
      @media screen and (max-width: 991px) {
        .block-item-info-bottom-order-landing-top{
          padding: 0!important;
        }
      }
    }


    .block-description-info-bottom-order-landing-top {
      padding: 11px 40px 22px 40px;

      p.description-info {
        width: 100%;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: $color_text_gray;
        margin: 0;
        font-weight: 400;
      }
    }
  }

  .block-more-info-order-landing-top:after {
    content: '';
    position: absolute;
    width: calc(100% - 80px);
    background-color: rgba(0, 0, 0, 0.3);
    height: 1px;
    top: 0px;
    left: 40px;
  }

  .block-more-info-order-landing-top {
    padding: 20px 40px 30px 40px;
    position: relative;


    .button-more-info {
      display: inline-block;
      height: 23px;
      padding: 0 30px 0 0;
      position: relative;
      cursor: pointer;

      p.title-button-more-info {
        font-size: 18px;
        line-height: 25px;
        margin: 0;
        color: $color_green;
        font-weight: 500;
        transition: .3s;
      }

      :after, :before {
        content: '';
        position: absolute;
        background-color: $color_green;
        height: 2px;
        width: 9px;
        border-radius: 1px;
        top: 13px;
        transition: .3s;
      }

      :after {
        transform: rotate(45deg);
        right: 11px;
      }

      :before {
        transform: rotate(-45deg);
        right: 5px;
      }
    }

    .button-more-info.active {
      :after {
        transform: rotate(-45deg);
      }

      :before {
        transform: rotate(45deg);
      }
    }
  }

  .block-anim-order-landing-top{
    position: relative;
    overflow: hidden;
    height: 284px;
    transition: 1s;
  }
  .block-anim-order-landing-top.active{
    height: 757px;
  }
  .block-body-order-landing-top{
    position: relative;
    top: 0;
    left: 0;
    transition: 1s;
  }
  .block-body-order-landing-top.active{
    top: -270px;
  }
  .block-bottom-order-landing-top{
    position: absolute;
    transition: 1s;
    top: 290px;
    left: 0;
  }
  .block-bottom-order-landing-top.active{
    top: 0;
  }

  @media screen and (max-width: 715px) {
    .block-top-order-landing-top{
      padding: 16px;
    }
    .block-body-order-landing-top{
      padding: 16px 16px 14px 16px;
    }
    .block-bottom-order-landing-top{
      padding: 16px 16px 14px 16px;
    }
    .block-more-info-order-landing-top{
      padding: 16px 16px 15px 16px;
    }
    .block-more-info-order-landing-top:after {
      width: calc(100% - 32px);
    }
    .block-description-info-bottom-order-landing-top{
      padding: 11px 20px 22px 20px!important;
    }

  }



  @media screen and (min-width: 760px) {
    .block-anim-order-landing-top{
      height: 284px;
    }
    .block-anim-order-landing-top.active{
      height: 802px;
    }
    .block-body-order-landing-top.active{
      top: -290px;
    }
    .block-bottom-order-landing-top{
      top: 290px;
    }
  }
  @media screen and (max-width: 760px) {
    .block-anim-order-landing-top{
      height: 298px;
    }
    .block-anim-order-landing-top.active{
      height: 828px;
    }
    .block-body-order-landing-top.active{
      top: -295px;
    }
    .block-bottom-order-landing-top{
      top: 300px;
    }
    .block-more-info-order-landing-top:after {
      left: unset;
    }
  }
  @media screen and (max-width: 575px) {
    .block-anim-order-landing-top{
      height: 465px;
    }
    .block-anim-order-landing-top.active{
      height: 1430px;
    }
    .block-body-order-landing-top.active{
      top: -530px;
    }
    .block-bottom-order-landing-top{
      top: 535px;
    }
  }
  @media screen and (max-width: 420px) {
    .block-anim-order-landing-top.active{
      height: 1500px;
    }
    .block-body-order-landing-top.active{
      top: -530px;
    }
    .block-bottom-order-landing-top{
      top: 535px;
    }
  }
  @media screen and (max-width: 395px) {
    .block-anim-order-landing-top{
      height: 500px;
    }
  }
  @media screen and (max-width: 350px) {
    //.block-anim-order-landing-top{
    //  height: 526px;
    //}
    .block-anim-order-landing-top.active{
      height: 1550px;
    }
    .block-body-order-landing-top.active{
      top: -530px;
    }
    .block-bottom-order-landing-top{
      top: 535px;
    }
  }


}