.block-background-expert{
    height: 180px;
    width: 100%;
    overflow: hidden;
}
.block-background-expert img{
    width: 100%;
}


.block-container-expert{
    margin: -120px auto 0 auto;
    width: 980px;	border-radius: 6px 6px 0 0;	background-color: #FDFDFD;
    padding: 20px;
    text-align: left;
    position: relative;
    min-height: 90vh;
}

@media (min-width: 990px) {
    .card-wrapper.on-scroll .block-container-expert {
        margin: 60px auto 0 auto;
    }
    .card-wrapper.on-scroll .block-background-expert {
        position: fixed;
        margin-top: -234px;
        z-index: 988;
    }
}

.card-wrapper .block-container-expert {
    padding: 20px 20px 140px 20px;
}
.card-wrapper.block-footer {
    margin-top: 0;
}

.block-cart-expert{
    width: 100%;
    display: table;
    z-index: 888;
    position: relative;
}
.block-left-cart-expert{
    width: 140px;
    float: left;
}
.block-right-cart-expert{
    width: calc(100% - 160px);
    float: right;
}
.block-left-cart-expert.startup{
    width: 200px;
    float: left;
}
.block-right-cart-expert.startup{
    width: calc(100% - 240px);
    float: right;
}

.block-photo-cart-expert{
    height: 140px;	width: 140px;	border-radius: 4px;
}
.block-photo-cart-expert .photo-cart-expert{
    height: 140px;	width: 140px;	border-radius: 4px;
    background-size: cover!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
}
.block-photo-cart-expert .photo-cart-expert img{
    display: none;
}

.startup .block-photo-cart-expert{
    height: 200px;	width: 200px;	border-radius: 4px;
}
.startup .block-photo-cart-expert .photo-cart-expert{
    height: 200px;	width: 200px;	border-radius: 4px;
    background-size: 100% 100%!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
}
.photo-cart-expert img {
    width: 100%;
}
.block-title-description-left-expert{
    margin: 25px 0 0 0;
}
.block-title-description-left-expert p.title-l{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0; font-weight: 400;
}
.block-title-description-left-expert p.description-l{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: 6px 0 0 0; font-weight: 400;
}
.block-title-description-left-expert p.description-l.ico{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 14px;	font-weight: 600;	line-height: 24px;	text-align: center;
    border-radius: 12px;	background-color: #2F3849; display: inline-block; padding: 0 16px;
}

.block-info-cart-expert{
    width: 100%;
    display: table;
}
.block-left-info-cart-expert{
    width: calc(100% - 190px);
    float: left;
}
.block-right-info-cart-expert{
    width: 160px;
    float: right;
}
.block-right-info-cart-expert .block-button-3-0.button-3-0-black.disabled {
    width: 100%;
}
.block-right-info-cart-expert .block-button-3-0{
    height: 40px!important;
}
.block-right-info-cart-expert .block-button-3-0 p{
    height: 40px;
    line-height: 40px!important;
}
.block-info-cart-expert p.name-expert{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 28px;	font-weight: 500;	line-height: 34px;
    margin: 0;
    max-width: 380px;
    padding: 0 30px 0 0;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}
.name-expert h1{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 28px;	font-weight: 500;	line-height: 34px;
    margin: 0;
    display: inline-block;
}

.block-info-cart-expert p.name-expert span.check-ok-expert{
    height: 23px;	width: 23px;	background-color: #0ECFAA;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    right: 0;
    top: 5px;
    position: relative; margin: 0 0 0 8px;
}
.block-info-cart-expert p.name-expert span.check-ok-expert.investor{
    background-color: #2CA6EB;
}
.block-info-cart-expert p.name-expert span.check-ok-expert.startup{
    background-color: #2F3849;
}
.block-info-cart-expert p.name-expert span.check-ok-expert i{
    color: #FFFFFF;
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    width: 23px;
    height: 23px;
    line-height: 24px;
    text-align: center;
}

.block-info-cart-expert p.title-prof{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 24px;
    margin: 13px 0 0 0;
}
.block-info-cart-expert p.title-prof span.round-prof{
    padding: 0 12px;
    border-radius: 14px;
    height: 24px;
    display: inline-block;
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 14px;	font-weight: 400;	line-height: 24px;
    margin: 0 8px 0 0;
}
.block-info-cart-expert p.title-prof span.round-prof.expert{
    background-color: #0ECFAA;
}
.block-info-cart-expert p.title-prof span.round-prof.investor{
    background-color: #2CA6EB;
}
.block-info-cart-expert p.title-prof span.round-prof.fund{
    background-color: #2CA6EB;
}

.block-info-cart-expert p.title-prof span.round-prof h2 {
    padding: 0;
    border-radius: 14px;
    height: 24px;
    display: inline-block;
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 0
}


.block-info-cart-expert p.title-prof span.round-prof.startup{
    background-color: #2F3849;
}
.block-info-cart-expert p.description-expert{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: 20px 0 0 0; max-width: 560px;
}
.block-info-cart-expert h2.description-expert{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: 20px 0 0 0; max-width: 560px;
    font-weight: inherit;
}
.block-info-cart-expert .block-button-3-0.button-3-0-green{
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
}
.block-info-cart-expert .block-button-3-0.button-3-0-green p{
    line-height: 40px!important;
    height: 40px;
}
.block-info-cart-expert .block-button-3-0.button-3-0-blue{
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
}
.block-info-cart-expert .block-button-3-0.button-3-0-blue p{
    line-height: 40px!important;
    height: 40px;
}
.block-info-cart-expert .block-button-3-0.button-3-0-border{
    position: absolute;
    top: 20px;
    right: 195px!important;
    height: 40px!important;
    border-color: #2CA6EB;
}
.block-info-cart-expert .block-button-3-0.button-3-0-border p{
    line-height: 36px!important;
    height: 36px;
    color: #2CA6EB;
}

.block-skills-expert{
    margin: 25px 0 0 0;
    width: 100%;
    display: table;
}
.block-skills-expert a{
    color: #2CA6EB;	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: 8px 0 0 0; font-weight: 400; display: block;
}
.block-skills-expert p.title-block-expert{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0; font-weight: 400;
}
.block-skills-expert p.list-skills-expert{
    margin: 10px 0 0 0;
    max-width: 680px;
}
.block-skills-expert p.list-skills-expert span.skill{
    color: #525252;	font-family: "Stratos LC";	font-size: 14px;	line-height: 23px;	text-align: center;
    display: inline-block;
    border-radius: 4px;	background-color: rgba(0,0,0,0.03);
    margin: 0 5px 5px 0;
    padding: 0 8px;
    /*word-wrap: break-word;*/
}


.block-col-3-skills-expert{
    width: 145px;
    float: left;
}

.block-skills-expert p.description-block-expert{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    font-weight: 400;
    margin: 6px 0 10px 0;
}


.block-work-experience{
    margin: 32px 0 0 0;
    width: 100%;
}
.block-work-experience p.title-block-expert{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0; font-weight: 400;
    border-bottom: 1px solid #ECECEC;
    padding: 0 0 8px 0;
}

.block-work-experience .block-item-work-experience{
    /*height: 48px;*/
    width: 100%;
    display: table;
    border-bottom: 1px solid #ECECEC;
    cursor: pointer;
}

.block-work-experience .block-item-work-experience .block-left-work-experience{
    width: 140px;
    height: 48px;
    float: left;
    display: flex;
}
.block-work-experience .block-item-work-experience .block-left-work-experience p.title-work-experience{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: auto 0; font-weight: 400; display: flex;
}
.block-work-experience .block-item-work-experience .block-center-work-experience{
    width: calc(100% - 188px);
    height: 48px;
    float: left;
    display: flex;
}
.block-work-experience .block-item-work-experience .block-center-work-experience p.description-work-experience{
    color: rgba(0,0,0,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: auto 0; font-weight: 400; max-width: 520px;
}
.block-work-experience .block-item-work-experience .block-center-work-experience .description-work-experience h2{
    color: rgba(0,0,0,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: auto 0; font-weight: 400; max-width: 520px; display: inline-block;
}

.block-work-experience .block-item-work-experience .block-center-work-experience p.description-work-experience span.gray{
    color: rgba(0,0,0,0.5);
}
.block-work-experience .block-item-work-experience .block-right-work-experience{
    width: 48px;
    height: 48px;
    float: right;
}
.block-work-experience .block-item-work-experience .block-button-open-close-work-experience{
    width: 48px;
    height: 48px;
}
.block-work-experience .block-item-work-experience .block-button-open-close-work-experience i{
    display: block;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    opacity: 0.3;
    transition: .3s;
}
.block-work-experience .block-item-work-experience.active .block-button-open-close-work-experience i{
    transform: rotate(180deg);
}
.block-work-experience .block-item-work-experience:hover .block-button-open-close-work-experience i{
    opacity: 1;
}

.block-work-experience a.show-all-work-experience{
    color: #0ECFAA;	font-family: "Stratos LC";	font-size: 14px;	font-weight: 500;	line-height: 18px;
    margin: 15px 0 0 0; display: block;
    cursor: pointer;
}
.block-work-experience a.show-all-work-experience.investor{
    color: #2CA6EB;
    cursor: pointer;

}


.open-close-block,
.open-close-block-description-item-work-experience{
    display: none;
    width: 100%!important;
    padding: 0;
}
.block-description-item-work-experience{
    width: 100%;
    display: table;
    padding: 0 0 16px 0;
}
.block-description-item-work-experience .block-left-work-experience{
    width: 140px;
    float: left;
    height: auto!important;
}
.block-description-item-work-experience .block-left-work-experience p.title-work-experience{
    color: rgba(0,0,0,0.5)!important;	font-family: "Stratos LC";	font-size: 14px!important;	line-height: 18px!important;
    margin: 0!important; font-weight: 400!important;
    padding: 0 15px 0 0;
    display: block!important;
}
.block-description-item-work-experience .block-center-work-experience{
    width: calc(100% - 140px)!important;
    float: right;
    display: block!important;
    height: auto!important;
}
.block-description-item-work-experience .block-center-work-experience a{
    color: #0ECFAA;	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    text-decoration: none; font-weight: 400; margin: 0 0 7px 0;
    display: block;
}
.block-description-item-work-experience .block-center-work-experience  a.investor{color: #2CA6EB}
.block-description-item-work-experience .block-center-work-experience p.description-work-experience{
    color: rgba(8,8,8,0.8)!important;	font-family: "Stratos LC";	font-size: 14px!important;	line-height: 18px!important;
    font-weight: 400!important; margin: 0;
}






.block-body-expert{
    width: 100%;
    margin: 32px 0 0 0;
}





.block-cart-achievements{
    width: 100%;
    border-radius: 6px;	background-color: #FFFFFF;	box-shadow: 0 6px 16px 0 rgba(0,0,0,0.07);
    margin: 0 0 24px 0;
}
.block-top-cart-achievements{
    display: table;
    width: 100%;
    height: 100px;
    padding: 30px 24px 30px 32px;
}
.block-top-cart-achievements .block-rating-cart-achievements{
    width: 56px;
    float: left;
    height: 40px;
}
.block-top-cart-achievements .block-rating-cart-achievements span.rating-cart-achievements{
    height: 40px;	width: 40px;	border-radius: 4px;	background-color: #ECECEC;
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 18px;	font-weight: 500;	line-height: 40px;	text-align: center;
    display: block;
}
.block-top-cart-achievements .block-rating-cart-achievements span.rating-cart-achievements.expert{
    background-color: #0ECFAA;
    color: #FFFFFF;
}
.block-top-cart-achievements .block-rating-cart-achievements span.rating-cart-achievements.investor{
    background-color: #2CA6EB;
    color: #FFFFFF;
}

.block-top-cart-achievements .block-title-cart-achievements{
    width: 315px;
    float: left;
    height: 40px;
}
.block-top-cart-achievements .block-title-cart-achievements p.title-cart-achievements{
    color: #000000;	font-family: "Stratos LC";	font-size: 22px;	font-weight: 500;	line-height: 40px;
    margin: 0;
}
.block-top-cart-achievements .block-title-cart-achievements h2.title-cart-achievements{
    color: #000000;	font-family: "Stratos LC";	font-size: 22px;	font-weight: 500;	line-height: 40px;
    margin: 0;
}

.block-top-cart-achievements .block-progress-cart-achievements{
    width: 250px;
    height: 40px;
    float: left;
}
.block-top-cart-achievements .block-progress-cart-achievements .block-progress-bar-cart-achievements{
    background-color: #ECECEC;	border-radius: 5px;	height: 10px;
    margin: 15px 0;
    width: 100%;
}
.block-top-cart-achievements .block-progress-cart-achievements .block-progress-bar-cart-achievements .block-progress-cart-achievements{
    border-radius: 5px;	height: 10px;	background-color: #ECECEC;
}
.block-top-cart-achievements .block-progress-cart-achievements .block-progress-bar-cart-achievements .block-progress-cart-achievements.expert{
    background-color: #0ECFAA;
}

.block-top-cart-achievements .block-description-cart-achievements{
    width: 220px;
    height: 40px;
    float: left;
    display: flex;
}
.block-top-cart-achievements .block-description-cart-achievements p.description-cart-achievements{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 20px;	text-align: right;
    width: 100%;
    margin: auto 0;
}

.block-top-cart-achievements .block-button-open-close-cart-achievements{
    width: calc(100% - 220px - 250px - 315px - 56px);
    float: right;
    height: 40px;
}
.block-top-cart-achievements .block-button-open-close-cart-achievements i{
    display: block;
    text-align: center;
    line-height: 40px;
    height: 40px;
    width: 40px;
    margin: 0 0 0 auto;
    transition: .3s;
}
.block-cart-achievements.active .block-top-cart-achievements .block-button-open-close-cart-achievements i{
    transform: rotate(180deg);
}


.block-body-cart-achievements{
    border-top: 1px solid #ECECEC;
    width: 100%;
    padding: 30px 24px 30px 32px;
    display: none;
}
.block-list-state-cart-achievements{
    width: 100%;
    display: table;
}
.block-left-state-cart-achievements{
    width: 200px;
    float: left;
    margin: 0 100px 0 0;
}
.block-center-state-cart-achievements{
    width: 200px;
    float: left;
    margin: 0 100px 0 0;
}
.block-right-state-cart-achievements{
    width: 200px;
    float: left;
}

.block-list-state-cart-achievements p.title-state-cart-achievements{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 18px;	font-weight: 600;	line-height: 22px;
    margin: 0 0 15px 0;
}
.block-list-state-cart-achievements p.description-state-cart-achievements{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 24px;
    height: 24px;

    margin: 4px 0; font-weight: 400;
}
.block-list-state-cart-achievements p.description-state-cart-achievements span.rating{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 14px;	line-height: 24px;
    display: inline-block;
    float: right;
}
.block-list-state-cart-achievements p.description-state-cart-achievements span.rating.expert{
    height: 24px;	width: 24px;	border-radius: 4px;	background-color: #0ECFAA; text-align: center; color: #FFFFFF;
}

.block-list-state-cart-achievements p.description-state-cart-achievements span.rating.investor{
    height: 24px;	width: 24px;	border-radius: 4px;	background-color: #2CA6EB; text-align: center; color: #FFFFFF;
}

.block-list-achievements-cart-achievements{
    border-top: 1px solid #ECECEC;
    width: calc(100% + 56px);
    padding: 24px 24px 0 32px;
    margin: 35px -24px 0 -32px;
}
p.title-list-achievements-cart-achievements{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 14px;	font-weight: 600;	line-height: 24px;
    margin: 0 0 16px 0;
}
.list-achievements-tab{}
.list-achievements-tab .block-button-tab-counter{
    margin: 0 6px 6px 0;
}

.list-achievements-cart-share{
    width: 100%;
    display: table;
    margin: 18px 0 0 0;
}
.block-item-achievements-cart-share{
    height: 52px;	width: calc((100% / 3) - 6px);	border: 1px solid rgba(151,151,151,0.2);	border-radius: 6px;	background-color: #FFFFFF;
    display: flex; float: left; margin: 0 6px 6px 0;
}
.block-left-achievements-cart-share{
    width: calc(100% - 40px);
    height: 52px;
    float: left;
    display: flex;
}
.block-left-achievements-cart-share p.title-achievements-cart-share{
    color: #000000;	font-family: "Stratos LC";	font-size: 12px;	line-height: 14px;
    margin: auto 0; display: flex; font-weight: 400;
    padding: 0 0 0 12px;
    max-height: 42px;
    overflow: hidden;
}
.block-right-achievements-cart-share{
    width: 40px;
    height: 52px;
    float: right;
}
.block-right-achievements-cart-share i{
    width: 40px;
    line-height: 52px;
    height: 52px;
    text-align: center;
    color: rgba(8,8,8,0.5);
}



.block-social-link-expert{
    width: 100%;
    padding: 0 0 80px 0;
    margin: 60px 0 0 0;
}
p.title-social-link{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 28px;	font-weight: 500;	line-height: 35px;
    width: 100%; position: relative;z-index: 10;
}
p.title-social-link a{
    display: inline-block;
    height: 22px;	width: 22px;
    margin: 0 0 20px 35px;
}
p.title-social-link a i{
    height: 22px;	width: 22px;
    opacity: 0.4;
    transition: .3s;
    color: #000000;
}
p.title-social-link a:hover i{
    opacity: 1;
}
p.description-social-link{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 24px;
    margin: 42px 0 0 0;
}


.block-comments-expert{
    width: 100%;
    /*background: linear-gradient(180deg, #F5F5F5 0%, rgba(255,255,255,0) 100%);*/
    padding: 40px 0 0 0;
}
p.title-comments{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 28px;	font-weight: 500;	line-height: 35px;
    margin: 0 0 32px 0; z-index: 10; position: relative;
}
p.title-comments span.count-comments{
    font-weight: 400; margin: 0 0 0 10px;
}

.block-item-comments{
    width: 100%;
    margin: 0 0 25px;
}
.block-item-comments p.title-item-comments{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 20px;
    margin: 0 0 4px 0; z-index: 10; position: relative;
}
.block-item-comments p.title-item-comments span.time{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 12px;	line-height: 20px; margin: 0 0 0 8px ;
}
p.description-item-comments{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    font-weight: 400;
    margin: 0;
    /*width: 100%;*/
    display: inline-block;
    padding: 0 120px 0 0;
    position: relative; z-index: 10; position: relative;
}
p.description-item-comments span.like-comments{
    width: 130px;
    position: absolute;right: -30px;
    top: -4px;
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 14px;	line-height: 24px;
    text-align: left;  cursor: pointer;
}
p.description-item-comments span.like-comments span.block-icon-like{
    height: 19px;	width: 19px;
    display: inline-block;
    position: relative;
    margin: 0 5px 0 0;
    top: 5px;

}
p.description-item-comments span.like-comments span.block-icon-like i{
    color: #000000;
    height: 19px;	width: 19px;
    position: absolute;
    top: 0;
    left: 0;
    transition: .3s;
    font-size: 18px;
}
p.description-item-comments span.like-comments span.block-icon-like i.fas{
    opacity: 0;
    color: #2CA6EB;
}
p.description-item-comments span.like-comments span.block-icon-like i.far{
    opacity: 1;
}
p.description-item-comments span.like-comments:hover span.block-icon-like i.fas{
    opacity: 1;
    color: #2CA6EB;
}
p.description-item-comments span.like-comments:hover span.block-icon-like i.far{
    opacity: 0;
}
p.description-item-comments span.like-comments:hover {
    color: #2CA6EB;
}

.block-title-description-info{
    width: 100%;
    display: table;
    margin: 25px 0 25px 0;
}
.block-title-description-info p.title-info-investor{
    color: rgba(0,0,0,0.5);
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    font-weight: 400;
}
.block-title-description-info p.description-info-investor{
    color: rgba(8,8,8,0.8);
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    margin: 6px 0 0 0;
    font-weight: 400;
}

.block-title-description-col{
    width: 100%;
    display: table;
    margin: 0 0 25px 0;
}
.block-title-description-col .block-col-title-description{
    width: 170px;
    margin: 0 30px 0 0;
    float: left;
}
.block-title-description-col .block-col-title-description p.title-info-investor{
    color: rgba(0,0,0,0.5);
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    font-weight: 400;
}
.block-title-description-col .block-col-title-description p.description-info-investor{
    color: rgba(8,8,8,0.8);
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    margin: 6px 0 0 0;
    font-weight: 400;
}


.block-list-previous-investor{
    width: 100%;
    display: table;
}
.block-left-previous-investor{
    width: calc(50% - 4px);
    margin: 0 8px 0 0;
    float: left;
}
.block-right-previous-investor{
    width: calc(50% - 4px);
    margin: 0;
    float: right;
}
.block-open-close-previous-investor{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 12px;
    right: 12px;
}
.block-open-close-previous-investor i{
    opacity: 0.3;
    transition: .3s;
}
.block-top-previous-investor:hover .block-open-close-previous-investor i{
    opacity: 1;
}
.block-previous-investor.active .block-open-close-previous-investor i{
    transform: rotate(180deg);
}
.block-list-previous-investor p.title-block-expert{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0 0 10px 0; font-weight: 400;
}
.block-previous-investor{
    width: 100%;
    margin: 0 0 8px 0;
    border-radius: 6px;	background-color: #FFFFFF;	box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
}
.block-top-previous-investor{
    width: 100%;
    padding: 12px;
    position: relative;
    cursor: pointer;
}
.block-top-previous-investor p.title-previous-investor{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 18px;
    font-weight: 500; margin: 0;
    padding: 0 30px 0 0 ;


    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.block-top-previous-investor p.info-previous-investor{
    color: rgba(8,8,8,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px; font-weight: 400;
    margin: 15px 0 0 0;
}
.block-top-previous-investor p.info-previous-investor span{color: rgba(8,8,8,0.8); font-size: 16px; margin: 0 15px 0 5px}
.block-body-previous-investor{
    border-top: 1px solid rgba(0,0,0,0.15);
    display: none;
}
.block-body-previous-investor p.description-previous-investor{
    padding: 12px;
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0; font-weight: 400;
}


.block-list-cart-business-investor {
    width: 100%;
    display: table;
    margin: 32px 0 0 0;
}
.block-list-cart-business-investor p.title-block-expert{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0 0 10px 0; font-weight: 400;
}
.block-cart-business-investor{
    width: calc(33.33% - 8px);
    margin: 0 8px 8px 0;
    float: left;
    border-radius: 6px;	background-color: #FFFFFF;	box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    padding: 8px;
    height: 56px;
    cursor: pointer;
    overflow: hidden;
}
.block-cart-business-investor .block-left-cart-business-investor{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    float: left;
}
.block-cart-business-investor .block-right-cart-business-investor{
    height: 40px;
    width: calc(100% - 40px);
    float: left;
    display: flex;
}
.block-cart-business-investor .block-left-cart-business-investor .block-img-cart-business-investor{
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background-size: 100% 100%!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
}
.block-cart-business-investor .block-right-cart-business-investor p.title-cart-business-investor{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 18px; margin: auto 0;
    font-weight: 500; text-align: left;
    padding: 0 0 0 8px;
}
.block-achievements-investor{
    width: 100%;
    display: table;
    margin: -20px 0 0 0;
}
.block-achievements-investor p.title-achievements-investor{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 38px;	font-weight: 500;	line-height: 48px;
    margin: 0;
}
.block-list-tab-achievements-investor{
    margin: 25px 0 15px 0;
}
.block-list-tab-achievements-investor .block-button-tab-counter{
    margin: 0 6px 6px 0;
}
.block-list-achievements-investor{
    width: 100%;
    display: table;
}
.block-item-achievements-investor{
    width: calc(33.33% - 8px);
    float: left;
    margin: 0 8px 8px 0;
    border: 1px solid rgba(151,151,151,0.2);	border-radius: 6px;	background-color: #FFFFFF;
    padding: 12px;
    display: flex;
    min-height: 52px;
    cursor: pointer;
    transition: .3s;
}

.block-item-achievements-investor p{
    text-align: justify !important;
}

.block-item-achievements-investor:hover{
    border: 1px solid rgba(151,151,151,0.5);
}

.block-item-achievements-investor p.title-item-achievements-investor{
    color: #000000;	font-family: "Stratos LC";	font-size: 12px;	line-height: 14px;
    margin: auto 0; text-align: center; width: 100%; display: inline-block;
}
.block-item-achievements-investor p.title-item-achievements-investor:not(.show){
    text-align: left!important;
}
.block-item-achievements-investor p.title-item-achievements-investor span{
    text-align: center; display: inline-block;
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 11px;	font-weight: 500;	line-height: 13px;
    margin: 0;
    transition: .3s;
}
.block-item-achievements-investor p.title-item-achievements-investor span i{
    display: inline-block;
    margin: 0 0 0 5px;
    transition: .3s;
}
.block-item-achievements-investor:hover p.title-item-achievements-investor span{
    color: rgba(0,0,0,0.8);
}

.block-body-team {
    width: calc(100% + 70px);
    height: 470px;
    /*margin-left: -30px;*/
    /*padding-left: 30px;*/
    /*padding-bottom: 50px;*/
    /*margin-top: 60px;*/
    position: relative;
    /*overflow: hidden;*/
}
.block-body-team .swiper-slide {
    min-width: 240px;
}
p.title-block-body-team {
    color: rgba(8,8,8,0.8);
    font-family: "Stratos LC";  font-size: 38px;    font-weight: 600;   line-height: 48px;
    margin: 35px 0 8px 0;
}
p.title-block-body-team span {
    color: #2CA6EB;
}
p.description-block-body-team{
    opacity: 0.8;	color: #000000;	font-family: "Stratos LC";	font-size: 24px;	line-height: 25px;
    margin: 0;
}
.block-body-team .show-all {
    color: rgba(0,0,0,0.5); font-family: "Stratos LC";  font-size: 16px;    font-weight: 600;   line-height: 20px;
    float: right;
    margin: 2px 0 0 0;
    cursor: pointer;
}
.block-body-team .arrow-right {
    height: 29px;   width: 29px;    background-color: #FFFFFF;  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 230px;
}
.block-body-team .arrow-right .arrow {
    text-align: center;
    transform: rotate(-90deg);
    margin-top: 5px;
}
.block-body-team-list {
    cursor: pointer;
    float: left;
    /*width: 220px;*/
    /*height: 345px;*/
    margin: 25px 20px 0 0;
    transition: .3s;
    border-radius: 6px; background-color: #FFFFFF;  box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
}
@media (max-width: 500px) {
    .block-body-team-list {
        margin: 0;
    }
}
.block-body-team-list:hover {
    box-shadow: -7px 12px 24px 0 rgba(0,0,0,0.1);
}
.block-body-team-list .content-box {
    width: inherit;
    height: inherit;
    padding: 16px 16px 12px 16px;
    position: relative;
}
.list-avatar {
    height: 200px;  width: 200px;   border-radius: 4px;
    background-position: center!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
}
.list-user-name {
    margin-top: 12px;
    margin-bottom: 0;
    color: rgba(8,8,8,0.8); font-family: "Stratos LC";  font-size: 20px;    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.list-position {
    margin-top: 6px;
    color: rgba(0,0,0,0.5); font-family: "Stratos LC";  font-size: 14px;    line-height: 18px;
}
.list-social {
    display: flex;
    flex-direction: column;
    /*font-size: 25px!important;*/
    line-height: 22px!important;
    margin-top: 12px;
}
.list-social span {
    margin: 0 10px 0 0!important;
}
.list-social span i {
    margin-right: 5px;
    color: rgba(0,0,0,0.5);
    transition: .3s;
    cursor: pointer;
}
.list-social span i:hover {
    color: rgba(0,0,0,0.8);
}



.block-background-gray{
    position: relative;
    padding: 68px 0 80px 0;
}
.block-background-gray p.title-social-link{
    padding: 0 0 68px 0;
    margin: 0;
}
.block-background-gray:after{
    content: '';
    width: 3000px;
    height: 172px;
    background-color: #F4F4F4;
    left: -100%;
    position: absolute;
    top: 0;
    z-index: 0;
}



.block-list-investors{
    width: 100%;
    margin: 80px 0 0 0;
}
.block-list-investors p.title-investors{
    color: #2F3849;	font-family: "Stratos LC";	font-size: 38px;	font-weight: 500;	line-height: 48px;
    margin: 0 0 24px 0;
}
.block-list-item-investors{
    width: 100%;
    display: table;
    margin: 0 0 30px 0;
}
.block-list-item-investors .block-item-investors{
    width: calc(25% - 16px);
    min-width: 220px;
    height: 80px;
    float: left;
    margin: 0 16px 16px 0;
    border-radius: 6px;	background-color: #FFFFFF;	box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    padding: 8px;
    transition: .3s;
    cursor: pointer;
}
.block-list-item-investors .block-item-investors .block-left-item-investors{
    height: 64px;	width: 64px;
    float: left;
}
.block-list-item-investors .block-item-investors .block-right-item-investors{
    width: calc(100% - 64px);
    padding: 0 0 0 12px;
    float: right;
}
.block-list-item-investors .block-item-investors .block-left-item-investors .block-logo-item-investors{
    height: 64px;	width: 64px;	border-radius: 4px;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: 100% 100%!important;
}
.block-list-item-investors .block-item-investors .block-right-item-investors p.title-item-investors{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: 0; height: 40px;
    overflow: hidden;
}
.block-list-item-investors .block-item-investors .block-right-item-investors p.description-item-investors{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 20px;
    margin: 5px 0 0 0;
}
.block-list-item-investors .block-item-investors.show{
     border: 1px solid rgba(151,151,151,0.2);
 }
.block-list-item-investors .block-item-investors.show p.title-show-more{
    width: 100%;
    height: 64px;
    line-height: 64px;
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 11px;	font-weight: 500;
    margin: 0;
    transition: .3s;
    text-align: center;
}
.block-list-item-investors .block-item-investors.show p.title-show-more i{
    margin: 0 0 0 8px;
    color: rgba(0,0,0,0.5);
    transition: .3s;
}
.block-list-item-investors .block-item-investors.show:hover{
    border: 1px solid rgba(151,151,151,0.5);
}
.block-list-item-investors .block-item-investors.show:hover p.title-show-more{
    color: rgba(0,0,0,0.8);
}
.block-list-item-investors .block-item-investors.show:hover p.title-show-more i{
    color: rgba(0,0,0,0.8);
}


.block-expert-evaluation{
    width: 100%;
    margin: 25px 0 0 0;
    border: 1px solid #D0D0D0;	border-radius: 4px;		background-color: #2F3849;
    padding: 15px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.block-expert-evaluation p.title-expert-evaluation{
    color: rgba(255, 255, 255, 0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 48px;
    font-weight: 300;
    margin: 0; text-align: center;
}
.block-expert-evaluation p.title-expert-evaluation span{
    color: #0ECFAA;	font-family: "Stratos LC";	font-size: 38px;	font-weight: 500;	line-height: 48px;
}
.block-expert-evaluation p.description-expert-evaluation{
    color: #ffffff;	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;	text-align: center;
    margin: 0; font-weight: 300;
}
.block-expert-evaluation:hover .block-back-expert-evaluation{
    opacity: 1;
}
.block-back-expert-evaluation{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2F3849;
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    opacity: 0;
    transition: .3s;
}
.block-back-expert-evaluation p.title-back-expert-evaluation{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 12px;	line-height: 14px;	text-align: center;
    margin: 0; font-weight: 300;
}
.block-back-expert-evaluation a{
    color: #0ECFAA;	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;	text-align: center;
    margin: 22px 0 0 0;font-weight: 400;
    display: block;
    width: 100%;
    text-decoration: none;
}



.block-button-icon-title-description{
    width: 100%;
    display: table;
    height: 39px;
    border: 1px solid #D0D0D0;	border-radius: 6px;	background-color: #FFFFFF;
    margin: 0 0 7px 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: block;
}
.block-button-icon-title-description .block-button-icon{
    width: 37px;
    height: 37px;
    float: left;
    position: relative;
    transition: .3s;
    left: 0;
}
.block-button-icon-title-description .block-button-icon i{
    width: 37px;
    height: 37px;
    line-height: 37px;
    text-align: center;
}
.block-button-icon-title-description .block-button-icon i.fa-heart{
color: #E43A70;
}
.block-button-icon-title-description .block-button-icon i.fa-clock{
color: #0ECFAA;
}
.block-button-icon-title-description .block-button-icon i.fa-share-alt{
color: #2CA6EB;
}
.block-button-icon-title-description .block-button-title{
    width: 50px;
    height: 37px;
    float: left;
}
.block-button-icon-title-description .block-button-title p.title-button-title{
    color: #2F3849;	font-family: "Stratos LC";	font-size: 14px;	line-height: 37px;
    margin: 0; height: 37px;
    overflow: hidden;
    position: relative;
    transition: .3s;
    left: 0;
}
.block-button-icon-title-description .block-button-description{
    width: calc(100% - 87px);
    float: right;
    height: 37px;
    position: relative;
    transition: .3s;
    left: 0;
}
.block-button-icon-title-description .block-button-description p.description-button-description{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 12px;	line-height: 37px;	text-align: right;
    margin: 0; padding: 0 9px 0 0;height: 37px;
    overflow: hidden;
    position: relative;
    transition: .3s;
    left: 0;
}

.block-button-icon-title-description:hover .block-button-social-icon-title-description{
    left: 0px;
}
.block-button-icon-title-description.social:hover .block-button-icon{
    left: -160px;
}

.block-button-icon-title-description.social:hover .block-button-title p.title-button-title{
    left: -160px;
}
.block-button-icon-title-description.social:hover .block-button-description p.description-button-description{
    left: -160px;
}
.block-button-social-icon-title-description{
    display: table;
    height: 37px;
    position: absolute;
    width: 160px;
    padding: 6px 0 6px 28px;
    left: 160px;
    transition: .3s;
}
.block-button-social-icon-title-description .block-item-button-social{
    width: 24px;
    height: 24px;
    float: left;
    margin: 0 16px 0 0;
    cursor: pointer;
}
.block-button-social-icon-title-description .block-item-button-social i{
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 18px;
    margin: 4px;
    color: rgba(0,0,0,0.8);
    transition: .3s;
}
.block-button-social-icon-title-description .block-item-button-social:hover i{
    color: rgba(0,0,0,0.5);

}


.block-super-description-startup{
    width: 100%;
}
.block-top-super-description-startup{
    border-bottom: 1px solid rgba(151,151,151,0.45);
    padding: 40px 0;
}
.block-top-super-description-startup p.title-top-super-description-startup{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 38px;	font-weight: 500;	line-height: 48px;
    margin: 0 0 20px 0;
}
.block-top-super-description-startup h2.title-top-super-description-startup{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 38px;	font-weight: 500;	line-height: 48px;
    margin: 0 0 20px 0;
    display: block;
}
.block-top-super-description-startup p.description-top-super-description-startup{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 18px;	line-height: 28px;
    margin: 0;
}
.block-body-super-description-startup{

}
.block-row-super-description-startup{
    display: table;
    width: 100%;
    border-bottom: 1px solid rgba(151,151,151,0.45);
    padding: 40px 0;
}
.block-left-row-super-description-startup{
    width: calc(50% - 20px);
    float: left;
}
.block-right-row-super-description-startup{
    width: calc(50% - 20px);
    float: right;
}
.block-row-super-description-startup p.title-super-description-startup{
    color: #000000;	font-family: "Stratos LC";	font-size: 24px;	line-height: 25px;
    margin: 0 0 12px 0;
}
.block-row-super-description-startup h2.title-super-description-startup{
    /*color: #000000;	font-family: "Stratos LC";	font-size: 24px;	line-height: 25px;*/
    margin: 0 0 12px 0;
    /*font-weight: inherit;*/
}
.block-row-super-description-startup p.description-super-description-startup{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 18px;	line-height: 24px;
    margin: 0;
}


.block-progress-block-startup{
    width: 100%;
    display: table;
    border: 2px solid #D0D0D0;	border-radius: 4px;	background-color: #FFFFFF;
    padding: 14px 0 16px 0;
    margin: 16px 0 0 0;
}
.block-progress-block-startup .block-item-progress-block-startup{
    width: calc(33.33% - 55px);
    padding: 0 13px;
    float: left;
}
.block-progress-block-startup .block-item-progress-block-startup p.title-progress-block-startup{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	font-weight: 500;	line-height: 18px;
    margin: 0 0 6px 0;
    height: 18px; overflow: hidden;
}
.block-progress-block-startup .block-item-progress-block-startup.active p.title-progress-block-startup{
    color: rgba(8,8,8,0.8);
}
.block-progress-block-startup .block-item-progress-block-startup p.description-progress-block-startup{
    color: #979797;	font-family: "Stratos LC";	font-size: 11px;	line-height: 13px;
    margin: 0; height: 26px; overflow: hidden;
}
.block-progress-block-startup .block-item-progress-block-startup.active p.description-progress-block-startup{
    height: 13px;
}
.block-progress-block-startup .block-line-progress-block-startup{
    width: 60px;
    height: 2px;
    background: #D0D0D0;
    float: left;
    margin: 9px 0 0 0;
}

.block-button-progress-block-startup{
    width: 134px;
    height: 23px;
    background: #D0D0D0;
    cursor: pointer;
    border-radius: 2px;
    opacity: 1;
    transition: .3s;
}
.block-button-progress-block-startup:hover{
    opacity: .8;
}
.block-button-progress-block-startup.blue{	background-color: #2CA6EB;}
.block-button-progress-block-startup.green{	background-color: #0ECFAA;}
.block-button-progress-block-startup.pink{	background-color: #E43A70;	width: 114px;}
.block-button-progress-block-startup p.title-button-progress-block-startup{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 12px;	font-weight: 500;	line-height: 23px;
    padding: 0 0 0 8px;
    position: relative;
}
.block-button-progress-block-startup p.title-button-progress-block-startup i{
    width: 19px;
    height: 19px;
    position: absolute;
    right: 2px;
    top: 2px;
    color: #FFFFFF;
    line-height: 19px;
    text-align: center;
}

.block-swiper-startup{
    width: 940px;
    height: 640px;
    display: table;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    /*background-color: #000000;*/
}
.block-swiper-startup:after{
    /*content: '';*/
    /*position: absolute;*/
    /*background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);*/
    /*width: 100%;*/
    /*bottom: 0;*/
    /*left: 0;*/
    /*height: 120px;*/
    /*z-index: 0;*/
}
.block-swiper-startup .image-gallery{
    width: 940px;
    height: 560px;
}
.block-swiper-startup .image-gallery-thumbnails-wrapper{
    z-index: 10;
    position: absolute !important;
    bottom: -90px;
    /*width: 940px !important;*/
}
.block-swiper-startup .image-gallery-thumbnail.active{
    border: 4px solid transparent;
}
.block-swiper-startup .image-gallery-thumbnail.active:after{
    display: none;
}
.block-swiper-startup .image-gallery-thumbnail{
    width: 120px;
    height: 77px;
    /*display: block;*/
    overflow: hidden;
}
.block-swiper-startup .image-gallery-thumbnail:after{
    display: block;
    content: '';
    position: absolute;
    width: 120px;
    height: 68px;
    left: 0;
    top: 0;

    border-radius: 4px;
    background-color: rgba(0,0,0,0.2);
}
.block-swiper-startup .image-gallery-thumbnail .image-gallery-thumbnail-inner{
    max-width: 120px;
    max-height: 68px;
    overflow: hidden;
    border-radius: 4px;
    display: flex;
    margin: auto;
}

.block-swiper-startup .image-gallery-thumbnail{
    width: 128px;
    border-radius: 4px;
    position: relative;
}

.block-swiper-startup .image-gallery-thumbnail img{
    border-radius: 4px;
}
.block-swiper-startup  .image-gallery-fullscreen-button:hover::before,
.block-swiper-startup .image-gallery-play-button:hover::before,
.block-swiper-startup .image-gallery-left-nav:hover::before,
.block-swiper-startup .image-gallery-right-nav:hover::before{
    color: #FFFFFF;
}
.block-swiper-startup .image-gallery-right-nav{
    padding: 160px 15px;
}
.block-view-swiper-item-startup{
    width: 940px;
    height: 460px;
}
.block-active-img-swiper-item-startup{
    width: 940px;
    height: 460px;

    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: 100%;
}
.block-list-item-swiper-startup{
    width: 940px;
    height: 120px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
    padding: 40px 0 0 0;
}
.block-list-item-swiper-startup .block-item-swiper-startup{
    height: 66px;	width: 120px;	border-radius: 4px;
    background-size: 100% 100%!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
    margin-left: 13px;
}



.shell-popup-expert-marketplace{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 10000;
}
.block-popup-expert-marketplace{
    width: 600px;	border-radius: 8px;	background-color: #FFFFFF;	box-shadow: -1px 5px 14px 0 rgba(0,0,0,0.1);
    padding: 24px 60px;
    top: 160px;
    left: calc(50% - 300px);
    position: absolute;
}
.block-popup-expert-marketplace p.title-popup-expert-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 28px;	font-weight: 500;	line-height: 35px;
    margin: 0 0 40px 0;
}
.block-title-input-invest-popup-expert-marketplace{
    height: 40px;
    width: 100%;
    display: table;
    margin: 0 0 37px 0;
}
.block-title-input-invest-popup-expert-marketplace p.title-invest-popup-expert-marketplace{
    color: #000000;	font-family: "Stratos LC";	font-size: 16px;	line-height: 40px;
    margin: 0;
    float: left;
}
.block-title-input-invest-popup-expert-marketplace .block-new-input{
    width: 110px;
    height: 40px;
    float: left;
    margin: 0 0 0 16px;
}
.block-title-input-invest-popup-expert-marketplace .block-new-input input{
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 20px;	font-weight: 500;
    border-radius: 4px;	background-color: #ECECEC;
    border: 1px solid #ECECEC;
}

.block-input-range-popup-expert-marketplace{
    width: 100%;
    display: table;
}
.block-input-range-popup-expert-marketplace .input-range__track{
    height: 10px;
    background-color: #ECECEC;
    border-radius: 5px;
}
.block-input-range-popup-expert-marketplace .input-range__track--active{
    background-color: #0ECFAA;
    opacity: 0.5;
}
.block-input-range-popup-expert-marketplace .input-range__slider{
    background-color: #0ECFAA;
    border: 3px solid #FFFFFF;
    height: 30px;	width: 30px;
    top: -9px;
    left: -9px;
    box-shadow: 0 4px 19px 0 rgba(0,0,0,0.15);
}
.block-input-range-popup-expert-marketplace .input-range__slider:active{
    transform: scale(1);
}
.block-input-range-popup-expert-marketplace p.title-input-range{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 20px;
    margin: 10px 0 0 0;
}
.block-input-range-popup-expert-marketplace p.title-input-range.min{
    float: left;
}
.block-input-range-popup-expert-marketplace p.title-input-range.max{
    float: right;
}

p.description-popup-expert-marketplace{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;
    margin: 32px 0;
}
.block-button-popup-expert-marketplace{
 text-align: center;
}
.block-button-close-popup{
    position: absolute;
    width: 35px;
    height: 35px;
    top: 26px;
    right: 24px;
    cursor: pointer;
}
.block-button-close-popup i{
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 26px;
}

.image-gallery-image{
    /*height: 560px;*/
    position: relative;
}
.image-gallery-image .video-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.image-gallery-image img{
    max-height: 100%;
}

.image-gallery-slide img {
    width: auto !important;
    max-width: 100% !important;

}



.popup-wrap{}
.popup-wrap .block-left-cart-expert{
    width: 200px;
}
.popup-wrap .block-right-cart-expert{
    width: calc(100% - 220px);
}
.popup-wrap .block-body-team{
    width: 100%;
}
.popup-wrap .block-footer{
    display: none;
}


.block-body-team .swiper-button-prev, .block-body-team .swiper-button-next {
    position: absolute;
    top: 50%;
    width: 29px;
    height: 29px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    /*background-size: 27px 44px;*/
    background-position: center;
    background-repeat: no-repeat;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
    border-radius: 50%;
    background-size: 36%;
}
.label-is-founder {
    height: 24px;
    min-width: 69px;
    border-radius: 2px;
    background-color: rgba(0,0,0,0.5);
    color: #ffffff;
    line-height: 24px;
    position: absolute;
    top: 24px;
    left: 24px;
    text-align: center;
    font-size: 14px;
    padding: 0 5px;
}

.label-is-founder + .label-is-founder{
    margin-top: 30px;
}

.block-list-button-install-app{
    margin: 25px 0 0 0;
    width: 100%;
    height: 40px;
    display: table;
}
.block-button-install-app{
    width: 40px;
    height: 40px;
    float: left;
    cursor: pointer;
    margin: 0 8px 0 0;
}
.block-button-install-app img{
    width: 40px;
    height: 40px;
}

.reg-wrap .block-box-shadow-cart-startup{
    box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.06);
    border-radius: 6px 6px 0px 0px;
    width: 100%;
    height: 175px;
    position: absolute;
    top: 0;
    left: 0;

}
.h2-description {
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
    padding: inherit;
    margin: inherit;
    display: inherit;
}




.block-cart-expert-mobile{
    width: 100%;
}
.block-top-cart-expert-mobile{}
.block-avatar-name-top-cart-expert-mobile{
    width: 100%;
    display: table;
}
.block-left-top-cart-expert-mobile{
    width: 72px;
    height: 72px;
    float: left;
}
.block-left-top-cart-expert-mobile .photo-cart-expert{
    width: 72px;
    height: 72px;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center!important;
}
.block-left-top-cart-expert-mobile .photo-cart-expert img{
    display: none;
}
.block-right-top-cart-expert-mobile{
    width: calc(100% - 84px);
    float: right;
}
.title-top-cart-expert-mobile{
    /*font-family: "Stratos LC";*/
    /*font-size: 22px;*/
    /*line-height: 24px;*/
    /*margin: 0;*/
    /*color: rgba(8, 8, 8, 0.8);*/
    position: relative;
    padding: 0 25px 0 0;
    margin: 0 0 20px 0;
}
.title-top-cart-expert-mobile span.check-box-cart{
    display: block;
    width: 19px;
    height: 19px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
}
.title-top-cart-expert-mobile span.check-box-cart i{
    color: #FFFFFF;
    font-size: 12px;
    display: inline-block;
    vertical-align: top;
    width: 19px;
    height: 19px;
    line-height: 24px;
    text-align: center;
    position: relative;
    top: -1px;
}

.title-top-cart-expert-mobile span.check-box-cart.expert{
    background: #0ECFAA;
}
.title-top-cart-expert-mobile span.check-box-cart.investor{
    background: #2CA6EB;
}
.title-top-cart-expert-mobile span.check-box-cart.startup{
background: #2F3849;
}
.round-prof{
    font-family: Stratos LC;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    padding: 3px 12px;
    border-radius: 50px;
}
.round-prof.expert{
    background: #0ECFAA;
}
.round-prof.investor{
    background: #2CA6EB;
}
.round-prof.startup{
background: #2F3849;
}
p.description-top-cart-expert-mobile{
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    color: rgba(8, 8, 8, 0.8);
    margin: 20px 0 0 0;
    word-wrap: break-spaces;
}

.block-body-cart-expert-mobile{
    margin: 30px -20px 0 -20px;
}
.block-list-button-cart-expert-mobile{
    max-width: 300px;
}
.block-list-button-cart-expert-mobile .block-button-3-0.button-3-0-border{
    margin: 0 0 20px 0;
    height: 40px!important;
    border-color: #2CA6EB;
    width: 100%;
}
.block-list-button-cart-expert-mobile .block-button-3-0.button-3-0-border p{
    line-height: 36px!important;
    height: 36px;
    color: #2CA6EB;
}

.block-list-button-cart-expert-mobile .block-button-3-0.button-3-0-black{  height: 40px!important;}
.block-list-button-cart-expert-mobile .block-button-3-0.button-3-0-black p{
    line-height: 40px!important;
    height: 40px!important;
}

.block-list-button-cart-expert-mobile .block-button-3-0.button-3-0-blue{
    height: 40px!important;
}
.block-list-button-cart-expert-mobile .block-button-3-0.button-3-0-blue p{
    line-height: 40px!important;
    height: 40px!important;
}

.block-get-preliminary-evaluation {
    min-height: 138px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px rgba(0,0,0,0.03);
    border-radius: 6px;
    padding: 26px 48px 33px 33px;
    justify-content: space-between;
}
.block-get-preliminary-evaluation .block-button-wrapper {
    max-width: 254px;
}
.block-get-preliminary-evaluation .block-button-wrapper .block-button-3-0.button-3-0-green {
    width: 254px;
}
.block-get-preliminary-evaluation p.title-block-body-team {
    margin: 0 0 10px 0;
}
.block-get-preliminary-evaluation .get-evaluation-desr {
    font-size: 16px;
    line-height: 21px;
}


.block-list-state-cart-expert-mobile{}
.block-list-info-cart-expert-mobile{}

@media (min-width: 1020px){
    .block-cart-expert-mobile{
        display: none;
    }
}
@media (max-width: 1020px){
    .block-button-icon-title-description{
        max-width: 300px;
    }
    .block-button-social-icon-title-description{
        left: 60px;
    }
    .block-button-icon-title-description:hover .block-button-social-icon-title-description{
        left: 60px;
    }
    .block-button-icon-title-description:hover .block-button-icon{
        left: 0!important;
    }
    .block-button-icon-title-description:hover .block-button-title p.title-button-title{
        left: 0!important;
    }
    .block-button-icon-title-description.social:hover .block-button-description p.description-button-description{
        left: 0!important;

    }
    .block-cart-expert-mobile{
        display: block;

    }
    .block-container-expert{
        width: calc(100% - 40px);
    }
    .block-cart-expert{
        display: none;
    }

    .block-top-cart-achievements{
        padding: 20px;
        position: relative;
    }
    .block-top-cart-achievements .block-title-cart-achievements{
        width: calc(100% - 56px)!important;
        height: auto;
        padding: 0 40px 0 0;
    }
    .block-top-cart-achievements .block-progress-cart-achievements{
        width: 100%;
    }
    .block-top-cart-achievements .block-description-cart-achievements{
        width: 100%;
    }
    .block-top-cart-achievements .block-button-open-close-cart-achievements{
        width: 40px;
        height: 40px;
        position: absolute;
        top: 20px;
        right: 20px;
    }
    .block-body-expert {
        width: calc(100% + 40px);
        margin: 32px -20px 0 -20px;
    }
    .block-body-cart-achievements{
        padding: 20px;
    }
    .block-list-achievements-cart-achievements {
        border-top: 1px solid #ECECEC;
        width: calc(100% + 56px);
        padding: 20px 20px 0 20px;
        margin: 20px -20px 0 -20px;
    }
    p.title-list-achievements-cart-achievements{
        margin: 0;
    }
    .block-left-state-cart-achievements{
        width: 100%;
        margin: 0 0 20px 0;
    }
    .block-center-state-cart-achievements{
        width: 100%;
        margin: 0 0 20px 0;
    }
    .block-right-state-cart-achievements{
        width: 100%;
        margin: 0;
    }
    .block-social-link-expert{
        width: calc(100% + 40px);
        margin: 40px -20px 0 -20px;
        padding: 0;
    }
    .block-comments{
        width: calc(100% + 40px);
        margin: 40px -20px 0 -20px;
    }
    .block-input-button-sent-comments .block-new-input{
        width: 100%;
    }
    .block-left-previous-investor{
        width: 100%;
        /*margin: 0 0 20px 0;*/
    }
    .block-right-previous-investor{
        width: 100%;
        /*margin: 0 0 20px 0;*/
    }
    .block-previous-investor{
        margin: 0 0 20px 0;
    }
    .block-item-achievements-investor{
        width: calc(50% - 10px);
    }
    .block-row-super-description-startup{
        padding: 20px 0;
    }
    .block-left-row-super-description-startup{
        width: 100%;
        margin: 0 0 20px 0;
        border-bottom: 1px solid rgba(151,151,151,0.45);
        padding: 0 0 20px 0;
    }
    .block-right-row-super-description-startup{
        width: 100%;
    }
    /*.block-top-super-description-startup h2.title-top-super-description-startup{*/
    /*    font-size: 28px;*/
    /*    font-weight: 500;*/
    /*    line-height: 35px;*/
    /*}*/
    p.title-block-body-team{
        font-size: 28px;
        font-weight: 500;
        line-height: 35px;
    }
    .block-list-investors p.title-investors{
        font-size: 28px;
        font-weight: 500;
        line-height: 35px;
    }
    .block-body-team{
        width: 100%;
    }

    .popup-wrap{
        max-width: 100%;
    }
    .video-pitch-popup-inner-block.startup{
        width: 100%!important;
        min-width: auto!important;
    }
    .video-pitch-popup-player-block{
        width: 100%!important;
        height: auto!important;
    }
    .video-pitch-popup-inner-block{
        width: 100%!important;
        min-width: auto!important;
    }
    .block-work-experience .block-item-work-experience .block-right-work-experience{
        height: auto;
        min-height: 48px;
    }
    .block-work-experience .block-item-work-experience .block-center-work-experience{
        height: auto;
        min-height: 48px;
    }
    .block-work-experience .block-item-work-experience .block-left-work-experience{
        height: auto;
        min-height: 48px;
    }
}
@media (max-width: 600px){
    .block-item-achievements-investor{
        width: 100%;
    }
    .block-popup-expert-marketplace {
        width: 360px;
        left: calc(50% - 180px);
        top: 50px;
        padding: 20px 30px;
    }
}
@media (max-width: 480px){
    .block-popup-expert-marketplace {
        top: 20px;
    }
}
@media (max-width: 400px){
    .block-get-preliminary-evaluation {
        padding: 26px 15px 33px 15px;
    }
}

.productStatus{
    background: #2F3849;
    color: #fff;
    border-radius: 4px;
    padding: 10px 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    text-align: center;
    vertical-align: middle;
    margin-left: 16px;
    margin-top: -6px;
    display: inline-block;
}


.block-sticky-role-cart{
    position: fixed;
    width: 100%;
    left: 0;
    top: -100px;
    transition: 1s;
    z-index: 999;
}
.block-sticky-role-cart.active{
    top: 8px;
}
.video-pitch-popup-block .block-sticky-role-cart{
    display: none;
}
@media (max-width: 990px){
    .block-sticky-role-cart{
        display: none;
    }
}

.image-gallery-content.fullscreen{
    display: flex;
    align-items: center;
}

.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.block-swiper-startup {
    overflow: unset;
    display: block;
}

.image-gallery-image{
    display: flex;
    align-items: center;
}
.image-gallery-image div{
    margin-left: auto;
    margin-right: auto;
}

.image-gallery-slides,
.image-gallery-image,
.image-gallery-slide,
.image-gallery-swipe,
.image-gallery-slide-wrapper{
    height: 100%;
    width: 100%;
}
.block-body-team-list.no-link{
    cursor: auto;
}
.block-body-team-list.no-link:hover{
    box-shadow: -4px 6px 14px 0 rgba(0, 0, 0, 0.03);
}