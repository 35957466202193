/*fund start*/
.background-white{
    /*background: transparent!important;*/
    background: #FFFFFF;
}
.shell-header-start-page-new{
    width: 100%;
    background: #212800;
    padding: 54px 0 0 0;
}
.block-header-start-page-new{
    padding: 60px 0;
    width: 950px;
    margin: 0 auto;
    overflow: hidden;
}

.block-left-header-start-page-new{
    width: 49%;
    float: left;
}
.block-right-header-start-page-new{
    width: 49%;
    float: right;
    padding: 0 0 0 75px;
}


.title-progress-bar{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.11px;	line-height: 18px;
    margin: 0; font-weight: 300;
}

.block-logo-title-description-new-fund{
    width: 100%;
    height: 60px;
}
.block-logo-title-description-new-fund .block-logo-fund{
    height: 60px;	width: 60px;
    float: left;
}
.block-logo-title-description-new-fund .block-logo-fund img{
    height: 60px;	width: 60px;
    display: block; background: #002BDE;
}
.block-logo-title-description-new-fund .block-title-description-new-fund{
    width: calc(100% - 60px);
    padding: 0 0 0 20px;
    float: left;
}
.block-logo-title-description-new-fund .block-title-description-new-fund p.title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 20px;	letter-spacing: -0.16px;	line-height: 25px;
    margin: 0; font-weight: 400;
}
.block-logo-title-description-new-fund .block-title-description-new-fund p.description{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 37px;	letter-spacing: -0.79px;	line-height: 42px;
    margin: 0; font-weight: 400;
}


.block-description-new-fund{
    width: 100%;
    overflow: hidden;
    margin: 15px 0 0 0;
}
.block-description-new-fund p.description{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 17px;	letter-spacing: -0.18px;	line-height: 21px;
    margin: 0; font-weight: 300; height: 84px;
}


.shell-tag-list-new-fund{
    width: 100%;
    overflow: hidden;
    margin: 15px 0 0 0;
}
.block-tag-list-new-fund{}


.shell-button-list-new-fund{
    width: 100%;
    overflow: hidden;
    margin: 15px 0 0 0;
}
.block-button-list-new-fund{}
.block-button-list-new-fund .block-button-2-0{
    width: 115px!important;
    margin: 0 10px 0 0;
    border-radius: 2px;
}
.block-button-list-new-fund .block-button-2-0 p{
    font-weight: 400!important;
}







.shell-person-info-new-fund{
    width: 100%;
    height: 40px;
    overflow: hidden;
    margin: 15px 0 0 0;
}
.block-person-info-new-fund{
    width: 100%;
    height: 40px;
    overflow: hidden;
}
.block-by-img-person-info-new-fund{
    width: 50px;
    height: 40px;
    float: left;
    position: relative;
}
.block-by-img-person-info-new-fund span{
    color: #D4D6D9;	font-family: "Stratos LC";	font-size: 11px;	letter-spacing: 0.1px;	line-height: 13px;
    margin: 0; font-weight: 300;
    position: absolute;
}
.block-by-img-person-info-new-fund img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    /*background: #002BDE;*/
    float: right;
}
.block-title-description-person-info-new-fund{
    width: calc(100% - 50px);
    height: 40px;
    float: left;
    padding: 0 0 0 10px;
}
.block-title-description-person-info-new-fund p.title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 15px;	font-weight: 600;	letter-spacing: -0.06px;	line-height: 19px;
    margin: 3px 0 0 0; font-weight: 400;
}
.block-title-description-person-info-new-fund p.description{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 13px;	letter-spacing: -0.19px;	line-height: 16px;
    margin: 0; font-weight: 300;
}
.block-create-title-new-fund{
    margin: 15px 0 0 0;
}
.block-create-title-new-fund p.title{
    color: #D4D6D9;	font-family: "Stratos LC";	font-size: 11px;	letter-spacing: 0.1px;	line-height: 13px;
    margin: 0; font-weight: 300;
}


.shell-info-new-fund{
    width: 100%;
    overflow: hidden;
    margin: 10px 0 0 0;
}
.block-info-new-fund{
    width: 100%;
    overflow: hidden;
}
.block-pool-closes{
    width: 28%;
}
.block-Participants{
    width: 28%;    padding: 0 0 0 20px;
}
.block-participation{
    width: 44%;    padding: 0 0 0 20px;
}
.block-info{
    float: left;
}
.block-info p.title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 13px;	letter-spacing: -0.1px;	line-height: 16px;
    font-weight: 300; margin: 0 0 5px 0;
}
.block-info p.description{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 23px;	letter-spacing: -0.18px;	line-height: 29px;
    font-weight: 400; margin: 0;
}

.shell-body-start-new-fund{
    width: 100%;
    overflow: hidden;
}
.block-body-start-new-fund{
    width: 100%;
    /*overflow: hidden;*/
    padding: 0 0 5px 0;
    position: relative;
}
.shell-top-panel-new-fund{
    height: 60px;
    width: 100%;
    overflow: hidden;
    background: #FFFFFF;
}
.shell-top-panel-new-fund.fix {
    padding: 0 20px;
    position: relative;
    top: -40px;
}
.block-top-panel-new-fund{}
.block-left-top-panel-new-fund{
    width: calc(100% - 360px);
    float: left;
    height: 60px;
}
.block-right-top-panel-new-fund{
    width: 360px;
    float: right;
    height: 60px;
}
.block-right-top-panel-new-fund.fix {
    width: 280px;
    float: left;
}
.block-list-filter-new-fund{
    height: 60px;
    width: 100%;
    overflow: hidden;
    padding: 17px 0;
}
.block-list-filter-new-fund .block-button-2-0{
    height: 26px!important;
    margin: 0 10px 0 0;
}
.block-list-filter-new-fund .block-button-2-0 p{
    line-height: 26px!important;
}
.block-item-filter-new-fund{
    float: left;
    height: 22px;
    margin: 19px 70px 19px 0;
}
.block-item-filter-new-fund:last-child{
    margin: 19px 0;
}
.block-item-filter-new-fund a{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.15px;	line-height: 22px;
    margin: 0;
    text-decoration: underline;
    font-weight: 300;
    cursor: pointer;
}
.block-item-filter-new-fund a.active,
.block-item-filter-new-fund a.active span{
    font-weight: 400;
}
.block-item-filter-new-fund a span{
    color: #0028DC;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.15px;	line-height: 22px;
    margin: 0;
    display: inline-block;
    text-decoration: none;
    font-weight: 300;
    margin: 0 0 0 10px;
}

.block-search-input-DAO{
    height: 36px;
    margin: 3px 0 0 15px;
    display: inline-block;
    border-bottom: 1px solid #E0E0E0;
    /*border-radius: 4px;*/
}
.block-search-input-DAO i{
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    float: right;
}
.block-search-input-DAO input{
    width: calc(100% - 36px);
    height: 20px;
    margin: 8px 0;
    padding: 0 15px 0 0;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	font-weight: 300;	line-height: 18px;
    border: 0;
    outline: none;
}

.block-body-new-fund{
    margin: 40px 0 0 0;
}

.padding-20-top{
    padding: 20px 0 0 0!important;
}

.shell-header-start-page-new-startup{
    width: 100%;
    padding: 70px 0 0 0;
    overflow: hidden;
    max-width: 1920px;
    height: 410px;
    margin: 0 auto;
    background: #ffffff;
}
.block-header-start-page-new-startup{
    width: 100%;
}


/*slider*/

.back-dark-pink{
    background: #D634BD!important;
}
.back-pink{
    background: #FB008E!important;
}
.back-red{
    background: #FF4500!important;
}
.back-orange{
    background: #FF772B!important;
}
.back-yellow{
    background: #FFB400!important;
}
.back-green{
    background: #8BE30B!important;
}
.back-emerald{
    background: #06D384!important;
}
.back-turquoise{
    background: #02CCE4!important;
}
.back-white{
    background: #fdfdfd!important;
}
.shell-item-slider{
    width: 1155px;
    height: 340px;
    padding: 0 0 0 15px;
    outline: none;
}
.block-item-slider{
    width: 1140px;
    height: 340px;
    cursor: pointer;
    border-radius: 4px;
    padding: 30px 80px;
    box-sizing: border-box;
    position: relative;
    box-shadow: inset 0px 0px 0px 10000px rgba(0,0,0,0.3);
}

.startup-slider-arrow{
    position: absolute;
    width: 100px;
    height: 340px;
    top: 0;
    background: rgba(0, 0, 0, 0);
    z-index: 9;
    transition: .3s;
    cursor: pointer;
}
@media (min-width: 1300px) {
    .startup-arrow-next{
        width: calc((100% - 1185px) / 2);
    }
}
@media (max-width: 1299px) {
    .startup-arrow-next{
        width: 100px;
    }
}


.startup-arrow-next i{
    float: right!important;
}

.startup-slider-arrow:hover{
    background: rgba(0, 0, 0, 0.3);
}

.startup-arrow-next{
    right: 0;
}
.startup-arrow-prev{
    left: 0;
    width: calc((100% - 1155px) / 2);
}
.startup-slider-arrow i{
    width: 100px;
    height: 340px;
    line-height: 340px;
    font-size: 75px;
    text-align: center;
    transition: .3s;
    /*opacity: 0;*/
    opacity: 1;
    color: #ffffff;
}
.startup-slider-arrow:hover i{
    opacity: 1;
}

.shell-item-startup{
    position: absolute;
    width: 100%;
    height: 340px;
    overflow: hidden;
    top: 0;
    left: 0;
}
.block-item-startup{}



.shell-item-startup-slider{}
.block-item-startup-slider{
    overflow: hidden;
}
.block-left-item-startup-slider{
    width: 50%;
    float: left;
}
.block-right-item-startup-slider{
    width: 50%;
    padding: 0 0 0 40px;
    float: left;
    box-sizing: border-box;
    position: relative;
    height: 215px;
}

.block-logo-title-startup-slider{
    width: 100%;
    height: 60px;
}
.block-left-logo{
    height: 60px;	width: 60px;
    float: left;
}
.block-left-logo img{
    height: 60px;	width: 60px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.5);
    display: block;
    /*background: #002BDE;*/
}
.block-right-title{
    float: left;
    width: calc(100% - 60px);
    padding: 0 0 0 20px;
    box-sizing: border-box;
}
.block-right-title p.title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 20px;	letter-spacing: -0.16px;	line-height: 20px;
    margin: 0; font-weight: 400;
}
.block-right-title p.description{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 37px;	letter-spacing: -0.79px;	line-height: 52px;
    margin: 0; font-weight: 400;
}

.block-description-startup-slider{
    width: 100%;
    overflow: hidden;
margin: 20px 0 0 0;
}
.block-description-startup-slider p.description{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 17px;	letter-spacing: -0.18px;	line-height: 21px;
    font-weight: 300; margin: 0;
}

.block-tag-list-startup-slider{
    width: 100%;
    overflow: hidden;
    margin: 20px 0 0 0;
}
.block-tag-list-startup-slider .block-tag-2-0 a p{
    color: #FFFFFF!important;
}

.block-list-button-startup-slider{
    width: 100%;
    overflow: hidden;
    margin: 20px 0 0 0;
}
.block-button-blue-startup-slider{
    height: 32px;
    vertical-align: top;
    height: 32px;
    vertical-align: top;
    display: inline-block;
    padding: 0 10px;
    background-color: #0028DC;
    border-radius: 2px;
    margin: 0 10px 0 0;
    transition: .3s;
}
.block-button-blue-startup-slider p{
    color: #F5F6F6;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: 0.15px;	line-height: 32px;
    margin: 0; font-weight: 300;
    transition: .3s;
}
.block-button-blue-startup-slider p span{
    display: inline-block;
    margin: 0 0 0 30px;

}
.block-button-blue-startup-slider p span.color-green{
    margin: 0 8px 0 0;
    color: #00F082;

}
.block-button-blue-startup-slider:hover{
    background-color: #00F082;
}
.block-button-blue-startup-slider:hover p{
    color: #000000;
}
.block-button-blue-startup-slider:hover p span.color-green{
    color: #F5F6F6;
}

.block-list-button-startup-slider .block-button-2-0{
    border-radius: 2px;
    margin: 0 10px 0 0 ;
}

.block-info-list-startup-slider{
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 40px;
}
.block-info-item-startup-slider{
    width: 100%;
    overflow: hidden;
}
.block-info-item-startup-slider .title-info{
    float: left;
    width: 120px;
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 14px;
    letter-spacing: -0.15px;
    line-height: 24px;
    font-weight: 300;
}
.block-info-item-startup-slider .description-info{
    float: left;
    width: calc(100% - 130px);
    color: #D4D6D9;
    font-family: "Stratos LC";
    font-size: 14px;
    letter-spacing: -0.15px;
    line-height: 24px;
    margin: 0;
    font-weight: 300;
}

.block-right-item-startup-slider .shell-progress-bar-multi-fund{}
.block-right-item-startup-slider .shell-progress-bar-multi-fund p.count-raised-multi-fund span.count{
    color: #00F082!important;
}
.block-right-item-startup-slider .shell-progress-bar-multi-fund p.count-raised-multi-fund span.title{
    color: #FFFFFF!important;
}

.block-right-item-startup-slider .shell-progress-bar-multi-fund p.count-contrebuted-multi-fund span.count{
    color: #00F082!important;
}
.block-right-item-startup-slider .shell-progress-bar-multi-fund p.count-contrebuted-multi-fund span.title{
    color: #FFFFFF!important;
}
.block-right-item-startup-slider .shell-progress-bar-multi-fund .block-soft-cap-multi-fund p{ color: #FFFFFF!important;}



.shell-cart-list-start-page{
    width: 100%;
    margin: 0;
}
.block-cart-list-start-page{}
.block-top-cart-list-start-page{
    overflow: hidden;

}
.block-left-top-cart-list-start-page{
    float: left;
}
.block-left-top-cart-list-start-page p.big-title-start-page{
    color: #000000;	font-family: "Stratos LC";	font-size: 34px;	letter-spacing: -0.73px;	line-height: 43px;
    margin: 0; font-weight: 300;
}
.block-left-top-cart-list-start-page p.big-title-start-page a{
    color: #0028DC;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: 0.13px;	line-height: 18px;
    margin: 0 0 0 10px;font-weight: 300;
}
.block-left-top-cart-list-start-page p.small-title-start-page{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 20px;	letter-spacing: -0.25px;	line-height: 25px;
    margin: 0; font-weight: 300;
}

.block-right-top-cart-list-start-page{
    float: right;
}
.block-right-top-cart-list-start-page .block-button-2-0{
    border-radius: 2px;
}

.block-body-cart-list-start-page{
    margin: 25px 0 0 0;
    /*overflow: hidden;*/
    padding: 0 5px 5px 5px;
    display: table;
    width: 100%;
}
@media (min-width: 1200px) {
    .block-item-cart-start-page{
        width: calc((100% - (25px * 3)) / 4);
        margin: 0 25px 25px 0;
    }
    .block-item-cart-start-page:nth-child(4n){
        margin: 0;
    }
}
@media (max-width: 1199px) {
    .block-item-cart-start-page{
        width: calc((100% - (25px * 2)) / 3);
        margin: 0 25px 25px 0;
    }
    .block-item-cart-start-page:nth-child(4n){
        margin: 0 25px 25px 0;
    }
    .block-item-cart-start-page:nth-child(3n){
        margin: 0;
    }
}
@media (max-width: 800px) {
    .block-item-cart-start-page{
        width: calc((100% - (25px * 1)) / 2);
        margin: 0 25px 25px 0;
    }
    .block-item-cart-start-page:nth-child(4n){
        margin: 0 25px 25px 0;
    }
    .block-item-cart-start-page:nth-child(3n){
        margin: 0 25px 25px 0;
    }
    .block-item-cart-start-page:nth-child(2n){
        margin: 0;
    }
}
@media (max-width: 600px) {
    .block-item-cart-start-page{
        width: calc((100% - (25px * 0)) / 1);
        margin: 0 25px 25px 0;
    }
    .block-item-cart-start-page:nth-child(4n){
        margin: 0 25px 25px 0;
    }
    .block-item-cart-start-page:nth-child(3n){
        margin: 0 25px 25px 0;
    }
    .block-item-cart-start-page:nth-child(2n){
        margin: 0 25px 25px 0;
    }
    .block-item-cart-start-page:nth-child(1n){
        margin: 0 0 25px 0;
    }
}
.block-item-cart-start-page{
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
    float: left;
    cursor: pointer;
    transition: .1s;
    position: relative;
    top: 0;
    height: 397px;
}
.block-item-cart-start-page:hover{
    box-shadow: 0px 0px 20px 0 rgba(0,0,0,0.4);
    top: -7px;
}
.block-top-cart-start-page{
    height: 180px;
    width: 100%;
    position: relative;
}
.block-top-cart-start-page:after{
    height: 50px;
    width: 100%;
    content: '';
    border-radius: 0 0 3px 3px;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    transform: scaleY(-1);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}
.block-top-cart-start-page img{
    height: 160px;	min-width: 100%;
    display: block; background: #02CCE4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.shell-img-top-cart-start-page{
    height: 160px;	min-width: 100%;
    display: block; background: #02CCE4;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: cover!important;
}
.block-top-cart-start-page p.title-left{
    position: relative;
    float: left;
    z-index: 3;
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.2px;	line-height: 20px;
    margin: 0; font-weight: 400;padding: 12px 0 0 15px;
}
.block-top-cart-start-page p.title-left.round{
    font-weight: 300;
}
.block-top-cart-start-page p.title-left.round span{
    font-weight: 400;
}
.block-top-cart-start-page p.title-right{
    position: relative;
    float: right;
    z-index: 3;
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.2px;	line-height: 20px;
    margin: 0; font-weight: 300;padding: 12px 15px 0 0;
}
.block-top-cart-start-page p.title-right i{
    font-size: 12px;
    color: #FF6020;
    margin: 0 3px 0 0;
    line-height: 20px;
}
.block-top-cart-start-page p.title-right i.color-green{
    color: #00F082;
}

.block-line-logo{
    width: 100%;
    height: 24px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
}
.block-line-logo.back-color-blue{
    background-color: #FFFFFF;
}
.block-line-logo.back-color-gray{
    background-color: #4A4A4A;
}
.block-line-logo .shell-img-line-logo{
    height: 60px!important;	width: 60px!important;
    border-radius: 2px;	background-color: #FFFFFF;	box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
    display: block; background-color: #1b1b1b; position: absolute; top: -25px; left: 18px;
    overflow: hidden;
    background-size: cover!important;
    background-position: center!important;
}
.block-line-logo .shell-img-line-logo img{
    height: 60px!important;
    min-height: 60px!important;	min-width: 60px!important;
    border-radius: 2px;	background-color: #FFFFFF;	box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
    display: block; background-color: #1b1b1b;
}
.block-line-logo p.title-line{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 24px;
    margin: 0; font-weight: 400; padding: 0 0 0 86px;
}
.block-line-logo p.title-line span.font-size-12{
    font-weight: 300!important;
    font-size: 10px;
}
.block-line-logo p.title-line span{
    font-weight: 400;
}

.block-body-cart-start-page{
    padding: 20px 20px 50px 20px;
    position: relative;
    background-color: #ffffff;
    height: 217px;
}
.block-body-cart-start-page p.title-cart-start-page{
    color: #000000;	font-family: "Stratos LC";	font-size: 18px;	letter-spacing: -0.39px;	line-height: 24px;
    font-weight: 400; margin: 0;
}
.block-body-cart-start-page p.description-cart-start-page{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 13px;	letter-spacing: -0.16px;	line-height: 17px;
    margin: 5px 0 0 0; font-weight: 300; max-height: 51px; overflow: hidden;
}
.block-tag-list-cart-start-page{
    margin: 8px 0 0 0;
    height: 60px;
    overflow: hidden;
}
.block-tag-list-cart-start-page a p{
    color: #4A4A4A!important;
}

.block-progress-bar-cart-start-page{
    width: 100%;
    overflow: hidden;
    height: 36px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.block-progress-bar-cart-start-page p.title-progress{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.18px;	line-height: 17px;
    font-weight: 300; margin: 0; padding: 8px 20px;
}
.block-progress-bar-cart-start-page p.title-progress span{
    font-weight: 400; margin: 0 10px 0 0;
}
.block-progress-bar-cart-start-page .block-progress-bar{
    width: 100%;
    margin: 0;
    background-color: #EBEBEB;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
}
.block-progress-bar-cart-start-page .block-progress-bar .block-progress{
    background-color: #00F082;
    height: 3px;
}


.shell-blur{
    position: fixed;
    width: 100%;
    height: 70px;
    top: 200px;
    left: 0;
    z-index: 100;
    overflow: hidden;
}
.block-blur{
    /*position: absolute;*/
    /*width: calc(100% + 50px);*/
    /*height: calc(100% + 50px);*/
    /*background-color: rgba(255,255,255,0.8);*/
    /*margin: -25px;*/
    /*filter: blur(10px);*/

width: 100%;
    height: 70px;
    filter: url(filter.svg#blur); /* FF, IE10 & Opera */
    -webkit-filter: blur(2px); /* Chrome */
}






/*header anim*/


.shell-header-start-page-dao{
    width: 100%;
    /*padding: 54px 0 0 0;*/
    overflow: hidden;
}
.block-header-start-page-dao{
    width: 100%;
    height: 740px;
    position: relative;
}
.block-background-video-start-page-dao{
    position: absolute;
    width: 100%;
    height: 570px;
    top: 0;
    left: 0;
    /*background-color: #90CAF9;*/
    z-index: 1;
    overflow: hidden;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: center!important;
}
.block-background-video-start-page-dao video{
    /*height: 1100px;*/
    width: 100%;
    position: absolute;
    left: 0;
    /*bottom: -20px;*/
}
.block-background-video-start-page-dao video source{
    width: 100%;
}
.block-body-header-start-page-dao{
width: 100%;
    position: relative;
    padding: 60px 0;
    z-index: 1;
    display: table;
    height: 440px;
}
.block-left-body-header-start-page-dao{
    width: 50%;
    float: left;
    padding: 0 90px 0 0;
    box-sizing: border-box;
}
.block-right-body-header-start-page-dao{
    width: 50%;
    float: left;
}

.block-left-body-header-start-page-dao .title-mini{
    color: #000000;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.24px;	line-height: 18px;
    margin: 0; font-weight: 400;
}
.block-left-body-header-start-page-dao .title-big{
    color: #2AA2E6;	font-family: "Stratos LC";	font-size: 46px;	font-weight: 400;	letter-spacing: -0.58px;	line-height: 58px;
    margin: 0 20px  0 0; transition: .3s;
}

.block-title-button-home-page-top{
    margin: 0 0 20px 0;
    display: table;
    width: 100%;
}

.block-title-button-home-page-top p.title-big{
    float: left;

}
.block-title-button-home-page-top .block-button-2-0{
    float: left;
    position: relative;
    top: 15px;
}

.block-left-body-header-start-page-dao .description{
    color: #000000;	font-family: "Stratos LC";	font-size: 19px;	letter-spacing: -0.24px;	line-height: 24px;
    margin: 0 0 0px 0; font-weight: 300;transition: .3s;
    height: 72px;
}
/*@media (min-width: 1200px) {*/
    /*.block-left-body-header-start-page-dao .description{*/
        /*height: 62px;*/
    /*}*/
/*}*/
/*@media (max-width: 1199px) {*/
    /*.block-left-body-header-start-page-dao .description{*/
        /*height: 120px;*/
    /*}*/
/*}*/
.block-list-link{
    margin: 20px 0 20px 0;
}
.block-button-red-new .block-button-2-0{
    border-radius: 3px;
    width: 270px;
}

.block-left-body-header-start-page-dao .block-list-link a{
    /*color: #0028DC;	font-family: "Stratos LC";	font-size: 20px;	letter-spacing: -0.25px;*/
    /*margin: 0 20px 0 0; text-decoration: underline; cursor: pointer; transition: .3s;*/
}
.block-list-link a.active{
    /*color: #000000; text-decoration: none;*/
}


.block-left-body-header-start-page-dao .block-list-link a{
    line-height: 41px;
    height: 41px;
    width: 120px;
    display: inline-block;
    /*border: 1px solid #000000;*/
    margin: 0;
    text-align: center;
    transition: .3s;
    color: #2AA2E6;	font-family: "Stratos LC";	font-size: 18px;	letter-spacing: 0.12px;	text-decoration: none;
    padding: 0 15px;
    font-weight: 300;
    background-color: #FFFFFF;
}
.shell-list-link{
    box-shadow: 0 1px 7px 0 rgba(0,0,0,0.24);
    border-radius: 50px;
    display: inline-block;
    width: 360px;
}
.block-left-body-header-start-page-dao .block-list-link a:hover{

}
.block-left-body-header-start-page-dao .block-list-link a:hover{
    background-color: #CFCFCF;
    /*border: 1px solid #CFCFCF;*/
}
.block-left-body-header-start-page-dao .block-list-link a.circle-3{
    /*border-radius: 0 2px 2px 0;*/
    /*border-left: 1px solid rgba(0,0,0,0);*/
    border-radius: 0 20px 20px 0;
}
.block-left-body-header-start-page-dao .block-list-link a.circle-2{
    /*border-right: 1px solid #000000;*/
}
.block-left-body-header-start-page-dao .block-list-link a.circle-1{
    border-right: 0;
    border-radius: 20px 0 0 20px;
}
.block-left-body-header-start-page-dao .block-list-link a.circle-1.active:hover{
    background-color: #2AA2E6;
    color: #FFFFFF;
    /*border: 1px solid #0028DC;*/
}
.block-left-body-header-start-page-dao .block-list-link a.circle-1.active{
    background-color: #2AA2E6;
    color: #FFFFFF;
    /*border: 1px solid #0028DC;*/
}
.block-left-body-header-start-page-dao .block-list-link a.circle-2.active:hover{
    background-color: #1BC8A7;
    /*border: 1px solid #000000;*/
}
.block-left-body-header-start-page-dao .block-list-link a.circle-2.active{
    background-color: #1BC8A7;
    color: #FFFFFF;
}
.block-left-body-header-start-page-dao .block-list-link a.circle-3.active:hover{
    background-color: #4A4A4A;
    color: #FFFFFF;
    /*border: 1px solid #000000;*/
}
.block-left-body-header-start-page-dao .block-list-link a.circle-3.active{
    background-color: #4A4A4A;
    color: #FFFFFF;
    /*border: 1px solid #000000;*/
}

.block-button-start-page{}
.block-button-start-page .block-button-2-0{
    border-radius: 2px;
    border: 0;

}
.block-button-start-page .block-button-2-0 p{
    font-weight: 400!important;
    text-decoration: underline;
}


.block-list-circle{
    width: 100%;
    padding: 36px 0 0 36px;
    position: relative;
}
.block-item-circle{
    width: 94px;
    height: 94px;
    border-radius: 50%;
    position: absolute;
    transition: .6s;
    cursor: pointer;
    z-index: 5;
}
.block-item-circle:after{
    content: '';
    width: 166px;
    height: 166px;
    opacity: 0.20;
    position: absolute;
    top: -36px;
    left: -36px;
    border-radius: 50%;
    z-index: -1;
    transition: .3s;
}
.block-item-circle:hover:after{
    width: 146px!important;
    height: 146px!important;
    top: -26px!important;
    left: -26px!important;
}
.block-item-circle.puls:after{
    width: 156px;
    height: 156px;
    top: -31px;
    left: -31px;
}
.block-item-circle.circle-1{
    background-color: #2AA2E6;
}
.block-item-circle.circle-1:after{
    background-color: #2AA2E6;
}
.block-item-circle.circle-2{
    background-color: #1BC8A7;
}
.block-item-circle.circle-2:after{
    background-color: #1BC8A7;
    opacity: 0.35;
}
.block-item-circle.circle-3{
    background-color: #4A4A4A;

}
.block-item-circle.circle-3:after{
    background-color: #4A4A4A;
}
.block-item-circle.circle-top{
    top: 35px;
    left: 35px;
    transition: .6s;
}
.block-item-circle.circle-middle{
    top: 120px;
    left: 140px;
    transition: .6s;
}
.block-item-circle.circle-bottom{
    top: 205px;
    left: 245px;
    transition: .6s;
}
.block-item-circle p.title{
    width: 100%;
    line-height: 96px;
    text-align: center;
    font-family: "Stratos LC";	font-size: 16px;	letter-spacing: 0.15px;
    margin: 0; font-weight: 300;
    border-radius: 50%;
}
.block-item-circle.circle-1 p.title{
    color: #FFFFFF;
    background: #2AA2E6;
}
.block-item-circle.circle-2 p.title{
    color: #FFFFFF;
    background: #1BC8A7;
}
.block-item-circle.circle-3 p.title{
    color: #FFFFFF;
    background: #4A4A4A;
}
.block-item-circle span{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 19px;	letter-spacing: -0.24px;	line-height: 24px;
    position: absolute; margin: 0; font-weight: 300;
    top: 20px;
    width: 300px;
    left: 110px;
    padding: 0 0 0 60px;
    transition: .3s;
    overflow: hidden;
    white-space: nowrap;
    z-index: -1;
}
.block-item-circle span:after{
    content: '';
    width: 50px;
    height: 1px;
    background-color: #000000;
    position: absolute;
    left: 0px;
    top: 12px;
    transition: .3s;
}
.block-item-circle.active span{
    width: 0;
    left: 0;
}
.block-item-circle.active span:after{
    width: 0;
}




.advising {
    font-family: 'Stratos LC';
}
.advising .back {
    padding: 15px;
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    /* IE 9 */
    -webkit-transform: rotateY(180deg);
    /* Chrome, Safari, Opera */
}

.advising .back p {
    clear: both;
    text-align: center;
    font-size: 12px;
    line-height: 1.3;
    margin-top: 5px;
}

.advising .block-card {
    width: 100%;
    display: inline-block;
    text-align: center;
}

.advising .card-container {
    -webkit-perspective: 800px;
    -o-perspective: 800px;
    perspective: 800px;
    display: inline-block;
    margin: 0 15px 0 0;
}
.advising .card-container:last-child{
    margin: 0;
}

.advising .card-container:hover .card, .advising .card-container.hover .card {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.advising .card-container:hover .card .front, .advising .card-container.hover .card .front {
    z-index: 1;
}

.advising .card-container:hover .card .back, .advising .card-container.hover .card .back {
    z-index: 2;
}

.advising .card-container.static:hover .card, .advising .card-container.static.hover .card {
    -webkit-transform: none;
    transform: none;
}

.advising .card {
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
}

.advising .front, .advising .back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.advising .front {
    display: inline-block;
    position: relative;
    width: calc((100% / 4) - (15px * 3));
    height: 330px;
    overflow: hidden;
    /*background: #fff;*/
    padding: 15px;
    z-index: 2;
}

.advising .front h3 {
    position: absolute;
    /*font-size: 2.3rem;*/
    bottom: 40px;
    color: #0028DC;
    margin-bottom: 20px;
    font-weight: normal;
}

.advising .front p {
    position: absolute;
    bottom: 20px;
    text-align: left;
}

.advising .front .flip {
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.advising .back {
    background-color: #000000;
    width: calc((100% / 4) - (15px * 3));
    height: 330px;
    color: #ffffff;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    z-index: 1;
}

.advising .back .flip {
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.advising .back h3, .advising .back p, .advising .back i {
    text-align: left;
}

.advising .back h3 {
    position: absolute;
    /*font-size: 2.3rem;*/
    bottom: 40px;
    margin-bottom: 20px;
    font-weight: normal;
}

.advising .back .from {
    position: absolute;
    bottom: 20px;
}

.advising i {
    margin-top: 10px;
    /*font-size: 1.8rem;*/
    margin-bottom: 10px;
    display: block;
    color: #fff;
}

.advising .card-container, .advising .front, .advising .back {
    width: 271px;
    height: 350px;
}

.advising .avatar {
    width: 216px;
    height: 216px;
    display: block;
    margin: auto;
    /*background-color: #afff62;*/
    float: none;
    clear: both;
    border-radius: 50%;
    margin-bottom: 0;
    cursor: auto;
}


.block-banner{
    width: 100%;
    height: 50px;
    background-color: #00F082;
}
.block-banner p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 24px;	letter-spacing: -0.51px;	line-height: 30px;
 font-weight: 400;margin: 10px 0; float: left;
}
.block-banner .block-button-2-0{
    margin: 9px 0;
    float: right;
    border-radius: 2px;
}

.block-new-radius-banner{
    width: 100%;
    padding: 50px 70px;
    background-color: #FFFFFF;
    border-radius: 25px;
    position: absolute;
    z-index: 1;
    box-shadow: 0 4px 7px 0 rgba(0,0,0,0.16);
    transition: .3s;
    bottom: -150px;
}
.block-new-radius-banner:hover{
    box-shadow: 0 10px 45px 0 rgba(0,0,0,0.10);
    bottom: -130px;
}
.block-new-radius-banner p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 60px;	letter-spacing: -0.36px;	line-height: 28px;
    margin: 20px 0 30px 0;
}
.block-new-radius-banner p.description{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 24px;	letter-spacing: -0.75px;	line-height: 31px;
    margin: 0; font-weight: 300;
    width: 740px;
}
.shell-circle-button-new-radius-banner{
    width: 112px;
    height: 112px;
    border-radius: 50%;
    position: absolute;
    right: 70px;
    top: calc(50% - (112px / 2) - 20px);
    background: rgba(42, 162, 230, 0.5);
    cursor: pointer;
}
.shell-circle-button-new-radius-banner p.title{
    color: #000000;	font-family: "Stratos LC";	font-size: 18px;	letter-spacing: -0.1px;	line-height: 23px;	text-align: center;
    position: absolute; margin: 0; font-weight: 300;
    bottom: -40px;
    left: 10px;
}
.block-circle-button-new-radius-banner{
    width: 68px;
    height: 68px;
    background: #2AA2E6;
    border-radius: 50%;
    margin: 22px;
    position: relative;
}
.block-circle-button-new-radius-banner:after{
    content: '';
    position: absolute;
    background: #FFFFFF;
    width: 15px;
    height: 2px;
    border-radius: 2px 0 0 2px;
    transform: rotate(55deg);
    top: 27px;
    left: 30px;
}
.block-circle-button-new-radius-banner:before{
    content: '';
    position: absolute;
    background: #FFFFFF;
    width: 15px;
    height: 2px;
    border-radius: 2px 0 0 2px;
    transform: rotate(-55deg);
    top: 38px;
    left: 30px;
}


.block-new-banner-radius-green{
    width: 100%;
    border-radius: 25px;
    box-shadow: 0 4px 7px 0 rgba(0,0,0,0.16);
    background-color: #FFFFFF;
    padding: 50px 50px 50px 50px;
    display: table;
    position: relative;
    z-index: 5;
    transition: .3s;
    top: 32px;
}
.block-new-banner-radius-green:hover{
    box-shadow: 0 10px 45px 0 rgba(0,0,0,0.1);
    top: 22px;
}
.block-left-new-banner-radius-green{
    width: 260px;
    float: left;
}
.block-left-new-banner-radius-green img{
    width: 225px;
}
.block-circle-coin{
    position: relative;
    width: 130px;
    height: 85px;
}
.block-circle-coin:after{
    content: '';
    position: absolute;
    height: 85px;
    width: 85px;
    border: 1px solid #1BC8A7;	background-color: #FFFFFF;
    left: 0;top: 0;
    border-radius: 50%;
}
.block-circle-coin:before{
    content: '';
    position: absolute;
    height: 85px;
    width: 85px;
    border: 1px solid #1BC8A7;	background-color: #FFFFFF;
    left: 42px;top: 0;
    border-radius: 50%;
}
.block-center-new-banner-radius-green{
    width: calc(100% - 460px);
    float: left;
    margin: 10px 0;
}
.block-center-new-banner-radius-green p.title{
    color: #4a4a4a;	font-family: "Stratos LC";	font-size: 40px;	letter-spacing: -0.3px;
    font-weight: 300; margin: 0;
}
.block-center-new-banner-radius-green p.description{
    color: #4a4a4a;	font-family: "Stratos LC";	font-size: 24px;	letter-spacing: -0.75px;
    font-weight: 300; margin: 0;
}
.block-right-new-banner-radius-green{
    width: 190px;
    float: right;
    position: relative;
    min-height: 1px;
}
.block-right-new-banner-radius-green .block-button-2-0{
    float: right;
    margin: 25px 0 0 0;
}
.block-right-new-banner-radius-green .block-button-2-0:hover{
    border: 1px solid #4a4a4a;
}
.block-right-new-banner-radius-green .block-button-2-0:hover p{
    color: #4a4a4a;
}

.shell-button-circle-blue{
    width: 112px;
    height: 112px;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    background: rgba(42, 162, 230, 0.5);
    cursor: pointer;
    right: 0;
}
.block-button-circle-blue p.title{
    color: #000000;	font-family: "Stratos LC";	font-size: 18px;	letter-spacing: -0.1px;	line-height: 23px;	text-align: center;
    position: absolute; margin: 0; font-weight: 300;
    bottom: -40px;
    left: 10px;
}
.block-button-circle-blue{
    width: 68px;
    height: 68px;
    background: #2AA2E6;
    border-radius: 50%;
    margin: 22px;
    position: relative;
}
.block-button-circle-blue:after{
    content: '';
    position: absolute;
    background: #FFFFFF;
    width: 15px;
    height: 2px;
    border-radius: 2px 0 0 2px;
    transform: rotate(55deg);
    top: 27px;
    left: 30px;
}
.block-button-circle-blue:before{
    content: '';
    position: absolute;
    background: #FFFFFF;
    width: 15px;
    height: 2px;
    border-radius: 2px 0 0 2px;
    transform: rotate(-55deg);
    top: 38px;
    left: 30px;
}
.title-big-list-page{
    color: #4a4a4a;	font-family: "Stratos LC";	font-size: 34px;	letter-spacing: -0.24px;	line-height: 40px;
    font-weight: 400; margin: 0; float: left; text-align: left!important;
}



.block-coming-soon-home-page{
    width: 100%;
    display: block;
    padding: 0 0 60px 0;
    box-sizing: content-box;
    position: relative;
    background: #FBFBFB;
}
.block-coming-soon-home-page p.title-coming-soon-home-page{
    line-height: 200px;
    color: rgba(8,8,8,0.6);	font-family: "Stratos LC";	font-size: 38px;
    margin: 0;
    font-weight: 500;
    text-align: center;
    position: sticky;
    top: 100px;
}
.block-coming-soon-home-page img{
    margin: 80px auto;
    display: block;
    opacity: .7;
}
