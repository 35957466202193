
.shell-new-top-menu-rocket-dao {
    height: calc(100vh + 500px);
    background: #4A4A4A;
}

.block-new-top-menu-rocket-dao {
    padding: 0 38px 0 0;
    background-color: #FDFDFD;
    height: 54px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 6;
    /*border-bottom: 1px solid #BBBBBB;*/
}

.sticky-meta {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 300;
    width: 100%;
    background-color: #FDFDFD;
}

.sticky-meta.active {
    top: -25px;
}

.sticky-meta.active.remove-active {
    top: 0px;
}

.sticky-meta.off-sticky {
    top: -80px;
    position: relative;
}

.block-blur-new-top-menu-rocket-dao {
    position: absolute;
    width: 100%;
    height: 54px;
    top: 0;
    left: 0;
    filter: blur(5px);
}

.block-logo-new-top-menu-rocket-dao {
    height: 54px;
    width: auto;
    float: left;
    z-index: 3;
    position: relative;
}

.block-search-header-input .profile-edit-popup-block {
    z-index: -1;
    background-color: transparent;
    display: none;
}

.block-search-header-input {
    width: 340px;
    float: right;
    /*display: none;*/
    height: 40px;
    margin-top: 7px;
}
.block-search-header-input .block-new-input input {
    height: 40px;
    padding: 0 55px 0 30px;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 5px 12px rgba(0,0,0,0.1);
    border-radius: 20px;
    transition: none;
    font-size: 16px;
    color: rgba(8,8,8,0.8);
}
.block-search-header-input .block-new-input input::placeholder {
    font-size: 16px;
    color: rgba(8,8,8,0.8)!important;
    opacity: 1;
}
.block-search-header-input .block-new-input input:focus::placeholder  {
    color: rgba(8,8,8,0.2)!important;
}
.block-search-header-input.changed-shadow-box .block-new-input input {
    letter-spacing: -0.505952px;
    color: rgba(0,0,0,0.8);
    font-weight: 500;
}
.block-search-header-input.changed-shadow-box .block-new-input input {
    box-shadow: inset 0px -4px 20px rgba(0,0,0,0.05);
}
.block-search-header-input .block-new-input input:focus {
    border-radius: 20px 20px 0 0;
}
.block-search-header-input .block-new-input {
    line-height: 40px;
    height: 40px;
    width: 100%;
}
.block-search-header-input .input-close-icon {
    position: absolute;
    right: 14px;
    top: 20px;
    width: 20px;
    height: 14px;
    background-image: url("../../header/Search/img/close_icon.svg");
    background-size: unset;
    background-position: 50%;
    background-repeat: no-repeat;
    cursor: pointer;
}
.block-search-header-input .search-header-open-list {
    width: calc(100% - 0.5px);
    background-color: #FFFFFF;
    border-left: 1px solid #D0D0D0;
    border-right: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    border-radius: 0 0 20px 20px;
    box-shadow: -1px 5px 14px rgba(0,0,0,0.1);
    display: none;
    margin-top: -20px!important;
    padding-top: 14px;
    height: auto!important;
}

.search-recent-list {
    padding: 22px 10px 12px 22px;
}
.search-points-list {
    padding: 10px 0;
}
.block-search-header-input .search-among-button {
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    border-radius: 4px;
    height: 38px;
    display: inline-block;
    margin: 0 10px 10px 0;
    cursor: pointer;
}
.block-search-header-input .search-among-button.active {
    background-color: #2f3849;
    color: #FFFFFF;
    border: 1px solid #2f3849;;
}
.block-search-header-input .search-among-text {
    font-size: 14px;
    line-height: 38px;
    font-weight: 500;
    color: rgba(8,8,8,0.8);
}
.block-search-header-input .resent-search-title {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.505952px;
    color: #404040;
}
.block-search-header-input .search-all-padding {padding: 0 22px;}
.block-search-header-input .search-startup-padding {padding: 0 25px;}
.block-search-header-input .search-investor-padding {padding: 0 23px;}
.block-search-header-input .search-expert-padding {padding: 0 22px;}
.block-search-header-input .search-accelerator-padding {padding: 0 51px;}

.searched-profile-to-list {
    height: 60px;
    background-color: rgba(255,255,255,0.1);
    cursor: pointer;
    transition: .3s;
    padding: 4px 11px;
}
.searched-profile-to-list:hover {
    background-color: rgba(0,0,0,0.1);
}
.searched-profile-avatar {
    max-width: 52px;
    height: 52px;
    border: 2px solid #FFFFFF;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 12px;
}
.searched-profile-role-text {
    margin-top: 9px;
    font-size: 12px;
    line-height: 15px;
    color: rgba(0,0,0,0.6);
}
.searched-profile-name-text {
    font-size: 16px;
    line-height: 20px;
    color: rgba(0,0,0,0.8);
    font-weight: 500;
    height: 20px;
    max-width: 251px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.search-points-list .text-show-all {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.505952px;
    color: #0ECFAA;
    padding-left: 15px;
    cursor: pointer;
}
.searched-location-to-list {
    height: 40px;
    background-color: rgba(255,255,255,0.1);
    cursor: pointer;
    transition: .3s;
    padding-left: 13px;
    position: relative;
}
.searched-location-to-list:hover {
    background-color: rgba(0,0,0,0.1);
}
.searched-location-to-list .location-icon {
    background-image: url("../../header/Search/img/location_icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    max-width: 8px;
    height: 10px;
    line-height: 40px;
    margin-right: 6px;
    position: absolute;
    top: 50%;
    margin-top: -5px;
}

.searched-location-to-list .location-name {
    font-size: 14px;
    line-height: 40px;
    color: rgba(0,0,0,0.8);
    margin-left: 12px;
}



.block-logo-new-top-menu-rocket-dao img {
    width: 134px;
    height: 23px;
    margin: 15px 0 15px 20px;
}
.block-search-header-input i {
    background-image: url("../../header/Search/img/search_icon.svg");
    background-size: contain;
    background-repeat: no-repeat;
    top: 12px!important;
    left: 12px!important;
}
.block-search-header-input .fa-search:before {
    content: '';
}


.block-list-new-top-menu-rocket-dao {
    width: calc(100% - 76px);
    position: absolute;
    z-index: 2;
}

.block-grid-new-top-menu-rocket-dao {
    display: table;
    margin: 0 auto;
    height: 54px;
    position: relative;
}

.block-item-new-top-menu-rocket-dao {
    display: inline-block;
    padding: 0 20px;
    height: 54px;
}

.block-item-new-top-menu-rocket-dao:first-child {
    padding: 0 20px 0 0;
}

.block-item-new-top-menu-rocket-dao:last-child {
    padding: 0 0 0 20px;
}

.block-item-new-top-menu-rocket-dao a {
    text-decoration: none;
    color: #000000;
    font-family: "Stratos LC";
    font-size: 18px;
    letter-spacing: -0.07px;
    line-height: 54px;
    font-weight: 300;
    transition: .3s;
    position: relative;
}

.block-item-new-top-menu-rocket-dao a:hover {
    color: #0028DC;
}

.block-item-new-top-menu-rocket-dao a.active {
    color: #9B9B9B;
    cursor: default;
}

.line-new-top-menu-rocket-dao {
    transition: .3s;
    background: #0028DC;
    height: 5px;
    width: 40px;
    position: absolute;
    bottom: 0;
    left: 30px;
}

.block-right-container-new-top-menu-rocket-dao {
    width: 300px;
    float: right;
    height: 54px;
    z-index: 3;
    position: relative;
}

.shell-person-name-photo-new-top-menu-rocket-dao {
    /*width: calc(100% - 30px);*/
    float: left;
    margin: 11px 0;
    /*padding: 0 20px;*/
    z-index: 3;
    position: relative;
    height: 32px;
}

.block-person-name-photo-new-top-menu-rocket-dao {
    width: 100%;
    overflow: hidden;
    z-index: 3;
    position: relative;
    text-align: right;
}

.block-person-name-new-top-menu-rocket-dao {
    width: calc(100% - 40px);
    float: left;
    cursor: pointer;
}

.block-person-name-new-top-menu-rocket-dao-2 {
    width: calc(100% - 100px);
    float: left;
    cursor: pointer;
}

.block-person-name-new-top-menu-rocket-dao p {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 16px;
    letter-spacing: -0.06px;
    line-height: 32px;
    font-weight: 300;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: right;
    cursor: pointer;
}

.block-person-photo-new-top-menu-rocket-dao {
    /*width: 32px;*/
    float: left;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
}

.block-person-photo-new-top-menu-rocket-dao-2 {
    width: 100px;
    float: right;
    position: relative;
    cursor: pointer;
}

.state-person-photo-new-top-menu-rocket-dao {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #FA4600;
    position: absolute;
    top: 0;
    left: 4px;
}

.block-person-photo-new-top-menu-rocket-dao img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
}

.block-grid-menu-person-new-top-menu-rocket-dao {
    top: -600px;
    padding: 0;
    left: 10px;
    position: relative;
    transition: .3s;
}

.shell-person-name-photo-new-top-menu-rocket-dao.no-active-right-menu:hover .block-grid-menu-person-new-top-menu-rocket-dao {
    top: 5px;
}

.shell-list-menu-person-new-top-menu-rocket-dao {
    position: relative;
    width: 240px;
    left: 20px;
}

.block-list-menu-person-new-top-menu-rocket-dao {
    padding: 1px 0 5px 0;
    background-color: #6A6A6A;
    z-index: 4000;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}

.block-item-menu-person-user-name{
    width: 100%;
    padding: 20px 25px;
    margin: 0 0 20px 0;
    background: #4A4A4A;
}
.block-item-menu-person-user-name p.title-user-name{
    color: #CFCFCF;	font-family: "Stratos LC";	font-size: 15px;	letter-spacing: -0.16px; line-height: 18px;
    font-weight: 300; margin: 0;
}

.block-item-menu-person-new-top-menu-rocket-dao {
    margin: 15px 0;
    position: relative;
    padding: 0 25px;
}

.block-item-menu-person-new-top-menu-rocket-dao a {
    text-decoration: none;
    color: #FFFFFF;
    font-family: "Stratos LC";
    transition: .3s;
    cursor: pointer;
    font-size: 18px;
    letter-spacing: -0.19px;
    line-height: 23px;
    font-weight: 300;
    position: relative;

}

.block-item-menu-person-new-top-menu-rocket-dao a:hover {
    text-decoration: underline;
}

.block-item-menu-person-new-top-menu-rocket-dao span.state-item-menu-person-new-top-menu-rocket-dao {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #FA4600;
    position: absolute;
    right: -10px;
    top: 4px;
}

.shell-button-open-close-menu-right-new-top-menu-rocket-dao {
    float: right;
    width: 30px;
    height: 54px;
}

.block-button-open-close-new-top-menu-rocket-dao {
    width: 30px;

}

.block-button-open-close-new-top-menu-rocket-dao .hamburger-icon {
    margin: 16px 0 !important;
    z-index: 11;
}

.block-button-open-close-new-top-menu-rocket-dao .hamburger-icon .line {
    background: #4a4a4a !important;
}

.block-grid-menu-right-new-top-menu-rocket-dao {
}

.shell-menu-right-new-top-menu-rocket-dao {
    width: 320px;
    position: fixed;
    height: 100vh;
    background-color: transparent;
    right: -100%;
    top: 0px;
    transition: .3s;
    z-index: 10;
}

.block-list-menu-right-new-top-menu-rocket-dao {
    position: absolute;
    width: 320px;
    right: 0;
    top: 0;
    z-index: 2;
    background-color: #f1f2f2;
    height: 100vh;
    padding: 35px;
}

.block-item-menu-right-new-top-menu-rocket-dao {
    margin: 15px 0;
}

.block-item-menu-right-new-top-menu-rocket-dao a {
    text-decoration: none;
    transition: .3s;
    color: #4a4a4a;
    font-family: "Stratos LC";
    font-size: 19px;
    line-height: 24px;
    font-weight: 400;
    margin: 0;
}

.block-item-menu-right-new-top-menu-rocket-dao a:hover {
    text-decoration: underline;
}

.right-no-reg-user {
    display: inline-block;
    float: right;
}

.right-no-reg-user span {
    padding-right: 10px;
    cursor: pointer;
    float: left;
    /*margin: 0 15px 0 0;*/
}

.right-no-reg-user button {
    cursor: pointer;
    background-color: #002BDE;
    color: #ffffff;
    height: 30px;
    line-height: 30px;
    padding: 0px 12px;
    outline: none;
}

/*.block-item-new-top-menu-rocket-dao a.active::after {*/
/*display: block;*/
/*position: absolute;*/
/*content: "";*/
/*height: 4px;*/
/*width: 100%;*/
/*background-color: #0028DC;*/
/*top: 35px;*/
/*}*/

.block-item-new-top-menu-rocket-dao {
    position: relative;
}

.shell-down-menu {
    width: 170px;
    position: absolute;
    z-index: 50;
    left: -25%;
    transition: .3s;
    height: 0;
    overflow: hidden;
    padding: 0;
}

.block-down-menu {
    width: 170px;
    background-color: #FFFFFF;
    border-radius: 3px;
    padding: 0;
    border: 0 solid #F1F2F2;
    transition: .3s;
    height: 0;
    overflow: hidden;
    margin: 0;
    z-index: 50;
    position: relative;
}

.block-item-new-top-menu-rocket-dao:hover .shell-down-menu {
}

.block-item-new-top-menu-rocket-dao:hover .block-down-menu.count-1 {
    height: 50px;
}

.block-item-new-top-menu-rocket-dao:hover .block-down-menu.count-2 {
    height: 80px;
}

.block-item-new-top-menu-rocket-dao:hover .block-down-menu.count-3 {
    height: 110px;
}

.block-item-new-top-menu-rocket-dao:hover .block-down-menu.count-4 {
    height: 140px;
}

.block-item-new-top-menu-rocket-dao:hover .block-down-menu.count-5 {
    height: 170px;
}

.block-item-new-top-menu-rocket-dao:hover .block-down-menu.count-6 {
    height: 200px;
}

.block-item-new-top-menu-rocket-dao:hover .block-down-menu.count-7 {
    height: 230px;
}

.block-item-new-top-menu-rocket-dao:hover .block-down-menu.count-8 {
    height: 260px;
}

.block-item-new-top-menu-rocket-dao:hover .block-down-menu.count-9 {
    height: 290px;
}

.block-item-new-top-menu-rocket-dao:hover .block-down-menu {
    padding: 10px 0 15px 0;
    border: 1px solid #F1F2F2;
    margin: 15px 0 0 0;
}

.block-item-new-top-menu-rocket-dao:hover .shell-down-menu.count-1 {
    height: 70px;
}

.block-item-new-top-menu-rocket-dao:hover .shell-down-menu.count-2 {
    height: 100px;
}

.block-item-new-top-menu-rocket-dao:hover .shell-down-menu.count-3 {
    height: 130px;
}

.block-item-new-top-menu-rocket-dao:hover .shell-down-menu.count-4 {
    height: 160px;
}

.block-item-new-top-menu-rocket-dao:hover .shell-down-menu.count-5 {
    height: 190px;
}

.block-item-new-top-menu-rocket-dao:hover .shell-down-menu.count-6 {
    height: 220px;
}

.block-item-new-top-menu-rocket-dao:hover .shell-down-menu.count-7 {
    height: 250px;
}

.block-item-new-top-menu-rocket-dao:hover .shell-down-menu.count-8 {
    height: 280px;
}

.block-item-new-top-menu-rocket-dao:hover .shell-down-menu.count-9 {
    height: 310px;
}

.block-down-menu.count-1:hover {
    height: 50px;
}

.block-down-menu.count-2:hover {
    height: 80px;
}

.block-down-menu.count-3:hover {
    height: 110px;
}

.block-down-menu.count-4:hover {
    height: 140px;
}

.block-down-menu.count-5:hover {
    height: 170px;
}

.block-down-menu.count-6:hover {
    height: 200px;
}

.block-down-menu.count-7:hover {
    height: 230px;
}

.block-down-menu.count-8:hover {
    height: 260px;
}

.block-down-menu.count-9:hover {
    height: 290px;
}

.block-down-menu:before {
    content: '';
    width: 15px;
    height: 15px;
    background: #FFFFFF;
    border-top: 1px solid #F1F2F2;
    border-left: 1px solid #F1F2F2;
    transform: rotate(45deg);
    position: absolute;
    top: 8px;
    left: calc(50% - 8px);
}

.block-item-down-menu {
    margin: 0;
    height: 30px;
    padding: 0;
    transition: .3s;
    display: block;
}

.block-item-down-menu a:hover {
    background-color: #F1F2F2;
}

.block-item-down-menu a.active:hover {
    background-color: transparent;
}

.block-item-down-menu:last-child {
    margin: 0;
}

.block-item-down-menu a {
    padding: 5px 15px;
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 15px;
    line-height: 20px;
    margin: 0;
    font-weight: 300;
    display: block;
    box-sizing: content-box;
}

.block-item-down-menu a.active {
    color: #0028DC;
}

.accel-inv-input-wrapper {
    justify-content: space-between;
}
.accel-gistogramm-block {
    padding-bottom: 30px;
    padding-top: 15px;
}
.accel-gistogramm-block .container-input-range {
    padding-top: 0;
}
.accel-gistogramm-block .container-input-range .input-range__track {
    height: 3px;
    background-color: #c4c4c4;
}
.accel-gistogramm-block .container-input-range .input-range__track.input-range__track--active {
    top: 0;
    height: 3px;
    background-color: #0ECFAA;
}
.accel-gistogramm-block .container-input-range .input-range__slider {
    top:-2px;
    left: -2px;
    background-color: #0ECFAA;
    border: 3px solid #FFFFFF;
    box-shadow: 0px 4px 19px rgba(0,0,0,0.15);
    width: 21px;
    height: 21px;
}
.accel-inv-input-wrapper .block-new-input input {
    color: rgba(0,0,0,0.8);
    border-radius: 6px;
    border: 1px solid rgba(151,151,151,0.45);
    opacity: 1!important;
}
.accel-gistogramm-block .col {
    height: 50px;
    min-width: 10px;
    transition: .4s;
}
.accel-gistogramm-block .input-range {
    margin-top: -7px;
}
.accel-gistogramm-block .container-input-range .input-range__slider:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    height: 7px;
    width: 4px;
    border-left: 1px solid #FFFFFF;
    border-right: 1px solid #FFFFFF;
}
.accel-gistogramm-block .container-input-range .input-range__slider:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 10px;
    height: 7px;
    width: 4px;
    border-left: 1px solid #FFFFFF;
}


@media all and (max-width: 465px) {
    .block-search-header-input .search-header-open-list {
        position: fixed;
        width: 100vw;
        left: 0;
        top:54px;
        margin-top: 0px!important;
        border-left: unset;
        border-right: unset;
        max-height: calc(100vh - 134px);
        /*max-height: 50vh;*/
        overflow-y: scroll;
    }
    .block-search-header-input {
        max-width: 100vw !important;
        padding-right: 5px !important;
    }
    .block-search-header-input .block-new-input input:focus {
        border-radius: 6px;
    }
    .block-new-top-menu-rocket-dao {
        padding: 0!important;
    }
    .accel-gistogramm-block {
        margin: 0 auto;
        width: max-content;
    }
    .not-scroll-this {
        display: none;
    }
    .block-new-top-menu-rocket-dao.container-full-reg {
        padding: 0 20px 0 10px!important;
    }
}
@media all and (min-width: 465px) {
    .scroll-this-display {
        display: none;
    }
}

@media all and (max-width: 330px) {
    .search-recent-list {
        padding: 22px 10px 12px 18px;
    }
}

@media all and (max-width: 390px) {
    .block-logo-new-top-menu-rocket-dao img {
        width: 110px;
        margin: 15px 0 15px 10px;
    }
    .block-list-link-blog-header .header-title-about {
        margin-right: 15px;
    }
    .block-new-top-menu-rocket-dao {
        /*padding: 0 10px 0 0!important;*/
    }
    .block-button-hamburger-new {
        margin: 6px 0 0 0!important;
    }
}
@media all and (max-width: 1000px) {
    .block-search-header-input {
        /*display: none;*/
    }
}