.container-lending {
    width: 950px;
    margin: 0 auto;
    display: table;
}
.shell-header-back-title-form{
    position: relative;
    top: -108px;
}
.shell-header-back-title-form-after{
    top: -54px;
}

.block-header-back-title-form {
    height: 600px;
    width: 100%;
    background-size: cover;
    background-position-x: center;
    background-position-y: top;
    padding: 230px 0 50px 0;
    position: relative;
    /*-webkit-box-shadow: inset 0px 0px 0px 10000px rgba(0,0,0,0.5);*/
    /*box-shadow: inset 0px 0px 0px 10000px rgba(0,0,0,0.5);*/
}
.block-header-about{
    padding: 100px 0 250px 0;
    height: 730px;
    background: #FDFDFD;
}
.block-header-about p.title{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 38px;	font-weight: 500;	letter-spacing: -0.3px;	line-height: 48px;
    margin: 0 0 20px 0;
}
.block-header-about p.description{
    color: #000000;	font-family: "Stratos LC";	font-size: 20px;	line-height: 24px;
    margin: 0; width: 360px;
}

.block-left-header-back-title-form {
    width: calc(100% - 615px);
    float: left;
    padding: 50px 30px 0 0;
}

.block-left-header-back-title-form .small-title {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 18px;
    letter-spacing: -0.57px;
    line-height: 20px;
    font-weight: 300;
    margin: 0;
}

.block-left-header-back-title-form .big-title {
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 40px;
    letter-spacing: -0.57px;
    line-height: 48px;
    text-shadow: 0 2px 40px 0 rgba(0, 0, 0, 0.25);
    font-weight: 400;
    margin: 10px 0 0 0;
    position: relative;
    left: -2px;
}

.block-right-header-back-title-form {
    width: 515px;
    float: right;
}

.shell-form-lending-page {
    display: table;
    width: 100%;
    background: #FFFFFF;
    z-index: 5;
    position: relative;
}
.block-form-form-lending-page{
    padding: 30px;
}

.block-form-form-lending-page .block-item-drop-down-select{
    padding: 0 10px;
}

.block-gradient-white-bottom {
    width: 100%;
    height: 240px;
    background: linear-gradient(180deg, rgba(249, 250, 250, 0) 0%, #F1F2F2 100%);
    position: absolute;
    bottom: 0;
}

.block-column-title-gradient {
    background: #F1F2F2;
    padding: 30px 0 30px 0;
}
.shell-column-title-gradient{
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
}
.shell-column-title-gradient:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 50px;
    background: #fdfdfd;
    left: 0;
    bottom: 0;
    z-index: 1;
}
.block-column-title-gradient-about{
    background: #FFFFFF;
    position: relative;
    padding: 50px 0;
z-index: 2;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}
.block-column-title-gradient p.description{
    /*height: 60px;*/
}
.block-column-title-gradient-about p.description{
    height: 90px;
}
.block-column-title-gradient-about a{
    color: #2AA2E6;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.57px;	line-height: 23px;
    margin: 5px 0 0 0;
    cursor: pointer;
}

.block-column-title-gradient-about a i{
    color: #2AA2E6;
    margin: 0 0 0 10px;
    font-size: 20px;
    position: relative;
    top: 4px;
}
.block-group-3-column {
    width: 100%;
    display: flex;
    padding: 0 60px;
}

.block-group-3-column .block-item-3-column {
    width: calc((100% / 3) - ((60px * 2) / 3));
    float: left;
    margin: 0 70px 0 0;
}

.block-group-3-column .block-item-3-column:last-child {

    margin: 0;
}

.block-group-3-column .block-item-3-column .title {
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 27px;
    letter-spacing: -0.19px;
    line-height: 32px;
    font-weight: 400;
    margin: 0 0 15px 0;
}

.block-group-3-column .block-item-3-column .description {
    color: #454545;
    font-family: "Stratos LC";
    font-size: 16px;
    letter-spacing: -0.5px;
    line-height: 20px;
    font-weight: 300;
    margin: 0 0 15px 0;
}

.shell-list-title-img {
}

.block-list-title-img {
}

.block-item-title-img {
    display: flex;
    padding: 50px 0;
}

.block-item-title-img.title-left-img-right {
}

.block-item-title-img.img-left-title-right {
}
.block-item-title-img img{
    width: auto;
    height: auto;
    float: right;
}
.block-item-title-img.title-left-img-right .block-left-item-title-img {
    width: calc(100% - 420px);
    padding: 0 50px 0 0;
    float: left;
    display: flex;
}

.block-item-title-img.title-left-img-right .block-right-item-title-img {
    width: 420px;
    /*height: 420px;*/
    float: right;
    display: flex;
}

.block-item-title-img.img-left-title-right .block-left-item-title-img {
    width: 420px;
    /*height: 420px;*/
    float: right;
    /*display: flex;*/
}

.block-item-title-img.img-left-title-right .block-right-item-title-img {
    width: calc(100% - 420px);
    padding: 0 0 0 50px;
    float: left;
    display: flex;
}

.block-item-title-img.img-left-title-right .block-right-item-title-img.img {
    display: none;
}

.block-number-title-description {
    display: flex;
    margin: auto 0;
}

.block-number-title-description .block-left-number-title-description {
    float: left;
    width: 90px;
}

.block-number-title-description .block-left-number-title-description p.number {
    color: #2F3849;	font-family: "Stratos LC";	font-size: 100px;	letter-spacing: -1.18px;
    font-weight: 500;
    margin: 0;
    position: relative;
    top: 5px;
}

.block-number-title-description .block-right-number-title-description {
    float: right;
    width: calc(100% - 90px);
    padding: 0 0 0 10px;
}

.block-number-title-description .block-right-number-title-description p.title {
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 27px;
    letter-spacing: -0.58px;
    line-height: 34px;
    font-weight: 400;
    margin: 0;
}

.block-number-title-description .block-right-number-title-description p.description {
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 20px;
    letter-spacing: -0.03px;
    line-height: 24px;
    font-weight: 300;
    margin: 20px 0 0 0;
}

.shell-group-3-column {
    width: 100%;
    display: block;
}

.block-img-title {
    /*height: 70px;*/
    overflow: hidden;
    margin: 0 0 20px 0;
    display: flex;
}

.block-img-title img {
    width: 70px;
    height: 70px;
    float: left;
    display: block;
    /*background: #0000d3;*/
}
.block-img-title.new-view img{
    width: 100%;
    float: none;
}
.block-img-title p.title {
    width: calc(100% - 70px);
    float: right;
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 27px;
    letter-spacing: -0.58px;
    line-height: 34px;
    font-weight: 300;
    margin: 0;
    display: flex;
    padding: 0 0 0 30px;
    margin: auto 0;
}
.block-img-title.new-view p.title{
    width: 100%;
    float: none;
}
p.description-img-title {
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 20px;
    letter-spacing: -0.03px;
    line-height: 24px;
    font-weight: 300;
    margin: 0 0 20px 0;
    width: 100%;
    display: flex;
}

.title-lending-page {
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 34px;
    letter-spacing: -0.24px;
    line-height: 32px;
    font-weight: 400;
    margin: 80px 0 50px 0;
}

.shell-column-2-lending-page {
}

.block-column-2-lending-page {
    display: table;
    width: 100%;
    padding: 50px 0;
}

.block-item-column-2-lending-page {
    width: calc((100% / 2) - ((60px) / 2));
    float: left;
    margin: 0 60px 0 0;
}
.block-item-column-2-lending-page:last-child{
    margin: 0;
}
.block-back-title-button{
    width: 100%;
    padding: 70px 150px 0 150px;
    height: 400px;
}
.block-back-title-button p.title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 40px;	letter-spacing: -0.44px;	line-height: 50px;	text-shadow: 0 2px 30px 0 rgba(0,0,0,0.5);
    font-weight: 400; margin: 0 0 20px 0;
}


.big-title-form{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 22px;	letter-spacing: -0.16px;	line-height: 30px;
    font-weight: 400; margin: 0;
}
.shell-drop-down-lending-form{}
.shell-drop-down-lending-form .block-button-open-close-drop-down-select{
    background: transparent;
}
.shell-drop-down-lending-form .block-button-open-close-drop-down-select:after, .shell-drop-down-lending-form  .block-button-open-close-drop-down-select:before{
    background: #002BDE;
}
.shell-drop-down-lending-form .block-top-drop-down-select{
    border: 1px solid #CFCFCF!important;
    border-radius: 2px;
}
.shell-drop-down-lending-form .block-top-drop-down-select.active{
    border-top: 1px solid #CFCFCF!important;
    border-right: 1px solid #CFCFCF!important;
    border-bottom: 0;
    border-left: 1px solid #CFCFCF!important;
    border-radius: 2px 2px 0 0;
}
.shell-drop-down-lending-form .block-list-input-drop-down-select.active{
    border-top: 0;
    border-right: 1px solid #CFCFCF!important;
    border-bottom: 1px solid #CFCFCF!important;
    border-left: 1px solid #CFCFCF!important;
    border-radius: 0 0 2px 2px;
}
.shell-drop-down-lending-form .block-value-top-drop-down-select p{
    padding: 0 10px ;
    color: #454545;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.5px;	line-height: 20px;
    font-weight: 300;
}
.shell-drop-down-lending-form.fix-ddn {
    margin-bottom: 15px;
}

.shell-drop-down-lending-form.fix-ddn .block-top-drop-down-select {
    border: 1px solid transparent!important;
     border-bottom: 1px solid #979797!important;
}
.shell-drop-down-lending-form.fix-ddn .block-value-top-drop-down-select p {
    /*padding-left: 0;*/
}

.general-dropdown-class.color-white .block-value-top-drop-down-select p{
    color: #ffffff;
}
.shell-drop-down-lending-form.fix-ddn .block-item-drop-down-select p {
    padding-left: 10px;
}


.title-input{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 13px;	line-height: 16px;
    font-weight: 300; margin: 15px 0 7px 0;
}
.shell-input-lending-form{}
.shell-input-lending-form input{
    border-radius: 2px;
    border: 1px solid #CFCFCF;
    width: 100%;
    height: 36px;
    padding: 0 10px;
    color: #454545;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.3px;	line-height: 36px;
    font-weight: 300;
}
.shell-input-lending-form.fix-eth {
    position: relative;
}
.shell-input-lending-form.fix-eth input {
    padding-left: 40px;
}
.shell-input-lending-form.fix-eth span {
    position: absolute;
    top: 32px;
    left: 10px;
}
.count-rock{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 34px;	letter-spacing: -0.24px;	line-height: 32px;
    margin: 0; font-weight: 400;
}
.count-rock span.small-title{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 13px;	letter-spacing: -0.13px;	line-height: 24px;
    margin: 0 0 0 10px; font-weight: 300;
}
.small-title-form{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 13px;	letter-spacing: -0.13px;	line-height: 24px;
    font-weight: 400; margin: 0 0 15px 0;
}
.small-description-form{
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 13px;
    font-weight: 300;
    margin: 20px 0 10px 0;
}
.shell-select-tags-drop-down{
    margin: 15px 0;
}
.shell-select-tags-drop-down .block-top-drop-down-select{
    border: 1px solid #CFCFCF;
    border-radius: 2px;
}
.shell-select-tags-drop-down .block-top-drop-down-select.active{
    border: 1px solid #CFCFCF;
    border-bottom: 0;
    border-radius: 2px 2px 0 0;
}
.shell-select-tags-drop-down .block-list-input-drop-down-select.active{
    border: 1px solid #CFCFCF;
    border-radius: 0 0 2px 2px;
}
.shell-select-tags-drop-down .block-item-drop-down-select{
    border-bottom: 1px solid #CFCFCF;
    padding: 0 10px;
}
.shell-select-tags-drop-down .block-item-drop-down-select:last-child{
    border-bottom: 0;
}
.shell-select-tags-drop-down .block-button-open-close-drop-down-select{
    background: transparent;
}
.shell-select-tags-drop-down .block-button-open-close-drop-down-select:after,
.shell-select-tags-drop-down .block-button-open-close-drop-down-select:before{
    background: #0028DC;
}
.shell-select-tags-drop-down .block-value-top-drop-down-select p{
    padding: 0 10px;
    color: #454545;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.5px;	line-height: 20px;
    font-weight: 300;

}

.shell-section-list-description{
    margin: 80px 0;
}
.block-section-list-description{}


.block-left-section-list-description{
    width: 280px;
    overflow: hidden;
    min-height: 630px;

    padding: 0 50px 0 0;
    float: left;
}

.block-item-button-active-section{
    margin: 0 0 40px 0;
    display: block;
    cursor: pointer;
}
.block-item-button-active-section .block-number-item-button-active-section{
    width: 50px;
    float: left;
}
.block-item-button-active-section .block-number-item-button-active-section p.number{
    color: #454545;	font-family: "Stratos LC";	font-size: 48px;	letter-spacing: -1.66px;	line-height: 44px;
    font-weight: 400; margin: 0; transition: .3s;
}
.block-item-button-active-section .block-title-item-button-active-section{
    width: calc(100% - 50px);
    display: flex;
    height: 40px;
}
.block-item-button-active-section .block-title-item-button-active-section p.title{
    color: #454545;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.5px;	line-height: 20px;
    display: flex;
    margin: auto 0;
    font-weight: 300;
    transition: .3s;
}
.block-item-button-active-section:hover{}
.block-item-button-active-section:hover .block-number-item-button-active-section p.number{
    color: #0028DC;
}
.block-item-button-active-section:hover .block-title-item-button-active-section p.title{
    color: #0028DC;
}
.block-item-button-active-section.active:hover .block-number-item-button-active-section p.number{
    color: #CFCFCF;
}
.block-item-button-active-section.active:hover .block-title-item-button-active-section p.title{
    color: #CFCFCF;
}

.block-item-button-active-section.active{}
.block-item-button-active-section.active .block-number-item-button-active-section p.number{
    color: #CFCFCF;
}
.block-item-button-active-section.active .block-title-item-button-active-section p.title{
    color: #CFCFCF;
}

.block-right-section-list-description{
    width: calc(100% - 280px);
    float: right;
    border-left: 1px solid #0028DC;
    position: relative;
    display: table;
}

.block-right-section-list-description .section-item{
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: .3s;
    padding: 0 0 0 45px;
    border-left: 1px solid #0028DC;
}
.block-right-section-list-description .section-item p.description{
    color: #000000;	font-family: "Stratos LC";	font-size: 15px;	letter-spacing: -0.29px;	line-height: 20px;
    font-weight: 300; margin: 0 0 15px 0;
}

.block-right-section-list-description .section-item.active{
    opacity: 1;
}

.shell-list-guide{
    width: 100%;
}
.block-list-guide{
    width: 100%;
}


.block-item-guide{
    width: 100%;
    display: flex;
    margin: 0 0 50px 0;
}
.block-item-guide .block-img-item-guide{
    width: 50%;
    padding: 0 50px;
}
.block-item-guide .block-img-item-guide img{}
.block-item-guide.left-img-item-guide .block-img-item-guide.left-img img{

}

.block-item-guide .block-number-item-guide{
    width: 60px;
    float: left;
    margin: auto 0;
}
.block-item-guide .block-number-item-guide p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 70px;	letter-spacing: -0.83px;
    font-weight: 400;margin: 0;
}
.block-item-guide .block-title-item-guide{
    width: calc(50% - 60px);
    margin: auto 0;
}
.block-item-guide .block-title-item-guide p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 27px;	letter-spacing: -0.58px;	line-height: 34px;
    font-weight: 300; margin: 0;
}
.block-item-guide .block-title-item-guide a{
    color: #0028DC;
}

.block-item-guide.right-img-item-guide .block-title-item-guide{
    float: left;
}
.block-item-guide.left-img-item-guide .block-title-item-guide{
    float: right;
}


.block-item-guide.right-img-item-guide .block-img-item-guide{
    float: right;
}

.block-item-guide.left-img-item-guide .block-img-item-guide.left-img{
    float: left;
}
.block-item-guide.left-img-item-guide .block-img-item-guide.right-img{
    display: none;
}





.block-expert-bounty{
    height: 345px;
    background-color: #2F3849;	box-shadow: 0 7px 9px 0 rgba(0,0,0,0.16);
    width: 100%;
    padding: 55px 0;
    display: table;
    overflow: hidden;
}
.block-left-expert-bounty{
    width: calc(100% - 180px);
    float: left;
}
.block-evaluation-about .block-left-expert-bounty {
    width: 50%;
}
.block-evaluation-about .block-right-expert-bounty {
    width: calc(50% - 100px);
    min-height: 1px;
    height: auto;
    margin-right: 100px;
}
.block-left-expert-bounty p.title{
    color: #000000;	font-family: "Stratos LC";	font-size: 40px;	letter-spacing: -0.36px;
    margin: 0; font-weight: 500; line-height: normal;
}
.block-left-expert-bounty p.description{
    color: #000000;	font-family: "Stratos LC";	font-size: 22px;	letter-spacing: -0.88px;	line-height: 36px;
    margin: 10px 0 0 0; font-weight: 300;	width: 561px;
}
.block-evaluation-about .block-left-expert-bounty p.description {
    font-size: 24px;
    line-height: 29px;
    width: unset;
    max-width: 561px;
    padding-right: 15px;
}
.block-right-expert-bounty{
    width: 180px;
    float: left;
    position: relative;
}
.block-right-expert-bounty img{
    position: absolute;
    top: -49px;
    right: 0;
}
.block-button-expert-bounty{
    width: 100%;
    cursor: pointer;
}
.block-button-expert-bounty .shell-button-expert{
    height: 112px;	width: 112px;	background-color: rgba(255, 255, 255, .5);
    border-radius: 50%; margin: 0 34px;display: table;
}
.block-button-expert-bounty .block-button-expert{
    height: 66px;	width: 66px;	background-color: #FFFFFF;    border-radius: 50%;
    margin: 23px; position: relative;
}
.block-button-expert-bounty .block-button-expert:after{
    content: '';
    background: rgb(42, 162, 230);
    position: absolute;
    width: 22px;
    height: 2px;
    transform: rotate(55deg);
    left: 24px;
    top: 24px;
    border-radius: 2px 0 0 2px;
}
.block-button-expert-bounty .block-button-expert:before{
    content: '';
    background: rgb(42, 162, 230);
    position: absolute;
    width: 22px;
    height: 2px;
    transform: rotate(-55deg);
    left: 24px;
    top: 40px;
    border-radius: 2px 0 0 2px;
}
.block-button-expert-bounty p.title{
    color: #000000;	font-family: "Stratos LC";	font-size: 18px;	letter-spacing: -0.1px;	line-height: 23px;	text-align: center;
    margin: 20px 0 0 0; font-weight: 300;
    text-decoration: none;
}
.block-evaluation-about .block-left-expert-bounty .block-button-3-0 {
    height: 40px;
    margin-right: 20px;
}
.block-evaluation-about .block-left-expert-bounty .block-button-3-0 p.block-button-3-0-title {
    line-height: 40px;
    font-weight: 500;
}
.block-evaluation-about .block-right-expert-bounty .block-evaluation-img {
    background: url("../../../images/ilustaciya/MagazineBig.png");
    background-repeat: no-repeat!important;
    background-size: auto!important;
    height: 260px;
    margin-left: calc(100% - 727px);
}
.block-evaluation-about .block-right-expert-bounty .see-sample-arrow,
.block-evaluation-about .block-right-expert-bounty .see-sample-text {
    float: right;
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
    color: #0ECFAA;
    cursor: pointer;
}
.block-evaluation-about .block-right-expert-bounty .see-sample-text {
    padding-right: 10px;
}
.block-evaluation-about .block-right-expert-bounty .see-sample-arrow {
    background: url("../../../images/green_arrow.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 50%;
    width: 20px;
    height: 20px;
}
.block-lending-about-container{
    background: #f1f2f2;
}
.block-program-bounty{
    /*background-color: #FFFFFF;*/
    padding: 60px 0;
    display: table;
    width: 100%;
}
p.title-program-bounty{
    color: #3D3C40;	font-family: "Stratos LC";	font-size: 34px;	letter-spacing: -0.24px;	line-height: 32px;
    margin: 0 0 40px 0; font-weight: 400;
}
.block-left-program-bounty{
   	border-radius: 3px;	background-color: #FFFFFF;	box-shadow: 0 20px 30px 0 rgba(0,0,0,0.07);
    width: 47%; float: left; padding: 40px 50px;
}
.block-left-program-bounty p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 27px;	letter-spacing: -0.19px;	line-height: 32px;
    margin: 0; font-weight: 400;
}
.block-left-program-bounty p.description{
    color: #454545;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.5px;	line-height: 20px;
    margin: 20px 0; font-weight: 300;
    height: 105px;
}
.block-left-program-bounty .block-button-2-0{
    margin: 20px 0 0 0;
    border-radius: 2px;
    width: 210px;
}
.block-right-program-bounty{
   border-radius: 3px;	background-color: #FFFFFF;	box-shadow: 0 20px 30px 0 rgba(0,0,0,0.07);
    width: 47%; float: right; padding: 40px 50px;
}
.block-right-program-bounty p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 27px;	letter-spacing: -0.19px;	line-height: 32px;
    margin: 0; font-weight: 400;
}
.block-right-program-bounty p.description{
    color: #454545;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.5px;	line-height: 20px;
    margin: 20px 0; font-weight: 300;
    height: 105px ;
}
.block-right-program-bounty .block-button-2-0{
    margin: 20px 0 0 0;
    border-radius: 2px;
    width: 210px;
}

.block-join-community{
    width: 100%;
    display: table;
}

.block-left-join-community{
    width: 365px;
    float: left;
}
.block-right-join-community{
    width: calc(100% - 390px);
    float: right;
    display: table;
}

.block-top-right-join-community{
    width: 100%;
    display: table;
    padding: 0 0 25px 0;
}

.block-left-top-right-join-community{
    width: calc(50% - (25px/2));
    float: left;
}
.block-right-top-right-join-community{
    width: calc(50% - (25px/2));
    float: right;
}

.block-bottom-right-join-community{

}

.block-cart-join-community{
    width: 100%;
    padding: 40px;
    background: #FFFFFF;
    box-shadow: 0 20px 30px 0 rgba(0,0,0,0.1);
    border-radius: 3px;
    box-sizing: border-box;
    position: relative;
}
.block-bottom-right-join-community .block-cart-join-community{
    padding: 20px 40px;
}

.block-cart-join-community .block-top-right-icon i{
    position: absolute;
    top: 18px;
    right: 25px;
    font-size: 25px;
    color: #4a4a4a;
}
.block-cart-join-community p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 27px;	letter-spacing: -0.19px;	line-height: 32px;
    margin: 0; font-weight: 400;
}
.block-cart-join-community p.description{
    color: #454545;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.5px;	line-height: 23px;
    margin: 25px 0 0 0; font-weight: 300;
}
.block-cart-join-community a{
    color: #454545;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.5px;	line-height: 23px;
    margin: 15px 0 0 0; font-weight: 400; text-decoration: none; display: block;
}
.block-cart-join-community a i{
    color: #0028DC;
    font-size: 20px;
    margin: 0 0 0 10px;
    position: relative;
    top: 3px;
    transition: .3s;
}

.block-social-link-join-community{
    width: 100%;
    height: 23px;
    display: table;
    margin: 10px 0 0 0;
}
.block-social-link-join-community a{
    width: 23px;
    height: 23px;
    display: block;
    float: left;
    margin: 0 25px 0 0;
}
.block-social-link-join-community a i{
    color: #4a4a4a;
    font-size: 24px;
    margin: 0;
    top: 0;
    left: 0;
}
.block-social-link-join-community a:hover i{
    color: #0028DC;
}
.block-social-link-join-community a span.block-svg-icon{
    position: relative;
}
.block-social-link-join-community a span.block-svg-icon img{
    width: 23px;
    height: 23px;
    transition: .3s;
    position: absolute;
    top: 0;
    left: 0;
}

.block-social-link-join-community a span.block-svg-icon img:nth-child(1){
    opacity: 1;
}
.block-social-link-join-community a span.block-svg-icon img:nth-child(2){
    opacity: 0;
}
.block-social-link-join-community a:hover span.block-svg-icon img:nth-child(1){
    opacity: 0;
}
.block-social-link-join-community a:hover span.block-svg-icon img:nth-child(2){
    opacity: 1;
}



.block-lending-about-container .block-list-dots-new-road-map .block-item-dots-new-road-map{
    width: calc(100% / 16);
}
.block-lending-about-container .block-list-dots-new-road-map .block-item-dots-new-road-map{
    width: calc(100% / 16);
}
.block-lending-about-container .block-left-item-new-road-map{
    width: 360px;
}
.block-lending-about-container .block-right-item-new-road-map{
    width: calc(100% - 360px);
}

.block-lending-about-rocket-wallet{
    height: 645px;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center!important;
    display: table;
    width: 100%;

}
.block-left-rocket-wallet{
    width: 350px;
    height: 645px;
    float: left;
    position: relative;
}
.block-left-rocket-wallet img{
    position: absolute;
    left: 0;
    bottom: 0;
}
.block-right-rocket-wallet{
    width: calc(100% - 400px);
    float: right;
    height: 645px;
}
.block-right-rocket-wallet p.title{
    margin: 230px 0 0 0;
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 47px;	letter-spacing: -1.45px;	line-height: 59px;	text-shadow: 0 2px 15px 0 rgba(0,0,0,0.31);
    font-weight: 400;
}
.block-right-rocket-wallet p.description{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 26px;	letter-spacing: -0.8px;	line-height: 33px;	text-shadow: 0 2px 15px 0 rgba(0,0,0,0.31);
    margin: 20px 0 0 0; font-weight: 300;
}
.block-right-rocket-wallet .block-img{
    margin: 20px 0 0 0;
}
.block-right-rocket-wallet .block-img a{
    margin:  0 20px 0 0;
}
.block-right-rocket-wallet .block-img a img{}

.block-checked-about{}
.block-checked-about p.title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 22px;	letter-spacing: -0.16px;	line-height: 30px;
    margin: 0 0 20px 0; font-weight: 300;
}
.block-checked-about p.item{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 19px;	line-height: 24px;
    margin: 0 0 10px 0; font-weight: 300;
}
.block-checked-about p.item img{
    margin: 0 15px 0 0 ;
}



.block-banner-peeranha{
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 33px 50px 45px 39px;
    border: 1px solid #0ECFAA;

    background-position: right!important;
    background-size: 50.6%!important;
    background-repeat: no-repeat!important;
}
.block-title-description-banner-peeranha{}
.block-title-description-banner-peeranha p.title-banner{
    font-size: 32px;
    line-height: 46px;
    color: rgba(8, 8, 8, 0.8);
    margin: 0 0 6px 0;
    font-weight: 500;
}
.block-title-description-banner-peeranha p.description-banner{
    font-size: 16px;
    line-height: 20px;
    color: rgba(8, 8, 8, 0.8);
    margin: 0 0 36px 0;
    font-weight: 400;
    max-width: 572px;
}
.block-button-banner-peeranha{

}

.block-button-banner-peeranha .block-button-3-0{
    height: 40px;
    margin: 0 16px 0 0;
}
.block-button-banner-peeranha .block-button-3-0 p.block-button-3-0-title{
    height: 40px!important;
    line-height: 40px!important;
}
.block-button-banner-peeranha .block-button-3-0.button-3-0-border{
    border: 1px solid #0ECFAA;
}
.block-button-banner-peeranha .block-button-3-0.button-3-0-border p.block-button-3-0-title{
    color: #0ECFAA;
    height: 38px!important;
    line-height: 38px!important;
}

@media (max-width: 970px) {
    .block-banner-peeranha{
        background-image: none!important;
        padding: 33px 50px 29px 39px;
    }
    .block-button-banner-peeranha .block-button-3-0{
        margin: 0 0 16px 0;
        display: block;
        width: 100%;
    }
}
@media (max-width: 970px) {
    .block-banner-peeranha{
        background-image: none!important;
        padding: 23px 30px 19px 30px;
    }
}
@media (min-width: 1650px) {
    .block-evaluation-about .block-right-expert-bounty .block-evaluation-img {
        margin-left: 0;
    }
    .block-evaluation-about .block-right-expert-bounty {
        width: 728px;
        margin-right: calc(50% - 728px);
    }
}
@media (min-width: 1825px) {
    .block-evaluation-about .block-right-expert-bounty {
        margin-right: calc(45% - 728px);
    }
}
@media (max-width: 1050px) {
    .block-evaluation-about .block-right-expert-bounty {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 32px;
        transform: translateX(-50%);
        width: unset;
        margin-right: 0;
    }
    .block-evaluation-about .block-right-expert-bounty .block-evaluation-img {
        display: none;
    }
    .block-expert-bounty.block-evaluation-about {
        padding: 55px 0 70px 0;
    }
    .block-evaluation-about .block-left-expert-bounty {
        width: 435px;
        margin: 0 auto;
        float: unset;
    }
}
@media (max-width: 999px) {
    .container-lending{
        width: 700px;
    }
    .block-left-header-back-title-form{
        width: 100%;
        float: none;
    }
    .block-right-header-back-title-form{
        width: 100%;
        float: none;
    }
    .block-header-about{
        height: 1140px;
        padding: 0 0 ;
    }
    .block-group-3-column{
        display: block;
    }
    .block-group-3-column .block-item-3-column{
        width: 100%;
        float: none;
        margin: 0 0 40px 0;
    }
    .block-column-title-gradient-about p.description{
        height: 60px;
    }
    .block-left-expert-bounty{
        width: 100%;
        float: none;
    }
    .block-right-expert-bounty{
        display: none;
    }
    .block-left-program-bounty{
        width: 100%;
        float: none;
        margin: 0 0 30px 0;
    }
    .block-right-program-bounty{
        width: 100%;
        float: none;
    }


    .block-item-title-img.title-left-img-right .block-left-item-title-img{
        width: calc(100% - 220px);
    }
    .block-item-title-img.title-left-img-right .block-right-item-title-img{
        width: 220px;
    }
    .block-item-title-img.img-left-title-right .block-left-item-title-img{
        width: 220px;
    }
    .block-item-title-img.img-left-title-right .block-right-item-title-img{
        width: calc(100% - 220px);
    }
}

@media (max-width: 800px) {
    .container-lending{
        width: 100%;
        margin: auto;
        padding: 0 20px;
    }
    .block-left-expert-bounty p.description{
        width: 100%;
    }
    .container-DAO{
        width: 100%;
    }
}
@media (max-width: 600px) {
    .container-DAO{
        width: 100%;
    }
    .block-left-program-bounty{
        padding: 20px;
    }
    .block-right-program-bounty{
        padding: 20px;
    }


    .block-item-title-img{
        display: block;
    }
    .block-item-title-img.title-left-img-right .block-left-item-title-img{
        width: 100%;
        float: none;
        padding: 0;
    }
    .block-item-title-img.title-left-img-right .block-right-item-title-img{
        width: 100%;
        float: none;
        text-align: center;
    }
    .block-item-title-img img{
        margin: auto;
        float: none;
    }
    .block-item-title-img.img-left-title-right .block-left-item-title-img{
        width: 100%;
        float: none;
        display: none;
    }
    .block-item-title-img.img-left-title-right .block-right-item-title-img{
        width: 100%;
        float: none;
        padding: 0;
    }
    .block-item-title-img.img-left-title-right .block-right-item-title-img.img{
        display: block;
        text-align: center;
    }

}
@media (max-width: 580px) {
    .block-left-program-bounty p.description{
        height: auto;
    }
    .block-right-program-bounty p.description{
        height: auto;
    }
    .block-team-2-0{
        overflow: hidden;
    }
    .block-right-header-back-title-form img{
        width: 100%;
    }
    .block-header-about p.description{
        width: 100%;
    }
    .block-header-about{
        height: 1240px;
        padding: 0 0 ;
    }
    .block-group-3-column{
        display: block;
        padding: 0;
    }
    .block-group-3-column .block-item-3-column{
        width: 100%;
        float: none;
        margin: 0 0 40px 0;
    }
    .block-column-title-gradient-about p.description{
        height: 90px;
    }
}
@media (max-width: 580px) {
    .block-evaluation-about .block-left-expert-bounty {
        width: calc(100% - 50px);
    }
    .block-evaluation-about .block-left-expert-bounty .block-button-3-0 {
        width: 100%;
    }
    .block-evaluation-about .block-left-expert-bounty .block-button-3-0.button-3-0-green {
        margin-bottom: 15px;
    }
}