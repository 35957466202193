
.block-fund-sidebar .block-startup-way {
	width: 100%;
	border-top: 1px solid #D8D8D8;
	border-bottom: 1px solid #D8D8D8;
	padding: 20px 0 20px 0;
}
.block-startup-way .closed-block-way-name .number-way-block {
	background-color: rgba(0, 0, 0, 0.5);	
}
.block-startup-way .closed-block-way-name .number-way-block > div {
	background-image: url('./img/ic_closed.svg');
	background-position: 50% 50%;
}
.block-dashboard-buttons-line {
	margin: 25px 0 30px 0;
	width: 100%;
	height: 35px;
}
.block-dashboard-buttons-line .dashboard-button {
	float: left;
	margin-right: 7px;
	width: 148px;
	height: 35px;
	border: 1px solid #D0D0D0;
	border-radius: 6px;
}

.dashboard-button.views{
	border: 1px solid #2F3849;
}

.dashboard-button.likes{
	border: 1px solid #E43A70;
}

.dashboard-button.wish{
	border: 1px solid #2AA2E6;
}

.block-fund-newsfeed .block-dashboard-temp-content {
	margin-top: 5px;
	height: 1070px;
	background-image: url(../temp_img/temp_dashboard.jpg);
	background-repeat: no-repeat;
}
.block-dashboard-shares {
	margin-top: 65px;
}
.block-dashboard-shares .shares-title {
	font-family: Stratos LC;
	font-size: 22px;
	line-height: 22px;
	color: #2F3849;
}
.block-dashboard-shares .social-group-line {
	margin-top: 25px;
	height: 28px;
	width: 100%;
}
.block-dashboard-shares .social-group {
	float: left;
	margin-right: 20px;
	height: 28px;
}
.block-dashboard-shares .social-group .social-facebook,
.block-dashboard-shares .social-group .social-twitter,
.block-dashboard-shares .social-group .social-linkedin {
	float: left;
	margin-right: 5px;
	width: 28px;
	height: 28px;
	cursor: pointer;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
.block-dashboard-shares .social-group .social-facebook {
	background-image: url(./img/ic_facebook.svg);
}
.block-dashboard-shares .social-group .social-twitter {
	background-image: url(./img/ic_twitter.svg);
}
.block-dashboard-shares .social-group .social-linkedin {
	background-image: url(./img/ic_linkedin.svg);
}

.block-dashboard-shares .social-group .count {
	font-size: 16px;
	line-height: 28px;
	color: rgba(8, 8, 8, 0.8);
}
 .block-community-temp-content {
	margin-top: 5px;
	height: 780px;
	background-image: url(../temp_img/temp_community.jpg);
	background-repeat: no-repeat;
}
.block-community-temp-text  {
	max-width: 800px;
	padding: 30px 0 80px 0;
}
.block-startup-team {
	padding: 18px 0 80px 0;
}
.block-startup-team-title .team-title {
	/*float: left;*/
    font-size: 28px;
    line-height: 22px;
    color: #2F3849;
    font-weight: 600;
}
.block-startup-team-title .button-add-members {
	/*float: left;*/
	/*margin-left: 20px;*/
	height: 28px;
	background: #2F3849;
	border-radius: 3px;
	cursor: pointer;
}
.block-startup-team-title .button-add-members > span {
	font-family: Stratos LC;
	font-size: 14px;
	line-height: 28px;
	text-align: center;
	color: #FFFFFF;
	padding: 0 10px;
}
.block-startup-team .block-team-instruct-text {
	clear: both;
	max-width: 480px;
}
.block-startup-team .block-team-instruct-text .insrtuct-title {
	font-size: 18px;
	line-height: 22px;
	color: #2F3849;
}
.block-startup-team .block-startup-team-list {
	margin-top: 8px;
	height: auto;

}
.block-startup-team .block-startup-team-list .team-card {
	float: left;
	margin-right: 20px;
	width: 210px;
	height: 345px;
	background-image: url(./tempImg/team_card.png);
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
