.block-tag-2-0 {
    /*display: block;*/
    float: left;
    vertical-align: top;
    margin: 0 7px 0 0;
    /*width: 100%;*/
}

.block-tag-2-0 a {
    color: #00F082;
    cursor: pointer;
    width: 100%;
    display: block;
}

.block-tag-2-0 a p  i {
    display: inline-block;
    font-size: 12px;
    transform: rotate(90deg);
    transition: .3s;
    height:6px;
    position: relative;
    left: 4px;
    top: 3px;
    margin: 0 8px 0 0;
}

.block-tag-2-0 a p {
    color: #00F082;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 18px;
    margin: 0 0 0 5px;
    font-weight: 300;
    display: inline-block;
    text-decoration: underline;
    transition: .3s;
    max-width: 200px;
    white-space: nowrap; /* Отменяем перенос текста */
    overflow: hidden; /* Обрезаем содержимое */
    text-overflow: ellipsis; /* Многоточие */
    text-align: left;
    width: 100%;
}

.block-tag-2-0:hover a {
    opacity: .6;
}

.block-tag-2-0:hover a p {
    opacity: .6;
}
