@import "../../CONST";

.add-expert-param-wrapper {
  @extend %white-bg-wrapper;
  .popup {
    width: 100%;
    max-width: 640px;

    .content {
      padding: 0!important;
      .add-expert-param-content {
        padding: 32px;
        .title {
          @extend %input-label-22;
          line-height: 28px;
          font-weight: 500;
        }
        .descr-wrapper {
          margin-top: 2px;
          &.descr {
            font-size: 16px;
            line-height: 22px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
        .list-parameters-wrapper {
          margin-top: 22px;
          border-top: 1px solid rgba(0, 0, 0, 0.3);
          .param-line {
            position: relative;
            padding: 13px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            cursor: pointer;
            .param-title {
              font-size: 20px;
              line-height: 26px;
              color: rgba(0, 0, 0, 0.8)
            }
            .add-button {
              position: absolute;
              right: 0;
              top: 50%;
              height: 36px;
              transform: translateY(-50%);
              background: transparent;
              border-radius: 6px;
              transition: 0.1s;
              padding: 0 24px;

              font-size: 14px;
              line-height: 36px;
              text-align: center;
              color: #2F3849;
              font-weight: 500;
            }
            &:hover {
              font-weight: 500;
              .add-button {
                color: #FFFFFF;
                background: #4B586E;
              }
            }
          }
        }
      }
    }

  }
}

@media (max-width: 756px) {
  .add-expert-param-wrapper {
    .popup {
      .content {
        .add-expert-param-content {
          padding: 16px;
        }
      }
    }
  }
}