%grey-title {
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.6);
}
%input-label {
  font-size: 14px;
  line-height: 24px;
  color: rgba(8,8,8,0.8);
  margin: 0 0 2px 0;
}
%block-descr-14 {
  font-size: 14px;
  line-height: 18px;
  color: rgba(0,0,0,0.75);
}
%block-descr-16 {
  font-size: 16px;
  line-height: 20px;
  color: rgba(0,0,0,0.75);
}
%menu-title {
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
}
%input-label-22 {
  font-size: 22px;
  line-height: 35px;
  color: rgba(8,8,8,0.8);
}

%white-bg-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  z-index: 10000;
}

%font-weight-500 {
  font-weight: 500;
}
%mt-5 {
  margin-top:5px;
}
%mt-7 {
  margin-top:7px;
}
%mt-25 {
  margin-top:25px;
}
%mt-60{
  margin-top: 60px;
}

$default-background: #FDFDFD;