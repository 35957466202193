.popup-overlay{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 2222222;
}
.popup-fc{
    position: fixed !important;
    z-index: 333333;
    transform: translateX(-50%) translateY(-50%);
    left: 50%;
    top: 50%;
}

.block-login-popup-container {
    position: relative;
    max-width: 542px;
    width: 95%;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 4px 16px rgba(0,0,0,0.15);
    padding: 80px 90px 105px 90px;
}
.block-login-popup-container .close {
    left: unset;
    right: 36px;
    top: 36px;
    opacity: 1;
}
.block-login-popup-container .close::before,
.block-login-popup-container .close::after {
    color: rgba(0,0,0,0.8);
}

.block-login-popup-container .login-popup-main-title {
    font-size: 38px;
    line-height: 40px;
    color: #080808;
    opacity: 0.8;
}

.block-login-popup-container .login-popup-descr {
    font-size: 16px;
    line-height: 22px;
    color: rgba(8,8,8,0.8);
}

.login-popup-button {
    padding: 0 30px;
}
.login-popup-button .block-button-3-0 {
    width: 100%;
}
.login-popup-button .block-button-3-0 p.block-button-3-0-title {
    font-weight: 500;
}
.block-button-popup-login .block-button-3-0 p.block-button-3-0-title {
    color: rgba(0,0,0,0.8);
}


@media all and (max-width: 540px) {
    .block-login-popup-container {
        padding: 80px 40px 105px 40px;
    }
}
@media all and (max-width: 440px) {
    .block-login-popup-container {
        padding: 80px 30px 105px 30px;
    }
    .login-popup-button {
        padding: 0 10px;
    }
}
@media all and (max-width: 380px) {
    .block-login-popup-container {
        padding: 80px 10px 105px 10px;
    }
    .login-popup-button {
        padding: 0 10px;
    }
}