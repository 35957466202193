html, body {
  font-family: "Stratos LC", sans-serif;
}
body.iosBugFixCaret {
  position: fixed;
  width: 100%;
}

.hide-highlight {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

.fullscreen{
  min-height: 100vh;
}

.mt-60{
  margin-top: 60px;
}
.mt-45{
  margin-top: 45px;
}

a{
  text-decoration: none;
  &:focus{
    outline: none;
  }
}

.gradient{
  position: relative;
  &::before{
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255,255,255,0) 100%);
    height: 300px;
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
  }

  & > * {
    z-index: 1;
    position: relative;
  }
}

.App {
  background-color: #FDFDFD;
  min-height: 100vh;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-up{
  animation: slide-up 0.4s ease;
}

//.App-logo {
//  animation: App-logo-spin infinite 20s linear;
//  height: 80px;
//}
//
//.App-header {
//  background-color: #222;
//  height: 150px;
//  padding: 20px;
//  color: white;
//}
//
//.App-title {
//  font-size: 1.5em;
//}
//
//.App-intro {
//  font-size: large;
//}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
//
//.padding-header {
//  padding-top: 110px;
//  min-height: calc(100vh - 110px);
//  padding-bottom: 78px;
//}

.no-underline {
  text-decoration: none;
}

.Modal {
  position: absolute;
  top: 40px;
  /*left: 40px;*/
  /*right: 40px;*/
  /*bottom: 40px;*/
  background-color: #f5f6f6;
  outline: none;
  border-radius: 16px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*background-color: black;*/
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999999;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ReactModalPortal .ReactModal__Overlay {
  transition: opacity 5000ms ease-in-out;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.clear,
.clearfix {
  clear: both;
}

.padding-40 {
  padding: 40px;
}

.m-top-30 {
  margin-top: 30px;
}

.fix-long-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.fix-long-text-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  box-shadow: inset 0px 0px 0px 9999px rgba(0,0,0,0.3);
  /*background-color: #000000;*/
  /*opacity: 0.5;*/
}

.text-center {
  text-align: center !important;
}
.text-bold {
  font-weight: 400;
}

h1 {
  font-size: 38px;
  line-height: 48px;
  color: rgba(0,0,0,0.8);
  font-weight: 500;
  margin: 0;
}
h2 {
  font-size: 29px;
  line-height: 38px;
  color: rgba(0,0,0,0.8);
  font-weight: 400;
  margin: 0;
}
h3 {
  font-size: 28px;
  line-height: 34px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
  margin: 0;
}
h4 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: rgba(0,0,0,0.8);
}
h5 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(0,0,0,0.8);
  margin: 0;
}
h6 {
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  font-weight: 400;
  margin: 0;
}


  /* Landscape phones and smaller */

@media (min-width: 320px) and (max-width: 479px) {
}

@media (min-width: 480px) and (max-width: 767px) {
}

@media (max-width: 767px) {
  h1 {
    font-size: 28px;
    line-height: 34px;
    color: #000000;
    font-weight: 500;
  }
  h2 {
    font-size: 24px;
    line-height: 30px;
    color: #000000;
  }
  h3 {
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    color: #000000;
  }
}

/* Landscape phones and portrait tablets */

@media (min-width: 768px) {
  .modal-list-projects {
    width: 880px;
  }
}

/* Large desktops and laptops */

@media (min-width: 1200px) {
}

.text-one-line {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block!important;
}

.break-word {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.padding-for-header {
  padding-top: 80px !important;
}

.fix-height-for-footer {
  min-height: calc(100vh - 185px);
}

.container-body {
  padding-top: 100px;
  padding-bottom: 40px;
  min-height: calc(100vh - 187px);
}

.block-react-disqus-comments {
  padding: 20px 0;
}

.tw-share,
.fb-share,
.li-share,
.copy-link {
  text-decoration: none;
}

.text-right {
  text-align: right;
}
.metamask-line {
  background-color: #000000;
  width: 100%;
  height: 25px;
  line-height: 25px;
  /*position: fixed;*/
  position: relative;
  top: 0;

  color: #FA4600;
  /*z-index: 4;*/
  z-index: 3;
  font-size: 14px;
  font-weight: 300;
}
.metamask-line span{
  color: #FFFFFF;
}
.metamask-line a {
  text-decoration: underline;
  color: #FA4600;
  cursor: pointer;
  transition: .3s;
}
.metamask-line a:hover {
  color: #FFFFFF;
}
.metamask-status {
  display: inline-block;
  width: 166px;
  height: 26px;
  float: right;
  margin: 14px 0;
  border-radius: 2px;
  border: 1px solid #4A4A4A;
  position: relative;
}
.metamask-indicator {
  display: inline-block;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: relative;
  top: 2px;
  left: 10px;
}
.metamask-indicator.green {
  background-color: #00F082;
}
.metamask-indicator.red {
  background-color: #FA4600;
}
.metamask-indicator.purple {
  background-color: #6e11f0;
}
.metamask-text {
  position: relative;
  top: 2px;
  color: #4A4A4A;
  font-size: 12px;
  left: 20px;
}
.editorClassName {
  border: 1px solid #F1F1F1;
}
.block-line-tags {
  height: 40px;
  border-bottom: 1px solid #979797;
  cursor: pointer;
}
.block-line-tags .blt-placeholder {
  color: rgba(74,74,74,0.7);
  position: relative;
  font-weight: 300;
  top: 12px;
  font-family: "Stratos LC";
  font-size: 13px;
}
.block-line-tags .line-tags {
  display: block;
  position: relative;
  top: 10px;
  white-space: nowrap; /* Отменяем перенос текста */
  overflow: hidden; /* Обрезаем содержимое */
  text-overflow: ellipsis; /* Многоточие */
  font-weight: 300;
  color: #4a4a4a;
    font-family: "Stratos LC";
    font-size: 13px;
}
.block-line-tags .line-tags.tags-white span {
  color: #ffffff;
}
.block-line-tags .line-tags span:after {
  content: '; ';
}
.block-line-tags .line-tags span:last-child:after {
  content: '';
}
/*.fr-wrapper>div>a { display: none!important; }*/

.container-local-loader {
  position: relative;
  min-height: 350px;
  margin-bottom: 50px;
}
.container-local-loader.cll-empty {
  min-height: auto;
}

.global-container-error {
  display: flex;
  min-height: 100vh;
  background-color: #4A4A4A;
  /*background-color: #002BDE;*/
  /*color: #00F082;*/
  color: #dad9d9;
  text-align: center;
  flex-direction: column;
  align-items: center;
  /*justify-content: center;*/
  padding-top: 10%;
}
.global-container-error .gce-logo {

}
.global-container-error .gce-logo img{
  width: 300px;
  margin-bottom: 40px;
}
.global-container-error .gce-text-top {
  font-size: 34px;
}
.global-container-error .gce-text-1 {
  font-size: 26px;
  padding-top: 12px;
}
.global-container-error .gce-back {
  padding-top: 60px;
}
.global-container-error .gce-back .block-button-2-0{
  margin-right: 20px;
}
.global-container-error .gce-link{
  padding-top: 30px;

}
.global-container-error .gce-link a{
  color: #5878ff;
}
.global-container-error .gce-temporary img {font-family: "Stratos LC", Arial, sans-serif;
  margin: 40px 0 0 0;
  width: 200px;
}
.user-link-default {
  text-decoration: none;
  color: inherit;
}
.avatar-flex-center {
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff!important;
  font-size: 30px;
  background-color: #2CA6EB;
  width: 100%;
  height: 100%;
}

.avatar-flex-center.min{
  border-radius: 50%;
  font-size: 20px;
}

i.liked{
  color: #E43A70 !important;
}
.min-height-100 {
  min-height: 100vh;
}
