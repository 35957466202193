.modal-login {
    width: 364px;
    font-family: "Stratos LC", sans-serif;
}
.modal-register {
    width: 702px;
}
.modal-login-block {
    padding: 8%;
    background-color: #ffffff;
    border-radius: 16px;
}

.modal-background-560{
    width: 560px;
}

.modal-login-block.fix-wallet{
    padding: 8% 8% 5% 8%;
}
.modal-login-block.modal-register-block {
    padding: 5%;
}
.modal-login .block-top {
    color: #4A4A4A;
    font-size: 30px;
    position: relative;
    padding-top: 10px;
}
.modal-login.modal-register .block-top {
    padding-top: 0px;
}
.modal-login .block-top img {
    position: absolute;
    top: -2px;
    right: -1px;
    width: 28px;
    height: 28px;
    cursor: auto;
    cursor: pointer;
}
.modal-login .block-right {

}
.modal-login .top-active {
    color: #000;
    font-weight: bold;
    font-weight: 500;
}
.modal-login .top-link {
    text-decoration: underline;
    cursor: pointer;
}
.modal-login .block-form {
    padding-top: 20px;
}
.modal-login .forgot_pass {
    color: #24C875;
    text-decoration: underline;
    cursor: pointer;
}
.modal-login-block .input-reg-dao{
    padding-bottom: 30px;
}
.modal-login .block-or {
    margin: 10px 0 20px 0;
    text-align: center;
}
.not_have {
    color: #4A4A4A;
    text-align: center;
    margin-top: 20px;
}
.get_started {
    margin-top: 8px;
    text-align: center;
    text-transform: uppercase;
    color: #24C875;
    text-decoration: underline;
    cursor: pointer;
}
.modal-login .login-form__btn-text {
    text-transform: none;
}
.modal-login .login-form__btn_telegram {
    background-color: #008FC2;
}
.modal-login .login-form__btn_fb.btn-google {
    text-transform: none;
    position: relative;
}
.login-form__btn_fb.btn-google {
    margin: 0;
}
.modal-login .login-form__btn_fb.btn-google i.fa{
    position: absolute;
    left: 18px;
}
.text-note {
    color: #4A4A4A;
    font-size: 8px;
    line-height: 10px;
    padding-top: 5px;
}
.modal-register .login-form__btn.login-form__btn_join {
    margin-bottom: 0;
}
.modal-register .login-form__devider {
    margin-top: 20px;
    margin-bottom: 10px;
}


.modal-login .reg_error_field.fix-error {
    position: relative;
    top: 0;
}
.modal-login .reg_error_field.fix-error-2 {
    position: relative;
    top: -10px;
}
.modal-login button {
    outline: none;
}
.modal-login .block-bottom-drop-list-dao {
    /*border-bottom: none;*/
}
.modal-login .block-drop-list-dao {
    padding-top: 4px;
}
.modal-login  .block-title-top-drop-list-dao {
    border-bottom: none;
}
.login-form__captcha {
    margin: 0px 0 20px 0;
}
.modal-login .Select-placeholder {
    color: #BBBBBB;
    font-size: 14px;
    padding-left: 0;
}

.modal-login .Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
    padding-left: 0;
}
.Select-placeholder, .Select--single > .Select-control .Select-value{
    padding: 0!important;
}
.Select-placeholder, .Select--single > .Select-control .Select-value{
    font-size: 13px!important;
}

.modal-login .block-list-countries .Select-value-label {
    color: #4A4A4A!important;
}
.sr-title {
    padding-right: 10%;
    color: #000000;
    font-size: 30px;
}
h1.sr-title {
    font-weight: inherit;
    margin: inherit;
}
.sr-description {
    color: #4A4A4A;
    padding-top: 20px;
    font-size: 17px;
    line-height: 21px;
}
.line-get-tokens {
    height: 35px;
}
.line-get-tokens .number-get-tokens {
    color: #0028DC;
}
.modal-login .block-top.fix {
    padding-top: 0;
    min-height: 30px;
}
.login-form__btn.login-form__btn_join.btn-change-pass {
    margin-top: 20px;
}
.new-pass-page {
    margin: 0 auto;
    padding-top: 5%;
}

.block-list-countries{}
.block-list-countries .Select-input{
    padding: 0!important;
}
.block-list-countries .Select-placeholder{
    font-size: 13px;
    line-height: 36px;
    font-weight: 300;
    color: #BBBBBB;
}
.block-list-countries .Select-menu-outer{
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 35px;
    font-weight: 300;
}
.block-list-countries .Select-menu-outer .VirtualizedSelectOption{
    cursor: pointer;
}
.block-list-countries .Select-menu-outer .VirtualizedSelectOption:hover{
    background: #BBBBBB;
}
.block-list-countries .Select-input > input{
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 25px;
    font-weight: 300;
}


.block-list-countries .Select-arrow-zone{
    padding: 0;
    width: 36px;
    background: #0028DC;
    height: 36px;
    position: relative;
}

.block-list-countries .Select-control{
    border-bottom: 1px solid #979797;
}


.block-list-countries .Select-arrow{ opacity: 0}
.block-list-countries .Select-arrow-zone:after{
    content: '';
    width: 16px;
    height: 2px;
    background: #FFFFFF;
    position: absolute;
    top: 17px;
    left: 4px;
    transition: .3s;
}
.block-list-countries .Select-arrow-zone:before{
    content: '';
    width: 16px;
    height: 2px;
    background: #FFFFFF;
    position: absolute;
    top: 17px;
    left: 15px;
    transition: .3s;
}
.block-list-countries .Select.is-open > .Select-control .Select-arrow-zone:after{
    transform: rotate(-45deg);
}
.block-list-countries .Select.is-open > .Select-control .Select-arrow-zone:before{
    transform: rotate(45deg);
}
.block-list-countries .Select.is-open > .Select-control .Select-arrow-zone:hover:after{
    transform: rotate(-40deg);
}
.block-list-countries .Select.is-open > .Select-control .Select-arrow-zone:hover:before{
    transform: rotate(40deg);
}
.block-list-countries .Select-arrow-zone:after{
    transform: rotate(45deg);
}
.block-list-countries .Select-arrow-zone:before{
    transform: rotate(-45deg);
}
.block-list-countries .Select-arrow-zone:hover:after{
    transform: rotate(40deg);
}
.block-list-countries .Select-arrow-zone:hover:before{
    transform: rotate(-40deg);
}
.ReactVirtualized__Grid{

}
.ReactVirtualized__Grid::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}

.ReactVirtualized__Grid::-webkit-scrollbar
{
    width: 8px;
    background-color: #F5F5F5;
}

.ReactVirtualized__Grid::-webkit-scrollbar-thumb
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #0000D3;
}

.link-pass-kyc-center{
    display: block;
    color: #0028DC;	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;	text-align: center; margin: 10px 0 0 0;
    text-decoration: underline;
    cursor: pointer;
}








.shell-list-img-background{
    width: 100%;
    overflow: hidden;
}
.block-list-img-background{
    width: 100%;
    overflow: hidden;
    padding: 40px 0 0 0;
}

.block-item-img-background{
    height: 50px;
    width: calc((100% / 3) - (10px * 2));
    margin: 0 10px 10px 0;
    box-sizing: border-box;
    position: relative;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid transparent;
    transition: .3s;
    display: inline-block;
    cursor: pointer;
    width: 150px;
}
.block-item-img-background:nth-child(3n){
    margin: 0 0 10px 0;
}
.block-item-img-background img{
    position: absolute;
    width: 100%;
    height: 75px;
    display: block;
    background: #1b1b1b;
    top: 0;
    left: 0;
}
.block-item-img-background:hover{
    border: 1px solid #00F082;
}
.block-item-img-background.active:hover{
    border: 1px solid transparent;
}

.block-item-img-background i{
    height: 24px;
    width: 24px;
    position: absolute;
    right: 13px;
    top: 13px;
    color: #00F082;
    font-size: 24px;
    opacity: 0;
    transition: .3s;
}

.block-item-img-background.active{}
.block-item-img-background.active i{
    opacity: 1;
}

.block-item-img-background.add-new{
    border: 1px solid #979797;
}
.block-item-img-background.add-new:after{
    width: 35px;
    height: 1px;
    background: #979797;
    content: '';
    position: absolute;
    transition: .3s;
    left: calc(50% - 17px);
    top: calc(50% - 1px);
}
.block-item-img-background.add-new:before{
    width: 35px;
    height: 1px;
    background: #979797;
    content: '';
    position: absolute;
    transition: .3s;
    transform: rotate(90deg);
    left: calc(50% - 17px);
    top: calc(50% - 1px);
}
.block-item-img-background.add-new:hover{
    border: 1px solid #00F082;
}
.block-item-img-background.add-new:hover:after{
    background: #00F082;
}
.block-item-img-background.add-new:hover:before{
    background: #00F082;
}


.logo-create-fund-modal{
    height: 87px;	width: 87px;	box-shadow: 0 0 2px 0 rgba(0,0,0,0.5);
    display: block;
    background: #0000d3;
}

.title-modal-big{
    color: #000000;	font-family: "Stratos LC";	font-size: 30px;	letter-spacing: -0.64px;	line-height: 38px; margin: 20px 0 ;
}
.title-modal-small{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	line-height: 20px; margin: 0;
}
.block-group-title-state{margin: 20px 0; display: flex}
.block-title-group{
    width: calc(100% - 40px);
    float: left;
}
.block-title-group p.title-group{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 18px;	letter-spacing: -0.17px;	line-height: 20px; margin: 0;
}
img.logo-meta-mask{
    height: 20px;	width: 20px;
    margin: 0 0 0 10px;
    position: relative;
    top: 5px;
}
.block-title-group p.description-group{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	line-height: 20px; margin: 0;
}
.block-title-group p.description-group.disabled {
    color: #bcbcbc;
}
.block-title-group p.description-group.disabled span{
    text-decoration: underline;
}

.block-title-group p.description-group a{
    color: #0028DC; text-decoration: underline;
}
.blokc-state-group{
    width: 40px;
    float: right;
}
.blokc-state-group span.circle-state-modal{
    height: 10px;	width: 10px;
    border-radius: 50%;display: block;
    margin: 5px 0 0 0;
    float: right;
}
.blokc-state-group span.circle-state-modal.state-green{background-color: #00F082;}
.blokc-state-group span.circle-state-modal.state-gray{	background-color: #CCCCCC;}

.blokc-state-group .shell-check-box-control{
    float: right;
}

.modal-check-access .block-title-group p.description-group {
    line-height: 16px;
    margin-bottom: 5px;
}
.modal-new-border{
    width: 754px!important;
    border: 3px solid #00F082;
    border-radius: 3px;
}

.block-modal-img-title-buttons{
    width: 100%;
    display: table;
    padding: 40px 0 0 0;
}
.block-modal-img-title-buttons .block-img{
    width: 156px;
    float: left;
}
.block-modal-img-title-buttons .block-title-buttons{
    width: calc(100% - 156px);
    padding: 0 0 0 25px;
    float: left;
}
.block-modal-img-title-buttons .block-title-buttons p.title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 20px;	letter-spacing: -0.43px;	line-height: 28px;
    margin: 0 0 20px 0; font-weight: 400;
}
.block-modal-img-title-buttons .block-buttons{
    display: table;
    width: 100%;
}
.block-modal-img-title-buttons .block-buttons .block-button-left{
    width: 49%;
    float: left;
}
.block-modal-img-title-buttons .block-buttons .block-button-right{
    width: 49%;
    float: right;
}
.block-modal-img-title-buttons .block-buttons .block-button-2-0{
    border-radius: 2px;
}


.block-modal-doc-reg-expert{
    width: 80%;
    max-width: 1000px;
    height: 90vh;
    position: absolute;
    top: 5vh;
    border-radius: 12px 12px 0 0;	background-color: #FFFFFF;
}


.block-bottom-doc-reg-expert{
    width: 100%;
    height: 100px;
    display: table;
    position: absolute;
    bottom: 0;
    padding: 24px 32px 0 32px;
}
.block-bottom-doc-reg-expert .button-3-0-gray{
    float: left;
}
.block-bottom-doc-reg-expert .button-3-0-blue{
    float: right;
}


.block-top-doc-reg-expert{
    padding: 32px 32px 0 32px;
}
.block-top-doc-reg-expert p.title-top-doc{
    color: rgba(8,8,8,0.8);
    margin: 0;
}

.block-body-doc-reg-expert{
    height: calc(90vh - 180px);
    padding: 32px 32px 0 32px;
    position: relative;
}
.block-body-doc-reg-expert:after{
    content: '';
    position: absolute;
    height: 30px;	width: 100%;	background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
    bottom: 0;
    left: 0;
}
.block-body-doc-reg-expert p.description{
    color: rgba(8,8,8,0.8);
    font-family: "Stratos LC";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    position: relative;
    padding: 0 15px 0 0;
}
.block-body-doc-reg-expert p.description.bold{
    font-weight: 500;
}
.block-body-doc-reg-expert p.description.dot{
    padding: 0 0 0 50px;
}
.block-body-doc-reg-expert p.description.dot:after{
    content: '';
    position: absolute;
    top: 7px;
    left: 30px;
    width: 9px;
    height: 9px;
    background: rgba(8,8,8,0.8);
    border-radius: 50%;
}
.block-body-doc-reg-expert p.description a{
    color: #2CA6EB;
}

.modal-error-404-wrapper {
    min-height: 100vh;
    padding: 120px 15px;
    overflow-x: hidden;
}
.modal-error-404-wrapper .modal-error-404-bg {
    width: 1250px;
    height: 250px;
    background-image: url('img/error-404-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    margin: -103px -200px 0 0;
    float: right;
}
.modal-error-404-wrapper .modal-error-404-bg-person {
    position: absolute;
    bottom: -180px;
    right: -255px;
    width: 255px;
    height: 230px;
    background-image: url('img/error-404-person.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

}
.modal-error-404-wrapper .modal-error-404-block {
    position: relative;
    margin: 0 auto;
    padding: 37px 50px;
    max-width: 412px;
    height: 311px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 0px 4px 32px rgba(0,0,0,0.25);
}
.modal-error-404-block .modal-error-404-button .block-button-3-0.button-3-0-green {
    height: 50px;
}
.modal-error-404-block .modal-error-404-button .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    line-height: 50px;
    font-weight: 500;
}
.modal-error-404-block .text-error-404 {
    font-size: 70px;
    line-height: 88px;
    letter-spacing: -0.541789px;
    color: #2f3849;
}
.modal-error-404-block .text-error-404-descr {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.154797px;
    color: rgba(8,8,8,0.8);
}

.modal-error-404-block .error-404-descr-padding {
    padding: 0 50px 33px 50px;
}



@media all and (min-width: 1000px) {
    .modal-error-404-wrapper .modal-error-404-bg {
        margin-right: 0px;
    }
}
@media all and (min-width: 1250px) {
    .modal-error-404-wrapper .modal-error-404-bg {
        width: 100%;
    }
}
@media all and (min-width: 1500px) {
    .modal-error-404-wrapper .modal-error-404-bg {
        height: 300px;
        margin-top: -153px;
    }
}
@media all and (min-width: 1750px) {
    .modal-error-404-wrapper .modal-error-404-bg {
        height: 350px;
        margin-top: -203px;
    }
}
@media all and (min-width: 2000px) {
    .modal-error-404-wrapper .modal-error-404-bg {
        height: 400px;
        margin-top: -253px;
    }
}
@media all and (max-width: 850px) {
    .modal-error-404-wrapper .modal-error-404-bg-person {
        right: -155px;
    }
}
@media all and (max-width: 710px) {
    .modal-register {
        width: 356px;
    }
}
@media all and (max-width: 650px) {
    .modal-error-404-wrapper .modal-error-404-bg-person {
        right: -100px;
        bottom: -238px;
    }
    .modal-error-404-wrapper .modal-error-404-bg {
        margin: -45px -250px 0 0;
    }
}
@media all and (max-width: 600px) {
    .modal-error-404-wrapper .modal-error-404-bg-person {
        right: -65px;
    }
}
@media all and (max-width: 570px) {
    .modal-background-560 {
        width: 356px;
        margin-bottom: 0px;
    }
    .modal-login-block {
        padding: 5%;
    }
    .block-item-img-background:nth-child(2n) {
        margin: 0 0 10px 0;
    }
    .block-item-img-background:nth-child(3n) {
        margin: 0 10px 10px 0;
    }
}
@media all and (max-width: 530px) {
    .block-bottom-doc-reg-expert.bottom-doc-reg-adaptive {
        text-align: center;
    }
    .block-bottom-doc-reg-expert.bottom-doc-reg-adaptive .block-button-3-0 {
        float: none;
        width: 100%;
        margin-bottom: 8px;
    }

}
@media all and (max-width: 450px) {
    .modal-error-404-wrapper .modal-error-404-block {
        width: 262px;
        height: 232px;
        padding: 25px 28px;
    }
    .modal-error-404-block .text-error-404 {
        font-size: 44px;
        line-height: 55px;
        letter-spacing: -0.340553px;
    }
    .modal-error-404-block .text-error-404-descr {
        font-size: 15px;
        line-height: 19px;
        letter-spacing: -0.116098px;
    }
    .modal-error-404-block .error-404-descr-padding {
        padding: 8px 15px 26px 15px;
    }

}