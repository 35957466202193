.block-button-2-0 {
    transition: .3s;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-radius: 50px!important;
}

.block-button-2-0 p {
    font-family: "Stratos LC";
    text-align: center;
    margin: 0;
    transition: .3s;
    cursor: pointer;
    display: block!important;
}

.button-2-0-big {
    height: 40px;
    vertical-align: top;

}

.button-2-0-big p {
    font-weight: 300 !important;
    line-height: 40px !important;
    font-size: 16px !important;
}

.button-2-0-small {
    height: 32px;
    vertical-align: top;

}

.button-2-0-small p {
    font-weight: 300 !important;
    line-height: 32px !important;
    font-size: 13px !important;
}

.button-2-0-green {
    background: #1BC8A7;
}

.button-2-0-green p {
    color: #FFFFFF;
}

.button-2-0-green:hover {
    background: #2AA2E6;
}

.button-2-0-green:hover p {
    color: #ffffff;
}

.button-2-0-blue {
    background: #2AA2E6;
    cursor: pointer;
}

.button-2-0-blue p {
    color: #ffffff;
}

.button-2-0-blue:hover {
    background: #1BC8A7;
}

.button-2-0-blue:hover p {
    color: #FFFFFF;
}

.button-2-0-red {
    background: #FF713F;
}

.button-2-0-red p {
    color: #ffffff;
}

.button-2-0-red:hover {
    background: #1BC8A7;
}

.button-2-0-red:hover p {
    color: #FFFFFF;
}

.button-2-0-trans-black {
    background: none;
    border: 1px solid #4A4A4A;
}

.button-2-0-trans-black p {
    color: #4A4A4A;

}

.button-2-0-trans-black:hover,
.button-2-0-trans-black.active {
    background: none;
    border: 1px solid #2AA2E6;
}

.button-2-0-trans-black:hover p,
.button-2-0-trans-black.active p {
    color: #2AA2E6;
}

.button-2-0-trans-blue {
    background: none;
    border: 1px solid #2AA2E6;
}

.button-2-0-trans-blue p {
    color: #2AA2E6;
}

.button-2-0-trans-blue:hover {
    background: none;
    border: 1px solid #4A4A4A;
}

.button-2-0-trans-blue:hover p {
    color: #4A4A4A;
}


.button-2-0-trans-white {
    background: none;
    border: 1px solid #ffffff;
}

.button-2-0-trans-white p {
    color: #ffffff;
}

.button-2-0-trans-white:hover {
    background: none;
    border: 1px solid #1BC8A7;
}

.button-2-0-trans-white:hover p {
    color: #1BC8A7;
}

.react-datepicker-popper {
    z-index: 999!important;
}

.react-datepicker {
    border-radius: 0 !important;
}

.react-datepicker__header {
    background-color: #545454 !important;
    border-radius: 0;
}

.react-datepicker__month {
    background: #7B7B7B;
    border-radius: 0;
}

.react-datepicker__month-container {
    background: #7B7B7B;
    border-radius: 0;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color: #7B7B7B !important;
}

.react-datepicker__current-month, .react-datepicker-time__header {
    color: #ffffff !important;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #ffffff !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: #1BC8A7 !important;
    border-radius: 50% !important;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before, .react-datepicker__month-read-view--down-arrow::before, .react-datepicker__month-year-read-view--down-arrow::before {
    border-top-color: #7B7B7B !important;
}

.react-datepicker__day:hover {
    border-radius: 50% !important;
    background-color: #2AA2E6 !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
    background-color: #1BC8A7 !important;
    border-radius: 50% !important;
}
.react-datepicker__year-read-view--selected-year {
    color: #ffffff;
    position: relative;
    top: 3px;
}
.react-datepicker__day--disabled {
    cursor: default;
    color: #969696!important;
}


.anim-button-2-0{
    position: absolute;
    overflow: hidden;
    z-index: 2;
    width: 100%;
    height: 40px;
    top: 0;
    left: 0;
}
.anim-button-2-0:before {
    content: '';
    position: absolute;
    display: block;
    background: var(--ripple-background, white);
    border-radius: 50%;
    pointer-events: none;
    top: calc(var(--y) * 1px);
    left: calc(var(--x) * 1px);
    width: calc(var(--d) * 1px);
    height: calc(var(--d) * 1px);
    opacity: calc(var(--o, 1) * var(--ripple-opacity, 0.3));
    transition: calc(var(--t, 0) * var(--ripple-duration, 600ms)) var(--ripple-easing, linear);
    -webkit-transform: translate(-50%, -50%) scale(var(--s, 1));
    transform: translate(-50%, -50%) scale(var(--s, 1));
    -webkit-transform-origin: center;
    transform-origin: center;
}
.anim-button-2-0-blue:before{
    background: var(--ripple-background, #2AA2E6)!important;
}



.button-2-0-big.button-2-0-trans-black p{line-height: 38px!important;}
.button-2-0-big.button-2-0-trans-blue p{line-height: 38px!important;}
.button-2-0-big.button-2-0-trans-white p{line-height: 38px!important;}


.button-2-0-trans-blue.button-disabled{
    border-color: #979797;
}
.button-2-0-trans-blue.button-disabled p{
    color: #979797;
}

.button-2-0-trans-black .button-disabled{}
.button-2-0-trans-white .button-disabled{}
.button-enabled{

}

.disabled-button{background-color: #9B9B9B!important;}
.disabled-button p{color: #FFFFFF!important;}
.disabled-button:hover{background-color: #9B9B9B!important;}
.disabled-button:hover p{    color: #FFFFFF!important;}
