.block-footer.new{
  padding: 68px 0 48px 0;

  .footer-new-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 62px;
  }

  .logo-footer{
    img{
      height: 27px;
    }
  }

  .footer-new-subscribe {
    position: relative;
    max-width: 410px;
    width: 100%;
    display: flex;
  }
  .footer-new-subscribe-elem {
    width: 49%;
    border-radius: 6px 0 0 6px;
    height: 41px;
    background-color: #fff;
    color: rgba(0,0,0,.8);
    font-family: "Stratos LC", sans-serif;
    font-size: 14px;
    line-height: 41px;
    margin: 0;
    padding: 0 15px;
    border: 0;
    outline: none;
  }
  .footer-new-subscribe-submit {
    width: 51%;
    border-radius: 0 6px 6px 0;
    white-space: nowrap;
    height: 41px;
    background-color: #0ECFAA;
    float: left;
    cursor: pointer;
    color: #FFFFFF;
    font-family: "Stratos LC", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 41px;
    text-align: center;
    transition: background-color 0.3s ease-in-out;
    margin: 0;
    &:hover {
      background-color: #1BC8A7;
    }
  }


  .footer-menu-col {
    margin-bottom: 36px;
  }

  .block-col-link-list{
    margin: 0 auto 26px;
    .title-link-list {
      font-family: "Stratos LC", sans-serif;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 11px 0;
      color: #FFFFFF;
    }
    a {
      font-family: "Stratos LC", sans-serif;
      font-size: 12px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.6);
      display: block;
      font-weight: 300;
      margin: 0 0 7px 0;
      transition: color 0.3s ease-in-out;
      &[href]:hover {
        color: #0ECFAA;
      }
    }
    a.title-link-list {
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }
    .link-list-email {
      color: #0ECFAA;
      &:hover {
        text-decoration: underline;
      }
    }
    &.block-col-link-list-soon {
      color: rgba(255, 255, 255, 0.3);
      p, a {
        color: rgba(255, 255, 255, 0.3);
        &:hover {
          color: rgba(255, 255, 255, 0.3);
        }
      }
    }
  }

  @media (max-width: 577px) {
    .block-col-link-list.register-list,
    .block-col-link-list.evaluated-list {
      margin: 0;
      width: unset;
    }
  }
  .block-list-social-link{
    max-width: 130px;
    .social-button{
      margin: 0 15px 15px 0;
      a i {
        font-size: 21px;
      }
    }
  }
  .active-social-button a:hover,
  .active-social-button a i:hover {
    color: #0ECFAA;
  }


  .footer-bottom {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }

  .footer-tap2pay-img {
    max-width: 300px;
    width: 100%;
  }

  .footer-copyright {
    display: flex;
    font-family: 'Stratos LC', sans-serif;
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.4);
    margin: 0 0 6px;
    p {
      margin: 0;
    }
    a {
      color: #0ECFAA;
      margin: 0 0 0 28px;
    }
    .links-wrapper {
      display: inline-block;
    }
  }
  .T2P-wrapper {
    margin-top: 28px;
  }
  .T2P-wrapper a img {
    height: 38px;
  }
}


@media (max-width: 899px) {
  .footer-menu-col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
}

@media (max-width: 767px) {

  .block-footer.new {
    padding: 68px 0 38px 0;
  }
  .block-footer.new .footer-new-top {
    display: block;
    text-align: center;
    margin-bottom: 70px;
  }
  .logo-footer {
    margin-bottom: 50px;
  }
  .block-footer.new .footer-new-subscribe {
    margin: 0 auto;
  }

  .footer-menu-col {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .block-footer.new .block-col-link-list {
    margin: 0 auto 32px;
  }
  .block-footer.new .footer-menu-col {
    margin-bottom: 50px;
  }

  .block-footer.new .footer-bottom {
    display: block;
    text-align: center;
  }
  .block-footer.new .footer-copyright {
    justify-content: center;
    a {
      color: #0ECFAA;
      margin: 0 14px 0 18px;
    }
  }

  .footer-tap2pay-link {
    margin: -8px 0 32px;
    display: inline-block;
  }
}

@media (max-width: 567px) {
  .block-footer.new .container {
    max-width: none;
  }
}


@media (max-width: 479px) {
  .block-footer.new .footer-new-subscribe {
    max-width: none;
    width: 100%;
    display: block;
  }
  .block-footer.new .footer-new-subscribe-elem {
    width: 100%;
    border-radius: 6px 6px 0 0;
    text-align: center;
  }
  .block-footer.new .footer-new-subscribe-submit {
    width: 100%;
    border-radius: 0 0 6px 6px;
    float: none;
  }

  .footer-menu-row {
    justify-content: space-between;
  }
  .footer-menu-col {
    flex: 1 0 auto;
    max-width: 180px;
  }
}


@media (max-width: 399px) {

  .footer-menu-col {
    max-width: 150px;
  }
}