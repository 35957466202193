.buttonWithTerms{
  .text{
    margin-top: 8px;

    font-size: 10px;
    font-style: normal;
    font-weight: normal;
    line-height: 13px;
    text-align: center;

    @media (max-width: 750px) {
      margin-bottom: 20px;
    }


    &._black{
      color: rgba(0, 0, 0, 0.6);
    }

    &._white{
      color: rgba(255, 255, 255, 0.6);
    }

    a{
      font-size: inherit;
      font-style: inherit;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
      cursor: pointer;
      text-decoration: underline;
    }

  }
}