.view-eval-left-bar {
    width: 100%;
    padding: 30px 45px 30px 45px;
    background-color: #F6F6F6;
    min-height: 100%;
}
.view-eval-left-bar .avatar {
    width: 140px;
    height: 140px;
    border-radius: 6px;
    background-size: cover!important;
    background-repeat: no-repeat!important;
    background-position: 50% 50%!important;
}
.view-eval-left-bar .startup-profile {
    padding-bottom: 18px;
    border-bottom: 1px solid #D8D8D8;
}
.view-eval-left-bar .startup-profile .startup-name-block {
    max-height: 40px;
    overflow-y: hidden;
}


/*Expert Review Score*/

.expert-review-score {
    height: 175px;
    width: 100%;
    max-width: 300px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px rgba(0,0,0,0.03);
    border-radius: 6px;
    padding: 20px 14px 0 14px;
}
.expert-review-score .review-main-title {
    font-size: 16px;
    line-height: 18px;
}
.expert-main-score-block {
    padding-bottom: 12px;
    border-bottom: 1px solid #D9D9D9;
}
.expert-review-score .review-score {
    display: inline-block;
}
.expert-main-score {
    font-size: 38px;
    line-height: 48px;
    color: #0ECFAA;
    width: 51px;
}
.expert-main-score-ten {
    font-size: 16px;
    line-height: 14px;
    width: 25px;
    color: rgba(0,0,0,0.5);
}
.expert-review-score .second-score-block {
    width: 43px;
}
.expert-review-score .expert-second-score {
    font-size: 18px;
    line-height: 23px;
}
.expert-review-score .main-score-title {
    font-size: 14px;
    line-height: 18px;
}
.expert-review-score .second-score-title {
    font-size: 10px;
    line-height: 12px;
}


/*QUESTIONREVIEW*/

.block-question-review {
    width: 100%;
    min-height: 61px;
    border-bottom: 1px solid #ECECEC;
}
.block-question-review .block-button-open-close {
    height: 61px;
    margin-left: 30px;
}
.block-question-review .value-name-text {
    font-size: 18px;
    line-height: 61px;
}
.block-question-review .left-block-value-name {
    float: left;
    width: calc(100% - 120px);
}

.block-question-review .block-button-open-close  i {
    height: 61px;
    line-height: 61px;
}
.block-question-review .open-close-block-description-item-work-experience .answer-block {
    padding-left: 10px;
    border-left: 2px solid #2f3849;
    white-space: pre-wrap;
}
.block-question-review .open-close-block-description-item-work-experience .expert-review-block {
    padding-left: 10px;
    border-left: 2px solid #0ECFAA;
}
.block-question-review .open-close-block-description-item-work-experience .expert-review-text {
    font-size: 14px;
    line-height: 14px;
    color: #0ECFAA;
}
.block-value-eval-number {
    height: 61px;
}
.block-value-eval-number .eval-num-text {
    font-size: 24px;
    line-height: 61px;
    color: #2f3849;
}
.block-value-eval-number .eval-num-text span,
.block-param-total-score .total-score-text span {
    font-size: 10px;
    line-height: 14px;
    color: rgba(0,0,0,0.5);
    /*opacity:0.8;*/
}

.block-evaluation-view-comments {
    padding: 0 25px;
}
.block-evaluation-view-comments p.description-item-comments span.like-comments {
    right:60px!important;
    width: 20px;
}
.block-evaluation-view-comments .like-count {
    right: -10px;
}

/*Preliminary evaluation table*/
.block-preliminary-evaluation-table {
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px rgba(0,0,0,0.03);
    border-radius: 6px;
    max-width: 700px;
    padding: 25px 26px 55px 33px;
}
.table-title {
    font-size: 12px;
    line-height: 35px;
    color: rgba(0,0,0,0.5);
}
.name-col-text {
    font-size: 18px;
    line-height: 60px;
}
.info-col-text {
    font-size: 12px;
    line-height: 60px;
}
.block-preliminary-evaluation-table .evaluation-table-row {
    height: 60px;
    border-top: 1px solid #ECECEC;
}
.block-preliminary-evaluation-table .evaluation-table-row .fa-chevron-down {
    transform: rotate(-90deg);
    margin-left: 6px;
}
.eval-table-main-title {
    font-size: 38px;
    line-height: 48px;
}
.text-send-date {
    font-size: 10px;
    line-height: 13px;
    color: #979797;
}
.text-conducted {
    font-size: 16px;
    line-height: 23px;
}
.expert-total-score {
    border: 1px solid rgba(0,0,0,0.25);
    max-width: 200px;
    position: relative;
    padding: 20px 14px 0 14px;
    height: 130px;
    width: 200px;
}
.expert-total-score .expert-main-score {
    font-size: 36px;
    line-height: 38px;
    margin-left: 23px;
}
.block-info-icon-absolute {
    position: absolute;
    top: 10px;
    right: 11px;
    margin: 0!important;
}
.text-swipe-descr {
    font-size: 12px;
    line-height: 21px;
    color: rgba(0,0,0,0.5);
    padding-left: 42px;
}
.total-score-swipe {
    margin-top: 6px;
}
.total-score-swipe .block-swipe-blue,
.total-score-swipe .block-swipe-blue.active {
    background-color: rgba(0,0,0,0.5);
    width: 33px;
    height: 18px;
    border: none;
}
.total-score-swipe .block-swipe-blue:after {
    background-color: #FFF;
    height: 14px;
    width: 14px;
}
.total-score-swipe .block-swipe-blue.active:after {
    background-color: #0ECFAA;
    left: 17px;
}
.table-col-1 {
    max-width: 194px;
    min-width: 100px;
}
.table-col-2 {
    max-width: 104px;
}
.table-col-2-full {
    max-width: 335px;
}
.table-col-3 {
    max-width: 325px;
    min-width: 320px;
}
.table-col-4 {
    max-width: 15px;
}
.table-col-3 .block-avatar {
    max-width: 32px;
    height: 32px;
    border-radius: 4px;
    background-repeat: no-repeat!important;
    background-position: 50%!important;
    background-size: cover!important;
}
.table-col-3 .text-block {
    max-width: 285px;
    padding-left: 9px;
}

.text-block .expert-name-text {
    font-size: 16px;
    line-height: 20px;
}
.text-block .text-40-symbols {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0,0,0,0.5);
}
.table-top-right-col {
    max-width: 200px;
}
.table-top-left-col .block-button-3-0.button-3-0-green {
    background-color: #2f3849;
    border-radius: 3px;
    height: 34px;
    margin-top: 18px;
    width: 168px;
}
.table-top-left-col .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    font-size: 14px;
    line-height: 34px;
    font-weight: 300;
    letter-spacing: -0.505952px;
}
.preliminary-evaluation-table-top {
    justify-content: space-between;
    padding-bottom: 20px;
}
.expert-total-score-full {
    height: 115px;
    padding: 27px 14px 0 14px;
}
.expert-total-score-full .expert-main-score-block {
    border-bottom: none;
}
.preliminary-evaluation-table-full {
    max-width: 942px;
}
.average-rating-line {
    position: relative;
    width: 260px;
    height: 4px;
    border-radius: 3px;
    background-color: #ECECEC;
    float: right;
    margin-top: 28px;
    margin-right: 40px;
}
.average-rating-line .active {
    position: absolute;
    height: 4px;
    left: 0;
    top: 0;
    border-radius: 3px;
}
.block-evaluation-view-leftbar {
    background-color: #F6F6F6;
}


.color-rgba-88 {
    color: rgba(8,8,8,0.8)!important;
}
.margin-top-10 {
    margin-top: 10px;
}
.padding-top-5 {
    padding-top: 5px!important;
}
.padding-bottom-15 {
    padding-bottom: 15px!important;
}

@media screen and (max-width: 1200px) {
    .block-comments {
        width: 100%!important;
        margin: 0!important;
    }
}
@media screen and (min-width: 690px) {
    .view-eval-left-bar {
        width: 225px;
        padding: 30px 10px 0 15px;
    }
    .block-evaluation-view-leftbar {
        max-width: 225px;
    }
    .block-main-parameter-review {
        width: calc(100% - 225px);
    }
    .block-main-parameter-review .preliminary-evaluation-title,
    .block-main-parameter-review .parameter-name-title {
        text-align: left!important;
    }
    .block-evaluation-view-comments {
        padding: 0;
    }
}
@media screen and (min-width: 820px) {
    .view-eval-left-bar {
        width: 265px;
        padding: 30px 30px 0 35px;
    }
    .block-evaluation-view-leftbar {
        max-width: 265px;
    }
    .block-main-parameter-review {
        width: calc(100% - 265px);
    }
}
@media screen and (min-width: 1100px) {
    .view-eval-left-bar {
        width: 265px;
    }
    .block-evaluation-view-leftbar {
        max-width: 265px;
    }
    .block-preliminary-evaluation-view .block-main-parameter-review {
        margin-left: calc(25% - 265px);
    }
    .block-main-parameter-review {
        width: auto;
    }
}

@media screen and (max-width: 655px) {
    .eval-table-main-title {
        font-size: 28px;
        line-height: 36px;
    }
    .block-preliminary-evaluation-table {
        padding: 25px 15px 55px 15px;
    }
    .block-container-expert .block-preliminary-evaluation-table {
        padding: 25px 0 55px 0;
    }
}


@media screen and (max-width: 940px) {
    .preliminary-evaluation-table-full {
        max-width: 700px;
    }
    .table-col-2-full {
        max-width: 104px;
    }
    .average-rating-line {
        display: none;
    }
}
@media screen and (max-width: 700px) {
    .preliminary-evaluation-table-top {
        padding-bottom: 50px;
    }
    .evaluation-table-top-row {
        width: 320px;
        justify-content: space-between;
    }
    .evaluation-table-top-row .table-col-3.table-title {
        display: none;
    }
    .block-preliminary-evaluation-table .evaluation-table-row {
        height: 105px;
        width: 320px;
        justify-content: space-between;
        position: relative;
    }
    .evaluation-table-row,
    .evaluation-table-top-row {
        margin: 0 auto;
    }
    .name-col-text {
        line-height: 48px;
    }
    .table-col-3 > div {
        margin-top: 0;
    }
    .table-col-2-full {
        max-width: 50px;
    }
    .info-col-text {
        line-height: 105px;
    }
    .table-col-4 {
        position: absolute;
        right: 0;
    }
}
@media screen and (max-width: 735px) and (min-width:690px) {
    .block-question-review .value-name-text.long-value-name {
        line-height: 20px;
        margin: 8px 0;
    }
}
@media screen and (max-width: 420px) {
    .table-top-right-col {
        max-width: 100%;
        width: 100%;
        margin-top: 30px;
    }
    .table-top-right-col.col {
        flex-basis: unset;
    }
    .expert-total-score {
        max-width: unset;
        width: 100%;
    }

}
@media screen and (max-width: 455px) {
    .block-question-review .value-name-text.long-value-name {
        line-height: 20px;
        margin: 8px 0;
    }
}
