
.like-block-to-list {
	position: relative;
	width: 100%;
	height: 90px;
	background: #F6F6F6;
	border-top: 1px solid #FFFFFF;	
	border-bottom: 1px solid #FFFFFF;
	transition: .3s;
	cursor: pointer;	
}
.like-block-to-list:hover {
	background: #EDEDED;
}
.like-block-to-list .stripe {
	position: absolute;
	right: 0;
	top: 0;
	width: 4px;
	height: 100%;
	background: #2F3849;
}
.like-block-to-list .main-block {
	float: left;
	margin: 17px 0 0 30px;
	height: 55px;
	width: calc(100% - 120px);
}
.like-block-to-list .block-info {
	float: right;
	margin: 15px 20px 0 0;
}
.like-block-to-list .block-info .time-title {
	font-family: Stratos LC;
	font-size: 10px;
	line-height: 14px;
	/* identical to box height, or 140% */
	text-align: right;
	color: rgba(0, 0, 0, 0.3);
}
.like-block-to-list .block-info .icon-like {
	float: right;
	margin-top: 10px;
	width: 20px;
	height: 15px;
	background-image: url(./img/ic_black_like.svg);
	background-repeat: no-repeat;
}
.like-block-to-list .main-block .avatar {
	float: left;
	width: 48px;
	height: 48px;
	border-radius: 3px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
.like-block-to-list .main-block .subtitle {
	float: left;
	margin-left: 10px;
	height: inherit;
	width: calc(100% - 60px);
	overflow: hidden;
}
.like-block-to-list .main-block .descr-line {
	font-family: Stratos LC;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.505952px;
	color: #404040;
}
.like-block-to-list .main-block .name-line {
	font-family: Stratos LC;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: -0.505952px;
	color: #404040;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


/*NEW*/


.new-like-block-to-list {
	position: relative;
	width: 430px;
	height: 90px;
	background: #F6F6F6;
	border-top: 1px solid #FFFFFF;
	border-bottom: 1px solid #FFFFFF;
	transition: .3s;
	cursor: pointer;
	padding: 17px 0 0 30px;
}
.new-like-block-to-list:hover {
	background: #EDEDED;
}
.new-like-block-to-list .stripe {
	position: absolute;
	right: 0;
	top: 0;
	width: 4px;
	height: 100%;
	background: #2F3849;
}
.new-like-block-to-list .main-block {
	float: left;
	height: 55px;
	width: 290px;
}
.new-like-block-to-list .block-info {
	float: right;
	padding-right: 20px;
}
.new-like-block-to-list .block-info .time-title {
	font-family: Stratos LC;
	font-size: 10px;
	line-height: 14px;
	/* identical to box height, or 140% */
	text-align: right;
	color: rgba(0, 0, 0, 0.3);
}
.new-like-block-to-list .block-info .icon-like {
	float: right;
	margin-top: 10px;
	width: 20px;
	height: 15px;
	background-image: url(./img/ic_black_like.svg);
	background-repeat: no-repeat;
}
.new-like-block-to-list .main-block .avatar {
	float: left;
	width: 48px;
	height: 48px;
	border-radius: 3px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
.new-like-block-to-list .main-block .subtitle {
	float: left;
	height: inherit;
	width: 240px;
	overflow: hidden;
	padding-left: 10px;
}
.new-like-block-to-list .main-block .descr-line {
	font-family: Stratos LC;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.505952px;
	color: #404040;
}
.new-like-block-to-list .main-block .name-line {
	font-family: Stratos LC;
	font-size: 18px;
	line-height: 18px;
	letter-spacing: -0.505952px;
	color: #404040;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
