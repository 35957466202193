.block-button-3-0{
    vertical-align: top;
    position: relative;
}

.block-button-3-0 p{
    user-select: none;
}

.block-button-3-0.button-3-0-blue{
    height: 48px;
    opacity: 1;
    border-radius: 6px;
    background-color: #2CA6EB;
    box-shadow: 0 8px 19px 0 rgba(32,165,238,0);
    cursor: pointer;
    transition: .3s;
}
.block-button-3-0.button-3-0-blue.disabled{
    opacity: 0.4;
    cursor: default;
    box-shadow: 0 8px 19px 0 rgba(32,165,238,0);
}
.block-button-3-0.button-3-0-blue.disabled:hover{
    opacity: 0.4;
    cursor: default;
    box-shadow: 0 8px 19px 0 rgba(32,165,238,0);

}
.block-button-3-0.button-3-0-blue p.block-button-3-0-title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 48px;	text-align: center;
    margin: 0;
}
.block-button-3-0.button-3-0-blue:hover{
    opacity: 0.8;
    box-shadow: 0 8px 19px 0 rgba(32,165,238,0.15);
}
.block-button-3-0.button-3-0-blue:hover p.block-button-3-0-title{}


.block-button-3-0.button-3-0-blue.loader-button{
    /*padding-right: 51px!important;*/
}
.block-button-3-0.button-3-0-blue.loader-button{
    transition: 1s;
    -moz-animation-name: spin;
    -moz-animation-duration: 1s;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 1s;

    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: normal;
    -moz-animation-delay: 0;
    -moz-animation-play-state: running;
    -moz-animation-fill-mode: forwards;

    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    -webkit-animation-delay: 0;
    -webkit-animation-play-state: running;
    -webkit-animation-fill-mode: forwards;
}
@keyframes spin {
    0% {opacity: 1}
    50%{opacity: 0.8}
    100% {opacity: 1}
}
.block-button-3-0.button-3-0-blue.loader-button p.block-button-3-0-title{}
.block-button-3-0.button-3-0-blue.loader-button span.loader-button{
    height: 27px;	width: 27px;
    border-radius: 50%;
    display: block;
    position: absolute;
    right: 12px;
    top: 11px;
    /*border: 4px solid rgba(255, 255, 255, 0.2);*/
}
.block-button-3-0.button-3-0-blue.loader-button span.loader-button img{}


.block-button-3-0.button-3-0-gray{
    height: 48px;
    opacity: 1;
    border-radius: 6px;
    background-color: #ECECEC;
    box-shadow: 0 8px 19px 0 rgba(32,165,238,0);
    cursor: pointer;
    transition: .3s;
}
.block-button-3-0.button-3-0-gray.disabled{
    opacity: 0.4;
    cursor: default;
}
.block-button-3-0.button-3-0-gray.disabled:hover{
    opacity: 0.4;
    cursor: default;
}
.block-button-3-0.button-3-0-gray p.block-button-3-0-title{
    color: #525252;	font-family: "Stratos LC";	font-size: 16px;	font-weight: 400;	line-height: 48px;	text-align: center;
    margin: 0;
}
.block-button-3-0.button-3-0-gray:hover{
    opacity: 0.88;
    box-shadow: 0 8px 19px 0 rgba(32,165,238,0.15);
}
.block-button-3-0.button-3-0-gray:hover p.block-button-3-0-title{}



.block-button-3-0.button-3-0-border{
    height: 48px;
    opacity: 1;
    border-radius: 6px;
    background-color: transparent;
    box-shadow: 0 8px 19px 0 rgba(32,165,238,0.15);
    cursor: pointer;
    transition: .3s;
    border: 2px solid #CDD2DD;
}
.block-button-3-0.button-3-0-border.disabled{
    opacity: 0.4;
    cursor: default;
}
.block-button-3-0.button-3-0-border.disabled:hover{
    opacity: 0.4;
    cursor: default;
}
.block-button-3-0.button-3-0-border p.block-button-3-0-title{
    color: #8A92A3;	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 44px;	text-align: center;
    margin: 0;
}
.block-button-3-0.button-3-0-border:hover{
    opacity: 0.88;
}
.block-button-3-0.button-3-0-border:hover p.block-button-3-0-title{}



.block-button-3-0.button-3-0-green{
    height: 48px;
    opacity: 1;
    border-radius: 6px;
    background-color: #0ECFAA;
    box-shadow: 0 8px 19px 0 rgba(14,207,170,0.15);
    cursor: pointer;
    transition: .3s;
}
.block-button-3-0.button-3-0-green.disabled{
    opacity: 0.4;
    cursor: default;
}
.block-button-3-0.button-3-0-green.disabled:hover{
    opacity: 0.4;
    cursor: default;
}
.block-button-3-0.button-3-0-green p.block-button-3-0-title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 48px;	text-align: center;
    margin: 0;
}
.block-button-3-0.button-3-0-green:hover{
    opacity: 0.8;
}
.block-button-3-0.button-3-0-green:hover p.block-button-3-0-title{}




.block-button-3-0.button-3-0-black{
    height: 48px;
    opacity: 1;
    border-radius: 6px;
    background-color: #2F3849;
    box-shadow: 0 8px 19px 0 rgba(47,56,73,0.15);
    cursor: pointer;
    transition: .3s;
}
.block-button-3-0.button-3-0-black.disabled{
    opacity: 0.4;
    cursor: default;
}
.block-button-3-0.button-3-0-black.disabled:hover{
    opacity: 0.4;
    cursor: default;
}
.block-button-3-0.button-3-0-black p.block-button-3-0-title{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 16px;	font-weight: 400;	line-height: 48px;	text-align: center;
    margin: 0;
}
.block-button-3-0.button-3-0-black:hover{
    opacity: 0.8;
}
.block-button-3-0.button-3-0-black:hover p.block-button-3-0-title{}


.block-button-3-0.button-3-0-black.loader-button{
    /*padding-right: 51px!important;*/
}
.block-button-3-0.button-3-0-black.loader-button{
    transition: 1s;
    -moz-animation-name: spin;
    -moz-animation-duration: 1s;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 1s;

    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: normal;
    -moz-animation-delay: 0;
    -moz-animation-play-state: running;
    -moz-animation-fill-mode: forwards;

    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    -webkit-animation-delay: 0;
    -webkit-animation-play-state: running;
    -webkit-animation-fill-mode: forwards;
}
@keyframes spin {
    0% {opacity: 1}
    50%{opacity: 0.8}
    100% {opacity: 1}
}
.block-button-3-0.button-3-0-black.loader-button p.block-button-3-0-title{}
.block-button-3-0.button-3-0-black.loader-button span.loader-button{
    height: 27px;	width: 27px;
    border-radius: 50%;
    display: block;
    position: absolute;
    right: 12px;
    top: 11px;
    /*border: 4px solid rgba(255, 255, 255, 0.2);*/
}
.block-button-3-0.button-3-0-black.loader-button span.loader-button img{}