@import "../../v4/CONST";

.search-startup-page{

  background: $default-background;
  .block-header-marketplace{
    min-height: 100vh;
  }
  .sticky-header {
    height: auto;
    padding-bottom: 0;
    box-shadow: none;
    @media (max-width: 465px) {
      padding-bottom: 12px;
    }
    .search-line-wrapper {
      display: block;
      width: calc(100% + 20px);
      margin: 12px 0 0 -10px;
    }
    .block-search-header-input {
      .search-header-open-list {
        position: relative;
        z-index: 7;
        @media (max-width: 465px) {
          top: 11px;
          left: -10px;
        }
      }
      .block-new-input {
        z-index: 8;
      }
    }
  }
  .profile-edit-popup-block {
    background: rgba(255,255,255,0.7);
  }
  .block-button-tab-counter {
    opacity: 1;
    border: 1px solid #979797;
  }
  .block-button-tab-counter.active {
    border: 1px solid #0ECFAA;
  }
  .block-button-tab-counter p.title {
    opacity: 0.5;
  }
  .block-button-tab-counter.active p.title {
    opacity: 1;
    color: rgba(8,8,8,0.8);
  }
  .error-not-found-text {
    color: rgba(0,0,0,0.6);
  }

  @media (max-width: 680px) {
    .search-error-not-found {
      //padding: 50px 30px 0 50px!important;
      //height: 260px!important;
    }
  }

  @media (max-width: 500px) {
    .search-error-not-found {
      //padding: 50px 30px 0 30px!important;
      //height: 260px!important;
    }
  }
  @media (max-width: 465px) {
    .search-page-mobile-margin {
      margin-top: 170px;
    }
  }

  .not-found {
    box-shadow: -1px 5px 14px rgba(0,0,0,0.1);
    .text {
      font-size: 22px;
      line-height: 28px;
      color: rgba(0,0,0,0.5);
      padding: 111px 0 146px 0;
      span {
        font-weight: 500;
      }
    }
  }
}
