.block-list-mail-more{
    width: 100%;
    display: table;
}
.block-list-first-item-select{
    width: 100%;
    padding: 0 15px 0 0;
}
.block-list-last-item-select{
    width: 100%;
    padding: 0 15px 15px 0;
    position: relative;
}
.block-list-last-item-select .block-item-mail-more{
    opacity: 0;
}
.block-list-last-item-select.active .block-item-mail-more{
    opacity: 1;
}


.block-button-more{
    height: 38px;	width: 100%;
    padding: 0 0 0 12px;box-sizing: border-box;
    margin: 0 0 6px 0;
    cursor: pointer;
    position: relative;
    z-index: 3;
    border-radius: 4px;
}
.block-button-more:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 38px;
    border: 1px solid rgba(151,151,151,0.2);
    background-color: #FFFFFF;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,0.05);
    z-index: 30;
    left: 0;
    transition: .3s;
}
.block-list-last-item-select .block-no-item-mail-more{
    position: absolute;
    height: 20px;	width: calc(100%);	border: 1px solid rgba(151,151,151,0.2);
    border-radius: 4px;	background-color: #FFFFFF;
    margin: 0 0 6px 0;
    padding: 0 0 0 12px;
    box-sizing: border-box;
    cursor: pointer;
    z-index: -1;
    left: 0px;
    transition: .3s;
}
.block-list-last-item-select .block-button-more:hover:before{
    background-color: #F4F4F4;
}
.block-button-more .block-no-item-mail-more:nth-child(3){
    top: 25px;
    z-index: -2;
    left: 2px;
    width: calc(100% - 4px);
    opacity: 1;
}
.block-button-more .block-no-item-mail-more:nth-child(4){
    top: 32px;
    z-index: -3;
    left: 4px;
    width: calc(100% - 8px);
    opacity: 1;
}
.block-list-last-item-select .block-button-more:hover .block-no-item-mail-more:nth-child(3){
    top: 28px;
}
.block-list-last-item-select .block-button-more:hover .block-no-item-mail-more:nth-child(4){
    top: 38px;
}
.block-list-last-item-select.active .block-button-more:hover .block-no-item-mail-more:nth-child(3){
    top: 44px;
}
.block-list-last-item-select.active .block-button-more:hover .block-no-item-mail-more:nth-child(4){
    top: 98px;
}
.block-list-last-item-select.active .block-button-more .block-no-item-mail-more:nth-child(3){
    top: 44px;
    opacity: 0;
}
.block-list-last-item-select.active .block-button-more .block-no-item-mail-more:nth-child(4){
    top: 98px;
    opacity: 0;
}
.block-list-last-item-select.active .block-button-more .block-no-item-mail-more{
    z-index: -100;
}
.block-list-last-item-select .block-list-item-anim{
    top: -50px;
    position: relative;
    transition: .3s all;
    overflow: hidden;
}
.block-list-last-item-select.active .block-list-item-anim{
    top: 0px;
}
.block-button-more p.title-button-more{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 14px;	line-height: 36px;
    font-weight: 400;
    margin: 0;
    width: calc(100% - 38px);
    float: left;
    position: relative;
    z-index: 33;
}
.block-button-more .block-button-open-close-more{
    float: left;
    width: 38px;
    height: 38px;
    position: relative;
    z-index: 33;
}
.block-button-more .block-button-open-close-more:after,.block-button-more .block-button-open-close-more:before{
    content: '';
    position: absolute;
    background: rgba(0,0,0,.2);
    height: 1px;
    width: 7px;
    transition: .3s;
}
.block-button-more:hover .block-button-open-close-more:after,.block-button-more:hover .block-button-open-close-more:before{
    background: rgba(0,0,0,.4);
}
.block-button-more .block-button-open-close-more:after{
    top: 18px;
    left: 13px;
    transform: rotate(-45deg);
}
.block-button-more .block-button-open-close-more:before{
    top: 18px;
    right: 13px;
    transform: rotate(45deg);
}
.block-list-last-item-select.active .block-button-more .block-button-open-close-more:after{
    transform: rotate(45deg);
}
.block-list-last-item-select.active .block-button-more .block-button-open-close-more:before{
    transform: rotate(-45deg);
}

.block-item-mail-more{
    height: 48px;	width: 100%;	border: 1px solid rgba(151,151,151,0.2);	border-radius: 4px;	background-color: #FFFFFF;	box-shadow: 0 1px 6px 0 rgba(0,0,0,0.07);
    margin: 0 0 6px 0;
    padding: 0 0 0 12px;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 20;
    position: relative;
    transition: .3s;
}

.block-left-item-mail-more{
    width: calc(100% - 48px);
    float: left;
}
.block-item-mail-more p.title-item-mail-more{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 14px;	line-height: 25px;
    margin: 0; font-weight: 400;
}
.block-item-mail-more p.title-item-pos-more{
    opacity: 0.75;	color: #000000;	font-family: "Stratos LC";	font-size: 12px;	line-height: 14px;
    margin: 0; font-weight: 300;
}
.block-item-mail-more .block-button-delete-mail-more{
    width: 48px;
    height: 48px;
    float: right;
    position: relative;
}
.block-item-mail-more .block-button-delete-mail-more:after,.block-button-delete-mail-more:before{
    content: '';
    position: absolute;
    background: rgba(0,0,0,.2);
    height: 1px;
    width: 10px;
    transition: .3s;
}
.block-item-mail-more:hover .block-button-delete-mail-more:after,.block-item-mail-more:hover .block-button-delete-mail-more:before{
    background: rgba(0,0,0,.4);
}
.block-item-mail-more .block-button-delete-mail-more:after{
    top: 23px;
    left: 23px;
    transform: rotate(45deg);
}
.block-item-mail-more .block-button-delete-mail-more:before{
    top: 23px;
    left: 23px;
    transform: rotate(-45deg);
}

