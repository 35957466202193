.block-button {
    height: 30px;
    cursor: pointer;
    display: inline-block;
    box-sizing: border-box;
    transition: 1s;
    border: 1px solid;
}

.block-button:hover {
    transition: 0.5s;
}
.block-button:hover .block-button p{
    transition: 0.5s;
}
.block-button p
 {
    line-height: 30px;
    display: block;
    text-align: center;
    font-family: "Stratos LC";
    font-size: 13px;
    cursor: pointer;
    margin: 0;
    font-weight: 300;
    user-select: none;
    transition: 1s;
    white-space: nowrap;
}


