.block-button-icon-img-check-box {
    height: 48px;
    width: 300px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.07);
    cursor: pointer;
}

.block-button-icon-img-check-box .block-left-button-icon-img-check-box {
    padding: 0 0 0 48px;
}

.block-button-icon-img-check-box.icon .block-left-button-icon-img-check-box {
    padding: 0 0 0 64px;
}

.block-button-icon-img-check-box.img .block-left-button-icon-img-check-box {
    padding: 0 0 0 64px;
}

.block-button-icon-img-check-box.icon.img .block-left-button-icon-img-check-box {
    padding: 0 0 0 120px;
}

.block-left-button-icon-img-check-box {
    width: calc(100% - 48px);
    height: 48px;
    float: left;
    position: relative;
}

.block-left-button-icon-img-check-box img.icon-button {
    width: 25px;
    height: 25px;
    position: absolute;
}

.block-left-button-icon-img-check-box img.img-button {
    height: 33px;
    width: 33px;
    border-radius: 50%;
    position: absolute;
}

.block-button-icon-img-check-box.icon .block-left-button-icon-img-check-box img.icon-button {
    left: 27px;
    top: 11px;
}

.block-button-icon-img-check-box.icon.img .block-left-button-icon-img-check-box img.icon-button {
    left: 27px;
    top: 11px;
}

.block-button-icon-img-check-box.icon.img .block-left-button-icon-img-check-box img.img-button {
    left: 73px;
    top: 7px;
}

.block-button-icon-img-check-box.img .block-left-button-icon-img-check-box img.img-button {
    top: 7px;
    left: 15px;
}

.block-left-button-icon-img-check-box p.title-button-icon-img-check-box {
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 48px;
    margin: 0;
}

.block-right-button-icon-img-check-box {
    width: 48px;
    height: 48px;
    float: right;
    padding: 13px 12px 12px 13px;
}