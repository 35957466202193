.text-h1 {
    color: #000000;
    font-family: "Stratos LC";
}

.text-h2 {
    color: #000000;
    font-family: "Stratos LC";
}

.text-h3 {
    color: #000000;
    font-family: "Stratos LC";
}

.text-h4 {
    color: #000000;
    font-family: "Stratos LC";
}

.text-h5 {
    color: #000000;
    font-family: "Stratos LC";
}

.text-h6 {
    color: #000000;
    font-family: "Stratos LC";
}

.text-body {
    color: #000000;
    font-family: "Stratos LC";
}
.text-body b{
    font-weight: 500;
}

.text-body-2 {
    color: #000000;
    font-family: "Stratos LC";
}

.text-button {
    color: #000000;
    font-family: "Stratos LC";
    cursor: pointer;
}
.text-button b{
    font-weight: 600;
}

.text-button-2 {
    color: #000000;
    font-family: "Stratos LC";
}
.text-button-3 {
    color: #000000;
    font-family: "Stratos LC";
}

.text-sub-title {
    color: #000000;
    font-family: "Stratos LC";
}

.text-caption {
    color: #000000;
    font-family: "Stratos LC";
}

.text-caption-2 {
    color: #000000;
    font-family: "Stratos LC";
}

.text-18-500 {
    font-family: "Stratos LC";
}

@media (min-width: 1200px) {
    .text-h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 48px;
    }

    .text-h2 {
        font-size: 29px;
        line-height: 38px;
    }

    .text-h3 {
        font-size: 28px;
        font-weight: 600;
        line-height: 35px;
    }

    .text-h4 {
        font-size: 24px;
        line-height: 30px;
    }

    .text-h5 {
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
    }

    .text-h6 {
        font-size: 20px;
        line-height: 24px;
    }

    .text-body {
        font-size: 16px;
        line-height: 20px;
    }

    .text-body-2 {
        font-size: 12px;
        line-height: 14px;
    }

    .text-button {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }

    .text-button-2 {
        font-size: 16px;
        line-height: 20px;
    }
    .text-button-3 {
        font-size: 18px;
        line-height: 24px;
    }

    .text-sub-title {
        font-size: 14px;
        line-height: 18px;
    }

    .text-caption {
        font-size: 12px;
        line-height: 15px;
    }

    .text-caption-2 {
        font-size: 11px;
        line-height: 13px;
    }

    .text-18-500 {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
    }
}
@media (max-width: 1199px) {
    .text-h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 48px;
    }

    .text-h2 {
        font-size: 29px;
        line-height: 38px;
    }

    .text-h3 {
        font-size: 28px;
        font-weight: 600;
        line-height: 35px;
    }

    .text-h4 {
        font-size: 24px;
        line-height: 25px;
    }

    .text-h5 {
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
    }

    .text-h6 {
        font-size: 20px;
        line-height: 24px;
    }

    .text-body {
        font-size: 16px;
        line-height: 20px;
    }

    .text-body-2 {
        font-size: 12px;
        line-height: 14px;
    }

    .text-button {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }

    .text-button-2 {
        font-size: 16px;
        line-height: 20px;
    }
    .text-button-3 {
        font-size: 18px;
        line-height: 24px;
    }

    .text-sub-title {
        font-size: 14px;
        line-height: 18px;
    }

    .text-caption {
        font-size: 12px;
        line-height: 15px;
    }

    .text-caption-2 {
        font-size: 11px;
        line-height: 13px;
    }

    .text-18-500 {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
    }
}
@media (max-width: 900px) {
    .text-h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 48px;
    }

    .text-h2 {
        font-size: 29px;
        line-height: 38px;
    }

    .text-h3 {
        font-size: 28px;
        font-weight: 600;
        line-height: 35px;
    }

    .text-h4 {
        font-size: 24px;
        line-height: 25px;
    }

    .text-h5 {
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
    }

    .text-h6 {
        font-size: 20px;
        line-height: 24px;
    }

    .text-body {
        font-size: 16px;
        line-height: 20px;
    }

    .text-body-2 {
        font-size: 12px;
        line-height: 14px;
    }

    .text-button {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }

    .text-button-2 {
        font-size: 16px;
        line-height: 20px;
    }
    .text-button-3 {
        font-size: 18px;
        line-height: 24px;
    }

    .text-sub-title {
        font-size: 14px;
        line-height: 18px;
    }

    .text-caption {
        font-size: 12px;
        line-height: 15px;
    }

    .text-caption-2 {
        font-size: 11px;
        line-height: 13px;
    }

    .text-18-500 {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
    }
}
@media (max-width: 620px) {
    .text-h1 {
        font-size: 28px;
        font-weight: 600;
        line-height: 35px;
    }

    .text-h2 {
        font-size: 24px;
        line-height: 25px;
    }

    .text-h3 {
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
    }

    .text-h4 {
        font-size: 24px;
        line-height: 25px;
    }

    .text-h5 {
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
    }

    .text-h6 {
        font-size: 20px;
        line-height: 24px;
    }

    .text-body {
        font-size: 16px;
        line-height: 20px;
    }

    .text-body-2 {
        font-size: 12px;
        line-height: 14px;
    }

    .text-button {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
    }

    .text-button-2 {
        font-size: 16px;
        line-height: 20px;
    }
    .text-button-3 {
        font-size: 18px;
        line-height: 24px;
    }

    .text-sub-title {
        font-size: 14px;
        line-height: 18px;
    }

    .text-caption {
        font-size: 12px;
        line-height: 15px;
    }

    .text-caption-2 {
        font-size: 11px;
        line-height: 13px;
    }

    .text-18-500 {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
    }
}