.block-header-marketplace{
    width: 100%;
    /*width: 970px;*/
}
.block-top-header-marketplace{
    width: 100%;
    display: table;
    height: 70px;
}
.block-top-header-marketplace .block-logo{
    height: 70px;
    width: 130px;
}
.block-top-header-marketplace .block-logo a{}
.block-top-header-marketplace .block-logo a img{
    height: 16px;	width: 100px;
    margin: 27px 0;
}
.block-body-header-marketplace{
    width: 100%;
    position: relative;
    display: table;
}
.block-body-header-marketplace img{
    position: absolute;
    top: 0;
    right: 0;
}
.block-dots-header-marketplace{
    width: 6px;
    float: left;
}
.block-item-dot-header-marketplace{
    height: 55px;	width: 6px;
    margin:  0 0 12px 0;position: relative;
    border-radius: 50%;
    overflow: hidden;
    padding: 0 10px;
    cursor: pointer;
    box-sizing: content-box;
}
.block-item-dot-header-marketplace:before{
    content: '';
    position: absolute;
    width: 6px;
    height: 55px;
    border-radius: 6px;
    left: 10px;
    top: 0;
    background-color: #D0D0D0;
}
.block-item-dot-header-marketplace:after{
    content: '';
    position: absolute;
    width: 6px;
    height: 55px;
    border-radius: 6px;
    left: 10px;
    top: -55px;
}

.block-item-dot-header-marketplace.start:after{
    top: 0;
    transition: 10s;
    transition-timing-function:linear;
}
.block-item-dot-header-marketplace.active:after{
    top: 0;
    transition: 0s;
    transition-timing-function:ease;
}
.block-item-dot-header-marketplace.remove:after{
    top: 55px;
    transition: .5s;
    transition-timing-function:ease;
}
.block-item-dot-header-marketplace.delete:after{
    top: -55px;
    transition: 0s;
}
.block-item-dot-header-marketplace.dot-1:after{
    background-color: #2F3849;
}
.block-item-dot-header-marketplace.dot-2:after{
    background-color: #2CA6EB;
}
.block-item-dot-header-marketplace.dot-3:after{
    background-color: #0ECFAA;
}

.block-info-header-marketplace{
    /*width: calc(100% - 46px);*/
    /*float: right;*/
    width: 100%;
    position: relative;
}
.block-info-header-marketplace p.title-header-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 38px;	font-weight: 600;	letter-spacing: -0.3px;	line-height: 48px;
    margin: 0; transition: .3s;
    max-width: 550px;
    /*height: 144px;*/
    overflow: hidden;
    position: relative;
    z-index: 9;
}
.block-info-header-marketplace p.description-header-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 22px;
    margin: 20px 0 36px 0;transition: .3s;
    max-width: 420px;
}

.block-button-color-header-marketplace{
    width: 235px;
    border-radius: 6px;
    height: 51px;
    padding: 0 23px;
    cursor: pointer;
    margin: 0 auto 16px 0;
    transition: .3s;
}
.block-button-color-header-marketplace.black{
    background-color: #2F3849;
}
.block-button-color-header-marketplace.green{
    background-color: #0ECFAA;
}
.block-button-color-header-marketplace.blue{
    background-color: #2CA6EB;
}
.block-button-color-header-marketplace.white{
    background-color: #FFFFFF;
    border: 1px solid rgba(151,151,151,0.2);
}
.block-button-color-header-marketplace p.title-button-color-header-marketplace{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 51px;	text-align: center;
    margin: 0;transition: .3s;
}
.block-button-color-header-marketplace.white p.title-button-color-header-marketplace{
    color: rgba(8,8,8,0.8);line-height: 49px; text-align: left;
}
.block-button-color-header-marketplace p.title-button-color-header-marketplace span.right-title-button{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 16px;	line-height: 49px;	float: right;
    transition: .3s;
}



/*.block-form-email-marketplace{*/
    /*margin: 120px 0 0 0;*/
/*}*/
/*.block-form-email-marketplace p.title-form-email-marketplace{*/
    /*color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 22px;	font-weight: 500;	line-height: 30px;	text-align: center;*/
    /*max-width: 560px;*/
    /*margin: 0 auto 24px auto;*/
/*}*/

.block-input-button-form-email-marketplace{
    max-width: 560px;
    margin: 0 auto;
    padding: 0 25px;
    display: table;
    width: 100%;
}
.block-input-button-form-email-marketplace .block-new-input{
    float: left;
    width: 230px;
}
.block-input-button-form-email-marketplace .block-new-input input{
    height: 40px;
    line-height: normal;

}
.block-input-button-form-email-marketplace .block-button-3-0{
    height: 40px;
    float: right;
}
.block-input-button-form-email-marketplace .block-button-3-0 p{
    height: 40px;
    line-height: 40px!important;
}



.block-popup-form-marketplace{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: #2F2F2F;
    z-index: 1000;
}

.block-form-marketplace{
    width: 630px;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    position: relative;
    margin: 50px auto auto auto;
    padding: 35px 60px;
}
.block-form-marketplace p.title-form-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 28px;	font-weight: 500;	line-height: 35px;
    margin: 0 0 21px 0;
}
.block-form-marketplace p.description-form-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
    margin: 0 0 40px 0; font-weight: 400;
}
.block-close-popup-form-marketplace{
    position: absolute;
    width: 35px;
    height: 35px;
    top: 24px;
    right: 24px;
    cursor: pointer;
}
.block-close-popup-form-marketplace i{
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    font-size: 26px;
}
.block-form-marketplace .block-input-button-form-email-marketplace{
    padding: 0;
    width: 100%;
    max-width: 100%;
}


.block-cart-new-startup-marketplace{
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0px 4px 14px 0 rgba(0,0,0,0.03);
    overflow: hidden;
    text-align: left;
    vertical-align: top;
    margin: 0 0 20px 0;
    height: 430px;
}

.block-cart-new-startup-marketplace,
.block-cart-new-video-marketplace,
.all-card-block,
.block-cart-startup-marketplace{
    transition: .3s;
    position: relative;
    top: 0px;
    left: 0px;
}

.block-cart-new-video-marketplace:hover,
.block-cart-new-startup-marketplace:hover,
.all-card-block:hover,
.block-cart-startup-marketplace:hover{
    cursor: pointer;
    box-shadow: 0px 0px 11px #a7a5a5;
    top: -10px;
}

.block-top-cart-new-startup-marketplace{
    width: 100%;
    height: 100px;
    position: relative;
}
.block-bg-top-cart-new-startup-marketplace{
    width: 100%;
    height: 60px;
    position: relative;
}
.bg-top-cart-new-startup-marketplace{
    width: 100%;
    height: 60px;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    background: #0E76A8;
}
.block-icon-play-top-cart-new-startup-marketplace{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;
    right: 8px;
    border-radius: 2px;	background-color: rgba(8,8,8,0.8);
    cursor: pointer;
}
.block-icon-play-top-cart-new-startup-marketplace i{
    color: #FFFFFF;
    font-size: 9px;
    width: 20px;
    line-height: 20px;
    text-align: center;
    position: relative;
    top: -2px;
}
.block-ing-cart-new-startup-marketplace{
    height: 88px;	width: 88px;	border-radius: 4px;	background-color: #FFFFFF;
    position: absolute;
    top: 12px;
    left: 12px;
}
.img-cart-new-startup-marketplace{
    height: 80px;	width: 80px;	border-radius: 2px;
    margin: 4px;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    background: #fff;
}
.block-body-cart-new-startup-marketplace{
    width: 100%;
    padding: 16px 16px 70px 16px;
    height: calc(100% - 100px);
    position: relative;
}
.block-body-cart-new-startup-marketplace p.title-body-cart-new-startup-marketplace{
    color: #000000;	font-family: "Stratos LC";	font-size: 20px;	line-height: 24px;
    margin: 0 0 12px 0; font-weight: 400; height: 24px;
    overflow: hidden;
    white-space: nowrap; /* Запрещаем перенос строк */
    text-overflow: ellipsis; /* Добавляем многоточие */
}
.block-body-cart-new-startup-marketplace p.description-body-cart-new-startup-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0 0 16px 0; font-weight: 400;
    overflow: hidden;
}
.block-list-industries-body-cart-new-startup-marketplace{
    height: 107px;
    overflow: hidden;
}
.block-list-industries-body-cart-new-startup-marketplace p.title-industries-body-cart-new-startup-marketplace{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0 0 8px 0;
}
.block-item-industries-body-cart-new-startup-marketplace{
    display: inline-block;
    height: 20px;
    border-radius: 4px;	background-color: rgba(0,0,0,0.03);
    margin: 0 6px 6px 0;
    cursor: pointer;
}
.block-item-industries-body-cart-new-startup-marketplace p.title-item-industries-body-cart-new-startup-marketplace{
    color: #525252;	font-family: "Stratos LC";	font-size: 12px;	line-height: 20px;	text-align: center;
    margin: 0; padding: 0 10px;
}
.block-list-button-body-cart-new-startup-marketplace{
    width: calc(100% - 32px);
    height: 38px;
    position: absolute;
    bottom: 16px;
    left: 16px;
}
.block-list-button-body-cart-new-startup-marketplace .block-item-button-body-cart-new-startup-marketplace{
    height: 38px;
    width: calc(33.33% - 4px);
    border: 1px solid #D0D0D0;	border-radius: 6px;	background-color: #FFFFFF;
    float: left;
    cursor: pointer ;
}
.block-list-button-body-cart-new-startup-marketplace .block-item-button-body-cart-new-startup-marketplace.m{
    margin: 0 6px 0 0;
}
.block-list-button-body-cart-new-startup-marketplace .block-item-button-body-cart-new-startup-marketplace i{
    width: 26px;
    height: 36px;
    float: left;
    text-align: center;
    line-height: 36px;
    color: rgba(0,0,0,0.5);
}
.block-list-button-body-cart-new-startup-marketplace .block-item-button-body-cart-new-startup-marketplace i.fa-heart{}
.block-list-button-body-cart-new-startup-marketplace .block-item-button-body-cart-new-startup-marketplace i.fa-clock{}
.block-list-button-body-cart-new-startup-marketplace .block-item-button-body-cart-new-startup-marketplace i.fa-share-alt{}
.block-list-button-body-cart-new-startup-marketplace .block-item-button-body-cart-new-startup-marketplace p.title-item-button-body-cart-new-startup-marketplace{
    width: calc(100% - 26px);
    height: 36px;
    float: right;
    line-height: 36px;
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 12px;	text-align: right;
    margin: 0;
    padding: 0 8px 0 0;
}

span.title-round{
    position: absolute;
    right: 17px;
    top: 75px;
    font-family: Stratos LC;
    font-size: 14px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
}
span.title-round span.round{
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
}




.block-cart-new-video-marketplace{
    border-radius: 6px;	background-color: #FFFFFF;	box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    margin: 0 0 20px 0;
    height: 290px;
    overflow: hidden;
    vertical-align: top;
    text-align: left;
}
.block-top-cart-new-video-marketplace{
    width: 100%;
    height: 210px;
    position: relative;
}
.block-bg-top-cart-new-video-marketplace{
    width: 100%;
    height: 180px;
}
.bg-top-cart-new-video-marketplace{
    width: 100%;
    height: 180px;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    background: #0E76A8;
}
.block-button-play-top-cart-new-video-marketplace{
    height: 40px;	width: 40px;	border-radius: 6px;	background-color: rgba(8,8,8,0.8);
    position: absolute;
    top: 70px;
    left: 130px;
    cursor: pointer;
}
.block-button-play-top-cart-new-video-marketplace i{
    line-height: 40px;
    width: 40px;
    height: 40px;
    text-align: center;
    color: #FFFFFF;
    font-size: 20px;
}
.block-button-path-top-cart-new-video-marketplace{
    position: absolute;
    display: inline-block;
    height: 20px;	border-radius: 10px;	background-color: rgba(8,8,8,0.8);
    top: 8px;
    right: 8px;
    cursor: pointer;
}
.block-button-path-top-cart-new-video-marketplace p.title-button-path-top-cart-new-video-marketplace{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 11px;	font-weight: 500;	line-height: 20px;	text-align: center;
    padding: 0 10px;
    margin: 0;
}
.block-img-top-cart-new-video-marketplace{
    height: 88px;	width: 88px;	border-radius: 4px;	background-color: #FFFFFF;
    position: absolute;
    left: 12px;
    top: 120px;
}
.block-img-top-cart-new-video-marketplace .img-top-cart-new-video-marketplace{
    height: 80px;	width: 80px;	border-radius: 2px;
    margin: 4px;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    background: #fff;
}
.block-body-cart-new-video-marketplace{
    padding: 20px 16px;
}
.block-body-cart-new-video-marketplace p.title-body-cart-new-video-marketplace{
    color: #000000;	font-family: "Stratos LC";	font-size: 20px;	line-height: 24px;
    margin: 0; font-weight: 400;
 }







.block-cart-startup-marketplace{
    width: 300px;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    overflow: hidden;
    float: left;
    margin: 0 20px 20px 0;
    text-align: left;
}
.block-top-cart-startup-marketplace{
    width: 100%;
    height: 210px;
    position: relative;
}
.block-bg-top-cart-startup-marketplace{
    width: 100%;
    height: 180px;
}
.block-bg-top-cart-startup-marketplace .bg-top-cart-startup-marketplace{
    width: 100%;
    height: 180px;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    background: #0E76A8;
}
.block-img-top-cart-startup-marketplace{
    height: 88px;	width: 88px;	border-radius: 4px;	background-color: #FFFFFF;
    position: absolute;
    left: 12px;
    top: 120px;
}
.block-img-top-cart-startup-marketplace .img-top-cart-startup-marketplace{
    height: 80px;	width: 80px;	border-radius: 2px;
    margin: 4px;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    background: #0E76A8;
}
.block-body-cart-startup-marketplace{
    width: 100%;
    padding: 16px 16px 70px 16px;
    position: relative;
}
.block-body-cart-startup-marketplace p.title-body-cart-startup-marketplace{
    color: #000000;	font-family: "Stratos LC";	font-size: 20px;	line-height: 24px;
    margin: 0 0 12px 0; font-weight: 400;
}
.block-body-cart-startup-marketplace p.description-body-cart-startup-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0 0 16px 0; font-weight: 400; max-height: 55px;
}
.block-list-industries-body-cart-startup-marketplace{}
.block-list-industries-body-cart-startup-marketplace p.title-industries-body-cart-new-startup-marketplace{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0 0 8px 0;
}
.block-item-industries-body-cart-startup-marketplace{
    display: inline-block;
    height: 20px;
    border-radius: 4px;	background-color: rgba(0,0,0,0.03);
    margin: 0 6px 6px 0;
    cursor: pointer;
}
.block-item-industries-body-cart-startup-marketplace p.title-item-industries-body-cart-startup-marketplace{
    color: #525252;	font-family: "Stratos LC";	font-size: 12px;	line-height: 20px;	text-align: center;
    margin: 0; padding: 0 10px;
}
.block-list-button-body-cart-startup-marketplace{
    width: calc(100% - 32px);
    height: 38px;
    position: absolute;
    bottom: 16px;
    left: 16px;
}
.block-list-button-body-cart-startup-marketplace .block-item-button-body-cart-startup-marketplace{height: 38px;
    width: calc(33.33% - 4px);
    border: 1px solid #D0D0D0;	border-radius: 6px;	background-color: #FFFFFF;
    float: left;
    cursor: pointer;
}
.block-list-button-body-cart-startup-marketplace .block-item-button-body-cart-startup-marketplace.m{
    margin: 0 6px 0 0;
}
.block-item-button-body-cart-startup-marketplace i{
    width: 26px;
    height: 36px;
    float: left;
    text-align: center;
    line-height: 36px;
    color: rgba(0,0,0,0.5);
}
.block-item-button-body-cart-startup-marketplace i.fa-heart{}
.block-item-button-body-cart-startup-marketplace i.fa-clock{}
.block-item-button-body-cart-startup-marketplace i.fa-share-alt{}
.block-item-button-body-cart-startup-marketplace p.title-item-button-body-cart-startup-marketplace{
    width: calc(100% - 26px);
    height: 36px;
    float: right;
    line-height: 36px;
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 12px;	text-align: right;
    margin: 0;
    padding: 0 8px 0 0;
}
.block-bottom-cart-startup-marketplace{
    width: 100%;
    border-top: 1px solid #D0D0D0;
    padding: 16px;
}
.block-bottom-cart-startup-marketplace p.title-bottom-cart-startup-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;	text-align: center;
    margin: 0; font-weight: 400; width: 100%;
}
.block-bottom-cart-startup-marketplace p.description-bottom-cart-startup-marketplace{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 12px;	line-height: 14px;	text-align: center;
    margin: 0; font-weight: 400;
}


















.block-list-cart-marketplace{
    margin: 0 0 60px 0;
}
.block-list-cart-marketplace p.title-list-cart-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 22px;	font-weight: 500;	line-height: 30px;
    margin: 0;
}
.block-list-cart-marketplace p.description-list-cart-marketplace{
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 16px;	font-weight:400; line-height: 20px;
    margin: 0 0 20px 0;
}
.block-list-cart-marketplace p.more-list-cart-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	font-weight: 500;	line-height: 20px;
    margin: 0;
    cursor: pointer;
}
.block-list-cart-marketplace p.more-list-cart-marketplace i{
    width: 20px;
    height: 20px;
    text-align: center;
    margin: 0 0 0 4px;
    font-size: 16px;
}
.list-cart-marketplace{
    display: table;width: 100%;
    text-align: left;
}
@media screen and (max-width: 950px) {
    .list-cart-marketplace{
        /*display: table;*/
        display: block;
        width: 100%;
        text-align: center;
    }
}



.all-card-block {
    border-radius: 6px;	background-color: #FFFFFF;	box-shadow: 0px 4px 14px 0 rgba(0,0,0,0.03);
    cursor: pointer;
    margin: 0 0 20px 0;
}
.expert-card-block {
    height: 425px;
}
.all-card-top {
    height: 115px;
    width: inherit;
    padding: 16px;
    position: relative;
}
.all-card-content {
    height: 235px;
    width: inherit;
    border-top: 1px solid #D0D0D0;
    border-bottom: 1px solid #D0D0D0;
    padding: 16px ;
}
.expert-card-content {
    height: 130px;
}
.bottom-0-cart-expert{
    padding: 0 0 16px 0;
}
.expert-card-content {
    border-bottom-width: 0px;
}
.all-card-bottom {

}
.title {
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 12px;	line-height: 14px;
}
.subtitle {
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 16px;	line-height: 20px;
}



.all-card-top .avatar {
    height: 80px;
    width: 80px;
    border-radius: 2px;
    margin-right: 12px;
    float: left;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.all-card-top .title {
    margin-top: 4px;
    text-transform: capitalize;
}
.all-card-top .name {
    color: #000000;	font-family: "Stratos LC";	font-size: 20px;	line-height: 24px;
    height: 48px;
    margin-top: 4px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.all-card-top span.check{
    height: 23px;	width: 23px; background-color: #2CA6EB;	box-shadow: 0 8px 19px 0 rgba(14,207,170,0.15);
    display: inline-block;
    border-radius: 50%;
    margin: 0 0 0 8px;
    position: absolute;
    top:16px;
    right: 16px;
}
.all-card-top span.check i{
    color: #FFFFFF;
    font-size: 14px;
    display: inline-block;
    vertical-align: top;
    width: 23px;
    height: 23px;
    line-height: 24px;
    text-align: center;
}
.all-card-content .description {
    max-height: 70px;
    width: 100%;
    word-wrap: break-word;
    font-size: 14px;
    line-height: 18px;
    /*white-space: wrap;*/
    overflow: hidden;
    /*text-overflow: ellipsis;*/
}
.all-card-content .location {
    height: 20px;
    margin-top: 12px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.all-card-content .industries {
    margin-top: 10px;
    height: 18px;
}
.all-card-content .industries-list {
    margin-top: 8px;
    max-height: 80px;
    overflow: hidden;
}
.expert-card-content .industries-list {
    margin-top: 16px;
}
.all-card-content .industries-list > span {
    color: #525252;	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;	text-align: center;
    display: inline-block;
    border-radius: 4px;	background-color: rgba(0,0,0,0.03);
    margin: 0 5px 5px 0;
    padding: 2px 8px 3px 8px;
}
.all-card-bottom.border-top{
    border-top: 1px solid #D0D0D0;
}
.all-card-bottom .investment-size {
    padding: 16px 0 16px 0;
    text-align: center;
}
.all-card-bottom .fund-size {
    padding: 16px 0 16px 0;
    text-align: center;
}
.all-card-bottom .subtitle {
    margin-top: 4px;
    height: 20px;
}
.expert-card-top span.check {
    background-color: #0ECFAA;
}
.expert-card-bottom {
    padding: 0 16px;
    height: 90px;
    align-content: baseline;
}
.expert-card-bottom .evaluation-parameters-title {
    color: #000000;	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;
    margin-top: 12px;
}
.expert-card-bottom .evaluation-parameters-line {
    height: 4px;	width: 268px;	border-radius: 3px;	background-color: #ECECEC;
    margin-top: 6px;
}
.expert-card-bottom .evaluation-parameters-line .line {
    height: 4px;
    border-radius: 3px;
    background-color: #0ECFAA;
    width: 0;
}




.latest-articles-block {
    /*height: 585px;*/
    /*width: 940px;*/
}
.main-post-block {
    height: 295px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 100%;
}
.main-post-block a{
    text-decoration: none;
}
.block-col-post-block{
    display: table;
    width: 100%;
}
.bottom-post {
    height: 200px;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    margin-top: 20px;
}
.bottom-post a{
    text-decoration: none;
}
.right-post-block {
    margin-right: 0;
}
.date-title {
    color: rgba(0,0,0,0.5);
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
}
.bottom-post .bottom-post-img {
    height: 157px;
    width: inherit;
    cursor: pointer;
    border-radius: 8px 8px 0 0;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.bottom-post .date-title {
    margin-top: 12px;
    margin-left: 16px;
}
.main-post-block .main-post-img {
    height: 295px;
    width: 560px;
    float: left;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
@media screen and (max-width: 950px) {
    .main-post-block .main-post-img {
        height: 295px;
        width: 100%;
        float: none;
        cursor: pointer;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
    .main-post-left-info{
        display: block;
        width: 100%
    }
}
.main-post-block .date-title {
    display: inline-block;
    padding: 0 25px;
    margin-top: 30px;
}
.main-post-block .main-post-title {
    padding:0 25px;
    margin-top: 8px;
    cursor: pointer;
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 22px;	font-weight: 500;	line-height: 30px;
    max-height: 60px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.main-post-block .main-post-descr {
    padding:0 25px;
    margin-top: 12px;
    max-height: 120px;
    line-height: 20px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(8,8,8,0.8);
    width: calc(100% - 560px);
}
.main-post-block .arrow {
    height: 20px;
    width: 20px;
    position: absolute;
    transform: rotate(180deg);
    right: 40px;
    bottom: 20px;
    cursor: pointer;
}
.latest-articles-block-button {
    height: 40px;
    width: 224px;

    margin: 0 auto;
}
.latest-articles-block-button .button-3-0-blue {
    background-color: #2F3849;
    margin-top: 30px;
    height: 40px;
}
.latest-articles-block-button .button-3-0-blue p {
    line-height: 40px!important;
}




p.title-latest-articles{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 38px;	font-weight: 500;	line-height: 46px;	text-align: center;
    margin: 0 0 60px 0;
    position: relative;
    z-index: 5;
}





.list-item-success-stories{
    width: 780px;
    margin: 0 auto;
    padding: 20px 0 0 0;
    position: relative;
    z-index: 5;
}
.block-item-success-stories{
    width: 100%;
    display: table;
    margin: 0 0 60px 0;
}
.block-item-success-stories.left-item-success-stories{
    padding: 0 188px 0 0;
}
.block-item-success-stories.right-item-success-stories{
    padding: 0 0 0 188px;
}

.block-item-success-stories.left-item-success-stories .block-left-item-success-stories{
    width: 188px;
    float: left;
}
.block-item-success-stories.left-item-success-stories .block-right-item-success-stories{
    width: calc(100% - 188px);
    float: right;
}

.block-item-success-stories.right-item-success-stories .block-left-item-success-stories{
    width: calc(100% - 188px);
    float: left;
}
.block-item-success-stories.right-item-success-stories .block-right-item-success-stories{
    width: 188px;
    float: right;
}


p.title-item-success-stories{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 24px;	line-height: 30px;
    margin: 0 0 24px 0; font-weight: 400;
    max-width: 400px;
}
p.description-item-success-stories{
    color: rgba(0,0,0,0.8);	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0 0 4px 0; font-weight: 400;
}
p.description-item-success-stories span.gray{
    color: rgba(0,0,0,0.5);
}
p.description-item-success-stories span.blue{
    color: #2CA6EB;
}
a.link-item-success-stories{
    color: #2CA6EB;	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;
    margin: 0; font-weight: 400;
}
.img-item-success-stories{
    height: 140px;	width: 140px;	border-radius: 8px;
    background-size: cover!important;
    background-position: center!important;
    background-repeat: no-repeat!important;
}
.block-item-success-stories.right-item-success-stories .block-right-item-success-stories .img-item-success-stories{
    float: right;
}

.block-list-success-stories{
    margin: 40px 0 0 0;
    padding: 60px 0 0 0;
}

.block-gradient-gray-reg {
    position: relative;
    width: 100%;
    z-index: 0;
}

.block-gradient-gray-reg {
    /*background: linear-gradient(180deg, #F5F5F5 0%, rgba(255, 255, 255, 0) 100%);*/
}




.block-left-item-img{
    display: none;
    width: 188px;
    float: left;
}
@media (max-width: 1000px) {
    .block-left-item-img{
        display: block;
    }
    .block-item-success-stories.right-item-success-stories .block-right-item-success-stories{
        display: none;
    }
    .block-item-success-stories.right-item-success-stories{
        padding: 0;
    }
    .block-item-success-stories.left-item-success-stories{
        padding: 0;
    }
    .list-item-success-stories{
        width: 100%;
        padding: 0 20px;
    }
}
@media (max-width: 600px) {
    .block-left-item-img{
        float: none;
        text-align: center;
        width: 100%;
    }
    .block-item-success-stories.left-item-success-stories .block-left-item-success-stories{
        float: none;
        text-align: center;
        width: 100%;
    }
    .block-item-success-stories.left-item-success-stories .block-right-item-success-stories{
        float: none;
        width: 100%;
        margin: 30px 0 0 0;
    }
    .block-item-success-stories.right-item-success-stories .block-left-item-success-stories{
        float: none;
        width: 100%;
        margin: 30px 0 0 0;
    }
    .img-item-success-stories{
        display: inline-block;
    }
}


.block-want-know-more{
    width: 100%;
    display: table;
    margin: 120px 0 0 0;
    text-align: center;
}
.block-want-know-more p.title-want-know-more{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 38px;	font-weight: 500;	line-height: 46px;	text-align: center;
    margin: 0 0 16px 0;
}
.block-want-know-more p.description-want-know-more{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 18px;	line-height: 24px;	text-align: center;
    font-weight: 400; margin: 0 auto 40px auto;
    max-width: 560px;
}
.block-want-know-more .block-button-3-0{
    height: 60px !important;
}
.block-want-know-more .block-button-3-0 p{
    height: 60px !important;
    line-height: 60px!important;

}


.block-top-marketplace {
    text-align: center;
    margin: 0 auto 45px;
    padding: 0 25px;
    max-width: 618px;
}
.link-top-marketplace {
    font-size: 30px;
    line-height: 46px;
    text-decoration-line: underline;
    color: rgba(8, 8, 8, 0.8);
    margin-bottom: 12px;
    display: inline-block;
}
.link-top-marketplace:hover {
    text-decoration: none;
}
.block-top-marketplace .title-top-marketplace {
    font-size: 32px;
    line-height: 46px;
    color: rgba(8, 8, 8, 0.8);
    margin: 0 0 36px;
}
.btn-top-marketplace {
    font-size: 16px;
    line-height: 20px;
    padding: 16px 40px;
    background: #7D29FD;
    box-shadow: 0 8px 19px rgba(47, 56, 73, 0.151664);
    border-radius: 6px;
    color: #fff;
    display: inline-block;
    transition: opacity 0.2s ease-in-out;
}
.btn-top-marketplace:hover {
    opacity: 0.8;
    box-shadow: 0 8px 19px 0 rgba(32,165,238,0.15);
}


.block-top-title-button-marketplace{
    padding: 60px 0 45px 0;
}
.block-top-title-button-marketplace p.title-top-marketplace{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 38px;	font-weight: 500;	line-height: 46px;	text-align: center;
    margin: 0 0 16px 0;
}
.block-list-button-top-marketplace{
    width: 100%;
    text-align: center;
}
.block-item-button{
    border: 1px solid rgba(151,151,151,0.2);	border-radius: 6px;	background-color: #FFFFFF;
    display: inline-block;
    height: 51px;
    width: 190px;
    margin: 0 12px 24px 12px;
    /*cursor: pointer;*/
}
p.title-item-button{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 18px;	font-weight: 500;	line-height: 49px;
    padding: 0 20px; text-align: left;
    margin: 0;
}
p.title-item-button span.right{
    float: right;
    color: rgba(0,0,0,0.5);	font-family: "Stratos LC";	font-size: 16px;	line-height: 49px;	font-weight: 400;
}


.block-new-banner-get-email{
    height: 230px;
    width: 100%;
    padding: 36px 40px;
    border-radius: 8px;
    margin: 80px 0;
    box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    background-position: right!important;
    background-repeat: no-repeat!important;
}
.title-new-banner-get-email{
    color: rgba(8,8,8,0.8);	font-family: "Stratos LC";	font-size: 30px;	font-weight: 500;	line-height: 36px;
    margin: 0 0 40px 0;
    max-width: 560px;
}
.block-input-button-new-banner-get-email{
    display: table;
    width: 100%;
}
.block-input-button-new-banner-get-email .block-new-input{
    height: 58px;
    float: left;
    width: 230px;
    margin: 0 16px 0 0;
}
.block-input-button-new-banner-get-email .block-new-input input{
    height: 40px;
}

.block-input-button-new-banner-get-email .button-3-0-blue{
    height: 40px !important;
    float: left;
}
.block-input-button-new-banner-get-email .button-3-0-blue p{
    height: 40px !important;
    line-height: 40px!important;
}


.block-big-post-blog{
    width: 100%;
    border-radius: 0;	background-color: #FFFFFF;	box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
    cursor: pointer;
    overflow: hidden;
}
.block-big-post-blog a{
    display: flex;
    align-items: center;
    text-decoration: none;
}
.block-left-big-post-blog{
    width: calc(100% - 380px);
    float: left;
    position: relative;
}
.block-right-big-post-blog{
    width: 380px;
    margin-bottom: auto;
    padding: 32px 25px;
    box-sizing: border-box;
}
.block-left-big-post-blog .bg-big-post-blog{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat!important;
    background-position: center!important;
    background-size: cover!important;
    padding-top: 52.8%;
}
.block-right-big-post-blog p.date-big-post-blog{
    color: rgba(0,0,0,0.5);	font-size: 14px;	line-height: 18px;
    margin: 0;
}
.block-right-big-post-blog p.title-big-post-blog{
    color: rgba(8,8,8,0.8);	font-size: 22px;	font-weight: 500;	line-height: 30px;
    margin: 15px 0 0 0;
}
.block-right-big-post-blog p.description-big-post-blog{
    color: rgba(8,8,8,0.8);	font-size: 16px;	line-height: 22px;
    margin: 20px 0 0 0 ;
}



@media (max-width: 1199px) {
    .block-list-cart-marketplace{
        padding: 0 20px;
    }
    .latest-articles-block{
        padding: 0 20px;
    }
}

@media (max-width: 767px) {
    .block-top-marketplace .title-top-marketplace {
        font-size: 28px;
        line-height: 34px;
    }
}

@media (max-width: 760px) {
    .block-new-banner-get-email{
        height: 630px;
        width: calc(100% - 40px);
        padding: 36px 40px;
        border-radius: 8px;
        margin: 80px auto;
        box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
        background-position: 90% bottom!important;
        background-repeat: no-repeat!important;
    }
    .block-left-big-post-blog{
        width: 100%;
        float: none;
        position: relative;
    }
    .block-big-post-blog a {
        display: block;
    }
    .block-right-big-post-blog{
        width: 100%;
        float: none;
        padding: 32px 25px;
        box-sizing: border-box;
    }
}
@media (max-width: 375px) {
    .all-card-block,
    .block-cart-new-startup-marketplace,
    .block-cart-new-video-marketplace {
        width: 100%;
    }
    #accelerators .list-cart-marketplace {
        display: unset;
        width: unset;
    }

}
@media (max-width: 342px) {
    .block-list-cart-marketplace{
        padding: 0 10px;
    }
}
/*@media (max-width: 322px) {*/
    /*.all-card-block,*/
    /*.block-cart-new-startup-marketplace,*/
    /*.block-cart-new-video-marketplace {*/
    /*}*/

/*}*/