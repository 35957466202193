.block-user-sidebar {
    padding: 31px 10px 50px 36px;
    height: 100%;
    width: 100%;
    align-content: flex-start;
}
.block-user-sidebar .block-user-buttons-line {
    height: 36px;
    width: 100%;
    margin-bottom: 4px;
}
.block-user-sidebar .block-user-buttons-line .button-wrapper {
    font-size: 16px;
    color: #2F3849;
    line-height: 34px;
    text-align: center;
    background-color: #FFFFFF;
    border: 1px solid rgba(0,0,0,0.6);
    border-radius: 4px;
    display: inline-block;
    padding: 0 18px;
    cursor: pointer;
}
.block-user-sidebar.account-sidebar .block-user-buttons-line .button-wrapper {
    display: none;
}
.block-user-sidebar .main-menu-title {
    font-size: 18px;
    line-height: 18px;
    color: rgba(0,0,0,0.6);
    font-weight: 400;
    letter-spacing: normal;
    margin-top: 24px;
    min-height: 18px;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}
.block-user-sidebar .block-main-menu {
    margin-top: 0;
}
.block-user-sidebar.account-sidebar .block-main-menu {
    padding-bottom: 0;
}
.block-user-sidebar .block-main-menu .main-menu-title.active {
    color: rgba(0,0,0,0.8);
    font-weight: 500;
}
.block-user-sidebar .block-main-menu .main-menu-title-block {
    display: block;
}
.block-user-right {
    padding: 29px 0 100px 0;
    min-height: 100vh;
}
.block-user-right .profile-user-name-block {
    /*padding: 20px 32px 16px 32px;*/
    border-radius: 6px;
    margin-bottom: 32px;
}
.block-user-right .block-notification {
    margin-bottom: 24px;
}
.block-user-right .profile-user-shadow {
    box-shadow: -4px 6px 14px rgba(0,0,0,0.03);
}
.block-user-right .profile-user-name-block .block-new-input input {
    border: 1px solid rgba(151, 151, 151, 0.5);
    color: rgba(0,0,0,0.8);
}
.block-user-right .profile-user-name-block .block-user-basic-input-title {
    font-size: 14px;
    line-height: 24px;
    color: rgba(8,8,8,0.8);
    opacity: 1;
    margin-bottom: 5px;
}
.block-user-right .profile-user-name-block.block-location {
    padding: 28px 32px 31px 38px;
}
.block-user-right .profile-user-name-block.block-location .block-location-title,
.block-user-right .profile-user-name-block.block-social .block-location-title{
    font-size: 22px;
    line-height: 35px;
    font-weight: 500;
    color: rgba(8,8,8,0.8);
}
.block-user-right .profile-user-name-block.block-location .block-location-descr,
.block-user-right .profile-user-name-block.block-social .block-location-descr{
    font-size: 16px;
    line-height: 20px;
    color: rgba(0,0,0,0.75);
}
.block-user-right .profile-user-name-block.block-location .shell-select-reg {
    width: 100%;
}
.block-user-right .profile-user-name-block.block-location .select-block {
    padding: 24px 0 0 20px;
}
.block-user-right .profile-user-name-block .social-links-block .block-cont-540 {
    width: 100%;
    max-width: unset!important;
}
.block-user-right .profile-user-name-block .social-links-block {
    margin-top: 24px;
}
.block-user-right .social-links-block .block-input-min .block-new-input {
    height: 57px;
}
.block-user-right .profile-user-name-block.block-social {
    padding: 25px 32px 28px 32px;
}
.block-user-right .profile-user-name-block.block-photo {
    padding: 16px 37px 17px 40px;
}
.block-user-right .profile-user-name-block.block-contacts {
    padding: 25px 32px 16px 32px;
}
.block-user-right .profile-user-name-block.block-password {
    padding: 29px 32px 6px 32px;
}
.block-user-right .profile-user-name-block.block-photo .block-right-cart-load-img-reg-expert.test-fix {
    align-items: flex-end;
}
.block-user-right .profile-user-name-block.block-photo .photo-title {
    margin: 24px 0 7px 0;
}
.block-user-right .profile-user-name-block.block-photo .title-load-img {
    margin: 3px 0 0 0;
}
.block-user-right .block-photo-descr-text {
    font-size: 16px;
    line-height: 22px;
    color: rgba(0,0,0,0.75);
}
.block-user-right .photo-descr-text-wrapper {
    max-width: 560px;
    padding-top: 2px;
}
.profile-user-name-block .bottom-password-line .button-wrapper {
    padding-left: 25px;
    align-self: flex-end;
    padding-bottom: 18px;
}

.bottom-password-line .button-wrapper .block-button-3-0.button-3-0-blue {
    height: 36px;
    background-color: #2AA2E6;
    border-radius: 4px;
    float: right;
}
.bottom-password-line .button-wrapper .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
    line-height: 36px;
    font-weight: 500;
    letter-spacing: -0.505952px;
}
.block-user-right .profile-user-name-block .block-user-password-title {
    font-size: 16px;
    line-height: 24px;
    color: rgba(0,0,0,0.8);
    opacity: 0.75;
}
.block-user-right .profile-user-name-block.block-password .text-h5 {
    margin: 0 0 19px 0;
}
.block-user-right .shell-input-reg .block-new-input {
    height: 53px;
}
.profile-user-name-block .set-all-wrapper {
    justify-content: flex-end;
}
.profile-user-name-block .set-all-text {
    font-size: 14px;
    line-height: 15px;
    color: #0ECFAA;
    padding-top: 15px;
    cursor: pointer;
}
.block-user-right .block-cart-kyc-reg-expert {
    max-width: 740px;
    padding: 26px 32px 32px 32px;
}
.block-user-right .block-cart-kyc-reg-expert .block-left-cart-kyc-reg-expert {
    width: calc(100% - 208px);
}
.block-user-right .block-right-cart-kyc-reg-expert {
    width: 189px;
}
.block-user-right .shell-input-kyc.input-41 .block-new-input {
    height: 41px;
}
.block-user-right .block-cart-kyc-reg-expert .block-left-cart-kyc-reg-expert .block-left-left-cart-kyc-reg-expert {
    width: 208px;
}
.block-user-right .block-date-input p.title-input-kyc{
    line-height: 21px;
}
.block-user-right .notifications-descr-text {
    font-size: 16px;
    line-height: 20px;
    color: rgba(8,8,8,0.8);
    margin-top: 17px;
}
.block-user-right .button-wrapper .button-reset-wrapper {
    display: inline-block;
}
.block-user-right .button-wrapper .button-reset-wrapper .block-button-3-0.button-3-0-blue {
   background: #2AA2E6;
    border-radius: 4px;
    height: 36px;
}
.block-user-right .button-wrapper .block-button-3-0.button-3-0-blue {
    background: #2AA2E6;
    border-radius: 4px;
    height: 36px;
}
.block-user-right .button-wrapper .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
    line-height: 36px;
    font-weight: 500;
    letter-spacing: -0.505952px;
}

.block-user-right .button-wrapper .button-reset-wrapper {
    margin-left: 15px;
}
.block-user-right .button-wrapper .button-reset-wrapper .block-button-3-0.button-3-0-blue {
    background: transparent;
}
.block-user-right .button-wrapper .button-reset-wrapper .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
    color: #4A4A4A;
    font-weight: 400;
}
.block-user-right .button-wrapper .button-reset-wrapper .block-button-3-0.button-3-0-blue:hover {
    box-shadow: none;
}
.user-edit-container {
    background: #F6F6F6;
}
.new-edit-container .block-new-top-menu-rocket-dao  {
    display: none;
}
.photo-block-wrapper {
    padding: 16px 37px 17px 40px;
}
.name-block-wrapper {
    padding: 24px 32px 18px 32px;
}
.lang-block-wrapper {
    padding: 35px 32px 40px 31px;
}
.location-block-wrapper {
    padding: 24px 39px 16px 31px;
}
.skill-tags-wrapper .block-photo-descr-text.second-text {
    margin-top: 14px;
    line-height: 20px;
    max-width: 500px;
}
.prof-exp-wrapper .prof-exp-title {
    font-size: 16px;
    line-height: 20px;
    color: rgba(8, 8, 8, 0.8);
    padding-left: 3px;
}
.block-user-right .prof-exp-wrapper .prof-exp-button-wrapper {
    margin-top: 36px;
}
.block-user-right .prof-exp-wrapper .prof-exp-button-wrapper .block-button-3-0.button-3-0-blue {
    height: 40px;
}
.block-user-right .prof-exp-wrapper .prof-exp-button-wrapper .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
    line-height: 40px;
    font-weight: 500;
}
.block-user-right .prof-exp-wrapper .new-col.c-2 {
    min-width: 235px;
}
.block-user-right .prof-exp-wrapper .block-button-circle {
    position: relative;
    top: 7px;
}
.block-user-right .prof-exp-wrapper .block-button-circle i {
    position: absolute;
}
.block-user-right .prof-exp-wrapper .block-top-select img {
    top: 15px;
}
.block-user-right .prof-exp-wrapper .block-list-experience {
    margin-top: 25px;
}
.block-user-right.big-user-right {
    max-width: 805px;
}

.block-user-right .block-item-title-description-more .block-button-delete-title-description-more {
    position: absolute;
    top: 0;
    right: 0;
}
.block-user-right .block-item-title-description-more {
    height: auto;
    min-height: 48px;
}
.block-user-right .block-left-item-title-description-more {
    float: unset;
    height: unset;
    min-height: 48px;
}
.block-user-right .block-item-title-description-more p.description-item-title-description-more {
    padding: 10px 0;
}

.block-user-right .validation-button-wrapper {
    padding-top: 2px;
}
.block-user-right .validation-button-wrapper .block-button-3-0.button-3-0-blue {
    float: right;
    height: 36px;
}
.block-user-right .validation-button-wrapper .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
    line-height: 36px;
    letter-spacing: -0.505952px;
    font-weight: 500;
}
.block-user-right .block-cart-kyc-reg-expert .block-load-img-kyc .block-button-3-0.button-3-0-gray {
    height: 41px;
}
.block-user-right .block-cart-kyc-reg-expert .block-load-img-kyc .block-button-3-0.button-3-0-gray p.block-button-3-0-title {
    line-height: 41px;
    color: #525252;
    font-weight: 500;
}
.block-user-right .button-wrapper {
    justify-content: flex-end;
}
.block-user-right .buttons-personal-info {
    padding-right: 16px;
    margin-top: -12px;
}
.block-user-right .buttons-photo {
    margin-top: 26px;
}
.block-user-right .buttons-skills {
    justify-content: flex-start;
    margin-top: 35px;
}

.block-user-right .password-apply-wrapper {
    position: relative;
}
.block-user-right .password-apply-wrapper .block-button-3-0.button-3-0-blue {
    position: absolute;
    bottom: 17px;
    right: 10px;
    height: 36px;
}
.block-user-right .password-apply-wrapper .block-button-3-0.button-3-0-blue p.block-button-3-0-title  {
    line-height: 36px;
    font-weight: 500;
    letter-spacing: -0.505952px;
}
.block-user-right .block-right-cart-kyc-reg-expert .block-top-select img {
    top: 14px;
}

.margin-bottom-12 {
    margin-bottom: 12px;
}



@media all and (max-width: 1199px) {
    .block-user-right .prof-exp-wrapper .container-inline-checkbox {
        margin: 0 0 35px 0;
    }
    .block-user-right .prof-exp-wrapper .block-left-add-experience-cart {
        margin-bottom: 0;
    }
    .block-user-right .prof-exp-wrapper .block-right-add-experience-cart.top-cart {
        margin-top: 15px;
    }
    .block-user-right .block-right-cart-kyc-reg-expert .shell-select-kyc {
        margin-top: 15px;
    }
}
@media all and (max-width: 900px) {
    .block-user-right {
        padding: 34px 0 100px 0;
    }
}
@media screen and (max-width: 861px) {
    .block-user-right {
        min-height: unset;
    }
    .block-user-right .notifications-descr-text {
        padding: 0 16px;
    }
}
@media all and (max-width: 700px) {
    .block-user-right .block-left-item-title-description-more {
        flex-direction: column;
        height: auto;
        padding: 10px 0;
        float: none;
    }
    .block-user-right .block-item-title-description-more {
        height: auto;
    }
    .block-user-right .block-item-title-description-more p.title-item-title-description-more {
        padding: 4px 0 12px 5px;
    }
    .block-user-right .block-item-title-description-more p.description-item-title-description-more {
        font-size: 14px;
        padding: 0 18px 4px 8px;
        width: 100%;
    }
    .block-left-item-title-description-more {
        width: 100%;
    }
    .block-user-right .block-item-title-description-more .block-button-delete-title-description-more {
        width: 15px;
        height: 15px;
        top: 15px;
        right: 15px;
    }
    .block-user-right .block-item-title-description-more .block-button-delete-title-description-more:before,
    .block-user-right .block-item-title-description-more .block-button-delete-title-description-more:after {
        top: 7px;
        left: 3px;
    }
}

@media all and (max-width: 620px) {
    .block-user-right .prof-exp-wrapper .block-button-circle {
        top: 2px;
    }
    .block-user-right .prof-exp-wrapper .block-list-experience {
        margin-right: -15px;
    }
}

@media all and (max-width: 576px) {
    .block-user-right {
        padding: 34px 0 100px 0;
    }
    .photo-block-wrapper {
        padding: 25px 16px 16px 16px;
    }
    .block-user-right .photo-descr-text-wrapper {
        padding: 0 16px;
    }
    .skill-tags-wrapper {
        padding: 36px 16px 0 16px;
    }
    .skill-tags-wrapper .block-photo-descr-text.second-text {
        margin-top: 10px;
    }
    .block-user-right .prof-exp-wrapper {
        padding: 26px 16px 16px 16px;
    }
    .block-user-right .prof-exp-wrapper .prof-exp-button-wrapper {
        margin-top: 25px;
    }
    .block-user-sidebar {
        padding: 14px 16px 19px 15px;
    }
    .block-user-sidebar .block-user-buttons-line .button-wrapper {
        width: 100%;
    }
    .block-user-sidebar .block-user-buttons-line {
        margin-bottom: 0;
    }
    .block-user-sidebar .block-main-menu {
        padding-bottom: 35px;
    }
    .block-user-sidebar.account-sidebar .block-user-buttons-line {
        height: 30px;
    }
    .block-user-right .notifications-descr-text {
        display: none;
    }
}
@media all and (max-width: 805px) and (min-width: 768px) {
    .block-user-right .profile-user-name-block.block-photo {
        padding: 16px 15px 17px 25px;
    }
}
@media all and (max-width: 500px) {
    .mobile-menu-hide .block-new-top-menu-rocket-dao {
        /*display: none;*/
        background: rgb(246, 246, 246);
    }
    .user-edit-container {
        padding-left: 0!important;
    }
    .block-user-right {
        padding: 0 0 100px 0;
        /*width: calc(100% + 10px);*/
    }
    .block-user-right .profile-user-name-block {
        box-shadow: none;
        margin: 0;
        border-radius: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.3);
    }
    .block-user-right .profile-user-name-block:first-child {
        border-top:none;
    }
    .name-block-wrapper {
        padding: 25px 16px 16px 16px;
    }
    .lang-block-wrapper {
        padding: 32px 16px 32px 16px;
    }
    .location-block-wrapper {
        padding: 32px 16px 18px 16px;
    }
    .block-user-right .photo-descr-text-wrapper {
        padding: 23px 16px 0 16px;
    }
    .block-user-right .profile-user-name-block.block-social {
        padding: 28px 16px;
    }
    .block-user-right .profile-user-name-block.block-social .block-location-descr{
        margin-top: 8px!important;
    }
    .block-user-right .profile-user-name-block.block-contacts {
        padding: 25px 16px 16px 16px;
    }
    .block-user-right .profile-user-name-block.block-password {
        padding: 39px 16px 20px 16px;
    }
    .block-user-right .profile-user-name-block.block-password .text-h5 {
        margin-bottom: 34px;
    }
    .block-user-right .profile-user-name-block .block-user-password-title {
        margin-top: 7px;
    }
    .block-user-right .profile-user-name-block .block-user-password-title.last-title {
        margin-top: 11px;
    }
    .block-user-right .block-notification {
       box-shadow: none;
        padding: 0;
        margin-bottom: 25px;
        width: 100%;
    }
    .block-user-right .first-notification-block {
        margin-top: 22px;
    }
    .new-edit-container .block-new-top-menu-rocket-dao  {
        display: block;
        background: rgb(246, 246, 246);
    }
}


