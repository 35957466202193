.textarea{
    border-radius: 4px;
    width: 100%;
    border: 1px solid rgba(151, 151, 151, 0.5);
    display: block;
}

.textarea textarea{
    background-color: #FFFFFF;
    overflow: hidden;
    resize: none !important;
    min-height: 82px;
    width: 100% !important;
    color: #000000;
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    padding: 12px 16px !important;
    outline: none;
    transition: .3s;
    font-weight: 300;
    border: none !important;
    white-space: pre-wrap !important;
    display: block !important;
    /*text-indent:-3px !important;*/
    box-sizing: border-box !important;
    padding-bottom: 0 !important;
}

/*.wrap:before,*/
/*.wrap:after{*/
    /*content: ' ';*/
    /*height: 16px;*/
    /*display: block;*/
    /*background: transparent;*/
    /*width: 100%;*/
/*}*/

.textarea .wrap{}

.noShow{
    position: absolute !important;
    left: -10000px !important;
    top: -10000px !important;
}

.w100{
    width: 100%;
}

.mw380{
    max-width: 380px;
}

.mw620{
    max-width: 620px;
}