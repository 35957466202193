
.block-expert-task-list {
	position: fixed;
	left: 56px;
	height: 100vh;
	width: 320px;
	background-color: #F6F6F6;
	padding: 30px 45px 50px 80px;
}
.block-expert-task-list .block-expert-task-list-header {
	padding-bottom: 40px;
	border-bottom: 1px solid #D8D8D8;
}
.block-expert-task-list-header .task-list-title {
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
	letter-spacing: -0.505952px;
	color: #2F3849;
}
.block-task-list-categories .task-list-category {
	margin-top: 18px;
}
.block-task-list-categories .task-list-category .title {
	float: left;
	margin-right: 12px;
	font-weight: 500;
	font-size: 18px;
	line-height: 18px;
	color: #343434;
}
.block-task-list-categories .task-list-category .count {
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.708333px;
	color: rgba(8, 8, 8, 0.8);
}
.block-task-list-categories .task-list-subtitle {
	margin-top: 6px;
	font-size: 11px;
	line-height: 13px;
	color: #979797;
	
}