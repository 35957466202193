.zoom-inp input {
    width: 111px!important;
    display: block!important;
}
.add-new-btn {
    margin: -50px 0 50px 0;
}
.add-new-btn .block-button-load-img{
    position: inherit;
    /*left: auto;*/
    /*left: -7px;*/
    margin-top: 5px;
    background-color: #efefef;
    cursor: pointer;
    top: 0;
}
.block-load-img img.user-avatar {
    width: 100%;
}
input[type=range] {
    -webkit-appearance: none;
    background-color: transparent;
    vertical-align: top;
    margin: 2px 0;
    width: 200px;
}
input[type=range]:hover::-webkit-slider-thumb:before,
input[type=range]:hover::-webkit-slider-thumb:after {
    opacity: 1;
    transition: opacity 0.2s ease;
}
input[type=range]:focus {
    outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    border: 1px solid #9B9B9B;
    background-color: #9B9B9B;
    border-radius: 3px;
    margin: 0;
    box-sizing: border-box;
    -webkit-transform: translate(0, 6px);
    -ms-transform: translate(0, 6px);
    transform: translate(0, 6px);
}
input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    border: 1px solid #383838;
    background-color: #181818;
    border-radius: 2px;
    margin: 0;
    box-sizing: border-box;
}
input[type=range]::-moz-range-track:hover::-moz-range-thumb:before,
input[type=range]::-moz-range-track:hover::-moz-range-thumb:after {
    opacity: 1;
    transition: opacity 0.2s ease;
}
input[type=range]::-ms-fill-lower,
input[type=range]::-ms-fill-upper {
    width: 100%;
    height: 4px;
    border: 1px solid #383838;
    background-color: #181818;
    border-radius: 2px;
    margin: 0;
    box-sizing: border-box;
}
input[type="range"]::-ms-track {
    -webkit-transform: translate(0, 6px);
    -ms-transform: translate(0, 6px);
    transform: translate(0, 6px);
    background: transparent;
    color: transparent;
    height: 16px;
    border: 6px solid transparent;
}
input[type="range"]::-ms-track:hover::-ms-thumb:before,
input[type="range"]::-ms-track:hover::-ms-thumb:after {
    opacity: 1;
    transition: opacity 0.2s ease;
}
input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border: 3px solid #2AA2E6;
    background: #2AA2E6;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    -webkit-transform: translate(0, -5px);
    -ms-transform: translate(0, -5px);
    transform: translate(0, -5px);
}
input[type=range]::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border: 3px solid #0028DC;
    background: #0028DC;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
}
input[type="range"]::-ms-thumb {
    width: 12px;
    height: 12px;
    border: 3px solid #0028DC;
    background: #0028DC;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    -webkit-transform: translate(0, -5px);
    -ms-transform: translate(0, -6px);
    transform: translate(0, -5px);
}
input[type='range']::-webkit-slider-thumb:before {
    content: "1337";
    display: block;
    position: absolute;
    bottom: 16px;
    left: 2px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    border-radius: 16px;
    padding: 4px 8px 2px 8px;
    box-sizing: border-box;
    color: #181818;
    background: #383838;
    font-size: 12pt;
    text-align: center;
    opacity: 0;
    transition: opacity 0.2s ease 1s;
    z-index: 1;
}
input[type='range']::-webkit-slider-thumb:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 24px;
    left: 2px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    background-color: #282828;
    border: 2px solid #383838;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease 1s;
    z-index: 2;
}
input[type=range]::-moz-range-thumb:before {
    content: "1337";
    display: block;
    position: absolute;
    bottom: 16px;
    left: 2px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    border-radius: 16px;
    padding: 4px 8px 2px 8px;
    box-sizing: border-box;
    color: #181818;
    background: #383838;
    font-size: 12pt;
    text-align: center;
    opacity: 0;
    transition: opacity 0.2s ease 1s;
    z-index: 1;
}
input[type=range]::-moz-range-thumb:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 12px;
    left: 2px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    background-color: #282828;
    border: 2px solid #383838;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease 1s;
    z-index: 2;
}
input[type="range"]::-ms-thumb:before {
    content: "1337";
    display: block;
    position: absolute;
    bottom: 16px;
    left: 2px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    border-radius: 16px;
    padding: 4px 8px 2px 8px;
    box-sizing: border-box;
    color: #181818;
    background: #383838;
    font-size: 12pt;
    text-align: center;
    opacity: 0;
    transition: opacity 0.2s ease 1s;
    z-index: 1;
}
input[type="range"]::-ms-thumb:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 12px;
    left: 2px;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: 8px;
    height: 8px;
    box-sizing: border-box;
    background-color: #282828;
    border: 2px solid #383838;
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.2s ease 1s;
    z-index: 2;
}
input[type="range"]::-ms-tooltip {
    display: none;
}