.shell-pre-loader{
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999998;
}
.shell-pre-loader.white-load{
    background: rgba(255, 255, 255, 0.8);
}
.shell-pre-loader.display-none {
    display: none;
}
.block-pre-loader{
    width: 100%;
    height: 100px;
    margin: auto;
}
.shell-pre-loader-circle{
    width: 88px;
    height: 88px;
    margin: 0 auto;
    position: relative;
    display: flex;
}

.pre-loader-circle-1{
    width: 88px;
    height: 88px;
    background-color: rgba(0, 240, 130, 0.5);
    border-radius: 50%;
    z-index: 1;
    animation: loader-c-1 1.8s infinite;
    transition: .4s linear;
    margin: auto;
    display: flex;
}
.pre-loader-circle-2{
    width: 31px;
    height: 31px;
    background-color: rgba(0, 240, 130, 0.5);
    border-radius: 50%;
    z-index: 2;
    margin: auto;
    animation: loader-c-2 1.8s infinite;
    transition: .4s linear;
}
.shell-pre-loader.white-load .title-loader{
    color: #4A4A4A;
}
.title-loader{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.51px;	line-height: 20px;
    margin: 20px 0 0 0; font-weight: 300; text-align: center;

}


@keyframes loader-c-1 {
     0% {
         height: 88px;  width: 88px;
     }
     50% {
         height: 58px;	width: 58px;
     }
     100% {
         height: 88px;  width: 88px;
     }
 }

@keyframes loader-c-2 {
    0% {
        height: 18px;	width: 18px;
    }
    50% {
        height: 44px;	width: 44px;
    }
    100% {
        height: 18px;	width: 18px;
    }
}