

.block-container-755{
    width: 755px;
    margin: 0 auto;
}

.block-container-1140{
    width: 1140px;
    margin: 0 auto;
}
.margin-top-5{
    margin-top: 5px!important;
}

.margin-top-10{
    margin-top: 10px!important;
}

.margin-top-30{
    margin-top: 30px!important;
}
.padding-top-25{
    padding-top: 25px;
}
.float-left-49{
    width: 48%;
    float: left;
    overflow: hidden;
}
.float-left-50{
    width: 49%;
    float: left;
    overflow: hidden;
}
.float-left-80{
    width: 79%;
    float: left;
    overflow: hidden;
}
.float-left-85{
    width: 84%;
    float: left;
    overflow: hidden;
}
.float-right-49{
    width: 48%;
    float: right;
    overflow: hidden;
}
.float-right-50{
    width: 49%;
    float: right;
    overflow: hidden;
}
.float-right-20{
    width: 19%;
    float: right;
    overflow: hidden;
}
.float-right-15{
    width: 14%;
    float: right;
    overflow: hidden;
}