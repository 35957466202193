@import '../../v4/CONST';

.god-menu{
  position: fixed;
  height: 100vh;
  //width: 56px;
  top: 0;
  left: 0;
  z-index: 10000;
  transition: transform 0.16s linear;
  transform: translateX(0px);
  &.god-close{
    transform: translateX(-56px);
  }

  &.loading{
    background: red;
  }

  .main-menu{
    width: 100%;
    min-height: 100%;
    position: relative;
    z-index: 200;
    margin: 0;
    padding: 0 11px;
    background: #2F3849;
    box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.15);
    .bs-test-remove{
      width: 50px;
      height: 34px;
      //background: #00c46b;
      text-align: center;
      line-height: 50px;
      vertical-align: center;
      margin: 0 auto 13px auto;
    }
  }
  .main-logo {
    width: 36px;
    height: 37px;
    padding-top: 13px;
    margin: 0 0 24px -1px;
    cursor: pointer;
    img {
      width: 36px;
      height: 24px;
    }
  }

  .icons-block {
    padding: 0 5px;
    margin-bottom: 30px;
    .icon {
      margin-bottom: 8px;
      &.chat {
        margin-bottom: 10px;
      }
    }
  }

  .user-avatar {
    position: absolute;
    bottom: 50px;
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
  }

  .user-settings {
    position: fixed;
    width: auto;
    left: 50px;
    bottom: 25px;
    background: #FFFFFF;
    border-radius: 8px;
    transition: 0.4s;
    visibility: hidden;
    opacity: 0;
    &.active {
      visibility: visible;
      opacity: 1;
      .settings-popup-line,
      .settings-popup-line-name {
        cursor: pointer;
      }
    }
    .settings-popup-line,
    .settings-popup-line-name {
      height: 40px;
      padding: 0 8px 0 15px;
      transition: .3s;
    }
    .settings-popup-line {
      border-top: 1px solid rgba(0, 0, 0, 0.3);
    }
    .settings-popup-line.first-title {
      border-top: none;
      position: relative;
    }
    .settings-popup-line.first-title:before {
      content:'';
      position: absolute;
      left: 0;
      top: 0;
      height: 1px;
      width: 100%;
      opacity: 0.1;
      background: rgba(0, 0, 0, 0.3);
    }
    .settings-popup-title,
    .settings-popup-name {
      font-size: 14px;
      line-height: 39px;
      color: #2F3849;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &.last-title {
        border-bottom-right-radius: 8px;
      }
    }
    .settings-popup-name {
      color: rgba(0, 0, 0, 0.5);
    }
    .settings-popup-line:hover {
      background-color: rgba(0,0,0,0.1);
    }
  }
  .user-settings-mobile {
    display: none;
  }

  .menu-logo{
    width: 48px;
    height: 48px;
    text-align: center;
    img{
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
      justify-content: center;
    }
  }

  .role-menu-item{
    height: 56px;
    position: relative;
    &:after{
      content: ' ';
      border-bottom: 1px solid #6D737F;
      width: 100%;
      height: 1px;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    font-size: 18px;
    line-height: 19px;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
    &.disable{
      opacity: 0.3;
      cursor: default;
    }
  }

  .never-hide-menu,
  .sub-menu{
    top: 0;
    left: 0;
    max-width: 300px;
    width: 300px;
    height: 100vh;
    position: fixed;
    background: #2F3849;
    transition: transform 0.22s linear;
    -webkit-transform: translate3d (-300px, 0, 0);
    transform: translate3d(-300px, 0, 0);
    //will-change: transform;
    z-index: 80;

    &.active{
      transform: translate3d(56px,0,0);
      -webkit-transform: translate3d(56px, 0, 0);
    }

    &.likes {
      background: #F6F6F6;
      .wrapper {
        padding: 0;
        .new-like-block-to-list {
          width: 100%;
          padding-left: 10px;
          .main-block {
            width: calc(100% - 100px);
            .subtitle {
              width: auto;
              max-width: 140px;
            }
          }
          .block-info {
            padding-right: 12px;
          }
        }
      }
    }
    &.about {
      .wrapper {
        padding: 16px;
      }
    }
    &.startup {
      .sub-block{
        height: auto;
        .title {
          line-height: 19px;
          margin-top: -4px;
          max-height: 37px;
          overflow-y: hidden;
        }
        .type {
          margin-top: 5px;
        }
      }
    }

    .sub-head{
      height: 65px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      .title{
        @extend %menu-title;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.black {
        .title{
          color: rgba(0,0,0,0.8);
        }
        .close-block {
          position: unset;
          width: 24px;
          height: 24px;
        }
        .h_back {
          .fa-long-arrow-left {
            line-height: 25px;
          }
        }
        .h_close {
          height: 24px;
        }
      }

      .h_back,
      .h_close {
        height: 25px;
      }
    }

    .sub-block{
      position: relative;
      &:after{
        content: ' ';
        border-bottom: 1px solid #6D737F;
        width: 100%;
        left: 0;
        right: 0;
        height: 1px;
        display: block;
        position: absolute;
        bottom: 0;

      }
      //border-bottom: 1px solid #6D737F;
      min-height: 80px;
      padding: 16px 8px;
      cursor: pointer;
      .logo{
        width: 48px;
        height: 48px;
      }



      .title{
        font-size: 16px;
        line-height: normal;
        letter-spacing: -0.708333px;
        color: #FFFFFF;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        &.create-title {
          letter-spacing: unset;
          margin-bottom: 8px;
        }
      }


      .type{
        color: rgba(255, 255, 255, 1);
        font-size: 12px;
        line-height: 15px;
      }
      .description{
        font-size: 12px;
        line-height: 15px;
        color: rgba(255, 255, 255, 0.6);
      }
    }

    &.never-hide{
      z-index: 50 !important;

      .h_back,
      .h_close{
        display: none;
      }

    }



  }

  .sub-menu{
    .h_back.edit{
      display: none;
    }
  }

  .profile-menu{
    .description{
      font-size: 14px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.8);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .inner{
      padding: 0 8px;
    }
  }

  .never-hide-menu,
  .role-menu{
    z-index: 90 !important;


  }

  .out-menu{

    z-index: 90;

    &.never-hide{
      z-index: 55 !important;
    }
  }

  .rw{
    padding: 0 13px;
  }

  .p13{
    padding-top: 13px;
  }

  .wb{
    padding-top: 16px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .menu-icon:not(:last-child){
    margin-bottom: 13px;
  }

  .mobile-links {
    display: none;
    padding: 14px 0 2px 0;
    .title {
      @extend %menu-title;
      line-height: 26px;
      margin-bottom: 18px;
    }
  }
  .mobile-button-close {
    display: none;
  }
}


@media all and (max-width: 756px) {
  .fullW{
    width: calc(100% + 16px) !important;
  }
  .god-menu{
    width: 100%;
    overflow: auto;
    &.god-close{
      transform: translateX(-100%);
    }

    .never-hide-menu,
    .sub-menu {
      transform: translateX(-110%);
      max-width: 100%;
      width: 100%;
    }

    .never-hide-menu{
      z-index: 2100 !important;
      &.active{
        transform: translateX(0%);

      }


      &.never-hide{
        z-index: 50000 !important;
        .h_back,
        .h_close{
          display: flex;
        }
      }
    }

    .main-menu{
      z-index: 1000;
      padding: 0 16px;
    }

    .sub-menu{
      max-width: 100%;
      z-index: 2000;

      .sub-head{
        .h_back{
          display: flex;
        }
        .h_back.edit{
          display: flex;
        }
      }


      .sub-block{
        &:after{
          width: calc(100% - 32px);
          right: unset;
          left: 16px;
        }
      }
      &.active{
        transform: translateX(0%);
      }
      &.likes {
        //.wrapper{
        //  padding: 0 8px;
        //}
      }
    }
    .icons-block {
      padding: 24px 5px;
      margin: 0;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
    }
    .profile-buttons-wrapper {
      padding: 24px 0 43px 0;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
    }
    .user-avatar {
      display: none;
    }
    .user-settings-mobile {
      display: block;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      padding: 21px 0 14px 0;
      &.login-wrapper {
        padding: 18px 0 14px 0;
      }
      .settings-popup-title {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.505952px;
        color: #FFFFFF;
        margin-bottom: 11px;
      }
    }
    .mobile-links {
      display: block;
    }
    .mobile-button-close {
      display: block;
      position: absolute;
      right: 16px;
      top: 20px;
    }
  }
}
