.block-shadow-gray-document{
    width: 1000px;
    position: absolute;
    /*height: 100vh;*/
    background-color: rgba(8, 8, 8, 0.05);
    top: 0;
    right: 50px;
    bottom: 0;

}



.index-document{

    width: 100%;
    background: #FFFFFF;
    overflow-y: hidden;
}
.index-document .sticky-meta {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.block-list-item-index-document{
    width: 225px;
    /*float: right;*/
    position: sticky;
    top: 120px;
    z-index: 3;

    margin: 0 50px 0 auto;
}
.block-list-item-index-document .block-item-index-document{
    border-bottom: 1px solid #FFFFFF;
    cursor: pointer;
}
.block-list-item-index-document .block-item-index-document a{
    text-decoration: none;
}
.block-list-item-index-document .block-item-index-document:last-child{
    border-bottom: 0;
}
.block-list-item-index-document .block-item-index-document p.title-item-index-document{
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    margin: 0;
    transition: .1s;
    text-decoration: none;
    padding: 12px 30px 12px 0;
}
.block-list-item-index-document .block-item-index-document:hover p.title-item-index-document{
    color: #000000;
    text-decoration: underline;
    font-weight: 400;
}
.block-list-item-index-document .block-item-index-document.active p.title-item-index-document{
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    font-weight: 500;
}
.block-list-item-index-document .block-item-index-document.active:hover p.title-item-index-document{
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
    font-weight: 500;
}
.block-right-index-document{

    /*width: calc(100% - 440px);*/
    padding: 0 0 100px 0;
    /*float: right;*/
    background: #FFFFFF;

}


.index-document .document{

}
.index-document .document p{
    max-width: 620px;
}
.index-document .document h1{
    max-width: 620px;
    font-family: "Stratos LC";
    font-size: 38px;
    line-height: 48px;
    color: rgba(8, 8, 8, 0.8);
    margin: 40px 0 0 0;
}
.index-document .document h2{
    max-width: 620px;
    font-family: "Stratos LC";
    font-size: 28px;
    line-height: 38px;
    color: rgba(8, 8, 8, 0.8);
    margin: 30px 0 0 0;
}
.index-document .document h3{
    font-size: 16px;
    font-family: "Stratos LC";
    line-height: 20px;
    max-width: 620px;
    color: rgba(8, 8, 8, 0.8);
    margin: 24px 0 0 0;
}

.index-document .heading-1{}
.index-document .heading-2{}
.index-document .heading-3{}
.index-document .description{
    padding: 0 0 0 17px;
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 20px;
    color: rgba(8, 8, 8, 0.8);
    margin: 18px 0 0 0;
    font-weight: 300;
}
.index-document .description-dots{
    padding: 0 0 0 35px;
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 20px;
    color: rgba(8, 8, 8, 0.8);
    margin: 0;
    position: relative;
    font-weight: 300;

}
.index-document .description-dots span.dot{
    position: absolute;
    width: 6px;
    height: 6px;
    background: #000000;
    border-radius: 50%;
    top: 7px;
    left: 22px;
}
.index-document .description-number{
    padding: 0 0 0 20px;
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 20px;
    color: rgba(8, 8, 8, 0.8);
    margin: 18px 0 0 0;
    position: relative;
    font-weight: 300;

}
.index-document .description-number span.number{
    position: absolute;
    top: 0px;
    font-weight: 500;
    left: 7px;
}

.index-document .description a{
    color: #2CA6EB;
    text-decoration: none;
}
.description a:hover{
    text-decoration: underline;
}

@media (min-width: 1200px){
    .block-left-index-document{
        position: relative;
        /*width: 440px;*/
        padding: 70px 0 0 0;
        /*background: rgba(8, 8, 8, 0.05);*/
        /*float: left;*/
        /*position: fixed;*/
        /*top: 0;*/
        /*left: 0;*/
        /*bottom: 0;*/
        /*display: table;*/
        /*height: 100vh;*/

    }
    .block-right-index-document{

        width: calc(100% - 440px);
        padding: 0 0 100px 0px;
        float: right;
        background: #FFFFFF;

    }
}
@media (max-width: 1199px){
    .block-left-index-document{

        /*width: 290px;*/
        padding: 70px 0 0 0;
        /*background: rgba(8, 8, 8, 0.05);*/
        /*float: left;*/
        /*position: fixed;*/
        /*top: 0;*/
        /*left: 0;*/
        /*bottom: 0;*/
        /*display: table;*/
        /*height: 100vh;*/

    }
    .block-right-index-document{

        /*width: calc(100% - 290px);*/
        padding: 0 0 100px 0px;
        /*float: right;*/
        background: #FFFFFF;

    }
}
@media (max-width: 860px){
    .block-left-index-document{

        /*width: 100%;*/
        padding: 20px 0 20px 25px;
        /*background: rgba(8, 8, 8, 0.05);*/
        float: none;
        position: relative;
        /*top: 0;*/
        /*left: 0;*/
        /*bottom: 0;*/
        /*display: table;*/
        /*height: auto;*/

    }
    .block-list-item-index-document{
        /*width: 100%;*/
        /*float: right;*/
        margin: 0;
    }
    .block-right-index-document{

        /*width: 100%;*/
        padding: 0 25px 50px 0px;
        /*float: none;*/
        background: #FFFFFF;

    }
    .block-shadow-gray-document{
        width: calc(100% + 32px);
        right: -16px;
    }
}
@media (max-width: 576px) and (orientation: portrait) {
    .container.documents-container {
        max-width: unset;
    }
}