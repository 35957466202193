.block-check-box-blue{
    height: 23px;	width: 23px;

    border: 2px solid #2CA6EB;
    border-radius: 50%;

    transition: .3s;
    position: relative;
}
.block-check-box-blue.active{
    background-color: #2CA6EB;
    box-shadow: 0 8px 19px 0 rgba(32,165,238,0.15);
}
.block-check-box-blue:after, .block-check-box-blue:before{
    content: '';
    position: absolute;
    background: #FFFFFF;
    height: 2px;
}
.block-check-box-blue:after{
    width: 8px;
    transform: rotate(40deg);
    top: 10px;
    left: 2px;
}
.block-check-box-blue:before{
    width: 12px;
    transform: rotate(-45deg);
    top: 9px;
    left: 6px;
}

.block-box-check-box-blue{
    height: 18px;	width: 18px;
    border-radius: 2px;
    border: 1px solid #979797;
    transition: .3s;
    position: relative;
}
.block-box-check-box-blue.active{
    background-color: #2CA6EB;
}
.block-box-check-box-blue:after, .block-box-check-box-blue:before{
    content: '';
    position: absolute;
    background: #FFFFFF!important;
    height: 2px;
}
.block-box-check-box-blue:after{
    width: 6px;
    transform: rotate(40deg);
    top: 9px;
    left: 3px;
}
.block-box-check-box-blue:before{
    width: 9px;
    transform: rotate(-50deg);
    top: 7px;
    left: 6px;
}