.top-header-row {
  background: #161616;
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 400;
  width: 100%;
  padding: 0 20px;
  display: flex;
  height: 42px;
  + * {
    top: 42px !important;
  }
}
.top-header-title {
  font-size: 15px;
  line-height: 26px;
  color: rgba(255,255,255,.8);
  margin: 0;
  padding: 8px 0;
  white-space: nowrap;
}

.top-header-menu-open-btn,
.top-header-menu-close-btn {
  display: none;
}
.top-header-menu {
  display: flex;
  margin: 0 -12px 0 16px;
  padding: 0;
  list-style: none;
  flex: 1 0 auto;
  justify-content: center;
}
.top-header-menu-elem {
  position: relative;
  z-index: 2;
  max-width: 142px;
  width: 100%;
  &::before {
    content: '';
    width: 1px;
    height: 17px;
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &.active, &:first-child, &.active + * {
    &::before {
      display: none;
    }
  }
  &.active {
    z-index: 1;
    margin: 0 -8px;
    max-width: 228px;
    &::before, &::after {
      content: '';
      width: 16px;
      height: auto;
      top: 0;
      bottom: 0;
      background: #161616;
      display: block;
      position: absolute;
      z-index: 1;
    }
    &::before {
      left: -8px;
      right: auto;
      border-radius: 0 0 8px 0;
    }
    &::after {
      right: -8px;
      left: auto;
      border-radius: 0 0 0 8px;
    }
    .top-header-menu-link {
      font-size: 22px;
      line-height: 26px;
      color: #161616;
      background: #fff;
      padding: 8px 20px;
    }
  }
  &:last-child .top-header-menu-link[data-popover]:hover::before {
    width: 100%;
  }
}
.top-header-menu-link {
  color: #0ECFAA;
  font-size: 15px;
  line-height: 26px;
  text-decoration: none;
  padding: 8px 12px;
  width: 100%;
  text-align: center;
  display: block;
  position: relative;
  &[data-popover]:hover::before {
    content: attr(data-popover);
    position: absolute;
    left: 50%;
    width: 220px;
    padding: 10px;
    transform: translateX(-50%);
    background: #383838;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.1), -1px 5px 14px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    color: #fff;
    top: 80%;
    font-size: 11px;
    line-height: 13px;
    text-align: left;
    margin-top: 9px;
    opacity: 0;
    animation: data-popover 0.3s forwards;
  }
  &[data-popover]:hover::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 10px 7px;
    border-color: transparent transparent #383838 transparent;
    position: absolute;
    left: 50%;
    margin-left: -7px;
    top: 80%;
    opacity: 0;
    animation: data-popover 0.3s forwards;
    pointer-events: none;
  }
}
@keyframes data-popover {
  0% {
    opacity: 0;
    top: 80%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

@keyframes data-menu-close {
  0% {
    top: -100%;
  }
  100% {
    top: 0;
  }
}
@keyframes data-menu-close-rev {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}



@media (max-width: 900px) {

  .top-header-menu-open-btn {
    display: block;
    background-color: transparent;
    width: 42px;
    padding: 0;
    border: none;
    margin: 0 -11px 0 auto;
    outline: none;
    position: relative;
    &::before {
      content: '';
      height: 2px;
      width: 20px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      background: #fff;
      box-shadow: 0 -7px 0 0 #fff, 0 7px 0 0 #fff;
    }
  }
  .top-header-menu-close-btn {
    display: none;
    background-color: transparent;
    left: 0;
    right: 0;
    top: -100%;
    height: 42px;
    width: 100%;
    padding: 0;
    border: none;
    margin: 0 0 0 auto;
    outline: none;
    position: absolute;
    animation: data-menu-close 0.3s forwards 0.2s;
  }

  .top-header-row {
    &:hover {
      .top-header-menu {
        display: flex;
      }
      .top-header-menu-close-btn {
        top: 0;
        display: block;
        animation: data-menu-close 0.3s forwards;
        &:focus,
        &:active {
          animation: data-menu-close-rev 0.3s forwards 0.2s;
          ~ .top-header-menu {
            display: none;
          }
        }
      }
    }
  }

  .top-header-menu {
    position: absolute;
    display: none;
    flex-direction: column;
    align-items: center;
    background: #161616;
    z-index: 1000;
    right: 0;
    padding: 20px;
    width: 100%;
    text-align: center;
    margin: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    top: 80%;
    opacity: 0;
    animation: data-popover 0.3s forwards;
    &:hover {
      display: flex;
    }
  }
  .top-header-menu-elem {
    &:hover {
      z-index: 5;
    }
    &::before {
      content: '';
      width: 17px;
      height: 1px;
      background: rgba(255, 255, 255, 0.4);
      position: absolute;
      left: 0;
      top: 0;
      bottom: auto;
      margin: auto;
      right: 0;
    }
  }
}