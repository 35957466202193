/** {*/
/*    transition: all linear 0.25s;*/
/*}*/
.block-viral-answer-list {
    width: 100%;
    max-width: 615px;
    transform: translateY(0px);
    transition: .5s;
    margin: 0px auto 0px auto;
}
.block-viral-answer-list .question-text {
    font-size: 23px;
    line-height: 28px;
    color: #FFFFFF;
    cursor: default;

}

.block-viral-answer-point {
    margin-top: 8px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 3px 18px rgba(0, 0, 0, 0.3);
    border-radius: 11px;
    padding: 12px 15px;
    position: relative;
    cursor: default;
}
.block-viral-answer-point .choise-point {
    top: 50%;
    margin-top: -8px;
    position: absolute;
    float: left;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #e1e1e1;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
}
.block-viral-answer-point .block-answer-text {
    height: 100%;
    padding-left: 30px;
}
.block-viral-answer-point .answer-text {
    font-size: 16px;
    line-height: 20px;
    color: #000000;
}
.block-viral-answer-point.true .choise-point {
    background-color: #17B699;
}
.block-viral-answer-point.true {
    border: 1px solid #17B699;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
}
.block-viral-answer-list .block-button-3-0.button-3-0-green {
    height: 62px;
    background-color: #17B699;
    border-radius: 8px;
    box-shadow: 0 8px 19px rgba(47,56,73,0.151664);
}
.block-viral-answer-list .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    font-size: 18px;
    line-height: 62px;
    font-weight: 500;
}
.block-viral-answer-list .block-question-buttons-line .block-button-3-0.button-3-0-green {
    height: 46px;
    float: left;
    width: 48%;
    background-color: #FFFFFF;
}
.block-viral-answer-list .block-question-buttons-line .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    line-height: 46px;
    color: #000000;
}
.block-viral-answer-list .user-question-button-cancel .block-button-3-0.button-3-0-green {
    background-color: #FFFFFF;
    float: right;
    width: 50%;
}
.block-viral-answer-list .choice-list-button-cancel .block-button-3-0.button-3-0-green,
.block-viral-answer-list .choice-list-button-ok .block-button-3-0.button-3-0-green,
.block-viral-answer-list .block-choice-list-buttons .block-button-3-0.button-3-0-green {
    float: left;
    width: auto;
    margin-right: 12px;
    margin-top: 12px;
    /*border: 2px solid #FFFFFF;*/
}


.block-viral-answer-list .user-question-button-cancel .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    color: #000000;
}
.block-viral-answer-list .block-question-buttons-line .active-button .block-button-3-0.button-3-0-green {
    background-color: #17B699;
    /*border: 2px solid #17B699*/
}
.block-viral-answer-list .block-question-buttons-line .checked-button .block-button-3-0.button-3-0-green {
    background-color: #D72020;
    /*border: 2px solid #D72020*/
}
.block-viral-answer-list .block-question-buttons-line .right-button .block-button-3-0.button-3-0-green {
    background: rgba(23, 182, 153, 0.6);
    /*border: 2px solid rgba(23, 182, 153, 0);*/
}
.block-viral-answer-list .block-question-buttons-line .active-button .block-button-3-0.button-3-0-green p.block-button-3-0-title,
.block-viral-answer-list .block-question-buttons-line .checked-button .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    color: #FFFFFF;
}
.block-viral-answer-list .block-question-buttons-line .right-button .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    color: #FFFFFF;
}

.block-viral-answer-list .block-button-descr {
    position: relative;
    margin-top: 12px;
    margin-left: -50px;
    width: 100%;
    max-width: 330px;
    background-color: #C4C4C4;
    border-radius: 8px;
    padding: 22px 18px;
    transition: .4s;
}
.block-viral-answer-list .block-button-descr:before {
    position: absolute;
    top:-13px;
    left: 50%;
    margin-left: -13px;
    content: '';
    width: 26px;
    height: 26px;
    background-color: #c4c4c4;
    transform: rotate(45deg);
}
.block-viral-answer-list .button-descr-text {
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.8);
}

.block-head-standart-message {
    position: relative;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 11px;
    padding: 20px 15px 20px 25px;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.05);
    max-width: 410px;
    /*width: 410px;*/
    background-color: #FFFFFF;
    margin: 8px 150px 0 135px;

    opacity: 0;
}
.block-head-standart-message .little-traingle {
    position: absolute;
    bottom: -2px;
    right: -45px;
    width: 62px;
    height: 43px;
    background-image: url("./components/img/new_ugol.svg");
    background-repeat: no-repeat;
    background-size: 100%;
}
/*.representation-message-wrapper .block-head-standart-message .little-traingle {*/
/*    bottom: -3px;*/
/*}*/


.block-head-standart-message .message-text {
    font-size: 20px;
    line-height: 24px;
    color: rgba(0,0,0,0.8);
    opacity: 0;
    cursor: default;
}
.block-head-transparent-message {
   background: #F3F3F3;

}
.block-head-transparent-message .message-text {
    font-weight: 400;
    color: rgba(0,0,0,0.5);
}
.block-head-transparent-message .little-traingle {
    display: none;
}
.block-viral-answer-list .choice-list-question {
    width: 100%;
    border-left: 5px solid #FFFFFF;
    padding-left: 15px;

}
.block-viral-answer-list .choice-list-question-text {
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: default;
}
.block-viral-test-progress-bar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 8px;
    background-color: #2F3849;
    z-index: 20;
    overflow: hidden;
    /*will-change: z-index, transform;*/
}
.progress-bar-text {
    font-size: 7px;
    line-height: 8px;
    color: #FFFFFF;
}
.block-viral-test-progress-bar .block-viral-test-step {
    background-color:#0ECFAA;
    height: 8px;
    float:left;
    text-align: right;
    padding: 0 6px;
    margin-right: 6px;
    /*max-width: calc(100% - 30px);*/
    color: #2F3849;
}


.viral-block-number-of-answer {
    display: table;
    cursor: default;
}
.viral-block-number-of-answer .text-number-of-answer {
    font-size: 12px;
    line-height: 20px;
    color: #FFFFFF;
    /*padding-left: 5px;*/
}
.viral-block-number-of-answer .show-number-of-answer {
    font-size: 40px;
    line-height: 40px;
    color: #FFFFFF;
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
}
.viral-block-number-of-answer .show-number-of-answer .middle-number-of-answer,
.viral-block-number-of-answer .show-number-of-answer .last-number-of-answer {
    padding: 0 4px;
}
.viral-block-number-of-answer .show-number-of-answer .middle-number-of-answer {
    border-right: 1px solid #FFFFFF;
}



.viral-block-test-result-wrapper {
    position: relative;
    width: 100%;
    background-color:#2f3849;
    min-height: 100vh;
    min-height: 100vh;
    overflow-x: hidden;
    z-index: 9888888888;
    animation:none!important;
}
.viral-test-result-content-wrapper {
    padding: 0 30px 150px 30px;
    min-width: 310px;
}
.footer-viral-test-result {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 0 35px 22px 35px;
    transition: .3s;
    z-index: 23;
}
.footer-viral-test-result.finished {
    padding: 0 35px 22px 50px;
}
.footer-viral-test-result .viral-test-footer-button {
    float: left;
}
.footer-viral-test-result .viral-test-footer-button.first-button {
    margin-right: 27px;
}
.footer-viral-test-result .viral-test-footer-button.third-button {
    float:right;
    margin:0;
}
.footer-viral-test-result .footer-text {
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 0.6;
}
.viral-block-test-result {
    max-width: 825px;
    width: 100%;
    margin: 0 auto;
}
.viral-test-result-header {
    width: 0;
    height: 0;
    border-top: 125px solid #FFFFFF;
    border-right: 99vw solid transparent;
    overflow: hidden;
}
.viral-test-result-header .block-viral-test-logo {
    position: absolute;
    left: 50px;
    top: 30px;
}
.block-viral-test-logo .viral-test-logo {
    background-image: url("./components/img/LogoBlack.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 116px;
    height: 18px;
}
.block-viral-test-logo .viral-test-logo-title {
    font-size: 32px;
    line-height: 41px;
    color: #2f3849;
    margin-left: -2px;
}
.viral-block-test-result .block-test-result-left {
    max-width: 320px;
    width: 100%;
    float: left;
}
.viral-block-test-result .block-test-result-right {
    width: 100%;
    max-width: 400px;
    float: right;
}
.viral-block-test-result .block-test-result-left .test-result-main-title {
    font-size: 33px;
    line-height: 57px;
    color: #35F3C6;

}
.viral-block-test-result .block-test-result-left .test-result-main-title-big {
    font-size: 57px;
    line-height: 57px;
    color: #35F3C6;
}
.block-test-result-left .block-test-result-parameters {
    width: 100%;
    min-width: 300px;
    position: relative;
}
.block-test-result-parameters .test-result-parameter {
    height: 68px;
    width: 100%;
    box-sizing: content-box;
}
.block-test-result-parameters .test-result-parameter strong {
    float: right;
}
.block-test-result-parameters .test-result-parameter.parameter-cost {
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}
.block-test-result-parameters .text-parameters {
    font-size: 24px;
    line-height: 68px;
    color: #FFFFFF;
}
.block-test-result-parameters .test-result-share-block,
.start-test-buttons-top-line .test-result-share-block,
.popup-wrapper .test-result-share-block {
    /*position: absolute;
    background: #c4c4c4;
    bottom: -17px;*/
    position: fixed;
    background-color: #FFFFFF;
    left: calc(50% - 164px);
    box-shadow: 0px 8px 19px rgba(47,56,73,0.151664);
    width: 328px;
    height: 120px;
    border-radius: 8px;
    z-index: 2;
}
.popup-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    background-color: rgba(0,0,0,0.3);
    z-index: 777777;
}
.start-test-buttons-top-line .test-result-share-block,
.popup-wrapper .test-result-share-block {
    /*top: 50px;*/
    top: calc(50% - 60px);
}
.block-test-result-parameters .test-result-share-block:after,
.start-test-buttons-top-line .test-result-share-block:after {
    display: none;
    position: absolute;
    bottom: -13px;
    left: 28px;
    content: '';
    width: 26px;
    height: 26px;
    background-color: #c4c4c4;
    transform: rotate(45deg);
}
.start-test-buttons-top-line .test-result-share-block:after {
    top: -13px;
}
.start-test-buttons-top-line .test-result-share-block:after {

}
.block-test-result-parameters .test-result-share-block .social-button,
.start-test-buttons-top-line .test-result-share-block .social-button,
.popup-wrapper .test-result-share-block .social-button {
    text-align: center;
    line-height: 136px;
    margin: 0 15px 0 45px;
    width: 38px;
}
.block-test-result-parameters .test-result-share-block .social-button a i,
.start-test-buttons-top-line .test-result-share-block .social-button a i,
.popup-wrapper .test-result-share-block .social-button a i {
    font-size: 38px;
    cursor: pointer;
}
.block-test-result-parameters .test-result-share-block .social-button a i:hover,
.start-test-buttons-top-line .test-result-share-block .social-button a i:hover,
.popup-wrapper .test-result-share-block .social-button a i:hover {
    color: rgba(0,0,0,0.6);
}


.block-test-result-left .block-button-3-0.button-3-0-green {
    height: 51px;
    background-color: #17B699;
    box-shadow: 0px 8px 19px rgba(47, 56, 73, 0.151664);
}
.block-test-result-left  .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    line-height: 51px;
    font-weight: 500;
}
.block-test-result-left .test-result-repeat-block {
    height: 20px;
    padding: 0 5px;
}
.test-result-repeat-block .icon-repeat {
    float: left;
    background-image: url("./components/img/ic_repeat_new.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    margin: 2px 7px 0 0;
    cursor: pointer;
}
.test-result-repeat-block .repeat-title {
    font-size: 14px;
    line-height: 20px;
    color: #17B699;
    cursor: pointer;
    font-weight: 500;
}
.block-test-result-right .block-test-result-image {
    background-repeat: no-repeat;
    background-size: contain;
    height: 400px;
    margin-bottom: 45px;
}
.block-test-result-right .mark-growing-image {
    background-image: url("./components/img/testResult/mark_growing.png");
}
.block-test-result-right .mark-wise-image {
    background-image: url("./components/img/testResult/mark_wise.png");
}
.block-test-result-right .mark-winged-image {
    background-image: url("./components/img/testResult/mark_winged.png");
}
.block-test-result-right .mark-resolute-image {
    background-image: url("./components/img/testResult/new_mark_resolute.png");
}
.block-test-result-right .mark-certain-image {
    background-image: url("./components/img/testResult/new_mark_certain.png");
}

.block-test-result-right .ilon-growing-image {
    background-image: url("./components/img/testResult/ilon_growing.png");
}
.block-test-result-right .ilon-wise-image {
    background-image: url("./components/img/testResult/ilon_wise.png");
}
.block-test-result-right .ilon-winged-image {
    background-image: url("./components/img/testResult/ilon_winged.png");
}
.block-test-result-right .ilon-resolute-image {
    background-image: url("./components/img/testResult/new_ilon_resolute.png");
}
.block-test-result-right .ilon-certain-image {
    background-image: url("./components/img/testResult/new_ilon_certain.png");
}

.block-test-result-right .steve-growing-image {
    background-image: url("./components/img/testResult/steve_growing.png");
}
.block-test-result-right .steve-wise-image {
    background-image: url("./components/img/testResult/steve_wise.png");
}
.block-test-result-right .steve-winged-image {
    background-image: url("./components/img/testResult/steve_winged.png");
}
.block-test-result-right .steve-resolute-image {
    background-image: url("./components/img/testResult/new_steve_resolute.png");
}
.block-test-result-right .steve-certain-image {
    background-image: url("./components/img/testResult/new_steve_certain.png");
}

.block-test-result-right .pavel-growing-image {
    background-image: url("./components/img/testResult/pavel_growing.png");
}
.block-test-result-right .pavel-wise-image {
    background-image: url("./components/img/testResult/pavel_wise.png");
}
.block-test-result-right .pavel-winged-image {
    background-image: url("./components/img/testResult/pavel_winged.png");
}
.block-test-result-right .pavel-resolute-image {
    background-image: url("./components/img/testResult/new_pavel_resolute.png");
}
.block-test-result-right .pavel-certain-image {
    background-image: url("./components/img/testResult/new_pavel_certain.png");
}

.block-test-result-right .block-test-result-descr {
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
}

.viral-block-test-result-export {
    background-color:#2f3849;
    width: 1200px;
    height: 630px;
    padding: 60px 60px 0 75px;
}
.viral-block-test-result .block-test-result-left.export {
    max-width: 385px;
    float: none;
    margin-left: 40px;
}
.viral-block-test-result.export {
    margin: 0;
    max-width: none;
}
.viral-test-result-header .viral-test-export-logo {
    position: absolute;
    left: 40px;
    top: 10px;
}
.viral-test-export-logo .logo-export {
    background-image: url("./components/img/Logo.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 180px;
    height: 27px;
}
.viral-test-export-logo .logo-title {
    font-size: 23px;
    line-height: 44px;
}
.block-test-result-left  .test-result-main-title.export {
    font-size: 41px;
    line-height: 70px;
}
.block-test-result-left .test-result-parameter.export {
    font-size: 31px;
}
.block-test-result-right.export {
    max-width: 543px;
}
.block-test-result-right.export .block-test-result-image {
    position: relative;
    top: 25px;
    height: 490px;
    width: 490px;
    margin: 0 auto;
}
.block-test-result-right .image-bg-ellipse {
    width: 543px;
    height: 543px;
    background-color: #17B699;
    box-shadow: 0px 4px 40px rgba(0,0,0,0.25);
    border-radius: 50%;
}

        /*TEST*/
.block-viral-test-main-wrapper .block-viral-test-top {
    position: relative;
    height: 650px;
    background-image: url("./components/img/bg/viral_bg_rocketDAO.png");
    background-repeat: no-repeat;
    background-size: 1250px 720px;
    background-position: 50% 1%;
}

.block-viral-test-content .bg-top-traingle .triangle-first {
    position: absolute;
    width: 100%;
    height: 85px;
    background-image: url("./components/img/bg/bg_traingle.png");
    background-repeat: no-repeat;
    background-size: 102% 85px;
    background-position: 50% 50%;
    top: -82px;
    left: 0;
}
.block-viral-test-main-wrapper .bg-top-traingle .triangle-second {
    background-image: url("./components/img/bg/bg_traingle_new.png");
    position: fixed;
    width: 100%;
    height: 85px;
    background-repeat: no-repeat;
    background-size: 102% 85px;
    background-position: 50% 50%;
    top: 161px;
    left: 0;
    z-index: 11;
    display: none;
}
@media all and (min-width: 600px) {
    /*.block-viral-test-content .bg-top-traingle.new-traingle {*/
    /*    background-image: url("./components/img/bg/bg_traingle_new.png");*/
    /*}*/
}

.block-viral-test-main-wrapper .block-viral-test-content {
    position: relative;
    background-color: #2f3849;
    padding: 40px 25px 200px 25px;
}
.block-viral-test-content.content-in-progress {
    margin-top: 350px;
    z-index: 3;
}
.block-viral-test-top .head-message-wrapper {
    position: absolute;
    bottom: 230px;
    /*right: 120px;*/
    right: 280px;
    opacity: 1;
}
.viral-test-main-logo {
    padding: 20px 0 0 35px;
    display: inline-block;
    cursor: pointer;
    position: relative;
    z-index: 1;
}
.viral-test-main-logo .main-logo {
    background-image: url("./components/img/LogoBlack.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 100px;
    height: 16px;
}
.viral-test-main-logo .main-logo-title {
    font-size: 15px;
    line-height: 25px;
    color: rgba(0, 0, 0, 0.8);
}
.viral-test-loader-logo {
    padding: 40px 0 0 30px;
}
.viral-test-loader-logo .main-logo.loader-logo {
    background-image: url("./components/img/LogoWhite.svg");
    background-repeat: no-repeat;
    background-size: auto;
    width: 100px;
    height: 16px;
}
.viral-test-loader-logo .main-logo-title.loader-logo-title {
    color: rgba(255, 255, 255, 0.8);
}

.block-viral-test-top .vital-test-block-start-title {
    margin: 100px 0 0 16%;
    position: inherit;
    z-index: 2;
}
.viral-test-top-bg .bg-top-head {
    position: absolute;
    width: 986px;
    height: 600px;
    background-image: url("./components/img/bg/viral_head_screen_-min_new.png");
    background-repeat: no-repeat;
    background-size: 100%;
    bottom: 30px;
    right: -525px;
}
.block-viral-test-main-wrapper .block-viral-test-top.bg-in-progress {
    height: 400px;
    background-position: 50% 79%;
    position: fixed;
    top: 0;
    width: 100%;
    /*will-change: z-index, height, transform;*/
}

.block-viral-test-top.bg-in-progress .bg-top-head {
    background-position: 50% 100%;
    bottom: 228px;
    /*right: -150px;*/
    right: -325px;
    transform: scale(2.65);
    height: 289px;
    width: 520px;
}
.block-viral-test-top .start-test-buttons-top-line {
    position: absolute;
    top: 20px;
    left: 180px;
}
.block-viral-test-top .start-test-buttons-top-line .block-button-3-0.button-3-0-green {
    background: #FFFFFF;
    box-shadow: 0px 8px 19px rgba(47,56,73,0.151664);
    border-radius: 8px;
    height: 38px;
    float: right;
}
.block-viral-test-top .start-test-buttons-top-line  .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    line-height: 38px;
    color: #2f3849;
    font-weight: 500;
}




    /*LOADER*/
.viral-test-loader-block .viral-test-loader,
.viral-test-loader-block .viral-loader-main-title,
.viral-test-loader-block .viral-loader-register-buttons-block {
    margin-left: auto;
    margin-right: auto;
}

.viral-test-loader-block .viral-loader-main-title {
    max-width: 608px;
    width: 100%;
}
.viral-test-loader-block .loader-title {
    font-size: 36px;
    line-height: 40px;
    color: #FFFFFF;
    text-align: center;
}
.viral-test-loader-block .viral-test-loader {
    position: relative;
    background-image: url("./components/img/loader/loader.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    max-width: 314px;
    height: 106px;
    width: 100%;
}
.viral-test-loader-block .text-viral-loader {
    font-size: 12px;
    line-height: 12px;
    color: #000;
}
.viral-test-loader-block .loader-menu-title {
    position: absolute;
    left: 9px;
    top: 5px;
    color: #FFFFFF;
}
.viral-test-loader-block .loader-left-title {
    position: absolute;
    left: 62px;
    top: 50px;
}
.viral-test-loader-block .loader-right-title {
    position: absolute;
    right: 23px;
    top: 50px;
}
.viral-test-loader-line{
    position: absolute;
    width: 8px;
    height: 9px;
    bottom: 17px;
    left: 20px;
    margin-right: 5px;
    background: url("./components/img/loader/piece_of_loader.svg");

}

        /*StartTitle*/
.viral-block-start-test-title .big-start-title,
.viral-block-start-test-title .medium-start-title,
.viral-block-start-test-title .little-start-title {
    color: #000;
    opacity: 0.8;
}
.viral-block-start-test-title .big-start-title {
    font-size: 69px;
    /*line-height: 25px;*/

    line-height: 48px;
}
.viral-block-start-test-title .medium-start-title {
    font-size: 46px;
    line-height: 48px;
    margin: 0 0 10px 0;
}
.viral-block-start-test-title .little-start-title {
    font-size: 33px;
    line-height: 36px;
}
.viral-block-start-test-title .little-start-block {
    border-left: 6px solid #FFC700;
    padding-left: 13px;
}
.viral-block-start-test-title .block-button-3-0.button-3-0-green {
    background-color: #FFC700;
    box-shadow: 0px 8px 19px rgba(47, 56, 73, 0.151664);
    height: 60px;
}
.viral-block-start-test-title.representation-button .block-button-3-0.button-3-0-green {
    background-color: #17B699;
}
.viral-block-start-test-title .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    font-size: 20px;
    line-height: 60px;
    color: #000;
    font-weight: 500;
}.viral-block-start-test-title.representation-button .block-button-3-0.button-3-0-green p.block-button-3-0-title {
     color: #fff;
 }

        /*ChooseCharacter*/
.block-viral-test-main-wrapper .block-viral-test-top.full-screen {
    min-height: 100vh;
    background-size: cover;
    background-position: 35% 50%;
}
.block-viral-test-top .choose-character-block {
    display: none;
}
.viral-test-choose-character-block {
    max-width: 630px;
    width: 100%;
    padding: 0 30px;
    opacity:0;
    /*display: none;*/
    transition: .6s linear;
}
.viral-test-choose-character-block .character {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 118px;
    height: 180px;
    cursor: pointer;
    margin-top: -44px;
    margin-left: -2px;
    float: left;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}
.viral-test-choose-character-block .character-circle {
    background-image: url("./components/img/characters/Circle.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 0;
    height: 180px;
    float: left;
}
.viral-test-choose-character-block .characters-container {
    justify-content: space-between;
    margin-left: 3px;
}
.viral-test-choose-character-block .characters-container .character-wrapper {
    max-width:118px;
    min-width: 118px;
    height: 180px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}
.last-wrapper {
    margin-right: 0;
}
.viral-test-choose-character-block .character-pavel {
    background-image: url("./components/img/characters/Pavel.svg");
}
.viral-test-choose-character-block .character-steve {
    background-image: url("./components/img/characters/Steve.svg");
}
.viral-test-choose-character-block .character-mark {
    background-image: url("./components/img/characters/Mark.svg");
}
.viral-test-choose-character-block .character-ilon {
    background-image: url("./components/img/characters/Ilon.svg");
    margin-right: 0px;
}

.viral-test-choose-character-ru-block  .character-pavel {
    background-image: url("./components/img/characters/PavelRU.svg");
}
.viral-test-choose-character-ru-block  .character-steve {
    background-image: url("./components/img/characters/SteveRU.svg");
}
.viral-test-choose-character-ru-block  .character-mark {
    background-image: url("./components/img/characters/MarkRU.svg");
}
.viral-test-choose-character-ru-block  .character-ilon {
    background-image: url("./components/img/characters/IlonRU.svg");
    margin-right: 0px;
}
.viral-test-choose-character-block .choose-character-title {
    font-size: 32px;
    line-height: 32px;
    color: rgba(0,0,0,0.8);
}
.viral-test-choose-character-block .block-button-3-0.button-3-0-green {
    height: 60px;
    box-shadow: 0px 8px 19px rgba(47,56,73,0.151664);
    background-color: #17B699;
}
.viral-test-choose-character-block .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    line-height: 60px;
    font-size: 18px;
    font-weight: 500;
}
.block-viral-test-top.viral-test-top-bg.full-screen {
    height: 700px;
    min-height: 100vh;
    margin-top: -1px;
}

        /*REPRESENTATION*/
.viral-block-start-test-title.representation-button .block-button-3-0.button-3-0-green {
    height: 61px;
    opacity: 0;
    z-index: -1;
}
.viral-block-start-test-title.representation-button .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    line-height: 61px;
    font-size: 18px;
    font-weight: 500;
}
.viral-block-start-test-title.representation-button .block-button-3-0.button-3-0-green:hover {
    opacity: .8!important;
}
.block-viral-test-top .head-message-wrapper.representation-message-wrapper  {
    bottom: 172px;
    right: 185px;
    white-space: pre-wrap;
    font-weight: 300;
}

    /*LITTLE CHARACTER*/
.viral-test-top-bg .little-character-block {
    position: absolute;
    width: 85px;
    height: 80px;
    bottom: 52px;
    right: 1000px;
    background-size: 100% 100%!important;
    display: none;
}
.viral-test-top-bg .steve-character {
    background: url("./components/img/characters/Jobs-1.gif");
}
.viral-test-top-bg .ilon-character {
    background: url("./components/img/characters/Mask-1.gif");
}
.viral-test-top-bg .mark-character {
    background: url("./components/img/characters/Face-1.gif");
}
.viral-test-top-bg .pavel-character {
    background: url("./components/img/characters/Durov-1.gif");
}

.viral-test-top-bg .little-character-finished-block {
    position: absolute;
    width: 85px;
    height: 80px;
    bottom: 80px;
    /*right: 310px;*/
    right: 460px;
    background-size: contain!important;
    background-repeat: no-repeat!important;
}
.viral-test-top-bg .steve-character-finished {
    background-image: url("./components/img/characters/SteveFull.png");
}
.viral-test-top-bg .ilon-character-finished {
    background-image: url("./components/img/characters/IlonFull.png");
}
.viral-test-top-bg .mark-character-finished {
    background-image: url("./components/img/characters/MarkFull.png");
}
.viral-test-top-bg .pavel-character-finished {
    background-image: url("./components/img/characters/PavelFull.png");
}

    /*ON SCROLL*/
@media all and (min-width: 600px) {
    .block-viral-test-main-wrapper.on-scroll .block-viral-test-top.bg-in-progress {
        clip-path: polygon(0% 0%, 100% 0%, 100% 181px, 0% 243px);
        z-index: 10;
    }
    .block-viral-test-main-wrapper.on-scroll .bg-top-traingle.in-progress .triangle-first {
        display: none;
    }
    .block-viral-test-main-wrapper.on-scroll .bg-top-traingle.in-progress .triangle-second  {
        display: block;
    }
}

.test-result-share-block .close,
.get-bucks-popup-wrapper .close {
    left: unset;
    right: 6px;
    top: 8px;
    opacity: 0.8;
}
.get-bucks-popup-wrapper .close {
    right: 45px;
    top: 28px;
}

    /*HEAD TRASH ANIMATION*/
.viral-test-top-bg .head-trash {
    position: absolute;
    width: 70px;
    height: 70px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    transition: .5s;
}
.viral-test-top-bg .head-trash-1 {
    right: 105px;
    top: 0px;
    width: 90px;
    height: 150px;
    background-image: url('./components/img/bg/head-trash-1-min.png');
    -webkit-animation: myBounce 4.5s linear infinite;
    -moz-animation: myBounce 4.5s linear infinite;
    -o-animation: myBounce 4.5s linear infinite;
    animation: myBounce 4.5s linear infinite;
}
.viral-test-top-bg.represent-screen .head-trash-1 {
    top: -130px;
}
.viral-test-top-bg .head-trash-2 {
    right: 225px;
    top: 145px;
    background-image: url('./components/img/bg/head-trash-2-min.png');
    -webkit-animation: myOrbit 7s linear infinite;
    -moz-animation: myOrbit 7s linear infinite;
    -o-animation: myOrbit 7s linear infinite;
    animation: myOrbit 7s linear infinite;
}
.viral-test-top-bg.represent-screen .head-trash-2 {
    top: 15px;
}
.viral-test-top-bg .head-trash-3 {
    right: 45px;
    top: 60px;
    width: 55px;
    height: 55px;
    background-image: url('./components/img/bg/head-trash-3-min.png');
    -webkit-animation: myRotate 6s linear infinite;
    -moz-animation: myRotate 6s linear infinite;
    -o-animation: myRotate 6s linear infinite;
    animation: myRotate 6s linear infinite;
}
.viral-test-top-bg.represent-screen .head-trash-3 {
    top: -70px;
}
.viral-test-top-bg .head-trash-4 {
    right: 95px;
    top: 185px;
    background-image: url('./components/img/bg/head-trash-4-min.png');
    -webkit-animation: orbit-85 7s linear infinite;
    -moz-animation: orbit-85 7s linear infinite;
    -o-animation: orbit-85 7s linear infinite;
    animation: orbit-85 7s linear infinite;
}
.viral-test-top-bg.represent-screen .head-trash-4 {
    top: 55px;
}
.viral-test-top-bg .head-trash-5 {
    right: 5px;
    top: 90px;
    width: 90px;
    height: 70px;
    background-image: url('./components/img/bg/head-trash-5-min.png');
    -webkit-animation: myOrbit 6s linear infinite;
    -moz-animation: myOrbit 6s linear infinite;
    -o-animation: myOrbit 6s linear infinite;
    animation: myOrbit 6s linear infinite;
}
.viral-test-top-bg.represent-screen .head-trash-5 {
    top: -40px;
}
.viral-test-top-bg .head-trash-6 {
    right: 75px;
    top: 190px;
    background-image: url('./components/img/bg/head-trash-6-min.png');
    -webkit-animation: myOrbit 4s linear infinite;
    -moz-animation: myOrbit 4s linear infinite;
    -o-animation: myOrbit 4s linear infinite;
    animation: myOrbit 4s linear infinite;
}
.viral-test-top-bg.represent-screen .head-trash-6 {
    top: 60px;
}
.viral-test-top-bg .head-trash-7 {
    right: 240px;
    top: 180px;
    width: 100px;
    height: 100px;
    background-image: url('./components/img/bg/head-trash-7-min.png');
    -webkit-animation: orbit-60 10s linear infinite;
    -moz-animation: orbit-60 10s linear infinite;
    -o-animation: orbit-60 10s linear infinite;
    animation: orbit-60 10s linear infinite;
}
.viral-test-top-bg.represent-screen .head-trash-7 {
    top: 50px;
}
.viral-test-top-bg .head-trash-8 {
    right: 160px;
    top: 130px;
    background-image: url('./components/img/bg/head-trash-8-min.png');
    -webkit-animation: orbit-125 8s linear infinite;
    -moz-animation: orbit-125 8s linear infinite;
    -o-animation: orbit-125 8s linear infinite;
    animation: orbit-125 8s linear infinite;
}
.viral-test-top-bg.represent-screen .head-trash-8 {
    top: 0;
}
.viral-test-top-bg .head-trash-9 {
    right: 285px;
    top: 160px;
    background-image: url('./components/img/bg/head-trash-9-min.png');
    -webkit-animation: myBounce 8s linear infinite;
    -moz-animation: myBounce 8s linear infinite;
    -o-animation: myBounce 8s linear infinite;
    animation: myBounce 8s linear infinite;
}
.viral-test-top-bg.represent-screen .head-trash-9 {
    top: 30px;
}
.viral-test-top-bg .head-trash-12 {
    right: 240px;
    top: 110px;
    width: 77px;
    height: 79px;
    background-image: url('./components/img/bg/head-trash-12-min.png');
    -webkit-animation: myRotate 4.2s linear infinite;
    -moz-animation: myRotate 4.2s linear infinite;
    -o-animation: myRotate 4.2s linear infinite;
    animation: myRotate 4.2s linear infinite;
}
.viral-test-top-bg.represent-screen .head-trash-12 {
    top: -20px;
}

@-webkit-keyframes myBounce {
    0% { -webkit-transform: translateY(0px); }
    50% { -webkit-transform: translateY(30px); }
    100%   { -webkit-transform: translateY(0px); }
}
@-moz-keyframes myBounce {
    0% { -moz-transform: translateY(0px); }
    50% { -moz-transform: translateY(30px); }
    100%   { -moz-transform: translateY(0px); }
}
@-o-keyframes myBounce {
    0% { -o-transform: translateY(0px); }
    50% { -o-transform: translateY(30px); }
    100%   { -o-transform: translateY(0px); }
}
@keyframes myBounce {
    0% { transform: translateY(0px); }
    50% { transform: translateY(30px); }
    100%   { transform: translateY(0px); }
}

@-webkit-keyframes myRotate {
    0% { -webkit-transform: rotate(0deg); }
    50% { -webkit-transform: rotate(180deg); }
    100%   { -webkit-transform: rotate(360deg); }
}
@-moz-keyframes myRotate {
    0% { -moz-transform: rotate(0deg); }
    50% { -moz-transform: rotate(180deg); }
    100%   { -moz-transform: rotate(360deg); }
}
@-o-keyframes myRotate {
    0% { -o-transform: rotate(0deg); }
    50% { -o-transform: rotate(180deg); }
    100%   { -o-transform: rotate(360deg); }
}
@keyframes myRotate {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(180deg); }
    100%   { transform: rotate(360deg); }
}

@-webkit-keyframes myOrbit {
    0% { -webkit-transform: rotate(0deg) translateX(60px) rotate(0deg); }
    25% { -webkit-transform: rotate(90deg) translateX(40px) rotate(-90deg); }
    50% { -webkit-transform: rotate(180deg) translateX(20px) rotate(-180deg); }
    75% { -webkit-transform: rotate(270deg) translateX(40px) rotate(-270deg); }
    100%   { -webkit-transform: rotate(360deg) translateX(60px) rotate(-360deg); }
}
@-moz-keyframes myOrbit {
    0% { -moz-transform: rotate(0deg) translateX(60px) rotate(0deg); }
    25% { -moz-transform: rotate(90deg) translateX(40px) rotate(-90deg); }
    50% { -moz-transform: rotate(180deg) translateX(20px) rotate(-180deg); }
    75% { -moz-transform: rotate(270deg) translateX(40px) rotate(-270deg); }
    100%   { -moz-transform: rotate(360deg) translateX(60px) rotate(-360deg); }
}
@-o-keyframes myOrbit {
    0% { -o-transform: rotate(0deg) translateX(60px) rotate(0deg); }
    25% { -o-transform: rotate(90deg) translateX(40px) rotate(-90deg); }
    50% { -o-transform: rotate(180deg) translateX(20px) rotate(-180deg); }
    75% { -o-transform: rotate(270deg) translateX(40px) rotate(-270deg); }
    100%   { -o-transform: rotate(360deg) translateX(60px) rotate(-360deg); }
}
@keyframes myOrbit {
    0% { transform: rotate(0deg) translateX(60px) rotate(0deg); }
    25% { transform: rotate(90deg) translateX(40px) rotate(-90deg); }
    50% { transform: rotate(180deg) translateX(20px) rotate(-180deg); }
    75% { transform: rotate(270deg) translateX(40px) rotate(-270deg); }
    100%   { transform: rotate(360deg) translateX(60px) rotate(-360deg); }
}

@-webkit-keyframes orbit {
    0% { -webkit-transform: rotate(0deg) translateX(5px) rotate(0deg); }
    100%   { -webkit-transform: rotate(360deg) translateX(5px) rotate(-360deg); }
}
@-moz-keyframes orbit {
    0% { -moz-transform: rotate(0deg) translateX(5px) rotate(0deg); }
    100%   { -moz-transform: rotate(360deg) translateX(5px) rotate(-360deg); }
}
@-o-keyframes orbit {
    0% { -o-transform: rotate(0deg) translateX(5px) rotate(0deg); }
    100%   { -o-transform: rotate(360deg) translateX(5px) rotate(-360deg); }
}
@keyframes orbit {
    0% { transform: rotate(0deg) translateX(5px) rotate(0deg); }
    100%   { transform: rotate(360deg) translateX(5px) rotate(-360deg); }
}

@-webkit-keyframes orbit-60 {
    0% { -webkit-transform: rotate(0deg) translateX(60px) rotate(0deg); }
    100%   { -webkit-transform: rotate(360deg) translateX(60px) rotate(-360deg); }
}
@-moz-keyframes orbit-60 {
    0% { -moz-transform: rotate(0deg) translateX(60px) rotate(0deg); }
    100%   { -moz-transform: rotate(360deg) translateX(60px) rotate(-360deg); }
}
@-o-keyframes orbit-60 {
    0% { -o-transform: rotate(0deg) translateX(60px) rotate(0deg); }
    100%   { -o-transform: rotate(360deg) translateX(60px) rotate(-360deg); }
}
@keyframes orbit-60 {
    0% { transform: rotate(0deg) translateX(60px) rotate(0deg); }
    100%   { transform: rotate(360deg) translateX(60px) rotate(-360deg); }
}

@-webkit-keyframes orbit-85 {
    0% { -webkit-transform: rotate(0deg) translateX(85px) rotate(0deg); }
    100%   { -webkit-transform: rotate(360deg) translateX(85px) rotate(-360deg); }
}
@-moz-keyframes orbit-85 {
    0% { -moz-transform: rotate(0deg) translateX(85px) rotate(0deg); }
    100%   { -moz-transform: rotate(360deg) translateX(85px) rotate(-360deg); }
}
@-o-keyframes orbit-85 {
    0% { -o-transform: rotate(0deg) translateX(85px) rotate(0deg); }
    100%   { -o-transform: rotate(360deg) translateX(85px) rotate(-360deg); }
}
@keyframes orbit-85 {
    0% { transform: rotate(0deg) translateX(85px) rotate(0deg); }
    100%   { transform: rotate(360deg) translateX(85px) rotate(-360deg); }
}

@-webkit-keyframes orbit-125 {
    0% { -webkit-transform: rotate(0deg) translateX(125px) rotate(0deg); }
    100%   { -webkit-transform: rotate(360deg) translateX(125px) rotate(-360deg); }
}
@-moz-keyframes orbit-125 {
    0% { -moz-transform: rotate(0deg) translateX(125px) rotate(0deg); }
    100%   { -moz-transform: rotate(360deg) translateX(125px) rotate(-360deg); }
}
@-o-keyframes orbit-125 {
    0% { -o-transform: rotate(0deg) translateX(125px) rotate(0deg); }
    100%   { -o-transform: rotate(360deg) translateX(125px) rotate(-360deg); }
}
@keyframes orbit-125 {
    0% { transform: rotate(0deg) translateX(125px) rotate(0deg); }
    100%   { transform: rotate(360deg) translateX(125px) rotate(-360deg); }
}


.block-viral-answer-list .block-box-check-box-blue {
    background: #FFFFFF;
}
.block-viral-answer-list .block-box-check-box-blue:after, .block-viral-answer-list .block-box-check-box-blue:before {
    background: #404040!important;
    display: none;
}
.block-viral-answer-list .block-box-check-box-blue.active:after, .block-box-check-box-blue.active:before {
    display: block;
}
.block-viral-answer-list .block-item-check-box-reg-startup .search-popup-right {
    color: #FFFFFF;
    line-height: 20px;
    letter-spacing: -0.505952px;
    margin-bottom: 6px;
}
.block-viral-answer-list .block-check-button-wrapper .block-button-3-0.button-3-0-green {
    margin-top: 40px;
}
.block-viral-answer-list .break-time-text {
    font-size: 36px;
    line-height: 50px;
    color: #FFFFFF;
    font-weight: 500;
}
.block-viral-answer-list.break-time-block .block-button-3-0.button-3-0-green {
    height: 48px;
}
.block-viral-answer-list.break-time-block .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    line-height: 48px;
}
.answer-list-wrapper {
    transition: .5s;
    opacity: 0;
}
.selected-answer-point .block-viral-answer-point {
    background: transparent;
    border: 1px solid #DFDFDF;
    box-shadow: 0px 1px 6px rgba(0,0,0,0.05);
}
.selected-answer-point .block-viral-answer-point .choise-point {
    background-color: #17B699;
}
.selected-answer-point .block-viral-answer-point .answer-text {
    color: #FFFFFF;
    font-weight: 500;
}
.block-viral-answer-list .user-question-button-ok.margin-top-50 .block-button-3-0.button-3-0-green {
    width: unset;
}
.viral-block-test-result-wrapper .viral-loader-block {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: #FFFFFF;
    z-index: 9888888888;
}
.viral-block-test-result-wrapper .viral-loader-block .viral-loader-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    letter-spacing: 4px;
}
.block-viral-answer-list .block-button-descr-en {
    margin-left: -85px;
}
.block-test-result-left .rocket-link-descr {
    font-size: 12px;
    line-height: 15px;
}
.block-test-result-left .rocket-button-wrapper .block-button-3-0.button-3-0-green {
    height: 32px;
    background: transparent;
    border: 1px solid #FFFFFF;
}
.block-test-result-left .rocket-button-wrapper .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    font-size: 14px;
    line-height: 30px;
}
.viral-loader-register-buttons-block .btn-google {
    height: 48px;
}
.viral-loader-register-buttons-block .btn-google > button {
    height: 48px;
}
.viral-loader-register-buttons-block .viral-reg-button {
    margin-bottom: 24px;
}
.viral-loader-register-buttons-block .block-button-reg-social {
    margin-bottom: 0!important;
}
.block-viral-answer-list textarea::placeholder {
    color: rgba(0,0,0,0.8)!important;
    font-size: 16px!important;
    line-height: 20px!important;
}
.block-viral-answer-list textarea {
    background: #FFFFFF;
}
/*.block-viral-answer-list .wrong-button .block-button-3-0 {*/
/*    background: transparent;*/
/*    border: 2px solid #DC4E41;*/
/*    cursor: default;*/
/*}*/
/*.block-viral-answer-list .wrong-button .block-button-3-0.button-3-0-green p.block-button-3-0-title {*/
/*    color: #DC4E41;*/
/*}*/
/*.block-viral-answer-list .wrong-button .block-button-3-0:hover {*/
/*    opacity: 1;*/
/*}*/

.delay-time-wrapper .delay-time-title {
    font-size: 29px;
    line-height: 24px;
}
.delay-time-wrapper .delay-time-title.wrong-title {
    color: #DC4E41;
}
.delay-time-wrapper .delay-time-title.right-title {
    color: #17B699;
}
.delay-time-wrapper .delay-time-descr {
    font-size: 18px;
    line-height: 29px;
    color: #FFFFFF;
}
.choice-list-question-wrapper {
    position: relative;
    min-height: 50px;
}
.choice-list-question-wrapper .delay-time-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    margin-top: 10px;
}
.block-item-check-box-reg-startup.viral-check-box {
    width: 100%!important;
    float: unset!important;
}
.mobile-answer-list-scroll-wrapper {
    display: none;
}
.block-viral-test-main-wrapper .block-viral-test-top.represent-screen {
    height: 500px;
    background-position: 50% 60%;
}
.viral-test-top-bg.represent-screen .bg-top-head {
    bottom: 10px;
}

/*HEAD JOKE*/
.head-joke-wrapper {
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    box-shadow: 0 1px 16px rgba(0, 0, 0, 0.05);
    border-radius: 11px;
    padding: 32px 27px 40px 27px;
    width: 465px;
    top: 145px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 124;
}
.head-joke-wrapper .head-joke-title {
    font-size: 20px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.8);
}
.head-joke-button {
    margin-top: 35px;
}
.head-joke-wrapper .head-joke-button .block-button-3-0.button-3-0-green {
    height: 46px;
    border-radius: 8px;
    background: #17B699;
    box-shadow: 0px 8px 19px rgba(47, 56, 73, 0.151664);
}
.head-joke-wrapper .head-joke-button .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    font-size: 18px;
    line-height: 46px;
    font-weight: 500;
}
.head-joke-icon {
    width: 310px;
    height: 145px;
    /*background-image: url("./components/img/headJoke.gif");*/
    background-repeat: no-repeat;
    background-size: contain;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.block-viral-test-main-wrapper .block-viral-test-top.bg-in-progress.in-joke {
    height: 300px;
    background-position: 50% 70%!important;
}
.block-viral-test-main-wrapper .block-viral-test-content.in-joke {
    margin-top: 300px;
}

/*Last screen upd*/
.block-test-result-left .button-share .block-button-3-0.button-3-0-green {
    width: 100%;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    transition: all 0.2s;
}
@keyframes color {
    0% {
        background-color: #17B699;
    }
    50% {
        background-color: #35d4b7;
    }
    100% {
        background-color: #17B699;
    }
}
.block-test-result-left .button-get-bucks {
    height: 51px;
    background: #FFC700;
    box-shadow: 0px 8px 19px rgba(47, 56, 73, 0.151664);
    border-radius: 6px;
    padding-top: 9px;
    transition: .3s;
}
.block-test-result-left .button-get-bucks:hover {
    opacity: 0.8;
}
.block-test-result-left .button-get-bucks .title-get-bucks {
    font-size: 13px;
    line-height: 16px;
    color: #2F3849;
}
.block-test-result-left .button-get-bucks .title-get-bucks .title-get-bucks-500 {
    font-size: 16px;
    line-height: 17px;
}

/*checkList upd*/
.block-check-list .check-button-wrapper {
    height: 38px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    background: rgba(255,255,255,0.15);
    margin: 0 12px 12px 0;
    max-width: min-content;
}
.block-check-list .check-button-wrapper.active {
    border: 1px solid #17B699;
    background: #17B699;
}
.block-check-list .check-button-wrapper .check-button-title {
    font-size: 14px;
    line-height: 38px;
    color: #FFFFFF;
    white-space: nowrap;
    padding: 0 16px;
}

/*getBucks popup*/
.get-bucks-popup-wrapper {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    border: 1px solid #D0D0D0;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    max-width: 540px;
    width: calc(100% - 30px);
    min-height: 100px;
    /*padding: 28px 29px 37px 29px;*/
    padding: 28px 0 37px 0;
}
.get-bucks-popup-wrapper .scroll-inner-container {
    max-height: 65vh;
    padding: 0 29px;
}
.get-bucks-popup-wrapper .get-bucks-title {
    font-size: 28px;
    line-height: 22px;
    color: #2F3849;
}
.get-bucks-popup-wrapper .get-bucks-descr {
    font-size: 16px;
    line-height: 20px;
    color: rgba(8, 8, 8, 0.8);
}
.get-bucks-popup-wrapper .block-advantage-point {
    max-width: unset;
    min-width: unset;
    margin: 10px 0!important;
    /*margin: 25px 10px!important;*/
}
.get-bucks-popup-wrapper .block-algorithm-table {
    width: 100%;
}
.get-bucks-popup-wrapper .block-algorithm-table:before,
.get-bucks-popup-wrapper .block-algorithm-table:after {
    width: 80px;
    border-top: 3px dotted #0ECFAA;
    top: 40px;
    content: '';
    position: absolute;
}
.get-bucks-popup-wrapper .block-algorithm-table:before {
    left: 280px;
}
.get-bucks-popup-wrapper .block-algorithm-table:after {
    right: 280px;
}
.get-bucks-popup-wrapper .block-advantage-point .text-algorithm-name {
    font-size: 15px;
    line-height: 19px;
    white-space: pre-wrap;
}
.get-bucks-popup-wrapper .block-advantage-point .text-algorithm-descr {
    font-size: 12px;
    line-height: 15px;
    white-space: pre-wrap;
    display: block;
}
.get-bucks-popup-wrapper .block-algorithm-table.final-step:after {
    content: none;
}
.get-bucks-popup-wrapper .block-algorithm-table.final-step:before {
    background: linear-gradient(180deg, #F5F5F5 0.24%, rgba(255, 255, 255, 0.0001) 55.46%);
    height: 100%;
    width: calc(100% + 58px);
    left: -29px;
    top: 0;
    border: none;
}
.get-bucks-popup-wrapper .final-step .block-advantage-point {
    margin: 25px 10px 15px 10px;
}
.get-bucks-popup-wrapper .get-bucks-button-wrapper .block-button-3-0.button-3-0-green {
    display: block!important;
    margin: 0 auto;
    width: 154px!important;
    background: #17B699;
    box-shadow: 0 8px 19px rgba(47, 56, 73, 0.151664);
}
.get-bucks-popup-wrapper .get-bucks-button-wrapper .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    font-weight: 500;
}
.get-bucks-popup-wrapper .final-step .number-circle {
    background: #0ECFAA;
}
.get-bucks-popup-wrapper .final-step .number-circle .text-number-circle {
    color: #2F3849;
}
.get-bucks-popup-wrapper .get-bucks-button-wrapper {
    background: #FFFFFF;
}

/*SAFARI FIXES*/
/*html, body{*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

/*html {*/
/*    height  : 100%;*/
/*    overflow: hidden;*/
/*}*/
/*body {*/
/*    height  : 100%;*/
/*    overflow: auto;*/
/*}*/


.text-align-center {
    text-align: center;
}
.block-align-center {
    margin-left: auto;
    margin-right: auto;
}
.block-align-center .viral-block-number-of-answer {
    margin-left: auto;
    margin-right: auto;
}
.padding-top-15 {
    padding-top: 15px;
}
.padding-top-40 {
    padding-top: 40px;
}
.padding-top-30 {
    padding-top: 30px;
}
.padding-bottom-20 {
    padding-bottom: 20px;
}
.margin-top-40 {
    margin-top: 40px;
}
.margin-top-45 {
    margin-top: 45px;
}
.margin-top-80 {
    margin-top: 80px;
}
.margin-bottom-80 {
    margin-bottom:80px!important;
}
.color-white {
    color: #FFFFFF;
}


        /*BG START*/
@media all and (min-width: 462px) {
    .get-bucks-popup-wrapper .mobile-content {
        display: none;
    }
    .get-bucks-popup-wrapper .desktop-content {
        display: block;
    }
}
@media all and (min-width: 600px) {
    .block-viral-test-main-wrapper .block-viral-test-top.hide {
        display: none;
    }
}

@media all and (min-width: 780px) {
    .block-viral-test-top.represent-screen .block-head-standart-message {
        max-width: 590px;
        margin: 8px 150px 0 14px;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        background-image: url("./components/img/bg/viral_head_screen_-min_new.png");
    }
    .representation-message-wrapper .block-head-standart-message .little-traingle {
        background-size: 98%;
    }
}
@media all and (min-width: 1250px) {
    .block-viral-test-main-wrapper .block-viral-test-top {
        background-size: 1550px 890px;
        background-position: 35% 55%;
    }
    .block-viral-test-main-wrapper .block-viral-test-top.bg-in-progress {
        background-position: 50% 78%;
    }
    .block-viral-test-main-wrapper .block-viral-test-top.represent-screen {
        background-position: 50% 67%;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: -275px;
    }
    .block-viral-test-top .bg-top-head {
        right: -450px;
    }
    .viral-test-top-bg .head-trash-1 {
        right: 180px;
    }
    .viral-test-top-bg .head-trash-2 {
        right: 300px;
    }
    .viral-test-top-bg .head-trash-3 {
        right: 150px;
    }
    .viral-test-top-bg .head-trash-4 {
        right: 170px;
    }
    .viral-test-top-bg .head-trash-5 {
        right: 80px;
    }
    .viral-test-top-bg .head-trash-6 {
        right: 150px;
    }
    .viral-test-top-bg .head-trash-7 {
        right: 315px;
    }
    .viral-test-top-bg .head-trash-8 {
        right: 235px;
    }
    .viral-test-top-bg .head-trash-9 {
        right: 360px;
    }
    .viral-test-top-bg .head-trash-12 {
        right: 315px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 330px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 510px;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 260px;
    }
    /*.block-head-standart-message .little-traingle {*/
    /*    bottom: -3px;*/
    /*    right: -44.5px;*/
    /*}*/
    /*.representation-message-wrapper .block-head-standart-message .little-traingle {*/
    /*    bottom: -2px;*/
    /*    right: -45px;*/
    /*}*/
}
@media all and (min-width: 1400px) {
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: -225px;
    }
    .block-viral-test-top .bg-top-head {
        right: -375px;
    }
    .viral-test-top-bg .head-trash-1 {
        right: 255px;
    }
    .viral-test-top-bg .head-trash-2 {
        right: 375px;
    }
    .viral-test-top-bg .head-trash-3 {
        right: 225px;
    }
    .viral-test-top-bg .head-trash-4 {
        right: 245px;
    }
    .viral-test-top-bg .head-trash-5 {
        right: 155px;
    }
    .viral-test-top-bg .head-trash-6 {
        right: 225px;
    }
    .viral-test-top-bg .head-trash-7 {
        right: 390px;
    }
    .viral-test-top-bg .head-trash-8 {
        right: 310px;
    }
    .viral-test-top-bg .head-trash-9 {
        right: 435px;
    }
    .viral-test-top-bg .head-trash-12 {
        right: 390px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 380px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 560px;
    }
    .block-head-standart-message .little-traingle {
        bottom: -2px;
        right: -45px;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 335px;
    }
}

@media all and (min-width: 1550px) {
    .block-viral-test-main-wrapper .block-viral-test-top {
        background-size: 1750px 1000px;
        background-position: 35% 62%;

    }
    .block-viral-test-main-wrapper .block-viral-test-top.bg-in-progress {
        background-position: 50% 77.3%!important;
    }
    .block-viral-test-main-wrapper .block-viral-test-top.represent-screen {
        background-position: 50% 69%;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: -125px;
    }
    .block-viral-test-top .bg-top-head {
        right: -300px;
    }
    .viral-test-top-bg .head-trash-1 {
        right: 330px;
    }
    .viral-test-top-bg .head-trash-2 {
        right: 450px;
    }
    .viral-test-top-bg .head-trash-3 {
        right: 300px;
    }
    .viral-test-top-bg .head-trash-4 {
        right: 320px;
    }
    .viral-test-top-bg .head-trash-5 {
        right: 230px;
    }
    .viral-test-top-bg .head-trash-6 {
        right: 300px;
    }
    .viral-test-top-bg .head-trash-7 {
        right: 465px;
    }
    .viral-test-top-bg .head-trash-8 {
        right: 385px;
    }
    .viral-test-top-bg .head-trash-9 {
        right: 510px;
    }
    .viral-test-top-bg .head-trash-12 {
        right: 465px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 480px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 660px;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 405px;
    }
}
@media all and (min-width: 1750px) {
    .block-viral-test-main-wrapper .block-viral-test-top {
        background-size: 2000px 1150px;
        background-position: 35% 66%;
    }
    /*.viral-test-top-bg .bg-top-head {*/
    /*    bottom: 28px*/
    /*}*/
    .block-viral-test-main-wrapper.on-scroll .bg-top-traingle.in-progress {
        top: 178px;
    }
    .block-viral-test-main-wrapper .block-viral-test-top.represent-screen {
        background-position: 50% 71%;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: -25px;
    }
    .block-viral-test-top .bg-top-head {
        right: -150px;
    }
    .viral-test-top-bg .head-trash-1 {
        right: 480px;
    }
    .viral-test-top-bg .head-trash-2 {
        right: 600px;
    }
    .viral-test-top-bg .head-trash-3 {
        right: 450px;
    }
    .viral-test-top-bg .head-trash-4 {
        right: 470px;
    }
    .viral-test-top-bg .head-trash-5 {
        right: 380px;
    }
    .viral-test-top-bg .head-trash-6 {
        right: 450px;
    }
    .viral-test-top-bg .head-trash-7 {
        right: 615px;
    }
    .viral-test-top-bg .head-trash-8 {
        right: 535px;
    }
    .viral-test-top-bg .head-trash-9 {
        right: 660px;
    }
    .viral-test-top-bg .head-trash-12 {
        right: 615px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 580px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 760px;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 560px;
    }
}
@media all and (min-width: 1850px) {
    .block-viral-test-top .bg-top-head {
        right: -75px;
    }
    .viral-test-top-bg .head-trash-1 {
        right: 555px;
    }
    .viral-test-top-bg .head-trash-2 {
        right: 675px;
    }
    .viral-test-top-bg .head-trash-3 {
        right: 525px;
    }
    .viral-test-top-bg .head-trash-4 {
        right: 545px;
    }
    .viral-test-top-bg .head-trash-5 {
        right: 455px;
    }
    .viral-test-top-bg .head-trash-6 {
        right: 525px;
    }
    .viral-test-top-bg .head-trash-7 {
        right: 690px;
    }
    .viral-test-top-bg .head-trash-8 {
        right: 610px;
    }
    .viral-test-top-bg .head-trash-9 {
        right: 735px;
    }
    .viral-test-top-bg .head-trash-12 {
        right: 690px;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 635px;
    }
}
@media all and (min-width: 2000px) {
    .block-viral-test-main-wrapper .block-viral-test-top {
        background-size: 2250px 1300px;
        background-position: 35% 68%;
    }
    /*.viral-test-top-bg .bg-top-head {*/
    /*    bottom: 26px*/
    /*}*/
    .block-viral-test-main-wrapper .block-viral-test-top.represent-screen {
        background-position: 50% 72%;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: 125px;
    }
    .block-viral-test-top .bg-top-head {
        right: -25px;
    }
    .viral-test-top-bg .head-trash-1 {
        right: 605px;
    }
    .viral-test-top-bg .head-trash-2 {
        right: 725px;
    }
    .viral-test-top-bg .head-trash-3 {
        right: 575px;
    }
    .viral-test-top-bg .head-trash-4 {
        right: 595px;
    }
    .viral-test-top-bg .head-trash-5 {
        right: 505px;
    }
    .viral-test-top-bg .head-trash-6 {
        right: 575px;
    }
    .viral-test-top-bg .head-trash-7 {
        right: 740px;
    }
    .viral-test-top-bg .head-trash-8 {
        right: 660px;
    }
    .viral-test-top-bg .head-trash-9 {
        right: 785px;
    }
    .viral-test-top-bg .head-trash-12 {
        right: 740px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 730px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 910px;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 685px;
    }
}
@media all and (min-width: 2250px) {
    .block-viral-test-main-wrapper .block-viral-test-top {
        background-size: 2500px 1430px;
        background-position: 35% 69%;
    }
    /*.viral-test-top-bg .bg-top-head {*/
    /*    bottom: 22px*/
    /*}*/
    .block-viral-test-main-wrapper .block-viral-test-top.represent-screen {
        background-position: 50% 72.8%;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: 225px;
    }
    .block-viral-test-top .bg-top-head {
        right: -10px;
    }
    .viral-test-top-bg .head-trash-1 {
        right: 620px;
    }
    .viral-test-top-bg .head-trash-2 {
        right: 740px;
    }
    .viral-test-top-bg .head-trash-3 {
        right: 590px;
    }
    .viral-test-top-bg .head-trash-4 {
        right: 610px;
    }
    .viral-test-top-bg .head-trash-5 {
        right: 520px;
    }
    .viral-test-top-bg .head-trash-6 {
        right: 590px;
    }
    .viral-test-top-bg .head-trash-7 {
        right: 755px;
    }
    .viral-test-top-bg .head-trash-8 {
        right: 675px;
    }
    .viral-test-top-bg .head-trash-9 {
        right: 800px;
    }
    .viral-test-top-bg .head-trash-12 {
        right: 755px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 830px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 1010px;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 700px;
    }
}

@media all and (min-width: 2500px) {
    .block-viral-test-main-wrapper .block-viral-test-top {
        background-size: 100% 1430px;
    }
    /*.block-viral-test-top.bg-in-progress .bg-top-head {*/
    /*    bottom: 52px*/
    /*}*/
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: 325px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 930px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 1110px;
    }
}
@media all and (min-width: 2750px) {
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: 425px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 1030px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 1210px;
    }
}
@media all and (max-width: 1100px) {
    .viral-test-top-bg .bg-top-head {
        right: -600px;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: -325px;
    }
    .block-viral-test-top .vital-test-block-start-title {
        margin-left: 10%;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 110px;
    }

    .viral-test-top-bg .head-trash-1 {
        right: 30px;
    }
    .viral-test-top-bg .head-trash-2 {
        right: 150px;
    }
    .viral-test-top-bg .head-trash-3 {
        right: -30px;
    }
    .viral-test-top-bg .head-trash-4 {
        right: 20px;
    }
    .viral-test-top-bg .head-trash-5 {
        right: -70px;
    }
    .viral-test-top-bg .head-trash-6 {
        right: 0px;
    }
    .viral-test-top-bg .head-trash-7 {
        right: 165px;
    }
    .viral-test-top-bg .head-trash-8 {
        right: 85px;
    }
    .viral-test-top-bg .head-trash-9 {
        right: 210px;
    }
    .viral-test-top-bg .head-trash-12 {
        right: 165px;
    }

}
@media all and (max-width: 1100px) and (min-width: 780px) {
    .representation-message-wrapper .block-head-standart-message .little-traingle {
        bottom: -3px;
        right: -45.5px;
    }
}
@media all and (max-width: 900px) and (min-width: 780px) {
    .representation-message-wrapper .block-head-standart-message .little-traingle {
        bottom: -3px;
        right: -46px;
    }
}

@media all and (max-width: 1000px) {
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: -375px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 230px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 410px;
    }
    .get-bucks-popup-wrapper .block-algorithm-table:before,
    .get-bucks-popup-wrapper .block-algorithm-table:after {
        content: '';
    }
    /*.block-head-standart-message .little-traingle {*/
    /*    bottom: -3px;*/
    /*    right: -44.5px;*/
    /*}*/
}
@media all and (max-width: 900px) {
    .viral-test-top-bg .bg-top-head {
        transform: scale(0.9);
        right: -625px;
        bottom: 5px;
    }
    .block-viral-test-top .vital-test-block-start-title {
        margin-left: 5%;
    }
    .block-viral-test-content .bg-top-traingle > div,
    .bg-top-traingle .triangle-second {
        background-size: 905px 85px!important;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: -475px
    }
    .block-viral-test-top .head-message-wrapper {
        right: 130px;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 50px;
        bottom: 185px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 310px;
    }
    .viral-test-top-bg .head-trash-1 {
        right: 5px;
        width: 81px;
    }
    .viral-test-top-bg .head-trash-2 {
        right: 125px;
        width: 63px;
    }
    .viral-test-top-bg .head-trash-3 {
        right: -55px;
        width: 55px;
    }
    .viral-test-top-bg .head-trash-4 {
        right: -5px;
        width: 63px;
    }
    .viral-test-top-bg .head-trash-5 {
        right: -95px;
        height: 63px;
    }
    .viral-test-top-bg .head-trash-6 {
        right: -25px;
        width: 63px;
    }
    .viral-test-top-bg .head-trash-7 {
        right: 140px;
        width: 90px;
    }
    .viral-test-top-bg .head-trash-8 {
        right: 60px;
        height: 63px;
    }
    .viral-test-top-bg .head-trash-9 {
        right: 185px;
        width: 63px;
    }
    .viral-test-top-bg .head-trash-12 {
        right: 140px;
        width: 70px;
    }
    .viral-block-test-result-wrapper .block-button-3-0:hover {
        opacity: 1;
    }
    .block-viral-test-main-wrapper .block-viral-test-top.represent-screen {
        background-position: 50% 70%;
    }
}
@media all and (max-width: 860px) {
    .block-viral-answer-list .block-button-descr-en {
        margin-left: 0;
    }
    .block-viral-answer-list .block-button-descr-en:before {
        margin-left: -100px;
    }
}
@media all and (max-width: 800px) {
    .block-test-result-right,
    .block-test-result-left {
        float: none!important;
        margin-left: auto;
        margin-right: auto;
    }
    .block-test-result-right .block-test-result-descr {
        margin-top: 50px;
    }
    .viral-block-number-of-answer {
        margin-left: auto;
        margin-right: auto;
    }
    .block-test-result-right .block-test-result-image {
        margin-bottom: 0;
    }
    .block-test-result-left {
        padding-top: 10px;
    }
    .block-test-result-parameters {
        margin-bottom: 40px;
    }
    .viral-test-result-header .block-viral-test-logo {
        left: 25px;
        top: 22px;
    }
    .block-viral-test-logo .viral-test-logo-title {
        font-size: 23px;
        line-height: 25px;
    }
    .viral-block-test-result .block-test-result-right.result-descr-wrapper {
        max-width: 320px;
    }
    .block-test-result-left .test-result-repeat-block {
        width: 172px;
        margin: 25px auto 0 auto;
    }
    .block-test-result-left .test-result-repeat-block.en-block {
        width: 155px;
    }
}
@media all and (max-width: 780px) {
    .viral-test-top-bg .bg-top-head {
        right: -200px;
        bottom: -2px;
        width: 520px;
        background-image: url("./components/img/bg/viral_head_rocketDAO.png");
    }
    .viral-test-top-bg.represent-screen .bg-top-head {
        bottom: -60px;
    }
    .viral-test-top-bg.represent-screen.animation .bg-top-head {
        bottom: -2px;
    }
    .block-viral-test-main-wrapper .block-viral-test-top {
        height: 850px;
        background-size: 1750px 1000px;
        background-position: 35% 1%;;
    }
    .block-viral-test-top .vital-test-block-start-title {
        margin-top: 115px;
    }
    .block-viral-test-main-wrapper .block-viral-test-top.bg-in-progress {
        background-size: 1205px 720px;
        /*background-position: 50% 79.5%!important;*/
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: -225px;
        transform: scale(1.4);
        bottom: 46px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 110px;
    }
    .block-viral-test-main-wrapper .block-viral-test-top.represent-screen {
        /*height: 510px;*/
        background-size: 1150px 675px;
        /*background-position: 35% 31%;*/

        height: 420px;
        background-position: 35% 50%;
    }
    .block-viral-test-main-wrapper .block-viral-test-top.represent-screen.animation {
        height: 600px;
        background-size: 1150px 675px;
        background-position: 35% 1%;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 90px;
        bottom: 112px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 235px;
    }
    .viral-test-top-bg .head-trash-1,
    .block-viral-test-top.represent-screen .head-trash-1 {
        display: none!important;
    }
    .viral-test-top-bg .head-trash-2,
    .block-viral-test-top.represent-screen .head-trash-1 {
        display: none!important;
    }
    .viral-test-top-bg .head-trash-3,
    .block-viral-test-top.represent-screen .head-trash-1 {
        display: none!important;
    }
    .viral-test-top-bg .head-trash-4,
    .block-viral-test-top.represent-screen .head-trash-1 {
        display: none!important;
    }
    .viral-test-top-bg .head-trash-5,
    .block-viral-test-top.represent-screen .head-trash-1 {
        display: none!important;
    }
    .viral-test-top-bg .head-trash-6,
    .block-viral-test-top.represent-screen .head-trash-1 {
        display: none!important;
    }
    .viral-test-top-bg .head-trash-7,
    .block-viral-test-top.represent-screen .head-trash-1 {
        display: none!important;
    }
    .viral-test-top-bg .head-trash-8,
    .block-viral-test-top.represent-screen .head-trash-1 {
        display: none!important;
    }
    .viral-test-top-bg .head-trash-9,
    .block-viral-test-top.represent-screen .head-trash-1 {
        display: none!important;
    }
    .viral-test-top-bg .head-trash-12,
    .block-viral-test-top.represent-screen .head-trash-1 {
        display: none!important;
    }

    .block-head-standart-message {
        border-radius: 8px;
        padding: 13px 10px 13px 13px;
        width: 220px;
        margin: 2px 80px 0 50px;
    }
    .block-head-standart-message .message-text {
        font-size: 13px;
        line-height: 16px;
    }
    .block-head-standart-message .little-traingle {
        transform: scale(0.85);
        bottom: -5px;
        right: -43px;
        background-size: contain;

        /*transform: scale(0.7);*/
        /*bottom: -7.7px;*/
        /*right: -40.8px;*/
    }
    .representation-message-wrapper .block-head-standart-message {
        width: 240px;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        background-position: 50% 100.14%;
    }
}
@media all and (max-width: 768px) {
    /*.block-check-list {*/
    /*    padding: 0 80px;*/
    /*}*/
}
@media all and (max-width: 720px) {
    .representation-message-wrapper .block-head-standart-message .message-text {
        font-size: 13px;
        line-height: 16px;
    }
    .representation-message-wrapper .block-head-standart-message {
        border-radius: 8px;
        padding: 13px 10px 13px 13px;
        margin: 0 80px 0 50px;
    }
    /*.block-viral-test-top .head-message-wrapper.representation-message-wrapper {*/
    /*    right: 90px;*/
    /*}*/
}
@media all and (max-width: 720px) {
    .block-viral-answer-list .block-button-descr {
        margin-left: 0;
    }
    .block-viral-answer-list .block-button-descr:before {
        margin-left: -63px;
    }
    .block-viral-answer-list .block-button-descr-en:before {
        margin-left: -100px;
    }
    .viral-block-test-result .block-test-result-left .test-result-main-title {
        font-size: 32px;
        line-height: 49px;
    }
    .viral-block-test-result .block-test-result-left .test-result-main-title-big {
        font-size: 49px;
        line-height: 49px;
    }

}
@media all and (max-width: 650px) {
    .passive-clearfix {
        clear: both;
    }
    .right-wrapper {
        float: right;
        margin-right: 0px;
    }
    .viral-test-choose-character-block {
        max-width: 350px;
        margin-top: 15px;
    }
    .block-viral-test-top .viral-test-top-bg.full-screen {
        /*height: 800px;*/
        min-height: 100vh;
        background-position: 45% 50%;
    }
    .viral-test-choose-character-block .choose-character-title {
        font-size: 26px;
        line-height: 26px;
    }
}
@media screen and (max-width: 645px) {
    .get-bucks-popup-wrapper .block-algorithm-table {
        max-width: 540px;
    }
    .get-bucks-popup-wrapper .block-algorithm-table.final-step {
        margin-top: 15px!important;
    }
    .get-bucks-popup-wrapper .block-algorithm-table.first-steps:before,
    .get-bucks-popup-wrapper .block-algorithm-table.first-steps:after {
        content: none;
    }
}

@media all and (max-width: 610px) {
    .viral-test-loader-block .viral-loader-main-title {
        padding: 0 20px;
    }
    .block-viral-answer-list .break-time-text {
        font-size: 24px;
        line-height: 34px;
    }
}
@media all and (max-width: 600px) {
    .footer-viral-test-result {
        height: 80px;
    }
     .footer-viral-test-result.finished {
         padding: 0 0 22px 0;
     }
    .footer-viral-test-result .viral-test-footer-button-top {
        width: 250px;
        height: 20px;
        margin: 0 auto;
    }
    .footer-viral-test-result .viral-test-footer-button.second-button {
    }
    .footer-viral-test-result .viral-test-footer-button.third-button {
        float: none;
        margin: 10px auto 0 auto;
        width: 230px;
    }

    .block-viral-answer-point .answer-text {
        font-size: 14px;
        line-height: 17px;
    }
    .block-viral-answer-list .question-text {
        font-size: 18px;
    }
    .block-viral-answer-list .block-question-buttons-line .block-button-3-0.button-3-0-green {
        width: 100%;
    }
    .block-viral-answer-list .user-question-button-cancel .block-button-3-0.button-3-0-green {
        margin-top: 12px;
    }

    .viral-test-loader-block {
        padding-bottom: 150px!important;
    }

    /*Mobile scroll*/
    .block-viral-answer-list {
        max-width: 450px;
    }
    .mobile-answer-list-scroll-wrapper {
        /*height: 270px;*/
        /*overflow-y: hidden;*/
        /*position: relative;*/
        display: block;
    }
    .desktop-answer-list-wrapper {
        display: none;
    }
    .mobile-answer-list-scroll {
        max-height: 260px;
        overflow-y: auto;
        padding-right: 3px;
        background: #FFFFFF;
    }
    .mobile-answer-list-scroll:after {
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, #2F3849 0%, rgba(255,255,255,0) 22.55%, rgba(255,255,255,0) 55.35%, #2f3849 96.44%);
    }
    .default-answer-point {
        z-index: 1233;
        position: relative;
    }
    .default-answer-point .block-viral-answer-point {
        margin-top: 0;
        border-radius: unset;
        box-shadow: unset;
        border: unset;
        /*background: transparent;*/
    }
    .default-answer-point .block-viral-answer-point.true {
        border: unset;
        box-shadow: unset;
    }
    .default-answer-point .block-viral-answer-point .block-answer-text {
        position: relative;
    }
    .default-answer-point .block-viral-answer-point .block-answer-text:before {
        position: absolute;
        content: '';
        top: -12px;
        left: 0;
        width: 100%;
        border-top: 1px solid rgba(0,0,0,0.15);
    }
}
@media all and (max-width: 600px) {
    .block-viral-test-top.viral-test-top-bg.bg-in-progress .viral-test-loader-logo {
        display: none;
    }
    .block-check-list {
        /*padding: 0 40px;*/
        width: calc(100% + 18px);
        margin-left: -9px!important;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        height: 600px;
        transform: scale(0.9);
        background-position: 50% 0%;
        /*bottom:0;*/
        bottom: -44px;
    }
    .block-viral-test-main-wrapper .block-viral-test-top.bg-in-progress {
        /*height: 350px;*/
        /*background-position: 50% 82%!important;*/
        height: 250px;
        background-position: 50% 73.3%!important;
        position: relative;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 70px;
        /*bottom: 185px;*/
        bottom: 128px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 150px;
        /*bottom: 65px;*/
        bottom: 15px;
        width: 75px;
    }
    .block-viral-test-content.content-in-progress {
        margin-top: 350px;
    }
    .block-viral-test-content .bg-top-traingle > div {
        top: -78px!important;
    }
    .block-viral-test-main-wrapper .block-viral-test-content {
        margin-top: 0!important;
        padding: 15px 25px 200px 25px
    }
    .get-bucks-popup-wrapper .block-advantage-point {
        /*margin: 25px 5px!important;*/
        margin: 10px 0!important;
    }
    .get-bucks-popup-wrapper {
        padding: 28px 0 37px 0;
        /*padding: 28px 9px 37px 9px;*/
    }
    .get-bucks-popup-wrapper .block-algorithm-table.final-step:before {
        width: calc(100% + 18px);
        left: -9px;
    }
    .get-bucks-popup-wrapper .scroll-inner-container {
        padding: 0 9px;
    }
}
@media all and (max-width: 576px) {
    .block-test-result-left .button-get-bucks {
        padding-top: 0;
    }
    .block-test-result-left .button-get-bucks .title-get-bucks {
        line-height: 51px;
    }
}
@media all and (max-width: 500px) {
    .big-start-title.start-title-ru-adaptive {
        font-size: 48px;
    }
    .medium-start-title.start-title-ru-adaptive {
        font-size: 48px;
        line-height: 44px;
    }
}
@media all and (max-width: 480px) {
    /*Head joke*/
    .head-joke-wrapper {
        padding: 30px 27px 39px 27px;
        width: calc(100% - 35px);
        top: 135px
    }
    .head-joke-wrapper .head-joke-title {
        font-size: 18px;
    }
    .head-joke-button {
        margin-top: 30px;
    }
    .head-joke-icon {
        width: 290px;
    }
    /*//Head joke*/
    /*.block-check-list {*/
    /*     padding: 0 10px;*/
    /* }*/
    .block-check-list .check-button-wrapper .check-button-title {
        font-size: 13px;
        padding: 0 9px;
    }
    .viral-test-top-bg .bg-top-head {
        right: -220px;
    }
    .viral-block-start-test-title {
        position: relative;
    }
    .viral-block-start-test-title .little-start-title {
        font-size: 22px;
        line-height: 25px;
        position: absolute;
        top: 293px;
        max-width: 225px;
    }
    .viral-block-start-test-title .block-button-3-0.button-3-0-green {
        width: 100%;
    }
    .viral-block-start-test-title .start-button-wrapper .block-button-3-0.button-3-0-green {
        width: auto;
    }
    .viral-block-start-test-title .start-button-wrapper {
        position: absolute;
        top: 355px;
    }
    .block-viral-test-top .vital-test-block-start-title {
        margin-left: 20px;
    }
    .block-viral-test-top .start-test-buttons-top-line {
        left: 170px;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: -250px;
    }
    .block-viral-test-top .head-message-wrapper {
        right: 40px;
    }
    .viral-test-choose-character-block .block-button-3-0.button-3-0-green {
        width: 100%;
    }
    .viral-block-start-test-title.representation-button .block-button-3-0.button-3-0-green {
        padding: 0!important;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 65px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 125px;
    }
    .block-viral-test-main-wrapper .block-viral-test-top {
        height: 630px;
        background-size: 1750px 1000px;
        background-position: 35% 49%;
    }
    .viral-test-top-bg .bg-top-head {
        bottom: -43px;
    }
    .block-viral-test-top .vital-test-block-start-title {
        margin-top: 25px;
    }
}
@media all and (max-width: 480px) {
    .block-viral-answer-list .block-button-3-0.button-3-0-green {
        width: 100%;
        height: 46px;
    }
    .block-viral-answer-list .answer-list-button-wrapper {
        width: 100%;
    }
    .block-viral-answer-list .block-button-3-0.button-3-0-green p.block-button-3-0-title {
        line-height: 46px;
    }
    .block-viral-answer-list .block-button-descr {
        margin-top: 8px;
    }
    .block-viral-answer-list .block-button-descr {
        margin-left: 0;
        max-width: none;
    }
    .block-viral-answer-list .block-button-descr:before {
        margin-left: -13px;
    }
    /*.block-viral-test-main-wrapper .block-viral-test-content {*/
    /*    padding-bottom: 250px;*/
    /*}*/
}
@media all and (max-width: 462px) {
    .get-bucks-popup-wrapper .block-advantage-point {
        margin: 25px 0!important;
    }
    .get-bucks-popup-wrapper .desktop-content {
        display: none;
    }
    .get-bucks-popup-wrapper .mobile-content {
        display: block;
    }
}

        /*IMG RESULT*/
@media all and (max-width: 440px) {
    .block-test-result-right .block-test-result-image {
        height: 390px;
    }
}
@media all and (max-width: 420px) {
    .block-test-result-right .block-test-result-image {
        height: 370px;
    }
    .viral-test-loader-block .loader-title {
        font-size: 28px;
        line-height: 32px;
        margin-top: 25px;
    }
    .viral-test-loader-block {
        margin-top: 35px;
    }
}
@media all and (max-width: 400px) {
    .block-test-result-right .block-test-result-image {
        height: 350px;
    }
    .viral-block-test-result-wrapper .block-btns-soc {
        width: 90%!important;
    }
    .block-viral-answer-list .user-question-button-ok.margin-top-50 .block-button-3-0.button-3-0-green {
        width: 100%;
        min-width: 250px;
        padding: 0!important;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 25px;
    }
    .viral-test-top-bg .bg-top-head {
        right: -265px;
    }
    .get-bucks-popup-wrapper .close {
        right: 30px;
    }
    .viral-test-loader-block .loader-title {
        font-size: 26px;
        line-height: 30px;
    }
}
@media all and (max-width: 380px) {
    .block-test-result-right .block-test-result-image {
        height: 330px;
    }
    .block-viral-test-top .start-test-buttons-top-line {
        width: 182px;
        right: 15px;
        left: unset;
    }
    .block-check-list {
        padding: 0;
    }
    .block-test-result-parameters .text-parameters {
        font-size: 22px;
    }
    .viral-test-loader-block .loader-title {
        font-size: 24px;
        line-height: 28px;
    }
    /*.viral-test-loader-block .loader-title.rus-loader-title {*/
    /*    font-size: 27px;*/
    /*    line-height: 30px;*/
    /*}*/
}
@media all and (max-width: 360px) {
    .block-test-result-right .block-test-result-image {
        height: 320px;
    }
    .viral-test-main-logo {
        padding: 20px 0 0 20px;
    }
    .viral-test-result-content-wrapper {
        padding: 0 20px 150px 20px;
    }
    .viral-test-loader-block .loader-title {
        font-size: 20px;
        line-height: 24px;
    }
    /*.viral-test-loader-block .viral-loader-main-title.rus-loader-title {*/
    /*    padding: 0 7px;*/
    /*}*/
}
@media all and (max-width: 350px) {
    .viral-block-start-test-title .big-start-title {
        font-size: 59px;
        line-height: 40px;
    }
    .viral-block-start-test-title .medium-start-title {
        font-size: 36px;
        line-height: 38px;
    }
    .viral-block-start-test-title .little-start-title {
        font-size: 20px;
        line-height: 23px;
        max-width: 200px;
    }
    .big-start-title.start-title-ru-adaptive {
        font-size: 44px;
    }
    .block-viral-test-top .vital-test-block-start-title {
        margin-left: 15px;
    }
    .viral-test-top-bg .bg-top-head {
        right: -285px;
    }
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: 5px;
    }
    .block-viral-test-main-wrapper .block-viral-test-top {
        height: 600px;
        background-position: 35% 55.5%;
    }
    .block-viral-test-top .vital-test-block-start-title {
        margin-top: 15px;
    }
    .viral-block-start-test-title .little-start-title {
        top: 280px;
    }
    .viral-block-start-test-title .start-button-wrapper {
        top: 342px;
    }
    .viral-test-top-bg .bg-top-head {
        bottom: -30px;
    }
    .get-bucks-popup-wrapper .close {
        right: 15px;
    }
    /*.viral-test-loader-block .loader-title.rus-loader-title {*/
    /*    font-size: 26px;*/
    /*    line-height: 28px;*/
    /*}*/
    .viral-test-loader-logo {
        padding: 25px 0 0 15px;
    }
    .viral-test-loader-block {
        margin-top: 30px;
    }
}
@media all and (max-width: 340px) {
    .block-test-result-right .block-test-result-image {
        height: 310px;
    }
    .viral-test-main-logo {
        padding: 15px 0 0 12px;
    }
    .block-viral-test-top .start-test-buttons-top-line {
        top: 15px;
    }

    .block-viral-test-top .head-message-wrapper {
        right: 25px;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        right: -265px;
    }
    .viral-test-top-bg .little-character-finished-block {
        right: 110px;
    }
    .viral-test-result-content-wrapper {
        padding: 0 15px 150px 15px;
    }
    .viral-block-test-result .block-test-result-left .test-result-main-title-big {
        font-size: 47px;
    }
    .viral-test-loader-bg-wrapper {
        padding: 0 15px;
    }
    .viral-block-test-result-wrapper .block-btns-soc {
        width: calc(100% - 30px)!important;
    }
    /*.viral-test-loader-block .loader-title.rus-loader-title {*/
    /*    font-size: 25px;*/
    /*    line-height: 27px;*/
    /*}*/
}
@media all and (max-width: 330px) {
    .block-viral-test-top .head-message-wrapper.representation-message-wrapper {
        right: -5px;
    }
    .viral-test-top-bg .bg-top-head {
        right: -295px;
    }
    .block-head-standart-message {
        width: 202px;
    }
    .block-viral-test-top .head-message-wrapper {
        bottom: 112px;
    }
    .block-viral-test-top.bg-in-progress .bg-top-head {
        bottom: -60px;
    }
    .block-viral-test-main-wrapper .block-viral-test-top.bg-in-progress {
        background-position: 50% 71%!important;
    }
    .viral-test-result-content-wrapper {
        padding: 0 10px 150px 10px;
    }
    /*.viral-test-loader-block .loader-title.rus-loader-title {*/
    /*    font-size: 24px;*/
    /*    line-height: 26px;*/
    /*}*/
}




/*traingle adaptive*/
@media all and (max-width: 870px) and (min-width: 600px) {
    .block-viral-test-main-wrapper.on-scroll .block-viral-test-top.bg-in-progress {
        clip-path: polygon(0% 0%, 100% 0%, 100% 181px, 0% 242px);
    }
}
@media all and (max-width: 870px) and (min-width: 600px) {
    .block-viral-test-main-wrapper.on-scroll .block-viral-test-top.bg-in-progress {
        clip-path: polygon(0% 0%, 100% 0%, 100% 181px, 0% 241px);
    }
}
@media all and (max-width: 810px) and (min-width: 600px) {
    .block-viral-test-main-wrapper.on-scroll .block-viral-test-top.bg-in-progress {
        clip-path: polygon(0% 0%, 100% 0%, 100% 181px, 0% 240px);
    }
}
@media all and (max-width: 780px) and (min-width: 600px) {
    .block-viral-test-main-wrapper.on-scroll .block-viral-test-top.bg-in-progress {
        clip-path: polygon(0% 0%, 100% 0%, 100% 181px, 0% 239px);
    }
}
@media all and (max-width: 750px) and (min-width: 600px) {
    .block-viral-test-main-wrapper.on-scroll .block-viral-test-top.bg-in-progress {
        clip-path: polygon(0% 0%, 100% 0%, 100% 181px, 0% 238px);
    }
}
@media all and (max-width: 710px) and (min-width: 600px) {
    .block-viral-test-main-wrapper.on-scroll .block-viral-test-top.bg-in-progress {
        clip-path: polygon(0% 0%, 100% 0%, 100% 177px, 0% 237px);
    }
}
@media all and (max-width: 680px) and (min-width: 600px) {
    .block-viral-test-main-wrapper.on-scroll .block-viral-test-top.bg-in-progress {
        clip-path: polygon(0% 0%, 100% 0%, 100% 179px, 0% 234px);
    }
}
@media all and (min-width: 768px) and (min-width: 600px) {
    .character-wrapper:hover .character-circle {
        width: 112px;
    }
}



