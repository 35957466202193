.social-button {
    display: inline-block;
    margin: 0 20px 15px 0;
    vertical-align: top;
    width: 26px;
    height: 26px;
}

.social-button a {

}

.social-button a i {
    font-size: 22px;
}

.social-button a .btc {
    border-radius: 50%;
    border: 2px solid;
    padding: 0 0 0 2px;
    width: 23px;
    height: 23px;
    font-size: 11px;
    text-align: center;
    line-height: 20px;
    vertical-align: bottom;
}

.bounty {
    padding: 0 7px;
    background: #0000D3;
    border-radius: 50px;
    height: 26px;
}

.bounty a i {
    line-height: 26px;
    font-size: 15px;
    display: inline-block;
    color: #979797;
}

.bounty a i {
    border: 2px solid;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    margin: 2px 0 0 0;
    padding: 3px;
    position: relative;
    left: -5px;
}

.bounty a p {
    line-height: 27px;
    color: #979797;
    font-size: 10px;
    font-weight: 300;
    font-family: "Stratos LC";
    display: inline-block;
    float: right;
    margin: 0 5px 0 0px;
}

.active-social-button a,
.active-social-button a i {
    color: #979797;
    transition: .4s;
}

.no-active-social-button a,
.no-active-social-button a i {
    color: #979797;

}

.active-social-button a:hover,
.active-social-button a i:hover {
    color: #FFFFFF;
}

.active-bounty {
    background: #0000D3;
    color: #979797;

    transition: .4s;
    cursor: pointer;
}

.no-active-bounty {
    background: #F1F2F2;
    color: #979797;

}

.active-bounty:hover {
    color: #979797;

    background: #00F082;
}

.bounty a i:before {
    top: -7px;
    left: 1px;
    position: relative;
}
.social-button{
    position: relative;
}
.social-button a{
    position: relative;
}
.social-button a img{
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    right: 0;
    transition: .3s;
}

.social-button a img.img-1{opacity: 1}
.social-button a img.img-2{opacity: 0}
.social-button:hover .img-1{opacity: 0}
.social-button:hover .img-2{opacity: 1}