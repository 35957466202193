
.block-eval-param-roadmap {
	left: 56px;
	height: 100vh;
	width: 320px;
	background-color: #F6F6F6;
	padding: 20px 45px 50px 80px;
}
.block-eval-param-dashboard {
	background-color: #FAFAFA;
	margin-left: 376px;
	min-height: 100vh;
	padding: 30px 0 50px 90px;
}
.block-eval-param-achieve {
	background-color: #FAFAFA;
	margin-left: 376px;
	min-height: 100vh;
	padding: 40px 0 50px 90px;
}
.block-eval-param-achieve .achieve-title {
	font-size: 28px;
	line-height: 22px;
	font-weight: 500;
	color: #2F3849;
}
.block-eval-param-achieve .toggle-buttons-line {
	margin: 22px 80px 24px 0;
}
.block-eval-param-achieve .toggle-buttons-line > * {
	margin-right: 6px;
}
.block-eval-param-dashboard .dashboard-title {
	font-size: 28px;
	line-height: 1;
	font-weight: 500;
	color: #2F3849;
}
.block-eval-param-dashboard .temp-text {
	width: 430px;
	font-size: 18px;
	line-height: 22px;
	font-weight: 300;
	color: #2F3849;
}
.block-eval-roadmap-header {
	padding-bottom: 15px;
	border-bottom: 1px solid #D8D8D8;
}
.block-eval-roadmap-header .eval-param-title {
	font-size: 11px;
	line-height: 14px;
	letter-spacing: -0.505952px;
	color: #2F3849;
}
.block-eval-roadmap-header .eval-param-name {
	font-weight: 500;
	font-size: 24px;
	line-height: 30px;
	letter-spacing: -0.505952px;
	color: #2F3849;
	cursor: pointer;
}
.block-eval-roadmap-header .eval-param-position {
	margin-top: 5px;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: -0.708333px;
	color: #0ECFAA;
}
.block-eval-param-roadmap .block-eval-roadmap-map {
	margin-top: 15px;
}
.block-eval-param-diplomas > div {
	margin-bottom: 8px;
}
.block-eval-param-diplomas .diplom {
	background-image: url(./tempImg/img_diplomas.png);
	background-repeat: no-repeat;
	height: 66px;
}
.block-eval-param-diplomas .button-show-more {
	background-image: url(./tempImg/img_show_more.png);
	background-repeat: no-repeat;
	height: 32px;
}