


.block-menu {
    /*margin: 0 0 30px 0;*/
}

.block-body-team-2-0 {
    margin: 0 0 70px 0;
    overflow: hidden;
}


.shell-cart-person {
    margin: 0 0 35px 0;
}

.title-block-team-2-0 {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 30px;
    line-height: 38px;
    font-weight: 400;
    margin: 0 0 25px 0;
}

.no-fixed {
    position: relative;
}

.block-team-2-0-members-right-looking {
    margin: 30px 0 0 0;
}

.block-team-2-0-members-right-looking .title-looking {
    color: #9B9B9B;
    font-family: "Stratos LC";
    font-size: 20px;
    line-height: 25px;
    margin: 0;
    font-weight: 300;
}

.block-team-2-0-members-right-looking-design {
    border: 1px solid #9B9B9B;
    padding: 15px 25px;
    margin: 20px 0 0 0;
}

.block-team-2-0-members-right-looking-design-title {
    overflow: hidden;
    margin: 0 0 10px;
}

.block-team-2-0-members-right-looking-design-title .design-title {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 15px;
    line-height: 19px;
    margin: 0;
    float: left;
    font-weight: 400;
}

.block-team-2-0-members-right-looking-design-title .design-date {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 19px;
    margin: 0;
    float: right;
    font-weight: 300;
}

.block-team-2-0-members-right-looking-design-description {
    margin: 0 0 10px;
}

.block-team-2-0-members-right-looking-design-description p {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 19px;
    margin: 0;
    font-weight: 300;
}

.block-team-2-0-members-right-looking-design-description a {
    color: #0000D3;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 19px;
    margin: 0;
    font-weight: 400;
    text-decoration: underline;
    cursor: pointer;
}

.block-team-2-0-members-right-looking-design-description a:hover {
    color: #2d2eff;
}

.block-team-2-0-members-right-looking-design-respond {
    border-top: 1px solid #9B9B9B;
    padding: 15px 0 0 0;
    margin: 15px 0 0 0;
}

.block-team-2-0-members-right-looking-design-respond p {
    color: #0000D3;
    font-family: "Stratos LC";
    font-size: 15px;
    line-height: 19px;
    font-weight: 400;
    margin: 0;
}

.block-team-2-0-members-right-looking-design-respond p span {
    color: #9B9B9B;
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 19px;
    font-weight: 300;
    margin: 0 0 0 8px;
}


@media screen and (min-width: 1200px) {

    .block-team-2-0-members {
        width: 1140px;
        margin: 0 auto;
    }

    .block-team-2-0-members-left {
        width: 720px;
        float: left;
    }

    .block-team-2-0-members-right {
        width: 330px;
        float: right;
    }
}

@media screen and (max-width: 1199px) {
    .block-team-2-0-members {
        width: 720px;
        margin: 0 auto;
    }

    .block-team-2-0-members-left {
        width: 720px;
        float: none;
    }

    .block-team-2-0-members-right {
        width: 720px;
        float: none;
    }
}

@media screen and (max-width: 750px) {
    .block-team-2-0-members {
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;

    }

    .block-team-2-0-members-left {
        width: 100%;
        float: none;
    }

    .block-team-2-0-members-right {
        width: 100%;
        float: none;
    }
}