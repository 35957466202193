.popup{
  padding: 10px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &.viral-result{
    width: 100%;
    max-width: 640px;
    h3 {
      margin-top: -12px;
    }
  }
  .close-block {
    top:30px;
    right: 30px;
  }

  .content{
    background: #fff;
    border: 1px solid #E4E4E4;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 32px;
    position: relative;

    //.close-title{
    //  position: absolute;
    //  right: 29px;
    //  top: 29px;
    //  z-index: 12321312;
    //  cursor: pointer;
    //  color: #000;
    //  .close{
    //    width: 16px;
    //    height: 16px;
    //    position: relative;
    //    opacity: 1;
    //
    //    &:before,
    //    &:after{
    //      width: 1px;
    //      height: 16px;
    //      background: #7D7D7D;
    //    }
    //  }
    //}

    h5{
      margin-top: 6px;
    }

    .mt23{
      margin-top: 23px;
    }

    i{
      background: #17B699;
      border-radius: 6px;
      width: 49px;
      height: 49px;
      color: #fff;
      text-align: center;
      vertical-align: center;
      line-height: 49px;
      font-size: 28px;
      cursor: pointer;
      transition: all 1s ease-in-out;
      &:hover{
        background: #1bcdac;
      }
    }

    img{
      max-width: 100%;
      width: 100%;
      display: block;
      margin-top: 28px;
      border-radius: 6px;
    }
  }
}

@media (max-width: 756px) {
  .popup{
    .content{
      padding: 16px;
      .mt23{
        margin-top: 13px;
      }
      img{
        margin-top: 14px;
      }
      h5{
        margin-top: 0;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
      }
      //.close-title{
      //  top: 14px;
      //  right: 14px;
      //}
    }
    &.viral-result{
      h3 {
        margin-top: -6px;
      }
    }
    .close-block {
      top:20px;
      right: 20px;
    }


  }
}
@media (max-width: 576px) {
  .popup {
    padding: 0;
    .content {
      height: 100vh;
      border: none;
      border-radius: unset;
    }
    .close-block {
      top:10px;
      right: 10px;
    }
  }
}