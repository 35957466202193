
.profile-menu-frame {
    position: fixed;
    left: -60px;
    top: 0;
    height: 100vh;
    z-index: 999;
    transition: .3s;
}

.profile-menu-frame.active {
    left: 0;
}

.main-menu-block {
    /*position: fixed;*/
    /*left: 0;*/
    /*top: 0;*/
    position: relative;
    float: left;
    width: 56px;
    height: 100vh;
    background: #2F3849;
    box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.15);
    z-index: 999;
}

.roles-menu-block {
    position: fixed;
    width: 235px;
    left: -280px;
    height: inherit;
    background: #2F3849;
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.15);
    z-index: 998 !important;
}

.startup-menu-block {
    padding: 30px 0 35px 35px;
}

.accelerator-menu-block {
    padding: 38px 0 35px 35px;
}

.investor-menu-block {
    padding: 40px 0 35px 35px;
}

.expert-menu-block {
    padding: 20px 0 35px 35px;
    width: 275px;
}

.expert-param-menu-block,
.like-menu-block {
    position: fixed;
    float: left;
    width: 430px;
    left: -500px;
    height: inherit;
    background: #F6F6F6;
    z-index: -1;
}

.like-menu-block .title {
    margin: 25px 0 30px 30px;
    font-family: Stratos LC;
    font-size: 18px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.8);
}

.main-menu-block .title {
    font-family: Stratos LC;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    letter-spacing: -0.04em;
    color: #979797;
}

.roles-menu-block .title {
    font-family: Stratos LC;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.505952px;
    color: #FFFFFF;
}

.startup-menu-block .title {
    margin-bottom: 25px;
}

.investor-menu-block .title {
    margin-bottom: 10px;

}

.menu-block-dao {
    margin: 17px auto 0;
    width: 40px;
    height: 28px;
    cursor: pointer;
}

.menu-block-dao .icon {
    width: 36px;
    height: 28px;
    margin: 0 auto;
    background-image: url('./img/Rocket_DAO-Block.svg');
    background-repeat: no-repeat;
}

.menu-block-arrow-back {
    margin: 20px auto 0;
    width: 18px;
    height: 10px;
    background-image: url('./img/ic_arrow.svg');
    background-repeat: no-repeat;
    cursor: pointer;
}
@media (max-width: 500px) {
    .menu-block-arrow-back.menu{
        background-size: 55%;
        background-image: url('./img/ic_add.svg');
        transform: rotate(45deg);
    }
}


.menu-block-icon-like {
    margin: 25px auto 0;
    width: 24px;
    height: 24px;
    background-image: url('./img/ic_like.svg');
    background-repeat: no-repeat;
    cursor: pointer;
}

.menu-block-roles {
    margin-top: 15vh;
    width: 100%;
}

.menu-block-roles .block-role,
.menu-block-avatar {
    margin: 4px auto 0;
    width: 34px;
    height: 34px;
    border-radius: 50%;
}

.menu-block-roles .button-block {
    width: 100%;
    cursor: pointer;
}

.menu-block-avatar {
    cursor: pointer;
}

.menu-block-roles .title {
    margin-top: 16px;
}

.menu-block-roles .block-startup {
    background-image: url('./img/ic_startup.png');
}

.menu-block-roles .block-investor {
    background: #2CA6EB;
    box-shadow: inset 0px 10px 10px rgba(0, 0, 0, 0.25);
}

.menu-block-roles .block-expert {
    background: #0ECFAA;
    box-shadow: inset 0px 10px 10px rgba(0, 0, 0, 0.25);
}

.menu-block-roles .block-accelerator {
    background: #FCFFFE;
    box-shadow: inset 0px 10px 10px rgba(0, 0, 0, 0.25);
}

.menu-block-avatar {
    margin-top: 15vh;
    margin-bottom: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.menu-block-avatar.head {
    margin-top: unset !important;
    margin-bottom: unset !important;
    float: right;
}

.menu-block-avatar.bottom-50 {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.roles-menu-block .icon-add {
    margin: 20px 10px;
    padding-top: 5px;
    width: 16px;
    height: 16px;
    background-image: url('./img/ic_add.svg');
    background-repeat: no-repeat;
    cursor: pointer;
}

.role-container .icon-add {
    margin: 20px 10px;
    padding-top: 5px;
    width: 16px;
    height: 16px;
    background-image: url('./img/ic_add.svg');
    background-repeat: no-repeat;
    cursor: pointer;
}

.roles-menu-block .expert-icon-add {
    margin: 28px 5px;
}

.roles-menu-block .role-container {
    margin-bottom: 55px;
}

.block-expert-menu-header {
    width: 100%;
    height: 80px;
}

.block-expert-menu-header .expert-header-avatar {
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    float: left;
}

.block-expert-menu-header .expert-header-text {
    width: 150px;
    margin-bottom: 14px;
    float: left;
    min-height: 40px;
}

.block-expert-menu-header .expert-header-40-simbols {
    height: 24px;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -0.505952px;
    color: #FFFFFF;
    opacity: 0.6;
    overflow: hidden;
}

.block-expert-menu-header .expert-header-balance {
    margin-top: 5px;
    font-size: 10px;
    line-height: 13px;
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.block-expert-menu-header .expert-header-profile-button
.black-button-profile {
    background-color: #0ECFAA;
    margin-right: 7px;
}

.block-expert-menu-header .expert-header-profile-button
.black-button-profile .black-button-title {
    padding: 0 60px 0 8px;
}

.block-expert-menu-header .expert-header-edit-button
.black-button-profile {
    background-color: #FFFFFF;
}

.block-expert-menu-header .expert-header-edit-button
.black-button-profile .black-button-title {
    color: #2F3849;
}

.block-expert-menu-header .expert-header-edit-button
.black-button-profile .black-button-arrow {
    background-image: url(../startupMenuProfile/img/ic_arrow_black.svg);
}

.expert-param-menu-block .block-expert-evaluation-param,
.expert-menu-block .block-expert-evaluation-param {
    display: block;
    margin-top: 16px;
    width: 100%;
    padding: 35px 0 25px 0;
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #FFFFFF;
}

.expert-menu-block .block-expert-evaluation-param {
    width: calc(100% - 25px);
}

.block-expert-evaluation-param .expert-eval-param-title {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.505952px;
    color: #FFFFFF;
}

.block-expert-menu-bottom {
    width: 100%;
}

.expert-param-menu-block .menu-title,
.expert-menu-block .menu-title {
    margin-top: 20px;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: -0.505952px;
    color: #FFFFFF;
    cursor: pointer;
}
.menu-title a{
    color: #FFFFFF;
    text-decoration: none;
}

.block-expert-evaluation-param .menu-title {
    margin-top: 0;
    line-height: 20px;
    float: left;
}

.block-expert-evaluation-param .tasks-count {
    margin-left: 10px;
    height: 20px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 11px;
    float: left;
}

.block-expert-evaluation-param .tasks-count > div {
    padding: 0 10px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.708333px;
    color: #FFFFFF;
}

.block-expert-tasks {
    margin-top: 50px;
    height: 20px;
}

.block-list-link-menu-mobile {
    display: table;
    width: 100%;
    /*padding: 0 20px;*/
}

.link-home-menu-mobile {
    padding: 0 20px 20px 20px;
}

.link-home-menu-mobile a {
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.505952px;
    margin: 0;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 300;
}

.links-user-menu-mobile {
    padding: 4px 0;
    margin: 0 20px;
    width: calc(100% - 40px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.block-link-icon-user-menu-mobile {
    width: 100%;
    display: table;
    margin: 16px 0;
    cursor: pointer;
}

.block-link-icon-user-menu-mobile p.title-link-user-menu-mobile {
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.505952px;
    margin: 0;
    color: #FFFFFF;
    text-decoration: none;
    float: left;
    font-weight: 300;
}

.block-link-icon-user-menu-mobile i {
    width: 20px;
    height: 20px;
    float: right;
    display: block;
    color: #FFFFFF;
    font-size: 20px;
}

.block-link-icon-user-menu-mobile.active i {
    color: #0ECFAA;
}


.list-role-user-menu-mobile {
    width: 100%;
    display: block;
    max-height: calc(100vh - 370px);
    overflow-x: hidden;
    overflow-y: auto;
    margin: 20px 0;
}

.block-role-list-user-menu-mobile {
    margin: 0 0 20px 0;
    transition: .3s;
}

.block-top-role-list-user-menu-mobile {
    padding: 0 20px;
    cursor: pointer;
}

.block-top-role-list-user-menu-mobile p.title-top-role-list-user-menu-mobile,
.link-settings-menu-mobile p.title-top-role-list-user-menu-mobile {
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.505952px;
    margin: 0;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 300;
}
.link-settings-menu-mobile .block-settings-menu-mobile {
    margin-bottom: 20px;
}

.block-role-list-user-menu-mobile.active .block-top-role-list-user-menu-mobile p.title-top-role-list-user-menu-mobile {
    font-weight: 400;
    font-size: 18px;
}

.block-body-role-list-user-menu-mobile {
    padding: 0 0 0 30px;
    position: relative;
    overflow: hidden;
}

.block-role-list-user-menu-mobile.active .block-body-role-list-user-menu-mobile {
    padding: 0 0 30px 50px;
}

.block-body-role-list-user-menu-mobile:after {
    content: '';
    position: absolute;
    left: -20px;
    bottom: 0;
    width: calc(100% + 40px);
    height: 56px;
    background: linear-gradient(180deg, rgba(47, 56, 73, 0) 13.15%, #2E3440 100%);
}

.block-body-role-list-user-menu-mobile .role-container {
    margin: 20px 0 0 0;
}

.block-body-role-list-user-menu-mobile .role-container .title {
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.505952px;
    margin: 10px 0;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 300;
    text-align: left;
}


.link-settings-menu-mobile {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px 0 0 0;
    width: calc(100% - 40px);
    margin: 0 20px;
}

.link-settings-menu-mobile a {
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.505952px;
    margin: 0;
    color: #FFFFFF;
    text-decoration: none;
    font-weight: 300;
}

.block-hamburger-edit-left-mobile {
    position: absolute;
    left: 10px;
    top: 15px;
    cursor: pointer;
}

@media (min-width: 500px) {
    .menu-block-roles {
        display: block;
    }

    .menu-block-icon-like {
        display: block;
    }

    .menu-block-avatar {
        display: block;
    }

    .block-list-link-menu-mobile {
        display: none;
    }

    .block-button-hamburger-like {
        display: none;
    }

    .block-logo-roles-menu-block {
        display: none;
    }

    .block-hamburger-edit-left-mobile {
        display: none;
    }

    .block-button-back-roles-menu-block {
        display: none;
    }

    .block-user-info-mobile {
        display: none;
    }
}

@media (max-width: 500px) {
    .new-like-block-to-list {
        width: 100%;
    }

    .new-like-block-to-list .main-block .subtitle {
        width: calc(100% - 50px);
    }

    .new-like-block-to-list .main-block {
        width: calc(100% - 120px);
    }

    .block-user-info-mobile {
        width: 100%;
        background: #FFFFFF;
        height: 70px;
        padding: 16px 0 14px 0;
    }

    .block-arrow-back-user-info-mobile {
        width: 40px;
        height: 40px;
        margin: 0 15px;
        float: left;
    }

    .block-arrow-back-user-info-mobile i {
        color: #2F3849;
        width: 40px;
        height: 40px;
        display: block;
        text-align: center;
        line-height: 40px;
        font-size: 24px;
        cursor: pointer;
    }

    .block-left-user-info-mobile {
        width: 40px;
        height: 40px;
        float: left;
    }

    .block-left-user-info-mobile .avatar {
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
    }

    .block-right-user-info-mobile {
        width: calc(100% - 110px);
        padding: 0 30px 0 10px;
        float: right;
    }

    .block-right-user-info-mobile p.title-right-user-info-mobile {
        font-size: 16px;
        letter-spacing: -0.505952px;
        color: #2F3849;
        margin: 0;
    }

    .active-mobile {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10000;
        transition: .3s;
        background: #E5E5E5;
    }

    .active-mobile .block-fund-waitlist {
        padding: 20px;
        min-height: 100vh;
        display: table;
    }

    .active-mobile .block-waitlist-temp-text {
        padding: 20px;
        min-height: 100vh;
        display: table;
    }

    .active-mobile .block-fund-newsfeed {
        padding: 20px;
        min-height: 100vh;
        display: table;
    }

    .active-mobile .block-community-temp-text {
        padding: 20px;
    }

    .active-mobile .block-startup-team {
        padding: 20px;
        min-height: 100vh;
        display: table;
    }

    .active-mobile .block-expert-ach {
        padding: 20px;
    }

    .active-mobile .block-expert-ach .block-form-add-edit-modal-reg-expert {
        padding: 20px !important;
    }

    .active-mobile .edit-profile-list {
        margin: 0;
        padding: 20px;
    }

    .active-mobile .edit-profile-table {
        width: 100%;
    }

    .active-mobile-false {
        left: -100%;
    }

    .active-mobile-true {
        left: 0;
    }

    .active-mobile-undefined {
        left: 0;
    }

    .active-mobile .block-dashboard-buttons-line .dashboard-button {
        width: 100%;
        display: block;
        margin: 0 0 10px 0;
    }

    .active-mobile .block-startup-team-title .button-add-members {
        margin: 20px 0 0 0;
    }

    .block-hamburger-edit-left-mobile {
        display: block;
    }

    .edit-left {
        position: absolute;
        top: 0;
        width: 100%;
        height: auto;
        z-index: 2000;
        background: #f6f6f6;
        overflow: hidden;
    }

    .open-left {
        left: 0;
    }

    .close-left {
        left: -100%;
    }

    .block-eval-roadmap-header {
        padding-left: 40px;
    }

    .block-fund-sidebar {
        margin: 0 !important;
        width: 100% !important;
        height: auto!important;
        float: none!important;
    }
    .profile-menu-frame.open-left-menu:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #F6F6F6;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .block-fund-sidebar .block-fund-info {
        padding: 0 0 0 40px;
        display: block !important;
    }

    .block-fund-sidebar .block-fund-buttons-line {
        padding: 0 0 0 40px;
    }

    .edit-right {

    }

    .block-eval-param-roadmap {
        width: 100% !important;
    }

    .menu-block-roles {
        display: none;
    }

    .menu-block-icon-like {
        display: none;
    }

    .menu-block-avatar {
        display: none;
    }

    .profile-menu-frame {
        width: 100%;
    }

    .main-menu-block {
        width: 100%;
    }

    .menu-block-dao {
        margin: 18px;
        float: left;
    }

    .menu-block-arrow-back {
        background-position: center;
        width: 30px;
        height: 30px;
        margin: 18px;
        float: right;
    }

    .block-list-link-menu-mobile {
        display: table;
    }

    .profile-menu-frame {
        left: -500px;
    }

    .expert-param-menu-block, .like-menu-block {
        z-index: 10000;
        width: 100%;
    }

    .like-menu-block.active {
        left: 0 !important;
    }

    .like-menu-block .title {
        display: none;
    }

    .block-button-hamburger-like {
        display: block;
        float: left;
        height: 60px;
        width: 100%;
        background: #FFFFFF;
        padding: 20px;
    }

    .block-button-hamburger-like .button-hamburger-new {
        margin: 2px 0 0 0;
        display: inline-block;
    }

    .block-button-hamburger-like .title-mob {
        font-family: "Stratos LC";
        font-size: 18px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.8);
        margin: 0 0 0 25px;
        display: inline-block;
    }

    .expert-param-menu-block, .like-menu-block {
        transition: .3s;
    }

    .expert-menu-block.roles-menu-block {
        transition: .3s;
    }

    .expert-menu-block.roles-menu-block.active {
        z-index: 10;
        left: 0 !important;
        width: 100%;
        padding: 20px 60px 20px 65px;
    }

    .block-logo-roles-menu-block {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
    }

    .block-logo-roles-menu-block .menu-block-dao {
        margin: 18px 10px;
    }

    .block-button-back-roles-menu-block {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
    }

    .edit-profile-list .col-auto {
        width: 100% !important;
    }

    /*.edit-profile-list .edit-profile-main-title {*/
        /*width: 100% !important;*/
    /*}*/

    .edit-profile-main-title .main-title {
        float: none !important;
    }

    .edit-profile-main-title .last-edited {
        position: relative !important;
    }

    .edit-profile-main-title .last-edited > div {
        text-align: left !important;
        margin: 4px 0 0 0;
    }

    .shell-scroll-component {
    }

    .shell-scroll-component .col-left {
        float: none !important;
        width: 100% !important;
        padding: 0 !important;
    }

    .shell-scroll-component .col-right {
        float: none !important;
        width: 100% !important;
        padding: 0 !important;
    }

    .block-list-tabs {
        display: block;
    }

    .block-list-tabs .col-6 {
        padding: 0 !important;
        width: 100% !important;
        flex: none !important;
        max-width: 100% !important;
    }

    .block-body-role-list-user-menu-mobile .icon-add {
        margin: 20px 10px 10px 10px;
        padding-top: 5px;
        width: 16px;
        height: 16px;
        background-image: url('./img/ic_add.svg');
        background-repeat: no-repeat;
        cursor: pointer;
        z-index: 1;
        position: relative;
    }
}

@media (max-width: 750px) {
    .block-swiper-startup {
        height: 480px !important;
    }

    .block-swiper-startup .image-gallery {
        height: 480px !important;
    }

    /*.image-gallery-image {*/
    /*    height: 390px !important;*/
    /*}*/

    /*.image-gallery-image .inner {*/
    /*    height: 390px !important;*/
    /*}*/
}

@media (max-width: 500px) {
    .block-swiper-startup {
        height: 355px !important;
    }

    .block-swiper-startup .image-gallery {
        height: 355px !important;
    }

    /*.image-gallery-image {*/
    /*    height: 260px !important;*/
    /*}*/

    /*.image-gallery-image .inner {*/
    /*    height: 260px !important;*/
    /*}*/
}

.block-swiper-startup {
    width: 100% !important;
}

.block-swiper-startup .image-gallery {
    width: 100% !important;
}

.block-swiper-startup .image-gallery-thumbnails-wrapper {
    width: 100% !important;
}

