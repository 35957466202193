.block-jobs-block {
    margin: 0 0 40px 0;
}

.block-profile-body {
    background-color: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    margin: 0 0 20px 0;
    padding: 0 0 40px 0;
    overflow: hidden;
    position: relative;
}

.block-profile-body-new {
    background-color: #FFFFFF;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    margin: 0 0 20px 0;
    padding: 0 0 40px 0;
    position: relative;
    overflow: hidden;
}

.block-profile-body.block-news {
    padding: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.col-left-size {
    width: 222px;
    margin: 0 auto;
    vertical-align: top;
}

.name-person-block-bottom-ava {
    margin: 30px 0;
}

.block-contact-link-desc-profile {
    margin: 30px 40px 0 40px;
}

.block-contact-link-profile {
    display: block;
    width: 100%;
    vertical-align: top;
    overflow: hidden;
}

.block-description-profile {
    display: block;
    width: 100%;
    vertical-align: top;
    padding: 25px 0 0 0;
    overflow: hidden;
}

.block-file-person-profile {
    margin: 45px 0 0 0;
}

.group-file {
    margin: 15px 40px 0 40px;
}

.block-tag-person-profile {
    margin: 50px 40px 0 40px;
}

.group-tag {
    margin: 15px 0 30px -5px;
}

.block-edit-user-info {
    margin: 30px 40px 0 40px;
}

.group-edit-user-info {
    margin: 15px 0 0 0;
    overflow: hidden;
}

.block-table-col-left {
    display: inline-block;
    width: 50%;
}

.block-table-col-right {
    display: inline-block;
    margin: 0 0 0 10px;
    width: 47%;
    box-sizing: border-box;
}

.block-table-col-third {
    display: inline-block;
    width: 100%;
    margin: 10px 0;
    box-sizing: border-box;
}

.block-table-col-third:first-child {
    margin-left: 0;
}

.group-small-project {
    margin: 30px 0 40px 0;
}

.group-follows-project {
    margin: 20px 0 0 0;
}

.photo-name-container {
    margin: 2% 0;
}

.proto-name {
    padding: 2% 0;
}

.proto-name div {
    /*float: left;*/
}

.proto-name span {
    color: #F52E07;
    text-decoration: underline;
    float: left;
    margin-left: 1%;
    cursor: pointer;
}

.files-container {
    width: 100%;

    padding-top: 10px;
    border-top: 2px solid #000000;
    /*display: flex;*/

    /*flex-direction: row;*/

    /*justify-content: flex-start;*/
}

.files-container .row {
    margin-left: 25px;
    margin-right: 25px;
}

.cancel-file-download {
    display: inline-block;
    text-decoration: underline;
    margin-right: 5%;
    cursor: pointer;
    color: #0000D3;
    font-size: 13px;
}

.save-file-download {
    display: inline-block;
    background-color: #00F082;
    height: 32px;
    width: 147px;
    border: none;
    cursor: pointer;
}

.button-add {
    width: 30px;
    height: 30px;
    /*font-weight: 500;*/
    font-size: 1.4rem;
    border-radius: 50%;
    background-color: #A2A6AF;
    transition: .5s;
    cursor: pointer;
    float: left;
    margin-top: 1.6%;
    border: none;
    color: white;
    /*padding: 1px 2px;*/
    line-height: 15px;
    text-align: center;
    text-decoration: none;
    display: block;

    outline: none;
}

.button-add:hover {
    background-color: #0028DC;
}

.button-add-2 {
    width: 30px;
    height: 30px;
    font-size: 1.4rem;
    background-color: rgb(188, 188, 188);
    color: white;
    line-height: 1.5;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    text-decoration: none;
    cursor: pointer;
    margin-left: 10px;
}

.button-upload-files {
    border: 1px black solid;
    background-color: white;
    width: 160px;
    padding: 0 17px;
    height: 30px;
    font-size: 13px;
    font-weight: 400;
    font-family: "Stratos LC";
    outline: none;
    cursor: pointer;
}

.button-upload-files.fix {
    margin-left: 40px;
}

.footer {
    bottom: 0;
    width: 100%;
    height: 78px;
    /*margin-top: 2%;*/
    line-height: 60px;
    background-color: #0028DC;
    padding: 0.5% 0;
    position: fixed;
    z-index: 999;
}

.footer-buttons {
    padding: 1% 0;
    float: right;
}

.footer-button {
    cursor: pointer;
    height: 70%;
    border: none;
    width: 147px;
    font-weight: 500;
    font-family: "Stratos LC";
}

.white {
    margin-left: 70%;
    background-color: white;
}

.green {
    background-color: #00F082;
    margin-left: 0.5%;
}

.add-file-table {
    margin-bottom: 40px;
}

.block-search-article-input {
    width: 100%;
    height: 40px;
}

.block-search-article-input input {
    width: 100%;
    color: #898989;
    font-family: "Stratos LC";
    font-size: 15px;
    line-height: 19px;
    font-weight: 300;
    margin: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #000000;
    padding: 5px 0;
    outline: none;
}

.block-search-input {
    width: 100%;
    height: 40px;
}

.block-search-input p {
    color: #898989;
    font-family: "Stratos LC";
    font-size: 11px;
    line-height: 13px;
    margin: 2px 0;
    font-weight: 300;
}

.block-search-input input {
    width: 100%;
    color: #000000;
    font-size: 20px;
    line-height: normal;
    font-weight: 300;
    margin: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid #00F082;
    padding: 10px 0 0 0;
    outline: none;
}

.block-search-input input::placeholder {
    color: #000000;
}

.new-font-1 {
    font-family: "Stratos-Light-Web";
    font-size: 48px;
}

.new-font-2 {
    font-family: "Stratos-Light Italic-Web";
    font-size: 48px;
}

.new-font-3 {
    font-family: "Stratos-Regular-Web";
    font-size: 48px;
}

.new-font-4 {
    font-family: "Stratos-Medium-Web";
    font-size: 48px;
}

.new-font-5 {
    font-family: "Stratos-Bold-Web";
    font-size: 48px;
}

.new-font-6 {
    font-family: "Stratos-Black-Web";
    font-size: 48px;
}

.new-font-1 span {
    font-family: "Stratos LC";
    font-size: 48px;
    font-weight: 300;
}

.new-font-2 span {
    font-family: "Stratos LC";
    font-size: 48px;
    font-weight: 300;
}

.new-font-3 span {
    font-family: "Stratos LC";
    font-size: 48px;
    font-weight: 400;
}

.new-font-4 span {
    font-family: "Stratos LC";
    font-size: 48px;
    font-weight: 500;    letter-spacing: -1.5px;

}

.new-font-5 span {
    font-family: "Stratos LC";
    font-size: 48px;
    font-weight: 500;    letter-spacing: -1.5px;

}

.new-font-6 span {
    font-family: "Stratos LC";
    font-size: 48px;
    font-weight: 700;
}

.Select-control {
    border-radius: 0;
    border-color: #000;
    font-family: "Stratos LC";
    font-weight: 300;
}

.Select.is-focused:not(.is-open) > .Select-control {
    border-color: #00F082;
    box-shadow: none;
}

.Select.is-open > .Select-control {
    border-color: #00F082;
}

.Select-menu-outer {
    border-color: #00F082;
}

.Select.is-open .Select-arrow, .Select .Select-arrow-zone:hover > .Select-arrow {
    color: #000000;
}

.Select-arrow {
    border-color: #000 transparent transparent;

}

.Select.is-open .Select-arrow, .Select .Select-arrow-zone:hover > .Select-arrow {
    border-top-color: #000;
}

.Select.is-open > .Select-control .Select-arrow {
    border-color: transparent transparent #000;
}

.Select-arrow {
    border-width: 5px 5px 4.5px;
}

.Select-clear {
    width: 24px;
    height: 24px;
    border: 1px solid;
    line-height: 22px;
    border-radius: 50%;
    color: #000000;
    display: none;
}

.Select-clear:hover {
    color: #FA4600;
}

.Select--multi .Select-value {
    color: #ffffff;
    background: #0000D3;
    font-family: "Stratos LC";
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    margin-top: 7px;
    border: 0;
    border-radius: 0;
}

.Select--multi .Select-value-icon {
    font-size: 22px;
    padding: 0 3px;
    vertical-align: sub;
    line-height: 19px;
    border-right: 1px solid #fff;
}

.Select--multi .Select-value-icon:hover {
    background: #FA4600;
    color: #FFFFFF;
}

.Select--multi .Select-value-label {
    vertical-align: unset;
}
.hp-block-inputs .row
.Select-menu-outer {
    border-radius: 0;
    font-family: "Stratos LC";
    font-weight: 300;
}

.shell-margin-top-left-right {
    margin-top: 40px;
    margin-right: 40px;
    margin-left: 40px;
}

.shell-margin-left-right {
    margin-right: 40px;
    margin-left: 40px;
}
.margin-0{
    margin: 0;
}
.margin-top-0{
    margin-top: 0;
}
.shell-margin-bottom-10 {
    margin-bottom: 10px!important;
}

.title-smell-new {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
}
.padding-top-35{
    padding-top: 35px;
}
.padding-top-50{
    padding-top: 50px;
}
.padding-top-70{
    padding-top: 70px;
}

.margin-top-50{
    margin-top: 50px;
}
.shell-margin-top-20 {
    margin-top: 20px;
}
.shell-margin-top-30{
    margin-top: 30px;
}


.shell-margit-full {
    margin: 40px;
}

.title-supper-big-new {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 30px;
    font-weight: 500;    letter-spacing: -1.5px;

    line-height: 23px;
    margin: 0;
}

.shell-inline {
    display: inline-block;
    vertical-align: top;
}

.shell-right-inline {
    display: inline-block;
    float: right;
    vertical-align: top;
}

.shell-margin-top-8 {
    margin: 8px 0 0 0;
}

.shell-none-padding {
    padding: 0 !important;
}
.shell-margin-bottom-5{
    margin-bottom: 5px!important;
}
.shell-margin-20{
    margin: 20px;
}

.shell-margin-top-20-left-right-40 {
    margin: 20px 40px 0 40px;
}

.description-standard {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 15px;
    line-height: 19px;
    margin: 10px 0 0 0;
    font-weight: 400;
}

.list-dot-standard li {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 15px;
    line-height: 19px;
    margin: 0;
    font-weight: 400;
}

.shell-margin-top-bottom-20 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.block-line-file {
    display: block;
}

.block-line-file p {
    display: inline-block;
    color: #000000;
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    margin: 0;
}

.block-line-file a {
    display: inline-block;
    color: #F52E07;
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    margin: 0 0 0 10px;
}
.shell-margin-top-0{
    margin-top: 0;
}
.shell-margin-bottom-20 {
    margin-bottom: 20px;
}
.shell-margin-bottom-30{
    margin-bottom: 30px;
}
.shell-margin-top-70{
    margin-top: 70px;
}
.shell-margin-top-60{
    margin-top: 60px;
}
.shell-margin-bottom-25{
    margin-bottom: 25px;
}
.shell-margin-bottom-40{
    margin-bottom: 40px;
}
.shell-margin-bottom-50{
    margin-bottom: 50px;
}
.shell-margin-bottom-60{
    margin-bottom: 60px;
}
.shell-margin-bottom-70{
    margin-bottom: 70px;
}
.shell-margin-bottom-80{
    margin-bottom: 80px;
}

.shell-margin-bottom-15 {
    margin-bottom: 15px;
}
.shell-margin-bottom-150{
    margin-bottom: 150px;
}
.shell-margin-left-20 {
    margin-left: 20px;
}

.gray-title-save {
    color: #898989;
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 30px;
    margin: 0;
    font-weight: 400;
    opacity: .8;
    user-select: none;
}

.block-form-add-road-map-task {
}

.block-title-select-date-form-add-road-map-task {
}

.block-title-form-add-road-map-task {
    display: table-cell;
    vertical-align: top;
    padding: 0 10px 0 0;
    width: 100%;
}

.button-select-date-form-add-road-map-task {
    display: table-cell;
    vertical-align: top;
    width: 130px;
}

.block-description-form-add-road-map-task {
    margin: 10px 0;
}

.block-button-form-add-road-map-task {
    margin: 0 0 20px 0;
    overflow: hidden;
}

.shell-button-cancel-form-add-road-map-task {
    display: inline-block;
    float: right;
    vertical-align: top;
    margin: 7px 10px;
}

.button-cancel-form-add-road-map-task {
    color: #0000D3;
    font-family: "Stratos LC";
    font-size: 13px;
    line-height: 16px;
    text-align: center;
}

.shell-button-save-form-add-road-map-task {
    display: inline-block;
    float: right;
    vertical-align: top;
}

.very-big-title {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 50px;
    font-weight: 500;    letter-spacing: -1.5px;

    margin: 0;
}

.very-small-title {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 13px;
    margin: 0;
}

.small-title {
    color: #000000;
    font-family: "Stratos LC";
    font-size: 13px;
    font-weight: 500;    letter-spacing: -1.5px;

    margin: 5px 0 0 0;
}
.block-new-menu-item-list{
    position: fixed;
     border-left: 5px solid #0000D3;
 }
.block-new-menu-item-list-2{
    border-left: 5px solid #0000D3;
}

.block-new-menu-item-list-person{
float: right; position: relative;

    background: #FFFFFF;
    padding: 15px 0;
}
.line-person-menu{
    position: absolute;
    width: 5px;
    height: 60px;
    top: 22px;
    left: 0;
    background: #0000D3;
}
.block-tool-tip-ico-mene{
    position: relative;
}
.block-tool-tip-ico{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}
.block-opacity{
    opacity: .5;
}
.shell-margit-top-bottom-20-left-right-40{
    margin-bottom: 20px;
    margin-left: 40px;
    margin-top: 20px;
    margin-right: 40px;
}



/*input[type=range] {*/
    /*-webkit-appearance: none;*/
    /*background-color: transparent;*/
    /*vertical-align: top;*/
    /*margin: 2px 0;*/
    /*width: 200px;*/
/*}*/
/*input[type=range]:hover::-webkit-slider-thumb:before,*/
/*input[type=range]:hover::-webkit-slider-thumb:after {*/
    /*opacity: 1;*/
    /*transition: opacity 0.2s ease;*/
/*}*/
/*input[type=range]:focus {*/
    /*outline: none;*/
/*}*/
/*input[type=range]::-webkit-slider-runnable-track {*/
    /*width: 100%;*/
    /*height: 4px;*/
    /*border: 1px solid #9B9B9B;*/
    /*background-color: #9B9B9B;*/
    /*border-radius: 3px;*/
    /*margin: 0;*/
    /*box-sizing: border-box;*/
    /*-webkit-transform: translate(0, 6px);*/
    /*-ms-transform: translate(0, 6px);*/
    /*transform: translate(0, 6px);*/
/*}*/
/*input[type=range]::-moz-range-track {*/
    /*width: 100%;*/
    /*height: 4px;*/
    /*border: 1px solid #383838;*/
    /*background-color: #181818;*/
    /*border-radius: 2px;*/
    /*margin: 0;*/
    /*box-sizing: border-box;*/
/*}*/
/*input[type=range]::-moz-range-track:hover::-moz-range-thumb:before,*/
/*input[type=range]::-moz-range-track:hover::-moz-range-thumb:after {*/
    /*opacity: 1;*/
    /*transition: opacity 0.2s ease;*/
/*}*/
/*input[type=range]::-ms-fill-lower,*/
/*input[type=range]::-ms-fill-upper {*/
    /*width: 100%;*/
    /*height: 4px;*/
    /*border: 1px solid #383838;*/
    /*background-color: #181818;*/
    /*border-radius: 2px;*/
    /*margin: 0;*/
    /*box-sizing: border-box;*/
/*}*/
/*input[type="range"]::-ms-track {*/
    /*-webkit-transform: translate(0, 6px);*/
    /*-ms-transform: translate(0, 6px);*/
    /*transform: translate(0, 6px);*/
    /*background: transparent;*/
    /*color: transparent;*/
    /*height: 16px;*/
    /*border: 6px solid transparent;*/
/*}*/
/*input[type="range"]::-ms-track:hover::-ms-thumb:before,*/
/*input[type="range"]::-ms-track:hover::-ms-thumb:after {*/
    /*opacity: 1;*/
    /*transition: opacity 0.2s ease;*/
/*}*/
/*input[type='range']::-webkit-slider-thumb {*/
    /*-webkit-appearance: none;*/
    /*width: 24px;*/
    /*height: 24px;*/
    /*border: 6px solid #2AA2E6;*/
    /*background: #2AA2E6;*/
    /*border-radius: 50%;*/
    /*box-sizing: border-box;*/
    /*position: relative;*/
    /*-webkit-transform: translate(0, -11px);*/
    /*-ms-transform: translate(0, -11px);*/
    /*transform: translate(0, -11px);*/
/*}*/
/*input[type='range']::-webkit-slider-thumb:before {*/
    /*content: "1337";*/
    /*display: block;*/
    /*position: absolute;*/
    /*bottom: 16px;*/
    /*left: 2px;*/
    /*-webkit-transform: translate(-50%, 0);*/
    /*-ms-transform: translate(-50%, 0);*/
    /*transform: translate(-50%, 0);*/
    /*border-radius: 16px;*/
    /*padding: 4px 8px 2px 8px;*/
    /*box-sizing: border-box;*/
    /*color: #181818;*/
    /*background: #383838;*/
    /*font-size: 12pt;*/
    /*text-align: center;*/
    /*opacity: 0;*/
    /*transition: opacity 0.2s ease 1s;*/
    /*z-index: 1;*/
/*}*/
/*input[type='range']::-webkit-slider-thumb:after {*/
    /*content: "";*/
    /*display: block;*/
    /*position: absolute;*/
    /*bottom: 24px;*/
    /*left: 2px;*/
    /*-webkit-transform: translate(-50%, 0);*/
    /*-ms-transform: translate(-50%, 0);*/
    /*transform: translate(-50%, 0);*/
    /*width: 8px;*/
    /*height: 8px;*/
    /*box-sizing: border-box;*/
    /*background-color: #282828;*/
    /*border: 2px solid #383838;*/
    /*border-radius: 50%;*/
    /*opacity: 0;*/
    /*transition: opacity 0.2s ease 1s;*/
    /*z-index: 2;*/
/*}*/
/*input[type=range]::-moz-range-thumb {*/
    /*width: 24px;*/
    /*height: 24px;*/
    /*border: 6px solid #0028DC;*/
    /*background: #0028DC;*/
    /*border-radius: 50%;*/
    /*box-sizing: border-box;*/
    /*position: relative;*/
/*}*/
/*input[type=range]::-moz-range-thumb:before {*/
    /*content: "1337";*/
    /*display: block;*/
    /*position: absolute;*/
    /*bottom: 16px;*/
    /*left: 2px;*/
    /*-webkit-transform: translate(-50%, 0);*/
    /*-ms-transform: translate(-50%, 0);*/
    /*transform: translate(-50%, 0);*/
    /*border-radius: 16px;*/
    /*padding: 4px 8px 2px 8px;*/
    /*box-sizing: border-box;*/
    /*color: #181818;*/
    /*background: #383838;*/
    /*font-size: 12pt;*/
    /*text-align: center;*/
    /*opacity: 0;*/
    /*transition: opacity 0.2s ease 1s;*/
    /*z-index: 1;*/
/*}*/
/*input[type=range]::-moz-range-thumb:after {*/
    /*content: "";*/
    /*display: block;*/
    /*position: absolute;*/
    /*bottom: 12px;*/
    /*left: 2px;*/
    /*-webkit-transform: translate(-50%, 0);*/
    /*-ms-transform: translate(-50%, 0);*/
    /*transform: translate(-50%, 0);*/
    /*width: 8px;*/
    /*height: 8px;*/
    /*box-sizing: border-box;*/
    /*background-color: #282828;*/
    /*border: 2px solid #383838;*/
    /*border-radius: 50%;*/
    /*opacity: 0;*/
    /*transition: opacity 0.2s ease 1s;*/
    /*z-index: 2;*/
/*}*/
/*input[type="range"]::-ms-thumb {*/
    /*width: 24px;*/
    /*height: 24px;*/
    /*border: 6px solid #0028DC;*/
    /*background: #0028DC;*/
    /*border-radius: 50%;*/
    /*box-sizing: border-box;*/
    /*position: relative;*/
    /*-webkit-transform: translate(0, -9px);*/
    /*-ms-transform: translate(0, -9px);*/
    /*transform: translate(0, -9px);*/
/*}*/
/*input[type="range"]::-ms-thumb:before {*/
    /*content: "1337";*/
    /*display: block;*/
    /*position: absolute;*/
    /*bottom: 16px;*/
    /*left: 2px;*/
    /*-webkit-transform: translate(-50%, 0);*/
    /*-ms-transform: translate(-50%, 0);*/
    /*transform: translate(-50%, 0);*/
    /*border-radius: 16px;*/
    /*padding: 4px 8px 2px 8px;*/
    /*box-sizing: border-box;*/
    /*color: #181818;*/
    /*background: #383838;*/
    /*font-size: 12pt;*/
    /*text-align: center;*/
    /*opacity: 0;*/
    /*transition: opacity 0.2s ease 1s;*/
    /*z-index: 1;*/
/*}*/
/*input[type="range"]::-ms-thumb:after {*/
    /*content: "";*/
    /*display: block;*/
    /*position: absolute;*/
    /*bottom: 12px;*/
    /*left: 2px;*/
    /*-webkit-transform: translate(-50%, 0);*/
    /*-ms-transform: translate(-50%, 0);*/
    /*transform: translate(-50%, 0);*/
    /*width: 8px;*/
    /*height: 8px;*/
    /*box-sizing: border-box;*/
    /*background-color: #282828;*/
    /*border: 2px solid #383838;*/
    /*border-radius: 50%;*/
    /*opacity: 0;*/
    /*transition: opacity 0.2s ease 1s;*/
    /*z-index: 2;*/
/*}*/
/*input[type="range"]::-ms-tooltip {*/
    /*display: none;*/
/*}*/





.block-shell-share{
    padding: 50px;
    background: #000000;
}
.block-share-popup{
    width: 155px;
    position: relative;
}
.block-title-share-popup{
    height: 40px;
    width: 100%;
    overflow: hidden;
    background: #ffffff;
}
.block-title-share-popup p{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 16px;	line-height: 40px; text-align: center;font-weight: 400; margin: 0;
}
.block-grid-share-popup{
    width: 100%;
    overflow: hidden;
    background: #ffffff;
}
.block-item-share-popup{
    height: 32px;
    width: 100%;
    cursor: pointer;
    position: relative;
z-index: 1000;
}

.block-left-item-share-popup:before{
    content: '';
    width: 155px;
    height: 32px;
    position: absolute;
    left: -155px;
    background: #D8D8D8;
    top: 0;
    transition: .3s;
    z-index: -1;
}
.block-item-share-popup:hover .block-left-item-share-popup:before{
    left: 0;
    z-index: -1;
}
.block-left-item-share-popup{
    width: 32px;
    height: 32px;
    float: left;
    z-index: 30;
}
.block-left-item-share-popup i{
    line-height: 32px;
    width: 32px;
    text-align: center;
    color: #4A4A4A;
    z-index: 30;
}
.block-right-item-share-popup{
    width: calc(100% - 32px);
    float: right;
    z-index: 30;
}
.block-right-item-share-popup p{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	line-height: 32px; margin: 0; font-weight: 300;
    z-index: 30;
}


