.block-button-categorie{
    width: 100%;
    height: 51px;
    border: 1px solid rgba(151,151,151,0.2);
    border-left: 0;
    border-radius: 6px;
    background-color: #FFFFFF;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,0.07);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .3s;
}

.block-button-categorie span.left{
    position: absolute;
    display: block;
    width: 4px;
    height: 51px;
    background-color: rgba(151,151,151,0.2);
    top: 0;
    transition: .3s;
    left: 0;
}
.block-button-categorie p.title{
    color: #000000;	font-family: "Stratos LC";	font-size: 18px;	line-height: 50px;
    margin: 0; padding: 0 0 0 24px;	opacity: 0.3;    transition: .3s; font-weight: 400;
}
.block-button-categorie:hover{
    border: 1px solid #D0D0D0;
    border-left: 0;
}
.block-button-categorie:hover span.left{
    background-color: #D0D0D0;
}
.block-button-categorie:hover p.title{
    opacity: 0.5;
}

.block-button-categorie.active{}
.block-button-categorie.active span.left{
    background-color: #0ECFAA;
}
.block-button-categorie.active:hover span.left{
    background-color: #0ECFAA;
}
.block-button-categorie.active p.title{
    color: rgba(8,8,8,0.8);
    opacity: 1;
    font-weight: 500;
}
.block-button-categorie.active:hover p.title{
    color: rgba(8,8,8,0.8);
    opacity: 1;
}
.block-button-categorie span.counter{
    position: absolute;
    top: 12px;
    right: 17px;
    display: block;
    background: #ECECEC;
    height: 24px;
    min-width: 24px;
    border-radius: 12px;
    padding: 0 8px 0 7px;
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 16px;	font-weight: 400;	line-height: 24px;	text-align: center;
    margin: 0;
    transition: .3s;
}
.block-button-categorie:hover span.counter{
    background: #D0D0D0;
}
.block-button-categorie.active span.counter{
    background-color: #0ECFAA;
}
.block-button-categorie.active:hover span.counter{
    background-color: #0ECFAA;
}