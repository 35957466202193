.block-new-input {
    width: 100%;
    height: 66px;
    display: table;
    position: relative;
}

.block-new-input.icon {
}

.block-new-input.error {
}

.block-new-input input {
    width: 100%;
    height: 48px;
    background-color: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #979797;
    opacity: 0.5 !important;
    outline: none;
    transition: .3s;
    color: #000000;
    font-size: 14px;
    line-height: normal;
    font-weight: 300;
    margin: 0;
    padding: 0 16px;
    z-index: 1;
}
.block-new-input-black input {
    opacity: 1 !important;
}
.block-new-input input::placeholder{
    color: #000000!important;
}
.block-new-input input:focus {
    opacity: 1 !important;
}
.block-new-input.active input{
    opacity: 1 !important;
}

.block-new-input.icon input {
    padding: 0 16px 0 45px;
}

.block-new-input.icon i {
    width: 20px;
    height: 20px;
    font-size: 20px;
    margin: -2px 0 0 -4px;
    position: absolute;
    top: 17px;
    left: 16px;
    transition: .3s;
    z-index: 2;
    opacity: 0.5;
}
.block-new-input.icon i img {
    position: relative;
    top: -2px;
}

.block-new-input.icon:focus-within i{
    opacity: 1 !important;
}
.block-new-input.icon i.active{
    opacity: 1 !important;
}
.block-new-input.error input {
    border: 1px solid #E43A70;
    opacity: 1 !important;
}
.block-new-input.icon.error i{
    opacity: 1 !important;
}
.block-new-input.error p.title-error {
    color: #E43A70;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    margin: 4px 0 0 0;
    padding: 0 0 0 16px;
text-align: left!important;
}
.block-new-input .right-icon {
    font-size: 14px;
    font-weight: 400;
    position: absolute;
    right: 10px;
    top: 3px;
}