.forgot-page {
  font-family: "Stratos LC";
  background-color: blue;
  height: 100vh;
}
.forgot-page .text-top{
  color: #FFFFFF;
  font-size: 29px;
  line-height: 40px;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0,0,0,0.5);
  width: 50%;
  margin: 0 auto;
  padding-top: 10%;
}
.forgot-page .forgot-block{
  width: 294px;
  margin: 50px auto;
}
.forgot-page .test-message{
  color: #ffffff;
  font-size: 16px;
}
.forgot-page .test-message-err{
  color: #eb1505;
  font-size: 16px;
}
.login-form__field.fix-margin {
  margin-bottom: 5px;
}
.under-input.fix {
  color: #ffffff;
}
