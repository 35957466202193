@import "../CONST";

.team-card-block {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 16px;
  transition: .3s;
  border-radius: 6px;
  background-color: #FFFFFF;
  cursor: pointer;
  box-shadow: -4px 6px 14px 0 rgba(0,0,0,0.03);
  &:hover {
    box-shadow: -7px 12px 24px 0 rgba(0,0,0,0.1)
  }
  .avatar {
    width: 100%;
    height: 168px;
    border-radius: 4px;
    background-position: 50%!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    font-size: 100px;
  }
  .name-title-wrapper {
    margin: 12px 0 0 0;
    &.name-title {
      font-size: 16px;
      line-height: 22px;
      color: rgba(8, 8, 8, 0.8);
      overflow: hidden;
      text-overflow: ellipsis;
      &.email-title {
        font-size: 14px;
      }
    }
  }
  .position-title-wrapper {
    margin: 3px 0 0 0;
    &.position-title {
      @extend %block-descr-14;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  .buttons-line {
    margin-top: 5px;
    .delete-title {
      @extend %block-descr-14;
      color: rgba(0, 0, 0, 0.8);
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
      &.red-color {
        color: #FF4133;
      }
    }
  }
  .data-descr-wrapper {
    margin: 5px 0 0 0;
    &.data-descr {
      @extend %grey-title;
    }
  }

}