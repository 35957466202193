.edit-profile-list {
	/*background-color: #FAFAFA;*/
	padding: 30px 0 80px 0;
	min-height: 100vh;
	/*margin-left: 85px;*/
}

.edit-profile-main-title .main-title {
	float: left;
}
.edit-profile-main-title .main-title > div {
	font-size: 28px;
	line-height: 30px;
	font-weight: 500;
	color: #2F3849;
}
.edit-profile-main-title .last-edited {
	position: absolute;
	right: 0;
	bottom: 0;
}
.edit-profile-main-title .last-edited > div {
	font-size: 10px;
	line-height: 13px;
	text-align: right;
	color: #979797;
}
.edit-profile-table {
	margin-top: 50px;
	border-top: 1px solid #D8D8D8;
}
.edit-profile-table .edit-profile-table-line {
	position: relative;
	width: 100%;
	height: 41px;
	border-bottom: 1px solid #D8D8D8;
	cursor: pointer;
}
.edit-profile-table .edit-profile-table-line:hover .table-edit-button {
	opacity: 1;
}
.edit-profile-table .edit-profile-table-line .table-edit-button {
	opacity: 0;
	position: absolute;
	right: 0;
	top: 7px;
	width: auto;
	height: 25px;
	background-color: #2F3849;
	box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.07);
	border-radius: 4px;
	transition: .2s;
}
.edit-profile-table .edit-profile-table-line .table-edit-button > span {
	font-family: Stratos LC;
	font-size: 11px;
	line-height: 25px;
	padding: 0 20px;
	text-align: center;
	color: #FFFFFF;
}

.edit-profile-table .edit-profile-table-line > span{
	font-family: Stratos LC;
	font-size: 16px;
	line-height: 40px;
	font-weight: 500;
	letter-spacing: -0.505952px;
	color: #000000;
}