@import '../CONST';

.notification-main-wrapper {
  .notif-title {
    @extend %input-label-22;
    font-weight: 500;
    line-height: 30px;
  }
  .notification-col-name {
    @extend %grey-title;
    margin-top: 7px;
  }
  .notification-list-main-row {
    padding-bottom: 16px;
    position: relative;
    justify-content: space-between;
    .column-container {
      max-width: 174px;
    }
  }
  .notification-list-main-row:after,
  .notification-line:after {
    position: absolute;
    left: 10px;
    bottom: 0;
    content: '';
    border-bottom: 1px solid rgba(0,0,0,0.1);
    width: calc(100% - 20px);
  }
  .notification-list-main-row.without-border-line:after,
  .notification-line.without-border-line:after  {
    content: none;
  }

  .notification-line {
    justify-content: space-between;
    position: relative;

    .descr-line {
      max-width: 328px;
      min-height: 70px;
      padding-top: 7px;
      padding-bottom: 11px;

      .notification-title {
        @extend %input-label;
        font-weight: 500;
        margin: 0;
      }
      .notification-descr {
        @extend %grey-title;
        color: rgba(0,0,0,0.5);
        margin-top: -1px;
      }
    }
    .swipes-countainer {
      max-width: 171px;
      .swipes-line {
        height: 100%;
        align-items: center;
        .swipe-block {
          display: block;
          height: 18px;
        }
      }
      .swipes-line.hide-swipes {
        display: none;
      }
    }
    .mobile-wrapper {
      display: none;
    }
    .arrow-wrapper {
      display: none;
      .far.fa-chevron-down {
        transform: rotate(-90deg);
        float: right;
      }
    }
  }
  .notification-line.short-line {
    .descr-line {
      min-height: 40px;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
  .notification-line.short-line:after {
    content: none;
  }
}

@media all and (max-width: 500px) {
  .notification-main-wrapper {
    .notification-list-main-row {
      border-bottom: none;
    }
    .notification-col-name {
      display: none;
    }
    .notification-line {
      align-items: center;
      .arrow-wrapper {
        display: block;
        max-width: 20px;
      }
      .swipes-countainer {
        display: none;
      }
      .mobile-wrapper {
        display: block;
        position: fixed;
        width: 100%;
        max-width: unset;
        height: 100vh;
        background: #FDFDFD;
        left: 0;
        top: 0;
        z-index: 999;
        .mobile-container {
          padding: 12px 16px 0 16px;
          .title-line-mobile {
            align-items: center;
            padding-bottom: 20px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .arrow-back-wrapper {
              max-width: 32px;
              height: 32px;
              background: url("../../../images/arrow-left.svg");
              background-repeat: no-repeat;
              background-position: 50%;
            }
            .mobile-title {
              font-size: 18px;
              line-height: 18px;
              color: rgba(0, 0, 0, 0.8);
              font-weight: 500;
              padding-left: 11px;
            }
          }
          .mobile-swipe-line {
            align-items: center;
            justify-content: space-between;
            height: 55px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            .swipe-block {
            }
            .title {
              font-size: 16px;
              line-height: 15px;
              color: rgba(0, 0, 0, 0.4);
            }
          }
        }
      }
    }

  }
}