.block-current-statistics {
    border-bottom: 1px solid rgba(0,0,0,0.5);
    padding-left: calc(50% - 480px);
}
.block-current-statistics .role-statistic-wrapper {
    max-width: 240px;
    min-width: 200px;
    margin-top: 50px;
    border-left: 1px solid rgba(0,0,0,0.5);
    padding: 0 20px 45px 20px;
}
.block-current-statistics .number-text {
    font-size: 60px;
    line-height: 76px;
    color: rgba(0,0,0,0.8);
}
.block-current-statistics .number-block {
    margin-top: -12px;
}
.block-current-statistics .descr-text {
    font-size: 18px;
    line-height: 38px;
    color: rgba(0,0,0,0.8);
}

.block-current-statistics .role-name-text {
    font-size: 30px;
    line-height: 35px;
}
.block-current-statistics .role-name-text.startup {
    color: #2f3849;
}
.block-current-statistics .role-name-text.investor {
    color: #2CA6EB;
}
.block-current-statistics .role-name-text.expert {
    color: #0ECFAA;
}
.block-current-statistics .main-text {
    font-size: 32px;
    line-height: 49px;
}
.block-expert-conducting-bg {
    background: url("./img/NewExpertBG.png");
    background-repeat: no-repeat;
    background-size: auto;
    height: 900px;
    width: 100%;
}
.expert-conducting-bg-wrapper {
    overflow-y: hidden;
}
.expert-conducting-block {
    max-width: 400px;
    padding-right: 20px;
}
.expert-conducting-main-title {
    font-size: 32px;
    line-height: 40px;
}
.expert-conducting-descr {
    font-size: 18px;
    line-height: 23px;
}
.expert-conducting-block.expert-conducting-main-title {
    margin-top: 390px;
}

/*What are the advantages*/
.block-advantages-table,
.block-algorithm-table,
.block-startups-wrapper {
    margin: 0 auto;
    max-width: 940px;
    width: calc(100% - 20px);
}
.block-advantage-point {
    margin: 40px 0;
}
.block-advantage-point.old-point {
    max-width: 300px;
    min-width: 300px;
    margin: 40px 0;
}
.block-advantage-point .icon {
    background-size: contain!important;
    background-repeat: no-repeat!important;
}
.block-advantage-point .icon.icon-investor {
    background: url("./img/AdvantageIcons/Investors.svg");
    width: 53px;
    height: 66px;
    margin-bottom: 17px;
    margin-top: 1px;
}
.block-advantage-point .icon.icon-expert {
    background: url("./img/AdvantageIcons/Experts.svg");
    width: 56px;
    height: 67px;
    margin-bottom: 17px;
}
.block-advantage-point .icon.icon-b-plan {
    background: url("./img/AdvantageIcons/BusinessPlan.svg");
    width: 55px;
    height: 64px;
    margin-bottom: 18px;
    margin-top: 2px;
}
.block-advantage-point .icon.icon-feedback {
    background: url("./img/AdvantageIcons/Feedback.svg");
    width: 54px;
    height: 45px;
    margin-bottom: 13px;
    margin-top: 26px;
}
.block-advantage-point .icon.icon-analisys {
    background: url("./img/AdvantageIcons/Analysis.svg");
    width: 59px;
    height: 42px;
    margin-bottom: 16px;
    margin-top: 26px;
}
.block-advantage-point .icon.icon-universality {
    background: url("./img/AdvantageIcons/Universality.svg");
    width: 73px;
    height: 57px;
    margin-bottom: 9px;
    margin-top: 18px;
}
.block-advantage-point .icon.icon-resourses {
    background: url("./img/AdvantageIcons/Resourses.svg");
    width: 50px;
    height: 55px;
    margin-bottom: 17px;
    margin-top: 12px;
}
.block-advantage-point .icon.icon-startup {
    background: url("./img/AdvantageIcons/Startups.svg");
    width: 52px;
    height: 75px;
    margin-bottom: 8px;
    margin-top: 1px;
}
.block-advantage-point .icon.icon-uniform {
    background: url("./img/AdvantageIcons/Uniform.svg");
    width: 46px;
    height: 56px;
    margin-bottom: 9px;
    margin-top: 19px;
}
.text-advantage-name {
    font-size: 20px;
    line-height: 32px;
}
.text-advantage-descr {
    font-size: 18px;
    line-height: 23px;
}
.block-advantages-main-title {
    font-size: 32px;
    line-height: 50px;
    z-index: 1;
}

/*How it works?*/
.block-advantage-point .number-circle {
    width: 60px;
    height: 60px;
    background-color: #2f3849;
    border-radius: 50%;
    margin: 0 auto;
}
.block-advantage-point .number-circle .text-number-circle {
    font-size: 28px;
    line-height: 60px;
    color: rgba(255,255,255,0.98);
}
.block-advantage-point .text-algorithm-name {
    font-size: 18px;
    line-height: 1.5;
    padding: 16px 0;
}
.block-advantage-point .text-algorithm-descr {
    font-size: 14px;
    line-height: 20px;
}
.algorithm-button-wrapper {
    margin: 0 auto;
}
.algorithm-button-wrapper .block-button-3-0.button-3-0-green {
    box-shadow: 0px 8px 19px rgba(47,56,73,0.15);
    height: 51px;
    min-width: 272px;
}
.algorithm-button-wrapper .block-button-3-0.button-3-0-green p.block-button-3-0-title {
    font-weight: 500;
    line-height: 51px;
}
.block-algorithm-table,
.block-startups-wrapper {
    position: relative;
}
/*.block-algorithm-table:before,*/
/*.block-algorithm-table:after {*/
/*    content:'';*/
/*    position: absolute;*/
/*    width: 220px;*/
/*    height: 4px;*/
/*    border-top: 4px dotted #0ECFAA;*/
/*}*/

span.line-dot{
    position: absolute;
    width: 140px;
    height: 4px;
    border-top: 4px dotted #0ECFAA;
    top: 70px;
}
span.line-dot.line-1{
    left: 160px;
}
span.line-dot.line-2{
    left: 400px;
}
span.line-dot.line-3{
    left: 640px;
}
span.line-dot.line-4{
    left: 200px;
    width: 220px;
}
span.line-dot.line-5{
    right: 200px;
    width: 220px;
}
.block-algorithm-table:before {
    left: 210px;
    top: 70px;
}
.block-algorithm-table:after {
    right: 210px;
    top: 70px;
}

/*HEADER*/
.block-header-landing-startup {
    /*height: 540px;*/
}
.block-header-landing-startup .block-startup-bg {
    background: url("./img/StartupBG.jpg");
    background-repeat: no-repeat;
    background-size: 100% 540px;
    width: 100%;
    /*height: 540px;*/
    padding-top: 60px;
}
.block-header-landing-startup .block-investor-bg {
    background: url("./img/InvestorBG.jpg");
    background-repeat: no-repeat;
    background-size: 100% 540px;
    width: 100%;
    height: 540px;
    padding-top: 145px;
}
.header-button-wrapper .block-button-3-0.button-3-0-blue {
    height: 51px;
    width: 206px;
    margin: 0 auto;
    box-shadow: 0px 8px 19px rgba(47,56,73,0.15);
}
.header-button-wrapper .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
    line-height: 51px;
    font-weight: 500;
}
.header-landing-main-text {
    font-size: 36px;
    line-height: 45px;
    color: #2CA6EB;
}
.header-landing-descr-text {
    font-size: 20px;
    line-height: 25px;
}
/*PayBanner*/
.block-landing-pay-banner-wrapper {
    max-width: 780px;
    margin: 0 auto;
    background-color: #2f3849;
    box-shadow: -4px 6px 14px rgba(0,0,0,0.03);
    border-radius: 16px;
    padding: 57px 81px 45px 80px;
}
.block-landing-pay-banner-wrapper .pay-banner-main-title,
.block-landing-pay-banner-wrapper .pay-banner-cost-text,
.block-landing-pay-banner-wrapper .text-pay-list {
    color: #FFF!important;
}
.block-investor-pay-banner-wrapper .pay-banner-cost-text {
    margin-top: 19px;
    font-size: 40px;
    /*line-height: 50px;*/
}
.block-landing-pay-banner-wrapper .pay-list-point {
    background-color: #FFF;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}
.block-landing-pay-banner-wrapper .text-button-descr {
    color: rgba(255,255,255,0.6);
}
.block-landing-pay-banner-wrapper .block-pay-banner .pay-banner-bg {
    background-image: url("./img/HumanWhite.svg");
}
.block-landing-pay-banner-wrapper .block-pay-banner {
    margin: 0 auto;
}
.swiper-container {
    width: 100%;
    height: 415px;
}
.swiper-slide {
    height: 370px!important;
    /*background: #fff;*/
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}
.block-slider-point {
    max-width: 800px;
    min-width: 800px;
    height: 225px;
    margin: -20px auto 0 auto;
    background-color: #FFF;
    box-shadow: 0px 4px 16px rgba(0,0,0,0.15);
    border-radius: 8px;
    padding: 55px 60px 60px 80px;
    justify-content: space-between;
}
.investor-sliader .block-slider-point {
    height: 200px;
}
.investor-sliader .swiper-slide {
    height: 345px!important;
}
.investor-sliader .swiper-container {
    height: 390px;
}
.swiper-pagination-bullet {
    width: 10px!important;
    height: 10px!important;
    opacity: 1!important;
    background: rgba(196,196,196,0.4)!important;
    margin: 0 9px 3px 9px!important;
}
.swiper-pagination-bullet-active {
    width: 16px!important;
    height: 16px!important;
    background: #c4c4c4!important;
    margin: 0 9px 0 9px!important;
}
.block-slider-point .client-avatar {
    background: url("./img/piere_avatar.jpg");
    max-width: 76px;
    height: 76px;
    border-radius: 50%;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: 50%!important;
}
.block-slider-point .client-name-block {
    padding-left: 23px;
}
.client-name-block .text-client-name {
    font-size: 18px;
    line-height: 23px;
}
.client-name-block-startup .text-client-name {
    display: inline;
}
.client-name-block .text-client-position {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0,0,0,0.6);
}
.client-name-block-startup .text-client-position {
    display: inline;
}
.client-name-block-startup .text-client-position a {
    color: #2CA6EB;
    text-decoration: none;
}
.block-slider-point .text-client-descr {
    font-size: 16px;
    line-height: 21px;
}
.block-slider-point .text-quotes {
    font-size: 90px;
    line-height: 0px;
}
.block-slider-point .quotes-block-left {
    max-width: 32px;
    align-self: center;
    margin-top: -5px;
}
.block-slider-point .quotes-block-right {
    max-width: 32px;
    align-self: flex-end;
}
.block-slider-point .client-descr-block {
    margin: 0 20px 0 12px;
    max-width: 420px;
    min-width: 260px;
}
.block-slider-point-left {
    max-width: 520px;
    min-width: 520px;
}
.block-slider-point-left .text-see-sample-pdf {
    padding: 15px 0 0 45px;
}
.text-see-sample-pdf a{
    color: #0ECFAA;
    font-size: 16px;
    line-height: 20px;
}
.block-slider-point-right {
    max-width: 210px;
    min-width: 210px;
}
.block-slider-point-right .client-logo {
    width: 210px;
    height: 210px;
    background-image: url("./img/ParsersLogo.svg");
    background-position: 50%!important;
    background-size: cover!important;
    background-repeat: no-repeat!important;
}
.block-slider-point-right .client-position-text {
    font-size: 32px;
    line-height: 40px;
    margin: 60px 0 0 20px;
    min-width: 190px;
}
.block-evaluated-projects-list {
    margin: 0 auto;
    max-width: 992px;
}
.block-cart-new-startup-marketplace.evaluated-card .block-ing-cart-new-startup-marketplace {
    top: 51px;
}
.block-cart-new-startup-marketplace.evaluated-card .bg-top-cart-new-startup-marketplace {
    height: 111px;
    will-change: transform;
}
.block-cart-new-startup-marketplace.evaluated-card .block-top-cart-new-startup-marketplace {
    height: 140px;
}
.block-cart-new-startup-marketplace.evaluated-card .block-startup-evaluation-view {
    height: 70px;
    padding: 12px 0 12px 18px;
}
.block-cart-new-startup-marketplace.evaluated-card .review-score-text-block {
    margin-top: 6px;
    padding-left: 20px;
    padding-right: 14px;
    position: relative;
    width: calc(100% - 76px);
}

.block-cart-new-startup-marketplace.evaluated-card .expert-review-score-text {
    font-size: 14px;
    line-height: 18px;
}
.block-cart-new-startup-marketplace.evaluated-card .expert-review-score-views {
    font-size: 12px;
    line-height: 14px;
    color: rgba(0,0,0,0.5);
}
.landing-startup-gradient {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(180deg, #F5F5F5 0%, rgba(255,255,255,0.0001) 54.77%);
}
.block-advantages-table-wrapper {
    position: relative;
}
.block-evaluated-startups {
    margin-top: 120px;
    margin-bottom: 115px;
}

/*Investor Page*/
.pay-banner-columns-wrapper {
    justify-content: space-between;
}
.pay-banner-column {
    max-width: 296px;
    min-width: 296px;
}
.pay-banner-invetor-main-title {
    font-size: 30px;
    line-height: 36px;
    color: #FFF;
}
.block-investor-pay-banner-wrapper {
    margin: 0 auto;
    background-color: #2f3849;
    box-shadow: -4px 6px 14px rgba(0,0,0,0.03);
    border-radius: 16px;
    max-width: 760px;
    padding:51px 74px!important;
}
.block-investor-pay-banner-wrapper .investor-banner-right-col {
    margin-left: 22px;
}
.block-evaluated-startups .block-box-shadow-cart-startup{
    box-shadow: none!important;
}




.margin-top-55 {
    margin-top: 55px;
}
.margin-top-75 {
    margin-top: 75px;
}
.margin-bottom-65 {
    margin-bottom: 65px;
}
.margin-top-145 {
    margin-top: 145px;
}


.block-front-review-score-text{
    position: relative;
    top: 0;
    transition: .3s;
}

.block-back-review-score-text{
    position: absolute;
    top: 0;
    transition: .3s;
    right: -100%;
    width: 100%;
    padding: 0 14px 0 20px;
}
.block-cart-new-startup-marketplace.evaluated-card.slide-up{
    overflow: hidden;
}
.block-cart-new-startup-marketplace.evaluated-card.slide-up:hover{

}
.block-cart-new-startup-marketplace.evaluated-card.slide-up:hover .block-front-review-score-text{
    position: relative;
    top: -60px;
}

.block-cart-new-startup-marketplace.evaluated-card.slide-up:hover .block-back-review-score-text{
    right: 0;

}
p.count-review-score{
    margin: 0;
    font-family: Stratos LC;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: rgba(8, 8, 8, 0.8);

}
p.title-review-score{
    margin: 0;
    font-family: Stratos LC;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: rgba(8, 8, 8, 0.8);
}
.pay-banner-button-call.investor {
    margin-top: 64px;
}
/*.pay-banner-button-call.investor .block-button-3-0.button-3-0-green {*/
/*    height: 48px;*/
/*    width: 100%;*/
/*}*/
/*.pay-banner-button-call.investor .block-button-3-0.button-3-0-green p.block-button-3-0-title{*/
/*    line-height: 48px;*/
/*}*/
.pay-banner-button-call.investor .block-button-3-0.button-3-0-blue {
    height: 51px;
}
.pay-banner-button-call.investor .block-button-3-0.button-3-0-blue p.block-button-3-0-title{
    line-height: 51px;
}

.block-landing-pay-banner-wrapper.block-investor-pay-banner-wrapper .text-see-sample-pdf {
    padding-left: 18px;
    margin-top: -19px;
    z-index: 1;
}
.block-investor-pay-banner-wrapper .pay-banner-cost-text.upon-text {
    color: #0ECFAA!important;
    font-size:26px;
    line-height:33px;
    margin-top:29px;
    padding-left: 1px;
}
.block-investor-pay-banner-wrapper .text-pay-list {
    padding-left: 16px;
    line-height: 150%;
    font-size: 14px;
}
.block-investor-pay-banner-wrapper .investor-banner-right-col .text-pay-list {
    padding-left: 18px;
}
.block-investor-pay-banner-wrapper .pay-list-point {
    margin: 7px 0 0 0;
}


@media screen and (min-width: 1400px) {
    .block-expert-conducting-bg {
        background: url("./img/NewExpertsFullBG2x.png");
        background-size: cover;
        background-position: 30% 0%;
        background-repeat: no-repeat;
    }
    .expert-conducting-block.expert-conducting-main-title {
        margin-top: 430px;
    }
    .block-expert-conducting-bg {
        height: 908px;
    }
}
@media screen and (max-width: 1100px) {
    .block-expert-conducting-bg {
        background-size: auto 675px;
        height: 675px;
    }
    .expert-conducting-block.expert-conducting-main-title {
        margin-top: 310px;
    }
    .expert-conducting-main-title {
        font-size: 29px;
    }
}
@media screen and (max-width: 1000px) {
    .block-advantages-table,
    .block-evaluated-projects-list {
        max-width: 640px;
    }
    .block-algorithm-table:before,
    .block-algorithm-table:after {
        content: none;
    }
    span.line-dot{
        display: none;
    }
    .block-algorithm-table .block-advantage-point:nth-child(3) {
        margin: 40px auto;
    }
    .block-header-landing-startup .block-startup-bg,
    .block-header-landing-startup .block-investor-bg {
        background-size: 1000px 540px;
        background-position: 40% 0%;
    }
}
@media screen and (max-width: 992px) {
    .block-current-statistics {
        border-bottom: none;
        padding: 0 30px;
    }
    .block-current-statistics .role-statistic-wrapper {
        border-bottom: 1px solid rgba(0,0,0,0.5);
    }
    .block-algorithm-table {
        max-width: 620px;
    }

}
@media screen and (max-width: 929px) {
    .swiper-container {
        height: 485px;
    }
    .swiper-slide {
        height: 420px!important;
    }
    .block-slider-point {
        height: 380px;
        min-width: unset;
        padding: 15px 60px 15px 80px;
        margin: 0 30px;
    }
    .block-slider-point-right {
        margin: 0 auto;
    }
    .block-slider-point-right .client-position-text {
        margin: 20px 0 0 30px;
    }
    .investor-sliader .block-slider-point {
        height: 360px;
    }
    .investor-sliader .swiper-slide {
        height: 410px!important;
    }
    .investor-sliader .swiper-container {
        height: 480px;
    }
    .block-slider-point-right .client-logo {
        height: 150px;
        margin: 10px auto 0 auto!important;
    }
}
@media screen and (max-width: 861px) {
    .block-startups-wrapper {
        max-width: 620px;
    }
}

@media screen and (max-width: 825px) {
    .block-expert-conducting-bg {
        background-size: cover;
        background-position: 20% 0%;
    }
    .expert-conducting-main-title {
        font-size: 27px;
    }
}
@media screen and (max-width: 780px) {

}

@media screen and (max-width: 769px) {
    .block-landing-pay-banner-wrapper {
        padding: 50px 10px 85px 10px;
        margin: 0 20px;
    }
    .block-expert-conducting-bg {
        height: 875px;
        background-size: auto 600px;
        background-position: 15% 0%;
    }
    .expert-conducting-block.expert-conducting-main-title {
        margin: 500px auto 0 auto;
    }
    .expert-conducting-main-title {
        font-size: 32px;
    }
    .expert-conducting-descr {
        margin: 45px auto 0 auto;
    }
    .expert-conducting-block {
        padding-right: 0;
    }
    .pay-banner-column {
        margin: 25px auto;
    }
    .block-investor-pay-banner-wrapper {;
        padding:25px 72px!important;
    }
    .block-landing-pay-banner-wrapper.block-investor-pay-banner-wrapper .text-see-sample-pdf {
        padding: 0;
        margin: 0 auto;
    }
}

@media screen and (max-width: 620px) {
    .swiper-container {
        height: 505px;
    }
    .swiper-slide {
        height: 440px!important;
    }
    .block-slider-point {
        height: 390px;
        padding: 15px 30px 15px 30px;
    }
    .block-slider-point .text-quotes {
        display: none;
    }
    .block-slider-point-left {
        min-width: unset;
    }
    .block-slider-point-left .text-see-sample-pdf {
        padding: 15px 0 0 10px;
    }
}
@media all and (max-width: 735px) {
    .block-pay-banner {
        padding: 0 20px;
    }
    .block-pay-banner .left-col {
        max-width: 100%;
        margin-right: 0;
    }
    .block-pay-banner .right-col {
        max-width: 260px;
        margin:  0 auto;
    }
    .block-pay-banner .pay-banner-bg {
        margin-top: 25px;
    }
}
@media screen and (max-width: 645px) {
    .block-advantages-table,
    .block-evaluated-projects-list {
        max-width: 300px;
        margin-top: 0!important;
    }

    .block-evaluated-startups .block-evaluated-projects-list {
        max-width: 320px;
    }
}
@media screen and (max-width: 640px) {
    .block-landing-pay-banner-wrapper {
        padding: 50px 10px 45px 10px;
    }
}
@media screen and (max-width: 620px) {
    .block-current-statistics .role-statistic-wrapper {
        border-left: none;
    }
    .block-roles-statistic {
        border-left: 1px solid rgba(0,0,0,0.5);
    }
}

@media screen and (max-width: 576px) {
    .block-algorithm-table,
    .block-startups-wrapper {
        max-width: 300px;
        margin-top: 0!important;
    }
    .block-advantages-table-wrapper .block-advantages-main-title {
        font-size: 27px;
    }
    .block-advantage-point,
    .block-algorithm-table .block-advantage-point:nth-child(3) {
        margin: 25px 0px
    }
    .pay-banner-button-call.investor {
        margin: 40px auto 0 auto;
    }

    .block-investor-pay-banner-wrapper .investor-banner-right-col {
        margin: 0;
    }
}
@media screen and (max-width: 500px) {
    .block-expert-conducting-bg {
        height: 930px;
        background-position: 8% -17%;
        background-size: auto 600px;
    }
    .expert-conducting-main-title {
        font-size: 28px;
    }
    .expert-conducting-block.expert-conducting-main-title {
        margin: 300px auto 0 auto;
    }
    .expert-conducting-block {
        padding: 0 30px;
        max-width: none;
    }
    .header-landing-main-text {
        font-size: 28px;
        line-height: 38px;
    }
    .header-landing-descr-text {
        font-size: 18px;
    }
    .block-investor-pay-banner-wrapper {;
        padding:25px !important;
    }
    .pay-banner-column{
        margin: 0;
    }
    .swiper-container {
        height: 550px;
    }
    .swiper-slide {
        height: 495px!important;
    }
    .block-slider-point {
        height: 440px;
        padding: 30px 5px 15px 20px;
        margin: 0 15px;
    }
    .investor-sliader .block-slider-point {
        height: 360px;
    }
    .investor-sliader .swiper-slide {
        height: 410px!important;
    }
    .investor-sliader .swiper-container {
        height: 480px;
    }
    .block-slider-point .client-name-block {
        padding-left: 15px;
        padding-right: 10px;
    }
    .block-slider-point-right .client-logo {
        margin: 12px auto 0 auto!important;
    }
    .text-see-sample-pdf{
        margin-top: 20px !important;
    }
    .investor-banner-right-col .pay-banner-invetor-main-title{
        margin-top: 20px;
    }
}

@media screen and (max-width: 400px) {
    .expert-conducting-block.expert-conducting-main-title {
        margin: 320px auto 0 auto;
    }
}
@media screen and (max-width: 365px) {
    .expert-conducting-block {
        padding: 0 5px 0 15px;
    }
    .algorithm-button-wrapper .block-button-3-0.button-3-0-green {
        padding-left: 10px!important;
        padding-right: 10px!important;
    }
}
@media all and (max-width: 330px) {
    .expert-conducting-main-title {
        font-size: 27px;
    }
    .expert-conducting-block.expert-conducting-main-title {
        margin: 340px auto 0 auto;
    }
}

@media (min-width: 576px) and (max-width: 620px) {
    .pay-banner-button-call.investor .block-button-3-0 p.block-button-3-0-title{
       font-size: 14px;
    }
}

