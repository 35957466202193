.super-big-title{
    width: 100%;
    /*overflow: hidden;*/
    color: #000000;	font-family: "Stratos LC";	font-size: 48px;	letter-spacing: -1.03px;	line-height: 60px;
    font-weight: 400; margin: 40px 0;
}
.no-margin{
    margin: 0!important;
}
.overflow-hidden{
    overflow: hidden;
}
.margin-top-100{
    margin-top: 100px;
}


.shell-grid-DAO{
    /*overflow: hidden;*/
    width: 100%;
    margin: 0 0 100px 0;
}
.block-grid-DAO{
    /*overflow: hidden;*/
    width: 100%;
}
.block-header-grid-DAO{
    overflow: hidden;
    width: 100%;
    border-bottom: 1px solid #CFCFCF;
}
.block-header-grid-DAO .block-column-grid-DAO{
    float: left;
    /*overflow: hidden;*/
    min-height: 1px;
}
.block-column-grid-DAO {
    padding: 0 15px 0 0;
}
.block-column-grid-DAO:last-child{
    padding: 0;
}
.block-column-grid-DAO p.title-header{ margin: 0;
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 25px; font-weight: 300;
}

.block-body-grid-DAO{
    /*overflow: hidden;*/
    width: 100%;
}
.block-list-items-grid-DAO{
    /*overflow: hidden;*/
    padding: 5px 0;
    background: #FFFFFF;
}
.block-item-grid-DAO{
    height: 87px;
    /*overflow: hidden;*/
    border-bottom: 1px solid #CFCFCF;
    transition: .3s;
    cursor: pointer;
}
.block-item-grid-DAO:hover{
    box-shadow: 0 0px 5px 0 rgba(0,0,0,0.3);
}
.block-item-grid-DAO .block-column-grid-DAO{
    float: left;
    /*overflow: hidden;*/
    height: 87px;
    display: flex;
}

.block-item-grid-DAO .block-column-grid-DAO p.title{
    padding: 0 10px 0 0;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 16px; font-weight: 300;
    text-overflow: ellipsis; white-space: nowrap; overflow: hidden;
}
.block-item-grid-DAO .block-column-grid-DAO p.description{
    padding: 0 10px 0 0;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 14px;font-weight: 300;
}






.block-column-person-grid-experts{width: 20%;}
.block-column-skill-grid-experts{width: 22%;}
.block-column-work-grid-experts{width: 28%;}
.block-column-country-grid-experts{width: 14%;}
.block-column-language-grid-experts{width: 14%;}
.block-column-social-grid-experts{width: 13%;}
.block-column-date-grid-experts{width: 7.5%;}
.block-column-role-grid-experts{width: 9.5%;}


.block-column-title-folder-grid-knowlege{width: 47%}
.block-column-tag-folder-grid-knowlege{width: 25.75%}
.block-column-date-folder-grid-knowlege{width: 27.25%}


.block-column-title-folder-grid-knowlege-edit{width: 40.5%}
.block-column-tag-folder-grid-knowlege-edit{width: 26%}
.block-column-date-folder-grid-knowlege-edit{width: 33.5%}


.block-column-logo-grid-file-knowlege{width: 8.7%}
.block-column-title-grid-file-knowlege{width: 37.8%}
.block-column-tags-grid-file-knowlege{width: 26.1%}
.block-column-date-grid-file-knowlege{width: 16.1%;}
.block-column-link-grid-file-knowlege{width: 11.3%}



.block-column-logo-grid-file-knowlege-edit{width: 8.7%}
.block-column-title-grid-file-knowlege-edit{width: 33.8%}
.block-column-tags-grid-file-knowlege-edit{width: 23.1%}
.block-column-date-grid-file-knowlege-edit{width: 15.1%;}
.block-column-link-grid-file-knowlege-edit{width: 11.65%}
.block-column-delete-grid-file-knowlege-edit{width: 7.65%}


.block-column-state-grid-forum-thread{width: 50px}
.block-column-title-grid-forum-thread{width: calc(100% - 330px)}
.block-column-user-grid-forum-thread{width: 170px}
.block-column-info-grid-forum-thread{width: 100px}


.block-column-logo-grid-new-fund{width: 7.8%}
.block-column-title-grid-new-fund{width: 18.4%}
.block-column-raised-far-grid-new-fund{width: 12.7%}
.block-column-raised-grid-new-fund{width: 13.1%}
.block-column-participants-grid-new-fund{width: 7%}
.block-column-goal-grid-new-fund{width: 8%}
.block-column-end-date-grid-new-fund{width: 13%}
.block-column-added-grid-new-fund{width: 7%}
.block-column-status-grid-new-fund{width: 13%}


.block-column-startup-grid-new-startup{width: 21.9%}
.block-column-contribution-grid-new-startup{width: 11.8%}
.block-column-funds-grid-new-startup{width: 10.9%}
.block-column-location-grid-new-startup{width: 10.9%}
.block-column-market-grid-new-startup{width: 21%}
.block-column-website-grid-new-startup{width: 11.4%}
.block-column-team-grid-new-startup{width: 12.1%}



.block-column-logo-grid-start-up-table{width: 75px}
.block-column-title-grid-start-up-table{width: calc(23.6% - 75px)}
.block-column-tags-grid-start-up-table{width: 14.9%}
.block-column-round-grid-start-up-table{width: 6.5%}
.block-column-fundrising-grid-start-up-table{width: 9.6%}
.block-column-token-grid-start-up-table{width: 15.7%}
.block-column-status-grid-start-up-table{width: 29.7%}



.block-column-logo-grid-state-quorum{width: 75px}
.block-column-title-grid-state-quorum{width: calc(37.2% - 75px)}
.block-column-tags-grid-state-quorum{width: 20.4%}
.block-column-round-grid-state-quorum{width: 11.2%}
.block-column-count-grid-state-quorum{width: 12.4%}
.block-column-price-grid-state-quorum{width: 18.8%}




.block-person-img-name-surname-grid-experts{
    width: 100%;
    height: 60px;
    margin: 13px 0 14px 0;
}
.block-img-person-grid-experts{
    height: 60px;	width: 80px; float: left;
}
.block-img-person-grid-experts.add-indicator {
    position: relative;
}
.block-img-person-grid-experts.add-indicator .notification-indicator {
    width: 12px;
    height: 12px;
    left: 6px;
}

.block-img-person-grid-experts img{
    height: 60px;	width: 60px;	border-radius: 30px;	background-color: #FFFFFF;	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.3);
}
.block-name-surname-grid-experts{
    height: 60px;
    width: calc(100% - 80px);
    float: left;
}
.block-name-surname-grid-experts p{
    color: #000000;	font-family: "Stratos LC";	font-size: 18px;	letter-spacing: -0.23px;	line-height: 20px;
    text-overflow: ellipsis; white-space: nowrap; overflow: hidden;
}
.block-name-surname-grid-experts p.name{
    margin: 10px 0 0 0;
}
.block-name-surname-grid-experts p.surname{ margin: 0}



.block-social-grid-experts{
    width: 100%;
    padding: 0 10px 0 0;
    overflow: hidden;
    margin: auto 0;
}
.block-email{
    overflow: hidden;
    width: 100%;
    margin: 0 0 15px 0;
}

.block-link-social{
    overflow: hidden;
    width: 100%;
    height: 18px;
}
.block-link-social a{
    text-decoration: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 10px 0 0;
}

.block-social-grid-experts a.email{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 15px;
    display: block; top: 0; width: 100%;text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

}
.block-social-grid-experts a.email:hover{
    text-decoration: underline;
}
.block-social-grid-experts a{
    text-decoration: none;
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 10px 0 0;
    position: relative;
}
.block-social-grid-experts a i{
    color: #4A4A4A;
    margin: 0;
    transition: .3s;
}
.block-social-grid-experts a:hover i{
    color: #2AA2E6;
}






.shell-button-drop-down{
    width: 100%;
    position: relative;
margin: 27px 0;
}
.block-button-drop-down{
    position: relative;
}
.shell-button-drop-down.open .block-body-button-drop-down{
    height: calc(26px * 6);
}
.shell-button-drop-down.close .block-body-button-drop-down {
    height: 0;
}
.block-button-drop-down:hover .block-body-button-drop-down{
    height: calc(26px * 6);
}

.block-header-button-drop-down{
    width: 100%;
    background: #00F082;
    height: 32px;
    z-index: -1;
}
.block-header-button-drop-down.block {
    background-color: #e44400;
}
.block-header-button-drop-down.block p{
    color: #ffffff;
}
.block-header-button-drop-down p{
    color: #000000;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 32px;	text-align: center;
    font-weight: 300; margin: 0;
}

.block-body-button-drop-down{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #2AA2E6;
    height: 0;
    overflow: hidden;
    transition: .3s;
    display: flow-root;
    cursor: default!important;
    z-index: 1;
}
.block-list-items-button-drop-down{

}
.block-item-button-drop-down{
    cursor: pointer;
    height: 16px;
    margin: 9px 0;
    display: block;
    overflow: hidden;
    box-sizing: content-box;
    position: relative;
}
.block-item-button-drop-down p{ transition: .3s;
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 16px;	text-align: center; font-weight: 300; margin: 0;
}
.block-item-button-drop-down p.active {
    color: #00F082;
}
.block-item-button-drop-down p:hover{
    color: #00F082;
}


.block-column-logo-grid-file-knowlege i{
    line-height: 87px;
    width: 100%;
    font-size: 50px;
}
.block-column-logo-grid-file-knowlege i.fa-link{
    font-size: 40px;
}
.block-column-logo-grid-file-knowlege-edit i{
    line-height: 87px;
    width: 100%;
    font-size: 50px;
}


.title-file-knowlege{
    color: #000000;	font-family: "Stratos LC";	font-size: 16px;	letter-spacing: -0.2px;	line-height: 20px;
    font-weight: 400; margin: 0;
}
.description-file-knowlege{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.2px;	line-height: 17px;
    font-weight: 400; margin: 0;
}
.padding-horizontal-20{
    padding: 0 20px;
}

.bock-state{
    width: 50px;
    height: 50px;
}
.bock-state span.state{
    width: 10px;
    height: 10px;
    margin: 20px;
    border-radius: 50%;
    display: block;
}
.bock-state span.state.state-blue{
    background: #2AA2E6;
}
.title-forum-thread{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 20px;	font-weight: 500;	letter-spacing: -0.25px;	line-height: 50px;
    margin: 0;text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.block-user-forum-thread{
 padding: 9px 0;
    box-sizing: border-box;
}
.block-user-forum-thread p{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 16px;
    margin: 0; font-weight: 300;
}
.title-count-forum-thread{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 15px;	letter-spacing: -0.19px;	line-height: 50px;
    margin: 0; font-weight: 400; text-align: right;
}
.background-CCCCCC{
    background: #CCCCCC!important;
}
.background-CCCCCC p{
    color: #ffffff!important;
}

/*new fund start*/

.block-logo-fund{
    height: 60px;	width: 60px;
    margin: 13px 0;
}
.block-logo-fund img{
    height: 60px;	width: 60px;
    display: block;
    /*background: #002BDE;*/
}

.block-title-description-new-fund{
    height: 60px;
    width: 100%;
    overflow: hidden;
    margin: 13px 0;
}
.block-title-description-new-fund .title-new-fund{
    color: #000000;	font-family: "Stratos LC";	font-size: 19px;	letter-spacing: -0.24px;	line-height: 20px;
    margin: 0; font-weight: 400;
}
.block-title-description-new-fund .description-new-fund{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 13px;
    margin: 0; font-weight: 300; padding: 0 40px 0 0;
    display: block!important;
    height: 41px;
    overflow: hidden;
}

.block-list-tag{
    display: block;
    margin: auto 0;
    width: 100%;
}
.block-list-tag .block-tag-2-0 a p{
    color: #4A4A4A!important;
    font-weight: 400;
}
.block-column-tags-grid-state-quorum .block-list-tag .block-tag-2-0{
    display: block!important;
    width: 100%;
}
.block-column-tags-grid-state-quorum .block-list-tag .block-tag-2-0 a p{
    font-weight: 300;
}


.title-fund-grid{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 18px;
    font-weight: 300; margin: 0; text-align: left; padding: 0 15px 0 0;
    max-height: 75px; overflow: hidden;
}
.title-fund-grid a{
    display: block;
    text-decoration: underline;
    color: #2AA2E6;
}
.text-center{
    margin: auto!important;
}

.block-person-img{
    height: 38px;	width: 38px;
    margin: auto 5px;
}
.block-person-img a{}
.block-person-img a img{
    height: 38px;	width: 38px;
    display: block;
    /*background: #002BDE;*/
    border-radius: 50%;
}

.no-border{
    border: 0;
}

.padding-15-grid-horizontal{
    padding: 0 15px;
}
.item-grid-new-fund{
    background: #FFFFFF;
    border-radius: 3px;	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
    border: 0;
    margin: 0 0 10px 0;
}
.item-grid-new-startup{
    background: #FFFFFF;
    box-shadow: 0 1px 3px 0 rgba(0,0,0,0.2);
    border: 0;
    border-bottom: 1px solid #CFCFCF;
    margin: 0 0 5px 0;
    height: 64px;
}
.item-grid-new-startup .shell-center-top-bottom-flex{
    height: 64px!important;
    width: 100%;
}
.item-grid-new-startup .block-column-grid-DAO{
    height: 64px;
}
.item-grid-new-startup.border-blue{
    border-bottom: 1px solid #2AA2E6;
}
.item-grid-new-startup.border-green{
    border-bottom: 1px solid #00F082;
}

.shell-progress-bar-new-fund{
    width: 90%;
    overflow: hidden;
    padding: 0 0 15px 0;
    margin: auto 0;
}
.block-title-progress-bar-new-fund{

}
.title-grid-polls{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 18px;
    margin: 0 0 6px 0; font-weight: 400;
}
.block-progress-bar-new-fund{
    width: 100%;
}
.block-down-progress-bar-new-fund{
    width: 100%;
    height: 7px;
    background: #EBEBEB;
    position: relative;
}
.block-up-progress-bar-new-fund{
    width: 100%;
    overflow: hidden;
    height: 7px;
    background: #00F082;
}
.new-fund-progress-bar{
    color: #00F082;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 15px;
    position: absolute; top: 10px ;
}

.shell-button-grid-new-fund{
    width: 100%;
    margin: auto 0;
}
.shell-button-grid-new-fund .block-button-2-0{
    border-radius: 3px;
    width: 100%;
}
.shell-button-grid-new-fund .block-button-2-0 p{
    font-weight: 400!important;
    text-align: center!important;
    display: block!important;
}

/*startup grid*/

.block-logo-title-startup{
    width: 100%;
    overflow: hidden;
    height: 35px;
    margin: 14px 0;
}
.block-left-logo-title-startup{
    float: left;
    height: 35px;	width: 35px;
}
.block-right-logo-title-startup{
    float: right;
    width: calc(100% - 35px);
    padding: 0 0 0 15px;
    box-sizing: border-box;
}
.block-left-logo-title-startup .block-logo{
    height: 35px;	width: 35px;
}
.block-left-logo-title-startup .block-logo img{
    height: 35px;	width: 35px;
    display: block;
    /*background: #002BDE;*/
}
.block-right-logo-title-startup .block-title{
    width: 100%;
    height: 35px;
}
.block-right-logo-title-startup .block-title p.title-startup{
    color: #000000;	font-family: "Stratos LC";	font-size: 17px;	letter-spacing: -0.21px;	line-height: 20px;
    margin: 0; font-weight: 400;     text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.block-right-logo-title-startup .block-title p.description-startup{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 13px;
    margin: 0; font-weight: 300;text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.block-title-review{
    margin: auto 0;
}
.block-title-review p.title-review{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
}
.block-title-review p.blue-review{
    color: #2AA2E6;	font-size: 16px;	line-height: 20px;
}
.block-title-review p.green-review{
    color: #00F082;	font-size: 16px;	line-height: 20px;
}

.block-state-dot{
    height: 7px;
    width: 100%;
    overflow: hidden;
    margin: auto 0;
}
.dot-color-blue{}
.state-dot{
    display: block;
    float: left;
    height: 7px;	width: 7px;
    margin: 0 4px 0 0;
    opacity: 0.2;

}
.dot-color-blue .state-dot{
    background-color: #2AA2E6;
}
.dot-color-blue .state-dot.active{
    opacity: 1;

}

.block-list-tag-startup{
    margin: auto 0;
}
.block-list-tag-startup .block-tag-2-0 a p{
    color: #4A4A4A!important;
    border: 1px solid #4A4A4A;
    border-radius: 50px;
    padding: 0 10px;
}
.block-list-tag-startup .block-tag-2-0 a p i{
    display: none;
}
.link-site-startup{
    margin: auto 0;
    display: block; font-weight: 300;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 13px;
    text-decoration: none;
    white-space: nowrap; /* Отменяем перенос текста */
    overflow: hidden; /* Обрезаем содержимое */
    text-overflow: ellipsis; /* Многоточие */
    width: 100%;
}
.link-site-startup:hover{
    text-decoration: underline;
}
