@import '../../CONST';

.service-button.icon {
  background-repeat: no-repeat;
  background-position: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &.disable {
    opacity: 0.2;
    cursor: unset;
  }
  &.like {
    background-image: url('../../../../images/icon/LikeIcon.svg');
  }
  &.chat {
    background-image: url('../../../../images/icon/ChatIcon.svg');
  }
  &.bell {
    background-image: url('../../../../images/icon/BellIcon.svg');
    padding: 2px;
    .title {
      padding-left: 42px;
    }
  }
  .title {
    @extend %menu-title;
    display: none;
    padding-left: 44px;
  }

  @media (max-width: 756px) {
    .title {
      display: block;
    }
  }
}