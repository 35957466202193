.container-100{
    width: 100%;
}

.container-100.edit{
    background: #FAFAFA;
}

.row{
    width: 100%;
    position: relative;
}

.row:before,
.row:after{
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
}

.col-25{
    width: 25%;
    float: left;
}

.col-75{
    width: 75%;
    float: left;
}

.col-m50{
    min-width: 50%;
}
.col-m60{
    min-width: 60%;
}

.col-auto-c{
    width: auto;
    max-width: max-content !important;
    min-width: auto !important;
}

.fixed{
    position: fixed;
}

.fly-button-container{
    bottom: 0;
    height: 70px;
    left: 0;
    background: rgba(255,255,255,0.5);
    align-items: center;
    display: flex;
    z-index: 88;
    padding-left: 56px;
}

.fly-button-container .button-wrapper{
    padding: 0 22px 0 90px;
    max-width: 710px;
    justify-content: flex-end;
}
.fly-button-container .button-wrapper.account-button-wrapper{
    padding-right: 0;
}
.fly-button-container .button-wrapper.account-button-wrapper .block-button-3-0.button-3-0-blue {
    margin-right: -10px;
}

@media all and (max-width: 900px){
    .col-m60{
        width: 100%;
        min-width: 100%;
    }
    .col-m50{
        width: 100%;
        min-width: 100%;
    }
}
@media all and (max-width: 576px) {
    .fly-button-container {
        height: auto;
    }
    .fly-button-container .button-wrapper{
        padding: 0 16px;
    }
    .fly-button-container .button-wrapper .block-button-3-0 {
        width: 100%;
    }
    .block-user-right .fly-button-container .button-wrapper .button-reset-wrapper {
        width: 100%;
        margin: 0;
        padding: 10px 0 0 0;
    }
    .block-user-right .fly-button-container .button-wrapper .reset-button-column {
        padding-bottom: 15px;
    }
    .profile-user-name-block .bottom-password-line .button-wrapper {
        padding: 0 16px!important;
    }
}
@media all and (max-width: 500px) {
    .fly-button-container {
        padding: 0;
        z-index: 100;
    }
}