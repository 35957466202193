.button {
  background: #FDFDFD;
  border: 1px solid #2F3849;
  box-sizing: border-box;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
  flex-wrap: nowrap;
  transition: opacity 0.15s linear;

  .title-button {
    margin: auto;
  }

  &.button:hover {
    opacity: 0.8;
  }

  &.h36 {
    height: 36px;

  }

  &.h40 {
    height: 40px;
    font-size: 16px;
    line-height: 19px;
  }

  &.h48 {

    height: 48px;
    font-size: 16px;
    line-height: 20px;
  }

  &.h60 {
    height: 60px;
    font-size: 18px;
    line-height: 23px;
  }

  &.active {
    color: white;
    background: #0ECFAA;;
    border: 1px solid #0ECFAA;;
  }

  &.startup {
    background: #2CA6EB;
    color: #FFFFFF;
    border: none;
  }

  &.profile {
    background-color: #2CA6EB;
    color: #FFFFFF;
    border: none;
  }


  &.white {
    border: 2px solid #2F3849;
    color: #2F3849;
  }

  &.pink {
    color: rgba(8, 8, 8, 0.5);
    border: 2px solid rgba(0, 0, 0, 0.5);
  }

  &.pinok_pod_zad {
    background: #E43A70;
    color: #FFFFFF;
    box-shadow: 0px 8px 19px rgba(32, 165, 238, 0.151664);
    border: 0;
  }

  &.accelerator {
    background: #2CDFEB;
    color: #FFFFFF;
    border: none;
  }

  &.investor,
  &.fund,
  &.angel_network {
    background: #2CA6EB;
    color: #FFFFFF;
    border: none;
  }

  &.expert {
    background: #0ECFAA;
    color: #FFFFFF;
    border: none;
  }

  &.black {
    background: #4B586E;
    color: #FFFFFF;
    border: none;
  }

  .loader-button {
    transition: 1s;
    -moz-animation-name: spin;
    -moz-animation-duration: 1s;
    -webkit-animation-name: spin;
    -webkit-animation-duration: 1s;

    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
    -moz-animation-direction: normal;
    -moz-animation-delay: 0;
    -moz-animation-play-state: running;
    -moz-animation-fill-mode: forwards;

    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: normal;
    -webkit-animation-delay: 0;
    -webkit-animation-play-state: running;
    -webkit-animation-fill-mode: forwards;
  }


  img.defender-img {
    margin-top: 4px;
    margin-right: -10px;
  }

  img.loader-button {
    height: 27px;
    width: 27px;
    border-radius: 50%;
    /*border: 4px solid rgba(255, 255, 255, 0.2);*/
  }
}