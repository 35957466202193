.shell-black-progress-2-0 {
    background: #000000;
    overflow: hidden;
}

.block-progress-bar-2-0 {
    margin: 0 2px;
}

.block-top-progress-bar-2-0 {
    overflow: hidden;
}

.block-top-progress-bar-2-0 .round {
    display: inline-block;
    vertical-align: top;
    float: left;
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 20px;
    line-height: 25px;
    margin: 0;
    font-weight: 400;
}

.block-count-progress-bar-2-0 {
    display: inline-block;
    vertical-align: top;
    float: right;
}

.block-count-progress-bar-2-0 img {
    display: inline-block;
    vertical-align: top;
    width: auto!important;
    height: auto!important;
    margin: 0!important;
}

.block-count-progress-bar-2-0 p {
    display: inline-block;
    vertical-align: top;
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 20px;
    line-height: 25px;
    margin: 0;
    font-weight: 400;
}

.block-body-progress-bar-2-0 {
    height: 7px;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin: 10px 0 0 0;
}

.block-body-progress-bar-2-0 .Progress {
    height: 7px;
    background: #00F082;
    border-radius: 4px;
}

.block-progress-bar-2-0 .soft-cap {
    color: #D4D6D9;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 13px;
    text-align: right;
    margin: 8px 0 0 0;
    float: left;
    position: relative;
    font-weight: 300;
}

.block-progress-bar-2-0 .soft-cap:after {
    content: '';
    width: 1px;
    height: 28px;
    position: absolute;
    background: #979797;
    right: -6px;
    bottom: 0;
}

.block-progress-bar-2-0 .hard-cap {
    color: #D4D6D9;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 13px;
    text-align: right;
    margin: 8px 0 0 0;
    float: right;
    font-weight: 300;
}
.block-progress-bar-2-0  .br-value{
    color: #00F082;
    font-size: 34px;
    padding-top: 5px;
}









.block-progress-bar-fund{

    height: 100px;
}
.block-top-progress-bar-fund{}
.block-top-progress-bar-fund p{
    color: #ffffff;	font-family: "Stratos LC";	font-size: 34px;	line-height: 38px; font-weight: 300; margin: 0;
}
.block-body-progress-bar-fund{
    height: 7px;
    width: 100%;
    background: #ffffff;
    border-radius: 4px;
    margin: 0;
    position: relative;
}
.block-body-progress-bar-fund .progress-fund{
    height: 7px;
    background: #00F082;
    border-radius: 4px;
}

.block-cap-fund{
    position: absolute;
    top: 10px;
}
.before-firs-cap{
    display: none;
}
.first-cap .line-cap-fund{
    background: #00F082;

}
.center-cap .line-cap-fund{
    height: 7px;
    background: #979797;
}
.last-cap .line-cap-fund{
    background: none;
}
.last-cap .percent-discount-fund{
    color: #D4D6D9!important;
}
.last-cap{
    right: 0!important;
}
.last-cap p{
    margin-right: 0;
}




.center-cap p{
    display: none;
}
.line-cap-fund{
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;

}

.count-cap-fund{white-space: nowrap;
    color: #D4D6D9;	font-family: "Stratos LC";	font-size: 12px;	line-height: 13px; text-align: right; font-weight: 300; margin: 17px 5px 5px 0;
}
.percent-discount-fund{white-space: nowrap;
    color: #00F082;	font-family: "Stratos LC";	font-size: 12px;	font-weight: 600;	line-height: 13px;	text-align: right;font-weight: 400; margin: 0 5px 0 0;
}

.fund-status-raised {
    color: #ffffff;
    padding-left: 10px;
    font-size: 14px;
}




