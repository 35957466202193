.new-container{}
.new-container{}


.new-row{
    width: 100%;
    display: table;
}
.new-col{
    float: left;
}
.new-col.float-right{
    float: right;
}
.new-col.c-2{
    width: calc(100% / 2);
}
.new-col.c-3{
    width: calc(100% / 3);
}
.new-col.c-4{
    width: calc(100% / 4);
}
.new-col.c-5{
    width: calc(100% / 5);
}
.new-col.c-6{
    width: calc(100% / 6);
}
.new-col.c-7{
    width: calc(100% / 7);
}
.new-col.c-8{
    width: calc(100% / 8);
}
.new-col.c-9{
    width: calc(100% / 9);
}
.new-col.c-10{
    width: calc(100% / 10);
}
.new-col.c-11{
    width: calc(100% / 11);
}
.new-col.c-12{
    width: calc(100% / 12);
}


.text-left{text-align: left}
.text-right{text-align: right}
.text-center{text-align: center}


.new-pad{}
.new-pad-8{padding: 8px;}
.new-pad-8-hor{padding: 0 8px;}
.new-pad-8-ver{padding: 8px 0;}
.new-pad-8-top{padding-top: 8px}
.new-pad-8-right{padding-right: 8px}
.new-pad-8-bottom{padding-bottom: 8px}
.new-pad-8-left{padding-left: 8px}
.new-pad-10{padding: 10px;}
.new-pad-10-hor{padding: 0 10px;}
.new-pad-10-ver{padding: 10px 0;}
.new-pad-10-top{padding-top: 10px}
.new-pad-10-right{padding-right: 10px}
.new-pad-10-bottom{padding-bottom: 10px}
.new-pad-10-left{padding-left: 10px}
.new-pad-15{padding: 15px;}
.new-pad-15-hor{padding: 0 15px;}
.new-pad-15-ver{padding: 15px 0;}
.new-pad-15-top{padding-top: 15px}
.new-pad-15-right{padding-right: 15px}
.new-pad-15-bottom{padding-bottom: 15px}
.new-pad-15-left{padding-left: 15px}
.new-pad-20{padding: 20px;}
.new-pad-20-hor{padding: 0 20px;}
.new-pad-20-ver{padding: 20px 0;}
.new-pad-20-top{padding-top: 20px}
.new-pad-20-right{padding-right: 20px}
.new-pad-20-bottom{padding-bottom: 20px}
.new-pad-20-left{padding-left: 20px}
.new-pad-25{padding: 25px;}
.new-pad-25-hor{padding: 0 25px;}
.new-pad-25-ver{padding: 25px 0;}
.new-pad-25-top{padding-top: 25px}
.new-pad-25-right{padding-right: 25px}
.new-pad-25-bottom{padding-bottom: 25px}
.new-pad-25-left{padding-left: 25px}
.new-pad-30{padding: 30px;}
.new-pad-30-hor{padding: 0 30px;}
.new-pad-30-ver{padding: 30px 0;}
.new-pad-30-top{padding-top: 30px}
.new-pad-30-right{padding-right: 30px}
.new-pad-30-bottom{padding-bottom: 30px}
.new-pad-30-left{padding-left: 30px}
.new-pad-35{padding: 35px;}
.new-pad-35-hor{padding: 0 35px;}
.new-pad-35-ver{padding: 35px 0;}
.new-pad-35-top{padding-top: 35px}
.new-pad-35-right{padding-right: 35px}
.new-pad-35-bottom{padding-bottom: 35px}
.new-pad-35-left{padding-left: 35px}
.new-pad-40{padding: 40px;}
.new-pad-40-hor{padding: 0 40px;}
.new-pad-40-ver{padding: 40px 0;}
.new-pad-40-top{padding-top: 40px}
.new-pad-40-right{padding-right: 40px}
.new-pad-40-bottom{padding-bottom: 40px}
.new-pad-40-left{padding-left: 40px}
.new-pad-45{padding: 45px;}
.new-pad-45-hor{padding: 0 45px;}
.new-pad-45-ver{padding: 45px 0;}
.new-pad-45-top{padding-top: 45px}
.new-pad-45-right{padding-right: 45px}
.new-pad-45-bottom{padding-bottom: 45px}
.new-pad-45-left{padding-left: 45px}
.new-pad-50{padding: 50px;}
.new-pad-50-hor{padding: 0 50px;}
.new-pad-50-ver{padding: 50px 0;}
.new-pad-50-top{padding-top: 50px}
.new-pad-50-right{padding-right: 50px}
.new-pad-50-bottom{padding-bottom: 50px}
.new-pad-50-left{padding-left: 50px}



.new-mar{}
.new-mar.mar-auto{margin: auto}
.new-mar.mar-auto-ver{margin-top: auto;margin-bottom: auto}
.new-mar.mar-auto-hor{margin-left: auto;margin-right: auto}
.new-mar.mar-auto-top{margin-top: auto}
.new-mar.mar-auto-right{margin-right: auto}
.new-mar.mar-auto-bottom{margin-bottom: auto}
.new-mar.mar-auto-left{margin-left: auto}

.new-mar-5{margin: 5px;}
.new-mar-5-hor{margin-left: 5px; margin-right: 5px;}
.new-mar-5-ver{margin-top: 5px; margin-bottom: 5px;}
.new-mar-5-top{margin-top: 5px}
.new-mar-5-right{margin-right:  5px}
.new-mar-5-bottom{margin-bottom: 5px}
.new-mar-5-left{margin-left: 5px}
.new-mar-8{margin: 8px;}
.new-mar-8-hor{margin-left: 8px; margin-right: 8px;}
.new-mar-8-ver{margin-top: 8px; margin-bottom: 8px;}
.new-mar-8-top{margin-top: 8px}
.new-mar-8-right{margin-right:  8px}
.new-mar-8-bottom{margin-bottom: 8px}
.new-mar-8-left{margin-left: 8px}
.new-mar-10{margin: 10px;}
.new-mar-10-hor{margin-left: 10px; margin-right: 10px;}
.new-mar-10-ver{margin-top: 10px; margin-bottom: 10px;}
.new-mar-10-top{margin-top: 10px}
.new-mar-10-right{margin-right:  10px}
.new-mar-10-bottom{margin-bottom: 10px}
.new-mar-10-left{margin-left: 10px}
.new-mar-15{margin: 15px;}
.new-mar-15-hor{margin-left: 15px; margin-right: 15px;}
.new-mar-15-ver{margin-top: 15px; margin-bottom: 15px;}
.new-mar-15-top{margin-top: 15px}
.new-mar-15-right{margin-right:  15px}
.new-mar-15-bottom{margin-bottom: 15px}
.new-mar-15-left{margin-left: 15px}
.new-mar-20{margin: 20px;}
.new-mar-20-hor{margin-left: 20px; margin-right: 20px;}
.new-mar-20-ver{margin-top: 20px; margin-bottom: 20px;}
.new-mar-20-top{margin-top: 20px}
.new-mar-20-right{margin-right:  20px}
.new-mar-20-bottom{margin-bottom: 20px}
.new-mar-20-left{margin-left: 20px}
.new-mar-25{margin: 25px;}
.new-mar-25-hor{margin-left: 25px; margin-right: 25px;}
.new-mar-25-ver{margin-top: 25px; margin-bottom: 25px;}
.new-mar-25-top{margin-top: 25px}
.new-mar-25-right{margin-right:  25px}
.new-mar-25-bottom{margin-bottom: 25px}
.new-mar-25-left{margin-left: 25px}
.new-mar-30{margin: 30px;}
.new-mar-30-hor{margin-left: 30px; margin-right: 30px;}
.new-mar-30-ver{margin-top: 30px; margin-bottom: 30px;}
.new-mar-30-top{margin-top: 30px}
.new-mar-30-right{margin-right:  30px}
.new-mar-30-bottom{margin-bottom: 30px}
.new-mar-30-left{margin-left: 30px}
.new-mar-40{margin: 40px;}
.new-mar-40-hor{margin-left: 40px; margin-right: 40px;}
.new-mar-40-ver{margin-top: 40px; margin-bottom: 40px;}
.new-mar-40-top{margin-top: 40px}
.new-mar-40-right{margin-right:  40px}
.new-mar-40-bottom{margin-bottom: 40px}
.new-mar-40-left{margin-left: 40px}
.new-mar-50{margin: 50px;}
.new-mar-50-hor{margin-left: 50px; margin-right: 50px;}
.new-mar-50-ver{margin-top: 50px; margin-bottom: 50px;}
.new-mar-50-top{margin-top: 50px}
.new-mar-50-right{margin-right:  50px}
.new-mar-50-bottom{margin-bottom: 50px}
.new-mar-50-left{margin-left: 50px}
.new-mar-60{margin: 60px;}
.new-mar-60-hor{margin-left: 60px; margin-right: 60px;}
.new-mar-60-ver{margin-top: 60px; margin-bottom: 60px;}
.new-mar-60-top{margin-top: 60px}
.new-mar-60-right{margin-right:  60px}
.new-mar-60-bottom{margin-bottom: 60px}
.new-mar-60-left{margin-left: 60px}
.new-mar-70{margin: 70px;}
.new-mar-70-hor{margin-left: 70px; margin-right: 70px;}
.new-mar-70-ver{margin-top: 70px; margin-bottom: 70px;}
.new-mar-70-top{margin-top: 70px}
.new-mar-70-right{margin-right:  70px}
.new-mar-70-bottom{margin-bottom: 70px}
.new-mar-70-left{margin-left: 70px}
.new-mar-80{margin: 80px;}
.new-mar-80-hor{margin-left: 80px; margin-right: 80px;}
.new-mar-80-ver{margin-top: 80px; margin-bottom: 80px;}
.new-mar-80-top{margin-top: 80px}
.new-mar-80-right{margin-right:  80px}
.new-mar-80-bottom{margin-bottom: 80px}
.new-mar-80-left{margin-left: 80px}
.new-mar-90{margin: 90px;}
.new-mar-90-hor{margin-left: 90px; margin-right: 90px;}
.new-mar-90-ver{margin-top: 90px; margin-bottom: 90px;}
.new-mar-90-top{margin-top: 90px}
.new-mar-90-right{margin-right:  90px}
.new-mar-90-bottom{margin-bottom: 90px}
.new-mar-90-left{margin-left: 90px}
.new-mar-100{margin: 100px;}
.new-mar-100-hor{margin-left: 100px; margin-right: 100px;}
.new-mar-100-ver{margin-top: 100px; margin-bottom: 100px;}
.new-mar-100-top{margin-top: 100px}
.new-mar-100-right{margin-right:  100px}
.new-mar-100-bottom{margin-bottom: 100px}
.new-mar-100-left{margin-left: 100px}


.new-float-left{float: left;}
.new-float-left{float: right;}


@media (min-width: 1200px) {
    .new-no-float-up-1200{float: none!important;}
    .new-width-100-up-1200{width: 100%!important;}
    .new-no-margin-up-1200{margin: 0!important;}
    .new-no-margin-up-1200-left{margin-left: 0!important;}
    .new-no-margin-up-1200-top{margin-top: 0!important;}
    .new-no-margin-up-1200-right{margin-right: 0!important;}
    .new-no-margin-up-1200-bottom{margin-bottom: 0!important;}
    .new-no-padding-up-1200{padding: 0!important;}
    .new-no-padding-up-1200-left{padding-left: 0!important;}
    .new-no-padding-up-1200-top{padding-top: 0!important;}
    .new-no-padding-up-1200-right{padding-right: 0!important;}
    .new-no-padding-up-1200-bottom{padding-bottom: 0!important;}
}
@media (max-width: 1199px) {
    .new-no-display-down-1199{display: none!important;}
    .new-display-block-down-1199{display: block!important;}
    .new-no-float-down-1199{float: none!important;}
    .new-width-100-down-1199{width: 100%!important;}
    .new-pos-relative-down-1199{position: relative!important;}
    .new-top-0-down-1199{top: 0!important;}
    .new-top-auto-down-1199{top: auto!important;}
    .new-no-margin-down-1199{margin: 0!important;}
    .new-no-margin-down-1199-left{margin-left: 0!important;}
    .new-no-margin-down-1199-top{margin-top: 0!important;}
    .new-no-margin-down-1199-right{margin-right: 0!important;}
    .new-no-margin-down-1199-bottom{margin-bottom: 0!important;}
    .new-no-padding-down-1199{padding: 0!important;}
    .new-no-padding-down-1199-left{padding-left: 0!important;}
    .new-no-padding-down-1199-top{padding-top: 0!important;}
    .new-no-padding-down-1199-right{padding-right: 0!important;}
    .new-no-padding-down-1199-bottom{padding-bottom: 0!important;}
}
@media (max-width: 900px) {
    .new-no-display-down-900{display: none!important;}
    .new-no-float-down-900{float: none!important;}
    .new-width-100-down-900{width: 100%!important;}
    .new-pos-relative-down-900{position: relative!important;}
    .new-top-0-down-900{top: 0!important;}
    .new-no-margin-down-900{margin: 0!important;}
    .new-no-margin-down-900-left{margin-left: 0!important;}
    .new-no-margin-down-900-top{margin-top: 0!important;}
    .new-no-margin-down-900-right{margin-right: 0!important;}
    .new-no-margin-down-900-bottom{margin-bottom: 0!important;}
    .new-no-padding-down-900{padding: 0!important;}
    .new-no-padding-down-900-left{padding-left: 0!important;}
    .new-no-padding-down-900-top{padding-top: 0!important;}
    .new-no-padding-down-900-right{padding-right: 0!important;}
    .new-no-padding-down-900-bottom{padding-bottom: 0!important;}
}
@media (max-width: 620px) {
    .new-no-display-down-620{display: none!important;}
    .new-no-float-down-620{float: none!important;}
    .new-width-100-down-620{width: 100%!important;}
    .new-pos-relative-down-620{position: relative!important;}
    .new-top-0-down-620{top: 0!important;}
    .new-no-margin-down-620{margin: 0!important;}
    .new-no-margin-down-620-left{margin-left: 0!important;}
    .new-no-margin-down-620-top{margin-top: 0!important;}
    .new-no-margin-down-620-right{margin-right: 0!important;}
    .new-no-margin-down-620-bottom{margin-bottom: 0!important;}
    .new-no-padding-down-620{padding: 0!important;}
    .new-no-padding-down-620-left{padding-left: 0!important;}
    .new-no-padding-down-620-top{padding-top: 0!important;}
    .new-no-padding-down-620-right{padding-right: 0!important;}
    .new-no-padding-down-620-bottom{padding-bottom: 0!important;}
}
@media (max-width: 520px) {
    .new-no-display-down-520{display: none!important;}
    .new-no-float-down-520{float: none!important;}
    .new-width-100-down-520{width: 100%!important;}
    .new-pos-relative-down-520{position: relative!important;}
    .new-top-0-down-520{top: 0!important;}
    .new-no-margin-down-520{margin: 0!important;}
    .new-no-margin-down-520-left{margin-left: 0!important;}
    .new-no-margin-down-520-top{margin-top: 0!important;}
    .new-no-margin-down-520-right{margin-right: 0!important;}
    .new-no-margin-down-520-bottom{margin-bottom: 0!important;}
    .new-no-padding-down-520{padding: 0!important;}
    .new-no-padding-down-520-left{padding-left: 0!important;}
    .new-no-padding-down-520-top{padding-top: 0!important;}
    .new-no-padding-down-520-right{padding-right: 0!important;}
    .new-no-padding-down-520-bottom{padding-bottom: 0!important;}
}



.text-one-line{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}