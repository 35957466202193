.scroll-this{
    /*-webkit-overflow-scrolling: touch;*/
    z-index: 789784878;
}

.profile-edit-popup-block {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    max-height: 100vh;
    background-color: rgba(0,0,0,0.3);
    z-index: 777777;
}



.profile-edit-popup-inner-block {
    position: relative;
    /*margin: 20px 0;*/
    padding: 25px 5px 0 0;

    border-radius: 8px;
    background-color: #FFFFFF;
    border: 1px solid #D0D0D0;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    max-width: 574px;
    margin: 0 auto;
    display: block;
    height: auto;
    max-height: 90vh;
    z-index: 55551;
    text-align: left;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.profile-edit-popup-inner-block.buttons-up {
    padding: 8px 5px 0 0;
}

@media (max-width: 756px) {
    .profile-edit-popup-inner-block{
        max-height: 100vh;
        height: 100%;
        border-radius: 0;
    }
}

.profile-edit-popup-inner-block .profile-edit-popup-header-block {
    width: 96%;
    padding: 0 35px 0 35px;
}

.block-reg-startup-step-2{
    /*padding: 0 30px 0 0;*/
}
.profile-edit-popup-inner-block .popup-descr-text-gray {
    font-size: 14px;
    line-height: 18px;
    color: rgba(8, 8, 8, 0.5);
}
.profile-edit-popup-header-block .title {
    text-align: left;
    font-size: 28px;
    line-height: 1;
    font-weight: 500;
    color: #2F3849;
}
.profile-edit-popup-inner-block .block-right-check-box-reg-startup {
    font-size: 14px;
}
.profile-edit-popup-header-block .close {
    top: 25px;
    left: auto;
    right: 30px;
    transform: scale(1.4);
    opacity: 1;
}
/*.profile-edit-popup-inner-block {*/
    /*!*float: left;*!*/
    /*height: 86px;*/
    /*width: 100%;*/
    /*padding-top: 50px;*/
/*}*/


.profile-edit-popup-inner-block .profile-edit-popup-footer {
    /*float: left;*/
    /*height: 86px;*/
    height: 50px;
    width: 100%;
    padding-top: 15px;
}
.profile-edit-popup-footer:after,
.profile-edit-popup-footer:before{
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
}

.profile-edit-popup-footer .button-reset .block-button-3-0.button-3-0-blue {
    background-color: #FFFFFF;
    margin:0 0 0 10px;
}
.profile-edit-popup-footer .button-reset .block-button-3-0.button-3-0-blue:hover {
    box-shadow: none;
}
.profile-edit-popup-footer .button-reset .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
    font-size: 16px;
    color:#4A4A4A;
}
.profile-edit-popup-footer .block-button-3-0.button-3-0-blue {
    height: 36px;
    float: right;
    margin-bottom: 20px;
}
.profile-edit-popup-footer .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
    line-height: 36px!important;
}
.profile-edit-popup-inner-block .block-cart-list-activity-reg-expert {
    width: 100%;
}
.profile-edit-popup-inner-block .shell-tabs-reg {
    width: 100%;
}
.profile-edit-popup-inner-block .select-block {
    float: left;
    margin-right: 25px;
}
.profile-edit-popup-inner-block .block-cart-load-img-reg-expert {
    padding: 16px 0;
}
.profile-edit-popup-inner-block .block-right-add-experience-cart {
    float: none;
}

.profile-edit-popup-inner-block .block-item-cart-round-reg-startup.bic-bm {
    margin: 0 auto 15px auto;
}
.profile-edit-popup-inner-block .block-list-title-input {
    margin:50px 0 0 0;
    text-align: left;
    max-width: 360px;
}
/*.profile-edit-popup-inner-block .block-left-previosu-add-startup {*/
    /*width: 100%;*/
    /*float: none;*/
/*}*/
/*.profile-edit-popup-inner-block .block-right-previosu-add-startup {*/
    /*float: none;*/
    /*margin-top: 30px;*/
/*}*/

.profile-edit-popup-inner-block .shell-text-area-kyc.bottom-calendars-block {
    flex-wrap: wrap;
}
.profile-edit-popup-inner-block .block-left-add-experience-cart {
    width: 100%;
}
.profile-edit-popup-inner-block .block-left-prof-ide {
    width: 100%;
}
.profile-edit-popup-inner-block .shell-text-area-kyc.bottom-calendars-block .container-inline-checkbox {
    margin: -8px 0 0 10px;
}
.profile-edit-popup-inner-block .block-cart-load-img-reg-expert {
    margin: 0;
}
.profile-edit-popup-inner-block .col-right {
    padding-left: 15px;
}
.profile-edit-popup-inner-block .col-left {
    padding-right: 15px;
}
.profile-edit-popup-inner-block .container-input-range {
    padding-top: 20px;
}
.shell-margin-bottom-100 {
    margin-bottom: 100px;
}
.profile-edit-popup-inner-block .block-right-user-profile-reg-startup {
    float: none;
}
.profile-edit-popup-inner-block .block-right-user-cart-user-profile-reg-startup {
    float:left;
}
.profile-edit-popup-inner-block .block-item-cart-activity-reg-expert {
    overflow-y: hidden;
}
.profile-edit-popup-inner-block .block-list-cart-choose {
    margin: 0;
}
.profile-edit-popup-inner-block .block-cart-choose {
    display: inline-block;
}
.profile-edit-popup-inner-block .block-popup-step-8 .shell-input-reg .block-new-input {
    height: 41px;
}
.font-weight-500 {
    font-weight: 500;
}
.profile-edit-popup-inner-block .select-block .shell-select-reg .block-new-input input {
    height: 32px;
    line-height: normal;
}
.profile-edit-popup-inner-block .block-left-list-input-email .block-select-reg {
    width: 100%;
}




.profile-edit-popup-inner-block .block-add-more-team .block-left-list-input-email .block-input-reg {
    max-width: 100%;
}
.profile-edit-popup-inner-block .block-add-more-team .block-select-reg p.title-input-reg,
.profile-edit-popup-inner-block .block-add-more-team .block-input-reg p.title-input-reg {
    font-size: 16px;
    color: #000000;
    opacity: 0.75;
}
.profile-edit-popup-inner-block .block-add-more-team .block-right-list-input-email .right-list-title {
    font-size: 24px;
    line-height: 27px;
    font-weight: 500;
    color: rgba(8, 8, 8, 0.8);
}


.profile-edit-popup-inner-block .block-invite-fund-members .block-button-list-input-email {
    margin: 28px 0 0 0;
}
.profile-edit-popup-inner-block .block-invite-fund-members .block-button-list-input-email .block-button-3-0.button-3-0-blue {
    height: 40px;
}
.profile-edit-popup-inner-block .block-invite-fund-members .block-button-list-input-email .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
    line-height: 40px;
}
.profile-edit-popup-inner-block .block-invite-fund-members .block-button-3-0.button-3-0-border {
    border: 1px solid #596377;
    border-radius: 4px;
}
.profile-edit-popup-inner-block .block-invite-fund-members .block-button-3-0.button-3-0-border p.block-button-3-0-title {
    font-size: 14px;
    font-weight: 500;
    color: #2F3849;
}
.profile-edit-popup-inner-block .block-invite-fund-members .block-list-first-item-select .block-item-mail-more {
    height: 38px;
}
.profile-edit-popup-inner-block .block-invite-fund-members .block-item-mail-more p.title-item-mail-more {
    line-height: 34px;
}
.profile-edit-popup-inner-block .block-invite-fund-members .block-item-mail-more .block-button-delete-mail-more {
    margin-top: -5px;
}
input[type="file"]{
    display: none;
}

div.error.textarea-error,
p.error.textarea-error {
    color: #E43A70;
    font-family: "Stratos LC";
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    margin: 4px 0 0 0;
    padding: 0 0 0 16px;
    text-align: left !important;
}

.profile-edit-popup-inner-block .work-experience-location .block-top-select img {
    top:15px;
}
.
.profile-edit-popup-inner-block .container-input-range .input-range {
    margin-left: 10px;
}
.profile-edit-popup-inner-block .block-body-select .block-list-item-select .block-item-select {
    margin-top: -1px;
}
.block-user-basic-information .block-cart-load-img-reg-expert {
    padding: 32px 0;
    border-top: 1px solid rgba(0,0,0,0.15);
    border-bottom: 1px solid rgba(0,0,0,0.15);
    box-shadow: none;
    border-radius: unset;
}
.block-user-basic-information .block-platform-newsletter {
    border-top: 1px solid rgba(0,0,0,0.15);
}
.text-newsletter-descr {
    font-size: 14px;
    line-height: 18px;
    color: #000;
    opacity: 0.75;
}
.block-user-basic-information .old-pass-block {
    width: calc(50% - 13px);
}
.block-user-basic-input-title {
    font-size: 16px;
    line-height: 24px;
    color: #000;
    opacity: 0.75;
}

.margin-left-26 {
    margin-left: 26px;
}


@media screen and (max-width: 500px) {
    .block-accelerator-members .button-add-members {
        margin: 15px 0 0 0!important;
    }
    .block-accelerator-members .accelerator-team-descr-block {
        margin-top: 55px;
    }
}
@media screen and (max-width: 462px) {
    .block-user-basic-information .old-pass-block {
        width: 100%;
    }
    .block-user-basic-information .margin-left-26 {
        margin-left: 0;
    }
}
@media screen and (max-width: 440px) {

    .block-accelerator-members {
        width: 100%!important;
    }
    .profile-edit-popup-inner-block {
        padding-top: 20px;
    }
    .profile-edit-popup-inner-block.little-title {
        padding-top: 10px;
    }
    .profile-edit-popup-inner-block .title{
        font-size: 25px;
        max-height: 40px;
    }
    .profile-edit-popup-inner-block.little-title .title{
        max-height: 50px;
    }
}