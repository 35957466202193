.parameters-dashboard-container {
  padding-top: 22px;
}
.pay-banner-container {
  padding: 0 0 50px 0;
}

.profile-dashboard-main-title {
  font-size: 28px;
  line-height: 28px;
  color: #2F3849;
  font-weight: 500;
  margin-top: 16px;
}
.profile-content-wrapper {
  padding-left: 22px;
}

.expert-parameters-container {
  padding: 28px 0 70px 0;
  .expert-param-wrapper {
    margin-bottom: 16px;
    &.button-wrapper {
      padding-top: 15px;
      .add-parameter-button {
        width: 200px!important;
      }
    }
  }
}
.parameters-dashboard-title {
  font-size: 22px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}
.parameters-dashboard-descr {
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 18px;
}

@media (max-width: 861px) {
  .pay-banner-container {
    padding: 0 16px 80px 16px;
  }
}
@media (max-width: 756px) {
  .profile-content-wrapper {
    padding-left: 0;
  }
  .team-wrapper {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
}
@media (max-width: 576px) {
  .team-wrapper {
    max-width: 250px;
  }
}