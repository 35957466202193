.block-team-2-0 {
    background-color: #FDFDFD;
    margin: 0 auto;
    overflow-x: hidden;
}
.list-of-projects .one-project {
    display: flex;
    overflow: hidden;
    padding: 150px 0;
    flex-direction: column;
    justify-content: center;
    font-family: 'Stratos LC';
    color: #ffffff;
    box-shadow: inset 0px 0px 0px 10000px rgba(0,0,0,0.3);
    background-size: cover!important;
    border-bottom: 5px solid #FFFFFF;
    font-weight: 300;
    position: relative;
}
.one-project .col-7,
.one-project .col-5,
.one-project .col-4,
.one-project .col-6,
.one-project .col-3{
    float: left;
}
.one-project .col-7 {
    padding-right: 70px;
}
.one-project .block-img{
    width: 75px;
    float: left;
}
.one-project .block-img img{
    width: 100%;
}
.one-project .block-name {
    width: calc(100%  - 100px);
    float: right;
}

.one-project .block-name .bn-1 {
    font-size: 20px;
}
.one-project .block-name .bn-1 a{
    color: #ffffff;
    text-decoration: none;
}
.one-project .block-name .bn-1 a:hover{
    cursor: pointer;
    text-decoration: underline;
}
.one-project .block-name .bn-2 {
    font-size: 42px;
    font-weight: 400;
}
.one-project .block-descr {
    font-size: 17px;
    padding: 10px 0 20px 0;
    font-weight: 300;
}
.one-project .list-of-buttons div.block-button-2-0{
    margin-right: 10px;
}


.one-project .right-table {
    margin-top: 20px;
}
.one-project .right-table div{
    padding-left: 0;
}
.one-project .right-table .rt-1{
    font-size: 13px;
    font-weight: 300;
}
.one-project .right-table .rt-2{
    font-size: 23px;
    padding-top: 10px;
    font-weight: 400;
}
.one-project .right-table .col-3,
.one-project .right-table .col-6 {
    padding-left: 0;
}
.one-project .block-header-body-right-ends-in {
    padding-top: 10px;
}
.one-project .create-date {
    font-size: 11px;
    padding-top: 20px;
}
.list-top-projects  {
    font-weight: 300;
}
.list-top-projects .ltp-top {
    padding: 100px 0 20px 0;
}
.list-top-projects .ltp-top.fix-p-top {
    padding-top: 0;
}
.list-top-projects .ltp-top .ltp-left {
    float: left;
    font-size: 46px;
    width: 80%;
    font-weight: 400;
}
.list-top-projects .ltp-top .ltp-left .count-project-nominated {
    color: #9B9B9B;
    font-size: 15px;
    position: relative;
    top: -25px;
}

.list-top-projects .ltp-top .ltp-right {
    float: right;
    line-height: 52px;
}
.ltp-right .block-button-2-0{
    vertical-align: bottom;
    margin: 0 0 10px 0;
}
.list-top-projects .ltp-descr {
    font-size: 15px;
    width: 80%;
    padding-bottom: 30px;
    color: #4A4A4A;
}
.rd-manifest {
    padding: 90px 0 50px 0;
}
.rd-manifest .rd-title{
    font-size: 48px;
    padding-bottom: 20px;
}
.rd-manifest .rd-descr{
    font-size: 15px;
    color: #4A4A4A;
    font-weight: 300;
}

.ltp-table .th-header {
    border-bottom: 1px solid #CFCFCF;
    color: #b5b5b5;
    font-size: 12px;
    padding-bottom: 5px;
}
.ltp-table .th-1,
.ltp-table .th-2,
.ltp-table .th-3,
.ltp-table .th-4,
.ltp-table .th-5,
.ltp-table .th-6,
.ltp-table .th-7,
.ltp-table .th-8,
.ltp-table .th-9 {
    display: inline-block;
    padding-right: 15px;
}
.ltp-table .th-1 {
    width: 19%;
}
.ltp-table .th-2 {
    width: 6%;
}
.ltp-table .th-3 {
    width: 9%;
}
.ltp-table .th-4 {
    width: 14%;
}
.ltp-table .th-5 {
    width: 11%;
}
.ltp-table .th-6 {
    width: 9%;
}
.ltp-table .th-7 {
    width: 6%;
}
.ltp-table .th-8 {
    width: 13%;
}
.ltp-table .th-9 {
    width: 12%;
    padding-right: 0;
}
.ltp-table .th-1-2 {
    width: 22%;
}
.th-8.descr {
    font-size: 13px;
    line-height: 15px;
    word-wrap: break-word;
}

.ltp-table .table-body {
    padding: 10px 0;
    font-weight: 300;
}
.ltp-table .table-body .tr-rable {
    border-bottom: 1px solid #CFCFCF;
    height: 85px;
    color: #4A4A4A;
    display: flex;
    align-items: center;
}
.th-11 {
    width: 30%;
    float: left;
}
.th-11 img{
    width: 100%;
}
.th-12 {
    width: 70%;
    float: left;
    padding-left: 5px;
}
.th-12-title {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 5px;
}
.th-12-description {
    font-size: 13px;
    color: #4A4A4A;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    height: 33px;
}
.tr-rable .th-2 {
    display: flex;
    justify-content: space-between;
}
.iau-1 span{
    color: #00F082;
}
.iau-2 span {
    color: #FA4600;
}
.iau-3 span {
    color: #0028DC;
}
.th-7 img {
    width: 38px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
.table-body .icon-arrow-up,
.table-body .icon-arrow-down,
.table-body .icon-comment {
    display: inline-block;
    width: 10px;
    height: 10px;
}
.th-2 i {
    color: #9B9B9B;
    padding-right: 3px;
}
/*.ltp-right .button-2-0-small p {*/
    /*font-weight: 400!important;*/
/*}*/







/*Mobile*/




.shell-line-container{
    /*overflow: hidden;*/
    width: 100%;
    display: flow-root;

}
.block-float-70-left{
    width: 69%;
    float: left;
}
.block-float-70-right{
    width: 69%;
    float: right;
}
.block-float-60-left{
    width: 59%;
    float: left;
}
.block-float-60-right{
    width: 59%;
    float: right;
}
.block-float-55-left{
    width: 55%;
    float: left;
}
.block-float-55-right{
    width: 55%;
    float: right;
}
.block-float-50-left{
    width: 49%;
    float: left;
}
.block-float-50-right{
    width: 49%;
    float: right;
}

.block-float-40-left{
    width: 39%;
    float: left;
}
.block-float-40-right{
    width: 39%;
    float: right;
}
.block-float-30-left{
    width: 29%;
    float: left;
}
.block-float-30-right{
    width: 29%;
    float: right;
}
.block-float-25-left{
    width: 25%;
    float: left;
}
.block-float-25-right{
    width: 25%;
    float: right;
}
.block-float-20-left{
    width: 20%;
    float: left;
}
.block-float-20-right{
    width: 20%;
    float: right;
}


.block-logo-name-start-fund{
    width: 100%;overflow: hidden;
}
.block-img-logo-name-start-fund{
    width: 75px;
    height: 75px;
    margin: 0 20px 0 0;
    float: left;
}
.block-img-logo-name-start-fund img{
    width: 75px;
    height: 75px;
}
.block-title-logo-name-start-fund{
    width: calc(100% - 95px);
    float: left;
    overflow: hidden;
}
.title-small-start-fund-name{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 20px;	line-height: 25px; margin: auto 0;  font-weight: 300;
    white-space: nowrap; overflow: hidden;text-overflow: ellipsis;
}
.title-big-start-fund-name{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 42px;	font-weight: 400;	line-height: 56px; margin: 0;
    white-space: nowrap; overflow: hidden;text-overflow: ellipsis;
}

.block-description-start-fund{
    width: 100%;
    margin: 10px 0;
}
.title-small-description-start-fund{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 17px;	line-height: 21px; margin: 0; font-weight: 300; text-align: left;
}
.block-list-tags-start-fund{
    margin: 10px 0 20px 0;
}
.block-list-tags-start-fund .block-tag-2-0 a p{
    color: #FFFFFF!important;
}




.block-progress-bar-start-fund{}
.block-title-progress-bar-start-fund{}
.title-small-progress-bar-start-fund{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px; font-weight: 300; margin: 0;
    white-space: nowrap; overflow: hidden;text-overflow: ellipsis;
}
.block-progress-bar-view-start-fund{}


.block-info-pool-min-max-participation-start-fund{
    overflow: hidden;
    margin: 15px 0 0 0;
}
.title-small-info-pool-min-max-start-fund{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 13px;	line-height: 16px; margin: 0; font-weight: 300;
    white-space: nowrap; overflow: hidden;text-overflow: ellipsis;
}
.title-big-info-pool-min-max-start-fund{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 18px;	line-height: 29px; margin: 5px 0 0 0; font-weight: 400;
    white-space: nowrap; overflow: hidden;text-overflow: ellipsis;
}
.title-creation-person-start-fund{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 11px;	line-height: 13px;margin: 10px 0 0 0; font-weight: 300;
    white-space: nowrap; overflow: hidden;text-overflow: ellipsis;
}
.block-list-button-start-fund{

}

.block-list-button-start-fund .block-button-2-0{
    margin: 0 10px 0 0;
}
.block-item-grid-start-fund:hover{
    box-shadow: 0 0px 10px 0 rgba(0,0,0,0.3);
}
.row-DAO{
    overflow: hidden;
}

@media (min-width: 1200px){
    .container-DAO{
        width: 1140px;
        margin: 0 auto;
        /*overflow: hidden;*/
    }
    .block-list-button-start-fund{
        margin: 20px 0 0 0;
    }
}

@media (max-width: 1199px){
    .container-DAO{
        width: 780px;
        margin: 0 auto;
        /*overflow: hidden;*/
    }
    .block-info-pool-min-max-participation-start-fund{
        text-align: center;
        width: 100%;
    }
    .block-left-pool-info-min-max{
        width: 49%;
        display: table;
        float: left;
        overflow: hidden;
        margin: 0 0 15px 0;
    }
    .block-center-pool-info-min-max{
        width: 49%;
        display: table;
        float: right;
        overflow: hidden;
        margin: 0 0 15px 0;
    }
    .block-right-pool-info-min-max{
        width: 80%;
        display: table;
        float: none;
        margin: 0 auto;
        overflow: hidden;
    }
    .one-project .block-header-body-right-ends-in{
        margin: 0 auto;
        display: table;
    }
    .title-creation-person-start-fund{
        text-align: center;
    }
}

@media (max-width: 800px){
    .container-DAO{
        width: 540px;
        margin: 0 auto;
    }
    .block-column-left-start-fund{
        width: 100%;
        float: none;
        margin: 0 0 20px 0;
    }
    .block-column-right-start-fund{
        width: 100%;
        float: none;
        margin: 0 0 20px 0;
    }
    .block-img-logo-name-start-fund{
        width: 40px;
        height: 40px;
        margin:  0 10px 0 0;
    }
    .block-img-logo-name-start-fund img{
        width: 40px;
        height: 40px;
    }
    .title-small-progress-bar-start-fund{
        text-align: center;
    }
    .block-title-logo-name-start-fund{
        width: calc(100% - 50px);
    }
    .title-big-start-fund-name{
        font-size: 30px;
        line-height: 35px;
    }
    .title-small-start-fund-name{
        font-size: 13px;
        line-height: 16px;
    }
    .title-small-description-start-fund{
        font-size: 13px;
        line-height: 19px;
    }



    .block-progress-bar-view-start-fund .block-top-progress-bar-fund p{
        text-align: center;
        font-size: 26px;
    }
    .block-list-button-start-fund{}
    .block-list-button-start-fund .button-2-0-green{
        width: 100%;
        display: block;
        margin: 0 0 10px 0;
    }
    .block-list-button-start-fund .button-2-0-trans-white:nth-child(1){
        display: block!important;
        float: left !important;
        width: calc(50% - 5px)!important;
        margin: 0!important;
    }
    .block-list-button-start-fund .button-2-0-trans-white{
        display: block!important;
        float: left !important;
        width: calc(50% - 5px)!important;
        margin: 0!important;

    }
    .block-list-button-start-fund .btn-share-fund .block-button-2-0{
        margin: 0;
        width: 100%!important;
    }
    .block-list-button-start-fund .btn-share-fund .block-share-popup{
        width: 100%!important;
    }
    .block-list-button-start-fund .btn-share-fund {
        display: block!important;
        float: right!important;
        width: calc(50% - 5px)!important;
        margin: 0!important;
    }
    .one-project .block-header-body-right-ends-in{
        margin: 0 auto;
        display: table;
    }
    .title-creation-person-start-fund{
        text-align: center;
    }
    .block-info-pool-min-max-participation-start-fund{
        text-align: center;
        width: 100%;
    }
    .block-left-pool-info-min-max{
        width: 30%;
        display: table;
        float: left;
        overflow: hidden;
        margin: 0 calc(10% / 2) 0 0;
    }
    .block-center-pool-info-min-max{
        width: 30%;
        display: table;
        float: left;
        overflow: hidden;
        margin: 0 calc(10% / 2) 0 0;
    }
    .block-right-pool-info-min-max{
        width: 30%;
        display: table;
        float: right;
        margin: 0;
        overflow: hidden;
    }



}

@media (max-width: 600px){
    .container-DAO{
        width: 300px;
        margin: 0 auto;
        /*overflow: hidden;*/
    }
    .block-left-pool-info-min-max{
        width: 49%;
        display: table;
        float: left;
        overflow: hidden;
        margin: 0 0 15px 0;
    }
    .block-center-pool-info-min-max{
        width: 49%;
        display: table;
        float: right;
        overflow: hidden;
        margin: 0 0 15px 0;
    }
    .block-right-pool-info-min-max{
        width: 80%;
        display: table;
        float: none;
        margin: 0 auto;
        overflow: hidden;
    }
}





/*start-fund grid*/


.shell-grid-start-fund{
    overflow: hidden;
}
.block-grid-start-fund{
    overflow: hidden;
    padding: 0 0 7px 0;
}
.block-header-grid-start-fund{
    border-bottom: 1px solid #CFCFCF;
    padding: 0 0 3px 0;
    overflow: hidden;
}

.block-column-grid-start-fund{
    float: left;
overflow: hidden;

}


.block-column-project-grid-start-fund{width: 19%;}
.block-column-votes-grid-start-fund{width: 6%;}
.block-column-category-grid-start-fund{width: 9%;}
.block-column-raised-grid-start-fund{width: 14%;}
.block-column-target-grid-start-fund{width: 11%;}
.block-column-token-sale-grid-start-fund{width: 10%;}
.block-column-added-grid-start-fund{width: 6%;}
.block-column-comments-grid-start-fund{width: 13%;}
.block-column-your-vote-grid-start-fund{width: 12%;}


.title-column-grid-start-fund{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 13px;	line-height: 16px; margin: 0; font-weight: 300;
    white-space: nowrap; overflow: hidden;text-overflow: ellipsis;
}
.title-column-grid-start-fund.active {
    font-weight: 500;
}


.block-body-grid-start-fund{

}
.block-item-grid-start-fund{
    overflow: hidden;
    border-bottom: 1px solid #CFCFCF;
    transition: .3s;
    cursor: pointer;
}
.block-body-grid-start-fund .block-column-grid-start-fund{
    height: 80px;
}

.block-logo-name-description-grid-start-fund{
    margin: 10px 0;
}
.block-logo-grid-start-fund{
    height: 60px;	width: 60px;
    float: left;
}
.block-logo-grid-start-fund img{
    height: 60px;	width: 60px;	background-color: #FFFFFF;	box-shadow: 0 1px 3px 0 rgba(0,0,0,0.3);
}
.block-name-description-grid-start-fund{
    width: calc(100% - 70px);
    float: right;
}
.block-name-description-grid-start-fund .name-grid-start-fund{
    color: #000000;
    font-family: "Stratos LC";
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    margin: 0;
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.block-name-description-grid-start-fund .description-grid-start-fund{
    font-family: "Stratos LC";
    color: #000000;
    font-size: 13px;
    line-height: 15px;
    font-weight: 300;
    margin: 0;
    height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px 0 0;
}


.block-up-down-votes-grid-start-fund{

    margin: 30px 0;
}
.block-up-votes-grid-start-fund{
    display: inline-block;
    margin: 0 5px 0 0;
    cursor: pointer;
}
.block-up-votes-grid-start-fund:hover i{
    color: #00F082;
}
.block-up-votes-grid-start-fund i{
    color: #9B9B9B;
    display: inline-block;
    vertical-align: text-top;
    transition: .3s;
}
.block-up-votes-grid-start-fund p{
    color: #00F082;
    font-family: "Stratos LC";	font-size: 14px;	line-height: 18px; font-weight: 300; margin: 0 0 0 5px; display: inline-block;
    white-space: nowrap; overflow: hidden;text-overflow: ellipsis; width: 16px;
}
.block-down-votes-grid-start-fund{
    display: inline-block;
    cursor: pointer;
}
.block-down-votes-grid-start-fund:hover i{
    color: #FA4600;
}
.block-down-votes-grid-start-fund i{
    color: #9B9B9B;
    display: inline-block;
    vertical-align: text-top;
    transition: .3s;
}
.block-down-votes-grid-start-fund p{
    color: #FA4600;
    font-family: "Stratos LC";	font-size: 14px;	line-height: 18px; font-weight: 300; margin: 0 0 0 5px; display: inline-block;
    white-space: nowrap; overflow: hidden;text-overflow: ellipsis; width: 16px;
}


.title-grid-start-fund{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	line-height: 18px;font-weight: 300; margin: 0;
    display: flex;
    margin: auto 0 !important;
}


.block-img-added-by-grid-start-fund{
    height: 38px;	width: 38px; margin: 21px 0;
}
.block-img-added-by-grid-start-fund img{
    height: 38px;	width: 38px;
    border-radius: 50%;
}

.block-column-grid-start-fund .shell-center-top-bottom-flex{
    display: flex;
    height: 80px;
}
.description-comments-grid-start-fund{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 13px;	line-height: 15px; font-weight: 300; margin: 0;
    display: flex;
    margin: auto 0 !important;
}


.block-list-button-grid-start-fund{
    margin: 24px 0;
}
.block-list-button-grid-start-fund .block-button-2-0{}
.block-list-button-grid-start-fund .button-2-0-green{
    float: left;
}
.block-list-button-grid-start-fund .button-2-0-red{
    float: right;
}


@media (min-width: 1200px){
    .block-header-grid-start-fund{
        display: block;
    }
}

@media (max-width: 1199px){
    .block-header-grid-start-fund{
        display: none;
    }
    .block-column-project-grid-start-fund{
        width: 100%;
        float: none;
        border-bottom: 1px solid #CFCFCF;
        border-top: 1px solid #CFCFCF;
        height: 80px!important;
        padding: 0!important;
    }
    .block-column-project-grid-start-fund:before{
        display: none;
    }
    .block-column-votes-grid-start-fund{width: 49%; float: left}
    .block-column-category-grid-start-fund{width: 49%; float: right}
    .block-column-raised-grid-start-fund{width: 49%; float: left}
    .block-column-target-grid-start-fund{width: 49%; float: right}
    .block-column-token-sale-grid-start-fund{width: 49%; float: left}
    .block-column-added-grid-start-fund{width: 49%; float: right}
    .block-column-comments-grid-start-fund{width: 49%; float: left}
    .block-column-your-vote-grid-start-fund{width: 49%; float: right}


    .block-body-grid-start-fund .block-column-grid-start-fund{
        position: relative;
    }
    .block-body-grid-start-fund .block-column-grid-start-fund:before{
        content: attr(title-column-grid-start-fund);
        color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;
        margin: 0;
        position: absolute;
        left: 0;
        top: 5px;
    }

    .block-column-grid-start-fund .shell-center-top-bottom-flex{
        height: 28px;
    }
    .block-body-grid-start-fund .block-column-grid-start-fund{
        height: 28px;
        padding: 0 0 0 80px;
    }
    .block-item-grid-start-fund{
        padding: 0 0 20px 0;
    }
    .block-up-down-votes-grid-start-fund{
        margin: 4px 0;
    }
    .block-img-added-by-grid-start-fund{
        margin: 0;
    }
    .block-img-added-by-grid-start-fund{
        width: 28px;
        height: 28px;
        margin: 0;
    }
    .block-img-added-by-grid-start-fund img{
        width: 28px;
        height: 28px;
    }

    .block-list-button-grid-start-fund{
        margin: 6px 0;
    }
    .block-list-button-grid-start-fund .block-button-2-0{}
    .block-list-button-grid-start-fund .button-2-0-green{
        float: none;margin: 0 10px 0 0;
    }
    .block-list-button-grid-start-fund .button-2-0-red{
        float: none;
    }
    .block-list-button-grid-start-fund .block-button-2-0{
        height: 23px!important;
    }
    .block-list-button-grid-start-fund .block-button-2-0 p{
        line-height: 23px!important;
    }
}

@media (max-width: 800px){

}

@media (max-width: 600px){
    .block-item-grid-start-fund{
        border: 0;
        padding: 10px 0;
    }
    .block-header-grid-start-fund{
        display: none;
    }
    .block-column-project-grid-start-fund{
        width: 100%;
        float: none;
        border-bottom: 1px solid #CFCFCF;
        border-top: 1px solid #CFCFCF;
        height: 80px!important;
        padding: 0!important;
    }
    .block-column-project-grid-start-fund:before{
        display: none;
    }
    .block-column-votes-grid-start-fund{width: 100%; float: none}
    .block-column-category-grid-start-fund{width: 100%; float: none}
    .block-column-raised-grid-start-fund{width: 100%; float: none}
    .block-column-target-grid-start-fund{width: 100%; float: none}
    .block-column-token-sale-grid-start-fund{width: 100%; float: none}
    .block-column-added-grid-start-fund{width: 100%; float: none}
    .block-column-comments-grid-start-fund{width: 100%; float: none}
    .block-column-your-vote-grid-start-fund{width: 100%; float: none}


    .block-body-grid-start-fund .block-column-grid-start-fund{
        position: relative;
    }
    .block-body-grid-start-fund .block-column-grid-start-fund:before{
        content: attr(title-column-grid-start-fund);
        color: #9B9B9B;	font-family: "Stratos LC";	font-size: 12px;	line-height: 15px;
        margin: 0;
        position: absolute;
        left: 0;
        top: 6px;
    }

    .block-column-grid-start-fund .shell-center-top-bottom-flex{
        height: 28px;
    }
    .block-body-grid-start-fund .block-column-grid-start-fund{
        height: 28px;
        padding: 0 0 0 80px;
    }
    .block-img-added-by-grid-start-fund{
        width: 23px;
        height: 23px;
    }
    .block-img-added-by-grid-start-fund img{
        width: 23px;
        height: 23px;
    }
    .block-up-down-votes-grid-start-fund{
        margin: 4px 0;
    }
    .block-img-added-by-grid-start-fund{
        margin: 2px 0;
    }


    .block-list-button-grid-start-fund{
        margin: 2px 0;
    }
    .block-list-button-grid-start-fund .block-button-2-0{
        height: 23px!important;
    }
    .block-list-button-grid-start-fund .block-button-2-0 p{
        line-height: 23px!important;
    }
    .block-list-button-grid-start-fund .button-2-0-green{
        float: none;margin: 0 10px 0 0;
    }
    .block-list-button-grid-start-fund .button-2-0-red{
        float: none;
    }
}

.header-container-footer {
    min-height: calc(100vh - 244px);
    padding: 20px 20px;
    margin-top: 54px;
}




/*adviser page*/
.advising {
    font-family: 'Stratos LC';
}
.advising .back {
    padding: 15px;
    transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    /* IE 9 */
    -webkit-transform: rotateY(180deg);
    /* Chrome, Safari, Opera */
}

.advising .back p {
    clear: both;
    text-align: center;
    font-size: 12px;
    line-height: 1.3;
    margin-top: 5px;
}

.advising .block-card {
    width: 100%;
    display: inline-block;
    text-align: center;
}

.advising .card-container {
    -webkit-perspective: 800px;
    -o-perspective: 800px;
    perspective: 800px;
    display: inline-block;
    margin: 7px;
}
.advising .card-container .work-experience-block {
    max-height: 220px;
    overflow: hidden;
}
.advising .card-container:hover .card, .advising .card-container.hover .card {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.advising .card-container:hover .card .front, .advising .card-container.hover .card .front {
    z-index: 1;
}

.advising .card-container:hover .card .back, .advising .card-container.hover .card .back {
    z-index: 2;
}

.advising .card-container.static:hover .card, .advising .card-container.static.hover .card {
    -webkit-transform: none;
    transform: none;
}

.advising .card {
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: relative;
}

.advising .front, .advising .back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.advising .front {
    display: inline-block;
    position: relative;
    width: 255px;
    height: 330px;
    overflow: hidden;
    background: #fff;
    padding: 15px;
    z-index: 2;
}

.advising .front h3 {
    position: absolute;
    /*font-size: 2.3rem;*/
    bottom: 40px;
    color: #0028DC;
    margin-bottom: 20px;
    font-weight: normal;
}

.advising .front p {
    position: absolute;
    bottom: 20px;
    text-align: left;
    color: #000000;
}

.advising .front .flip {
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.advising .back {
    background-color: #000000;
    width: 255px;
    height: 330px;
    color: #ffffff;
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
    z-index: 1;
}

.advising .back .flip {
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;
}

.advising .back h3, .advising .back p, .advising .back i {
    text-align: left;
}

.advising .back h3 {
    position: absolute;
    /*font-size: 2.3rem;*/
    bottom: 40px;
    margin-bottom: 20px;
    font-weight: normal;
}

.advising .back .from {
    position: absolute;
    bottom: 20px;
}

.advising i {
    margin-top: 10px;
    /*font-size: 1.8rem;*/
    margin-bottom: 10px;
    display: block;
    color: #fff;
}

.advising .card-container, .advising .front, .advising .back {
    width: 266px;
    height: 350px;
}

.advising .avatar {
    width: 216px;
    height: 216px;
    display: block;
    margin: auto;
    float: none;
    clear: both;
    border-radius: 50%;
    margin-bottom: 0;
    cursor: auto;
    filter: grayscale(100%);
}

.block-join-our-community {
    text-align: center;
    padding: 30px 0;
}



.page-experts-profile{
    top: 0;
    overflow: hidden;
    /*padding: 60px 0 0 0;*/
    background-color: #ffffff;
    min-height: 100vh;
}
.page-experts-profile .block-new-top-menu-rocket-dao{
    top: 0;
}



.shell-profile-experts{
    width: 100%;
    overflow: hidden;
}
.block-profile-experts{
    width: 100%;
    overflow: hidden;
}
.block-profile-experts .title{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 19px;	letter-spacing: -0.41px;	line-height: 24px; margin: 0; font-weight: 300;
}

.block-list-input-profile-experts{
    width: 100%;
    overflow: hidden;
    margin: 40px 0 0 0;
}
.block-button-profile-experts{
    overflow: hidden;
    margin: 20px 0 0 0;
    width: 100%;
}



.block-top-title-search-experts-comming{
    overflow: hidden;
}
.block-top-title-search-experts-comming .block-top-title-button-forum{
    margin: 60px 0 0 0;
}
.block-top-title-search-experts-comming .block-top-title-button-forum .block-button-2-0{
    border-radius: 3px;
}
.block-top-title-search-experts-comming .block-top-title-button-forum .block-button-2-0 p{
    font-weight: 400!important;
}
.block-top-title-search-experts-comming .block-input-DAO-new-icon{
    /*margin: 63px 0 0 0!important;*/
}
.block-top-title-search-experts-comming .block-input-new-icon{}
.block-top-title-search-experts-comming .block-input-new-icon input{
    padding: 0 26px 0 0!important;
}
.block-top-title-search-experts-comming .block-input-new-icon i{
    left: auto;
    right: 3px;
}

.card-block-links a {
    float: left;
    margin-right: 10px;
}




.multiselect {
    width: 100%;
    position: relative;
    background-color: #fff;
}

.selectBox {
    position: relative;

}

.selectBox select {
    width: 100%;
    height: 32px;
    line-height: 32px;
    border-radius: 0;
    font-family: "Stratos LC";
    font-size: 16px;
    color: #4A4A4A;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    padding-left: 5px;
}

.overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-bottom: 1px solid #979797;
}

#checkboxes-1,
#checkboxes-2,
#checkboxes-3 {
    display: none;
    /*border: 1px #F2F2F2 solid;*/
    position: absolute;
    width: 100%;
    background-color: #F2F2F2;
    z-index: 100;
    height: 128px;
    overflow-y: auto;
}


#checkboxes-1 label,
#checkboxes-2 label,
#checkboxes-3 label{
    display: block;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-family: "Stratos LC";
    font-weight: 300;
    padding-left: 25px;

}

#checkboxes-1 label:hover,
#checkboxes-2 label:hover,
#checkboxes-3 label:hover {
    background-color: #0028DC;
    color: #ffffff;
}
.multiselect input[type=checkbox] {
    display: inline-block;
    margin-right: 5px;
}


.skills-checkbox{
    /*padding-left: 25px;*/
}
.skills-checkbox input[type=checkbox] {
    display: none;
}
.skills-checkbox input[type=checkbox] + label {
    display: inline;
    padding: 0 0 0 25px;
    background: url(./../../images/icon/icon_checkbox_unchecked.png) no-repeat;
    background-size: 16px 15px;
    background-position: 5px 7px;
    cursor: pointer;

}
.skills-checkbox input[type=checkbox]:checked + label {
    height: 24px;
    width: 100%;
    display: inline;
    padding: 0 0 0 25px;
    background: url(./../../images/icon/icon_checkbox_checked.png) no-repeat;
    background-size: 16px 15px;
    background-position: 5px 7px;
    font-weight: normal;
    /*background-color: #0028DC;*/
    /*color: #ffffff;*/
}
.selectBox.block-list-countries .Select-arrow-zone {
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
}
.selectBox.block-list-countries .Select-arrow-zone:before {
    content: '';
    width: 14px;
    height: 2px;
    background: #FFFFFF;
    position: absolute;
    top: 15px;
    left: 14px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.selectBox.block-list-countries .Select-arrow-zone:after {
    content: '';
    width: 14px;
    height: 2px;
    background: #FFFFFF;
    position: absolute;
    top: 15px;
    left: 4px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.selectBox.block-list-countries.isSelected .Select-arrow-zone:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.selectBox.block-list-countries.isSelected .Select-arrow-zone:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.shell-center-top-bottom-flex .description{
    display: block;
}
.shell-center-top-bottom-flex .description .inline-span::after {
    content: '; ';
}
.shell-center-top-bottom-flex .description .inline-span:last-child::after {
    content: '';
}
.list-tags-expert .inline-span::after {
    content: '; ';
}
.list-tags-expert .inline-span:last-child::after {
    content: '';
}





.shell-padding-bottom-100{
    padding: 0 0 100px 0;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
}


.block-list-countries.fix-expert .Select-value-label {
    color: #4A4A4A!important;
    padding-left: 5px;
}
.block-list-countries.fix-expert .Select-arrow-zone {
    position: absolute;
    top: 3px;
    right: 0;
    width: 32px;
    height: 32px;
}
.block-input-DAO-new-select .fix-expert .Select-arrow-zone:before {
    content: '';
    width: 14px;
    height: 2px;
    background: #FFFFFF;
    position: absolute;
    top: 15px;
    left: 14px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}


.block-input-DAO-new-select .fix-expert .Select-arrow-zone:after {
    content: '';
    width: 14px;
    height: 2px;
    background: #FFFFFF;
    position: absolute;
    top: 15px;
    left: 4px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}


.shell-top-page-knowlege-base{
    /*overflow: hidden;*/
    width: 100%;
    height: 60px;
    margin: 20px 0 30px 0;
}
.block-top-page-knowlege-base{
    /*overflow: hidden;*/
    width: 100%;
    height: 60px;
}
.block-top-page-knowlege-base p{
    color: #000000;	font-family: "Stratos LC";	font-size: 48px;	letter-spacing: -1.03px;	line-height: 60px;
    float: left;font-weight: 400;margin: 0;
}
.block-top-page-knowlege-base p a{
    margin: 0 ;
    cursor: pointer;

}
.block-top-page-knowlege-base p a i{
    width: 40px;
    height: 40px;
    line-height: 40px;
    margin: 10px 0 10px 0;
    text-align: left;
    color: #0028DC;
    font-size: 32px;
}


.search-title-link{
    display: block;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 19px;	letter-spacing: -0.24px;	line-height: 20px;
    margin: 20px 0 0 0;
}
.search-title-link a.link-search{
    color: #0028DC;	font-family: "Stratos LC";	font-size: 19px;	letter-spacing: -0.24px;	line-height: 20px;
    margin: 0 0 0 20px;
    position: relative;
}
.search-title-link a.link-search span.count-search{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.17px;	line-height: 20px;
    position: relative;
    top: -10px;
}

.block-top-page-knowlege-base .block-search-input-DAO{
    width: 360px;
    float: right;
    margin: 12px 0;
    transition: .3s;
    position: relative;
}
.block-top-page-knowlege-base .block-search-input-DAO.active{
    width: calc(100% - 450px);
}
.block-list-tag-search{
    position: absolute;
    right: 5px;
    top: 5px;
    height: 24px;
}


.block-search-input-DAO{
    height: 36px;
    /*border-radius: 4px;*/
    /*box-shadow: 0 0px 3px 0 rgba(0,0,0,0.37);*/
    border-bottom: 1px solid #E0E0E0;
    /*padding:  0 10px;*/
    box-sizing: border-box;
}
.block-search-input-DAO i{
    width: 36px;
    height: 36px;
    float: left;
    line-height: 36px;
    color: #4A4A4A;
    text-align: center;
    font-size: 15px;
}

.block-search-input-DAO.active input{
    width: 157px;
}
.block-search-input-DAO .block-tag-search i{
    line-height: 24px;
}

.block-tag-search{
    display: inline-block;
    height: 24px;
    border-radius: 3px;
    background-color: #0028DC;
    cursor: pointer;
    margin: 0 0 0 5px;
}

.block-tag-search i{
    width: 24px;
    height: 24px;
    float: left;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
    font-size: 10px;

}
.block-tag-search p.title{
    height: 24px;
    float: left;
    display: inline-block;
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.26px;	line-height: 24px;
    font-weight: 300; margin: 0;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.block-tag-search span.close{
    width: 24px;
    height: 24px;
    float: left;
    position: relative;
}
.block-tag-search span.close:after{
    content: '';
    position: absolute;
    width: 14px;
    height: 1px;
    background: #FFFFFF;
    transform: rotate(45deg);
    top: 12px;
    left: 5px;
    transition: .3s;
}
.block-tag-search span.close:before{
    content: '';
    position: absolute;
    width: 14px;
    height: 1px;
    background: #FFFFFF;
    transform: rotate(-45deg);
    top: 12px;
    right: 5px;
    transition: .3s;
}
.block-tag-search span.close:hover:after,.block-tag-search span.close:hover:before{
    background: #FA4600;
}


.shell-menu-down-sticky{
    width: 100%;
    overflow: hidden;
    height: 54px;
    background-color: rgba(255,255,255,0.8);
    z-index: 9;
    position: sticky;
    top: 0;
    overflow: visible;
    border-bottom: 1px solid #BBBBBB;
}

.block-menu-down-sticky{
    width: 1140px;
    margin: 0 auto;
    height: 54px;
}
.block-left-menu-down-sticky{
    float: left;
    height: 54px;
}
.block-left-menu-down-sticky p.title{
    color: #000000;	font-family: "Stratos LC";	font-size: 24px;	letter-spacing: -0.51px;	line-height: 54px;
    margin: 0; font-weight: 400;
}

.block-right-menu-down-sticky{
    /*float: right;*/
    /*height: 54px;*/
}
.block-list-link-menu-down{
    /*text-align: right;*/
}
.block-list-link-menu-down a{
    display: inline-block;
    height: 24px;
    line-height: 24px;
    margin: 15px 0 15px 20px;
    font-family: "Stratos LC";
    letter-spacing: -0.06px;
    font-weight: 300;
    cursor: pointer;
    transition: .3s;
}
.block-list-link-menu-down a.link-item{
    color: #000000;
    font-size: 15px;
    cursor: pointer;
}
.block-list-link-menu-down a.link-item:hover{
    color: #0028DC;
}
.block-list-link-menu-down a.link-item.active{
    color: #9B9B9B;
    cursor: default;
}
.block-list-link-menu-down a.link-item.button{
    font-size: 12px;
    border-radius: 2px;
    background-color: #0028DC;
    padding: 4px 15px;
    color: #FFFFFF;
    line-height: 17px;
}
.block-list-link-menu-down a.link-item.button:hover{
    background-color: #00F082;
    color: #000000;
}

.block-list-countries.fix-expert.expert-language {

}
.block-list-countries.fix-expert.expert-language .Select-value-label {
    color: #ffffff !important;
    /*padding-left: 0!important;*/
}
.block-list-countries.fix-expert.expert-language .Select--multi .Select-input {
    margin-left: 0;
}
.block-list-countries.fix-expert.expert-language .Select--multi .Select-value {
    margin-right: 5px;
    margin-left: 0;
}

.link-style {
    text-decoration: none;
    margin: 0!important;
    color: inherit;
}
.span-divider-expert {
    line-height: 54px;
    float: left;
    padding-left: 20px;
    color: #000;
    font-size: 18px;
}

.temp-raise-project {
    color: #ffffff;
}
.temp-raise-project span.trp-1 {
    color: #00F082;
    font-size: 32px
}

.temp-raise-project.fix-black {
    color: #000;
    font-size: 14px;
}
.temp-raise-project.fix-black span.trp-1 {
    color: #00F082;
    font-size: 16px
}

.fix-test-fundspage {
    color: #ffffff;
}
.fix-test-fundspage p{
    margin-top: 0;
}
.temp-div-flex {
    display: flex;
    height: 87px;
    flex-direction: column;
    justify-content: center;
}


.block-progress-bar-fund-new{
    width: 100%;
}
.block-top-progress-bar-fund-new{
    display: table;
    width: 100%;
}
.block-top-progress-bar-fund-new p{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 29px;	letter-spacing: -0.53px;
    margin: 0 0 15px 0; font-weight: 300;
}
.block-top-progress-bar-fund-new span.count{
    float: left;
}
.block-top-progress-bar-fund-new span.val{
    float: left;margin: 0 0 0 7px;
}
.block-top-progress-bar-fund-new span.description{
    color: #FFFFFF;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.1px;
    font-weight: 300;float: left; margin: 3px 0 0 5px;
}
.block-body-progress-bar-fund-new{
    height: 7px;
    background: #EBEBEB;
}
.block-body-progress-fund-new{
    height: 7px;
    background: #00F082;
}

.block-img-logo-75x75{
    width: 75px;
    height: 75px;
    background-position: center!important;
    background-size: cover!important;
}
.block-img-logo-56x56{
    width: 56px;
    height: 56px;
    display: inline-block;
    background-position: center!important;
    background-size: cover!important;
}
.block-img-logo-60x60{
    width: 60px;
    height: 60px;
    display: inline-block;
    background-position: center!important;
    background-size: cover!important;
}


/*.image-gallery-right-nav:before{
    content: ''!important;
    position: absolute;
    width: 40px;
    height: 3px;
    background: #FFFFFF;
    border-radius: 1px;
    transform: rotate(60deg);
    top: 41.5%;
    left: -10px;
    box-shadow: 0px 0px 20px 1px #000000;
}
.image-gallery-right-nav:after{
    content: '';
    position: absolute;
    width: 40px;
    height: 3px;
    background: #FFFFFF;
    border-radius: 1px;
    transform: rotate(-60deg);
    top: 52%;
    left: -10px;
    box-shadow: 0px 0px 20px 1px #000000;
}*/
.block-swiper-startup .image-gallery-left-nav{
    padding: 160px 15px;
}
/*
.image-gallery-left-nav:before{
    content: ''!important;
    position: absolute;
    width: 40px;
    height: 3px;
    background: #FFFFFF;
    border-radius: 1px;
    transform: rotate(-60deg);
    top: 41.5%;
    left: 0px;
    box-shadow: 0px 0px 20px 1px #000000;
}
.image-gallery-left-nav:after{
    content: '';
    position: absolute;
    width: 40px;
    height: 3px;
    background: #FFFFFF;
    border-radius: 1px;
    transform: rotate(60deg);
    top: 52%;
    left: 0px;
    box-shadow: 0px 0px 20px 1px #000000;
}*/
