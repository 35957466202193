/*.edit-profile-list {*/
    /*background-color: #FAFAFA;*/
    /*padding: 30px 0 80px 0;*/
    /*min-height: 100vh;*/
    /*max-width: 400px;*/
    /*margin:0 auto;*/
/*}*/
.edit-profile-list .edit-profile-main-title {
    max-width: 400px;
    width: 100%;
    min-height: 30px;
    position: relative;
}
.edit-profile-main-title .main-title {
    float: left;
}
.text-edit-list-title {
    font-size: 28px;
    line-height: 30px;
    color: #2F3849;
}
.edit-profile-main-title .last-edited {
    position: absolute;
    right: 0;
    top: 0;
}
.edit-profile-main-title .text-edited{
    font-size: 10px;
    line-height: 13px;
    color: #979797;
}
.edit-profile-table {
    margin-top: 50px;
    border-top: 1px solid #D8D8D8;
}
.edit-profile-table .edit-profile-table-line {
    position: relative;
    width: 100%;
    height: 41px;
    border-bottom: 1px solid #D8D8D8;
    cursor: pointer;
    overflow: hidden;
}
.edit-profile-table .edit-profile-table-line:hover .table-edit-button {
    opacity: 1;
}
.edit-profile-table .edit-profile-table-line .table-edit-button {
    opacity: 0;
    position: absolute;
    right: 0;
    top: 7px;
    width: auto;
    height: 25px;
    background-color: #2F3849;
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.07);
    border-radius: 4px;
    transition: .2s;
}
.edit-profile-table .edit-profile-table-line .table-edit-button > span {
    font-size: 11px;
    line-height: 25px;
    padding: 0 20px;
    text-align: center;
    color: #FFFFFF;
}

.edit-profile-table .edit-profile-table-line > span{
    font-size: 16px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: -0.505952px;
    color: #000000;
}
.margin-top-3 {
    margin-top: 3px;
}

.block-page .main-title{
    font-family: "Stratos LC";
    font-size: 28px;
    line-height: 22px;
    color: #2F3849;
    font-weight: 500;
}

.block-expert-ach{
    min-height: 100vh;
    padding-top: 80px;
    max-width: 800px;
    width: auto;
}

#startup-team .invite-date{
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
}

#startup-team .mem-btn{
    font-size: 14px;
    line-height: 18px;
    text-decoration-line: underline;
    color: rgba(0, 0, 0, 0.8);
}

#startup-team .mem-btn:hover{
    text-decoration: none;
}

#startup-team .delete{
    text-align: right;
}

#startup-team .list-user-email{
    font-size: 14px;
    line-height: 24px;
    color: rgba(8, 8, 8, 0.8);
}

#startup-team .label-is-founder{
    top: 24px!important
}

.block-button-circle{
    cursor: pointer;
    margin-right: 12px;
}
.accelerator-team-descr {
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    font-weight: 300;
}
.edit-total-members-text {
    font-size: 14px;
    line-height: 14px;
    color: #2f3849;
    margin: 8px 0 0 0;
    font-weight: 300;
    /*float: left;*/
    cursor: pointer;
}
.block-fund-members-title .button-add-members {
    /*float: right!important;*/
}
.block-fund-members .toggle-buttons-line {
    margin: 28px 0 24px 0!important;
    padding: 24px 0 0 0;
    border-top: 1px solid #D8D8D8;
    height: 64px!important;
}
.block-fund-members .descr-text,
.block-startup-team .descr-text {
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    font-weight: 300;
}
.block-startup-team {
    max-width: 780px;
}
.block-startup-team-title .button-add-members {
    /*float: right!important;*/
}
.block-startup-team .block-startup-team-title {
    /*height: 54px;*/
    padding-bottom: 26px;
    /*border-bottom: 1px solid #D8D8D8;*/
}
.block-startup-team .block-startup-team-title:after {
    content: '';
    width: calc(100% - 20px);
    border-bottom: 1px solid #D8D8D8;
    position: relative;
    bottom: -26px;
    left: 10px;
}
.block-set-total-team {
    padding: 26px 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto!important;
}
.block-set-total-team .main-title  {
    font-size: 28px;
    line-height: 28px;
    color: #2f3849;
    font-weight: 500;
}
.block-set-total-team .descr-text  {
    font-size: 16px;
    line-height: 18px;
    color: rgba(8,8,8,0.8);
}
.block-set-total-team .input-wrapper input {
    background: #FFFFFF;
    font-family: "Stratos LC";
    outline: none;
    border-radius: 4px;
    border: 1px solid #979797;
    height: 52px;
    width: 88px;
    text-align: center;
    font-size: 28px;
    line-height: 25px;
    color: #2f3849;
    font-weight: 500;
}
.block-set-total-team .close {
    top: 32px!important;
}
.block-set-total-team .send-button-line .block-button-3-0.button-3-0-blue {
    height: 36px;
    border-radius: 4px;
    background-color: #2AA2E6;
}
.block-set-total-team .send-button-line .block-button-3-0.button-3-0-blue p.block-button-3-0-title {
    line-height: 36px;
    font-weight: 500;
}



.margin-top-35 {
    margin-top: 35px;
}

@media (max-width: 576px){
    .edit-profile-main-title{
        display: none;
        position: fixed !important;
        height: 64px;
        left: 105px;
        top: 0;
        /*display: flex;*/
        flex-direction: column;
        justify-content: center;
        z-index: 1000;
        width: 80% !important;
        background: #fff;
    }
}
@media (max-width: 390px){
    .edit-profile-main-title{
        left: 85px;
    }
    .edit-profile-main-title .text-edit-list-title {
        font-size: 26px;
    }
}