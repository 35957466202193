.title-input-small{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 13px;	line-height: 16px; font-weight: 300; margin: 0;
}
.title-input-small .sure-star{
    color: #FA4600;
}
.block-input-DAO-new{
    width: 100%;
    overflow: hidden;
}
.block-input-DAO-new .title{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 13px;	line-height: 16px; font-weight: 300; margin: 0;
}
.block-input-DAO-new .title .sure-star{
    color: #FA4600;
}
.block-input-DAO-new .error,
.block-input-DAO-new-select .error{
    width: 100%; height: 16px;
    color: #FA4600;	font-family: "Stratos LC";	font-size: 13px;	line-height: 16px; font-weight: 300; margin: 0 0 10px 0;
}

.block-input-DAO-new input{
    height: 36px;
    border: 0;
    background: none;
    color: #4A4A4A;
    border-bottom: 1px solid #979797;
    outline: none;
    width: 100%;
    font-size: 14px;
    margin: 5px 0 0 0;
    font-family: "Stratos LC";
    font-weight: 300;
}
.block-input-DAO-new textarea{
    font-family: "Stratos LC";
    font-weight: 300;
    min-height: 36px;
    max-height: 100px;
    border: 0;
    background: none;
    color: #4A4A4A;
    border: 1px solid #979797;
    outline: none;
    width: 100%;
    font-size: 14px;
    margin: 0;
    resize: vertical;
}
.block-input-DAO-new input:focus{
    border-bottom: 1px solid #2AA2E6;
}
.block-input-DAO-new.error input:focus{
    border-bottom: 1px solid #2AA2E6;
}
.block-input-DAO-new.ok input:focus{
    border-bottom: 1px solid #2AA2E6;
}

.block-input-DAO-new input::placeholder{
    color: #979797;
}

.block-input-DAO-new.error p.error{
    opacity: 1;
}
.block-input-DAO-new.error input{
    border-bottom: 1px solid #FA4600;
}

.block-input-DAO-new.ok input{
    border-bottom: 1px solid #00F082;
}




.block-input-DAO-new-icon{
    width: 100%;
    overflow: hidden;
    margin: 10px 0 0 0;
}
.block-input-DAO-new-icon .title{
    color: #9B9B9B;	font-family: "Stratos LC";	font-size: 13px;	line-height: 16px; font-weight: 300; margin: 0;
}
.block-input-DAO-new-icon .title .sure-star{
    color: #FA4600;
}
.block-input-DAO-new-icon .error{width: 100%; height: 16px;
    color: #FA4600;	font-family: "Stratos LC";	font-size: 13px;	line-height: 16px; font-weight: 300; margin: 0 0 0px 0 ;
}
.block-input-new-icon{
    width: 100%;
    overflow: hidden;
    position: relative;
}
.block-input-new-icon i{
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 8px;
    color: #979797;
}
.block-input-new-icon input{
    height: 36px;
    border: 0;
    background: none;
    color: #4A4A4A;
    border-bottom: 1px solid #979797;
    outline: none;
    width: 100%;
    margin: 0px 0 0 0;
    padding: 0 0 0 26px;
    font-size: 14px;
    font-family: "Stratos LC";
    font-weight: 300;
}
.block-input-DAO-new-icon .block-input-new-icon input:focus{
    border-bottom: 1px solid #2AA2E6;
}
.block-input-DAO-new-icon .block-input-new-icon input:focus ~ i{
   color: #2AA2E6;
}
.block-input-DAO-new-icon .block-input-new-icon input::placeholder{
    color: #979797;
}
.block-input-DAO-new-icon.error p.error{
    opacity: 1;
}
.block-input-DAO-new-icon.error .block-input-new-icon input{
    border-bottom: 1px solid #FA4600;
}
.block-input-DAO-new-icon.error .block-input-new-icon i{
    color: #FA4600;
}

.block-input-DAO-new-icon.error .block-input-new-icon input:focus{
    border-bottom: 1px solid #2AA2E6;
}
.block-input-DAO-new-icon.error .block-input-new-icon input:focus ~ i{
    color: #2AA2E6;
}

.block-input-DAO-new-icon.ok .block-input-new-icon input{
    border-bottom: 1px solid #00F082;
}
.block-input-DAO-new-icon.ok .block-input-new-icon i{
    color: #00F082;
}

.block-input-DAO-new-icon.ok .block-input-new-icon input:focus{
    border-bottom: 1px solid #2AA2E6;
}
.block-input-DAO-new-icon.ok .block-input-new-icon input:focus ~ i{
    color: #2AA2E6;
}



.block-input-DAO-new-select{
    margin: 10px 0 20px 0;
}
.block-input-DAO-new-select .Select-control{
    border: 0;
    border-bottom: 1px solid #979797;
    background: none;
}
.block-input-DAO-new-select .Select-placeholder{
    font-size: 14px!important;
    color: #979797;
}


.block-input-DAO-new-select .Select-menu-outer .VirtualizedSelectOption{
    cursor: pointer;
}
.block-input-DAO-new-select .Select-menu-outer .VirtualizedSelectOption:hover{
    background: #BBBBBB;
}
.block-input-DAO-new-select .Select-input > input{
    color: #4A4A4A;
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 25px;
    font-weight: 300;
}
.block-input-DAO-new-select .Select-input{
    padding: 0;
}


.block-input-DAO-new-select .Select-arrow-zone{
    padding: 0;
    width: 36px;
    background: #2AA2E6;
    height: 36px;
    position: relative;
}


.block-input-DAO-new-select .Select-arrow{ opacity: 0}
.block-input-DAO-new-select .Select-arrow-zone:after{
    content: '';
    width: 16px;
    height: 2px;
    background: #FFFFFF;
    position: absolute;
    top: 17px;
    left: 4px;
    transition: .3s;
}
.block-input-DAO-new-select .Select-arrow-zone:before{
    content: '';
    width: 16px;
    height: 2px;
    background: #FFFFFF;
    position: absolute;
    top: 17px;
    left: 15px;
    transition: .3s;
}
.block-input-DAO-new-select .Select.is-open > .Select-control .Select-arrow-zone:after{
    transform: rotate(-45deg);
}
.block-input-DAO-new-select .Select.is-open > .Select-control .Select-arrow-zone:before{
    transform: rotate(45deg);
}
.block-input-DAO-new-select .Select.is-open > .Select-control .Select-arrow-zone:hover:after{
    transform: rotate(-40deg);
}
.block-input-DAO-new-select .Select.is-open > .Select-control .Select-arrow-zone:hover:before{
    transform: rotate(40deg);
}
.block-input-DAO-new-select .Select-arrow-zone:after{
    transform: rotate(45deg);
}
.block-input-DAO-new-select .Select-arrow-zone:before{
    transform: rotate(-45deg);
}
.block-input-DAO-new-select .Select-arrow-zone:hover:after{
    transform: rotate(40deg);
}
.block-input-DAO-new-select .Select-arrow-zone:hover:before{
    transform: rotate(-40deg);
}
*::placeholder{
    color: rgba(74,74,74,0.7)!important;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgba(74,74,74,0.7)!important;
}
::-moz-placeholder { /* Firefox 19+ */
    color: rgba(74,74,74,0.7)!important;
}
:-ms-input-placeholder { /* IE 10+ */
    color: rgba(74,74,74,0.7)!important;
}
:-moz-placeholder { /* Firefox 18- */
    color: rgba(74,74,74,0.7)!important;
}
