
.block-header-sticky-card {
    height: 80px;
    width: 980px;
    margin-left: calc(50% - 490px);
    margin-top: 30px;
    border-radius: 6px;
    background-color:#FFFFFF;
    box-shadow: 0 6px 16px 0 rgba(0,0,0,0.1);
}
.block-photo-user-header-sticky-card {
    height: 60px;
    width: 60px;
    margin-left: 20px;
    margin-top: 10px;
    border-radius: 4px;
    background-color: #F5F5F5;
    float: left;
    background-size: cover;
    position: relative;
}
.block-photo-user-header-sticky-card .avatar {
    position: absolute;
    height: inherit;
    width: inherit;
    left: 9px;
    top: 5px;
    /*background-image: url(../../../images/svg/ic_avatar.svg);*/
    background-repeat: no-repeat;
}
.block-info-user-header-sticky-card {
    height: 55px;
    width: 355px;
    margin-left: 20px;
    margin-top: 12px;
    float: left;
}
.block-location-user-header-sticky-card {
    height: 45px;
    width: 175px;
    margin-left: 5px;
    margin-top: 15px;
    float: left;
}
.block-language-user-header-sticky-card {
    height: 45px;
    width: 155px;
    margin-left: 5px;
    margin-top: 15px;
    float: left;
}
.block-info-user-header-sticky-card-top {
    width: inherit;
    height: 30px;
}
.block-info-user-header-sticky-card-bottom {
    width: inherit;
    height: 25px;
    max-width: 335px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.title-info-user-header-sticky-card {
    height: 30px;
    width: auto;
    max-width: 325px;
    color: rgba(8,8,8,0.8);
    font-family: "Stratos LC";
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.verificati-info-user-header-sticky-card {
    height: 20px;
    width: 20px;
    margin-left: 8px;
    margin-top: 5px;
    float: left;
}
.verificati-info-user-header-sticky-card .check {
    height: 10px;
    width: 10px;
    position: absolute;
    /*background-image: url(../../../images/svg/ic_check.svg);*/
    background-repeat: no-repeat;
    top: 5px;
    left: 5px;
}
.verificati-info-user-header-sticky-card .circuit {
    height: 20px;
    width: 20px;
    position: relative;
    border-radius: 50px;
    background-color: lightgrey;
    box-shadow: 0 8px 19px 0 rgba(14,207,170,0.15);
}
.type-info-user-header-sticky-card {
    height: 20px;
    min-width: 50px;
    width: auto;
    margin-top: 5px;
    border-radius: 12px;
    background-color: lightgrey;
    float: left;
}
.type-info-user-header-sticky-card .title-text {
    height: 15px;
    min-width: 35px;
    width: auto;
    max-width: 85px;
    padding: 2px 10px;
    color: #FFFFFF;
    font-family: "Stratos LC";
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.block-info-user-header-sticky-card-bottom .text {
    height: 20px;
    width: auto;
    max-width: 230px;
    margin-top: 6px;
    margin-left: 8px;
    color: rgba(0,0,0,0.5);
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 18px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.city-location-user-header-sticky-card {
    height: 20px;
    width: inherit;
    color: rgba(8,8,8,0.8);
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.block-location-user-header-sticky-card .title-text  {
    height: 25px;
    width: inherit;
    margin-bottom: 2px;
    color: rgba(0,0,0,0.5);
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.block-language-user-header-sticky-card .title-text {
    height: 25px;
    width: inherit;
    margin-bottom: 2px;
    color: rgba(0,0,0,0.5);
    font-family: "Stratos LC";
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.options-language-user-header-sticky-card {
    height: 20px;
    width: inherit;
    color: rgba(8,8,8,0.8);
    font-family: "Stratos LC";
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.block-button-header-sticky-card{
    width: 160px;
    float: left;
}
.block-button-header-sticky-card .block-button-3-0 {
    height: 40px;
    margin: 20px 5px;
}
.block-button-header-sticky-card p.block-button-3-0-title {
    line-height: 40px!important;
}

/*
* Investor
*/
.type-info-user-header-sticky-investor-card,
.block-button-header-sticky-investor-card .block-button-3-0,
.verificati-info-user-header-sticky-card .circuit-investor{
    background-color: #2CA6EB;
}


/*
* Expert
*/
.type-info-user-header-sticky-expert-card,
.block-button-header-sticky-expert-card .block-button-3-0,
.verificati-info-user-header-sticky-card .circuit-expert{
    background-color: #0ECFAA;
}

/*
* Fund
*/
.type-info-user-header-sticky-fund-card,
.block-button-header-sticky-fund-card .block-button-3-0,
.verificati-info-user-header-sticky-card .circuit-fund{
    background-color: #2CA6EB;
}
.block-info-user-header-sticky-fund-card {
    width: 405px;
}
.title-info-user-header-sticky-fund-card {
    max-width: 375px!important;
}
.block-language-user-header-sticky-fund-card {
    width: 105px!important;
}

/*
* Startup
*/
.type-info-user-header-sticky-startup-card,
.block-button-header-sticky-startup-card .block-button-3-0,
.verificati-info-user-header-sticky-card .circuit-startup{
    background-color: #2F3849;
}
.block-button-header-sticky-startup-card .block-button-3-0 {
    margin-left: 40px;
}
.title-info-user-header-sticky-startup-card {
    max-width: 480px!important;
}
.block-info-user-header-sticky-startup-card {
    width: 510px;
}
.block-social-header-sticky-startup-card {
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    border: 1px solid #D0D0D0;
    border-radius: 6px;
    background-color: #FFFFFF;
    float:left;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 10px;
    transition: 0.2s linear;
}
.block-social-header-sticky-startup-card:hover {
    border: 1px solid #ADADAD;
}
.block-social-header-sticky-startup-card .heart,
.block-social-header-sticky-startup-card .clock,
.block-social-header-sticky-startup-card .share {
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
}
.block-social-header-sticky-startup-card .heart {
    margin:12px 10px;
    /*background-image: url(../../../images/png/ic_like.png);*/
}

.block-social-header-sticky-startup-card .clock {
    margin:10px 11px;
    /*background-image: url(../../../images/png/ic_waitlist.png);*/
}

.block-social-header-sticky-startup-card .share {
    margin:10px 10px;
    /*background-image: url(../../../images/png/ic_share.png);*/
}