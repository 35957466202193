.verification {
    width: 17px;
    height: 17px;
    &.blue {
        background-image: url(../../../images/icon/VerificationBlue.svg);
    }
    &.green {
        background-image: url(../../../images/icon/VerificationGreen.svg);
    }
    &.black{
        background-image: url(../../../images/icon/VerificationBlack.svg);
    }
}