.shell-folder {
}

.block-folder {

}

.block-top-folder {
    height: 55px;
    cursor: pointer;
    border-bottom: 1px solid #CFCFCF;
    padding: 0;
}

.block-logo-title-folder {
    width: calc(100% - 570px);
    float: left;
    height: 55px;
}
.block-logo-title-folder.folder-edit{
    width: calc(100% - 640px);
    float: left;
    height: 55px;
}
.block-top-logo-folder {
    width: 35px;
    float: left;
    height: 55px;
}
.block-top-logo-folder i{
    line-height: 55px;
    width: 50px;
    font-size: 23px;
}


.block-top-title-folder {
    width: calc(100% - 50px);
    float: left;
    padding:  0 20px 0 0;
}
.shell-flex-top-title-folder{
    width: 100%;
    overflow: hidden;
    height: 55px;
    display: flex;
}
.block-flex-top-title-folder{
    display: flex;
    margin: auto 0;
}
.block-top-title-folder p{
    color: #000000;	font-family: "Stratos LC";	font-size: 17px;	letter-spacing: -0.28px;	line-height: 20px;
    font-weight: 400;
    margin: 0;

    width: 100%;
}
.block-flex-top-title-folder input {
    height: 36px;
    border: 0;
    background: none;
    color: #4A4A4A;
    border-bottom: 1px solid #979797;
    outline: none;
    width: 100%;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    font-size: 14px;
}

.block-tags-date-folder {
    width: 570px;
    float: left;
}
.block-tags-date-folder.folder-edit{
    width: 640px;
    float: left;
}
.block-top-tags-folder {
    width: 50%;
    float: left;
    height: 55px;
    overflow: hidden;
}
.block-top-tags-folder p{
    font-weight: 400!important;
}
.block-top-tags-folder.folder-edit{
    width: 45%;
    float: left;
    height: 55px;
    overflow: hidden;
}
.shell-flex-folder{
    width: 100%;
    display: flex;
    height: 55px;
}
.block-flex-folder{
    width: 100%;
    display: flow-root;
margin: auto 0;
}

.block-top-date-folder {
    width: 28%;
    float: left;
    height: 55px;
}
.block-top-date-folder.folder-edit{
    width: 25%;
    float: left;
    height: 55px;
}

.block-top-link-folder{
    width: 22%;
    float: left;
    height: 55px;

}
.block-top-link-folder.folder-edit{
    width: 18.75%;
    float: left;
    height: 55px;
}
.block-top-delete-folder.folder-edit{
    width: 11.25%;
    float: left;
    height: 55px;
}
.block-top-delete-folder a{
    color: #000000;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.26px;	line-height: 18px;
    font-weight: 400; margin: 0; text-align: right; display: block;text-decoration: underline;
}
.block-top-date-folder p{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 55px;
    font-weight: 300; margin: 0;
}


.block-body-folder {
    display: none;
}
.block-button-add-new{
    width: 100%;
    height: 40px;
    padding: 0 0 0 20px;
    position: relative;
    border-bottom: 1px solid #CFCFCF;
}
.block-button-add-new a{
    color: #0028DC;	font-family: "Stratos LC";	font-size: 15px;	letter-spacing: -0.19px;	line-height: 40px;
    display: inline-block; text-decoration: none; cursor: pointer; padding: 0 0 0 15px;
}
.block-button-add-new a i{
    font-size: 15px;
    margin: 0 10px 0 0;
    width: 15px;
    height: 15px;
    line-height: 15px;

}


.block-popup-menu-add-new-button{
    width: 173px;	border-radius: 3px;	background-color: #FFFFFF;	box-shadow: 0 2px 7px 0 rgba(0,0,0,0.5);
    position: relative; display: none;
    left: 10px;
    z-index: 10;
}
.block-list-popup-menu-add-new-button{
}
.block-item-popup-menu-add-new-button{
    width: 100%;
    height: 36px;
    border-bottom: 1px solid #979797;
    padding: 0 0 0 20px;
}
.block-item-popup-menu-add-new-button:last-child{
    border: 0;
}
.block-item-popup-menu-add-new-button p{
    margin: 0; line-height: 36px;
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 14px;	letter-spacing: -0.15px;font-weight: 400;
    transition: .3s;
    cursor: pointer;
}
.block-item-popup-menu-add-new-button p:hover{
    color: #0028DC;
}