.shell-file{}
.block-file{}

.block-top-file{
    height: 60px;
    cursor: pointer;
    border-bottom: 1px solid #CFCFCF;
    padding: 0;
}


.block-logo-title-file{
    width: calc(100% - 570px);
    float: left;
    height: 60px;
}
.block-logo-title-file.file-edit{
    width: calc(100% - 640px);
    float: left;
    height: 60px;
}

.block-top-logo-fiel{
    width: 35px;
    float: left;
    height: 60px;
}
.block-top-logo-fiel i{
    line-height: 60px;
    width: 50px;
    font-size: 23px;
}
.block-top-logo-fiel i.fa-link{
 font-size: 20px;
}

.block-top-title-file{
    width: calc(100% - 50px);
    float: left;
    padding:  0 20px 0 0;
}
.block-top-title-file p{
    font-family: "Stratos LC"; margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}
.block-top-title-file p.title{
    color: #000000;		font-size: 15px;	letter-spacing: -0.21px;	line-height: 20px; font-weight: 300;
}
.block-top-title-file p.description{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.2px;	line-height: 17px; font-weight: 300;
}


.block-tags-date-file{
    width: 570px;
    float: left;
}
.block-tags-date-file.file-edit{
    width: 640px;
    float: left;
}
.block-top-tags-file {
    width: 50%;
    float: left;
    height: 60px;
    overflow: hidden;
}
.block-top-tags-file p{
    font-weight: 400!important;
}
.block-top-tags-file.file-edit{
    width: 45%;
    float: left;
    height: 60px;
    overflow: hidden;
}

.block-top-date-file {
    width: 28%;
    float: left;
    height: 60px;
}
.block-top-date-file.file-edit{
    width: 25%;
    float: left;
    height: 60px;
}

.block-top-date-file p{
    color: #4A4A4A;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.15px;	line-height: 60px;
    font-weight: 300; margin: 0;
}

.block-top-link-file{
    width: 22%;
    float: left;
    height: 60px;

}
.block-top-link-file.file-edit{
    width: 18.75%;
    float: left;
    height: 60px;
}
.block-top-link-file a,
.block-top-link-file span {
    color: #0028DC;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.26px;	line-height: 18px;
    font-weight: 400; margin: 0; text-align: left; display: block; text-decoration: underline;
    position: relative;
}


.block-top-delete-file.file-edit{
    width: 11.25%;
    float: left;
    height: 60px;
}
.block-top-delete-file a{
    color: #FA4600;	font-family: "Stratos LC";	font-size: 12px;	letter-spacing: -0.26px;	line-height: 18px;
    font-weight: 400; margin: 0; text-align: right; display: block; text-decoration: underline;

}




.shell-flex-file{
    width: 100%;
    display: flex;
    height: 60px;
}
.block-flex-file{
    width: 100%;
    display: flow-root;
    margin: auto 0;
}
.link-copied {
    text-decoration: none;
    position: absolute;
    color: #ffffff;
    display: inline-block;
    background-color: #2fde6a;
    padding: 0 3px;
    font-size: 9px;
    border-radius: 5px;
    line-height: 13px;
    top: 3px;
    right: -10px;
}




