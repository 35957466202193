.input-user-info {
    box-sizing: border-box;
    height: 32px;
    width: 100%;
    border: 1px solid #000;
    background-color: #FFFFFF;
    border-radius: 0;

    color: #000000;
    font-family: "Stratos LC";
    font-size: 15px;
    font-weight: 400;    letter-spacing: -.5px;

    line-height: 14px;

    padding: 0 0 0 10px;
}

input-user-info::placeholder {
    color: #363636;
}

.input-user-info:focus {
    border: 1px solid #00F082;
}

.block-text-field{

}
.block-text-field input{
    outline: none;
    margin: 0;
}
.block-text-field input:focus {
    border: 1px solid #00F082;
}

.block-text-field input::placeholder {
    color: #363636;
}
.block-input-password{
    height: 40px;
    width: 100%;

    display: block;
    overflow: hidden;
    position: relative;
    transition: .5s;
    margin-bottom: 10px;
}
.block-input-password input:focus > .block-input-password{
    border: 1px solid #00F082;
}
.block-input-password input{
    display: inline-block;
    border: 0;
    width: 100%;
    outline: 0;
    height: 40px;
    font-family: "Stratos LC";
    font-weight: 400;
    vertical-align: top;
    padding: 0 50px 0 15px;
    transition: .5s;
    border: 1px solid #ffffff;
}
.block-input-password input:-ms-input-placeholder {
    color: #A2A6AF;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear; }
.block-input-password input:focus {
    border-color: #00F082; }
.block-input-password input::-webkit-input-placeholder {
    color: #A2A6AF; }



.block-input-password input:focus{
    border: 1px solid #00F082;
}
.block-input-password i{
    vertical-align: top;
    display: inline-block;
    width: 40px;
    height: 40px;
    cursor: pointer;
    line-height: 37px;
    text-align: center;
    color: #A2A6AF;
    transition: .5s;
    position: absolute;
    top: 0;
    right: 0;
}
.block-input-password i:hover{
    color: #00F082;

}
.shell-input-user{
    overflow: hidden;
    width: 100%;
}